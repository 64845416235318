import {privateApi, privateBlobApi, publicApi} from '../../utils/api';
import React from "react";
import {saveAs} from 'file-saver';
import {listFetchAction, listInitDataAction} from "../../actions/listAction";
import {formErrors, formInit, formIsSend, formSimpleChange} from "./system/formService";
import {modalHideAction} from "../../actions/modalAction";
import {formInitAction} from "../../actions/formAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {
    convertTemperatureToUnitTemperature,
    floatFormatting,
    prepareFilter
} from "../../utils/app";
import {modalHide} from "./system/modalService";
import _ from "lodash";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import {UNIT_TEMPERATURE} from "../../constants/system";

import {uploadFileChunk} from "./fileService";
import uuid from "react-uuid";

export const searchSymptoms = (query) => {
    return (dispatch) => {
        return privateApi.get("/symptoms?search=commonName," + query)
            .then(response => {
                return dispatch(listFetchAction("searchSymptoms", response.data.content));
            }).catch(error => {

            });
    }
};

export const fetchBookings = (pageNumber, listName = 'bookings', isPreload = true) => {
    return (dispatch, state) => {
        if (isPreload) dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters[listName] || {});
        const sort = prepareFilter(state().list.sorts[listName] || {});
        return privateApi.get("/bookings?pageNumber=" + pageNumber + "&search=" + filter + "&sort=" + sort)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction(listName, content, {...rest}));
            }).finally(() => {
                if (isPreload) dispatch(preloaderPageHideAction());
            });
    }
};

export const isCanCreateReview = (bookingId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get('/v1/bookings/' + bookingId + '/isCanCreateReview')
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("isCanCreateReview", {isCan : data}))
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchBooking = (id, callback = null) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        dispatch(preloaderPageShowAction());
        return privateApi.get("/bookings/" + id)
            .then(response => {
                const {data} = response;
                if (data.temperature) {
                    data.temperature = user.unitTemperature != UNIT_TEMPERATURE.DEGREE_CELSIUS ?
                        convertTemperatureToUnitTemperature(data.temperature, user.unitTemperature) :
                        floatFormatting(data.temperature);
                }

                dispatch(formInit("booking", data));
                dispatch(formInitAction("consultation", data.consultation || {}));
                if (callback) callback(data);
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const approveBooking = (booking) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/bookings/" + booking.id + '/approved')
            .then(response => {
                return dispatch(fetchBooking(booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const rejectBooking = (booking) => {
    return (dispatch, state) => {
        const {reason = null, reasonDetail = null} = state().form.data.confirm || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/bookings/" + booking.id + '/rejected', {reason: reason, reasonDetail: reasonDetail})
            .then(response => {
                dispatch(modalHide("confirm"));
                return dispatch(fetchBooking(booking.id));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("confirm", error.response.data.details));
                }
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const saveBookingFile = (booking, file) => {
    return (dispatch) => {
        dispatch(
            uploadFileChunk(file,
                () => dispatch(fetchBooking(booking.id)),
                "/v1/bookings/" + booking.id + '/files')
        );
    }
};

export const removeBookingFile = (booking, file) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/bookings/" + booking.id + '/files/' + file.id)
            .then(response => {
                dispatch(fetchBooking(booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const approveBookingList = (booking, listName = "bookings") => {
    return (dispatch, state) => {
        const pagination = state().list.pagination[listName] || {};
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/bookings/" + booking.id + '/approved')
            .then(response => {
                dispatch(fetchBookings(pagination.number + 1, listName));
                if (listName == "patientWaitingItems") {
                    dispatch(fetchBookings(1, "widgetScheduleItems"));
                }
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const rejectBookingList = (booking, listName = "bookings") => {
    return (dispatch, state) => {
        const pagination = state().list.pagination[listName] || {};
        const {reason = null, reasonDetail = null} = state().form.data.confirm || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/bookings/" + booking.id + '/rejected', {reason: reason, reasonDetail: reasonDetail})
            .then(response => {
                dispatch(modalHide("confirm"));
                dispatch(fetchBookings(pagination.number + 1, listName));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("confirm", error.response.data.details));
                }
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fillDescriptionFromVideo = (file) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/files/" + file.id + '/speechRecognize')
            .then(response => {
                return dispatch(formSimpleChange("createBooking", "description", response.data));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fillSymptomsFromText = (text) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/symptoms/nlp/?text=" + text)
            .then(response => {
                dispatch(formSimpleChange("createBooking", "symptoms", response.data))
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const completeBooking = (booking) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/bookings/" + booking.id + '/completed')
            .then(response => {
                return dispatch(fetchBooking(booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};


export const fetchSpecializationsAsync = async (searchText) => {
    const response = await publicApi.get("/public/v1/specializations?pageNumber=1&search=searchText," + searchText);
    return response.data.content;
};

export const fetchSpecializations = (pageNumber, listName = "specializations") => {
    return (dispatch, state) => {
        const filter = prepareFilter(state().list.filters[listName] || {});
        const sort = prepareFilter(state().list.sorts[listName] || {});
        dispatch(preloaderPageShowAction());
        return publicApi.get("/public/v1/specializations?pageNumber=" + pageNumber + "&search=" + filter + "&sort=" + sort)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction(listName, content, {...rest}));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchSpecialisation = (pageNumber) => {
    return (dispatch) => {
        return privateApi.get("/specializations")
            .then(response => {
                return dispatch(listFetchAction("specialisations", response.data));
            }).catch(error => {

            });
    }
};

export const searchDoctors = (query) => {
    return (dispatch) => {
        return privateApi.get("/doctors?search=" + query)
            .then(response => {
                return dispatch(listFetchAction("doctors", response.data.content));
            }).catch(error => {

            });
    }
};

export const fetchDoctors = (pageNumber, listName = "doctors", isPreloader = true) => {
    return (dispatch) => {
        if (isPreloader) dispatch(preloaderPageShowAction());
        return privateApi.get("/doctors?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                return dispatch(listFetchAction(listName, content, {...rest}));
            }).catch(error => {

            }).finally(() => {
                if (isPreloader) dispatch(preloaderPageHideAction())
            });
    }
};

export const fetchScheduleByDate = (doctor, date) => {
    return (dispatch) => {
        return privateApi.get("/doctors/" + doctor.id + "/scheduleOnDate?date=" + date)
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("doctorSchedule", data, null));
            }).catch(error => {
            });
    }
};

export const fetchDoctorScheduleAvailableAppointmentDays = (doctor, typeConsultation, address, dateStart, dateEnd, listName = "availableAppointmentDays") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const params = {typeConsultation: typeConsultation, dateStart: dateStart, dateEnd: dateEnd};
        if (address != null) {
            params["address"] = address.id;
        }
        return privateApi.get('/v1/doctors/' + doctor.id + '/schedules/availableAppointmentDays', {params: params})
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction(listName, data, null));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction())
            });
    }
};

export const asyncFetchDoctorScheduleAvailableAppointmentDays = async (doctor, typeConsultation, address, dateStart, dateEnd) => {
    const params = {typeConsultation: typeConsultation, dateStart: dateStart, dateEnd: dateEnd};
    if (address != null) {
        params["address"] = address.id;
    }
    const response = await privateApi.get('/v1/doctors/' + doctor.id + '/schedules/availableAppointmentDays', {params: params});
    return response.data;
};

export const fetchStateAppointmentTimes = (doctor, typeConsultation, address, dateStart, dateEnd, listName = "stateAppointmentTimes") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const params = {typeConsultation: typeConsultation, dateStart: dateStart, dateEnd: dateEnd};
        if (address != null) {
            params["address"] = address.id;
        }
        return privateApi.get('/v1/doctors/' + doctor.id + '/schedules/stateAppointmentTimes', {params: params})
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction(listName, data, null));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction())
            });
    }
};

export const fetchReschedulingStateAppointmentTimes = (doctor, typeConsultation, address, dateStart, dateEnd, listName = "stateAppointmentTimes") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const params = {typeConsultation: typeConsultation, dateStart: dateStart, dateEnd: dateEnd};
        if (address != null) {
            params["address"] = address.id;
        }
        return privateApi.get('/v1/doctors/' + doctor.id + '/schedules/reschedulingStateAppointmentTimes', {params: params})
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction(listName, data, null));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction())
            });
    }
};

export const fetchScheduleByDateAsync = async (doctor, date) => {
    const response = await privateApi.get("/doctors/" + doctor.id + "/scheduleOnDate?date=" + date);
    return response.data;
};

export const fetchDoctorFreeBusyAppointments = async (doctor, month, year) => {
    const response = await privateApi.get('/doctors/' + doctor.id + '/freeBusyAppointments?month=' + month + '&year=' + year);
    return response.data;
};

export const freeBusyAppointmentsOnRescheduling = async (doctor, month, year) => {
    const response = await privateApi.get('/doctors/' + doctor.id + '/freeBusyAppointmentsOnRescheduling?month=' + month + '&year=' + year);
    return response.data;
};

export const fetchDoctorVacationDays = async (doctor, month, year) => {
    const response = await privateApi.get('/doctors/' + doctor.id + '/vacationDays?month=' + month + '&year=' + year);
    return response.data;
};

export const fetchDoctorScheduleStateByStatusAppointments = async (doctor, month, year) => {
    const response = await privateApi.get('/doctors/' + doctor.id + '/schedule/stateByStatusAppointments?month=' + month + '&year=' + year);
    return response.data;
};

export const getBookingHealthConditionVideo = async (booking) => {
    const response = await privateApi.get("/bookings/" + booking.id + "/healthConditionVideo", {responseType: "stream"});
    return response.data;
};


export const getBookingHealthConditionVideoLink = async (booking) => {
    const response = await privateApi.post("/bookings/" + booking.id + "/healthConditionVideo/createStreamingLink");
    return window._env_.BACKEND_URL + "/files/streaming?hash=" + response.data;
};

export const saveBooking = (booking, ownProps) => {
    return (dispatch, state) => {
        const creator = state().auth.user;
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("createBooking", true));

        let createBooking = {...{}, ...booking};
        if (creator.unitTemperature != UNIT_TEMPERATURE.DEGREE_CELSIUS) {
            if (createBooking.temperature) {
                createBooking = {
                    ...createBooking,
                    temperature: convertTemperatureToUnitTemperature(createBooking.temperature, UNIT_TEMPERATURE.DEGREE_CELSIUS)
                };
            }
        }

        return privateApi.post("/bookings", createBooking)
            .then(response => {
                dispatch(modalHideAction("appointmentSummary"));
                /*dispatch(formInit("createBooking", {}));
                dispatch(modalHide('chooseMember'));*/
                if (creator.role === 'PATIENT') {
                    ownProps.history.push("/bookings/" + response.data.id + "/detail");
                } else {
                    dispatch(modalHideAction("bookAnAppointment"));
                    return dispatch(noticeShowAction("", <FormattedMessage
                        id="notice.doctor.create.booking.msg.success.text"/>));
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createBooking", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.message));
                }
                dispatch(modalHideAction("appointmentSummary"));
                dispatch(formIsSend("createBooking", false));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const requestOnReschedulingBooking = ({booking, timeOfReceipt = null, comment = null}) => {
    return (dispatch, state) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("rescheduling", true));
        return privateApi.post("/bookings/" + booking.id + "/requestOnRescheduling", {
            timeOfReceipt: timeOfReceipt,
            comment: comment
        })
            .then(response => {

                const rescheduling = {
                    timeOfReceipt: parseInt(timeOfReceipt),
                    comment: comment
                };

                const stateBookings = state().list.data["bookings"] || [];
                if (stateBookings.length > 0) {
                    const elementIndex = stateBookings.findIndex(i => i.id == booking.id);
                    if (elementIndex != -1) {
                        stateBookings[elementIndex]["rescheduling"] = rescheduling;
                        dispatch(listInitDataAction("bookings", stateBookings));
                    }
                }

                const stateBooking = state().form.data["booking"] || {};
                if (!_.isEmpty(stateBooking)) {
                    dispatch(formSimpleChange("booking", "rescheduling", rescheduling));
                }

                dispatch(modalHideAction("rescheduling"));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("rescheduling", error.response.data.details));
                }
            }).finally(() => {
                dispatch(formIsSend("rescheduling", false));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const acceptRescheduling = (booking) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        const {rescheduling} = booking;
        const {timeOfReceipt} = rescheduling || {};
        return privateApi.post("/bookings/" + booking.id + "/acceptRescheduling")
            .then(response => {
                dispatch(formSimpleChange("booking", "timeOfReceipt", parseInt(timeOfReceipt)));
                dispatch(formSimpleChange("booking", "rescheduling", null));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const acceptReschedulingList = (booking, listName = "bookings") => {
    return (dispatch, state) => {
        const pagination = state().list.pagination[listName] || {};
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/bookings/" + booking.id + "/acceptRescheduling")
            .then(response => {
                dispatch(fetchBookings(pagination.number + 1, listName));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

/*export const randomAppointment = (specialisation) => {
    return (dispatch) => {
        dispatch(formIsSend("createBooking", true));
        return privateApi.post("/doctors/randomFreeSchedule",
            qs.stringify({specializationId: specialisation.id}),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then(response => {
                const {doctor, timeOfReceipt} = response.data;
                dispatch(formChangeFieldAction("createBooking", "doctor", doctor));
                dispatch(formChangeFieldAction("createBooking", "timeOfReceipt", timeOfReceipt));
                dispatch(formIsSend("createBooking", false));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createBooking", error.response.data.details));
                }
                dispatch(formIsSend("createBooking", false));
            });
    }
};*/

export const downloadBookingFile = (booking, file) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateBlobApi.get("/bookings/" + booking.id + "/files/" + file.id + "/download", {responseType: "blob"})
            .then(response => {
                saveAs(response.data, file.name);
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const downloadHealthConditionVideo = (booking) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateBlobApi.get("/bookings/" + booking.id + "/healthConditionVideo/download", {responseType: "blob"})
            .then(response => {
                saveAs(response.data, booking.healthConditionVideo.name);
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const downloadPrescription = (booking) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateBlobApi.get("/v1/bookings/" + booking.id + "/consultations/medicalTests/downloadFile", {responseType: "blob"})
            .then(response => {
                saveAs(response.data, uuid() + '.pdf');
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
}

export const downloadMedicalTestResult = (consultation, medicalTest, file) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateBlobApi.get("/consultations/" + consultation.id + "/medicalTests/" + medicalTest.id + "/fileResults/" + file.id + "/downloadFile", {responseType: "blob"})
            .then(response => {
                saveAs(response.data, file.name);
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchBookingCancelReasons = (booking) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/bookings/" + booking.id + "/cancelReasons")
            .then(response => {
                dispatch(listFetchAction("bookingCancelReasons", response.data, null));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};