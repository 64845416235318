import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Input} from "../../../../components/elements/input";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {Option, Select} from "../../../../components/elements/select";
import {formChangeField} from "../../../services/system/formService";
import {getSexInput, getSexsInput, stringToInt} from "../../../../utils/app";
import {fetchCountries, fetchMobileNetworkOperators, fetchSystemSettings} from "../../../services/commonService";
import {formChangeFieldAction} from "../../../../actions/formAction";
import {Label} from "../../../../components/elements/label";
import Birthday from "../../../systems/birthday";
import {Button, ButtonIcon} from "../../../../components/elements/button";
import {FormattedMessage} from "react-intl";

import {modalShow} from "../../../services/system/modalService";
import {noticeShowAction} from "../../../../actions/noticeAction";
import Dropdown, {DropdownWrapperItem} from "../../../systems/dropdown";
import {IconItem} from "../../../../components/elements/icon";
import {File} from "../../../../components/elements/file";
import {uploadPhoto} from "../../../services/fileService";
import {AvatarEdit} from "../../../../components/collections/avatar";
import {dropdownNotVisibleAction, dropdownVisibleAction} from "../../../../actions/dropdownAction";
import {fetchProfileGeneral, updateProfileGeneral} from "../../../services/patientProfileService";

class PatientProfileGeneral extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectSex = this.onSelectSex.bind(this);
        this.onChangeInteger = this.onChangeInteger.bind(this);
        this.onChangeBirthday = this.onChangeBirthday.bind(this);
        this.onUploadPhoto = this.onUploadPhoto.bind(this);
        this.onRemovePhoto = this.onRemovePhoto.bind(this);
        this.onSelectCountry = this.onSelectCountry.bind(this);
        this.onSelectMNO = this.onSelectMNO.bind(this);
    }

    componentDidMount() {
        const {profileUserId} = this.props;
        this.props.onFetchProfileGeneral(profileUserId);
        this.props.onfetchSystemSettings();
        this.props.onFetchCountries();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form:curForm} = this.props;
        const {form: prevForm} = prevProps;
        const {profile: curProfile} = curForm.data || {};
        const {profile: prevProfile} = prevForm.data || {};
        if (curProfile && !prevProfile) {
            if (curProfile.country) {
                console.log("fetch")
                this.props.onFetchMNOs(curProfile.country.id)
            }
        }
    }

    onRemovePhoto = () => {
        this.props.onFormSimpleChange("profile", "photo", null);
    }

    onUploadPhoto(event) {
        this.props.onDropdownNotVisibleAction();
        this.props.onUploadPhoto("profile", event);
    }

    onChangeInteger(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChange("profile", target.name, stringToInt(value));
    }

    onChange(event) {
        this.props.onFormChangeField('profile', event);
    }

    onChangeBirthday(day, month, year) {
        if (day != null && month != null && year != null) {
            const birthday = moment().set({'date': day, 'month': month, 'year': year});
            if (moment([year, month, day]).isValid()) {
                this.props.onFormSimpleChange("profile", "birthday", birthday.format("YYYY-MM-DD"));
            } else {
                this.props.onFormSimpleChange("profile", "birthday", null);
            }
        } else {
            this.props.onFormSimpleChange("profile", "birthday", null);
        }
    }

    onSelectSex(id) {
        this.props.onChangeDropdown("sex", id);
    }

    onSelectCountry(country) {
        this.props.onFormSimpleChange("profile", "country", country);
        this.props.onFormSimpleChange("profile", "mobileNetworkOperator", null);
        this.props.onFetchMNOs(country.id)
    }

    onSelectMNO(mobileNetworkOperator) {
        this.props.onFormSimpleChange("profile", "mobileNetworkOperator", mobileNetworkOperator);
    }

    render() {
        const {form, profileUserId} = this.props;
        const errors = form.errors.profile;
        const disabled = form.isSend.profile || false;
        const profile = form.data.profile || {};
        const currentYear = moment().format("YYYY");
        let practiceSinceOptions = [];
        if (profile && profile.birthday && moment(profile.birthday).isValid()) {
            const yearStart = moment(profile.birthday).format("YYYY");
            for (let year = yearStart; year <= currentYear; year++) practiceSinceOptions.push(year);
        }

        const dropdownButton = <ButtonIcon icon="photo-menu" iconSize={40}/>
        const dropdownPhoto = <div className="avatar-dropdown">
            <Dropdown button={dropdownButton}>
                <DropdownWrapperItem onClick={() => this.props.onDropdownVisibleAction()}>
                    <File icon={"camera-plus"} name="photo" onChange={this.onUploadPhoto}>
                        <FormattedMessage id="upload"/>
                    </File>
                </DropdownWrapperItem>
                <DropdownWrapperItem>
                    <ButtonIcon onClick={this.onRemovePhoto}>
                        <IconItem name="trash-black">
                            <FormattedMessage id="core.delete.label"/>
                        </IconItem>
                    </ButtonIcon>
                </DropdownWrapperItem>
            </Dropdown>
        </div>;
        return (
            <EmptyTag>
                <div className="row content-row">
                    <GridCol count="1">
                        <div className="white-block">
                            <div className="row form-elements">
                                <div className="col-12 align-center">
                                    <AvatarEdit doctor bytes={profile && profile.photo && profile.photo.bytes}>
                                        {dropdownPhoto}
                                    </AvatarEdit>
                                </div>

                                <GridCol count="2">
                                    <Input name="firstName"
                                           icon="user"
                                           value={profile.firstName || ''}
                                           error={(errors && errors.firstName) || null}
                                           placeholder={<FormattedMessage id="core.first.name.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Input name="lastName"
                                           icon="user"
                                           value={profile.lastName || ''}
                                           error={(errors && errors.lastName) || null}
                                           placeholder={<FormattedMessage id="core.last.name.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                                <GridCol count="2">
                                    <FormattedMessage id={getSexInput(profile['sex']) || 'undefined'}>
                                        {optionNameSelected =>
                                            <Select name="sex"
                                                    error={(errors && errors.sex) || null}
                                                    disabled={disabled}
                                                    label={<FormattedMessage id="core.gender.label"/>}
                                                    optionNameSelected={optionNameSelected}>
                                                {
                                                    getSexsInput().map(key =>
                                                        <Option
                                                            active={profile && profile['sex'] && profile['sex'] === key}
                                                            key={key}
                                                            onClick={() => this.onSelectSex(key)}>
                                                            {<FormattedMessage id={getSexInput(key)}/>}
                                                        </Option>
                                                    )
                                                }
                                            </Select>}
                                    </FormattedMessage>
                                </GridCol>
                                <GridCol count="2">
                                    <Label>{<FormattedMessage id="core.birthday.label"/>}</Label>
                                    <Birthday error={(errors && errors.birthday) || null}
                                              value={profile.birthday && moment(profile.birthday).format("DD.MM.YYYY") || null}
                                              onChangeBirthday={this.onChangeBirthday}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Input name="nhsNumber"
                                           icon="nhs"
                                           value={(profile && profile.nhsNumber) || ''}
                                           error={(errors && errors.nhsNumber) || null}
                                           placeholder={<FormattedMessage id="core.insurance.number.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChangeInteger}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Input name="phone"
                                           value={profile.phone || ''}
                                           error={(errors && errors.phone) || null}
                                           placeholder={<FormattedMessage id="mobile.phone"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                            </div>
                        </div>
                    </GridCol>

                </div>
                <div className="form-submit margin-top-15">
                    <Button
                        disabled={disabled} preloader={disabled === true}
                        onClick={() => this.props.onUpdateProfileGeneral(profileUserId, profile)}><FormattedMessage
                        id="core.btn.save.label"/></Button>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchCountries: () => dispatch(fetchCountries()),
        onFetchMNOs: (countryId) => dispatch(fetchMobileNetworkOperators(countryId)),
        onDropdownNotVisibleAction: () => dispatch(dropdownNotVisibleAction()),
        onDropdownVisibleAction: () => dispatch(dropdownVisibleAction()),
        onUploadPhoto: (formName, event) => dispatch(uploadPhoto(formName, event)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onFetchProfileGeneral: (userId) => dispatch(fetchProfileGeneral(userId)),
        onUpdateProfileGeneral: (userId, data) => dispatch(updateProfileGeneral(userId, data)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('profile', fieldName, fieldValue)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onShowNotice: (textMsg) => dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, textMsg)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfileGeneral);
