import React, {Component} from 'react';
import {connect} from "react-redux";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {fetchBookings} from "../../../services/bookingService";
import {GridCol} from "../../../../components/collections/grid";
import {Link} from "react-router-dom";
import {Widget, WidgetNoResult} from "../../../../components/collections/widget";
import {FormattedMessage} from "react-intl";

class Treatments extends Component {
    componentDidMount() {
        this.props.onListSorts({completedAt: 'desc'});
        this.props.onListFilterField("statuses", "COMPLETED");
        this.props.onFetchBookings(1);
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    render() {
        const {list: {data}} = this.props;
        const {treatments = []} = data;
        return (
            <Widget title={<FormattedMessage id="widget.patient.treatment.title"/>} linkTo={'/patient/treatment'} maxHeight={300} minHeight={200} marginMinus={30}>
                {treatments.length == 0 && <WidgetNoResult><FormattedMessage id="treatments.list.empty"/></WidgetNoResult>}
                {treatments.length > 0 &&
                <div className="row link-blocks-row blocks-hover-bg blocks-no-space blocks-bg-white">
                    {treatments.filter((value, index) => index < 4).map(treatment => {
                            const {consultation = {}, symptoms} = treatment;
                            const {diagnosis = ""} = consultation || {};
                            return (
                                <GridCol count="1" key={treatment.id}>
                                    <div className="link-block-wrapper">
                                        <Link to={'/patient/treatment/' + treatment.id + '/detail'}
                                              className="link-block"></Link>
                                        <div className="row align-items-center">
                                            <div className="col min-width-0">
                                                <div>{diagnosis || ""}</div>
                                                <div className="small-text color-gray text-ellipsis">
                                                    {symptoms && symptoms.map(symptom => symptom.name).join(", ")}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <span className="color-gray big-text">&rsaquo;</span>
                                            </div>
                                        </div>
                                    </div>
                                </GridCol>
                            );
                        }
                    )}
                </div>}
            </Widget>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitList: () => dispatch(listInitAction("treatments")),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber, "treatments")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("treatments", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("treatments", sorts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Treatments);
