import React, {Component} from 'react';
import {connect} from "react-redux";
import EditPatientProfile from "../patient/profile";
import {EmptyTag} from "../../../components/elements/emptyTag";
import EditDoctorProfile from "../doctor/profile";
import EditCommonProfile from "./profile";

class EditProfile extends Component {
    render() {
        const {auth: {user}} = this.props;

        return (
            <EmptyTag>
                {user.role === 'PATIENT' && <EditPatientProfile/>}
                {user.role === 'DOCTOR' && <EditDoctorProfile/>}
                {(user.role === 'ASSISTANT' || user.role === 'PHARMACY') && <EditCommonProfile/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
