import {NOTICE_HIDE, NOTICE_SHOW} from "../constants/actionTypes";
import {NOTICE_AREA, NOTICE_STATUS} from "../constants/system";

export const noticeShowAction = (title, textMsg, area = NOTICE_AREA.BODY, status = NOTICE_STATUS.INFO) => ({
    type: NOTICE_SHOW,
    title: title,
    textMsg: textMsg,
    area: area,
    status: status
});

export const noticeHideAction = (area = NOTICE_AREA.BODY) => ({
    type: NOTICE_HIDE,
    area: area
});