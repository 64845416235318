import React, {Component} from 'react';
import {connect} from "react-redux";
import {modalHide} from "../../services/system/modalService";
import {Header} from "../../../components/elements/header";
import {Modal} from "../../../components/modules/modal";
import {fetchBooking} from "../../services/bookingService";
import {tabChangePanelAction} from "../../../actions/tabAction";
import {Tab, TabPane, Tabs} from "../../../components/modules/tab";
import {GridCol} from "../../../components/collections/grid";
import BookingDetailDescription from "../doctor/bookings/detail/BookingDetailDescription";
import BookingDetailPrescription from "../doctor/bookings/detail/BookingDetailPrescription";
import BookingDetailAdvice from "../doctor/bookings/detail/BookingDetailAdvice";
import BookingDetailNote from "../doctor/bookings/detail/BookingDetailNote";
import {FormattedMessage} from "react-intl";

class BookingDetail extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {form: {data: formData}} = this.props;
        const {bookingDetail} = formData;
        const {bookingId = "", tabName = "description"} = bookingDetail || {};
        this.props.onFetchBooking(bookingId);
        this.props.onChangeTabPanel(tabName);
    }

    onChange(event) {

    }

    render() {
        const {form: {data: formData}, tab: {data: tabData}} = this.props;
        const {booking = {}} = formData;
        const {booking: tabBooking = {}} = tabData;
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('bookingDetail')} width={1000}>

                <Header as="h2"><FormattedMessage id="doctor.create-appointment.header"/></Header>
                <div className="row content-heading align-items-center">
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab onClick={() => this.props.onChangeTabPanel("description")}
                                 active={tabBooking.activeTabPanel === 'description'}><FormattedMessage id="core.tab.description.label"/></Tab>
                            {(booking.status === 'APPROVED' || booking.status === 'COMPLETED') &&
                            <Tab onClick={() => this.props.onChangeTabPanel("prescription")}
                                 active={tabBooking.activeTabPanel === 'prescription'}><FormattedMessage id="core.tab.prescription.label"/></Tab>}
                            {(booking.status === 'APPROVED' || booking.status === 'COMPLETED') &&
                            <Tab onClick={() => this.props.onChangeTabPanel("advices")}
                                 active={tabBooking.activeTabPanel === 'advices'}><FormattedMessage id="core.tab.medical-advice.label"/></Tab>}
                            {(booking.status === 'APPROVED' || booking.status === 'COMPLETED') &&
                            <Tab onClick={() => this.props.onChangeTabPanel("note")}
                                 active={tabBooking.activeTabPanel === 'note'}><FormattedMessage id="core.tab.note.label"/></Tab>}
                        </Tabs>
                    </GridCol>
                </div>

                <TabPane>
                    {tabBooking.activeTabPanel === 'description' && <BookingDetailDescription isCallBookingDetail/>}
                    {tabBooking.activeTabPanel === 'prescription' && <BookingDetailPrescription/>}
                    {tabBooking.activeTabPanel === 'advices' && <BookingDetailAdvice/>}
                    {tabBooking.activeTabPanel === 'note' && <BookingDetailNote/>}
                </TabPane>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth,
        tab: store.tab,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("booking", tabPanelName)),
        onFetchBooking: (id) => dispatch(fetchBooking(id)),
        onModalHide: (name) => dispatch(modalHide(name)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail);