import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Link, withRouter} from "react-router-dom";
import {Header} from "../../../../components/elements/header";
import {FormattedMessage} from "react-intl";
import {
    fetchAppointmentTemplate,
    fetchAppointmentTemplates,
    removeAppointmentTemplate
} from "../../../services/configureDoctorScheduleService";
import {isModal} from "../../../../utils/app";
import NewAppointmentTemplate from "./NewAppoinmentTemplate";
import {modalShow} from "../../../services/system/modalService";

import {ButtonIcon, Button} from "../../../../components/elements/button";
import classnames from "classnames";
import ApplyAppointmentTemplate from "./ApplyAppointmentTemplate";
import {Icon, IconItem} from "../../../../components/elements/icon";
import ScheduleViewWrapper from "./ScheduleViewWrapper";
import qs from "query-string";
import {fetchSystemSettings} from "../../../services/commonService";
import {BackTo} from "../../../../components/elements/backTo";
import {Redirect} from "react-router";

class ConfigureDoctorSchedule extends Component {
    constructor(props) {
        super(props);
        this.onApplyAppointmentTemplate = this.onApplyAppointmentTemplate.bind(this);
        this.onNewAppointmentTemplate = this.onNewAppointmentTemplate.bind(this);
        this.onEditAppointmentTemplate = this.onEditAppointmentTemplate.bind(this);
    }

    componentDidMount() {
        const {auth: {user}} = this.props;
        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);

        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        this.props.onFetchAppointmentTemplates(doctorId);
        this.props.onfetchSystemSettings();
    }

    onNewAppointmentTemplate() {
        const {auth: {user}} = this.props;
        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);
        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        this.props.onModalShow('newAppointmentTemplate', {doctor: {id: doctorId}});
    }

    onEditAppointmentTemplate(id) {
        const {auth: {user}} = this.props;
        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);
        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        this.props.onFetchAppointmentTemplate(
            id,
            "newAppointmentTemplate",
            (data) => {
                this.props.onModalShow("newAppointmentTemplate", {...data, doctor: {id: doctorId}})
            });
    }

    onApplyAppointmentTemplate(appointmentTemplate) {
        this.props.onModalShow("applyAppointmentTemplate", {
            "appointmentTemplate": appointmentTemplate
        });
    }

    render() {
        const {form: {data: formData}, list: {data: listData}, modal, auth: {user}} = this.props;
        if (user.role === 'DOCTOR' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }
        const {appointmentTemplates = []} = listData;
        const {systemSettings = {}} = formData;
        const {paymentCurrency} = systemSettings || {};
        const {profileAccountEmail = {}} = formData

        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);
        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    {user.role == 'ADMIN' &&
                    <div className="col-auto content-heading__back">
                        <BackTo to="/admin/users"/>
                    </div>}
                    <div className="col content-heading__main">
                        {(user.role === 'ADMIN' || user.role === 'ASSISTANT') && <Header as="h1">
                            <FormattedMessage id="configure.any.doctor.schedule" values={{
                                firstName: profileAccountEmail.firstName,
                                lastName: profileAccountEmail.lastName
                            }}/>
                        </Header>}
                        {user.role === 'DOCTOR' && <Header as="h2"><FormattedMessage id="configure.doctor.schedule"/></Header>}

                    </div>
                    <div className="col-auto content-heading__controls">
                        <Link to={'/doctor/schedule/settings'}>
                            <IconItem size={100} name="settings-black"></IconItem>
                        </Link>
                    </div>
                </div>

                <div className="row content-row">
                    <div className="col-12">
                        <div className="white-block">
                            <div className="margin-bottom-15">
                                <h3>
                                    <FormattedMessage id="appointment.types"/>
                                    <ButtonIcon icon={'plus-bordered'}
                                                onClick={() => this.onNewAppointmentTemplate()}></ButtonIcon>
                                </h3>
                            </div>

                            {appointmentTemplates.length == 0 && <div className="no-results-block">
                                <Icon name="calendar-middle"/>
                                <div><FormattedMessage id="empty.list.appointment.types.description"/></div>
                                <Button onClick={() => this.onNewAppointmentTemplate()}>
                                    <FormattedMessage id="add.appointment.type"/>
                                </Button>
                            </div>}
                            {appointmentTemplates.length > 0 &&
                            <div className="row color-template-items-row">
                                {appointmentTemplates.map(appointmentTemplate => {
                                        const {
                                            id,
                                            name,
                                            duration,
                                            address,
                                            price,
                                            priceRequest,
                                            appointmentTemplateTypes = [],
                                            color,
                                            isCanApply
                                        } =
                                            appointmentTemplate;
                                        const isOffline =
                                            appointmentTemplateTypes.findIndex(i => i.typeConsultation === 'OFFLINE') !== -1;
                                        const isChat =
                                            appointmentTemplateTypes.findIndex(i => i.typeConsultation === 'CHAT') !== -1;
                                        const isVideo =
                                            appointmentTemplateTypes.findIndex(i => i.typeConsultation === 'VIDEO') !== -1;
                                        const classNames = classnames('color-template-item', 'color-template-' + color.code);
                                        return (
                                            <div className="col-md-4" key={id}>
                                                <div className="color-template-item-wrapper">
                                                    <div className={classNames}>
                                                        <div className="row c-t-item-content-row">
                                                            <div className="col c-t-item-text">
                                                                <div className="bold">{name}</div>
                                                                <div>{duration} <FormattedMessage id="min"/></div>
                                                                {(price || priceRequest) &&
                                                                <div>
                                                                    {price &&
                                                                    <EmptyTag>{paymentCurrency} {price}&nbsp;</EmptyTag>}
                                                                    {priceRequest && <EmptyTag>
                                                                        {priceRequest.status === 'REQUESTED' &&
                                                                        <span className="color-orange">
                                                                        ({paymentCurrency} {priceRequest.price}&nbsp;
                                                                            <FormattedMessage
                                                                                id="request.update.price.consultation.status.requested.label"/>)
                                                                    </span>}
                                                                        {priceRequest.status === 'APPROVED' &&
                                                                        <span className="color-green">
                                                                        ({paymentCurrency} {priceRequest.price}&nbsp;
                                                                            <FormattedMessage
                                                                                id="request.update.price.consultation.status.approved.label"/>)
                                                                    </span>}
                                                                        {priceRequest.status === 'CANCELED' &&
                                                                        <span className="color-red">
                                                                        ({paymentCurrency} {priceRequest.price}&nbsp;
                                                                            <FormattedMessage
                                                                                id="request.update.price.consultation.status.canceled.label"/>)
                                                                    </span>}
                                                                    </EmptyTag>}
                                                                </div>}
                                                                {address &&
                                                                <EmptyTag>
                                                                    {!address.name && <div>{address.text}</div>}
                                                                    {address.name &&
                                                                    <div>{address.name + " - " + address.text}</div>}
                                                                </EmptyTag>}
                                                            </div>
                                                            <div className="col-auto c-t-item-types">
                                                                {isVideo &&
                                                                <i className="icon icon--circle-event-online"
                                                                   title="Online"></i>}
                                                                {isChat &&
                                                                <i className="icon icon--circle-event-chat"
                                                                   title="Chat"></i>}
                                                                {isOffline &&
                                                                <i className="icon icon--circle-event-offline"
                                                                   title="Offline"></i>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="color-template-controls">
                                                        <ButtonIcon
                                                            icon="calendar-add"
                                                            disabled={!isCanApply}
                                                            onClick={() => this.onApplyAppointmentTemplate(appointmentTemplate)}>
                                                        </ButtonIcon>
                                                        <ButtonIcon
                                                            icon="edit-black"
                                                            onClick={() => this.onEditAppointmentTemplate(id)}>
                                                        </ButtonIcon>
                                                        <ButtonIcon
                                                            icon="trash-red"
                                                            onClick={() => this.props.onConfirm({
                                                                text: <FormattedMessage
                                                                    id="confirm.remove.appointment.template"/>,
                                                                onProceed: (() => this.props.onRemoveAppointmentTemplate(id))
                                                            })}>
                                                        </ButtonIcon>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                )}


                            </div>}
                        </div>
                    </div>

                    <ScheduleViewWrapper userId={doctorId}/>

                </div>
                {isModal(modal, 'newAppointmentTemplate') && <NewAppointmentTemplate/>}
                {isModal(modal, 'applyAppointmentTemplate') && <ApplyAppointmentTemplate/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onRemoveAppointmentTemplate: (id) => dispatch(removeAppointmentTemplate(id)),
        onFetchAppointmentTemplate: (id, formName, callback) => dispatch(fetchAppointmentTemplate(id, formName, callback)),
        onFetchAppointmentTemplates: (doctorId) => dispatch(fetchAppointmentTemplates(doctorId)),
        onModalShow: (modalName, modalData) => dispatch(modalShow(modalName, modalData)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfigureDoctorSchedule));
