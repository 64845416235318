import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../components/collections/grid";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import {Button} from "../../../../components/elements/button";
import {AutocompleteAsync} from "../../../systems/autocomplete";
import {searchDoctors} from "../../../services/bookingService";
import {formInit} from "../../../services/system/formService";
import {listInitAction} from "../../../../actions/listAction";
import {isModal} from "../../../../utils/app";
import DoctorBookingsByStatus from "../../doctor/bookings/list";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import UnverifiablePaymentRefund from "../../doctor/bookings/detail/payment/UnverifiablePaymentRefund";

import qs from "query-string";
import {privateApi} from "../../../../utils/api";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../actions/preloaderAction";
import {Link} from "react-router-dom";

class DoctorBookings extends Component {
    constructor(props) {
        super(props);
        this.onChangeDoctor = this.onChangeDoctor.bind(this);
        this.onShowBookingDetail = this.onShowBookingDetail.bind(this);
        this.props.onSearchDoctors(null, "");

    }

    onShowBookingDetail(doctorId, bookingId) {
        if (doctorId != null && bookingId != null) {
            (async () => {
                this.props.onPageShowPreloader();
                const responseDoctor = await privateApi.get("/doctors/" + doctorId);
                this.onChangeDoctor(null, responseDoctor.data);

                const responseBooking = await privateApi.get("/bookings/" + bookingId);
                this.props.onModalHide('assistantPatientDetail');
                this.props.onModalShow('assistantPatientDetail', {booking: responseBooking.data});
                this.props.onPageHidePreloader();
            })();
        }
    }

    componentDidMount() {
        const p = this.props.location.search;
        const {doctorId = null, bookingId = null} = qs.parse(p);
        this.onShowBookingDetail(doctorId, bookingId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const pCur = this.props.location.search;
        const {reload: curReload = "", bookingId: curBookingId = null, doctorId: curDoctorId = null} = qs.parse(pCur);

        const pPrev = prevProps.location.search;
        const {
            reload: prevReload = "",
            bookingId: prevBookingId = null,
            doctorId: prevDoctorId = null
        } = qs.parse(pPrev);

        if (curBookingId != prevBookingId || curReload != prevReload || curDoctorId != prevDoctorId) {
            this.onShowBookingDetail(curDoctorId, curBookingId);
        }
    }

    onChangeDoctor(event, value) {
        this.props.onFormInit("chooseDoctor", value);
        this.props.onChangeTabPanel("waiting");
    }

    componentWillUnmount() {
        this.props.onInitList("bookings");
        this.props.onChangeTabPanel("waiting");
    }

    getOptionLabel(option) {
        const specialization = option.doctor && option.doctor.specialization ? ', ' + option.doctor.specialization.name : "";
        return option != null ? option.firstName + ' ' + option.lastName + specialization : "";
    }

    render() {
        const {tab: {data: tabData}, list: {data: listData}, form: {data: formData}, modal} = this.props;
        const {doctorBookings: tabDoctorBookings = {}} = tabData;
        const {activeTabPanel: doctorBookingsActiveTabPanel = "waiting"} = tabDoctorBookings || {};

        const {doctors = []} = listData;
        const {chooseDoctor} = formData;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.assistant.patients.header"/></Header>
                    </div>
                    {chooseDoctor &&
                    <div className="col-auto content-heading__controls">
                        <Link to={'/assistant/doctors/' + chooseDoctor.id + '/schedule'}>
                            <Button>
                                <FormattedMessage id="core.btn.configure.schedule.label"/></Button>
                        </Link>
                    </div>}
                </div>
                <div className="row content-heading">
                    <GridCol count="1">
                        <div className="max-width-500">
                            <AutocompleteAsync onInputChange={this.props.onSearchDoctors}
                                               value={chooseDoctor || null}
                                               getOptionLabel={option => this.getOptionLabel(option)}
                                               onChange={this.onChangeDoctor}
                                               placeholder={<FormattedMessage id="core.choose.doctor.label"/>}
                                               placeholderFixed={true}
                                               error={null}
                                               style={{width: 500}}
                                               options={doctors}/>
                        </div>
                    </GridCol>
                </div>
                {chooseDoctor != null && <EmptyTag>
                    <div className="row content-heading">
                        <GridCol count="1">
                            <Tabs showAs="links">
                                <Tab active={doctorBookingsActiveTabPanel === 'waiting'}
                                     onClick={() => this.props.onChangeTabPanel("waiting")}><FormattedMessage
                                    id="doctor.patients.waiting"/></Tab>

                                <Tab active={doctorBookingsActiveTabPanel === 'approved'}
                                     onClick={() => this.props.onChangeTabPanel("approved")}><FormattedMessage
                                    id="doctor.patients.approved"/></Tab>

                                <Tab active={doctorBookingsActiveTabPanel === 'rejected'}
                                     onClick={() => this.props.onChangeTabPanel("rejected")}><FormattedMessage
                                    id="doctor.patients.cancelled"/></Tab>

                                <Tab active={doctorBookingsActiveTabPanel === 'consulted'}
                                     onClick={() => this.props.onChangeTabPanel("consulted")}><FormattedMessage
                                    id="doctor.patients.consulted"/></Tab>
                            </Tabs>
                        </GridCol>
                    </div>
                    <div className="row content-row">
                        {doctorBookingsActiveTabPanel === 'waiting' &&
                        <DoctorBookingsByStatus status="REQUESTED" doctor={chooseDoctor} sort={'timeOfReceipt,asc'}/>}

                        {doctorBookingsActiveTabPanel === 'approved' &&
                        <DoctorBookingsByStatus status="APPROVED" doctor={chooseDoctor} sort={'timeOfReceipt,asc'}/>}

                        {doctorBookingsActiveTabPanel === 'rejected' &&
                        <DoctorBookingsByStatus status="REJECTED" doctor={chooseDoctor} sort={'timeOfReceipt,desc'}/>}

                        {doctorBookingsActiveTabPanel === 'consulted' &&
                        <DoctorBookingsByStatus status="COMPLETED" doctor={chooseDoctor} sort={'timeOfReceipt,desc'}/>}
                    </div>
                </EmptyTag>}
                {isModal(modal, 'unverifiablePaymentRefund') && <UnverifiablePaymentRefund/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onModalShow: (modalName, modalData) => dispatch(modalShow(modalName, modalData)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFormInit: (formName, data) => dispatch(formInit(formName, data)),
        onSearchDoctors: (event, query, reason) => dispatch(searchDoctors(query)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("doctorBookings", tabPanelName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorBookings);