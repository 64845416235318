import React, {Component} from 'react';
import {connect} from "react-redux";

import {Modal} from "../../../components/modules/modal";
import {Header} from "../../../components/elements/header";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Button, Buttons} from "../../../components/elements/button";
import {modalHide} from "../../services/system/modalService";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

class Confirm extends Component {
    render() {
        const {form: {data}} = this.props;
        const {confirm = {}} = data;
        const {text, onCancel = "", onProceed, labelCancel = false, labelProceed = false, advanceContent = null} = confirm || {};
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('confirm')}>
                <Header as="h2">{text}</Header>
                <GridRow>
                    {advanceContent || ""}
                    <GridCol count="1">
                        <Buttons width="auto">
                            <Link to="#" onClick={onCancel || (() => this.props.onModalHide('confirm'))}>
                                <Button color='white-red'>{labelCancel || <FormattedMessage id="core.btn.no.label"/>}</Button>
                            </Link>
                            <Button onClick={onProceed}>{labelProceed || <FormattedMessage id="core.btn.yes.label"/>}</Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);