import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {saveMedicalTest} from "../../../../services/consultationService";
import {formChangeField} from "../../../../services/system/formService";
import {Input} from "../../../../../components/elements/input";
import {Button, Buttons} from "../../../../../components/elements/button";
import {Modal} from "../../../../../components/modules/modal";
import {modalHide} from "../../../../services/system/modalService";
import {FormattedMessage} from "react-intl";

class NewMedicalTest extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('medicalTest', event);
    }

    render() {
        const {form: {data, errors: formErrors, isSend: formIsSend}} = this.props;
        const errors = formErrors.medicalTest;
        const disabled = formIsSend.medicalTest || false;
        const {booking: {consultation = {}}, medicalTest = {}} = data;

        return (
            <Modal close={true} onClose={() => this.props.onModalHide()}>
                <GridRow>
                    <GridCol count="1">
                        <Input name="name"
                               placeholder={<FormattedMessage id="medical.test.report.name"/>}
                               error={(errors && errors.name) || null}
                               disabled={disabled}
                               onChange={this.onChange}
                               value={medicalTest.name || ""}/>
                    </GridCol>
                    <GridCol count="1">
                        <Input name="description"
                               placeholder={<FormattedMessage id="medical.test.description"/>}
                               error={(errors && errors.description) || null}
                               disabled={disabled}
                               onChange={this.onChange}
                               value={medicalTest.description || ""}/>
                    </GridCol>
                    <GridCol count="1">
                        <Buttons>
                            <Button disabled={disabled}
                                    preloader={disabled === true}
                                    onClick={() => this.props.onSaveMedicalTest(consultation, medicalTest)}><FormattedMessage id="core.btn.save.label"/></Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: () => dispatch(modalHide('medicalTest')),
        onSaveMedicalTest: (consultation, medicalTest) => dispatch(saveMedicalTest(consultation, medicalTest)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewMedicalTest);
