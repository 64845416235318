import React, {Component} from 'react';
import {connect} from "react-redux";
import Pagination from "react-js-pagination";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import DatepicterRange from "../../../systems/datepicterRange";
import {fetchCalls} from "../../../services/callService";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";


class EmergencyCalls extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.props.onInitList("emergencyCalls");
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        this.props.onListFilterField("type", "EMERGENCY_CALL");
        this.props.onListSorts({'id': 'desc'});
        this.props.onFetchCalls(pageNumber);
    }

    componentWillUnmount() {
        this.props.onInitList("emergencyCalls");
    }

    render() {
        const {list: {data: listData, pagination: listPagination}} = this.props;
        const {emergencyCalls: calls = []} = listData;
        const {number = 0, size = 0, totalElements = 0} = listPagination['emergencyCalls'] || {};
        return (
            <EmptyTag>
                <div className="row form-elements">
                    <div className="col-auto">
                        <div className="row small-form-row align-items-center filtr-form">
                            <DatepicterRange fieldNameFrom={'createdAtFrom'}
                                             fieldNameTo={'createdAtTo'}
                                             labelFrom={'created.from.label'}
                                             labelTo={'created.to.label'}
                                             onListFilterField={this.props.onListFilterField}
                                             onPagination={this.onPagination}/>
                        </div>
                    </div>
                </div>

                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th><FormattedMessage id="createdAt"/></th>
                            <th><FormattedMessage id="doctor"/></th>
                            <th><FormattedMessage id="patient"/></th>
                            <th><FormattedMessage id="sessionId"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {calls.map(({participants = [], createdAt, sessionId, id}) => {
                            const doctor = participants.filter(i => i.user.role == 'DOCTOR').map(i => i.user).pop() || null;
                            const patient = participants.filter(i => i.user.role == 'PATIENT').map(i => i.user).pop() || null;
                            return (
                                <tr key={id}>
                                    <td>{moment(createdAt).format("lll")}</td>
                                    <td>
                                        {doctor && doctor.firstName + ' ' + doctor.firstName}
                                    </td>
                                    <td>
                                        {patient && patient.firstName + ' ' + patient.firstName}
                                    </td>
                                    <td>{sessionId}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}

                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onListSorts: (sorts) => dispatch(listSortAction("emergencyCalls", sorts)),
        onFetchCalls: (pageNumber) => dispatch(fetchCalls(pageNumber, "emergencyCalls")),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("emergencyCalls", fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyCalls);