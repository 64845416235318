import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

import {floatFormatting} from "../../../../../utils/app";
import ReportInfo from "../ReportInfo";
import InfoPeriod from "../InfoPeriod";

class ReportRemittanceInfo extends Component {

    render() {
        const {form: {data: formData}, dateFrom, dateTo} = this.props;
        const {reportParams, systemSettings} = formData || {};
        const {paymentCurrency} = systemSettings || {};
        const {totalRemittanceAmount = null, totalConsultationAmount = null} = reportParams || {};

        return (
            <div className="col-md-7 padding-bottom-0">
                <div className="row tile-row">
                    <div className="col-md-6">
                        <InfoPeriod title={<FormattedMessage id="report.period.value.label"/>}
                                    dateFrom={dateFrom} dateTo={dateTo}/>
                    </div>
                    <div className="col-md-6">
                        <ReportInfo title={<FormattedMessage id="report.totals.remittance.amount"
                                                             values={{currency: paymentCurrency}}/>}
                                    info={floatFormatting(totalRemittanceAmount)}/>
                    </div>
                    <div className="col-md-6">
                        <ReportInfo title={<FormattedMessage id="report.totals.consultation.amount"
                                                             values={{currency: paymentCurrency}}/>}
                                    info={floatFormatting(totalConsultationAmount)}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportRemittanceInfo);