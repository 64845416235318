import React, {Component} from 'react';
import {connect} from "react-redux";

import * as filesize from "filesize";
import {Modal} from "../../../../components/modules/modal";
import {GridCol, GridRow} from "../../../../components/collections/grid";
import {Textarea} from "../../../../components/elements/textarea";
import {Button} from "../../../../components/elements/button";
import {Header} from "../../../../components/elements/header";
import {modalHide} from "../../../services/system/modalService";
import {formChangeField} from "../../../services/system/formService";
import {FormattedMessage} from "react-intl";

class UploadFileDescription extends Component {
    constructor(props) {
        super(props);
        this.onChangeDescription = this.onChangeDescription.bind(this);
    }

    onChangeDescription(event) {
        this.props.onFormChangeField('uploadFileDescription', event);
    }

    render() {
        const {onModalHide, form: {data}} = this.props;
        const {uploadFileDescription: {files, onSend, description = ''}} = data;
        return (
            <Modal close={true} onClose={() => onModalHide('uploadFileDescription')}>
                <Header as="h2">{files[0].name} ({filesize(files[0].size)})</Header>
                <div className="row form-elements">
                    <GridRow>
                        <GridCol count='1'>
                                <Textarea placeholder={<FormattedMessage id="core.description.label"/>}
                                          name="description"
                                          value={description}
                                          disabled={false}
                                          onChange={this.onChangeDescription}/>
                        </GridCol>
                        <GridCol count='1'>
                            <div className="form-submit btns-width-auto">
                                <Button color={"white-red"} onClick={() => onModalHide('uploadFileDescription')}>
                                    <FormattedMessage id="core.btn.cancel.label"/>
                                </Button>
                                <Button onClick={onSend}><FormattedMessage id="core.btn.send.label"/></Button>
                            </div>
                        </GridCol>
                    </GridRow>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFileDescription);
