import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import MedicalRecordContent from "./MedicalRecordContent";
import ChooseMemberSelect from "../family/ChooseMemberSelect";
import {formInitAction} from "../../../../actions/formAction";
import {Redirect} from "react-router";

class MedicalRecord extends Component {
    componentDidMount() {
        const {auth: {user}} = this.props;
        this.props.onInitForm("chooseMemberSelect", {"member": user});
    }

    render() {
        const {form: {data: formData}, auth: {user}} = this.props;
        if (user.role === 'PATIENT' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }

        const {chooseMemberSelect = {}} = formData;
        const {member = null} = chooseMemberSelect;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    {!user.familyMember && <EmptyTag>
                        <div className="col-md-6">
                            <ChooseMemberSelect/>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </EmptyTag>}
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.medical.record.header"/></Header>
                    </div>
                </div>
                {member && <MedicalRecordContent patient={member}/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecord);
