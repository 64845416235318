import React from 'react';
import classnames from "classnames";
import {InputError} from "../error";

export const RadioBoxes = (props) => {
    const {error = null} = props;
    const blockClass = classnames('form-labels', {
        'block-error': (error !== null)
    });
    return (
        <div className={blockClass}>
            {props.children}
            {error && <InputError>{error}</InputError>}
        </div>
    );
};

export const RadioBox = (props) => {
    const {color = '', children, asButton = false, colorSquare = '', isSquare = false, isSquareColor = false, ...rests} = props;
    const blockClass = classnames('form-label', 'color-template-' + colorSquare, {
        'button-label': (asButton),
        'button-label-blue': color === 'blue',
        'square-label': isSquare,
        'square-label-color' : isSquareColor
    });
    return (
        <label className={blockClass}>
            <input type="radio" {...rests} />
            <span><i>{children}</i></span>
        </label>
    );
};

export const RadioBoxSimple = (props) => {
    const {children, ...rests} = props;

    return (
        <label className="label-simple-check">
            {children}
            <input type="radio" {...rests} />
            <span className="position-static"></span>
        </label>
    );
};