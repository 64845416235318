import React from 'react';
import {connect} from "react-redux";

import {MenuItem} from "../../../components/collections/menu";
import 'react-notifications-component/dist/theme.css';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";

class AdminMainMenu extends React.Component {

    render() {
        const {auth: {user}} = this.props;
        return (
            <EmptyTag>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/users'} icon="manage-users"
                              activeClassName="active"><FormattedMessage
                        id="manage.users"/></MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/calls'} icon="provider"
                              activeClassName="active"><FormattedMessage id="call.sessions"/></MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/settings'} icon="settings"
                              activeClassName="active"><FormattedMessage id="page.settings"/></MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/consultationsRates'} icon="approvals">
                        <FormattedMessage id="core.layout.sidebar.item.approvals.label"/></MenuItem>
                </div>

                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/commissions'} icon="specialization"><FormattedMessage
                        id="core.layout.sidebar.item.doctors.and.specialization.label"/></MenuItem>
                </div>

                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/recipients'} icon="email-receipeints"><FormattedMessage
                        id="core.layout.sidebar.item.email.recipients.label"/></MenuItem>
                </div>

                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/paymentSettings'} icon="payment-options"><FormattedMessage
                        id="core.layout.sidebar.item.payment.label"/></MenuItem>
                </div>

                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/bookings'} icon="bookings-payments"
                              activeClassName="active"><FormattedMessage
                        id="bookings.and.payments"/></MenuItem>
                </div>

                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/doctorReviews'} icon="approvals">
                        <FormattedMessage id="doctor.reviews"/>
                    </MenuItem>
                </div>

                {user.role === 'ADMIN' &&
                    user.userAdminParams &&
                    user.userAdminParams.isHasAccessConsultationRecording &&
                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/consultationRecordings'} icon="recordings"
                              activeClassName="active"><FormattedMessage
                        id="consultation.recordings"/></MenuItem>
                </div>}

                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/currencyRates'} icon="currency-rates">
                        <FormattedMessage id="currency.rates"/>
                    </MenuItem>
                </div>
            </EmptyTag>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminMainMenu);
