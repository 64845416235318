import React from 'react';
import {connect} from "react-redux";

import 'react-notifications-component/dist/theme.css';

class EmptyLayout extends React.Component {
    render() {
        return (
            this.props.children
        );
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(EmptyLayout);