import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import French from './lang/fr-FR.json';
import FrenchHT from './lang/fr-HT.json';
import English from './lang/en-GB.json';
import Spanish from './lang/es-DO.json';
import Russian from './lang/ru.json';
import Portuguese from './lang/pt-PT.json';
import {connect} from "react-redux";
import {changeLanguage, defineSystemLanguage} from "./containers/services/commonService";

class IntlProviderWrapper extends Component {

    componentDidMount() {
        const locale = window.location.search.split('lang=')[1] || "";
        if (locale != "") {
            this.props.onChangeLanguage(locale);
        } else {
            this.props.onDefineSystemLanguage();
        }
    }

    render() {
        const {language: {locale}} = this.props;
        let messages;
        switch (locale) {
            case "fr-FR" :
                messages = French;
                break;
            case "fr-HT" :
                messages = FrenchHT;
                break;
            case "es-DO" :
                messages = Spanish;
                break;
            case "pt-PT" :
                messages = Portuguese;
                break;
            case "ru" :
                messages = Russian;
                break;
            default:
                messages = English;
        }
        if (!locale) return "";
        return (
            <IntlProvider messages={messages} locale={locale}>
                {this.props.children}
            </IntlProvider>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        language: store.language
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDefineSystemLanguage: () => dispatch(defineSystemLanguage()),
        onChangeLanguage: (locale) => dispatch(changeLanguage(locale))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntlProviderWrapper);