import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../components/modules/modal";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {withRouter} from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import {formChangeField} from "../../../services/system/formService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";

import {Button, Buttons} from "../../../../components/elements/button";
import {Option, Select} from "../../../../components/elements/select";
import {
    createPaymentSetting,
    fetchCurrencies, fetchNotConfigureProviders, fetchPaymentGateways,
    fetchPaymentMethods
} from "../../../services/paymentService";
import {fetchCountries} from "../../../services/commonService";
import {listInitAction} from "../../../../actions/listAction";


class CreatePaymentSetting extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangePaymentMethod = this.onChangePaymentMethod.bind(this);
        this.state = {paymentMethod : null}
    }

    componentDidMount() {
        const {form} = this.props;
        const createPaymentSetting = form.data.createPaymentSetting || {};
        this.props.onFetchPaymentMethods();
        this.props.onFetchCountries();
        this.props.onFetchCurrencies();
        this.props.onFetchPaymentGateways();
        if (createPaymentSetting.paymentProvider) {
            this.setState({...this.state, paymentMethod: createPaymentSetting.paymentProvider.paymentMethod});
            this.props.onFetchNotConfigureProviders(createPaymentSetting.paymentProvider.paymentMethod.id);
        }
    }

    onChange(event) {
        this.props.onFormChangeField('createPaymentSetting', event);
    }

    onChangeSelect(fieldName, fieldValue) {
        this.props.onChangeDropdown(fieldName, fieldValue);
    }

    onChangePaymentMethod(paymentMethod) {
        this.setState({...this.state, paymentMethod: paymentMethod})
        this.props.onFetchNotConfigureProviders(paymentMethod.id);
        this.props.onFormSimpleChange('createPaymentSetting', "paymentProvider", null)
    }

    componentWillUnmount() {
        this.props.onInitForm("createPaymentSetting");
        this.props.onInitList("paymentMethods");
        this.props.onInitList("paymentProviders");
        this.props.onInitList("paymentGateways");
        this.props.onInitList("countries");
        this.props.onInitList("currencies");
    }

    render() {
        const {form, list: {data: listData}} = this.props;
        const {
            paymentMethods = [],
            paymentGateways = [],
            countries = [],
            currencies = [],
            paymentProviders = []
        } = listData
        const errors = form.errors.createPaymentSetting;
        const createPaymentSetting = form.data.createPaymentSetting || {};

        const countryIndexSelected = createPaymentSetting.countryIso != null ?
            countries.findIndex(i => i.iso == createPaymentSetting.countryIso) : -1;
        const countrySelected = countryIndexSelected > -1 ? countries[countryIndexSelected] : null;
        return (
            <Modal width={800} close={true} onClose={() => this.props.onModalHide('createPaymentSetting')}>
                <h2><FormattedMessage id="payment.setting"/></h2>

                <div className="row form-elements">
                    <div className="col-md-6">
                        <Select name="paymentMethod"
                                required={true}
                                disabled={createPaymentSetting.id != null}
                                label={<FormattedMessage id="payment.method"/>}
                                optionNameSelected={(this.state.paymentMethod && this.state.paymentMethod.name) || ''}>
                            {
                                paymentMethods.map(i =>
                                    <Option
                                        active={this.state.paymentMethod && this.state.paymentMethod.id === i.id}
                                        key={'payment-method-' + i.id}
                                        onClick={() => this.onChangePaymentMethod(i)}>
                                        {i.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>

                    <div className="col-md-6">
                        <Select name="paymentProvider"
                                required={true}
                                disabled={createPaymentSetting.id != null}
                                error={(errors && errors.paymentProvider) || null}
                                label={<FormattedMessage id="payment.provider"/>}
                                optionNameSelected={(createPaymentSetting.paymentProvider && createPaymentSetting.paymentProvider.name) || ''}>
                            {
                                paymentProviders.map(i =>
                                    <Option
                                        active={createPaymentSetting.paymentProvider && createPaymentSetting.paymentProvider.id === i.id}
                                        key={'payment-provider-' + i.id}
                                        onClick={() => this.onChangeSelect("paymentProvider", i)}>
                                        {i.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>

                    <div className="col-md-6">
                        <FormattedMessage id="any">
                            {optionNameSelected =>
                        <Select name="country"
                                error={(errors && errors.countryIso) || null}
                                label={<FormattedMessage id="country"/>}
                                optionNameSelected={(countrySelected && countrySelected.name) || optionNameSelected}>
                            <Option
                                active={createPaymentSetting.countryIso == null}
                                key={'country-0'}
                                onClick={() => this.onChangeSelect("countryIso", null)}>
                                <FormattedMessage id="any"/>
                            </Option>
                            {
                                countries.map(i =>
                                    <Option
                                        active={createPaymentSetting.countryIso && createPaymentSetting.countryIso === i.iso}
                                        key={'country-' + i.id}
                                        onClick={() => this.onChangeSelect("countryIso", i.iso)}>
                                        {i.name}
                                    </Option>
                                )
                            }
                        </Select>}</FormattedMessage>
                    </div>

                    <div className="col-md-6">
                        <FormattedMessage id="country.currency">
                            {optionNameSelected =>
                                <Select name="currency"
                                        error={(errors && errors.currency) || null}
                                        label={<FormattedMessage id="currency"/>}
                                        optionNameSelected={(createPaymentSetting.currency && createPaymentSetting.currency.name) || optionNameSelected}>
                                    <Option
                                        active={createPaymentSetting.currency == null}
                                        key={'currency-0'}
                                        onClick={() => this.onChangeSelect("currency", null)}>
                                        <FormattedMessage id="country.currency"/>
                                    </Option>
                                    {
                                        currencies.map(i =>
                                            <Option
                                                active={createPaymentSetting.currency && createPaymentSetting.currency.id === i.id}
                                                key={'currency-' + i.iso}
                                                onClick={() => this.onChangeSelect("currency", i)}>
                                                {i.name}
                                            </Option>
                                        )
                                    }
                                </Select>}</FormattedMessage>
                    </div>

                    <div className="col-md-6">
                        <Select name="paymentGateway"
                                required={true}
                                error={(errors && errors.paymentGateway) || null}
                                label={<FormattedMessage id="payment.gateway"/>}
                                optionNameSelected={(createPaymentSetting.paymentGateway && createPaymentSetting.paymentGateway.name) || ''}>

                            {
                                paymentGateways.map(i =>
                                    <Option
                                        active={createPaymentSetting.paymentGateway && createPaymentSetting.paymentGateway.id === i.id}
                                        key={'payment-gateway-' + i.id}
                                        onClick={() => this.onChangeSelect("paymentGateway", i)}>
                                        {i.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>

                    <div className="col-12">
                        <Buttons>
                            <Button disabled={createPaymentSetting.paymentProvider == null || createPaymentSetting.paymentGateway == null} onClick={() => this.props.onCreatePaymentSetting(createPaymentSetting)}>
                                <FormattedMessage id="core.btn.add.label"/></Button>
                        </Buttons>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFetchPaymentMethods: () => dispatch(fetchPaymentMethods()),
        onFetchCountries: () => dispatch(fetchCountries()),
        onFetchNotConfigureProviders: (paymentMethodId) => dispatch(fetchNotConfigureProviders(paymentMethodId)),
        onFetchCurrencies: () => dispatch(fetchCurrencies()),
        onFetchPaymentGateways: () => dispatch(fetchPaymentGateways()),
        onCreatePaymentSetting: (data) => dispatch(createPaymentSetting(data)),
        onInitForm: (formName, data = {}) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onModalShow: (name) => dispatch(modalShow(name)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('createPaymentSetting', fieldName, fieldValue)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePaymentSetting));
