import React, {Component} from 'react';
import {connect} from "react-redux";
import classnames from "classnames";
import moment from "moment";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import {getMessages} from "../../services/chatService";
import {formInitAction} from "../../../actions/formAction";
import {listInitAction} from "../../../actions/listAction";
import {ChatMessage} from "./ChatMessage";
import Avatar from "../avatar";

class ChatMessages extends Component {
    constructor(props) {
        super(props);
        this.mesRef = React.createRef();
        this.onPagination = this.onPagination.bind(this);
        this.onScroll = this.onScroll.bind(this)
    }

    componentDidMount() {
        const {listNameChatRoomMessages} = this.props;
        this.props.onInitForm(listNameChatRoomMessages, {});
        this.props.onInitList(listNameChatRoomMessages);
        this.onPagination();
    }

    componentWillUnmount() {
        const {listNameChatRoomMessages} = this.props;
        this.props.onInitForm(listNameChatRoomMessages, {});
        this.props.onInitList(listNameChatRoomMessages);
    }

    onScroll() {
        if (this.mesRef.current.scrollTop == 0) {
            const {list: {data: listData}, form: {data: formData}, listNameChatRoomMessages} = this.props;
            const messages = listData[listNameChatRoomMessages] || [];
            const {afterMessageId: prevAfterMessageId = null, totalElements = 0} = formData[listNameChatRoomMessages] || {};
            if (messages.length > 0 && messages.length < totalElements) {
                const afterMessageId = messages[0]["id"];
                if (prevAfterMessageId != afterMessageId) {
                    this.onPagination(afterMessageId);
                }
            }
        }
    }

    onPagination(afterMessageId = null) {
        const {form: {data: formData}, formNameChosenChatRoom, listNameChatRoomMessages} = this.props;
        const {id: roomId = null} = formData[formNameChosenChatRoom] || {};
        if (roomId)
            this.props.onGetMessages(listNameChatRoomMessages, roomId, afterMessageId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.mesRef.current.scrollTop = this.mesRef.current.scrollHeight;

        const {form: {data: curFormData}, formNameChosenChatRoom, listNameChatRoomMessages} = this.props;
        const {form: {data: prevFormData}} = prevProps;
        const curChatRoom = curFormData[formNameChosenChatRoom] || {};
        const prevChatRoom = prevFormData[formNameChosenChatRoom] || {};

        if (curChatRoom != prevChatRoom) {
            this.props.onInitForm(listNameChatRoomMessages, {});
            this.props.onInitList(listNameChatRoomMessages);
            this.onPagination();
        }
    }

    render() {
        const {list: {data: listData}, auth: {user: currentUser}, listNameChatRoomMessages} = this.props;
        const messages = listData[listNameChatRoomMessages] || [];
        const today = moment().format("ll");
        let groupByDateMessages = {};
        messages.map(message => {
            const date = moment.utc(message.created_at).local().format("ll");
            const key = date === today ? 'TODAY' : date;
            if (!groupByDateMessages[key]) groupByDateMessages[key] = [];
            groupByDateMessages[key].push(message);
        });
        const dates = Object.keys(groupByDateMessages);
        let prevAuthorId = null;
        return (
            <div className="chat-body" ref={this.mesRef} onScroll={this.onScroll}>
                <div className="chat-body-content">
                    {dates.map((date) => {
                        return (
                            <EmptyTag key={'msg-block-' + date}>
                                <div className="chat-body-note">
                                    <span className="chat-note-text">
                                        {date == 'TODAY' ? <FormattedMessage id="core.today.label"/> : date}
                                    </span>
                                </div>

                                {groupByDateMessages[date].map(({text, user, created_at}) => {
                                    const classNames = classnames('chat-body-line', {
                                        'chat-interlocutor-line': user == null || user.id != currentUser.id,
                                        'chat-your-line': user != null && user.id == currentUser.id
                                    });
                                    let isShowAuthor = user && prevAuthorId && user.id != prevAuthorId && user.id != currentUser.id;
                                    prevAuthorId = user && user.id ? user.id : null;
                                    return (
                                        <div key={created_at}
                                             className={classNames}>
                                            {isShowAuthor &&<Avatar userId={user.id}/>}
                                            {isShowAuthor && <EmptyTag>
                                                <div className="chat-replica-author">
                                                    {user.first_name} {user.last_name}
                                                </div>
                                            </EmptyTag>}
                                            <div className="chat-replica">
                                                <div className="chat-replica__text">
                                                    <ChatMessage text={text}/>
                                                </div>
                                                <div className="chat-replica__time">
                                                    {moment.utc(created_at).local().format("h:mm a")}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </EmptyTag>
                        )
                    })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetMessages: (listName, roomId, afterMessageId) => dispatch(getMessages(listName, roomId, afterMessageId)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessages);