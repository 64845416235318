import React from 'react';
import classnames from "classnames";

export const Label = (props) => {
    const {fixed, align = "", required = false} = props;
    const labelClass = classnames('field-label', {
        'label-fixed': (fixed === true),
        'align-right': align === 'right'
    });
    return (
        <label className={labelClass}>
            {props.children}
            {required && <span className="required">*</span>}
        </label>
    );
};