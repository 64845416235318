import React, {Component} from 'react';
import {connect} from "react-redux";
import {formInitAction} from "../../../actions/formAction";
import {listInitAction} from "../../../actions/listAction";
import {getMyOpenRooms} from "../../services/chatService";
import ChatWidgetRooms from "./ChatWidgetRooms";
import {Button} from "../../../components/elements/button";
import ChatWidgetChosenRoom from "./ChatWidgetChosenRoom";
import {formSimpleChange} from "../../services/system/formService";

class ChatWidget extends Component {
    constructor(props) {
        super(props);
        this.onChoose = this.onChoose.bind(this);
        this.onShowChats = this.onShowChats.bind(this);
        this.onHideChats = this.onHideChats.bind(this);
    }

    componentDidMount() {
        this.props.onInitForm("chatWidget", {'isShowChats': false});
    }

    onShowChats() {
        this.props.onFormSimpleChange("chatWidget", 'isShowChats', true);
    }

    onHideChats() {
        this.props.onFormSimpleChange("chatWidget", 'isShowChats', false);
    }

    componentWillUnmount() {
        this.props.onInitForm("chatWidget", {})
    }

    onChoose(room) {
        this.props.onInitForm("widgetChosenChatRoom", room)
    }

    render() {
        const {form: {data: formData}} = this.props;
        const chatRoom = formData["widgetChosenChatRoom"] || {};
        const chatWidget = formData["chatWidget"] || {};
        const isChooseChatRoom = Object.keys(chatRoom).length > 0;
        return (
            <div className="chat-widget-wrapper">
                <div className="chat-widget-main-btn">
                    <Button icon={'chat-white-filled'} circle={true} onClick={() => this.onShowChats()}></Button>
                    {/*<span className="inline-circle bg-color-red circle-chat-messages-amount">2</span>*/}
                </div>
                {chatWidget.isShowChats && !isChooseChatRoom && <ChatWidgetRooms onHideChats={this.onHideChats}/>}
                {isChooseChatRoom && <ChatWidgetChosenRoom onHideChats={this.onHideChats}/>}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onGetMyOpenRooms: () => dispatch(getMyOpenRooms()),
        onFormSimpleChange: (formName, fieldName, fieldValue) =>
            dispatch(formSimpleChange(formName, fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatWidget);