import {BROWSER_NOTIFICATION_GRANTED, BROWSER_NOTIFICATION_DENY} from "../constants/actionTypes";

const initialState = {
    isNotification : false
};

const browserReducer = (state = initialState, action) => {
    switch (action.type) {
        case BROWSER_NOTIFICATION_GRANTED:
            return {...state, isNotification: true};
        case BROWSER_NOTIFICATION_DENY:
            return {...state, isNotification: false};
        default:
            return state;
    }
};

export default browserReducer;