import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {getUrlStreamingCall} from "../../../services/callService";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Button} from "../../../../components/elements/button";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../actions/preloaderAction";
import {modalShow} from "../../../services/system/modalService";

class CallPay extends Component {
    constructor(props) {
        super(props);
        this.playVideo = this.playVideo.bind(this);
    }

    playVideo(callId) {
        (async () => {
            this.props.onPageShowPreloader();
            const src = await getUrlStreamingCall(callId);
            this.props.onModalShow("videoPlay", {src: src});
            this.props.onPageHidePreloader();
        })();
    }

    render() {
        const {call} = this.props;
        const {id, hasVideo, hasAudio, recordStatus} = call;

        return (
            <EmptyTag>
                {recordStatus == null && <FormattedMessage id="recording.pending"/>}
                {recordStatus != null && recordStatus != 'AVAILABLE' && <FormattedMessage id="recording.not.available"/>}
                {recordStatus == 'AVAILABLE' && (hasVideo || hasAudio) &&
                <Button
                    disabled={recordStatus != 'AVAILABLE'}
                    onClick={() => this.playVideo(id)}
                    icon="play-gray" size={"small"} border="gray-bordered" iconSize={10}>
                    {!hasVideo && hasAudio && <FormattedMessage id="audio"/>}
                    {hasVideo && <FormattedMessage id="video"/>}
                </Button>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallPay);