import {
    FORM_CHANGE_FIELD,
    FORM_FIELD_ERRORS,
    FORM_INIT,
    FORM_IS_SEND
} from "../constants/actionTypes";
import _ from "lodash";

const initialState = {
    errors: {},
    data: {},
    isSend: {}
};

const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case FORM_INIT:
            return {
                ...state,
                data: {...state.data, [action.formName]: action.data},
                isSend: {...state.isSend, [action.formName]: false},
                errors: {...state.errors, [action.formName]: {}},
                responseMsg: {...state.responseMsg, [action.formName]: false}
            };
        case FORM_IS_SEND:
            return {...state, isSend: {...state.isSend, [action.formName]: action.isSend}};
        case FORM_CHANGE_FIELD:
            let singleForm = {...state.data[action.formName]};
            let fieldValue = null;
            if (action.isMultiple) {
                let fieldArrayValue = _.get(singleForm, action.fieldName, []);
                fieldValue = _.includes(fieldArrayValue, action.fieldValue) ?
                    _.without(fieldArrayValue,  action.fieldValue) : _.concat(fieldArrayValue, action.fieldValue);
                if (fieldValue.length == 0) fieldValue = null;
            } else {
                fieldValue = action.fieldValue
            }

            if (fieldValue == null) {
                _.unset(singleForm, action.fieldName);
            } else {
                _.set(singleForm, action.fieldName, fieldValue);
            }

            return {...state, data: {...state.data, [action.formName]: singleForm}};
        case FORM_FIELD_ERRORS:
            const errors = {...state.errors, [action.formName]: action.errors};
            return {...state, errors: errors};
        default:
            return state;
    }
}

export default formReducer;