import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Redirect} from 'react-router';

import PrivateLayout from "./PrivateLayout";
import {defineSystemLanguageUser} from "../../services/commonService";
import {callHideAction} from "../../../actions/callAction";
import qs from "query-string";

class PrivateLayoutRouter extends Component {
    componentDidMount() {
        const {auth} = this.props;
        const {user} = auth;
        this.props.onDefineSystemLanguageUser(user);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {auth: curAuth, path: curPath} = this.props;
        const {auth: prevAuth, path: prevPath} = prevProps;
        if (prevAuth.isAuthenticated && !curAuth.isAuthenticated) {
            localStorage.removeItem("SignReferrer");
        }

        const pCur = this.props.location.search;
        const {reload: curReload = ""} = qs.parse(pCur);

        const pPrev = prevProps.location.search;
        const {reload: prevReload = ""} = qs.parse(pPrev);

        if (curPath != prevPath || curReload != prevReload) {
            this.props.onCallHide();
        }
    }

    render() {
        const {
            authenticate = false,
            permitAll = false,
            hasAnyRoles = false,
            hasPrivilege = true,
            title,
            auth,
            component: Component,
            ...rest
        } = this.props;
        const {user} = auth;
        const roles = hasAnyRoles ? hasAnyRoles.split(",") : [];
        const isHasAccess = permitAll ||
            (authenticate && auth.isAuthenticated && !hasAnyRoles) ||
            (hasAnyRoles && auth.isAuthenticated && user != null && roles.findIndex(role => role === user.role) >= 0 && hasPrivilege);

        const redirectTo = auth.isAuthenticated ? "/accessDenied" : "/welcome";

        if (!isHasAccess && !auth.isAuthenticated) {
            const {pathname, search} = this.props.location;
            if (!localStorage.getItem("SignReferrer") || localStorage.getItem("SignReferrer") == "/")
                localStorage.setItem("SignReferrer", pathname + search);
        } else {
            localStorage.removeItem("SignReferrer");
        }
        return (
            <Route {...rest}
                   render={matchProps => isHasAccess ? (
                       <PrivateLayout title={title}>
                           <Component {...matchProps} />
                       </PrivateLayout>
                   ) : (<Redirect to={redirectTo}/>)}
            />
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDefineSystemLanguageUser: (user) => dispatch(defineSystemLanguageUser(user)),
        onCallHide: () => dispatch(callHideAction())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayoutRouter);