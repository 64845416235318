import React from 'react';
import axios from "axios";
import {authenticateAction, updateProfileAction} from "../../actions/authAction";
import {formErrors, formInit, formIsSend} from "./system/formService";
import {signAxios, publicApi, privateApi} from '../../utils/api';
import * as jwt from "jsonwebtoken";
import {noticeShowAction} from "../../actions/noticeAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import moment from "moment-timezone";
import {FormattedMessage} from "react-intl";
import qs from "qs";
import {AUTH_PROVIDER} from "../../constants/system";
import {formIsSendAction} from "../../actions/formAction";
import {wsCloseAction} from "../../actions/wsAction";
import store from "../../store";
import {prepareFilter, prepareSort} from "../../utils/app";
import {listFetchAction} from "../../actions/listAction";
import {modalHide} from "./system/modalService";

let requestsToRefresh = [];
let isRefreshRequesting = false;
export const getRequestsToRefresh = () => {
    return requestsToRefresh;
}

export const refreshToken = () => {
    if (!isRefreshRequesting) {
        isRefreshRequesting = true;
        const refreshToken = localStorage.getItem('refreshToken') || null;
        publicApi.post("/users/refreshTokensAuthorize", {refreshToken: refreshToken})
            .then(response => {
                const {accessToken, refreshToken} = response.data;
                store.dispatch(authorizeUser(accessToken, refreshToken));
                requestsToRefresh.forEach((cb) => cb(accessToken));
            }).catch(reason => {
            requestsToRefresh.forEach((cb) => cb(null));
            store.dispatch(logoutUser());
            return false;
        }).finally(() => {
            requestsToRefresh = []
            isRefreshRequesting = false
        });
    }
}

export const reVerifyAccount = (data) => {
    return (dispatch) => {
        dispatch(formIsSend("reVerifyAccount", true));
        return publicApi.post("/users/reVerifyAccount", data)
            .then(response => {
                return dispatch(noticeShowAction(<FormattedMessage id="notice.re.verify.account.title"/>,
                    <FormattedMessage id="notice.reVerifyAccount.msg.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("reVerifyAccount", error.response.data.details));
                } else if (error.response.status === 403) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.re.verify.account.title"/>, error.response.data.message));
                }
                dispatch(formIsSend("reVerifyAccount", false));
            });
    }
};

export const forgotPassword = (data) => {
    return (dispatch) => {
        dispatch(formIsSend("forgotPassword", true));
        return publicApi.post("/users/forgotPassword", data)
            .then(response => {
                return dispatch(noticeShowAction(<FormattedMessage id="notice.doctor.forgot.password.title"/>,
                    <FormattedMessage id="notice.doctor.forgot.password.msg.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("forgotPassword", error.response.data.details));
                } else if (error.response.status === 403) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.doctor.forgot.password.title"/>, error.response.data.message));
                }
                dispatch(formIsSend("forgotPassword", false));
            });
    }
};

export const signUp = (data) => {
    return (dispatch) => {
        dispatch(formIsSend("signUp", true));
        data["isAgree"] = true;
        return publicApi.post("/users/sign-up", data)
            .then(response => {
                return dispatch(noticeShowAction(<FormattedMessage id="notice.patient.sign.up.title"/>,
                    <FormattedMessage id="notice.patient.sign.up.msg.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("signUp", error.response.data.details));
                }
                dispatch(formIsSend("signUp", false));
            });
    }
};

export const signUpDoctor = (data) => {
    return (dispatch) => {
        dispatch(formIsSend("signUp", true));
        data["isAgree"] = true;
        return publicApi.post("/public/v2/users/signUpDoctor", data)
            .then(response => {
                return dispatch(noticeShowAction(<FormattedMessage id="notice.doctor.sign.up.title"/>,
                    <FormattedMessage id="notice.doctor.sign.up.msg.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("signUp", error.response.data.details));
                }
                dispatch(formIsSend("signUp", false));
            });
    }
};

export const verifyAccount = (token) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return publicApi.post("/users/verifyAccount", {token: token})
            .then(response => {
                const textMsg = <FormattedMessage id="notice.verify.account.msg.success.text"/>;
                return dispatch(noticeShowAction(<FormattedMessage id="notice.verify.account.title"/>, textMsg));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {token = ""} = error.response.data.details || {};
                    return dispatch(noticeShowAction(<FormattedMessage id="notice.verify.account.title"/>, token));
                }
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const refreshTimezone = async () => {
    return await privateApi.post("/users/refreshTimezone", {timezone: moment.tz.guess(true)});

};

export const sign = (credential, ownProps, formName) => {
    return (dispatch) => {
        dispatch(formIsSend(formName, true));
        return signAxios.post("/users/sign", qs.stringify(credential))
            .then(response => {
                const {accessToken, refreshToken} = response.data;
                dispatch(formIsSend(formName, false));
                dispatch(authorizeUser(accessToken, refreshToken));
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 400) {
                        const {errorCode = ""} = error.response.data || {};
                        if (errorCode === "invalid.verification.code") {
                            dispatch(formInit("twoFA", {...credential, authProvider: AUTH_PROVIDER.DEFAULT}));
                            if (formName === 'twoFA')
                                dispatch(formErrors("twoFA", error.response.data.details));
                            ownProps.history.push('/users/sign-in/2fa');
                        } else {
                            dispatch(formErrors(formName, error.response.data.details));
                        }
                    } else if (error.response.status === 403) {
                        dispatch(noticeShowAction(<FormattedMessage id="notice.account.not.verify.title"/>,
                            <FormattedMessage id="notice.account.not.verify.msg.error.text"/>));
                    } else {
                        dispatch(formErrors(formName, []));
                    }
                }
                dispatch(formIsSend(formName, false));
            });
    }
};

export const signViaApple = (credential, ownProps, formName = 'signViaApple') => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return signAxios.post("/users/sign/apple", qs.stringify(credential))
            .then(response => {
                const {accessToken, refreshToken} = response.data;
                return dispatch(authorizeUser(accessToken, refreshToken));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {errorCode = ""} = error.response.data || {};
                    if (errorCode == "invalid.verification.code") {
                        dispatch(formInit("twoFA", {...credential, authProvider: AUTH_PROVIDER.APPLE}));
                        if (formName == 'twoFA')
                            dispatch(formErrors("twoFA", error.response.data.details));
                        ownProps.history.push('/users/sign-in/2fa');
                    } else if (errorCode == "authentication.account.not.found") {
                        dispatch(formInit("acceptTerms", {...credential, authProvider: AUTH_PROVIDER.APPLE}));
                        ownProps.history.push('/users/acceptTerms');
                    } else {
                        dispatch(formErrors('twoFA', error.response.data.details));
                    }
                } else if (error.response.status === 403) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.message));
                } else if (error.response.status === 401) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.details));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const signViaGoogle = (credential, ownProps, formName = 'signViaGoogle') => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return signAxios.post("/users/sign/google", qs.stringify(credential))
            .then(response => {
                const {accessToken, refreshToken} = response.data;
                return dispatch(authorizeUser(accessToken, refreshToken));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {errorCode = ""} = error.response.data || {};
                    if (errorCode == "invalid.verification.code") {
                        dispatch(formInit("twoFA", {...credential, authProvider: AUTH_PROVIDER.GOOGLE}));
                        if (formName == 'twoFA')
                            dispatch(formErrors("twoFA", error.response.data.details));
                        ownProps.history.push('/users/sign-in/2fa');
                    } else if (errorCode == "authentication.account.not.found") {
                        dispatch(formInit("acceptTerms", {...credential, authProvider: AUTH_PROVIDER.GOOGLE}));
                        ownProps.history.push('/users/acceptTerms');
                    }
                } else if (error.response.status === 403) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.message));
                } else if (error.response.status === 401) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.details));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const resetPassword = (token, data) => {
    return (dispatch) => {
        dispatch(formIsSend("resetPassword", true));
        return publicApi.post("/users/resetPassword", {...data, token: token})
            .then(response => {
                const textMsg = <FormattedMessage id="notice.default.msg.update.success.text"/>;
                return dispatch(noticeShowAction(<FormattedMessage id="notice.reset.password.title"/>, textMsg));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {token = ""} = error.response.data.details || {};
                    if (token) {
                        return dispatch(noticeShowAction(<FormattedMessage id="notice.reset.password.title"/>, token));
                    }
                    dispatch(formErrors("resetPassword", error.response.data.details));
                } else if (error.response.status === 403) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.reset.password.title"/>, error.response.data.message));
                }
                dispatch(formIsSend("resetPassword", false));
            });
    }
};

export const completeSignUpAsDoctor = (token, data) => {
    return (dispatch) => {
        dispatch(formIsSend("completeSignUpAsDoctor", true));
        return publicApi.post("/users/completeSignUpAsDoctor", {...data, token: token})
            .then(response => {
                const textMsg = <FormattedMessage id="notice.doctor.sign.up.complete.msg.success.text"/>;
                return dispatch(noticeShowAction(<FormattedMessage
                    id="notice.doctor.sign.up.complete.title"/>, textMsg));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {token = ""} = error.response.data.details || {};
                    if (token) {
                        return dispatch(noticeShowAction(<FormattedMessage
                            id="notice.doctor.sign.up.complete.title"/>, token));
                    }
                    dispatch(formErrors("completeSignUpAsDoctor", error.response.data.details));
                } else if (error.response.status === 403) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.message));
                }
                dispatch(formIsSend("completeSignUpAsDoctor", false));
            });
    }
};

export const googleUserInfo = (accessToken) => {
    return (dispatch) => {
        if (accessToken != null) {
            return axios.get("https://www.googleapis.com/oauth2/v2/userinfo", {params: {access_token: accessToken}})
                .then(response => {
                    const {email = "", given_name = "", family_name = ""} = response.data;
                    const user = {
                        email: email,
                        lastName: family_name,
                        firstName: given_name,
                        timezone: moment.tz.guess(true),
                        token: accessToken
                    };
                    dispatch(formInit("signUp", user));
                }).catch(error => {
                    if (error.response.status === 400) {

                    }
                });
        } else {
        }
    }
};

export const authorizeUser = (accessToken, refreshToken, user = {}) => {
    return (dispatch) => {
        if (accessToken !== "" && refreshToken !== "" &&
            accessToken !== null && refreshToken !== null &&
            accessToken !== "null" && refreshToken !== "null") {
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);

            const data = jwt.decode(accessToken);
            const {user: accessTokenUser = {}} = data;
            if (user == null || Object.keys(user).length == 0) {
                localStorage.setItem("user", JSON.stringify(accessTokenUser));
                user = accessTokenUser;
            } else if (accessTokenUser == null || Object.keys(accessTokenUser).length == 0 || accessTokenUser.id != user.id) {
                return dispatch(logoutUser());
            }

            (async () => {
                await refreshTimezone();
            })();

            dispatch(authenticateAction(user, accessToken));
        } else {
            return dispatch(logoutUser());
        }
    }
};

export const isValidAccessToken = async (accessToken) => {
    const response = await publicApi.post("/v1/users/isValidAccessToken", qs.stringify({accessToken: accessToken}));
    return response.data;
};

export const logoutUser = () => {
    return (dispatch) => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        delete axios.defaults.headers.common['Authorization'];
        dispatch(wsCloseAction());
        return dispatch(authenticateAction({}));
    }
};

export const fetchProfilePhoto = async (userId) => {
    try {
        const response = await privateApi.get("/v1/users/" + userId + "/profile/photo");
        return response.data;
    } catch (e) {
        return null;
    }
};


export const getUserCurrentGeolocation = (successCallback, errorCallBack) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            successCallback(position);
        }, error => {
            errorCallBack(error);
        });
    }
};

export const verifyNewEmail = (token) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return publicApi.post("/users/verifyNewEmail", {token: token})
            .then(response => {
                dispatch(updateProfileAction(response.data));
                const textMsg = <FormattedMessage id="notice.verify.new.email.msg.success.text"/>;
                return dispatch(noticeShowAction(<FormattedMessage id="notice.verify.new.email.title"/>, textMsg));
            }).catch(error => {
                return dispatch(noticeShowAction(<FormattedMessage
                    id="notice.verify.new.email.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const saveNewEmail = (userId, email) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        dispatch(preloaderPageShowAction());
        dispatch(formIsSendAction("profileAccountEmail", true));
        return privateApi.post("/users/" + userId + "/saveNewEmail", {email: email})
            .then(response => {
                dispatch(formErrors("profileAccountEmail", {}));
                if (user.id == userId) {
                    dispatch(updateProfileAction({...user, newEmail: email}));
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("profileAccountEmail", error.response.data.details));
                }
            })
            .finally(() => {
                dispatch(formIsSendAction("profileAccountEmail", false));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchAccount = (userId, formName = "account") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/users/" + userId + "/account")
            .then(response => {
                dispatch(formInit(formName, response.data));
            }).catch(error => {
                return dispatch(noticeShowAction("", error.response.data.message));
            })
            .finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const changePassword = (data) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(formIsSendAction("profileAccountPassword", true));
        return privateApi.post("/users/changePassword", data)
            .then(response => {
                dispatch(formErrors("profileAccountPassword", {}));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("profileAccountPassword", error.response.data.details));
                }
            })
            .finally(() => {
                dispatch(formIsSendAction("profileAccountPassword", false));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const applyPassword = (userId, data) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/users/" + userId + "/applyPassword", data)
            .then(response => {
                dispatch(formErrors("profileAccountPassword", {}));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("profileAccountPassword", error.response.data.details));
                }
            })
            .finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const generatePassword = async () => {
    const response = await privateApi.post("/users/generatePassword");
    return response.data;
};

export const fetchUsers = (pageNumber, listName = "users") => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters[listName] || {});
        const sort = prepareSort(state().list.sorts[listName] || {});
        return privateApi.get("/v1/users?pageNumber=" + pageNumber + "&search=" + filter + "&sort=" + sort)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction(listName, content, {...rest}));
                return dispatch(preloaderPageHideAction());
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const createAccount = (data, ownProps) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/users/createAccount", data)
            .then(response => {
                const {id} = response.data;
                dispatch(formErrors("createAccount", {}));

                let url = "";
                switch (data.role){
                    case "DOCTOR" :
                        url = "/admin/users/" + id + "/profile/doctor";
                        break;
                    case "PATIENT" :
                        url = "/admin/users/" + id + "/profile/patient";
                        break;
                    default:
                        url = "/admin/users";
                }
                dispatch(modalHide('createAccount'));
                ownProps.history.push(url);
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createAccount", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.message));
                }
            })
            .finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const enableUser = (id, listName = "users") => {
    return (dispatch, state) => {
        const {number} = state().list.pagination[listName] || {};
        dispatch(modalHide('confirm'));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/users/" + id + "/enable")
            .then(response => {
                dispatch(fetchUsers(number + 1));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const disableUser = (id, listName = "users") => {
    return (dispatch, state) => {
        const {number} = state().list.pagination[listName] || {};
        dispatch(modalHide('confirm'));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/users/" + id + "/disable")
            .then(response => {
                dispatch(fetchUsers(number + 1));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const enableUserSingle = (id, callback = false) => {
    return (dispatch) => {
        dispatch(modalHide('confirm'));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/users/" + id + "/enable")
            .then(response => {
                if (callback)
                    callback();
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const disableUserSingle = (id, callback = false) => {
    return (dispatch) => {
        dispatch(modalHide('confirm'));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/users/" + id + "/disable")
            .then(response => {
                if (callback)
                    callback();
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};