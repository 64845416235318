import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../../components/modules/modal";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {Button, Buttons} from "../../../../../components/elements/button";
import {modalHide} from "../../../../services/system/modalService";
import {withRouter} from 'react-router-dom';
import {Header} from "../../../../../components/elements/header";
import {Input} from "../../../../../components/elements/input";
import {formChangeField, formInit, formSimpleChange} from "../../../../services/system/formService";
import {FormattedMessage} from "react-intl";
import {healthInsurancePayment} from "../../../../services/bookingPaymentService";
import moment from "moment";
import {Paragraph} from "../../../../../components/elements/paragraph";
import {Label} from "../../../../../components/elements/label";
import {noticeShowAction} from "../../../../../actions/noticeAction";

class HealthInsurancePayment extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeDateTime = this.onChange.bind(this);
        this.onFillPhone = this.onFillPhone.bind(this);
    }

    componentDidMount() {
        const {form: {data: formData}} = this.props;
        const {healthInsurancePayment} = formData;
        const {booking} = healthInsurancePayment;
        if (booking.patient.nhsNumber == null || booking.patient.nhsNumber == "") {
            this.props.onNoticeShow(<FormattedMessage id="health.insurance.payment.not.fill.insurance.number"/>);
        }
    }

    onChange(event) {
        this.props.onFormChangeField('healthInsurancePayment', event);
    }

    onFillPhone() {
        const {auth: {user}} = this.props;
        this.props.onFormSimpleChangeField('healthInsurancePayment', 'paymentPhoneNumber', user.phone);
    }

    onChangeDateTime(event) {
        const value = moment(event.target.value, "DD.MM.YYYY hh:mm:ss").isValid() ? event.target.value : null;
        this.props.onFormSimpleChangeField('healthInsurancePayment', event.target.name, value);
    }

    render() {
        const {form: {data: formData, errors: formErrors, isSend: formIsSend}} = this.props;
        const {healthInsurancePayment} = formData;
        const {booking} = healthInsurancePayment;
        const {payment} = booking;

        const errors = formErrors['healthInsurancePayment'] || [];
        const disabled = formIsSend['healthInsurancePayment'].isSend || false;

        const totalAmount = ((payment && payment.currency) || '') + ' ' +
            ((payment && parseFloat(payment.amount).toFixed(2)) || '');

        return (
            <Modal close={true} onClose={() => this.props.onModalHide('healthInsurancePayment')} width={800}>
                <Header as="h2"><FormattedMessage id="modal.create.payment.not.verified.header"/></Header>
                <Header as="h3"><FormattedMessage id="instruction.for.health.insurance.payment"/></Header>

                <GridRow>
                    <GridCol count='1'>
                        <Label fixed={true}><FormattedMessage id="health.insurance.number.label"/></Label>
                        <Paragraph>{(booking && booking.patient && booking.patient.nhsNumber) || ''}</Paragraph>
                    </GridCol>
                    <GridCol count='1'>
                        <div className="row small-form-row align-items-center">
                            <div className="col">
                                <Input placeholder={<FormattedMessage id="core.phone.number.label"/>}
                                       name="paymentPhoneNumber"
                                       value={(healthInsurancePayment && healthInsurancePayment.paymentPhoneNumber) || ''}
                                       error={(errors && errors["paymentPhoneNumber"]) || null}
                                       disabled={disabled}
                                       onChange={this.onChange}/>
                            </div>
                            <div className="col-auto">
                                <Button onClick={() => this.onFillPhone()}
                                        size={'small'}
                                        border={'gray-bordered'}
                                        disabled={disabled}><FormattedMessage id="fill.from.profile"/></Button>
                            </div>
                            <div className="col-12">
                                <div className="color-blue small-text">
                                    <FormattedMessage id="unverifiable.payment.phone.description"/>
                                </div>
                            </div>
                        </div>
                    </GridCol>
                    <GridCol count='1'>
                        <div className="col-12">
                            <div className="row">
                                <div className="col big-text"><FormattedMessage
                                    id="total.label"/></div>
                                <div className="col big-text align-right">{totalAmount}</div>
                            </div>
                        </div>
                    </GridCol>
                    <GridCol count='1'>
                        <Buttons>
                            <Button onClick={() => this.props.onHealthInsurancePayment(healthInsurancePayment)}
                                    disabled={disabled}><FormattedMessage id="core.btn.save.label"/></Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        modal: store.modal,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onNoticeShow: (text) => dispatch(noticeShowAction("", text)),
        onFormInit: (formName) => dispatch(formInit(formName, {})),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onHealthInsurancePayment: (data) => dispatch(healthInsurancePayment(data))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HealthInsurancePayment));