import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../components/collections/grid";
import {BackTo} from "../../../../components/elements/backTo";
import {Header} from "../../../../components/elements/header";
import {
    acceptRescheduling, downloadBookingFile, downloadHealthConditionVideo,
    fetchBooking,
    rejectBooking,
    removeBookingFile,
    saveBookingFile
} from "../../../services/bookingService";
import {EmptyTag} from "../../../../components/elements/emptyTag";

import {formInitAction} from "../../../../actions/formAction";
import {modalShow} from "../../../services/system/modalService";
import qs from "query-string";
import {isModal} from "../../../../utils/app";
import Pay from "./payment/Pay";
import RescheduleWidget from "./RescheduleWidget";
import PayWidget from "./payment/PayWidget";
import {Button} from "../../../../components/elements/button";
import PaymentSuccess from "./payment/PaymentSuccess";
import PaymentDecline from "./payment/PaymentDecline";
import VideoPlay from "../../../systems/videoPlay";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../actions/preloaderAction";
import {noticeShowAction} from "../../../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import ConfirmReasonRejectedAdvanceContent from "../../doctor/bookings/ConfirmReasonRejectedAdvanceContent";
import PaymentReceipt from "./payment/PaymentReceipt";
import {Redirect} from "react-router";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import PatientBookingDetailChat from "./PatientBookingDetailChat";
import PatientBookingDetailDescription from "./PatientBookingDetailDescription";

class PatientBooking extends Component {
    componentDidMount() {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};
        const p = this.props.location.search;
        const {tab = "description"} = qs.parse(p);

        this.props.onChangeTabPanel(tab);
        this.props.onFetchBooking(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match: {params: curParam, url: curUrl}, form: {data: curFormData = {}}} = this.props;
        const {id: curId = ""} = curParam || {};

        const {match: {params: prevParam}, form: {data: prevFormData = {}}} = prevProps;
        const {id: prevId = ""} = prevParam || {};

        const pCur = this.props.location.search;
        const {reload: curReload = "", tab: curTab = "description"} = qs.parse(pCur);

        const pPrev = prevProps.location.search;
        const {reload: prevReload = ""} = qs.parse(pPrev);

        if (curId != prevId || curReload != prevReload) {
            this.props.onFetchBooking(curId);
            if (curTab != '')
                this.props.onChangeTabPanel(curTab);
        }

        const {booking: curBooking = {}} = curFormData;
        const {booking: prevBooking = {}} = prevFormData;

        if (curBooking != prevBooking && Object.keys(curBooking).length !== 0) {
            const {payment: curPayment = {}, status = ""} = curBooking;
            const {payment: prevPayment = {}} = prevBooking;

            if (curUrl.indexOf("/paymentSuccess") !== -1) {
                this.props.onModalShow("paymentSuccess", {booking: curBooking});
            } else if (curUrl.indexOf("/paymentDecline") !== -1) {
                this.props.onModalShow("paymentDecline", {booking: curBooking});
            }

            if (curPayment != prevPayment) {
                const {paymentStatus = ""} = curPayment || {};
                if ((paymentStatus === 'COMPLETED' || paymentStatus === 'NOT_VERIFY') && status === 'REQUESTED') {
                    this.props.onNotice(<FormattedMessage id="notice.booking.waiting.doctor.approved.title"/>,
                        <FormattedMessage id="notice.booking.waiting.doctor.approved.msg"/>);
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.onInitForm("booking", {});
        this.props.onInitForm("consultation", {});
    }

    render() {
        const {tab: {data: tabData}, form, modal, auth: {user}} = this.props;

        if (user.role === 'PATIENT' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }

        const {data: {booking = {}}} = form;
        const {booking: tabBooking = {}} = tabData;
        const {status = ""} = booking;
        const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent booking={booking}/>;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={"/bookings"}/>
                    </div>
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.patient.booking.detail.header"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                        {(status === 'REQUESTED' || status === 'APPROVED') &&
                            <Button color="white-red" onClick={() => this.props.onConfirm({
                                advanceContent: rejectedAdvanceContent,
                                text: <FormattedMessage id="core.confirm.booking.cancel"/>,
                                onProceed: (() => this.props.onRejectBooking(booking))
                            })}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>}
                    </div>
                </div>

                <PayWidget booking={booking}/>
                <RescheduleWidget/>

                <div className="row content-heading">
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab onClick={() => this.props.onChangeTabPanel("description")}
                                 active={tabBooking.activeTabPanel === 'description'}><FormattedMessage
                                id="core.tab.description.label"/></Tab>
                            {booking.status != 'REQUESTED' &&
                                <Tab onClick={() => this.props.onChangeTabPanel("chat")}
                                     active={tabBooking.activeTabPanel === 'chat'}><FormattedMessage
                                    id="core.tab.chat.label"/></Tab>}
                        </Tabs>
                    </GridCol>
                </div>

                {tabBooking.activeTabPanel === 'description' && <PatientBookingDetailDescription/>}
                {tabBooking.activeTabPanel === 'chat' && Object.keys(booking).length > 0 && <PatientBookingDetailChat/>}

                {isModal(modal, 'paymentSuccess') && <PaymentSuccess/>}
                {isModal(modal, 'paymentDecline') && <PaymentDecline/>}
                {isModal(modal, 'pay') && <Pay/>}
                {isModal(modal, 'videoPlay') && <VideoPlay/>}
                {isModal(modal, 'paymentReceipt') && <PaymentReceipt/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth,
        tab: store.tab,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("booking", tabPanelName)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onFetchBooking: (id) => dispatch(fetchBooking(id)),
        onDownloadFile: (booking, file) => dispatch(downloadBookingFile(booking, file)),
        onDownloadHealthConditionVideo: (booking) => dispatch(downloadHealthConditionVideo(booking)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onSaveBookingFile: (booking, file) => dispatch(saveBookingFile(booking, file)),
        onRemoveBookingFile: (booking, file) => dispatch(removeBookingFile(booking, file)),
        onAcceptRescheduling: (booking) => dispatch(acceptRescheduling(booking)),
        onRejectBooking: (booking) => dispatch(rejectBooking(booking)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onNotice: (title, textMsg) => dispatch(noticeShowAction(title, textMsg))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBooking);
