import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';

import {GridCol} from "../../../../components/collections/grid";
import Pagination from "react-js-pagination";
import {fetchDoctorPatientBookings} from "../../../services/doctorService";
import {Link} from "react-router-dom";
import {BackTo} from "../../../../components/elements/backTo";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import {Redirect} from "react-router";

class PatientBookings extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        const {auth, match: {params}} = this.props;
        const {patientId = ""} = params || {};
        this.props.onFetchDoctorPatientBookings(auth.user, {id: patientId}, pageNumber);
    }

    render() {
        const {list: {data, pagination}, auth: {user}} = this.props;
        if (user.role === 'DOCTOR' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }
        const {doctorPatientBookings = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['doctorPatientBookings'] || {};
        const {0 : firstBookings = {}} = doctorPatientBookings;
        const {patient = null} = firstBookings;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={"/patientConsulted"}/>
                    </div>

                    <div className="col content-heading__main">
                        <Header as="h2">
                            {patient && patient.firstName + ' ' + patient.lastName}
                            {!patient && <FormattedMessage id="doctor.bookings.empty"/>}
                        </Header>
                    </div>
                </div>
                <div className="row content-row">
                    <GridCol count="1">
                        <div className="row link-blocks-row">
                            {doctorPatientBookings.map(booking => {
                                const {id, consultation = {}, patient} = booking;
                                const {diagnosis = ''} = consultation || {};
                                return (
                                    <GridCol count="2" key={id}>
                                        <div className="link-block-wrapper">
                                            <Link to={"/patientConsulted/" + patient.id + "/bookings/" + id + "/detail"} className="link-block"></Link>
                                            <div className="row avatar-row align-items-center">
                                                <div className="col avatar-row__text">
                                                    <div>{diagnosis}</div>
                                                </div>
                                                <div className="col-auto">
                                            <span className="small-text color-gray">
                                                {moment(booking.timeOfReceipt).format("lll")}
                                            </span>
                                                </div>
                                            </div>
                                        </div>
                                    </GridCol>
                                );
                            })}
                        </div>
                    </GridCol>
                </div>
                <div className="col-12">
                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchDoctorPatientBookings: (doctor, patient, pageNumber) => dispatch(fetchDoctorPatientBookings(doctor, patient, pageNumber)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBookings);
