import React, {Component} from 'react';
import {connect} from "react-redux";
import {Label} from "../../../../components/elements/label";
import {acceptRescheduling, rejectBooking} from "../../../services/bookingService";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Paragraph} from "../../../../components/elements/paragraph";
import moment from "moment";
import {modalShow} from "../../../services/system/modalService";
import {Button} from "../../../../components/elements/button";
import {FormattedMessage} from "react-intl";
import ConfirmReasonRejectedAdvanceContent from "../../doctor/bookings/ConfirmReasonRejectedAdvanceContent";

class RescheduleWidget extends Component {
    render() {
        const {form} = this.props;
        const {data: {booking = {}}} = form;
        const {rescheduling} = booking;
        const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent booking={booking}/>;
        return (
            <EmptyTag>
                {rescheduling != null &&
                <div className="white-block">
                    <div className="row form-elements">
                        <div className="col-12">
                            <FormattedMessage id="reschedule.patient.widget.new.time.label"/>
                            &nbsp;
                            <span className="color-blue">
                                {moment(rescheduling.timeOfReceipt).format("lll")}
                            </span>
                        </div>
                        {rescheduling.comment &&
                        <div className="col-12">
                            <Label fixed="1"><FormattedMessage id="reschedule.patient.widget.comment.label"/></Label>
                            <Paragraph>{rescheduling.comment}</Paragraph>
                        </div>}
                        <div className="col-12">
                            <div className="color-gray align-center"><FormattedMessage id="reschedule.patient.widget.description.text"/></div>
                            <div className="form-submit btns-width-180">
                                <Button onClick={() => this.props.onConfirm({
                                    text: <FormattedMessage id="reschedule.patient.widget.confirm.accept.text"/>,
                                    onProceed: (() => this.props.onAcceptRescheduling(booking))
                                })}><FormattedMessage id="core.btn.accept.label"/></Button>
                                <Button color="white-red" onClick={() => this.props.onConfirm({
                                    advanceContent: rejectedAdvanceContent,
                                    text: <FormattedMessage id="core.confirm.booking.cancel"/>,
                                    onProceed: (() => this.props.onRejectBooking(booking))
                                })}><FormattedMessage id="core.btn.cancel.label"/></Button>
                            </div>
                        </div>
                    </div>
                </div>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onAcceptRescheduling: (booking) => dispatch(acceptRescheduling(booking)),
        onRejectBooking: (booking) => dispatch(rejectBooking(booking)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleWidget);
