import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button} from "../../../components/elements/button";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import {fetchCallParticipants, sendReCall} from "../../services/callService";
import {Paragraph} from "../../../components/elements/paragraph";
import {Label} from "../../../components/elements/label";
import {listInitAction} from "../../../actions/listAction";


class CallParticipants extends Component {
    componentWillUnmount() {
        this.props.onInitList("callParticipants");
    }

    componentWillMount() {
        const {sessionId = ""} = this.props.call.sessionOptions;
        this.props.onFetchCallParticipants(sessionId);
    }

    render() {
        const {list: {data: listData}, streams = [], callIncoming, auth: {user}, call: {sessionOptions}} = this.props;
        const {callParticipants = []} = listData;
        const {sessionId} = sessionOptions;
        return (
            <EmptyTag>
                <div className="white-block">
                    <h3><FormattedMessage id="participants.call"/></h3>
                    <div className="row form-elements form-bottom-bordered">
                        {callParticipants.map(callParticipant => {
                            const {id, role, firstName, lastName} = callParticipant.user;
                            let isNoConnected;
                            try {
                                isNoConnected = streams.map(i => JSON.parse(i.name)).findIndex(i => i.id == id) === -1;
                            } catch (e) {
                                isNoConnected = false;
                            }
                            return (
                                <div className="col-12" key={id}>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <Label fixed="true">
                                                {role === 'DOCTOR' && <FormattedMessage id="doctor"/>}
                                                {role === 'PATIENT' && <FormattedMessage id="patient"/>}
                                            </Label>
                                            <Paragraph>{firstName} {lastName}</Paragraph>
                                        </div>
                                        <div className="col-auto">
                                            {!callIncoming && isNoConnected && user.id != id &&
                                                <Button circle={true} icon="call-finish" color="blue"
                                                        circleMiddleSize={true}
                                                        onClick={() => this.props.onSendReCall(sessionId, id)}/>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </EmptyTag>
        )
    }
}


const mapStateToProps = store => {
    return {
        auth: store.auth,
        call: store.call,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFetchCallParticipants: (sessionId) => dispatch(fetchCallParticipants(sessionId)),
        onSendReCall: (sessionId, participantId) => dispatch(sendReCall(sessionId, participantId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CallParticipants);