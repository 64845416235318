import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../components/collections/grid";
import {Widget, WidgetNoResult} from "../../../../components/collections/widget";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {fetchBookings} from "../../../services/bookingService";
import {medicationDurationFormat} from "../../../services/consultationService";
import {FormattedMessage} from "react-intl";
import {getMedicationForm} from "../../../../utils/app";

class Prescriptions extends Component {

    componentDidMount() {
        this.props.onListSorts({completedAt: 'desc'});
        this.props.onListFilterField("statuses", "COMPLETED");
        this.props.onListFilterField("isExistPrescribe", "true");

        this.props.onFetchBookings(1);
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    render() {
        const {list: {data}} = this.props;
        const {widgetPrescriptions = []} = data;
        return (
            <Widget title={<FormattedMessage id="widget.patient.prescriptions.title"/>} linkTo={'/patient/treatment'} maxHeight={400} minHeight={300}>
                {widgetPrescriptions.length === 0 && <WidgetNoResult><FormattedMessage id="prescriptions.list.empty" /></WidgetNoResult>}
                {widgetPrescriptions.length > 0 && widgetPrescriptions.filter((value, index) => index < 1).map(widgetPrescription => {
                        const {consultation = {}, doctor} = widgetPrescription;
                        const {medications = []} = consultation || {};
                        return (
                            <EmptyTag key={widgetPrescription.id}>
                                <h4 className="margin-bottom-15">
                                    <span className="color-blue"><FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></span>
                                    <i className="icon icon--doctors-green"></i>
                                </h4>
                                <div className="row form-elements">
                                    {medications.map(({id, status, prescribedMedication, form, duration, directionsForUse, repeatPrescriptions}) => {
                                        return (
                                            <GridCol count="2" key={id}>
                                                <div className="row form-bg-light form-radius-8">
                                                    <div className="col">
                                                        <Header as="h4">{prescribedMedication}</Header>
                                                    </div>
                                                    <div className="col-auto">
                                                        {status && status === 'REQUESTED' &&
                                                        <div className="color-gray small-text"><FormattedMessage id="medication.status.requested.label"/></div>}
                                                        {status && status === 'ACCEPTED' &&
                                                        <div className="color-orange small-text"><FormattedMessage id="medication.status.accepted.label"/></div>}
                                                        {status && status === 'COMPLETED' &&
                                                        <div className="color-green small-text"><FormattedMessage id="medication.status.completed.label"/></div>}
                                                    </div>
                                                    <GridCol count="1">
                                                        <div className="small-text">
                                                            <div>{<FormattedMessage id={getMedicationForm(form)}/>}, {directionsForUse}</div>
                                                            <div>{<FormattedMessage id={medicationDurationFormat(repeatPrescriptions, duration)}
                                                                                    values={{repeat: repeatPrescriptions}}/>}</div>
                                                        </div>
                                                    </GridCol>
                                                </div>
                                            </GridCol>
                                        );
                                    })}
                                </div>
                            </EmptyTag>
                        );
                    }
                )}
            </Widget>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitList: () => dispatch(listInitAction("widgetPrescriptions")),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber, "widgetPrescriptions")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("widgetPrescriptions", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("widgetPrescriptions", sorts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Prescriptions);
