import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {formChangeField} from "../../../services/system/formService";
import {fetchSystemSettings} from "../../../services/commonService";
import {formChangeFieldAction} from "../../../../actions/formAction";
import {FormattedMessage} from "react-intl";
import {modalShow} from "../../../services/system/modalService";
import {Tab, Tabs} from "../../../../components/modules/tab";
import ProfileAccount from "../profile/ProfileAccount";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import qs from "query-string";
import {withRouter} from "react-router-dom";
import ProfileGeneral from "./ProfileGeneral";

class EditCommonProfile extends Component {

    componentDidMount() {
        this.props.onfetchSystemSettings();
        const p = this.props.location.search;
        const {tab = "account"} = qs.parse(p);
        this.props.onChangeTabPanel(tab);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const pCur = this.props.location.search;
        const {tab: curTab = ""} = qs.parse(pCur);

        const pPrev = prevProps.location.search;
        const {tab: prevTab = ""} = qs.parse(pPrev);

        if (curTab && curTab != prevTab) {
            this.props.onChangeTabPanel(curTab);
        }
    }

    render() {
        const {tab: {data: tabData}} = this.props;
        const {profile: tabProfile = {}} = tabData;

        return (
            <EmptyTag>

                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h1"><FormattedMessage id="my.profile"/></Header>
                    </div>
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab onClick={() => this.props.onChangeTabPanel("account")}
                                 active={tabProfile.activeTabPanel === 'account'}>
                                <FormattedMessage id="tab.item.account"/>
                            </Tab>

                            <Tab onClick={() => this.props.onChangeTabPanel("general")}
                                 active={tabProfile.activeTabPanel === 'general'}>
                                <FormattedMessage id="tab.item.general"/>
                            </Tab>
                        </Tabs>
                    </GridCol>
                </div>

                {tabProfile.activeTabPanel === 'general' && <ProfileGeneral/>}
                {tabProfile.activeTabPanel === 'account' && <ProfileAccount/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth,
        tab: store.tab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("profile", tabPanelName)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('profile', fieldName, fieldValue)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditCommonProfile));