import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {fetchPharmacyPatients} from "../../../services/pharmacyService";
import {Avatar} from "../../../../components/collections/avatar";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

class PharmacyPatients extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.props.onFetchPharmacyPatients(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchPharmacyPatients(pageNumber);
    }

    render() {
        const {list: {data, pagination}} = this.props;
        const {pharmacyPatients = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['pharmacyPatients'] || {};

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="pharmacy.list.patients.header"/></Header>
                    </div>
                </div>

                <div className="row link-blocks-row">
                    {pharmacyPatients.map(({firstName, lastName, id}) => {
                        return (
                            <div className="col-md-6" key={id}>
                                <div className="link-block-wrapper">
                                    <Link to={"/pharmacies/patients/" + id + "/prescriptions"} className="link-block"></Link>
                                    <div className="row avatar-row align-items-center">
                                        <div className="col-auto avatar-row__image">
                                            <Avatar/>
                                        </div>
                                        <div className="col avatar-row__text">
                                            <div>{firstName} {lastName}</div>
                                        </div>
                                        <div className="col-auto">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="col-12">
                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchPharmacyPatients: (pageNumber) => dispatch(fetchPharmacyPatients(pageNumber)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyPatients);
