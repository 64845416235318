import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../components/elements/emptyTag";
import DoctorHome from "../doctor/home";
import PatientHome from "../patient/home";
import AssistantHome from "../assistant/home";
import ClientAdminHome from "../admin/billing/home";
import Users from "../admin/users";

class Home extends Component {
    render() {
        const {auth} = this.props;
        const {user} = auth;
        return (
            <EmptyTag>
                {user.role === 'DOCTOR' && <DoctorHome/>}
                {user.role === 'PATIENT' && <PatientHome/>}
                {user.role === 'ASSISTANT' && <AssistantHome/>}
                {user.role === 'ADMIN' && <Users/>}
                {user.role === 'ADMIN_BILLING' && <ClientAdminHome/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);