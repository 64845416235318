import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";

import {FormattedMessage} from "react-intl";
import {Checkbox} from "../../../../components/elements/checkbox";
import {fetchSettings, updateSetting} from "../../../services/settingService";
import {Button, ButtonIcon} from "../../../../components/elements/button";
import {isModal} from "../../../../utils/app";
import ConfigureSupportEmails from "./ConfigureSupportEmails";
import {modalShow} from "../../../services/system/modalService";
import {Input} from "../../../../components/elements/input";

class Settings extends Component {
    constructor(props) {
        super(props);
        this.getSettingValue = this.getSettingValue.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.onOpenConfigureSupportEmails = this.onOpenConfigureSupportEmails.bind(this);
        this.onEditText = this.onEditText.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSaveText = this.onSaveText.bind(this);
        this.state = {
            editName: null,
            editValue: null
        }
    }

    componentDidMount() {
        this.props.onFetchSettings()
    }

    onChangeCheckbox(event) {
        const target = event.target
        this.props.onUpdateSetting({
            name: target.name,
            value: target.checked ? 'Y' : 'N'
        });
    }

    onSaveText() {
        this.props.onUpdateSetting({
            name: this.state.editName,
            value: this.state.editValue
        });
        this.setState({...this.state, editName: null, editValue: null});
    }

    onChange(event) {
        this.setState({...this.state, editValue: event.target.value});
    }

    getSettingValue(name = '') {
        const {list: {data: listData}} = this.props;
        const {settings = []} = listData;
        const item = settings.filter(i => i.name === name)[0];
        return item && item.value || "";
    }

    onOpenConfigureSupportEmails() {
        const supportServiceRecipients = this.getSettingValue('supportServiceRecipients');
        const emails = supportServiceRecipients.split(";");
        this.props.onModalShow('configureSupportEmails', {emails : emails});
    }

    onEditText(name) {
        this.setState({...this.state, editName: name, editValue: this.getSettingValue(name)});
    }

    render() {
        const {modal} = this.props;

        return (
            <EmptyTag>

                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <div className="filtr-links">
                            <Header as="h2"><FormattedMessage id="page.settings"/></Header>
                        </div>
                    </div>
                </div>


                <div className="row content-row">
                    <div className="col-12">
                        <div className="white-block">

                            <div className="row form-elements">
                                <div className="col">
                                    <FormattedMessage id="consultation.recording"/>
                                </div>
                                <div className="col-auto">
                                    <Checkbox
                                        name='isEnabledVideoRecordingConsultation'
                                        onChange={this.onChangeCheckbox}
                                        isChecked={this.getSettingValue('isEnabledVideoRecordingConsultation') === 'Y'}
                                        toggle={true} textSize={'small'} textColor={'gray'}><FormattedMessage id="video"/></Checkbox>
                                </div>
                                <div className="col-auto">
                                    <Checkbox
                                        name='isEnabledAudioRecordingConsultation'
                                        onChange={this.onChangeCheckbox}
                                        isChecked={this.getSettingValue('isEnabledAudioRecordingConsultation') === 'Y'}
                                        toggle={true} textSize={'small'} textColor={'gray'}><FormattedMessage id="audio"/></Checkbox>
                                </div>
                                <div className="col-12 padding-bottom-0">
                                    <hr className="hr-gray"/>
                                </div>
                                <div className="col">
                                    <FormattedMessage id="emergency.call.recording"/>
                                </div>
                                <div className="col-auto">
                                    <Checkbox
                                        name='isEnabledVideoRecordingEmergencyCall'
                                        onChange={this.onChangeCheckbox}
                                        isChecked={this.getSettingValue('isEnabledVideoRecordingEmergencyCall') === 'Y'}
                                        toggle={true} textSize={'small'} textColor={'gray'}><FormattedMessage id="video"/></Checkbox>
                                </div>
                                <div className="col-auto">
                                    <Checkbox
                                        name='isEnabledAudioRecordingEmergencyCall'
                                        onChange={this.onChangeCheckbox}
                                        isChecked={this.getSettingValue('isEnabledAudioRecordingEmergencyCall') === 'Y'}
                                        toggle={true} textSize={'small'} textColor={'gray'}><FormattedMessage id="audio"/></Checkbox>
                                </div>
                                <div className="col-12 padding-bottom-0">
                                    <hr className="hr-gray"/>
                                </div>
                                <div className="col">
                                    <FormattedMessage id="sms"/>
                                </div>
                                <div className="col-auto"></div>
                                <div className="col-auto">
                                    <Checkbox
                                        toggleBeforeText={'no'}
                                        toggleAfterText={'yes'}
                                        name='isEnabledSmsNotification'
                                        onChange={this.onChangeCheckbox}
                                        isChecked={this.getSettingValue('isEnabledSmsNotification') === 'Y'}
                                        toggle={true} textSize={'small'} textColor={'gray'}></Checkbox>
                                </div>
                                <div className="col-12 padding-bottom-0">
                                    <hr className="hr-gray"/>
                                </div>
                                <div className="col">
                                    <FormattedMessage id="configure.support.email.address"/>
                                </div>
                                <div className="col">
                                    {this.getSettingValue('supportServiceRecipients')}
                                </div>
                                <div className="col-auto">
                                    <ButtonIcon icon="edit-black"
                                                onClick={() => this.onOpenConfigureSupportEmails()}/>
                                </div>
                                <div className="col-12 padding-bottom-0">
                                    <hr className="hr-gray"/>
                                </div>
                                <div className="col">
                                    <FormattedMessage id="upload.file.max.size"/>
                                </div>
                                <div className="col">
                                    {this.state.editName != 'uploadFileMaxSize' &&
                                        this.getSettingValue('uploadFileMaxSize') &&
                                    <EmptyTag>
                                        <FormattedMessage id="upload.file.max.size.value"
                                        values={{
                                        size: this.getSettingValue('uploadFileMaxSize')
                                        }}/>
                                    </EmptyTag>}
                                    {this.state.editName == 'uploadFileMaxSize' &&
                                        <EmptyTag>
                                            <Input name='uploadFileMaxSize'
                                                   width={320}
                                                   fixedTooltip={<FormattedMessage id="mb"/>}
                                                   onChange={this.onChange}
                                                   placeholder={<FormattedMessage id="upload.file.max.size"/>}
                                                   value={this.state.editValue}
                                                    />
                                        </EmptyTag>}
                                </div>
                                <div className="col-auto">
                                    {this.state.editName != 'uploadFileMaxSize' &&
                                    <ButtonIcon icon="edit-black"
                                                onClick={() => this.onEditText('uploadFileMaxSize')}/>}
                                    {this.state.editName == 'uploadFileMaxSize' &&
                                        <Button size={'small'} onClick={() => this.onSaveText()}>
                                        <FormattedMessage id="core.btn.save.label"/>
                                    </Button>}
                                </div>
                                <div className="col-12 padding-bottom-0">
                                    <hr className="hr-gray"/>
                                </div>
                                <div className="col">
                                    <FormattedMessage id="default.doctor.rating"/>
                                </div>
                                <div className="col">
                                    {this.state.editName != 'defaultDoctorRating' &&
                                        this.getSettingValue('defaultDoctorRating')}
                                    {this.state.editName == 'defaultDoctorRating' &&
                                        <EmptyTag>
                                            <Input name='defaultDoctorRating'
                                                   width={320}
                                                   onChange={this.onChange}
                                                   placeholder={<FormattedMessage id="default.doctor.rating"/>}
                                                   value={this.state.editValue}
                                            />
                                        </EmptyTag>}
                                </div>
                                <div className="col-auto">
                                    {this.state.editName != 'defaultDoctorRating' &&
                                        <ButtonIcon icon="edit-black"
                                                    onClick={() => this.onEditText('defaultDoctorRating')}/>}
                                    {this.state.editName == 'defaultDoctorRating' &&
                                        <Button size={'small'} onClick={() => this.onSaveText()}>
                                            <FormattedMessage id="core.btn.save.label"/>
                                        </Button>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {isModal(modal, 'configureSupportEmails') && <ConfigureSupportEmails/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalShow: (name, data) => dispatch(modalShow(name, data)),
        onFetchSettings : () => dispatch(fetchSettings()),
        onUpdateSetting : (data) => dispatch(updateSetting(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);