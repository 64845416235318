import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import moment from "moment";

class RemittanceItems extends Component {
    render() {
        const {items} = this.props;
        return (
            <EmptyTag>
                <table className="admin-table">
                    <thead>
                    <tr>
                        <th><FormattedMessage id="report.date.complete.consultation"/></th>
                        <th><FormattedMessage id="report.patient.name"/></th>
                        <th><FormattedMessage id="report.final.diagnosis.consultation"/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(({patient, completedAt, consultation}) => {
                        return (
                            <tr key={"report-item-" + consultation.id}>
                                <td> {moment(completedAt).format('ll')}</td>
                                <td> {patient.firstName} {patient.lastName}</td>
                                <td>{consultation.diagnosis}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RemittanceItems);