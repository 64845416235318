import React from 'react';
import {connect} from "react-redux";

import {modalShow} from "../../services/system/modalService";
import Confirm from "../../systems/confirm";
import {isModal} from "../../../utils/app";
import 'react-notifications-component/dist/theme.css';
import EmergencyCall from "../../pages/call/EmergencyCall";
import {Slide, ToastContainer} from "react-toastify";
import {PreloaderPage} from "../../../components/elements/preloader";
import Call from "../../pages/call";
import {NoticeBodyModal, NoticeHeader} from "../../../components/collections/notice";
import {noticeHideAction, noticeShowAction} from "../../../actions/noticeAction";
import {fetchCountNoReadNotifications} from "../../services/notificationService";
import {fetchSizeQueueEmergencyCalls} from "../../services/callService";
import ConfirmUploadFile from "../../systems/uploadFile/ConfirmUploadFile";
import UploadFileDescription from "../../pages/patient/medicalRecord/UploadFileDescription";
import PatientMedicalRecord from "../../pages/doctor/patientMedicalRecord/PatientMedicalRecord";
import {wsConnect} from "../../services/wsService";
import {isFillPatientProfile} from "../../services/patientService";
import ShareLink from "../../pages/doctor/ShareLink";
import {FormattedMessage} from "react-intl";
import {fetchSystemSettings} from "../../services/commonService";
import CreateTicketSupport from "../../pages/CreateTicketSupport";
import ChooseMember from "../../pages/patient/family/ChooseMember";
import CreateMember from "../../pages/patient/family/CreateMember";
import IncomingCall from "../../pages/call/IncomingCall";
import MainMenu from "./MainMenu";
import HeaderLayout from "./Header";
import BackToCall from "../../pages/call/BackToCall";
import {EmptyTag} from "../../../components/elements/emptyTag";
import ChatWidget from "../../systems/chat/ChatWidget";
import StartChatConsultation from "../../pages/patient/bookings/StartChatConsultation";
import HealthInsurancePayment from "../../pages/patient/bookings/payment/HealthInsurancePayment";
import AskParentPay from "../../pages/patient/bookings/payment/AskParentPay";

class PrivateLayout extends React.Component {
    constructor(props) {
        super(props);
        this.showModal = this.showModal.bind(this);
        this.onChooseMember = this.onChooseMember.bind(this);
    }

    componentDidMount() {
        const {auth} = this.props;
        const {role, isEnabled, isFillProfile} = auth.user;
        this.props.onWsConnect();
        this.props.onIsNoReadNotifications();
        this.props.onfetchSystemSettings();
        if (role === 'DOCTOR') {
            this.props.onIsNotEmptyQueueEmergencyCalls();
        } else if (role === 'PATIENT') {
            if (!isFillProfile) {
                this.props.onIsFillPatientProfile();
            }
        }

        if (!isEnabled) {
            this.props.onNoticeShow(<FormattedMessage id="core.doctor.disabled.notice.text"/>);
        }
    }

    showModal(name) {
        this.props.onModalShow(name);
    }

    onChooseMember(isQuick = false) {
        this.props.onModalShow("chooseMember", {isQuick: isQuick});
    }

    render() {
        const {preloader, modal, notice, call} = this.props;
        return (
            <EmptyTag>
                <div className="wrapper">
                    {notice.header && <NoticeHeader type={notice.header.status}>{notice.header.textMsg}</NoticeHeader>}
                    <BackToCall/>
                    <MainMenu/>

                    <main className="base">
                        <HeaderLayout/>
                        <div className="content">
                            <div className="main-container container-fluid">
                                {call.isCall &&
                                    <Call/>
                                }
                                {(!call.isCall || !call.isShow) && this.props.children}
                            </div>
                        </div>
                    </main>
                    {isModal(modal, 'healthInsurancePayment') && <HealthInsurancePayment/>}
                    {isModal(modal, 'emergencyCall') && <EmergencyCall/>}
                    {isModal(modal, 'incomingCall') && <IncomingCall/>}
                    {isModal(modal, 'startChatConsultation') && <StartChatConsultation/>}
                    {isModal(modal, 'call') && <Call/>}
                    {isModal(modal, 'patientMedicalRecord') && <PatientMedicalRecord/>}
                    {isModal(modal, 'confirmFileUpload') && <ConfirmUploadFile/>}
                    {isModal(modal, 'uploadFileDescription') && <UploadFileDescription/>}
                    {isModal(modal, 'createTicketSupport') && <CreateTicketSupport/>}
                    {isModal(modal, 'confirm') && <Confirm/>}
                    {isModal(modal, 'shareLink') && <ShareLink/>}
                    {isModal(modal, 'chooseMember') && <ChooseMember/>}
                    {isModal(modal, 'createMember') && <CreateMember/>}
                    {isModal(modal, 'askParentPay') && <AskParentPay/>}
                    {notice.body && <NoticeBodyModal data={notice.body} onClose={() => this.props.onNoticeHide()}/>}

                    <ToastContainer transition={Slide}/>
                    {preloader.isShow && <PreloaderPage text={preloader.text}/>}
                </div>
                <ChatWidget/>
            </EmptyTag>
        );
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        notice: store.notice,
        preloader: store.preloader,
        form: store.form,
        auth: store.auth,
        call: store.call
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onWsConnect: () => dispatch(wsConnect()),
        onFormConfigureDoctorSchedule: () => dispatch(modalShow('configureDoctorSchedule')),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onNoticeHide: () => dispatch(noticeHideAction()),
        onNoticeShow: (text) => dispatch(noticeShowAction("", text)),
        onIsNoReadNotifications: () => dispatch(fetchCountNoReadNotifications()),
        onIsNotEmptyQueueEmergencyCalls: () => dispatch(fetchSizeQueueEmergencyCalls()),
        onIsFillPatientProfile: () => dispatch(isFillPatientProfile()),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateLayout);