import React, {Component} from 'react';
import {connect} from "react-redux";

import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {arrayRemoveNullElements} from "../../../../../utils/app";
import {Modal} from "../../../../../components/modules/modal";
import {Header} from "../../../../../components/elements/header";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {Button, ButtonIcon, ButtonRemove, Buttons} from "../../../../../components/elements/button";
import {modalHide} from "../../../../services/system/modalService";
import {formChangeField, formInit} from "../../../../services/system/formService";
import {formChangeFieldAction} from "../../../../../actions/formAction";
import {AutocompleteAsync} from "../../../../systems/autocomplete";
import {fetchDoctors} from "../../../../services/doctorService";
import {listFilterFieldAction, listInitAction} from "../../../../../actions/listAction";
import {sendRequestExtraConsultation} from "../../../../services/extraConsultationsService";

class AddParticipantExtraConsultation extends Component {
    constructor(props) {
        super(props);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onChangeDoctor = this.onChangeDoctor.bind(this);
        this.onSearchDoctor = this.onSearchDoctor.bind(this);
    }

    onAdd() {
        const {form: {data}} = this.props;
        const {addParticipantExtraConsultation: {users = [{}]}} = data;
        users.push({});
        this.props.onInitList("doctors");
        this.props.onFormSimpleChange("users", users);
    }

    onRemove(item) {
        const {form: {data}} = this.props;
        const {addParticipantExtraConsultation} = data;
        _.unset(addParticipantExtraConsultation, item);
        this.props.onFormSimpleChange("users", arrayRemoveNullElements(addParticipantExtraConsultation.users));
    }

    onSearchDoctor(event, query, reason) {
        this.props.onListFilterField("doctors", "searchText", query);
        this.props.onFetchDoctors(1);
    }

    onChangeDoctor(event, value, name) {
        this.props.onFormSimpleChange(name, value);
    }

    render() {
        const {form: {data: formData, isSend: formIsSend}, list: {data: listData}} = this.props;
        const {addParticipantExtraConsultation} = formData;
        const {consultationId} = addParticipantExtraConsultation;
        const {doctors = []} = listData;
        const {users = [{}]} = addParticipantExtraConsultation;

        return (
            <Modal width={700} close={true} onClose={() => this.props.onModalHide()}>
                <Header as="h2"><FormattedMessage id="invite.doctor"/></Header>
                <GridRow>

                    {users.map((user, index) => {
                        const name = "users[" + index + "]"
                        const value = Object.keys(user).length > 0 ? user : null;
                        return (
                            <GridCol count="1" key={index}>
                                <div className="row align-items-center">
                                    <div className="col-auto"></div>
                                    <div className="col">
                                        <AutocompleteAsync onInputChange={this.onSearchDoctor}
                                                           getOptionLabel={option => option.firstName + ' ' + option.lastName}
                                                           onChange={(event, value) => this.onChangeDoctor(event, value, "users[" + index + "]")}
                                                           name={name}
                                                           value={value}
                                                           placeholder={<FormattedMessage id="doctor"/>}
                                                           placeholderFixed={true}
                                                           error={null}
                                                           options={doctors}/>
                                    </div>
                                    <div className="col-auto relative-controls">
                                        {users.length > 1 &&
                                            <ButtonRemove onClick={() => this.onRemove(name)}/>}

                                        {index + 1 === users.length && users.length < 5 &&
                                            <ButtonIcon icon="plus-bordered" onClick={this.onAdd}/>}
                                    </div>
                                </div>
                            </GridCol>
                        );
                    })}
                    <GridCol count="1">
                        <Buttons width="auto">
                            <Button
                                    color="white-red"
                                    preloader={false}
                                    onClick={() => this.props.onModalHide()}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button disabled={users.filter(i => i.id != null).length == 0}
                                    preloader={formIsSend['addParticipantExtraConsultation'] || false}
                                    onClick={() => this.props.onSendRequestExtraConsultation(consultationId, users)}>
                                <FormattedMessage id="core.btn.send.label"/>
                            </Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSendRequestExtraConsultation: (consultationId, users) => dispatch(sendRequestExtraConsultation(consultationId, users)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFetchDoctors: (pageNumber) => dispatch(fetchDoctors(pageNumber, "doctors")),
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onFormInit: (data) => dispatch(formInit('addParticipantExtraConsultation', data)),
        onModalHide: () => dispatch(modalHide('addParticipantExtraConsultation')),
        onFormSimpleChange: (fieldName, fieldValue) => dispatch(
            formChangeFieldAction('addParticipantExtraConsultation', fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddParticipantExtraConsultation);
