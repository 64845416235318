import {AUTHENTICATE, ENABLE_2FA, ENABLED_USER, DISABLED_USER, UPDATE_PROFILE} from "../constants/actionTypes";

export const authenticateAction = (user, accessToken) => ({
    type: AUTHENTICATE,
    user: user,
    accessToken : accessToken
});

export const updateProfileAction = (profile) => ({
    type: UPDATE_PROFILE,
    profile: profile
});

export const enabledUserAction = () => ({
    type: ENABLED_USER
});

export const disabledUserAction = () => ({
    type: DISABLED_USER
});

export const enable2FAAction = () => ({
    type: ENABLE_2FA,
    isEnable2FA: true
});

export const disable2FAAction = () => ({
    type: ENABLE_2FA,
    isEnable2FA: false
});