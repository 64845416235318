import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import qs from "query-string";
import {EmptyTag} from "../../components/elements/emptyTag";

class GoogleCalendarAuthMobileCallback extends Component {
    render() {

        const p = this.props.location.search;
        const {code = "", scope = ""} = qs.parse(p);
        return (
            <EmptyTag>{"{\"code\": \"" + code + "\", \"scope\": \"" + scope + "\"}"}</EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoogleCalendarAuthMobileCallback));
