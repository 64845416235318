import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Avatar} from "../../../../../components/collections/avatar";
import {Button, Buttons} from "../../../../../components/elements/button";
import {Header} from "../../../../../components/elements/header";
import {Label} from "../../../../../components/elements/label";
import {Paragraph, WhiteBlock} from "../../../../../components/elements/paragraph";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {saveDiagnosis} from "../../../../services/consultationService";
import {formChangeField} from "../../../../services/system/formService";
import {modalShow} from "../../../../services/system/modalService";
import {
    downloadBookingFile,
    downloadHealthConditionVideo,
    getBookingHealthConditionVideoLink,
    removeBookingFile,
    saveBookingFile
} from "../../../../services/bookingService";
import {File, FilePreviewItem, FilePreviewItems} from "../../../../../components/elements/file";
import {Textarea} from "../../../../../components/elements/textarea";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import Rescheduling from "./Rescheduling";
import PaymentDetail from "./payment/PaymentDetail";
import {getFileExt, getSex, getTemperatureUnitToUnitTemperature, isModal} from "../../../../../utils/app";
import Tooltip from "../../../../systems/tooltip";
import * as filesize from "filesize";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../../actions/preloaderAction";
import VideoPlay from "../../../../systems/videoPlay";
import BookAnAppointment from "./BookAnAppointment";
import {FormattedMessage} from "react-intl";
import {canceledExtraConsultation} from "../../../../services/extraConsultationsService";
import {Icon} from "../../../../../components/elements/icon";

class BookingDetailDescription extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    playHealthConditionVideo(booking) {
        (async () => {
            this.props.onPageShowPreloader();
            const src = await getBookingHealthConditionVideoLink(booking);
            this.props.onModalShow("videoPlay", {src: src});
            this.props.onPageHidePreloader();
        })();
    }

    onChange(event) {
        this.props.onFormChangeField('consultation', event);
    }

    onChangeFile(event) {
        const {form: {data}} = this.props;
        const {booking} = data;
        const files = event.target.files;
        this.props.onSaveBookingFile(booking, files);
    }

    render() {
        const {
            list: {data:listData},
            form: {data, errors: formErrors},
            modal,
            auth: {user},
            print = false,
            isCallBookingDetail = false
        } = this.props;
        const {extraConsultations = []} = listData;
        const {booking = {}, consultation = {}, systemSettings = {}} = data;
        const {isEnableInfermedica = null} = systemSettings || {};
        const {
            patient = {}, symptoms = [], description, sharpPain, temperature, timeOfReceipt, preliminaryDiagnosis,
            completedAt, files, typeConsultation, levelCriticalState, healthConditionVideo, rescheduling, payment = {},
            status, statusDetail, doctor = {}, address
        } = booking;
        const unitTemperature = user.unitTemperature;
        const isBookingDoctor = booking && booking.doctor && user && booking.doctor.id == user.id;
        const isBookingPatient = booking && booking.patient && user && booking.patient.id == user.id;
        const isStartedConsultation = booking.consultation && booking.consultation.isStarted === true;
        const {firstName, lastName, sex, birthday, phone} = patient;
        const {diagnosis = "", startedAt} = consultation;
        const errors = formErrors.consultation;
        return (
            <div className="row content-row">
                <div className="col-md-7">
                    {(booking.status === 'APPROVED' || booking.status === 'COMPLETED') &&
                        <WhiteBlock>
                            <GridRow>
                                <GridCol count="1">
                                    <Header as="h3"><FormattedMessage
                                        id="booking.detail.description.diagnosis"/></Header>
                                </GridCol>
                                <GridCol count="1">
                                    {(booking.status === 'COMPLETED' || print) &&
                                        <Paragraph>{diagnosis ||
                                            <FormattedMessage
                                                id="booking.detail.print.diagnosis.empty.label"/>}</Paragraph>}
                                    {!print && booking.status === 'APPROVED' && user.isEnabled && isBookingDoctor &&
                                        <EmptyTag>
                                            <Textarea
                                                placeholder={<FormattedMessage id="core.clinical.consultation.label"/>}
                                                name="diagnosis"
                                                error={(errors && errors.diagnosis) || null}
                                                value={diagnosis || ''}
                                                onChange={this.onChange}/>
                                            <Buttons>
                                                <Button
                                                    onClick={() => this.props.onSaveDiagnosis(consultation)}><FormattedMessage
                                                    id="core.btn.save.label"/></Button>
                                            </Buttons>
                                        </EmptyTag>}
                                </GridCol>
                            </GridRow>
                        </WhiteBlock>}
                    <WhiteBlock>
                        <GridRow>
                            <GridCol count="1">
                                <div className="row avatar-row align-items-center">
                                    <div className="col-auto avatar-row__image">
                                        <Avatar size="80"/>
                                    </div>
                                    <div className="col avatar-row__text">
                                        <div>{firstName} {lastName}</div>
                                        <div className="small-text color-gray">
                                            <div>{sex && getSex(sex)}
                                                {sex && ", "}
                                                {birthday && <FormattedMessage id="core.birthday.value"
                                                                               values={{value: moment().diff(birthday, 'years')}}/>}
                                            </div>
                                            <div>{phone}</div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        {!isCallBookingDetail &&
                                            <div className="small-controls align-right">
                                                {doctor.isEnabled &&
                                                    <div className="margin-bottom-15">
                                                        <Button
                                                            onClick={() => this.props.onBookAnAppointment({
                                                                patient: patient,
                                                                doctor: doctor
                                                            })}
                                                            color="white-blue"><FormattedMessage
                                                            id="booking.detail.chat.book-an-appointment"/></Button>
                                                    </div>}
                                                <Button
                                                    onClick={() => this.props.onPatientMedicalRecord({patient: patient})}
                                                    color="white-black"><FormattedMessage
                                                    id="booking.detail.chat.medical-record"/></Button>
                                            </div>}
                                    </div>
                                </div>
                            </GridCol>
                            <GridCol count="1">
                                <Header as="h3"><FormattedMessage id="booking.detail.patient-condition"/></Header>
                            </GridCol>

                            <GridCol count='1'>
                                <Label fixed={true}><FormattedMessage id="core.video.description.label"/></Label>
                                {booking.healthConditionVideo &&
                                    <FilePreviewItems>
                                        <FilePreviewItem
                                            onPlay={() => this.playHealthConditionVideo(booking)}
                                            name={<a href="#"
                                                     onClick={() => this.props.onDownloadHealthConditionVideo(booking)}>
                                                {healthConditionVideo.name}</a>}
                                            size={filesize(booking.healthConditionVideo.size, {separator: "."})}
                                            ext={getFileExt(booking.healthConditionVideo.name)}/>
                                    </FilePreviewItems>
                                }
                            </GridCol>

                            {address &&
                                <GridCol count="1">
                                    <Label fixed={true}><FormattedMessage id="location"/></Label>
                                    <Paragraph>{address.text}</Paragraph>
                                </GridCol>}

                            <GridCol count="1">
                                <Label fixed="true"><FormattedMessage id="core.description.label"/></Label>
                                <Paragraph>{description}</Paragraph>
                            </GridCol>
                            <GridCol count="1">
                                <Label fixed="true"><FormattedMessage id="core.symptoms.label"/></Label>
                                {symptoms &&
                                    <div className="tags-block">
                                        {symptoms.map(symptom => <span className="tag-item"
                                                                       key={symptom.id}>{symptom.commonName}</span>)}
                                    </div>
                                }
                            </GridCol>
                            <GridCol count="2">
                                <Label fixed="true"><FormattedMessage id="core.sharp.pain.label"/></Label>
                                <Paragraph>{sharpPain}</Paragraph>
                            </GridCol>
                            <GridCol count="2">
                                <Label fixed="true">
                                    <FormattedMessage id="core.temperature.label"
                                                      values={{unit: getTemperatureUnitToUnitTemperature(unitTemperature)}}/>
                                </Label>
                                <Paragraph>{temperature}</Paragraph>
                            </GridCol>
                        </GridRow>
                    </WhiteBlock>

                    {isEnableInfermedica && <WhiteBlock>
                        <div className="row form-elements form-heading-margin form-padding-small">

                            <div className="col-md-6">
                                <h3>Analyzed by system</h3>
                                <label className="field-label label-fixed">Prospective diagnosis</label>
                                <div className="text-block">
                                    <div
                                        className="input">{preliminaryDiagnosis ? preliminaryDiagnosis.commonName : '?'}</div>
                                </div>
                            </div>
                            <div className="col-md-6 align-right">
                                <h3>Priority Level:</h3>
                                <div className="font-size-40">
                                    <span className="color-red">{levelCriticalState || 0}</span><span
                                    className="color-gray">/5</span>
                                </div>
                            </div>
                        </div>
                    </WhiteBlock>}
                </div>

                <div className="col-md-5">
                    {isBookingDoctor && !isCallBookingDetail && extraConsultations.length > 0 && <div className="white-block">
                        <h3><FormattedMessage id="consultation.with.another.doctors"/></h3>
                        <div className="row form-elements form-bottom-bordered">
                            {extraConsultations.map(extraConsultation => {
                                const user = extraConsultation.user;
                                return (
                                    <div className="col-12" key={extraConsultation.id}>
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <Label fixed="1"><FormattedMessage id="doctor"/></Label>
                                                <Paragraph>
                                                    {user.firstName}&nbsp;{user.lastName}
                                                </Paragraph>
                                                <div className="icon-item small-text color-gray">
                                                    <Icon name="calendar-exclam-black" size="24"/>
                                                    {extraConsultation.status === 'REQUESTED' &&
                                                        <span className="icon-item__text"><FormattedMessage
                                                            id="status.requested"/></span>}
                                                    {extraConsultation.status === 'APPROVED' &&
                                                        <span className="icon-item__text"><FormattedMessage
                                                            id="status.approved"/></span>}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <Button color="white-red"
                                                        size='small'
                                                        onClick={() => this.props.onConfirm({
                                                            text: <FormattedMessage id="confirm.cancel"/>,
                                                            onProceed: (() => this.props.onCanceledExtraConsultation(extraConsultation.id))
                                                        })}>
                                                    <FormattedMessage id="core.btn.cancel.label"/>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )})}
                        </div>
                    </div>}

                    {!isCallBookingDetail &&
                        <div className="white-block">
                            <div className="row form-elements form-bottom-bordered">
                                <div className="col-12">
                                    <Label fixed="1"><FormattedMessage
                                        id="booking.detail.consultation.status.label"/></Label>
                                    <div className="text-block">
                                        <div className="input">
                                            <div className="nowrap">
                                                {status === 'REQUESTED' &&
                                                    <FormattedMessage id="booking.status.requested.label"/>}
                                                {status === 'APPROVED' &&
                                                    <FormattedMessage id="booking.status.approved.label"/>}
                                                {status === 'REJECTED' &&
                                                    <FormattedMessage id="booking.status.rejected.label"/>}
                                                {status === 'COMPLETED' &&
                                                    <FormattedMessage id="booking.status.completed.label"/>}
                                                {status === 'REJECTED' && statusDetail &&
                                                    <Tooltip><FormattedMessage
                                                        id="booking.detail.reason"/>{statusDetail}
                                                    </Tooltip>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    {!isCallBookingDetail &&
                        <div className="white-block">
                            <div className="row form-elements form-bottom-bordered">
                                <GridCol count="1">
                                    <Label fixed="1"><FormattedMessage id="core.type.consultation.label"/></Label>
                                    <Paragraph>
                                        {typeConsultation === 'CHAT' &&
                                            <FormattedMessage id="core.type.consultation.chat.label"/>}
                                        {typeConsultation === 'VIDEO' &&
                                            <FormattedMessage id="core.type.consultation.video.label"/>}
                                        {typeConsultation === 'AUDIO' &&
                                            <FormattedMessage id="core.type.consultation.audio.label"/>}
                                        {typeConsultation === 'OFFLINE' &&
                                            <FormattedMessage id="core.type.consultation.offline.label"/>}
                                    </Paragraph>
                                </GridCol>
                                <GridCol count="1">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <Label fixed="1"><FormattedMessage id="core.datetime.label"/></Label>
                                            <Paragraph>
                                                {moment(timeOfReceipt).format("lll")}
                                            </Paragraph>
                                        </div>
                                        <div className="col-auto">
                                            {(booking.status === 'APPROVED' || booking.status === 'REQUESTED') && !isStartedConsultation && isBookingDoctor && user.isEnabled &&
                                                <div className="small-controls margin-0">
                                                    <Button color="white-blue"
                                                            icon="clock-blue"
                                                            onClick={() => this.props.onRescheduling(booking)}><span><FormattedMessage
                                                        id="core.btn.reschedule.label"/></span></Button>
                                                </div>}
                                        </div>
                                    </div>
                                </GridCol>
                                {rescheduling != null &&
                                    <GridCol count="1">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <Label fixed="1"><FormattedMessage
                                                    id="doctor.create-appointment.reschedule-datetime"/></Label>
                                                <Paragraph>
                                                    {moment(rescheduling.timeOfReceipt).format("lll")}
                                                </Paragraph>
                                            </div>
                                            <div className="col-auto">
                                                <div className="small-text"><FormattedMessage
                                                    id="booking.request-reschedule"/>
                                                </div>
                                            </div>
                                        </div>
                                    </GridCol>}
                                {startedAt &&
                                    <GridCol count="1">
                                        <Label fixed="1"><FormattedMessage id="booking.start-consultation"/></Label>
                                        <Paragraph>
                                            {moment(startedAt).format("lll")}
                                        </Paragraph>
                                    </GridCol>}
                                {completedAt &&
                                    <GridCol count="1">
                                        <Label fixed="1"><FormattedMessage id="booking.end-consultation"/></Label>
                                        <Paragraph>
                                            {moment(completedAt).format("lll")}
                                        </Paragraph>
                                    </GridCol>
                                }
                            </div>
                        </div>}

                    <div className="white-block">
                        <Header as="h3"><FormattedMessage id="core.attachments.label"/></Header>
                        <div className="row form-elements form-bottom-bordered">
                            {files && files.map(file => {
                                return (
                                    <GridCol count="1" key={file.id}>
                                        <div className="text-ellipsis"><a href="#"
                                                                          onClick={() => this.props.onDownloadFile(booking, file)}>{file.name}</a>
                                        </div>
                                        {(booking.status == 'APPROVED') && user.isEnabled && (isBookingDoctor || isBookingPatient) &&
                                            <div className="controls-top-right">
                                                <button type="button"
                                                        className="btn-delete"
                                                        onClick={() => this.props.onConfirm({
                                                            text: <FormattedMessage id="confirm.remove.file"
                                                                                    values={{fileName: file.name}}/>,
                                                            onProceed: (() => this.props.onRemoveBookingFile(booking, file))
                                                        })}>×
                                                </button>
                                            </div>}
                                    </GridCol>
                                );
                            })}

                            {(booking.status == 'APPROVED') && user.isEnabled && (isBookingDoctor || isBookingPatient) &&
                                <GridCol count="1">
                                    <File name="file"
                                          onChange={(event) => this.onChangeFile(event)}><FormattedMessage
                                        id="core.btn.add.label"/></File>
                                </GridCol>
                            }
                        </div>
                    </div>
                    {payment && <PaymentDetail payment={payment} patient={booking.patient}/>}
                </div>
                {isModal(modal, 'rescheduling') && user.isEnabled && isBookingDoctor && <Rescheduling/>}
                {isModal(modal, 'videoPlay') && <VideoPlay/>}
                {isModal(modal, 'bookAnAppointment') && <BookAnAppointment/>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCanceledExtraConsultation: (id) => dispatch(canceledExtraConsultation(id)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onRescheduling: (booking) => dispatch(modalShow('rescheduling', {booking: booking})),
        onPatientMedicalRecord: (data) => dispatch(modalShow('patientMedicalRecord', data)),
        onBookAnAppointment: (data) => dispatch(modalShow('bookAnAppointment', data)),
        onDownloadHealthConditionVideo: (booking) => dispatch(downloadHealthConditionVideo(booking)),
        onDownloadFile: (booking, file) => dispatch(downloadBookingFile(booking, file)),
        onSaveDiagnosis: (consultation) => dispatch(saveDiagnosis(consultation)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onSaveBookingFile: (booking, file) => dispatch(saveBookingFile(booking, file)),
        onRemoveBookingFile: (booking, file) => dispatch(removeBookingFile(booking, file)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailDescription);
