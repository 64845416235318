import React, {Component} from 'react';
import {connect} from "react-redux";
import {NoticeBodyPage, NoticeHeader} from "../../../components/collections/notice";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {noticeHideAction} from "../../../actions/noticeAction";
import {PreloaderPage} from "../../../components/elements/preloader";
import {BackToHome} from "../../../components/elements/backTo";
import ChooseLanguage from "../../systems/choose_language";
import {isMobile, isDesktop, isAndroid, BrowserView, MobileView} from "react-device-detect";
import classnames from "classnames";
import {FormattedMessage} from "react-intl";

const NoticePage = (props) => {
    const {data, onClose} = props;
    const {title, textMsg} = data;
    return (
        <NoticeBodyPage title={title}>
            <p>{textMsg}</p>
            <GridRow width={300}>
                <GridCol count="1">
                    <BackToHome to={"/"} onClick={onClose}/>
                </GridCol>
            </GridRow>
        </NoticeBodyPage>
    )
};

class PublicLayout extends Component {
    render() {
        const {preloader, notice} = this.props;
        const wrapperClassNames = classnames('wrapper', {
            'wrapper-web-desktop': isDesktop,
            /*'wrapper-web-desktop': isDesktop || isMobile*/
            'wrapper-web-mobile': isMobile
        });

        const urlDownloadApp = isAndroid ?
            window._env_.DIP_LINK_BASE_URI +
            "?link=" + window._env_.BASE_URL +
            "&apn=" + window._env_.DIP_LINK_APN +
            "&apn=" + window._env_.DIP_LINK_APN +
            "&afl=" + encodeURIComponent(window._env_.DIP_LINK_AFL) +
            "&isi=" + window._env_.DIP_LINK_ISI +
            "&ibi=" + window._env_.DIP_LINK_IBI +
            '&ius=telemed&utm_campaign=telemed&utm_medium=telemed&utm_source=telemed' :
            window._env_.DIP_LINK_BASE_URI +
            "?link=" + encodeURIComponent(window._env_.BASE_URL + "&instance=" + window._env_.CORTEX_INSTANCE_ID) +
            "&apn=" + window._env_.DIP_LINK_APN +
            "&apn=" + window._env_.DIP_LINK_APN +
            "&afl=" + window._env_.DIP_LINK_AFL +
            "&isi=" + window._env_.DIP_LINK_ISI +
            "&ibi=" + window._env_.DIP_LINK_IBI +
            '&ius=telemed&utm_campaign=telemed&utm_medium=telemed&utm_source=telemed';
        return (
            <div className={wrapperClassNames}>
                {notice.header && <NoticeHeader type={notice.header.status}>{notice.header.textMsg}</NoticeHeader>}
                <table className="wrapper__table">
                    <tbody>
                    <tr>
                        <td>
                            <div className="public-block">
                                <ChooseLanguage/>
                                <BrowserView>
                                    {!notice.body ? this.props.children :
                                        <NoticePage data={notice.body} onClose={() => this.props.onHideNotice()}/>}
                                    {preloader.isShow && <PreloaderPage fullScreen={false}/>}
                                </BrowserView>
                                <MobileView>
                                    <h2 className="big-text"><FormattedMessage id="mobile.view.header"/></h2>
                                    <p><FormattedMessage id="mobile.view.description"/></p>
                                    <div className="public-block-main-btn">
                                        <a href={urlDownloadApp} className="btn-default btn-mobile">
                                            <div className="btn__content"><FormattedMessage id="download.app"/></div>
                                        </a>
                                    </div>
                                </MobileView>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth,
        notice: store.notice,
        webSocket: store.webSocket,
        preloader: store.preloader
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onHideNotice: () => dispatch(noticeHideAction())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);