import React, {Component} from 'react';
import {connect} from "react-redux";

import moment from "moment";

import {FormattedMessage} from "react-intl";
import EmergencyCalls from "./EmergencyCalls";
import ConsultationCalls from "./ConsultationCalls";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Header} from "../../../../components/elements/header";
import {GridCol} from "../../../../components/collections/grid";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import {listFilterFieldAction, listInitAction} from "../../../../actions/listAction";

class Calls extends Component {
    constructor(props) {
        super(props);
        this.onSearch = this.onSearch.bind(this);
        this.onChangeDatePeriod = this.onChangeDatePeriod.bind(this);
        this.state = {
            createdAtFrom: null,
            createdAtTo: null
        }
    }

    componentWillUnmount() {
        this.props.onInitList("calls");
    }

    onSearch(text) {
        this.props.onListFilterField("searchText", text);
        this.onPagination(1);
    }

    onChangeDatePeriod(objMoment, fieldName) {
        const fieldValue = moment(objMoment).isValid() ? objMoment.format("YYYY-MM-DD") : null
        this.props.onListFilterField(fieldName, fieldValue);
    }

    render() {
        const {tab: {data: tabData}} = this.props;
        const {calls: tabCalls = {}} = tabData;
        const {activeTabPanel: callsActiveTabPanel = "consultationCalls"} = tabCalls || {};

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="call.sessions"/></Header>
                    </div>
                </div>

                <div className="row content-heading">
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab
                                active={callsActiveTabPanel === 'consultationCalls'}
                                onClick={() => this.props.onChangeTabPanel("consultationCalls")}>
                                <FormattedMessage id="sessions.consultation.calls"/>
                            </Tab>
                            <Tab active={callsActiveTabPanel === 'emergencyCalls'}
                                 onClick={() => this.props.onChangeTabPanel("emergencyCalls")}>
                                <FormattedMessage id="sessions.emergency.calls"/>
                            </Tab>
                        </Tabs>
                    </GridCol>
                </div>

                {callsActiveTabPanel === 'consultationCalls' && <ConsultationCalls/>}
                {callsActiveTabPanel === 'emergencyCalls' && <EmergencyCalls/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("calls", tabPanelName)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("calls", fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calls);