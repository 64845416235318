import {listFetchAction} from "../../actions/listAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {modalHide} from "./system/modalService";
import {bellNoRingAction, bellRingAction} from "../../actions/bellAction";

export const fetchCountNoReadNotifications = () => {
    return (dispatch) => {
        return privateApi.get("/v1/users/notifications/totalNoRead")
            .then(response => {
                const {data} = response;
                if (data > 0) {
                    dispatch(bellRingAction());
                }
            });
    }
};

export const fetchNotifications = (pageNumber) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/users/notifications?pageNumber=" + pageNumber + '&sort=createdAt,desc')
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("notifications", content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchLastNotifications = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/users/notifications?pageSize=5&pageNumber=1&search=isRead,false")
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("lastNotifications", content, {...rest}));
                dispatch(bellNoRingAction());
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const removeNotification = (notification) => {
    return (dispatch, state) => {
        const pagination = state().list.pagination['notifications'] || {};
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/v1/users/notifications/" + notification.id)
            .then(response => {
                dispatch(fetchNotifications(pagination.number + 1));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};