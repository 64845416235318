import React, {Component} from "react";
import {Icon} from "../../../components/elements/icon";
import classnames from "classnames";

const TooltipButton = (props) => {
    const {icon} = props;
    return (
        <button type="button" className="tip-toggle">
            <div className="icon-item">
                <Icon name={icon}/>
            </div>
        </button>
    )
}

class Tooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTooltip: false
        };

        this.onShowTooltip = this.onShowTooltip.bind(this);
        this.onHideTooltip = this.onHideTooltip.bind(this);
    }

    componentDidMount() {
        this.setState({isOpened: false});
    }

    componentWillUnmount() {
        this.setState({isOpened: false});
    }

    onShowTooltip(event) {
        this.setState({isTooltip: true});
    }

    onHideTooltip(event) {
        this.setState({isTooltip: false});
    }

    render() {
        const {isTooltip} = this.state;
        const {
            isTooltipButton = true,
            tooltipElement = null,
            isBlock = false,
            align = "",
            icon = "error",
            margin = ""
        } = this.props;
        const blockClass = classnames('tip-wrapper tip-hover-mode', {
            'd-block': isBlock == true,
            'tip-dir-left': align === 'left',
            'margin-left-10': margin == 'left-10'
        });
        return (
            <div className={blockClass}>
                <div onMouseOver={this.onShowTooltip}
                     onMouseOut={this.onHideTooltip}>
                    {isTooltipButton && <TooltipButton icon={icon}/>}
                    {tooltipElement}
                </div>
                {isTooltip &&
                <div className="tip-block">
                    <div className="tip-text">{this.props.children}</div>
                </div>}
            </div>
        )
    }
}

export default Tooltip;