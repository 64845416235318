import React from 'react';
import classnames from "classnames";
import {InputError} from "../error";
import InputMask from "react-input-mask";
import {Label} from "../label";
import {EmptyTag} from "../emptyTag";
import Tooltip from "../../../containers/systems/tooltip";

export const Input = (prop) => {
    const {width = null,
        placeholderFixed = false, mask = null, placeholder = null, error = null, tooltip = "", hasError = false,
        bgWhite = null, simple = null, autocomplete = false, required = false, fixedTooltip, ...rest
    } = prop;
    const blockClass = classnames('text-block', {
        'width-320' : width == 320,
        'block-error': (error !== null || hasError),
        'field-bg-white': bgWhite !== null,
        'text-block-simple': simple !== null
    });

    const element = <div className={blockClass}>
        {mask && <InputMask placeholder={placeholder} mask={mask} type="text" {...rest} autoComplete="off"/>}
        {!mask && <input placeholder={placeholder} type="text" {...rest} autoComplete="off"/>}
        <Label fixed={placeholderFixed} required={required}>{placeholder}</Label>
        {fixedTooltip && <div className="text-block-tip">{fixedTooltip}</div>}
    </div>

    return (
        <EmptyTag>
            {tooltip &&
            <Tooltip tooltipElement={element} isTooltipButton={false} isBlock={true} align='left'>{tooltip}</Tooltip>}
            {!tooltip && element}

            {error !== null && <InputError>{error}</InputError>}
        </EmptyTag>
    );
};