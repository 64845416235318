import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from "moment";

class InfoPeriod extends Component {
    render() {
        const {title, dateFrom, dateTo} = this.props;

        return (
            <div className="white-block padding-20">
                <label className="field-label">{title}</label>
                <div className="middle-text bold color-blue">
                    {dateFrom && moment(dateFrom).format('ll')}&nbsp;-&nbsp;
                    {dateTo && moment(dateTo).format('ll')}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoPeriod);