import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Button, ButtonEdit} from "../../../../components/elements/button";
import {formInitAction} from "../../../../actions/formAction";
import {Input} from "../../../../components/elements/input";
import {fetchAccount, saveNewEmail} from "../../../services/userService";
import {formChangeField} from "../../../services/system/formService";
import {Paragraph} from "../../../../components/elements/paragraph";
import {Link} from "react-router-dom";

class ProfileAccountEmail extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.state = {
            isEdit: false
        }
    }

    componentWillUnmount() {
        //this.props.onInitForm("profileAccountEmail");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: curForm} = this.props;
        const {form: prevForm} = prevProps;
        const curIsSend = curForm.isSend.profileAccountEmail;
        const prevIsSend = prevForm.isSend.profileAccountEmail;
        const curErrors = curForm.errors.profileAccountEmail || {};

        if (!curIsSend && prevIsSend && Object.keys(curErrors).length == 0) {
            const {profileUserId} = this.props;
            this.props.onFetchAccount(profileUserId, "profileAccountEmail");
            this.setState({...this.state, isEdit: false});
        }
    }

    onChange(event) {
        this.props.onFormChangeField('profileAccountEmail', event);
    }

    onEdit() {
        const {auth: {user}} = this.props;
        //this.props.onInitForm("profileAccountEmail", {email: user.email});
        this.setState({...this.state, isEdit: !this.state.isEdit});
    }

    render() {
        const {form, auth: {user}, profileUserId} = this.props;
        const errors = form.errors.profileAccountEmail;
        const {email} = form.data.profileAccountEmail || {};
        const {isEdit} = this.state;
        return (

            <div className="row form-elements align-items-center">
                {!isEdit && <EmptyTag>
                    <div className="col-auto">
                        <label className="field-label label-fixed">
                            <FormattedMessage id="core.field.email.label"/>
                        </label>
                        <Paragraph>{email}</Paragraph>
                    </div>
                    <div className="col-auto">
                        <ButtonEdit onClick={() => this.onEdit()}/>
                    </div>
                </EmptyTag>}
                {user.newEmail && !isEdit &&
                <div className="col-12">
                    <FormattedMessage id="change.profile.account.email.success" values={{newEmail: user.newEmail}}/>
                    <div>
                        <Link to={"#"} onClick={() => this.props.onSaveNewEmail(profileUserId, user.newEmail)}><FormattedMessage
                            id="resend.email"/></Link>
                    </div>
                </div>}
                {isEdit && <EmptyTag>
                    <div className="col-6">
                        <Input name="email"
                               icon="email"
                               value={email || ''}
                               error={(errors && errors.email) || null}
                               placeholder={<FormattedMessage id="core.field.email.label"/>}
                               autocomplete="email"
                               onChange={this.onChange}/>
                    </div>
                    {user.id == profileUserId &&
                    <div className="col-6">
                        <div>
                            <FormattedMessage id="change.profile.account.email.description"/>
                        </div>
                    </div>}
                    <div className="col-12">
                        <div className="form-submit">
                            <Button
                                color="white-red"
                                onClick={() => this.onEdit()}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button
                                disabled={user && email == user.email}
                                onClick={() => this.props.onSaveNewEmail(profileUserId, email)}>
                                <FormattedMessage id="core.btn.send.label"/>
                            </Button>
                        </div>
                    </div>
                </EmptyTag>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchAccount: (userId, formName) => dispatch(fetchAccount(userId, formName)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onSaveNewEmail: (userId, email) => dispatch(saveNewEmail(userId, email))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAccountEmail);