import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../components/collections/grid";
import {Modal} from "../../../../components/modules/modal";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {Header} from "../../../../components/elements/header";
import {fetchPharmacyNewPrescriptions} from "../../../services/pharmacyService";
import {Button} from "../../../../components/elements/button";
import moment from "moment";
import {
    acceptMedication,
    completeMedication,
    issueMedication, medicationDurationFormat,
} from "../../../services/consultationService";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {getMedicationForm} from "../../../../utils/app";

class PrescriptionMedications extends Component {
    constructor(props) {
        super(props);
        this.onChangeStatusMedication = this.onChangeStatusMedication.bind(this);
        this.onModalHideNewPrescriptionMedications = this.onModalHideNewPrescriptionMedications.bind(this);
    }

    onModalHideNewPrescriptionMedications() {
        const {list: {pagination}} = this.props;
        const {pharmacyNewPrescriptions: {number}} = pagination;
        this.props.onModalHide();
        this.props.onFetchPharmacyNewPrescriptions(number + 1);
    }

    onChangeStatusMedication(booking, medication, status) {
        switch (status) {
            case "ACCEPTED" :
                this.props.onAcceptMedication(booking, medication);
                break;
            case "COMPLETED" :
                this.props.onCompleteMedication(booking, medication);
                break;
        }
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {prescriptionMedications: booking = {}} = formData;
        const {consultation: {medications, createdAtPrescribe}, doctor, patient} = booking;
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        return (
            <Modal width={960} close={true} onClose={() => this.onModalHideNewPrescriptionMedications()}>
                <Header as="h2">
                <FormattedMessage id="booking.detail.print.patient"
                                  values={{firstName: patient.firstName, lastName: patient.lastName}}/>
                </Header>
                <Header as="h3"><FormattedMessage id="page.pharmacy.today.issue.prescription.label"/> <span
                    className="color-blue"><FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></span>&nbsp;-&nbsp;
                    {joinSpecializations}{doctor.doctor.licenseNumber ? <EmptyTag><br/>License number: {doctor.doctor.licenseNumber}</EmptyTag> : ""}
                </Header>

                <div className="row form-elements">
                    <div className="col">
                        <Link title="Print page" target="_blank" to={'/pharmacies/prescriptions/' + booking.id + '/print'}>
                            <Button icon='print' circle color='op-gray' circleMiddleSize={true} controlPrint={true}/>
                        </Link>&nbsp;
                        #{booking.id}
                    </div>
                    <div className="col-auto">
                        {moment(createdAtPrescribe).format("ll")}
                    </div>

                    <GridCol count="1">
                        <div className="row tile-row">
                            {medications.map(medication => {
                                const {
                                    prescribedMedication, repeatPrescriptions, duration, directionsForUse
                                } = medication;
                                return (
                                    <GridCol count="2" key={medication.id}>
                                        <div
                                            className="row form-elements form-padding-15 form-bg-light form-radius-8">
                                            <GridCol count="1">
                                                <Header as="h4">{prescribedMedication}</Header>
                                            </GridCol>
                                            <GridCol count="1">
                                                <div className="small-text">
                                                    <div><span
                                                        className="color-gray">{<FormattedMessage id={getMedicationForm(medication.form)}/>}, {directionsForUse}</span>&nbsp;
                                                        {<FormattedMessage id={medicationDurationFormat(repeatPrescriptions, duration)}
                                                                           values={{repeat: repeatPrescriptions}}/>}
                                                    </div>
                                                </div>
                                            </GridCol>
                                            <GridCol count="1">
                                                <div className="align-right">
                                                    {medication.status == 'REQUESTED' &&
                                                    <Button color="white-blue"
                                                            onClick={() => this.props.onConfirm({
                                                                text: <FormattedMessage id="page.pharmacy.confirm.prescription.accept.text" values={{prescribedMedication: prescribedMedication}}/>,
                                                                onProceed: (() => this.onChangeStatusMedication(booking, medication, 'ACCEPTED'))
                                                            })}><FormattedMessage id="core.btn.accept.label"/></Button>}

                                                    {medication.status == 'ACCEPTED' &&
                                                    <Button color="white-blue"
                                                            onClick={() => this.props.onConfirm({
                                                                text: <FormattedMessage id="page.pharmacy.confirm.prescription.complete.text" values={{prescribedMedication: prescribedMedication}}/>,
                                                                onProceed: (() => this.onChangeStatusMedication(booking, medication, 'COMPLETED'))
                                                            })}><FormattedMessage id="core.btn.complete.label"/></Button>}
                                                </div>
                                            </GridCol>
                                        </div>
                                    </GridCol>
                                )
                            })}

                        </div>
                    </GridCol>
                </div>

            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onModalHide: () => dispatch(modalHide('prescriptionMedications')),
        onFetchPharmacyNewPrescriptions: (pageNumber) => dispatch(fetchPharmacyNewPrescriptions(pageNumber)),
        onAcceptMedication: (booking, medication) => dispatch(acceptMedication(booking, medication)),
        onCompleteMedication: (booking, medication) => dispatch(completeMedication(booking, medication)),
        onIssueMedication: (booking, medication) => dispatch(issueMedication(booking, medication))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionMedications);
