import React, {Component} from 'react';
import {connect} from "react-redux";

import {Modal} from "../../../../components/modules/modal";
import {modalHide} from "../../../services/system/modalService";
import {ScheduleItem} from "./ScheduleItem";
import moment from "moment";

class ShowAllAppointment extends Component {

    render() {
        const {form: {data: formData}, list: {data: listData}} = this.props;
        const {doctorSchedules = []} = listData;
        const {showAllAppointment} = formData;
        const {showAllOnDate} = showAllAppointment;
        return (
            <Modal close={true} onClose={() => this.props.onModalHide()}>
                <h2>{showAllOnDate.format("ll")}</h2>
                {doctorSchedules.map(i => {
                    const {timeOfReceipt, id} = i;
                    const key = moment(timeOfReceipt).format("YYYY-MM-DD HH:mm");
                    return (
                            <ScheduleItem item={i}
                                          detailScheduleKey={this.props.detailScheduleKey}
                                          itemKey={key}
                                          key={key}
                                          onCloseDetail={() => this.props.onCloseDetail()}
                                          onRemoveSchedule={() => this.props.onRemoveSchedule(id)}
                                          onDetail={() => this.props.onDetail(key)}
                            />
                    )
                })}
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: () => dispatch(modalHide('showAllAppointment')),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllAppointment);
