import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../components/collections/grid";
import {Header} from "../../../components/elements/header";
import moment from "moment";
import {medicationDurationFormat} from "../../services/consultationService";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {fetchBooking} from "../../services/bookingService";
import {getMedicationForm} from "../../../utils/app";
import {FormattedMessage} from "react-intl";

class PrescriptionMedicationsPrint extends Component {

    componentDidMount() {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};
        this.props.onFetchBooking(id);
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {booking = {}} = formData;
        const {consultation = {}, doctor, patient} = booking;
        const {medications = [], createdAtPrescribe, prescriptionStatus, pharmacy} = consultation;
        if (Object.keys(booking).length == 0) return "";
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h1">PrescriptionPatient: {patient && patient.firstName} {patient && patient.lastName}</Header>
                        <Header as="h2">
                            {pharmacy && <EmptyTag>
                                <FormattedMessage id="booking.detail.print.pharmacy"
                                                  values={{value: pharmacy.name}}/><br/></EmptyTag>}
                            {pharmacy && <EmptyTag>
                                <FormattedMessage id="booking.detail.print.address"
                                                  values={{value: pharmacy.address}}/><br/></EmptyTag>}
                            {doctor && <EmptyTag><FormattedMessage id="booking.detail.print.doctor" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/><br/></EmptyTag>}
                            {doctor && doctor.doctor.licenseNumber && <EmptyTag>
                                <FormattedMessage id="booking.detail.print.doctor.license.number"
                                                  values={{value: doctor && doctor.doctor.licenseNumber}}/><br/></EmptyTag>}
                            {patient && <EmptyTag><FormattedMessage id="booking.detail.print.patient"
                                                                    values={{firstName: patient.firstName, lastName: patient.lastName}}/></EmptyTag>}
                        </Header>
                    </div>

                </div>

                <div className='white-block'>

                    <div className="row form-elements">
                        <div className="col">#{booking.id}</div>
                        <div className="col-auto">
                            {moment(createdAtPrescribe).format("ll")} &nbsp;&nbsp;&nbsp;
                            {prescriptionStatus === 'REQUESTED' &&
                            <span className="color-gray"><FormattedMessage id="medication.status.requested.label"/> <span
                                className="inline-circle bg-color-gray"></span></span>}
                            {prescriptionStatus === 'ACCEPTED' &&
                            <span className="color-gray"><FormattedMessage id="medication.status.accepted.label"/>  <span
                                className="inline-circle bg-color-orange"></span></span>}
                            {prescriptionStatus === 'COMPLETED' &&
                            <span className="color-gray"><FormattedMessage id="medication.status.completed.label"/> <span
                                className="inline-circle bg-color-green"></span></span>}
                        </div>

                        <GridCol count="1">
                            <div className="row tile-row">
                                {medications.map(medication => {
                                    const {
                                        prescribedMedication, repeatPrescriptions, duration, directionsForUse,
                                        countOfIssues, lastIssueAt, nextIssueAt
                                    } = medication;
                                    return (
                                        <GridCol count="2" key={medication.id}>
                                            <div
                                                className="row form-elements form-padding-15 form-bg-light form-radius-8">
                                                <div className="col-md-7">
                                                    <Header as="h4">{prescribedMedication}</Header>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="align-right">

                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <div className="small-text">
                                                        <div><span
                                                            className="color-gray">{<FormattedMessage id={getMedicationForm(medication.form)}/>}, {directionsForUse}</span>&nbsp;
                                                            {<FormattedMessage id={medicationDurationFormat(repeatPrescriptions, duration)}
                                                                               values={{repeat: repeatPrescriptions}}/>}
                                                        </div>
                                                        {medication.status === 'COMPLETED' &&
                                                        <div>
                                                            <span className="color-gray"><FormattedMessage id="page.pharmacy.today.issue.latest.issue.label"/></span>&nbsp;
                                                            {lastIssueAt ? moment(lastIssueAt).format("ll") : "-"}
                                                        </div>}
                                                        {medication.status === 'COMPLETED' &&
                                                        <div>
                                                            <span className="color-gray"><FormattedMessage id="page.pharmacy.today.issue.next.issue.label"/></span>&nbsp;
                                                            {nextIssueAt ? moment(nextIssueAt).format("ll") : "-"}
                                                        </div>}
                                                    </div>
                                                    <div className="small-controls">

                                                    </div>
                                                </div>
                                                {medication.status === 'COMPLETED' &&
                                                <div className="col-md-5">
                                                    <div className="white-mark mark-right-bottom">
                                                        <FormattedMessage id="core.issued.label"/>
                                                        <div className="font-size-40">
                                                            <span
                                                                className="color-blue">{countOfIssues || 0}</span><span
                                                            className="color-gray">/{repeatPrescriptions}</span>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </GridCol>
                                    )
                                })}

                            </div>
                        </GridCol>
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchBooking: (id) => dispatch(fetchBooking(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionMedicationsPrint);
