import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Paragraph} from "../../../../../components/elements/paragraph";
import {GridCol} from "../../../../../components/collections/grid";
import {Label} from "../../../../../components/elements/label";

import {FormattedMessage} from "react-intl";
import {downloadBookingFile, downloadHealthConditionVideo} from "../../../../services/bookingService";
import {getTemperatureUnitToUnitTemperature} from "../../../../../utils/app";
import {Link} from "react-router-dom";

class DetailDescription extends Component {

    render() {
        const {form: {data}, auth: {user}} = this.props;
        const {booking = {}} = data;
        const {doctor = {}, symptoms = [], description, sharpPain, temperature,
            timeOfReceipt, files, typeConsultation, healthConditionVideo} = booking;
        const unitTemperature = user.unitTemperature;
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        return (
            <div className="white-block">
                <div className="row content-row">
                    <div className="col-md-6">
                        <div className="row form-elements">
                            <div className="col-12">
                                <label className="field-label label-fixed"><FormattedMessage id="core.consultation.id.label"/></label>
                                <div className="text-block">
                                    <div className="input">{booking.id}</div>
                                </div>
                            </div>
                            <div className="col-12">
                                <Label fixed={true}><FormattedMessage id="core.hcsp.or.doctor.label"/></Label>
                                <Paragraph>
                                    <Link to={"/patient/catalogDoctors/" + doctor.id + "/detail"}>
                                    <FormattedMessage id="core.doctor.info"
                                                      values={{firstName: doctor.firstName,
                                                          lastName: doctor.lastName,
                                                          specialization: joinSpecializations }}/>
                                    </Link>
                                </Paragraph>
                            </div>
                            <GridCol count="1">
                                <h3><FormattedMessage id="core.my.condition.label"/></h3>
                            </GridCol>
                            {healthConditionVideo &&
                            <GridCol count="1">
                                <Label fixed="true"><FormattedMessage id="core.video.description.label"/></Label>
                                <a href="#"
                                   onClick={() => this.props.onDownloadHealthConditionVideo(booking)}>
                                    {healthConditionVideo.name}</a>
                            </GridCol>}
                            <GridCol count="1">
                                <Label fixed={true}><FormattedMessage id="core.description.label"/></Label>
                                <Paragraph>{description}</Paragraph>
                            </GridCol>
                            <GridCol count="1">
                                <Label fixed={true}><FormattedMessage id="core.symptoms.label"/></Label>
                                {symptoms &&
                                <div className="tags-block">
                                    {symptoms.map(symptom => <span className="tag-item"
                                                                   key={symptom.id}>{symptom.commonName}</span>)}
                                </div>
                                }
                            </GridCol>
                            <GridCol count="1">
                                <Label fixed={true}><FormattedMessage id="core.sharp.pain.label"/></Label>
                                <Paragraph>{sharpPain}</Paragraph>
                            </GridCol>
                            <GridCol count="1">
                                <Label fixed={true}><FormattedMessage id="core.temperature.label"
                                                                      values={{unit: getTemperatureUnitToUnitTemperature(unitTemperature)}}/></Label>
                                <Paragraph>{temperature}</Paragraph>
                            </GridCol>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="row form-elements">
                            <GridCol count="1">
                                <Label fixed={true}><FormattedMessage id="core.type.consultation.label"/></Label>
                                <Paragraph>
                                    {typeConsultation === 'CHAT' && <FormattedMessage id="core.type.consultation.chat.label"/>}
                                    {typeConsultation === 'VIDEO' && <FormattedMessage id="core.type.consultation.video.label"/>}
                                    {typeConsultation === 'AUDIO' && <FormattedMessage id="core.type.consultation.audio.label"/>}
                                    {typeConsultation === 'OFFLINE' && <FormattedMessage id="core.type.consultation.offline.label"/>}
                                </Paragraph>
                            </GridCol>
                            <GridCol count="1">
                                <Label fixed={true}><FormattedMessage id="core.datetime.label"/></Label>
                                <Paragraph>{moment(timeOfReceipt).format("lll")}</Paragraph>
                            </GridCol>
                            <GridCol count="1">
                                <h3><FormattedMessage id="core.attachments.label"/></h3>
                                <div className="row form-elements form-bottom-bordered">
                                    {files && files.map(file => {
                                        return (
                                            <GridCol count="1" key={file.id}>
                                                <div className="text-ellipsis">
                                                    <a href="#"
                                                       onClick={() => this.props.onDownloadFile(booking, file)}>{file.name}</a>
                                                </div>
                                            </GridCol>
                                        );
                                    })}
                                    {(!files || files.length == 0) && <FormattedMessage id="booking.attachments.list.empty" />}
                                </div>
                            </GridCol>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDownloadFile: (booking, file) => dispatch(downloadBookingFile(booking, file)),
        onDownloadHealthConditionVideo: (booking) => dispatch(downloadHealthConditionVideo(booking)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailDescription);
