import React, {Component} from 'react';
import classnames from "classnames";
import {InputError} from "../../../components/elements/error";
import {Label} from "../../../components/elements/label";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {connect} from "react-redux";
import Tooltip from "../tooltip";

class Password extends Component {

    render() {
        const {error = null, placeholder = null, isWithTip = true, ...rest} = this.props;
        const blockClass = classnames('text-block', {
            'block-error': (error !== null)
        });
        const element = <div className={blockClass}>
            <input placeholder={placeholder}
                   type="password"
                   {...rest}
                   autoComplete="new-password"/>
            <Label>{placeholder}</Label>
        </div>;
        return (
            <EmptyTag>
                {isWithTip &&
                <Tooltip tooltipElement={element}  isTooltipButton={false} isBlock={true} align='left'>
                The password should be at least 8 characters long and include 1 uppercase, 1 lowercase and 1 number. Passwords are case sensitive.
                </Tooltip>}

                {!isWithTip && element}
                {error !== null && <InputError>{error}</InputError>}
            </EmptyTag>
        );
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);