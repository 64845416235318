import React, {Component} from 'react';
import {connect} from "react-redux";

import PublicLayout from "./PublicLayout";
import {Route} from "react-router-dom";

class PublicLayoutRouter extends Component {
    render() {
        const {component: Component, ...rest} = this.props;
        return (
            <Route {...rest} render={matchProps => (
                <PublicLayout {...rest}>
                    <Component {...matchProps} />
                </PublicLayout>
            )}/>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayoutRouter);