import React, {Component} from 'react';
import {connect} from "react-redux";
import {Route, Redirect} from 'react-router';

import PrintLayout from "./PrintLayout";
import {defineSystemLanguageUser} from "../../services/commonService";

class PrintLayoutRouter extends Component {
    componentDidMount() {
        const {auth} = this.props;
        const {user} = auth;
        this.props.onDefineSystemLanguageUser(user);
    }

    render() {
        const {authenticate = false, permitAll = false, hasAnyRoles = false, title, auth, component: Component, ...rest} = this.props;
        const {user} = auth;
        const roles = hasAnyRoles ? hasAnyRoles.split(",") : [];

        const isHasAccess = permitAll ||
            (authenticate && auth.isAuthenticated && !hasAnyRoles) ||
            (hasAnyRoles && auth.isAuthenticated && user != null && roles.findIndex(role => role === user.role) >= 0);
        const redirectTo = auth.isAuthenticated ? "/accessDenied" : "/users/sign-in";

        return (
            <Route {...rest}
                   render={matchProps => isHasAccess ? (
                       <PrintLayout title={title}>
                           <Component {...matchProps} />
                       </PrintLayout>
                   ) : (<Redirect to={redirectTo}/>)}
            />
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDefineSystemLanguageUser: (user) => dispatch(defineSystemLanguageUser(user))
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(PrintLayoutRouter);