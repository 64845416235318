import React, {Component} from 'react';
import {MenuItem} from "../../../components/collections/menu";
import {Label} from "../../../components/elements/label";
import classnames from 'classnames';
import {connect} from "react-redux";
import {dropdownNotVisibleAction} from "../../../actions/dropdownAction";
import Search from "../search";

export const DropdownItem = (props) => {
    const {...rest} = props;
    return (
        <DropdownWrapperItem>
            <MenuItem {...rest}>{props.children}</MenuItem>
        </DropdownWrapperItem>
    );
};

export const DropdownItemSimple = (props) => {
    const {active = false} = props;
    const dropdownItemClassNames = classnames('dropdown-item', {
        'active': active === true
    });
    return (
        <div className={dropdownItemClassNames} onClick={props.onClick} onChange={props.onChange}>
            {props.children}
        </div>
    );
};

export const DropdownItemSearch = (props) => {
    const {onSearch} = props;
    return (
        <div
             className="width-400">
            <Search onSearch={onSearch}/>
        </div>
    )
}

export const DropdownWrapperItem = (props) => {
    const {onClick} = props;
    return (
        <div className="dropdown-item" onClick={onClick}>
            {props.children}
        </div>
    );
};

export const DropdownButtonPoint = () => {
    return (
        <div className="icon-item">
            <i className="icon icon--points"/>
        </div>
    );
};

export const DropdownButtonDownload = () => {
    return (
        <div className="icon-item">
            <i className="icon icon--download"/>
            <span className="icon-item__text">Скачать</span>
        </div>
    );
};

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownVisible: false
        };
        this.globalClickListener = this.globalClickListener.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {dropdown: {isVisible: currIsVisible = false}} = this.props;
        const {dropdown: {isVisible: prevIsVisible = false}} = prevProps;

        if (prevIsVisible && !currIsVisible) {
            this.setState({dropdownVisible: false}, () => {
                document.removeEventListener('click', this.globalClickListener)
            })
        }
    }

    globalClickListener = (nativeEvent) => {
        const {dropdown: {isVisible}} = this.props;
        if (isVisible && this._dropdown_body && this._dropdown_body.contains(nativeEvent.target)) return;
        this.setState({dropdownVisible: false}, () => {
            document.removeEventListener('click', this.globalClickListener)
        })
    }

    toggleDropdown = (syntheticEvent) => {
        this.setState(prevState => ({dropdownVisible: !prevState.dropdownVisible}), () => {
            if (this.state.dropdownVisible && this.props.id !== "users") {
                document.addEventListener('click', this.globalClickListener)
            }
        })
    }

    componentWillUnmount() {
        this.props.onDropdownNotVisibleAction();
        document.removeEventListener('click', this.globalClickListener)
    }

    render() {
        const {
            button,
            label,
            optionSelected = false,
            noArrow = false,
            notification = false,
            arrow = false,
            disabled = false,
            required = false
        } = this.props;
        const {dropdownVisible} = this.state;
        const dropdownClassNames = classnames('dropdown-wrapper', {
            'dropdown-opened': dropdownVisible,
            'dropdown-selected': optionSelected,
            'dropdown-no-arrow': noArrow,
            'dropdown-notifications': notification
        });
        const dropdownButtonClassNames = classnames('dropdown-toggle', {
            'dropdown-toggle-arrow': arrow
        });

        return (
            <div className={dropdownClassNames}>
                {button && <div className={dropdownButtonClassNames} onClick={this.toggleDropdown}>{button}</div>}
                {!disabled && dropdownVisible && (
                    <div className="dropdown-menu"
                         ref={ref => this._dropdown_body = ref}>
                        {this.props.children}
                    </div>
                )}
                {label && <Label required={required}>{label}</Label>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        dropdown: store.dropdown
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDropdownNotVisibleAction: () => dispatch(dropdownNotVisibleAction())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
