import {MODAL_SHOW, MODAL_HIDE} from "../constants/actionTypes";

const initialState = {
    shown: []
};

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_SHOW:
            return {...state, shown: state.shown.concat([action.name])};
        case MODAL_HIDE:
            return {...state, shown: state.shown.filter(name => name !== action.name)};
        default:
            return state;
    }
};

export default modalReducer;