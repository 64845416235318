import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../components/collections/grid";
import {Modal} from "../../../../components/modules/modal";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {Header} from "../../../../components/elements/header";
import {fetchPharmacyPatientPrescriptions} from "../../../services/pharmacyService";
import {Button} from "../../../../components/elements/button";
import moment from "moment";
import {
    acceptMedication,
    completeMedication,
    issueMedication, medicationDurationFormat,
} from "../../../services/consultationService";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {getMedicationForm} from "../../../../utils/app";

class PrescriptionMedications extends Component {
    constructor(props) {
        super(props);
        this.onChangeStatusMedication = this.onChangeStatusMedication.bind(this);
        this.onModalHidePrescriptionMedications = this.onModalHidePrescriptionMedications.bind(this);
    }

    onModalHidePrescriptionMedications() {
        const {form: {data: formData}, list: {pagination}} = this.props;
        const {prescriptionMedications: booking = {}} = formData;
        const {pharmacyPatientPrescriptions: {number}} = pagination;
        this.props.onModalHide();
        this.props.onFetchPharmacyPatientPrescriptions(booking.patient, number + 1);
    }

    onChangeStatusMedication(booking, medication, status) {
        switch (status) {
            case "ACCEPTED" :
                this.props.onAcceptMedication(booking, medication);
                break;
            case "COMPLETED" :
                this.props.onCompleteMedication(booking, medication);
                break;
        }
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {prescriptionMedications: booking = {}} = formData;
        const {consultation: {medications, createdAtPrescribe, prescriptionStatus}, doctor} = booking;
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        return (
            <Modal width={960} close={true} onClose={() => this.onModalHidePrescriptionMedications()}>
                <Header as="h2"><FormattedMessage id="page.pharmacy.today.issue.prescription.label"/> <span
                    className="color-blue"><FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></span>&nbsp;-&nbsp;
                    {joinSpecializations}{doctor.doctor.licenseNumber ? <EmptyTag><br/>
                    <FormattedMessage id="core.license.number.label"/> {doctor.doctor.licenseNumber}</EmptyTag> : ""}</Header>

                <GridCol count="1">
                    <div className="row form-elements">
                        <div className="col">
                            <Link title="Print page" target="_blank" to={'/pharmacies/prescriptions/' + booking.id + '/print'}>
                                <Button icon='print' circle color='op-gray' circleMiddleSize={true} controlPrint={true}/>
                            </Link>&nbsp;
                            #{booking.id}
                        </div>
                        <div className="col-auto">
                            {moment(createdAtPrescribe).format("ll")} &nbsp;&nbsp;&nbsp;
                            {prescriptionStatus === 'REQUESTED' &&
                            <span className="color-gray"><FormattedMessage id="medication.status.requested.label"/> <span
                                className="inline-circle bg-color-gray"></span></span>}
                            {prescriptionStatus === 'ACCEPTED' &&
                            <span className="color-gray"><FormattedMessage id="medication.status.accepted.label"/>  <span
                                className="inline-circle bg-color-orange"></span></span>}
                            {prescriptionStatus === 'COMPLETED' &&
                            <span className="color-gray"><FormattedMessage id="medication.status.completed.label"/> <span
                                className="inline-circle bg-color-green"></span></span>}
                        </div>

                        <GridCol count="1">
                            <div className="row tile-row">
                                {medications.map(medication => {
                                    const {
                                        prescribedMedication, repeatPrescriptions, duration, directionsForUse,
                                        countOfIssues, lastIssueAt, nextIssueAt
                                    } = medication;
                                    return (
                                        <GridCol count="2" key={medication.id}>
                                            <div
                                                className="row form-elements form-padding-15 form-bg-light form-radius-8">
                                                <div className="col-md-7">
                                                    <Header as="h4">{prescribedMedication}</Header>
                                                </div>
                                                <div className="col-md-5">
                                                    <div className="align-right">

                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <div className="small-text">
                                                        <div><span
                                                            className="color-gray">{<FormattedMessage id={getMedicationForm(medication.form)}/>}, {directionsForUse}</span>&nbsp;
                                                            {<FormattedMessage id={medicationDurationFormat(repeatPrescriptions, duration)}
                                                                               values={{repeat: repeatPrescriptions}}/>}
                                                        </div>
                                                        {medication.status === 'COMPLETED' &&
                                                        <div>
                                                            <span className="color-gray"><FormattedMessage id="page.pharmacy.today.issue.latest.issue.label"/></span>&nbsp;
                                                            {lastIssueAt ? moment(lastIssueAt).format("ll") : "-"}
                                                        </div>}
                                                        {medication.status === 'COMPLETED' &&
                                                        <div>
                                                            <span className="color-gray"><FormattedMessage id="page.pharmacy.today.issue.next.issue.label"/></span>&nbsp;
                                                            {nextIssueAt ? moment(nextIssueAt).format("ll") : "-"}
                                                        </div>}
                                                    </div>
                                                    <div className="small-controls">
                                                        {medication.status == 'REQUESTED' &&
                                                        <Button onClick={() => this.props.onConfirm({
                                                            text: <FormattedMessage id="page.pharmacy.confirm.prescription.accept.text" values={{prescribedMedication: prescribedMedication}}/>,
                                                            onProceed: (() => this.onChangeStatusMedication(booking, medication, 'ACCEPTED'))
                                                        })}><FormattedMessage id="core.btn.accept.label"/></Button>}

                                                        {medication.status == 'ACCEPTED' &&
                                                        <Button onClick={() => this.props.onConfirm({
                                                            text: <FormattedMessage id="page.pharmacy.confirm.prescription.complete.text" values={{prescribedMedication: prescribedMedication}}/>,
                                                            onProceed: (() => this.onChangeStatusMedication(booking, medication, 'COMPLETED'))
                                                        })}><FormattedMessage id="core.btn.complete.label"/></Button>}

                                                        {medication.status === 'COMPLETED' && <Button
                                                            disabled={countOfIssues && repeatPrescriptions && countOfIssues == repeatPrescriptions}
                                                            onClick={() => this.props.onConfirm({
                                                                text: <FormattedMessage id="page.pharmacy.confirm.prescription.issue.text" values={{prescribedMedication: prescribedMedication}}/>,
                                                                onProceed: (() => this.props.onIssueMedication(booking, medication))
                                                            })}><FormattedMessage id="core.btn.issuied.label"/></Button>}
                                                    </div>
                                                </div>
                                                {medication.status === 'COMPLETED' &&
                                                <div className="col-md-5">
                                                    <div className="white-mark mark-right-bottom">
                                                        <FormattedMessage id="core.issued.label"/>
                                                        <div className="font-size-40">
                                                            <span
                                                                className="color-blue">{countOfIssues || 0}</span><span
                                                            className="color-gray">/{repeatPrescriptions}</span>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </GridCol>
                                    )
                                })}

                            </div>
                        </GridCol>
                    </div>
                </GridCol>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onModalHide: () => dispatch(modalHide('prescriptionMedications')),
        onFetchPharmacyPatientPrescriptions: (patient, pageNumber) => dispatch(fetchPharmacyPatientPrescriptions(patient, pageNumber)),
        onAcceptMedication: (booking, medication) => dispatch(acceptMedication(booking, medication)),
        onCompleteMedication: (booking, medication) => dispatch(completeMedication(booking, medication)),
        onIssueMedication: (booking, medication) => dispatch(issueMedication(booking, medication))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionMedications);
