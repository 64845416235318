import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";

import SelectProvider from "./SelectProvider";
import {BackTo} from "../../../../../components/elements/backTo";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {Header} from "../../../../../components/elements/header";
import {Button, ButtonIcon, ButtonRemove} from "../../../../../components/elements/button";
import {Icon, IconItem} from "../../../../../components/elements/icon";
import {isModal} from "../../../../../utils/app";
import {modalShow} from "../../../../services/system/modalService";
import {
    calendarAuthorization,
    fetchAccountCalendars,
    removeAccountCalendar
} from "../../../../services/AccountCalendarsService";
import qs from "query-string";

class AccountCalendars extends Component {
    componentDidMount() {
        const p = this.props.location.search;
        const pathname = this.props.location.pathname || "";
        const {code = "", scope = ""} = qs.parse(p);

        if (code && pathname.indexOf("google-") != -1) {
            this.props.onCalendarAuthorization(code, 'google');
        } else if (code && pathname.indexOf("outlook-") != -1) {
            this.props.onCalendarAuthorization(code, 'outlook');
        } else {
            this.props.onFetchAccountCalendars();
        }
    }


    render() {
        const {list: {data: listData}, modal} = this.props;
        const {accountCalendars = []} = listData;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to="/doctor/schedule/configure"/>
                    </div>
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="settings"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls"></div>
                </div>

                <div className="row content-row">
                    <div className="col-12">
                        <div className="white-block">
                            <div className="row">
                                <div className="col">
                                    <Header as="h3"><FormattedMessage id="calendar.accounts"/></Header>
                                </div>
                                <div className="col-auto">
                                    <ButtonIcon color={'blue'}
                                                onClick={() => this.props.onModalShow("selectProvider", {})}>
                                        <IconItem name={'plus-bordered'}>
                                            <FormattedMessage id="add.calendar.account"/>
                                        </IconItem>
                                    </ButtonIcon>
                                </div>
                            </div>
                            <hr/>
                            {accountCalendars.length == 0 && <div className="no-results-block">
                                <Icon name="calendar-middle"/>
                                <div><FormattedMessage id="empty.list.account.calendars.description"/></div>
                                <Button onClick={() => this.props.onModalShow("selectProvider", {})}>
                                    <FormattedMessage id="core.btn.add.label"/>
                                </Button>
                            </div>}
                            {accountCalendars.length > 0 &&
                                <div className="row form-elements form-bottom-bordered">
                                    {accountCalendars.map(i => {
                                        return (
                                            <div className="col-12" key={i.id}>
                                                <div className="row">
                                                    <div className="col">
                                                        {i.type === 'GOOGLE' &&
                                                            <IconItem name={'calendar-google'} size={50}>
                                                                {i.email ? i.email : <FormattedMessage id="google"/>}
                                                            </IconItem>}
                                                        {i.type === 'OUTLOOK' &&
                                                            <IconItem name={'calendar-outlook'} size={50}>
                                                                {i.email ? i.email : <FormattedMessage id="outlook"/>}
                                                            </IconItem>}
                                                    </div>
                                                    <div className="col-auto relative-controls">
                                                        <ButtonRemove onClick={() => this.props.onConfirm({
                                                            text: <FormattedMessage
                                                                id="confirm.remove.item.account.calendar"/>,
                                                            onProceed: (() => this.props.onRemoveAccountCalendar(i.id))
                                                        })}/>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>}
                        </div>
                    </div>
                </div>
                {isModal(modal, 'selectProvider') && <SelectProvider/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onRemoveAccountCalendar: (id) => dispatch(removeAccountCalendar(id)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onCalendarAuthorization: (code, type) => dispatch(calendarAuthorization(code, type, ownProps)),
        onModalShow: (modalName, modalData) => dispatch(modalShow(modalName, modalData)),
        onFetchAccountCalendars: () => dispatch(fetchAccountCalendars())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountCalendars));
