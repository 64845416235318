import {privateApi, publicApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {formErrors, formIsSend} from "./system/formService";
import {modalHide} from "./system/modalService";
import moment from "moment";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {formChangeFieldAction, formInitAction} from "../../actions/formAction";
import {updateProfileAction} from "../../actions/authAction";
import {prepareFilter} from "../../utils/app";

export const fetchFamilyMembers = (listName = "familyMembers") => {
    return (dispatch, state) => {
        const user = state().auth.user;
        const filter = prepareFilter(state().list.filters[listName] || {});
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/familyMembers?search=" + filter)
            .then(response => {
                const {data = []} = response;
                dispatch(listFetchAction(listName, data, {}));
                dispatch(updateProfileAction({...user, isHasFamily: data.length > 0}));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchMember = (id, callback = null, formName = "familyMember") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/familyMembers/" + id)
            .then(response => {
                dispatch(formInitAction(formName, response.data))
                if (callback) {
                    callback(response.data);
                }
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchFamilyMemberTypes = (listName = "familyMemberTypes") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/public/v1/familyMemberTypes")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction(listName, data, {}));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const createMember = (data) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("createMember", true));
        if (!data["child"]) data["child"] = {};
        let dataChildPrepare = {...data["child"], birthday: null};
        if (data["child"] && data["child"]["birthday"]) {
            const birthday = moment(data["child"]["birthday"], "DD.MM.YYYY").format("YYYY-MM-DD")
            dataChildPrepare["birthday"] = birthday;
        }
        const dataPrepare = {...data, child: dataChildPrepare};
        return privateApi.post("/v1/familyMembers", dataPrepare)
            .then(response => {
                dispatch(modalHide('createMember'));
                dispatch(fetchFamilyMembers());
                dispatch(updateProfileAction({...user, isHasFamily: true}));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createMember", error.response.data.details));
                } else {
                    dispatch(modalHide('createMember'))
                }
            }).finally(() => {
                dispatch(formIsSend("createMember", false));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const activateMember = (id) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("confirm"));
        return privateApi.post("/v1/familyMembers/" + id + "/activate")
            .then(response => {
                dispatch(fetchFamilyMembers());
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const deactivateMember = (id) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("confirm"));
        return privateApi.post("/v1/familyMembers/" + id + "/deactivate")
            .then(response => {
                dispatch(fetchFamilyMembers());
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, <FormattedMessage id="family.member.deactivate.success"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const detachMember = (id, callback = null) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("confirm"));
        return privateApi.post("/v1/familyMembers/" + id + "/detach")
            .then(response => {
                if (callback) {
                    callback()
                } else {
                    dispatch(fetchFamilyMembers());
                }
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const detachRejectMember = (id, callback = null) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("confirm"));
        return privateApi.post("/v1/familyMembers/" + id + "/detachReject")
            .then(response => {
                if (callback) {
                    callback()
                } else {
                    dispatch(fetchFamilyMembers());
                }
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const detachRequestMember = (id) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("confirm"));
        return privateApi.post("/v1/familyMembers/" + id + "/detachRequest")
            .then(response => {
                dispatch(formChangeFieldAction("familyMember", "isDetachRequest", true));
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, <FormattedMessage
                    id="request.detach.success"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const completeSignUpAsFamilyMember = (token, data) => {
    return (dispatch) => {
        dispatch(formIsSend("completeSignUpAsFamilyMember", true));
        return publicApi.post("/users/completeSignUpAsFamilyMember", {...data, token: token})
            .then(response => {
                const textMsg = <FormattedMessage id="notice.doctor.sign.up.complete.msg.success.text"/>;
                return dispatch(noticeShowAction(<FormattedMessage
                    id="notice.family.member.sign.up.complete.title"/>, textMsg));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {token = ""} = error.response.data.details || {};
                    if (token) {
                        return dispatch(noticeShowAction(<FormattedMessage
                            id="notice.family.member.sign.up.complete.title"/>, token));
                    }
                    dispatch(formErrors("completeSignUpAsFamilyMember", error.response.data.details));
                } else if (error.response.status === 403) {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.message));
                }
                dispatch(formIsSend("completeSignUpAsFamilyMember", false));
            });
    }
};