import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import Calendar from "react-calendar/dist/entry.nostyle";
import moment from "moment";
import {
    fetchDoctorScheduleAvailableAppointmentDays, fetchReschedulingStateAppointmentTimes,
    fetchStateAppointmentTimes
} from "../../../../services/bookingService";
import {formChangeField} from "../../../../services/system/formService";
import {RadioBox} from "../../../../../components/elements/radiobox";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../../actions/preloaderAction";
import {Header} from "../../../../../components/elements/header";
import {FormattedMessage} from "react-intl";
import {getCalendarLocale} from "../../../../../utils/api";
import {listInitDataAction} from "../../../../../actions/listAction";

class ChooseTimeOfReceipt extends Component {
    constructor(props) {
        super(props);
        this.selectDay = this.selectDay.bind(this);
        this.incrementMonth = this.incrementMonth.bind(this);
        this.decrementMonth = this.decrementMonth.bind(this);
        this.fetchSchedule = this.fetchSchedule.bind(this);
        this.state = {
            activeMonth: new Date().getMonth() + 1,
            activeYear: new Date().getFullYear(),
            chosenDate: new Date(),
            chosenFormattedDate: "",

        }
    }

    fetchSchedule(month, year) {
        const {doctor, typeConsultation, address} = this.props;
        const dateStart = moment().year(year).month(month - 1).date(1);
        const dateEnd = moment().year(year).month(month - 1)
            .date(1).add(1, "month").add(-1, "day");
        const dateStartFormat = dateStart.format("YYYY-MM-DD");
        const dateEndFormat = dateEnd.format("YYYY-MM-DD");
        this.props.onFetchDoctorScheduleAvailableAppointmentDays(
            doctor, typeConsultation, address, dateStartFormat, dateEndFormat);
    }

    componentDidMount() {
        const {timeOfReceipt = null} = this.props;
        let {activeMonth, activeYear, chosenDate} = this.state;
        if (timeOfReceipt != null) {
            chosenDate = new Date(parseInt(timeOfReceipt));
            activeMonth = chosenDate.getMonth() + 1;
            activeYear = chosenDate.getFullYear();
            this.setState({...this.state, chosenDate: chosenDate, activeMonth: activeMonth, activeYear: activeYear});
        } else {
            this.props.onListInitDataAction("stateAppointmentTimes", []);
        }
        this.fetchSchedule(activeMonth, activeYear);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData}} = this.props;
        const {form: {data: prevFormData}} = prevProps;
        const {typeConsultation: curTypeConsultation = null} = curFormData.createBooking || {};
        const {typeConsultation: prevTypeConsultation = null} = prevFormData.createBooking || {};
        const {address: curAddress = null} = curFormData.advanceParamsCreateBooking || {};
        const {address: prevAddress = null} = prevFormData.advanceParamsCreateBooking || {};

        if ((curTypeConsultation != null && curTypeConsultation != prevTypeConsultation) ||
            (curAddress != null && curAddress != prevAddress)) {
            this.componentDidMount();
        }
    }

    incrementMonth() {
        const {activeMonth, activeYear} = this.state;
        const newMonth = activeMonth === 11 ? 0 : activeMonth + 1;
        const newYear = activeMonth === 11 ? activeYear + 1 : activeYear;

        this.fetchSchedule(newMonth, newYear);
        this.setState({activeMonth: newMonth, activeYear: newYear});
        this.props.onListInitDataAction("stateAppointmentTimes", []);
    }

    decrementMonth() {
        const {activeMonth, activeYear} = this.state;
        const newMonth = activeMonth === 0 ? 11 : activeMonth - 1;
        const newYear = activeMonth === 0 ? activeYear - 1 : activeYear;
        const dateStart = moment().set({'year': newYear, 'month': newMonth + 1, 'date': 1});
        const dateEnd = moment().set({'year': newYear, 'month': newMonth + 1, 'date': dateStart.daysInMonth()});

        if (moment().isAfter(dateEnd)) return false;

        this.fetchSchedule(newMonth, newYear);
        this.setState({activeMonth: newMonth, activeYear: newYear});
        this.props.onListInitDataAction("stateAppointmentTimes", []);
    }

    selectDay(value) {
        const {doctor, typeConsultation, address, isRescheduling = false} = this.props;
        const chosenDate = moment(value);
        const dateStartFormat = chosenDate.format("YYYY-MM-DD");
        const dateEndFormat = chosenDate.format("YYYY-MM-DD");

        this.setState({...this.state, chosenDate: value});
        if (isRescheduling) {
            this.props.onFetchReschedulingStateAppointmentTimes(
                doctor, typeConsultation, address, dateStartFormat, dateEndFormat);
        } else {
            this.props.onFetchStateAppointmentTimes(
                doctor, typeConsultation, address, dateStartFormat, dateEndFormat);
        }
    }

    render() {
        const {list: {data: listData}, timeOfReceipt} = this.props;
        const {availableAppointmentDays = [], stateAppointmentTimes = []} = listData;
        const {chosenDate} = this.state;
        const NextLabelComponent = () => <div onClick={this.incrementMonth}>&rsaquo;</div>;
        const PrevLabelComponent = () => <div onClick={this.decrementMonth}>&lsaquo;</div>;

        return (
            <GridRow>
                <GridCol count='1'>
                    <Calendar
                        activeStartDate={new Date()}
                        calendarType={"US"}
                        locale={getCalendarLocale()}
                        view="month"
                        name='calendar'
                        minDate={new Date()}
                        minDetail='month'
                        prev2Label={null}
                        next2Label={null}
                        nextLabel={<NextLabelComponent/>}
                        prevLabel={<PrevLabelComponent/>}
                        tileDisabled={({date}) => availableAppointmentDays.findIndex(v => moment(v).format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) === -1}
                        onClickDay={(value) => this.selectDay(value)}
                        showNeighboringMonth={false}
                        value={chosenDate}
                    />
                </GridCol>
                {Object.keys(stateAppointmentTimes).length > 0 &&
                <GridCol count='1'>
                    <Header as="h3" align="center"><FormattedMessage id="core.time.label"/></Header>
                    {
                        Object.keys(stateAppointmentTimes).map((key) => {
                            const tms = parseInt(key);
                            const isChecked = timeOfReceipt != null && parseInt(timeOfReceipt) === parseInt(key);
                            const isDisabled = !isChecked && (stateAppointmentTimes[key] == false || (tms / 1000) < moment().unix());
                            return (<RadioBox asButton={true}
                                              key={key}
                                              name='timeOfReceipt'
                                              onChange={this.props.onChangeTime}
                                              value={tms}
                                              checked={isChecked}
                                              disabled={isDisabled}>{moment(tms).format("LT")}</RadioBox>)
                        })
                    }

                </GridCol>
                }
            </GridRow>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onListInitDataAction : (listName, data) => dispatch(listInitDataAction(listName, data)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onFetchReschedulingStateAppointmentTimes: (doctor, typeConsultation, address, dateStart, dateEnd) =>
            dispatch(fetchReschedulingStateAppointmentTimes(doctor, typeConsultation, address, dateStart, dateEnd)),
        onFetchStateAppointmentTimes: (doctor, typeConsultation, address, dateStart, dateEnd) =>
            dispatch(fetchStateAppointmentTimes(doctor, typeConsultation, address, dateStart, dateEnd)),
        onFetchDoctorScheduleAvailableAppointmentDays: (doctor, typeConsultation, address, dateStart, dateEnd) =>
            dispatch(fetchDoctorScheduleAvailableAppointmentDays(doctor, typeConsultation, address, dateStart, dateEnd))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTimeOfReceipt);
