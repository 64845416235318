import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {listFilterFieldAction, listInitAction} from "../../../../actions/listAction";
import DoctorBookingsByStatus from "../bookings/list";
import {Button} from "../../../../components/elements/button";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {Redirect} from "react-router";

class DoctorSchedule extends Component {
    componentWillUnmount() {
        this.props.onInitList("bookings");
    }

    render() {
        const {auth: {user}} = this.props;
        if (user.role === 'DOCTOR' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="core.header.schedule"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                        {user.isFillProfile && user.isEnabled &&
                        <Link to={'/doctor/schedule/configure'}>
                            <Button><FormattedMessage id="doctor.schedule.configure-schedule"/></Button>
                        </Link>}
                    </div>
                </div>
                <div className="row content-row">
                    <DoctorBookingsByStatus statuses={"REQUESTED-APPROVED"} doctor={user} sort={'timeOfReceipt,desc'}/>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormConfigureDoctorSchedule: () => dispatch(modalShow('configureDoctorSchedule')),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("bookings", fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSchedule);
