import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {formChangeField} from "../../../services/system/formService";
import {fetchSystemSettings} from "../../../services/commonService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import {Button} from "../../../../components/elements/button";
import {FormattedMessage} from "react-intl";
import {Avatar} from "../../../../components/collections/avatar";
import {detachRequestMember, fetchMember} from "../../../services/familyService";
import {modalShow} from "../../../services/system/modalService";
import {Tab, Tabs} from "../../../../components/modules/tab";
import ProfileAccount from "../../users/profile/ProfileAccount";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import qs from "query-string";
import {withRouter} from "react-router-dom";
import PatientProfileGeneral from "./PatientProfileGeneral";
import {BackTo} from "../../../../components/elements/backTo";

class EditPatientProfile extends Component {

    componentDidMount() {
        const {auth: {user}} = this.props;
        const {id: memberId} = user.familyMember || {};
        this.props.onfetchSystemSettings();
        const p = this.props.location.search;
        const {tab = "account"} = qs.parse(p);
        this.props.onChangeTabPanel(tab);
        if (memberId)
            this.props.onFetchMember(memberId, null, 'familyMember');
    }

    componentWillUnmount() {
        this.props.onInitForm("profileAccountEmail");
        this.props.onInitForm("profile");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const pCur = this.props.location.search;
        const {tab: curTab = ""} = qs.parse(pCur);

        const pPrev = prevProps.location.search;
        const {tab: prevTab = ""} = qs.parse(pPrev);

        if (curTab && curTab != prevTab) {
            this.props.onChangeTabPanel(curTab);
        }
    }

    render() {
        const {form, userId, auth: {user}, form: {data: formData}} = this.props;
        const {familyMember = null} = form.data;
        const {tab: {data: tabData}} = this.props;
        const {profile: tabProfile = {}} = tabData;
        const {id: memberId, parent: memberParent, isDetachRequest} = familyMember || {};
        const profileUserId = userId || user.id;
        const {profileAccountEmail ={}} = formData;
        return (
            <EmptyTag>

                <div className="row content-heading align-items-center">
                    {user.role == 'ADMIN' && profileUserId != user.id &&
                    <div className="col-auto content-heading__back">
                        <BackTo to="/admin/users"/>
                    </div>}
                    <div className="col content-heading__main">
                        {user.role === 'ADMIN' && profileAccountEmail.firstName && profileAccountEmail.lastName && <Header as="h1">
                            <FormattedMessage id="patient.profile" values={{
                            firstName: profileAccountEmail.firstName,
                            lastName: profileAccountEmail.lastName
                        }}/></Header>}

                        {user.role === 'ADMIN' && (!profileAccountEmail.firstName || !profileAccountEmail.lastName) && <Header as="h1">
                            <FormattedMessage id="patient.profile.new" values={{
                                email: profileAccountEmail.email
                            }}/>
                        </Header>}
                        {user.role === 'PATIENT' && <Header as="h1"><FormattedMessage id="my.profile"/></Header>}
                    </div>

                    {familyMember != null && familyMember.id &&
                    <div className="col-12">
                        <div className="white-block">
                            <Header as="h3"><FormattedMessage id="profile.creator"/></Header>
                            <hr/>
                            <div className="row avatar-row align-items-center">
                                <div className="col-auto avatar-row__image">
                                    <Avatar userId={memberParent.id}/>
                                </div>
                                <div className="col avatar-row__text">
                                    <div>
                                        {memberParent.firstName} {memberParent.lastName}
                                    </div>
                                </div>
                                {isDetachRequest &&
                                <div className="col-auto">
                                    <FormattedMessage id="is.request.detach.true"/>
                                </div>}
                                <div className="col-auto">
                                    <Button
                                        disabled={isDetachRequest}
                                        onClick={() => this.props.onConfirm({
                                            text: <FormattedMessage
                                                id="confirm.detach"/>,
                                            onProceed: (() => this.props.onDetachRequestMember(memberId))
                                        })}>
                                        <FormattedMessage id="detach.from.family"/>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab onClick={() => this.props.onChangeTabPanel("account")}
                                 active={tabProfile.activeTabPanel === 'account'}>
                                <FormattedMessage id="tab.item.account"/>
                            </Tab>

                            <Tab onClick={() => this.props.onChangeTabPanel("general")}
                                 active={tabProfile.activeTabPanel === 'general'}>
                                <FormattedMessage id="tab.item.general"/>
                            </Tab>
                        </Tabs>
                    </GridCol>
                </div>

                {tabProfile.activeTabPanel === 'general' && <PatientProfileGeneral profileUserId={profileUserId}/>}
                {tabProfile.activeTabPanel === 'account' && <ProfileAccount profileUserId={profileUserId}/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth,
        tab: store.tab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("profile", tabPanelName)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchMember: (id, callback, formName) => dispatch(fetchMember(id, callback, formName)),
        onDetachRequestMember: (memberId) => dispatch(detachRequestMember(memberId)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('profile', fieldName, fieldValue)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditPatientProfile));