import React from 'react';
import classnames from "classnames";
import {InputError} from "../error";
import Dropdown, {DropdownItemSimple} from "../../../containers/systems/dropdown";
import {EmptyTag} from "../emptyTag";
import {Icon} from "../icon";
import {ButtonIcon} from "../button";

export const Select = (prop) => {
    const {
        label, required = false, disabled = false, optionNameSelected, icon = "", optionsSelected = [],
        error = null, inline = false, noBorder = false, underline = false, name = "select",
        labelColor, noLabel = false, height = 0, width = 0, bgColor = '', multiple = false
    } = prop;
    const buttonClassNames = classnames('dropdown-toggle', {
        'color-orange': (labelColor === 'orange'),
        'color-gray': (labelColor === 'gray'),
        'color-green': (labelColor === 'green')
    });
    const dropdownButton = <EmptyTag>
        {icon && <Icon name={icon}/>}
        {multiple && <EmptyTag>
            <div className="input">
                <div className="tags-block">
                    {optionsSelected.map(optionSelected =>
                        <span className="tag-item" key={'multiple-' + name + '-' + optionSelected.id}>
                            {optionSelected.name}
                            <ButtonIcon icon={'close-gray'} onClick={() => prop.onRemoveOption(optionSelected)}/>
                        </span>)}
                </div>
            </div>
        </EmptyTag>}

        {!multiple &&
            <input type="text" placeholder={label}
                   disabled={disabled}
                   autoComplete="off"
                   value={optionNameSelected != "undefined" ? optionNameSelected : ''} onChange={() => {
            }} className={buttonClassNames}/>}
    </EmptyTag>;

    const blockClassNames = classnames('select-block', {
        'select-block-multiple': multiple === true,
        'block-error': (error !== null),
        'select-inline': (inline === true),
        'select-no-border': (noBorder === true),
        'select-underline': (underline === true),
        'field-no-label': (noLabel === true),
        'field-height-40': height == 40,
        'width-180': width == 180,
        'width-220': width == 220,
        'width-360': width == 360,
        'field-bg-white': bgColor === 'white'
    });
    return (
        <EmptyTag>
            <div className={blockClassNames}>
                <Dropdown label={label} required={required} button={dropdownButton} disabled={disabled}
                          multiple={multiple}
                          optionSelected={optionNameSelected != '' && optionNameSelected != "undefined"}>{prop.children}</Dropdown>
            </div>
            {error !== null && <InputError>{error}</InputError>}
        </EmptyTag>
    );
};

export const Option = (prop) => {
    const {...rest} = prop;
    return (
        <DropdownItemSimple {...rest}>
            {prop.children}
        </DropdownItemSimple>
    );
};