import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {formChangeField} from "../../../services/system/formService";
import {fetchSystemSettings} from "../../../services/commonService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import {FormattedMessage} from "react-intl";
import {Link, withRouter} from "react-router-dom";
import {Tab, Tabs} from "../../../../components/modules/tab";
import DoctorProfileGeneral from "./DoctorProfileGeneral";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import DoctorProfileAboutMe from "./DoctorProfileAboutMe";
import DoctorProfileBilling from "./DoctorProfileBilling";
import qs from "query-string";
import ProfileAccount from "../../users/profile/ProfileAccount";
import {BackTo} from "../../../../components/elements/backTo";
import {Button} from "../../../../components/elements/button";

class EditDoctorProfile extends Component {
    componentDidMount() {
        const p = this.props.location.search;
        const {tab = "account"} = qs.parse(p);
        this.props.onChangeTabPanel(tab);
    }

    componentWillUnmount() {
        this.props.onInitForm("profileAccountEmail");
        this.props.onInitForm("profile");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const pCur = this.props.location.search;
        const {tab: curTab = ""} = qs.parse(pCur);

        const pPrev = prevProps.location.search;
        const {tab: prevTab = ""} = qs.parse(pPrev);

        if (curTab && curTab != prevTab) {
            this.props.onChangeTabPanel(curTab);
        }
    }

    render() {
        const {tab: {data: tabData}, userId, auth: {user}, form: {data: formData}} = this.props;
        const {profile: tabProfile = {}} = tabData;
        const {profileAccountEmail = {}} = formData
        const profileUserId = userId || user.id;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    {user.role == 'ADMIN' && profileUserId != user.id &&
                        <div className="col-auto content-heading__back">
                            <BackTo to="/admin/users"/>
                        </div>}
                    <div className="col content-heading__main">
                        {user.role === 'ADMIN' && profileAccountEmail.firstName && profileAccountEmail.lastName &&
                            <Header as="h1">
                                <FormattedMessage id="doctor.profile" values={{
                                    firstName: profileAccountEmail.firstName,
                                    lastName: profileAccountEmail.lastName
                                }}/>
                            </Header>}
                        {user.role === 'ADMIN' && (!profileAccountEmail.firstName || !profileAccountEmail.lastName) &&
                            <Header as="h1">
                                <FormattedMessage id="doctor.profile.new" values={{
                                    email: profileAccountEmail.email
                                }}/>
                            </Header>}
                        {user.role === 'DOCTOR' && <Header as="h1"><FormattedMessage id="my.profile"/></Header>}
                    </div>
                    {user.role == 'ADMIN' &&
                    <div className="col-auto content-heading__controls">
                        <Link to={'/admin/bookings?doctorId=' + profileUserId}>
                            <Button>
                                <FormattedMessage id="core.appointments.label"/>
                            </Button>
                        </Link>
                        <Link to={'/admin/users/' + profileUserId + '/profile/doctor/schedule'}>
                            <Button>
                                <FormattedMessage id="core.schedule.label"/>
                            </Button>
                        </Link>
                    </div>}

                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab onClick={() => this.props.onChangeTabPanel("account")}
                                 active={tabProfile.activeTabPanel === 'account'}>
                                <FormattedMessage id="tab.item.account"/>
                            </Tab>

                            <Tab onClick={() => this.props.onChangeTabPanel("general")}
                                 active={tabProfile.activeTabPanel === 'general'}>
                                <FormattedMessage id="tab.item.general"/>
                            </Tab>

                            <Tab onClick={() => this.props.onChangeTabPanel("aboutMe")}
                                 active={tabProfile.activeTabPanel === 'aboutMe'}>
                                <FormattedMessage id="about.me"/>
                            </Tab>

                            <Tab onClick={() => this.props.onChangeTabPanel("billing")}
                                 disabled={!profileAccountEmail.isFillProfile}
                                 active={tabProfile.activeTabPanel === 'billing'}>
                                <FormattedMessage id="tab.item.billing"/>
                            </Tab>
                        </Tabs>
                    </GridCol>
                </div>

                {tabProfile.activeTabPanel === 'general' && <DoctorProfileGeneral profileUserId={profileUserId}/>}
                {tabProfile.activeTabPanel === 'aboutMe' && <DoctorProfileAboutMe profileUserId={profileUserId}/>}
                {tabProfile.activeTabPanel === 'billing' && profileAccountEmail.isFillProfile &&
                    <DoctorProfileBilling profileUserId={profileUserId}/>}
                {tabProfile.activeTabPanel === 'account' && <ProfileAccount profileUserId={profileUserId}/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth,
        tab: store.tab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("profile", tabPanelName)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('profile', fieldName, fieldValue)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditDoctorProfile));
