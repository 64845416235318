import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {Button, ButtonIcon} from "../../../../components/elements/button";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import {RadioBox} from "../../../../components/elements/radiobox";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import moment from "moment";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import ScheduleViewOnWeek from "./ScheduleViewOnWeek";
import {
    fetchDoctorSchedule,
    fetchDoctorScheduleAppointmentDays, fetchDoctorScheduleContextOfDay,
    removeDoctorSchedule
} from "../../../services/doctorScheduleService";
import {modalShow} from "../../../services/system/modalService";
import ScheduleViewOnDay from "./ScheduleViewOnDay";
import classnames from "classnames";
import ScheduleViewOnMonth from "./ScheduleViewOnMonth";
import ScheduleViewOnYear from "./ScheduleViewOnYear";
import qs from "query-string";
import {isModal} from "../../../../utils/app";
import ShowAllAppointment from "./ShowAllAppointment";
import {noticeShowAction} from "../../../../actions/noticeAction";

class ScheduleViewWrapper extends Component {
    constructor(props) {
        super(props);
        this.onChangeView = this.onChangeView.bind(this);
        this.onNextPeriod = this.onNextPeriod.bind(this);
        this.onPrevPeriod = this.onPrevPeriod.bind(this);
        this.onDetail = this.onDetail.bind(this);
        this.onCloseDetail = this.onCloseDetail.bind(this);
        this.onApplyAppointmentTemplate = this.onApplyAppointmentTemplate.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onLoadContextOfDay = this.onLoadContextOfDay.bind(this);
        this.onLoadAppointmentDays = this.onLoadAppointmentDays.bind(this);
        this.onShowAll = this.onShowAll.bind(this);
        this.onRemoveScheduleItem = this.onRemoveScheduleItem.bind(this);
        this.onLoadByDate = this.onLoadByDate.bind(this);
        this.onToday = this.onToday.bind(this);
        this.state = {
            detailScheduleKey: null
        }
    }

    onLoadByDate(momentDate) {
        const {list: {data: listData}} = this.props;
        const {appointmentDays = []} = listData;
        if (appointmentDays.findIndex(value => value === momentDate.format("YYYY-MM-DD")) !== -1) {
            this.onShowAll(momentDate);
        } else {
            this.props.onNothingPlanned();
        }
    }

    onRemoveScheduleItem(id) {
        const {form: {data: formData}, modal} = this.props;
        const {doctor, view, formatStartCurrentPeriod, formatEndCurrentPeriod} = formData['tableSchedule'] || {};

        this.setState({...this.state, detailScheduleKey: null});
        if (isModal(modal, 'showAllAppointment')) {
            const {showAllOnDate} = formData['showAllAppointment'] || {};
            const date = showAllOnDate.format('YYYY-MM-DD');
            this.props.onRemoveSchedule(id,
                () => {
                    this.props.onFetchDoctorSchedule(doctor.id, date, date);
                    if (view === 'year')
                        this.props.onFetchDoctorScheduleAppointmentDays(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod);
                    if (view === 'month')
                        this.props.onFetchDoctorScheduleContextOfDay(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod);
                });
        } else {
            this.props.onRemoveSchedule(id,
                () => {
                    if (view === 'month') {
                        this.props.onFetchDoctorScheduleContextOfDay(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod);
                    } else {
                        this.props.onFetchDoctorSchedule(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod)
                    }
                });
        }
    }


    componentDidMount() {
        this.onChangeView("week");
    }

    onShowAll(momentDate) {
        const {auth: {user}} = this.props;
        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);
        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        const doctor = {id: doctorId};
        this.onLoad(doctor, momentDate, momentDate,
            (data) => this.props.onModalShow("showAllAppointment", {...data, showAllOnDate: momentDate}));
    }

    onLoad(user, momentDateStart, momentDateEnd, callback = null) {
        this.props.onFetchDoctorSchedule(
            user.id, momentDateStart.format('YYYY-MM-DD'), momentDateEnd.format('YYYY-MM-DD'), callback);
    }

    onLoadContextOfDay(user, momentDateStart, momentDateEnd) {
        this.props.onFetchDoctorScheduleContextOfDay(
            user.id, momentDateStart.format('YYYY-MM-DD'), momentDateEnd.format('YYYY-MM-DD'));
    }


    onLoadAppointmentDays(user, momentDateStart, momentDateEnd) {
        this.props.onFetchDoctorScheduleAppointmentDays(
            user.id, momentDateStart.format('YYYY-MM-DD'), momentDateEnd.format('YYYY-MM-DD'));
    }

    onApplyAppointmentTemplate(date, time) {
        const {auth: {user}} = this.props;
        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);
        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        this.props.onModalShow("applyAppointmentTemplate", {
            "doctor": {id: doctorId},
            "appointmentTemplate": null,
            "startTime": time,
            "startDate": date,
            "endDate": date
        });
    }

    onNextPeriod() {
        const {form: {data: formData}, auth: {user}} = this.props;
        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);
        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        const doctor = {id: doctorId};
        const {tableSchedule: formTableSchedule = {}} = formData;
        let {view, momentStartCurrentPeriod, momentEndCurrentPeriod} = formTableSchedule;

        const formatStart = momentStartCurrentPeriod.format("YYYY-MM-DD");
        const formatEnd = momentEndCurrentPeriod.format("YYYY-MM-DD");

        let momentStartNextPeriod = null;
        let momentEndNextPeriod = null;

        let formatStartCurrentPeriod;
        let formatEndCurrentPeriod;
        switch (view) {
            case "week" :
                momentStartNextPeriod = moment(formatStart).add(7, "days");
                momentEndNextPeriod = moment(formatEnd).add(7, "days");
                this.onLoad(doctor, momentStartNextPeriod, momentEndNextPeriod);
                break;
            case "year" :
                momentStartNextPeriod = moment(formatStart).add(1, "years");
                momentEndNextPeriod = moment(formatEnd).add(1, "years");
                this.onLoadAppointmentDays(doctor, momentStartNextPeriod, momentEndNextPeriod);
                break;
            case "month" :
                momentStartNextPeriod = moment(formatStart).add(1, "months");
                momentEndNextPeriod = moment(formatStart).add(1, "months").endOf('month');
                this.onLoadContextOfDay(doctor, momentStartNextPeriod, momentEndNextPeriod);
                break;
            case "day" :
                momentStartNextPeriod = moment(formatStart).add(1, "days");
                momentEndNextPeriod = momentStartNextPeriod;
                this.onLoad(doctor, momentStartNextPeriod, momentEndNextPeriod);
                break;
        }

        formatStartCurrentPeriod = momentStartNextPeriod.format("YYYY-MM-DD");
        formatEndCurrentPeriod = momentEndNextPeriod.format("YYYY-MM-DD");

        this.props.onFormSimpleChange("tableSchedule", "momentStartCurrentPeriod", momentStartNextPeriod);
        this.props.onFormSimpleChange("tableSchedule", "momentEndCurrentPeriod", momentEndNextPeriod);

        this.props.onFormSimpleChange("tableSchedule", "formatStartCurrentPeriod", formatStartCurrentPeriod);
        this.props.onFormSimpleChange("tableSchedule", "formatEndCurrentPeriod", formatEndCurrentPeriod);
    }

    onPrevPeriod() {
        const {form: {data: formData}, auth: {user}} = this.props;
        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);
        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        const doctor = {id: doctorId};
        const {tableSchedule: formTableSchedule = {}} = formData;
        let {view, momentStartCurrentPeriod, momentEndCurrentPeriod} = formTableSchedule;

        const formatStart = momentStartCurrentPeriod.format("YYYY-MM-DD");
        const formatEnd = momentEndCurrentPeriod.format("YYYY-MM-DD");

        let momentStartNextPeriod = null;
        let momentEndNextPeriod = null;

        switch (view) {
            case "week" :
                momentStartNextPeriod = moment(formatStart).add(-7, "days");
                momentEndNextPeriod = moment(formatEnd).add(-7, "days");
                this.onLoad(doctor, momentStartNextPeriod, momentEndNextPeriod);
                break;
            case "year" :
                momentStartNextPeriod = moment(formatStart).add(-1, "years");
                momentEndNextPeriod = moment(formatEnd).add(-1, "years");
                this.onLoadAppointmentDays(doctor, momentStartNextPeriod, momentEndNextPeriod);
                break;
            case "month" :
                momentStartNextPeriod = moment(formatStart).add(-1, "months");
                momentEndNextPeriod = moment(formatStart).add(-1, "months").endOf('month');
                this.onLoadContextOfDay(doctor, momentStartNextPeriod, momentEndNextPeriod);
                break;
            case "day" :
                momentStartNextPeriod = moment(formatStart).add(-1, "days");
                momentEndNextPeriod = momentStartNextPeriod;
                this.onLoad(doctor, momentStartNextPeriod, momentEndNextPeriod);
                break;
        }

        this.props.onFormSimpleChange("tableSchedule", "momentStartCurrentPeriod", momentStartNextPeriod);
        this.props.onFormSimpleChange("tableSchedule", "momentEndCurrentPeriod", momentEndNextPeriod);

        this.props.onFormSimpleChange("tableSchedule", "formatStartCurrentPeriod", momentStartNextPeriod.format("YYYY-MM-DD"));
        this.props.onFormSimpleChange("tableSchedule", "formatEndCurrentPeriod", momentEndNextPeriod.format("YYYY-MM-DD"));
    }

    onDetail(key) {
        this.setState({...this.state, detailScheduleKey: key});
    }

    onCloseDetail() {
        this.setState({...this.state, detailScheduleKey: null});
    }

    onToday() {
        const {form: {data: formData}} = this.props;
        const {view} = formData['tableSchedule'] || {};
        this.onChangeView(view);
    }

    onChangeView(view, momentDate = null) {
        const {auth: {user}} = this.props;
        const p = this.props.location.search;
        let {doctorId = null} = qs.parse(p);
        if (this.props.userId) {
            doctorId = this.props.userId;
        } else if (user.id) {
            doctorId = user.id;
        }
        const doctor = {id: doctorId};
        let momentStartCurrentPeriod;
        let momentEndCurrentPeriod;

        let formatStartCurrentPeriod;
        let formatEndCurrentPeriod;

        switch (view) {
            case "week" :
                momentStartCurrentPeriod = momentDate == null ?
                    moment().startOf('week') : momentDate.startOf('week');
                momentEndCurrentPeriod = momentDate == null ?
                    moment().startOf('week').add(6, "days") :
                    momentDate.startOf('week').add(6, "days");
                formatStartCurrentPeriod = momentStartCurrentPeriod.format("YYYY-MM-DD");
                formatEndCurrentPeriod = momentEndCurrentPeriod.format("YYYY-MM-DD");
                this.onLoad(doctor, momentStartCurrentPeriod, momentEndCurrentPeriod);
                break;
            case "year" :
                momentStartCurrentPeriod = momentDate == null ?
                    moment().month(0).date(1) : momentDate.month(0).date(1);
                momentEndCurrentPeriod = momentDate == null ?
                    moment().month(11).date(31) : momentDate.month(11).date(31);
                formatStartCurrentPeriod = momentStartCurrentPeriod.format("YYYY-MM-DD");
                formatEndCurrentPeriod = momentEndCurrentPeriod.format("YYYY-MM-DD");
                this.onLoadAppointmentDays(doctor, momentStartCurrentPeriod, momentEndCurrentPeriod);
                break;
            case "month" :
                momentStartCurrentPeriod = momentDate == null ? moment().date(1) : momentDate.date(1);
                momentEndCurrentPeriod = momentDate == null ?
                    moment().date(1).add(1, "month").add(-1, "day") :
                    momentDate.date(1).add(1, "month").add(-1, "day");
                formatStartCurrentPeriod = momentStartCurrentPeriod.format("YYYY-MM-DD");
                formatEndCurrentPeriod = momentEndCurrentPeriod.format("YYYY-MM-DD");
                this.onLoadContextOfDay(doctor, momentStartCurrentPeriod, momentEndCurrentPeriod);
                break;
            default:
                momentStartCurrentPeriod = momentDate == null ? moment() : momentDate;
                momentEndCurrentPeriod = momentDate == null ? moment() : momentDate;
                formatStartCurrentPeriod = momentStartCurrentPeriod.format("YYYY-MM-DD");
                formatEndCurrentPeriod = momentEndCurrentPeriod.format("YYYY-MM-DD");
                this.onLoad(doctor, momentStartCurrentPeriod, momentEndCurrentPeriod);
        }
        this.props.onInitForm("tableSchedule", {
            view: view,
            doctor: doctor,
            momentStartCurrentPeriod: momentStartCurrentPeriod,
            momentEndCurrentPeriod: momentEndCurrentPeriod,
            formatStartCurrentPeriod: formatStartCurrentPeriod,
            formatEndCurrentPeriod: formatEndCurrentPeriod
        });
        this.props.onChangeTabPanel(view);
        this.setState({...this.state, detailScheduleKey: null});
    }

    render() {
        const {tab: {data: tabData}, form: {data: formData}, modal} = this.props;
        const {detailScheduleKey = null} = this.state;
        const {tableSchedule: formTableSchedule = {}} = formData;
        const {view, momentStartCurrentPeriod, momentEndCurrentPeriod} = formTableSchedule;
        const {tableSchedule = {}} = tabData;
        const classnameWrapper = classnames('white-block calendar-block', 'calendar-block-period-' + view);

        return (
            <div className="col-12">
                <div className={classnameWrapper}>

                    <div className="calendar-block-head">
                        <div className="row c-b-head-row align-items-center">
                            <div className="col-auto">
                                <Button size="middle" onClick={() => this.onToday()}>
                                    <FormattedMessage id="today"/>
                                </Button>
                            </div>
                            <div className="col-auto">
                                <div className="row row-calendar-date-switch align-items-center">
                                    <div className="col-auto">
                                        <ButtonIcon icon={'arrow-left-black'}
                                                    onClick={() => this.onPrevPeriod()}></ButtonIcon>
                                    </div>
                                    <div className="col">
                                        {view === 'week' &&
                                        <EmptyTag>
                                            {momentStartCurrentPeriod.format("ll")}&nbsp;-&nbsp;
                                            {momentEndCurrentPeriod.format("ll")}
                                        </EmptyTag>}
                                        {view === 'year' &&
                                        <EmptyTag>
                                            {momentStartCurrentPeriod.format("YYYY")}
                                        </EmptyTag>}
                                        {view === 'month' &&
                                        <EmptyTag>
                                            {momentStartCurrentPeriod.format("MMM, YYYY")}
                                        </EmptyTag>}
                                        {view === 'day' &&
                                        <EmptyTag>
                                            {momentStartCurrentPeriod.format("ll")}
                                        </EmptyTag>}
                                    </div>
                                    <div className="col-auto">
                                        <ButtonIcon icon={'arrow-right-black'}
                                                    onClick={() => this.onNextPeriod()}></ButtonIcon>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="c-b-period-labels labels-no-border labels-small-size">
                                    <RadioBox asButton={true}
                                              color={'blue'}
                                              checked={tableSchedule.activeTabPanel === 'day'}
                                              onChange={() => this.onChangeView("day")}>
                                        <FormattedMessage id="day"/>
                                    </RadioBox>

                                    <RadioBox asButton={true}
                                              color={'blue'}
                                              checked={tableSchedule.activeTabPanel === 'week'}
                                              onChange={() => this.onChangeView("week")}>
                                        <FormattedMessage id="week"/>
                                    </RadioBox>

                                    <RadioBox asButton={true}
                                              color={'blue'}
                                              checked={tableSchedule.activeTabPanel === 'month'}
                                              onChange={() => this.onChangeView("month")}>
                                        <FormattedMessage id="month"/>
                                    </RadioBox>

                                    <RadioBox asButton={true}
                                              color={'blue'}
                                              checked={tableSchedule.activeTabPanel === 'year'}
                                              onChange={() => this.onChangeView("year")}>
                                        <FormattedMessage id="year"/>
                                    </RadioBox>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="calendar-block-body">
                        {tableSchedule.activeTabPanel === 'week' &&
                        <ScheduleViewOnWeek
                            detailScheduleKey={detailScheduleKey}
                            start={momentStartCurrentPeriod}
                            end={momentEndCurrentPeriod}
                            onDetail={(key) => this.onDetail(key)}
                            onApplyAppointmentTemplate={(date, time) => this.onApplyAppointmentTemplate(date, time)}
                            onCloseDetail={() => this.onCloseDetail()}
                            onRemoveSchedule={(id) => this.props.onConfirm({
                                text: <FormattedMessage id="confirm.remove.schedule.item"/>,
                                onProceed: (() => this.onRemoveScheduleItem(id))
                            })}/>}

                        {tableSchedule.activeTabPanel === 'day' &&
                        <ScheduleViewOnDay
                            detailScheduleKey={detailScheduleKey}
                            start={momentStartCurrentPeriod}
                            end={momentEndCurrentPeriod}
                            onDetail={(key) => this.onDetail(key)}
                            onApplyAppointmentTemplate={(date, time) => this.onApplyAppointmentTemplate(date, time)}
                            onCloseDetail={() => this.onCloseDetail()}
                            onRemoveSchedule={(id) => this.props.onConfirm({
                                text: <FormattedMessage id="confirm.remove.schedule.item"/>,
                                onProceed: (() => this.onRemoveScheduleItem(id))
                            })}/>}

                        {tableSchedule.activeTabPanel === 'month' &&
                        <ScheduleViewOnMonth
                            detailScheduleKey={detailScheduleKey}
                            start={momentStartCurrentPeriod}
                            end={momentEndCurrentPeriod}
                            onDetail={(key) => this.onDetail(key)}
                            onApplyAppointmentTemplate={(date, time) => this.onApplyAppointmentTemplate(date, time)}
                            onCloseDetail={() => this.onCloseDetail()}
                            onShowAll={(momentDate) => this.onShowAll(momentDate)}
                            onRemoveSchedule={(id) => this.props.onConfirm({
                                text: <FormattedMessage id="confirm.remove.schedule.item"/>,
                                onProceed: (() => this.onRemoveScheduleItem(id))
                            })}/>}

                        {tableSchedule.activeTabPanel === 'year' &&
                        <ScheduleViewOnYear
                            onLoadByDate={(momentDate) => this.onLoadByDate(momentDate)}
                            start={momentStartCurrentPeriod}
                            end={momentEndCurrentPeriod}/>}
                    </div>
                </div>

                {isModal(modal, 'showAllAppointment') &&
                <ShowAllAppointment detailScheduleKey={detailScheduleKey}
                                    onDetail={(key) => this.onDetail(key)}
                                    onCloseDetail={() => this.onCloseDetail()}
                                    onRemoveSchedule={(id) => this.props.onConfirm({
                                        text: <FormattedMessage id="confirm.remove.schedule.item"/>,
                                        onProceed: (() => this.onRemoveScheduleItem(id))
                                    })}/>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        auth: store.auth,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onNothingPlanned: () => dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>,
            <FormattedMessage id="nothing.planned"/>)),
        onModalShow: (modalName, modalData) => dispatch(modalShow(modalName, modalData)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onRemoveSchedule: (id, callback) => dispatch(removeDoctorSchedule(id, callback)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("tableSchedule", tabPanelName)),
        onFetchDoctorSchedule: (doctorId, dateStart, dateEnd, callback) => dispatch(fetchDoctorSchedule(doctorId, dateStart, dateEnd, "doctorSchedules", callback)),
        onFetchDoctorScheduleContextOfDay: (doctorId, dateStart, dateEnd) => dispatch(fetchDoctorScheduleContextOfDay(doctorId, dateStart, dateEnd)),
        onFetchDoctorScheduleAppointmentDays: (doctorId, dateStart, dateEnd) => dispatch(fetchDoctorScheduleAppointmentDays(doctorId, dateStart, dateEnd)),

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleViewWrapper));
