import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {DatePicter} from "../../../components/elements/datepicter";

class DatepicterRange extends Component {
    constructor(props) {
        super(props);
        this.onChangeDateRange = this.onChangeDateRange.bind(this);
    }

    onChangeDateRange(objMoment, fieldName) {
        if (moment.isMoment(objMoment)) {
            const fieldValue = moment(objMoment, "YYYY-MM-DD").isValid() ? objMoment.format("YYYY-MM-DD") : null
            this.props.onListFilterField(fieldName, fieldValue);
            this.props.onPagination(1);
        } else if (objMoment == null || objMoment == "") {
            this.props.onListFilterField(fieldName, null);
            this.props.onPagination(1);
        }
    }

    render() {
        const {fieldNameFrom, fieldNameTo, labelFrom, labelTo} = this.props;
        return (
            <EmptyTag>
                <div className="col-auto">
                    <label className="field-label margin-bottom-0"><FormattedMessage id={labelFrom} /></label>
                </div>
                <div className="col-auto">
                    <DatePicter no_label={true}
                                bg_white={true}
                                height={40}
                                width={180}
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={(v) => this.onChangeDateRange(v, fieldNameFrom)}
                                dateFormat={'ll'}/>
                </div>
                <div className="col-auto">
                    <label className="field-label margin-bottom-0"><FormattedMessage id={labelTo} /></label>
                </div>
                <div className="col-auto">
                    <DatePicter no_label={true}
                                bg_white={true}
                                height={40}
                                width={180}
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={(v) => this.onChangeDateRange(v, fieldNameTo)}
                                dateFormat={'ll'}/>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DatepicterRange);