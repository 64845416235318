import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Button, ButtonRemove} from "../../../../components/elements/button";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {listFilterAction, listFilterFieldAction, listSortAction} from "../../../../actions/listAction";
import {Link, NavLink} from "react-router-dom";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import Avatar from "../../../systems/avatar";
import {fetchDoctors} from "../../../services/doctorService";
import {fetchSystemSettings} from "../../../services/commonService";
import Search from "../../../systems/search";
import {isModal} from "../../../../utils/app";
import CatalogDoctorsFilter from "./CatalogDoctorsFilter";
import {Option, Select} from "../../../../components/elements/select";
import _ from 'lodash'
import {formInitAction} from "../../../../actions/formAction";
import Rating from "@material-ui/lab/Rating";
import {Icon} from "../../../../components/elements/icon";
import Tooltip from "../../../systems/tooltip";

class CatalogDoctors extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onSortBy = this.onSortBy.bind(this);
        this.onRemoveFilter = this.onRemoveFilter.bind(this);
    }

    componentDidMount() {
        this.props.onListSortAction({"doctor.rating": "desc"})
        this.props.onFetchDoctors(1);
    }

    componentWillUnmount() {
        this.props.onListSortAction({});
    }

    onSortBy = (sortBy = "") => {
        const splitSortBy = sortBy.split(",");
        this.props.onListSortAction({[splitSortBy[0]]: splitSortBy[1]})
        this.onPagination(1);
    }

    onPagination(pageNumber = 1) {
        this.props.onFetchDoctors(pageNumber);
    }

    onFilter(fieldName, fieldValue) {
        this.props.onListFilterField(fieldName, fieldValue);
        this.props.onFetchDoctors(1);
    }

    onSearch(text) {
        this.props.onListFilterField("searchText", text || null);
        this.onPagination(1);
    }

    onClear = () => {
        this.props.onInitFilter({});
        this.props.onInitForm("chosenFilters", {});
        this.onPagination(1);
    }

    onRemoveFilter = (field) => {
        const {form: {data: formData}} = this.props;
        const {chosenFilters = {}} = formData;
        _.unset(chosenFilters, field);
        this.props.onListFilterField(field, null);
        this.props.onInitForm("chosenFilters", chosenFilters);
        this.onPagination(1);
    }

    render() {
        const {
            list: {data: listData, pagination, filters, sorts},
            form: {data: formData},
            modal,
            auth: {user, isAuthenticated}
        } = this.props;
        const {catalogDoctors: listCatalogDoctorsFilter = {}} = filters;
        const {catalogDoctors: listCatalogDoctorsSort = {}} = sorts;
        const {catalogDoctors = []} = listData;
        const {number = 0, size = 0, totalElements = 0} = pagination['catalogDoctors'] || {};
        const {chosenFilters = {}, systemSettings = {}} = formData;
        const sortByOptions = {
            "doctor.rating,desc": "order.rating.desc",
            "doctor.nearest,asc": "order.nearest.asc",
            "doctor.priceReception,asc": "order.priceReception.asc",
            "doctor.priceReception,desc": "order.priceReception.desc",
            "doctor.practiceSince,asc": "order.practice.since.desc"
        };
        const currentSortBy = _.map(listCatalogDoctorsSort, (key, val) => val + "," + key);
        const currentSortByOption = sortByOptions[currentSortBy] || null;
        const {isFillProfile, isEnabled} = user;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="doctors.catalogue"/></Header>
                    </div>
                </div>

                <div className="row form-elements filtr-form align-items-center justify-content-end">
                    <div className="col-auto">
                        <Search onSearch={this.onSearch} placeholder="search.by.name"
                                value={listCatalogDoctorsFilter.searchText || null}/>
                    </div>

                    <div className="col col-empty"></div>

                    <div className="col-auto padding-right-0">
                        <label className="field-label"><FormattedMessage id="sort.by"/></label>
                    </div>

                    <div className="col-auto">
                        <FormattedMessage id={currentSortByOption || 'undefined'}>
                            {optionNameSelected =>
                                <Select noLabel
                                        bgColor="white"
                                        width={360}
                                        height={40}
                                        optionNameSelected={optionNameSelected}>
                                    {
                                        Object.keys(sortByOptions).map(key =>
                                            <Option
                                                active={listCatalogDoctorsSort && listCatalogDoctorsSort[key]}
                                                key={key}
                                                onClick={() => this.onSortBy(key)}>
                                                <FormattedMessage id={sortByOptions[key]}/>
                                            </Option>
                                        )
                                    }
                                </Select>}
                        </FormattedMessage>
                    </div>

                    <div className="col-auto">
                        <Button square icon="filtr" onClick={() => this.props.onModalShow("catalogDoctorsFilter")}/>
                    </div>

                    {Object.keys(listCatalogDoctorsFilter).length > 0 &&
                        <div className="col-12">
                            <div className="selected-filters">
                                <Button border="red-bordered" sizeMiddle onClick={() => this.onClear()}>
                                    <FormattedMessage id="clear.filter"/>
                                </Button>

                                {Object.keys(chosenFilters).map(field => {
                                    const value = chosenFilters[field];
                                    if (!value) return;
                                    return (
                                        <label className="form-label button-label editable-label button-label-gray"
                                               key={"chosen-filter-" + field}>
                                    <span>
                                        <i>
                                        {field === 'address' &&
                                            <EmptyTag>
                                                <FormattedMessage id="address"/>: {value}
                                            </EmptyTag>}
                                            {field === 'sex' &&
                                                <EmptyTag>
                                                    <FormattedMessage id="sex"/>: <FormattedMessage id={value}/>
                                                </EmptyTag>}
                                            {field === 'specializations' &&
                                                <EmptyTag>
                                                    <FormattedMessage id="specialization"/>: {value}
                                                </EmptyTag>}

                                            {field === 'speakLanguages' &&
                                                <EmptyTag>
                                                    <FormattedMessage id="doctor.speak.languages"/>: {value}
                                                </EmptyTag>}

                                            {field === 'ratingFrom' &&
                                                <EmptyTag>
                                                    <FormattedMessage id="rating"/>: <FormattedMessage id={value}/>
                                                </EmptyTag>}

                                            {field === 'priceReceptionFrom' &&
                                                <EmptyTag>
                                                    <FormattedMessage id="price"/>: <FormattedMessage id="from"/>&nbsp;
                                                    {systemSettings.paymentCurrency}&nbsp;{value}
                                                </EmptyTag>}

                                            {field === 'priceReceptionTo' &&
                                                <EmptyTag>
                                                    <FormattedMessage id="price"/>: <FormattedMessage id="to"/>&nbsp;
                                                    {systemSettings.paymentCurrency}&nbsp;{value}
                                                </EmptyTag>}

                                        </i>
                                    </span>
                                            <ButtonRemove onClick={() => this.onRemoveFilter(field)}/>
                                        </label>
                                    )
                                })}
                            </div>
                        </div>}
                </div>

                <div className="row link-blocks-row">
                    {catalogDoctors.map(i => {
                            const {doctor, id, firstName, lastName, speakLanguages = []} = i;
                            const {practiceSince, specializations = [], minPrice, maxPrice} = doctor || {};
                            const currentYear = moment().format("yyyy")
                            const experience = practiceSince ? currentYear - practiceSince : 0;
                            const listSpeakLanguages = speakLanguages.map(i => i.name).join(", ");
                            return (
                                <div className="col-md-6" key={id}>
                                    <div className="link-block-wrapper">
                                        {isAuthenticated &&
                                            <NavLink to={"/patient/catalogDoctors/" + id + "/detail"}
                                                     className="link-block"></NavLink>}
                                        <div className="row avatar-row height-100-percent">
                                            <div className="col-auto avatar-row__image padding-bottom-10">
                                                <Avatar size={120} userId={id} doctor/>
                                                <div className="rating-block">
                                                    <Rating name="half-rating-read"
                                                            defaultValue={doctor && doctor.rating || 0}
                                                            precision={0.5} readOnly/>

                                                    <span
                                                        className="tiny-text">({doctor && doctor.countRatings || 0})</span>
                                                </div>
                                            </div>
                                            <div className="col avatar-row__text col-fixed-bottom-controls">
                                                <div className="margin-bottom-10">
                                                    <FormattedMessage id="core.doctor.name"
                                                                      values={{firstName: firstName, lastName: lastName}}/>
                                                </div>
                                                <div className="small-text bigger-line-height">
                                                    <div className="color-gray">
                                                        {specializations.map(i => i.name).join(", ")}
                                                    </div>

                                                    <div>
                                                        {experience > 0 && <FormattedMessage id="experience.years.value"
                                                                                             values={{experience: experience}}/>}
                                                    </div>
                                                    {<div>

                                                        <FormattedMessage id="price.interval" values={{
                                                            currency: systemSettings.paymentCurrency,
                                                            from: (minPrice && parseFloat(minPrice).toFixed(2)) || '0',
                                                            to: (maxPrice && parseFloat(maxPrice).toFixed(2)) || '0'
                                                        }}/>
                                                    </div>}
                                                </div>
                                                <div className="relative-controls">
                                                    {isAuthenticated && (!isFillProfile || !isEnabled) &&
                                                        <Button disabled={!isFillProfile || !isEnabled}>
                                                            <FormattedMessage id="book.an.appointment"/>
                                                        </Button>}

                                                    {isAuthenticated && isFillProfile && isEnabled &&
                                                        <Link to={'/bookAnAppointment?doctorId=' + id}>
                                                            <Button>
                                                                <FormattedMessage id="book.an.appointment"/>
                                                            </Button>
                                                        </Link>}

                                                    {!isAuthenticated &&
                                                        <Link to={'/bookAnAppointment?doctorId=' + id}
                                                              target="_blank">
                                                            <Button>
                                                                <FormattedMessage id="book.an.appointment"/>
                                                            </Button>
                                                        </Link>}
                                                </div>
                                            </div>
                                            {speakLanguages.length > 0 &&
                                                <div className="col-auto avatar-row__langs">
                                                    <Tooltip
                                                        isTooltipButton={false}
                                                        tooltipElement={
                                                        <div className="langs-column">
                                                            {speakLanguages.map(speakLanguage =>
                                                                <div className='icon-item'
                                                                     key={'doctor-catalog-speak-language-' + speakLanguage.locale}>
                                                                    <Icon name={'lang-' + speakLanguage.locale}/>
                                                                </div>)}
                                                        </div>
                                                    }>
                                                        <FormattedMessage id="doctor.speak.languages.value"
                                                                          values={{listSpeakLanguages: listSpeakLanguages}}/>
                                                    </Tooltip>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}

                    {catalogDoctors.length === 0 &&
                        <div className="col-12"><FormattedMessage id="empty.list"/></div>}

                    <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}
                    </div>
                </div>

                {isModal(modal, 'catalogDoctorsFilter') &&
                    <CatalogDoctorsFilter onFilter={() => this.onPagination()}/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitFilter: (filters) => dispatch(listFilterAction('catalogDoctors', filters)),
        onModalShow: (name) => dispatch(modalShow(name)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onFetchDoctors: (pageNumber) => dispatch(fetchDoctors(pageNumber, "catalogDoctors")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("catalogDoctors", fieldName, fieldValue)),
        onListSortAction: (sorts) => dispatch(listSortAction("catalogDoctors", sorts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogDoctors);
