import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Modal} from "../../../../components/modules/modal";
import {Header} from "../../../../components/elements/header";
import {modalHide} from "../../../services/system/modalService";
import {withRouter} from "react-router";
import {Button} from "../../../../components/elements/button";
import {newDoctorReview} from "../../../services/doctorReviewService";
import {Textarea} from "../../../../components/elements/textarea";
import Rating from "@material-ui/lab/Rating";
import {formChangeField, formSimpleChange} from "../../../services/system/formService";
import {InputError} from "../../../../components/elements/error";

class NewDoctorReview extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeRating = this.onChangeRating.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('newDoctorReview', event);
    }

    onChangeRating(value) {
        this.props.onFormSimpleChangeField('newDoctorReview', "rating", value);
    }

    render() {
        const {form: {data: formData, errors : formErrors}, afterCreateFetch = true} = this.props;
        const {newDoctorReview = {}} = formData;
        const errors = formErrors.newDoctorReview;
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('newDoctorReview')} >
                <Header as="h2"><FormattedMessage id="what.is.you.rate"/></Header>
                <div className="row form-elements">
                    <div className="col-12">
                        <div className="rating-block rating-block-variable align-center">
                            <Rating
                                size="large"
                                name="simple-controlled"
                                value={newDoctorReview.rating || 0}
                                onChange={(event, newValue) => {
                                    this.onChangeRating(newValue);
                                }}
                            />
                            {errors && errors['rating'] && <InputError>{errors['rating']}</InputError>}
                        </div>
                    </div>
                    <div className="col-12">
                        <h3><FormattedMessage id="please.share.your.opinion"/></h3>
                        <Textarea placeholder={<FormattedMessage id="your.review"/>}
                                  name="text"
                                  error={errors && errors['text']}
                                  value={newDoctorReview.text || ''}
                                  onChange={this.onChange}/>
                    </div>
                    <div className="col-12">
                        <div className="form-submit">
                            <Button onClick={() => this.props.onModalHide('newDoctorReview')} color='white-red'>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button
                                disabled={!newDoctorReview.text && !newDoctorReview.rating}
                                onClick={() => this.props.onNewDoctorReview(newDoctorReview, afterCreateFetch)}>
                                <FormattedMessage id="core.btn.save.label"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        modal: store.modal,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
        onNewDoctorReview: (data, afterCreateFetch) => dispatch(newDoctorReview(data, afterCreateFetch)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewDoctorReview));