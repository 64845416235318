import React from 'react';
import classnames from "classnames";

export const Bell = (props) => {
    const {ring = false, onClick} = props;
    const buttonClassNames = classnames('btn-icon', {

    });
    return (
        <button type="button" className={buttonClassNames} onClick={onClick}>
                {ring && <i className="icon icon--notification icon--red-circle"></i>}
                {!ring && <i className="icon icon--notification"></i>}

        </button>
    );
};