import {
    PRELOADER_PAGE_IS_SHOW, PRELOADER_PAGE_IS_HIDE, PRELOADER_PAGE_INIT, PRELOADER_PAGE_TEXT
} from "../constants/actionTypes";

export const preloaderPageShowAction = () => ({
    type: PRELOADER_PAGE_IS_SHOW,
});

export const preloaderPageHideAction = () => ({
    type: PRELOADER_PAGE_IS_HIDE,
});

export const preloaderPageInitAction = () => ({
    type: PRELOADER_PAGE_INIT,
});

export const preloaderPageTextAction = (text = null) => ({
    type: PRELOADER_PAGE_TEXT,
    text: text
});