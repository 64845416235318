import React, {Component} from 'react';
import {connect} from "react-redux";
import {
    approveBookingList,
    fetchBookings,
    rejectBookingList
} from "../../../services/bookingService";
import {GridCol} from "../../../../components/collections/grid";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {Link} from "react-router-dom";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import ChooseMemberSelect from "../family/ChooseMemberSelect";
import {formInitAction} from "../../../../actions/formAction";
import {Redirect} from "react-router";

class Treatment extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    componentDidMount() {
        const {auth: {user}} = this.props;
        this.props.onInitForm("chooseMemberSelect", {"member": user});
        this.props.onListFilterField("statuses", "COMPLETED");
        this.props.onListFilterField("patientId", user.id);
        this.props.onListSorts({completedAt: 'desc'});
        this.props.onFetchBookings(1);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData}} = this.props;
        const {form: {data: prevFormData}} = prevProps;

        const {chooseMemberSelect: curChooseMemberSelect = {}} = curFormData;
        const {chooseMemberSelect: prevChooseMemberSelect = {}} = prevFormData;
        const {member : curMember= null} = curChooseMemberSelect;
        const {member : prevMember= null} = prevChooseMemberSelect;
        if (curMember && prevMember && curMember.id != prevMember.id) {
            this.props.onListFilterField("statuses", "COMPLETED");
            this.props.onListFilterField("patientId", curMember.id);
            this.props.onListSorts({completedAt: 'desc'});
            this.props.onFetchBookings(1);
        }
    }

    onPagination(pageNumber) {
        this.props.onFetchBookings(pageNumber);
    }

    onFilter(fieldName, fieldValue) {
        this.props.onListFilterField(fieldName, fieldValue);
        this.props.onFetchBookings(1);
    }

    componentWillUnmount() {
        this.props.onInitList("bookings");
    }

    render() {
        const {list: {data, pagination}, auth: {user}} = this.props;
        if (user.role === 'PATIENT' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }

        const {bookings = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['bookings'] || {};
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    {!user.familyMember && <EmptyTag>
                        <div className="col-md-6">
                            <ChooseMemberSelect/>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </EmptyTag>}
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.treatments.header"/></Header>
                    </div>
                </div>

                <div className="row content-row">
                    <div className="col-12">
                        <div className="row link-blocks-row">
                            {bookings.map(booking => {
                                    const {doctor, consultation = {}, symptoms} = booking;
                                    const {diagnosis = ""} = consultation || {};
                                    return (
                                        <GridCol count="2" key={booking.id}>
                                            <div className="link-block-wrapper">
                                                <Link to={'/patient/treatment/' + booking.id + '/detail'}
                                                      className="link-block"></Link>
                                                <div className="row">
                                                    <div className="col min-width-0">
                                                        <div>{diagnosis || ""}</div>
                                                        <div className="small-text color-gray">
                                                            <div
                                                                className="text-ellipsis">{symptoms && symptoms.map(symptom => symptom.name).join(", ")}</div>
                                                            <div><FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <span className="small-text color-gray"><FormattedMessage id="core.id.label"/> {booking.id}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </GridCol>
                                    );
                                }
                            )}
                            {bookings.length === 0 && <div className="col-12"><FormattedMessage id="page.treatments.treatments-list.empty" /></div>}

                            <div className="col-12">
                                {size < totalElements && <Pagination
                                    activeLinkClass={"active"}
                                    activePage={number + 1}
                                    itemsCountPerPage={size}
                                    totalItemsCount={totalElements}
                                    pageRangeDisplayed={5}
                                    onChange={this.onPagination}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onListSorts: (sorts) => dispatch(listSortAction("bookings", sorts)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber)),
        onApproveBooking: (booking) => dispatch(approveBookingList(booking)),
        onRejectBooking: (booking) => dispatch(rejectBookingList(booking)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("bookings", fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Treatment);