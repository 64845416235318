import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../components/collections/grid";
import Calendar from "react-calendar/dist/entry.nostyle";
import moment from "moment";
import {
    fetchDoctorScheduleStateByStatusAppointments, fetchDoctorVacationDays
} from "../../../services/bookingService";
import {formChangeField} from "../../../services/system/formService";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../actions/preloaderAction";
import {listInitDataAction} from "../../../../actions/listAction";
import {formInitAction} from "../../../../actions/formAction";
import {getCalendarLocale} from "../../../../utils/api";

class ScheduleCalendar extends Component {
    constructor(props) {
        super(props);
        this.getTimesOfDate = this.getTimesOfDate.bind(this);
        this.incrementMonth = this.incrementMonth.bind(this);
        this.decrementMonth = this.decrementMonth.bind(this);
        this.state = {
            vacationDays: [],
            scheduleState: [],
            availableDays: [],
            availableTime: [],
            activeMonth: new Date().getMonth(),
            activeYear: new Date().getFullYear(),
            activeFormattedDate: [],
            chosenFormattedDate: "",
            arrOfBusyDays: [],
            objOfDateTimes: {},
            chosenTime: '',
            isReload: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {isReload: curIsReload = false, form: {data: curFormData}} = this.props;
        const {isReload: prevIsReload = false, form: {data: prevFormData}} = prevProps;

        const {scheduleCalendar:curScheduleCalendar = {}} = curFormData;
        const {isReload:curIsReloadForm = false, isPreloader = true} = curScheduleCalendar;

        const {scheduleCalendar:prevScheduleCalendar = {}} = prevFormData;
        const {isReload:prevIsReloadForm = false} = prevScheduleCalendar;

        if (curIsReload != prevIsReload || (curIsReloadForm != prevIsReloadForm && curIsReloadForm)) {
            const {doctor} = this.props;
            const {activeMonth, activeYear} = this.state;
            this.fetchCalendarAdvanceData(doctor, activeMonth, activeYear, isPreloader);

            this.props.onInitForm("scheduleCalendar", {
                ...curScheduleCalendar,
                isReload: false
            })
        }

    }

    componentDidMount() {
        const {doctor} = this.props;
        const {activeMonth, activeYear} = this.state;
        this.fetchCalendarAdvanceData(doctor, activeMonth, activeYear);
    }

    incrementMonth() {
        const {doctor} = this.props;
        const {activeMonth, activeYear} = this.state;
        const newMonth = activeMonth === 11 ? 0 : activeMonth + 1;
        const newYear = activeMonth === 11 ? activeYear + 1 : activeYear;
        this.fetchCalendarAdvanceData(doctor, newMonth, newYear);
    }

    decrementMonth() {
        const {doctor} = this.props;
        const {activeMonth, activeYear} = this.state;
        const newMonth = activeMonth === 0 ? 11 : activeMonth - 1;
        const newYear = activeMonth === 0 ? activeYear - 1 : activeYear;
        this.fetchCalendarAdvanceData(doctor, newMonth, newYear);
    }

    fetchCalendarAdvanceData(doctor, month, year, isPreloader = true) {
        (async () => {
            if (isPreloader) this.props.onPageShowPreloader();
            let scheduleState = await fetchDoctorScheduleStateByStatusAppointments(doctor, month + 1, year);
            let vacationDays = await fetchDoctorVacationDays(doctor, month + 1, year);
            this.props.onListInitData("doctorVacationDays", vacationDays);
            this.setState({
                activeMonth: month,
                activeYear: year,
                scheduleState: scheduleState,
                vacationDays: vacationDays
            });
            if (isPreloader) this.props.onPageHidePreloader();
        })();
    }

    getTimesOfDate(date) {
        const {activeFormattedDate} = this.state;
        const activeDayTimes = activeFormattedDate[date];
        this.setState({objOfDateTimes: activeDayTimes});
    }

    render() {
        const {onChooseCalendarDate, defaultChooseDate, isShowRequested = true, isShowApproved = true, showDayStateShort = false} = this.props;
        const NextLabelComponent = () => <div onClick={this.incrementMonth}>&rsaquo;</div>;
        const PrevLabelComponent = () => <div onClick={this.decrementMonth}>&lsaquo;</div>;
        let requestedDays = [];
        let approvedDays = [];
        let freeDays = [];
        const {vacationDays} = this.state;

        Object.entries(this.state.scheduleState).map(([date, status]) => {
            const day = moment(date).date();
            if (status === 'REQUESTED' && isShowRequested) {
                requestedDays.push(day);
            } else if (status === 'APPROVED' && isShowApproved) {
                approvedDays.push(day);
            } else if (status === 'FREE') {
                freeDays.push(day);
            }
        });

        const tileClassNameShortStates = ({activeStartDate, date, view}) =>
            view === 'month' && requestedDays.findIndex(value => value === moment(date).date()) !== -1 ? "color-green" :
                view === 'month' && approvedDays.findIndex(value => value === moment(date).date()) !== -1 ? "color-green" :
                    view === 'month' && freeDays.findIndex(value => value === moment(date).date()) !== -1 ? "color-green" :
                        view === 'month' && vacationDays.findIndex(value => moment(value).date() === moment(date).date()) !== -1 ? "color-red" :
                            "color-black";

        const tileClassNameFullStates = ({activeStartDate, date, view}) =>
            view === 'month' && requestedDays.findIndex(value => value === moment(date).date()) !== -1 ? "color-orange" :
                view === 'month' && approvedDays.findIndex(value => value === moment(date).date()) !== -1 ? "color-green" :
                    view === 'month' && vacationDays.findIndex(value => moment(value).date() === moment(date).date()) !== -1 ? "color-red" :
                        "color-black";

        return (
            <GridRow>
                <GridCol count='1'>
                    <Calendar
                        activeStartDate={new Date()}
                        calendarType={"US"}
                        view="month"
                        name='calendar'
                        value={defaultChooseDate}
                        minDetail='month'
                        prev2Label={null}
                        next2Label={null}
                        tileClassName={showDayStateShort ? tileClassNameShortStates : tileClassNameFullStates}
                        nextLabel={<NextLabelComponent/>}
                        prevLabel={<PrevLabelComponent/>}
                        locale={getCalendarLocale()}
                        onClickDay={(value) => onChooseCalendarDate(value)}
                        showNeighboringMonth={false}
                    />
                </GridCol>
            </GridRow>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onListInitData: (listName, data) => dispatch(listInitDataAction(listName, data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCalendar);
