import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {createTicketSupport} from "../services/commonService";
import {Modal} from "../../components/modules/modal";
import {Header} from "../../components/elements/header";
import {GridCol, GridRow} from "../../components/collections/grid";
import {Textarea} from "../../components/elements/textarea";
import {Button} from "../../components/elements/button";
import {modalHide} from "../services/system/modalService";
import {formChangeField} from "../services/system/formService";
import {formChangeFieldAction} from "../../actions/formAction";

class CreateTicketSupport extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    onClear(event) {
        this.props.onFormSimpleChange("createTicketSupport", "description", "");
    }

    onChange(event) {
        this.props.onFormChangeField("createTicketSupport", event);
    }

    render() {
        const {form} = this.props;
        const {createTicketSupport = {}} = form.data;
        const errors = form.errors.createTicketSupport;
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('createTicketSupport')}>
                <Header as="h2"><FormattedMessage id="support.service"/></Header>
                <div className="row form-elements">
                    <GridRow>
                        <GridCol count='1'>
                            <Textarea placeholder={<FormattedMessage id="description.your.problem"/>}
                                      name="description"
                                      error={(errors && errors.description) || null}
                                      value={createTicketSupport.description || ''}
                                      disabled={false}
                                      onChange={this.onChange}/>
                        </GridCol>
                        <GridCol count='1'>
                            <div className="form-submit btns-width-auto">
                                <Button color={"white-red"} onClick={this.onClear}>
                                    <FormattedMessage id="clear"/>
                                </Button>
                                <Button onClick={() => this.props.onSend(createTicketSupport)}><FormattedMessage
                                    id="core.btn.send.label"/></Button>
                            </div>
                        </GridCol>
                    </GridRow>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSend: (data) => dispatch(createTicketSupport(data)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTicketSupport);
