import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../components/modules/modal";
import {Button} from "../../../../components/elements/button";
import {Label} from "../../../../components/elements/label";
import {modalHide} from "../../../services/system/modalService";
import {fetchSpecializations} from "../../../services/bookingService";
import {withRouter} from 'react-router-dom';
import {fetchLanguages, fetchSystemSettings} from "../../../services/commonService";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../components/elements/input";
import {getSexInput, getSexsInput, stringToFloat} from "../../../../utils/app";
import {Option, Select} from "../../../../components/elements/select";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {formChangeField} from "../../../services/system/formService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import {listFilterAction, listFilterFieldAction} from "../../../../actions/listAction";
import _ from 'lodash'
import {AutocompleteAsync} from "../../../systems/autocomplete";

class CatalogDoctorsFilter extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onSearchSpecialization = this.onSearchSpecialization.bind(this);
        this.onSelectedSpecialization = this.onSelectedSpecialization.bind(this);
        this.onSelectSex = this.onSelectSex.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onChangeFloat = this.onChangeFloat.bind(this);
        this.onSelectRating = this.onSelectRating.bind(this);
        this.onBlurSpecialization = this.onBlurSpecialization.bind(this);

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list: {data: curListData = {}}, form: {data: curFormData}} = this.props;
        const {languages: curLanguages = []} = curListData;
        const {catalogDoctorsFilter: curCatalogDoctorsFilter = null} = curFormData;
        if (curLanguages.length > 0 && !curCatalogDoctorsFilter["speakLanguages"]) {
            this.props.onChangeDropdown("speakLanguages", curLanguages);
        }
    }

    componentDidMount() {
        const {list: {filters}, form: {data: formData}} = this.props;
        const {catalogDoctors: listCatalogDoctorsFilter = {}} = filters;
        const {originCatalogDoctorsFilter = {}} = formData;

        if (Object.keys(listCatalogDoctorsFilter).length == 0) {
            const catalogDoctorsFilter = {
                "specializations": {id: "ANY", name: "ANY"}
            };
            this.props.onInitForm("catalogDoctorsFilter", catalogDoctorsFilter);
        } else {
            Object.keys(originCatalogDoctorsFilter).map(f => {
                if (!listCatalogDoctorsFilter[f]) {
                    _.unset(originCatalogDoctorsFilter, f);
                }
            })

            if (!originCatalogDoctorsFilter["specializations"]) {
                originCatalogDoctorsFilter["specializations"] = {id: "ANY", name: "ANY"};
            }

            this.props.onInitForm("catalogDoctorsFilter", originCatalogDoctorsFilter)
        }

        this.props.onfetchSystemSettings();
        this.props.onFetchLanguages();
    }

    onChange(event) {
        this.props.onFormChangeField('catalogDoctorsFilter', event);
    }

    onOpen(event) {
        this.props.onListFilterField("specializations", "searchText", "");
        this.props.onFetchSpecializations(1);
    }

    onSearchSpecialization(event, query, reason) {
        this.props.onListFilterField("specializations", "searchText", query);
        this.props.onFetchSpecializations(1);
    }

    onBlurSpecialization(event) {
        if (!event.target.value) {
            this.props.onChangeDropdown("specializations", {
                id: "ANY",
                name: "ANY"
            });
        }
    }

    onSelectedSpecialization(event, value, reason) {
        this.props.onChangeDropdown("specializations", value);
    }

    onSelectSex(id) {
        this.props.onChangeDropdown("sex", id);
    }

    onSelectRating(rating) {
        this.props.onChangeDropdown("ratingFrom", rating);
    }

    onSave = () => {
        const {form, list, onFilter} = this.props;
        const {filters: {catalogDoctors: listCatalogDoctorsFilter = {}}, data: {languages = []}} = list;
        const {data: {catalogDoctorsFilter = {}}} = form;
        let chosenFilters = {};

        Object.keys(listCatalogDoctorsFilter).map(f => {
            if (!catalogDoctorsFilter[f]) {
                _.unset(listCatalogDoctorsFilter, f);
            }
        })

        this.props.onInitForm("originCatalogDoctorsFilter", catalogDoctorsFilter);

        Object.keys(catalogDoctorsFilter).map(keyFilter => {
            if (catalogDoctorsFilter[keyFilter] == null || catalogDoctorsFilter[keyFilter] == "") return;
            switch (keyFilter) {
                case "specializations" :
                    if (catalogDoctorsFilter[keyFilter].id != 'ANY') {
                        chosenFilters[keyFilter] = catalogDoctorsFilter[keyFilter].name;
                        listCatalogDoctorsFilter[keyFilter] = catalogDoctorsFilter[keyFilter].id;
                    }
                    break;
                case "speakLanguages":
                    if (catalogDoctorsFilter[keyFilter].length != languages.length) {
                        chosenFilters[keyFilter] = catalogDoctorsFilter[keyFilter].map(i => i.name).join(", ");
                        listCatalogDoctorsFilter[keyFilter] = catalogDoctorsFilter[keyFilter].map(i => i.id).join("-");
                    }
                    break;
                case "sex" :
                    chosenFilters[keyFilter] = getSexInput(catalogDoctorsFilter[keyFilter]);
                    listCatalogDoctorsFilter[keyFilter] = catalogDoctorsFilter[keyFilter];
                    break;
                case "ratingFrom" :
                    chosenFilters[keyFilter] = this.getRatingFilterLabel(catalogDoctorsFilter[keyFilter]);
                    listCatalogDoctorsFilter[keyFilter] = catalogDoctorsFilter[keyFilter];
                    break;
                default :
                    chosenFilters[keyFilter] = catalogDoctorsFilter[keyFilter];
                    listCatalogDoctorsFilter[keyFilter] = catalogDoctorsFilter[keyFilter];
            }

        });

        this.props.onInitFilter(listCatalogDoctorsFilter);
        this.props.onInitForm("chosenFilters", chosenFilters);
        this.props.onModalHide('catalogDoctorsFilter');
        onFilter();
    }

    getRatingFilterLabels() {
        return [
            {value: 1, label: "one.and.above"},
            {value: 2, label: "two.and.above"},
            {value: 3, label: "three.and.above"},
            {value: 4, label: "four.and.above"}
        ];
    }

    getRatingFilterLabel(value) {
        const index = this.getRatingFilterLabels().findIndex(i => i.value == value);
        if (index === -1) return null;
        return this.getRatingFilterLabels()[index].label;
    }

    onChangeFloat(event) {
        const target = event.target;
        let value = stringToFloat(target.value);
        this.props.onFormSimpleChange('catalogDoctorsFilter', target.name, value);
    }

    onClear = () => {
        const {onFilter} = this.props;
        this.props.onInitFilter({});
        this.props.onModalHide('catalogDoctorsFilter');
        onFilter();
    }

    render() {
        const {form, list} = this.props;
        const {data: {specializations = [], languages = []}} = list;
        const {data: {catalogDoctorsFilter = {}}} = form;
        let speakLanguages = catalogDoctorsFilter.speakLanguages || [];
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('catalogDoctorsFilter')}>
                <h2><FormattedMessage id="filter"/></h2>

                <div className="form-center-300">
                    <div className="row form-elements">
                        <div className="col-12">
                            <div className="row small-form-row align-items-center">
                                <div className="col-auto">
                                    <Label><FormattedMessage id="price"/></Label>
                                </div>
                                <div className="col">
                                    <Input name="priceReceptionFrom"
                                           onChange={this.onChange}
                                           onBlur={this.onChangeFloat}
                                           value={catalogDoctorsFilter.priceReceptionFrom || ""}
                                           placeholder={<FormattedMessage id="from"/>}/>
                                </div>
                                <div className="col-auto">
                                    -
                                </div>
                                <div className="col">
                                    <Input name="priceReceptionTo"
                                           onChange={this.onChange}
                                           onBlur={this.onChangeFloat}
                                           value={catalogDoctorsFilter.priceReceptionTo || ""}
                                           placeholder={<FormattedMessage id="to"/>}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row small-form-row align-items-center">
                                <div className="col-md-4">
                                    <Label><FormattedMessage id="core.gender.label"/></Label>
                                </div>
                                <div className="col-md-8">
                                    <FormattedMessage id={getSexInput(catalogDoctorsFilter.sex) || 'any'}>
                                        {optionNameSelected =>
                                            <Select name="sex"
                                                    height={40}
                                                    noLabel
                                                    optionNameSelected={optionNameSelected}>

                                                <EmptyTag>
                                                    <Option
                                                        active={!catalogDoctorsFilter.sex}
                                                        onClick={() => this.onSelectSex(null)}>
                                                        <FormattedMessage id={'any'}/>
                                                    </Option>
                                                    {getSexsInput().map(key =>
                                                        <Option
                                                            active={catalogDoctorsFilter.sex && catalogDoctorsFilter.sex == key}
                                                            key={key}
                                                            onClick={() => this.onSelectSex(key)}>
                                                            {<FormattedMessage id={getSexInput(key)}/>}
                                                        </Option>
                                                    )}
                                                </EmptyTag>

                                            </Select>}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row small-form-row align-items-center">
                                <div className="col-md-4">
                                    <Label><FormattedMessage id="core.specialization.label"/></Label>
                                </div>
                                <div className="col-md-8">

                                    <FormattedMessage id={'any'}>
                                        {optionNameSelected => {

                                            const value = catalogDoctorsFilter.specializations && catalogDoctorsFilter.specializations.id == 'ANY' ?
                                                {
                                                    id: "ANY",
                                                    name: optionNameSelected[0]
                                                } : catalogDoctorsFilter.specializations;

                                            return (
                                                <AutocompleteAsync onInputChange={this.onSearchSpecialization}
                                                                   getOptionLabel={option => option.name || ''}
                                                                   onChange={this.onSelectedSpecialization}
                                                                   onOpen={this.onOpen}
                                                                   value={value || null}
                                                                   onBlur={this.onBlurSpecialization}
                                                                   placeholderFixed={true}
                                                                   options={specializations}/>
                                            );
                                        }
                                        }</FormattedMessage>


                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row small-form-row align-items-center">
                                <div className="col-md-4">
                                    <Label><FormattedMessage id="doctor.speak.languages"/></Label>
                                </div>
                                <div className="col-md-8">
                                    <Select
                                        multiple={true}
                                        name="speakLanguages"
                                        onRemoveOption={(v) => this.props.onChangeMultipleDropdown("speakLanguages", v)}
                                        optionsSelected={speakLanguages}>
                                        {
                                            languages.map(language =>
                                                <Option
                                                    active={speakLanguages.findIndex(i => i.id == language.id) !== -1}
                                                    multiple={true}
                                                    key={language.id}
                                                    onClick={() => this.props.onChangeMultipleDropdown("speakLanguages", language)}>
                                                    {language.name}
                                                </Option>
                                            )
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="row small-form-row align-items-center">
                                <div className="col-md-4">
                                    <Label><FormattedMessage id="rating"/></Label>
                                </div>
                                <div className="col-md-8">
                                    <FormattedMessage
                                        id={this.getRatingFilterLabel(catalogDoctorsFilter.ratingFrom) || "any"}>
                                        {optionAnySelected =>
                                            <Select noLabel
                                                    height={40}
                                                    optionNameSelected={optionAnySelected}>
                                                <Option
                                                    active={!catalogDoctorsFilter.ratingFrom}
                                                    onClick={() => this.onSelectRating(null)}>
                                                    <FormattedMessage id={'any'}/>
                                                </Option>
                                                {
                                                    this.getRatingFilterLabels().map(({label, value}) =>
                                                        <Option
                                                            active={catalogDoctorsFilter.ratingFrom &&
                                                                catalogDoctorsFilter.ratingFrom == value}
                                                            key={value}
                                                            onClick={() => this.onSelectRating(value)}>
                                                            <FormattedMessage id={label}/>
                                                        </Option>
                                                    )
                                                }
                                            </Select>}
                                    </FormattedMessage>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <Input
                                name="address"
                                onChange={this.onChange}
                                value={catalogDoctorsFilter.address || ""}
                                placeholder={<FormattedMessage id="address"/>}/>
                        </div>
                        <div className="col-12">
                            <div className="form-submit">
                                <Button color="white-red" onClick={this.onClear}>
                                    <FormattedMessage id="clear.filter"/>
                                </Button>
                                <Button onClick={this.onSave}>
                                    <FormattedMessage id="core.btn.save.label"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFetchLanguages: () => dispatch(fetchLanguages()),
        onInitFilter: (filters) => dispatch(listFilterAction('catalogDoctors', filters)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onFetchSpecializations: (pageNumber) => dispatch(fetchSpecializations(pageNumber)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('catalogDoctorsFilter', fieldName, fieldValue)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onChangeMultipleDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('catalogDoctorsFilter', fieldName, fieldValue, null, true)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CatalogDoctorsFilter));
