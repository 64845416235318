import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Button} from "../../../../components/elements/button";
import {Icon} from "../../../../components/elements/icon";

import {GridCol} from "../../../../components/collections/grid";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {listFilterFieldAction, listSortAction} from "../../../../actions/listAction";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {Link} from "react-router-dom";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import Avatar from "../../../systems/avatar";
import {formInitAction} from "../../../../actions/formAction";
import {
    approvedExtraConsultation,
    canceledExtraConsultation,
    fetchExtraConsultations
} from "../../../services/extraConsultationsService";

class ExtraConsultations extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    componentDidMount() {
        this.props.onListSorts({'consultation.booking.timeOfReceipt': 'desc'});
        this.onPagination(1);
    }


    onPagination(pageNumber) {
        this.props.onFetchExtraConsultations(pageNumber);
    }

    onFilter(fieldName, fieldValue) {
        this.props.onListFilterField(fieldName, fieldValue == 'ALL' ? null : fieldValue);
        this.onPagination(1);
    }

    render() {
        const {list: {data, pagination, filters}, auth: {user: currentUser}} = this.props;
        const {extraConsultations = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['extraConsultations'] || {};
        const {status: filterStatus = "ALL"} = filters['extraConsultations'] || {};
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">

                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="core.layout.sidebar.item.extra.consultation.label"/></Header>
                    </div>
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab active={filterStatus === 'ALL'}
                                 onClick={() => this.onFilter("status", "ALL")}><FormattedMessage
                                id="status.all"/></Tab>
                            <Tab active={filterStatus === 'REQUESTED'}
                                 onClick={() => this.onFilter("status", "REQUESTED")}><FormattedMessage
                                id="status.requested"/></Tab>
                        </Tabs>
                    </GridCol>
                </div>

                <div className="row link-blocks-row">
                    {extraConsultations.map(extraConsultation => {
                        const {id, consultation, status, user} = extraConsultation;
                        const booking = consultation.booking;
                        const doctor = user.id == currentUser.id ? booking.doctor : user
                        return (
                            <GridCol count="2" key={id}>
                                <div className="link-block-wrapper">
                                    <Link to={'/doctor/bookings/' + booking.id + '/detail'} className="link-block"></Link>
                                    <div className="row avatar-row align-items-center">
                                        <div className="col-12 padding-bottom-10">
                                            <div className="icon-item small-text color-gray margin-right-15">
                                                <Icon name="clock" size="16"/>
                                                <span className="icon-item__text">
                                                        {moment(booking.timeOfReceipt).format("lll")}
                                                    </span>
                                            </div>
                                            <div className="icon-item small-text color-gray">
                                                <Icon name="calendar-exclam-black" size="24"/>
                                                {status === 'REQUESTED' &&
                                                <span className="icon-item__text"><FormattedMessage
                                                    id="status.requested"/></span>}
                                                {status === 'APPROVED' &&
                                                <span className="icon-item__text"><FormattedMessage
                                                    id="status.approved"/></span>}
                                            </div>
                                        </div>
                                        <div className="col-auto avatar-row__image">
                                            <Avatar userId={booking.doctor.id}/>
                                        </div>
                                        <div className="col avatar-row__text">
                                            <div className="">
                                                {booking.doctor.id == currentUser.id && <Icon name={'call-outgoing'} size={"24"}/>}
                                                {user.id == currentUser.id && <Icon name={'call-incoming'} size={"24"}/>}
                                                <FormattedMessage id="core.doctor.name" values={{
                                                    firstName: doctor.firstName,
                                                    lastName: doctor.lastName
                                                }}/>
                                            </div>
                                            <div className="small-text color-gray">
                                                <FormattedMessage id="core.patient.name" values={{
                                                    firstName: booking.patient.firstName,
                                                    lastName: booking.patient.lastName
                                                }}/>
                                            </div>
                                        </div>
                                        <div className="col-auto avatar-row__controls">
                                            <div className="small-controls">
                                                {status === 'REQUESTED' && user.id == currentUser.id &&
                                                <Button onClick={() => this.props.onConfirm({
                                                    text: <FormattedMessage id="confirm.accept"/>,
                                                    onProceed: (() => this.props.onApprovedExtraConsultation(id))
                                                })}><FormattedMessage id="core.btn.accept.label"/></Button>}

                                                <Button color="white-red" onClick={() => this.props.onConfirm({
                                                    text: <FormattedMessage id="confirm.cancel"/>,
                                                    onProceed: (() => this.props.onCanceledExtraConsultation(id))
                                                })}><FormattedMessage id="core.btn.cancel.label"/></Button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </GridCol>
                        );
                    }
                    )}
                    {extraConsultations.length === 0 &&
                    <div className="col-12"><FormattedMessage id="empty.list"/></div>}

                    <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchExtraConsultations: (pageNumber) => dispatch(fetchExtraConsultations(pageNumber)),
        onApprovedExtraConsultation: (id) => dispatch(approvedExtraConsultation(id)),
        onCanceledExtraConsultation: (id) => dispatch(canceledExtraConsultation(id)),
        onListSorts: (sorts) => dispatch(listSortAction("extraConsultations", sorts)),
        onListFilterField: (fieldName, fieldValue) =>
            dispatch(listFilterFieldAction("extraConsultations", fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExtraConsultations);
