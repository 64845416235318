import React from 'react';
import uuid from "react-uuid";
import {toast} from "react-toastify";
import DoctorCall from "../pages/call/DoctorCall";
import {PushNotification} from "../../components/modules/pushNotification";
import {bellRingAction} from "../../actions/bellAction";
import {handsetRingAction} from "../../actions/handsetAction";
import {modalHide, modalShow} from "./system/modalService";
import {formChangeFieldAction, formInitAction} from "../../actions/formAction";
import {fetchSizeQueueEmergencyCalls} from "./callService";
import {Link} from "react-router-dom";
import {disabledUserAction, enabledUserAction, updateProfileAction} from "../../actions/authAction";
import {fetchBooking, fetchBookings, fetchDoctors} from "./bookingService";
import {fetchConsultationComingSoon} from "./doctorService";
import Stomp from "stompjs";
import {wsInitAction} from "../../actions/wsAction";
import {refreshToken} from "./userService";
import {updateScheduleForAssistant} from "./assistantService";
import {encode as base64_encode} from 'base-64';
import {callDestroyAction} from "../../actions/callAction";
import {handlerCloseChat, showNewMessage} from "./chatService";
import ChatNotificationNewMessage from "../systems/chat/ChatNotificationNewMessage";


let wsConnectTimer = null;
export const wsConnect = () => {
    return (dispatch, state) => {
        const {auth: {isAuthenticated, user}, webSocket: {isConnected}} = state();
        const accessToken = localStorage.getItem('accessToken');
        if (isConnected) {
            return false;
        } else if (!isAuthenticated || !accessToken) {
            return false;
        }

        const hash = uuid();
        const strUserInfo = JSON.stringify({hash: hash, userId: user.id, isMobile: false});
        const login = base64_encode(strUserInfo);
        const ws = new WebSocket(window._env_.WS_URL);
        const client = Stomp.over(ws);
        client.debug = () => {
        };
        const onSuccess = (client) => {
            const destination = "/exchange/telemed.core/user-" + user.id;
            const headers = {'x-queue-name': 'user-' + user.id + '-' + hash};
            const subscribe = client.subscribe(destination, (msg) => dispatch(handlerMessage(msg.body)), headers)
            dispatch(wsInitAction({client: client, subscribe: subscribe}));
            if (wsConnectTimer) clearTimeout(wsConnectTimer);
        };

        const onError = (error) => {
            if (wsConnectTimer) clearTimeout(wsConnectTimer);
            if (error.headers && error.headers.message == 'Bad CONNECT') {
                refreshToken();
            }
            wsConnectTimer = setTimeout(dispatch(wsConnect()), 5000);
        }

        if (wsConnectTimer) clearTimeout(wsConnectTimer);
        client.connect(login, accessToken, () => onSuccess(client), onError);
    }
}

export const updateDoctorHomePage = (typeMsg, dataMsg) => {
    return (dispatch, state) => {
        const {user} = state().auth;
        switch (typeMsg) {
            case "DOCTOR_REJECT_APPOINTMENT":
            case "PATIENT_REJECT_APPOINTMENT":
            case "APPROVED_APPOINTMENT":
                if (user.role === 'DOCTOR') {
                    switch (window.location.pathname) {
                        case "/doctor/home" :
                        case "/" :
                            dispatch(fetchBookings(1, "patientWaitingItems", false));
                            dispatch(fetchBookings(1, "widgetScheduleItems", false));
                            dispatch(fetchConsultationComingSoon())
                            break;
                    }
                }
                break;
            case "DOCTOR_COMPLETED_CONSULTATION" :
                if (user.role === 'DOCTOR') {
                    switch (window.location.pathname) {
                        case "/doctor/home" :
                        case "/" :
                            dispatch(fetchBookings(1, "widgetScheduleItems", false));
                            break;
                    }
                }
                break;
            case "PAY_APPOINTMENT" :
            case "REJECTED_NOT_PAYED_APPOINTMENT" :
            case "APPOINTMENT_REQUEST" :
                if (user.role === 'DOCTOR') {
                    switch (window.location.pathname) {
                        case "/doctor/home" :
                        case "/" :
                            dispatch(fetchBookings(1, "patientWaitingItems", false));
                            break;
                    }
                }
                break;
        }
    }
}

export const handlerMessage = (message) => {
    return (dispatch, state) => {
        const {form: {data: formData}, auth: {user}} = state();
        const {title: msgTitle, body: msgBody, data: msgData} = JSON.parse(message);
        const {type = ""} = msgData || {};
        const toastId = uuid();
        const toastDoctorCallOptions = {
            position: 'bottom-right',
            autoClose: 10000,
            draggable: false,
            closeOnClick: false,
            hideProgressBar: true,
            toastId: toastId
        };

        let bodyPushNotification = msgBody;
        dispatch(updateDoctorHomePage(type, msgData));
        switch (type) {
            case "ASSISTANT_UPDATE_SCHEDULE_DOCTOR":
                if (user.role === 'ASSISTANT') {
                    switch (window.location.pathname) {
                        case "/assistant/schedule" :
                            dispatch(updateScheduleForAssistant(msgData['doctorId']));
                            break;
                        case "/assistant/home" :
                        case "/" :
                            dispatch(fetchDoctors(1, "assistantDoctors", false))
                            break;
                    }
                }
                break;
            case "REJECTED_NOT_PAYED_APPOINTMENT" :
                const linkTo = user.role === 'DOCTOR' ?
                    "/doctor/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random() :
                    "/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random();
                bodyPushNotification =
                    <Link to={linkTo} onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case 'BOOKING_NOT_PAID':
            case 'EXTRA_CONSULTATION_REQUEST':
            case 'EXTRA_CONSULTATION_APPROVE':
            case 'EXTRA_CONSULTATION_CANCEL':
            case "APPOINTMENT_REQUEST" :
                let linkUrl = null;
                switch (user.role) {
                    case 'ASSISTANT' :
                        linkUrl = '/assistant/schedule?doctorId=' + msgData['doctorId'] + '&bookingId=' + msgData['bookId'] + "&reload=" + Math.random();
                        break;
                    case 'DOCTOR' :
                        linkUrl = "/doctor/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random();
                        break;
                }

                if (linkUrl) {
                    bodyPushNotification =
                        <Link to={linkUrl} onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>;
                }
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "DOCTOR_CREATE_APPOINTMENT" :
                bodyPushNotification =
                    <Link to={"/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random()}
                          onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "REQUEST_RESCHEDULING" :
                bodyPushNotification =
                    <Link to={"/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random()}
                          onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "ACCEPT_RESCHEDULING" :
                bodyPushNotification =
                    <Link to={"/doctor/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random()}
                          onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "APPROVED_DOCTOR_REQUEST_UPDATE_PRICE" :
            case "CANCELED_DOCTOR_REQUEST_UPDATE_PRICE" :
                bodyPushNotification =
                    <Link to={"/doctor/schedule/configure?reload=" + Math.random()}
                          onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "ASK_PAYMENT":
            case "DOCTOR_REJECT_APPOINTMENT":
                if (user.role === 'PATIENT') {
                    bodyPushNotification =
                        <Link to={"/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random()}
                              onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                    toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                    dispatch(bellRingAction());
                }
                dispatch(handlerCloseChat(msgData['bookId']));
                break;
            case "APPROVED_APPOINTMENT":
                if (user.role === 'PATIENT') {
                    bodyPushNotification =
                        <Link to={"/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random()}
                              onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                    toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                    dispatch(bellRingAction());
                }
                break;
            case "DOCTOR_COMPLETED_CONSULTATION":
                if (user.role === 'PATIENT') {
                    bodyPushNotification =
                        <Link
                            to={"/patient/treatment/" + msgData['bookId'] + "/detail?isNewDoctorReview=true&reload=" + Math.random()}
                            onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                    toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                    dispatch(bellRingAction());
                    const {doctorId = null} = msgData;
                    const {startChatConsultation = {}} = formData;
                    const {doctorId: consultationDoctorId = null} = startChatConsultation;
                    dispatch(handlerCloseChat(msgData['bookId']));
                    if (doctorId != null && consultationDoctorId != null && consultationDoctorId === doctorId) {
                        dispatch(formChangeFieldAction("startChatConsultation", "isCompleted", true));
                    }
                }
                break;
            case "PRESCRIPTION_READY_TO_ISSUE":
                bodyPushNotification =
                    <Link
                        to={"/patient/treatment/" + msgData['bookId'] + "/detail?tabName=prescription&reload=" + Math.random()}
                        onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "PHARMACY_RETURN_PRESCRIPTION":
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "PRESCRIBE_MEDICATION" :
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "PATIENT_REJECT_APPOINTMENT":
                bodyPushNotification =
                    <Link to={"/doctor/bookings/" + msgData['bookId'] + "/detail?reload=" + Math.random()}
                          onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                dispatch(handlerCloseChat(msgData['bookId']));
                break;
            case "ASSISTANT_INVITE_ACCEPT":
                bodyPushNotification = <Link to={"/doctor/assistants?status=accepted"}
                                             onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "APPOINTMENT_TEMPLATE_PRICE_REQUESTED":
            case "ASSISTANT_EXCLUDED_BY_DOCTOR":
            case 'COMPLETED_REMITTANCE':
            case "CHANGED_BILLING_CYCLE_PERIOD":
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "EMERGENCY_CALL":
                if (user.isEnabled) {
                    toast(<DoctorCall toastId={toastId} data={msgData}/>, toastDoctorCallOptions);
                    dispatch(handsetRingAction());
                }
                break;
            case "START_CHAT_CONSULTATION":
                dispatch(modalShow("startChatConsultation", msgData));
                break;
            case "APPLY_CHAT_CONSULTATION":
                if (user.role === 'PATIENT') {
                    const {data} = state().form;
                    const {startChatConsultation = null} = data;
                    const {bookId = null, isStarted = false} = startChatConsultation || {};
                    if (bookId && parseInt(bookId) === parseInt(msgData["bookId"]) && !isStarted) {
                        dispatch(modalHide("startChatConsultation"));
                    }
                }
                break;
            case 'CHAT_ROOM_NEW_MESSAGE':
                const {message, roomId} = msgData
                const {widgetChosenChatRoom = {}, bookingChosenChatRoom = {}} = formData;
                const {id: widgetChosenChatRoomId = null} = widgetChosenChatRoom;
                const {id: bookingChosenChatRoomId = null} = bookingChosenChatRoom;

                if ((widgetChosenChatRoomId == null && bookingChosenChatRoomId == null) ||
                    (widgetChosenChatRoomId != null && widgetChosenChatRoomId != roomId) ||
                    (bookingChosenChatRoomId != null && bookingChosenChatRoomId != roomId)) {
                    toast(
                        <ChatNotificationNewMessage toastId={toastId} user={message.user} text={message.text}
                                                    roomId={roomId}/>,
                        toastDoctorCallOptions);
                } else {
                    dispatch(showNewMessage('bookingChatRoomMessages', message, roomId));
                    dispatch(showNewMessage('widgetChatRoomMessages', message, roomId));
                }
                break;
            case "ACCEPT_EMERGENCY_CALL":
                dispatch(fetchSizeQueueEmergencyCalls());
                break;
            case "FAMILY_MEMBER_DETACH_REQUEST":
                bodyPushNotification =
                    <Link to={"/patient/family?reload=" + Math.random()}
                          onClick={() => toast.dismiss(toastId)}>{msgBody}</Link>
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                break;
            case "FAMILY_MEMBER_DETACH_REJECT" :
                dispatch(formChangeFieldAction("familyMember", "isDetachRequest", false));
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                dispatch(bellRingAction());
                break;
            case "FAMILY_MEMBER_DETACH":
                dispatch(updateProfileAction({...user, familyMember: null}));
                dispatch(formInitAction("familyMember", {}));
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                break;
            case "FAMILY_MEMBER_DEACTIVATE":
            case "DOCTOR_DISABLED":
                dispatch(disabledUserAction());
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                break;
            case "FAMILY_MEMBER_ACTIVATE":
            case "DOCTOR_ENABLED":
                dispatch(enabledUserAction());
                toast(<PushNotification title={msgTitle} body={bodyPushNotification}/>, toastDoctorCallOptions);
                break;
            case "APPLY_CALL":
                const {data} = state().form;
                const {incomingCall = null} = data;
                if (incomingCall && incomingCall["doctorId"] &&
                    parseInt(incomingCall["doctorId"]) === parseInt(msgData["doctorId"])) {
                    dispatch(modalHide("incomingCall"));
                }
                break;
            case "PATIENT_CALL":
            case "INCOMING_CALL":
                dispatch(modalShow("incomingCall", msgData));
                break;
            case "CANCEL_CALL":
                dispatch(modalHide("incomingCall"));
                dispatch(callDestroyAction());
                break;

            case "PAY_APPOINTMENT" :
                if (user.role === 'PATIENT') {
                    switch (window.location.pathname) {
                        case "/patient/home" :
                        case "/" :
                            dispatch(fetchBookings(1, "notPayedBookings", false));
                            break;
                        case "/bookings/" + msgData['bookId'] + "/paymentSuccess":
                        case "/bookings/" + msgData['bookId'] + "/detail" :
                            dispatch(fetchBooking(msgData['bookId']));
                            break;
                    }
                }
                break;
            default:
        }
    }
}