import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../components/collections/grid";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import DoctorBookingsByStatus from "../bookings/list";
import {FormattedMessage} from "react-intl";
import {Redirect} from "react-router";

class DoctorBookings extends Component {
    render() {
        const {tab: {data: tabData}, auth: {user: doctor}} = this.props;
        const {user} = this.props.auth;
        if (user.role === 'DOCTOR' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }
        const {doctorBookings: tabDoctorBookings = {}} = tabData;
        const {activeTabPanel: doctorBookingsActiveTabPanel = "waiting"} = tabDoctorBookings || {};
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="core.header.patients"/></Header>
                    </div>
                </div>
                <div className="row content-heading">
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab
                                active={doctorBookingsActiveTabPanel === 'waiting'}
                                onClick={() => this.props.onChangeTabPanel("waiting")}><FormattedMessage id="doctor.patients.waiting"/></Tab>
                            <Tab active={doctorBookingsActiveTabPanel === 'approved'}
                                 onClick={() => this.props.onChangeTabPanel("approved")}><FormattedMessage id="doctor.patients.approved"/></Tab>
                            <Tab active={doctorBookingsActiveTabPanel === 'rejected'}
                                 onClick={() => this.props.onChangeTabPanel("rejected")}><FormattedMessage id="doctor.patients.cancelled"/></Tab>
                            <Tab active={doctorBookingsActiveTabPanel === 'consulted'}
                                 onClick={() => this.props.onChangeTabPanel("consulted")}><FormattedMessage id="doctor.patients.consulted"/></Tab>
                        </Tabs>
                    </GridCol>
                </div>
                <div className="row content-row">
                    {doctorBookingsActiveTabPanel === 'waiting' &&
                    <DoctorBookingsByStatus status="REQUESTED" doctor={doctor} sort={'timeOfReceipt,asc'}/>}
                    {doctorBookingsActiveTabPanel === 'approved' &&
                    <DoctorBookingsByStatus status="APPROVED" doctor={doctor} sort={'timeOfReceipt,asc'}/>}
                    {doctorBookingsActiveTabPanel === 'rejected' &&
                    <DoctorBookingsByStatus status="REJECTED" doctor={doctor} sort={'timeOfReceipt,desc'}/>}
                    {doctorBookingsActiveTabPanel === 'consulted' &&
                    <DoctorBookingsByStatus status="COMPLETED" doctor={doctor} sort={'timeOfReceipt,desc'}/>}
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("doctorBookings", tabPanelName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorBookings);