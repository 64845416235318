import React from 'react';
import classnames from "classnames";

export const Header = (prop) => {
    const {as = "h1", align = '', color = ''} = prop;
    const headerClassNames = classnames({
        'align-center': align === 'center',
        'align-left': align === 'left',
        'align-right': align === 'right',
        'color-red': color === 'red',
        'color-green': color === 'green'
    });
    return (
        <>
            {as === 'h1' && <h1 className={headerClassNames}>{prop.children}</h1>}
            {as === 'h2' && <h2 className={headerClassNames}>{prop.children}</h2>}
            {as === 'h3' && <h3 className={headerClassNames}>{prop.children}</h3>}
            {as === 'h4' && <h4 className={headerClassNames}>{prop.children}</h4>}
        </>
    );
};