import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {GridCol} from "../../../../../components/collections/grid";
import {Label} from "../../../../../components/elements/label";
import {RadioBox, RadioBoxes} from "../../../../../components/elements/radiobox";
import {Header} from "../../../../../components/elements/header";
import {formChangeField, formSimpleChange} from "../../../../services/system/formService";
import {Option, Select} from "../../../../../components/elements/select";
import {InputError} from "../../../../../components/elements/error";
import ChooseTimeOfReceipt from "./ChooseTimeOfReceipt";
import {fetchDoctorScheduleAvailableAddresses} from "../../../../services/doctorScheduleService";

class Step2 extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectAddress = this.onSelectAddress.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('createBooking', event);
        if (event.target.name === 'typeConsultation') {
            if (event.target.value === 'OFFLINE') {
                const {form: {data: formData}} = this.props;
                const {createBooking = {}} = formData || {};
                this.props.onFetchDoctorScheduleAvailableAddresses(createBooking.doctor.id);
            } else {
                this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "address", null);
                this.props.onFormSimpleChangeField("createBooking", "address", null);
            }
            this.props.onFormSimpleChangeField("createBooking", "timeOfReceipt", null);
        }
    }

    onSelectAddress(address) {
        this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "address", address);
        this.props.onFormSimpleChangeField("createBooking", "address", address);
    }

    render() {
        const {form: {data: formData, errors: formErrors}, list: {data: listData}} = this.props;
        const {createBooking = {}, advanceParamsCreateBooking = {}} = formData || {};
        const errors = formErrors.createBooking;
        const {address = null} = advanceParamsCreateBooking;
        const {typeConsultation = null, doctor = null} = createBooking;
        const {doctorProfileAddresses = []} = listData;
        return (
            <EmptyTag>

                <GridCol count='1'>
                    <Label fixed={true}><FormattedMessage id="core.type.consultation.label"/></Label>
                    <RadioBoxes>
                        <RadioBox name='typeConsultation'
                                  value='CHAT'
                                  checked={createBooking && createBooking.typeConsultation === 'CHAT'}
                                  onChange={this.onChange}>
                            <FormattedMessage id="core.type.consultation.chat.label"/>
                        </RadioBox>
                        <RadioBox name='typeConsultation'
                                  value='VIDEO'
                                  checked={createBooking && createBooking.typeConsultation === 'VIDEO'}
                                  onChange={this.onChange}>
                            <FormattedMessage id="core.type.consultation.video.label"/>
                        </RadioBox>
                        <RadioBox name='typeConsultation'
                                  value='OFFLINE'
                                  checked={createBooking && createBooking.typeConsultation === 'OFFLINE'}
                                  onChange={this.onChange}>
                            <FormattedMessage id="core.type.consultation.offline.label"/>
                        </RadioBox>
                    </RadioBoxes>
                </GridCol>

                {createBooking.typeConsultation === 'OFFLINE' &&
                <GridCol count='1'>
                    <Select label={<FormattedMessage id="location"/>}
                            optionNameSelected={(address && address.text) || ''}>
                        {
                            doctorProfileAddresses.map(i =>
                                <Option
                                    active={address && i.id === address.id}
                                    key={i.id}
                                    onClick={() => this.onSelectAddress(i)}>
                                    {i.text}
                                </Option>
                            )
                        }
                    </Select>
                </GridCol>}

                <GridCol count='1'>
                    {
                        createBooking.doctor && createBooking.typeConsultation &&
                        (typeConsultation != 'OFFLINE' || (typeConsultation == 'OFFLINE' && address != null)) &&
                        <EmptyTag>
                            <Header as="h3"><FormattedMessage id="page.create.booking.time.receipt.label"/></Header>
                            <ChooseTimeOfReceipt doctor={doctor}
                                              address={address}
                                              typeConsultation={typeConsultation}
                                              timeOfReceipt={createBooking.timeOfReceipt || null}
                                              onChangeTime={this.onChange}/>
                            {errors && errors.timeOfReceipt && <InputError>{errors.timeOfReceipt}</InputError>}
                        </EmptyTag>
                    }
                </GridCol>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFetchDoctorScheduleAvailableAddresses: (doctorId) => dispatch(fetchDoctorScheduleAvailableAddresses(doctorId)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
