import React, {Component} from 'react';
import {connect} from "react-redux";
import {createSession} from 'opentok-react';
import {Header} from "../../../components/elements/header";
import {noticeShowAction} from "../../../actions/noticeAction";
import {modalHide, modalShow} from "../../services/system/modalService";
import {Redirect} from "react-router";
import {Button, ButtonIcon} from "../../../components/elements/button";

import classnames from "classnames";
import {
    callCallingInitAction,
    callCallingPauseAction,
    callDestroyAction,
    callSessionInitAction, callStreamsUpdateAction
} from "../../../actions/callAction";
import {completeCall, startRecord, stopMediaDevices} from "../../services/callService";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../actions/preloaderAction";
import {isModal} from "../../../utils/app";
import BookAnAppointment from "../doctor/bookings/detail/BookAnAppointment";
import Dropdown, {DropdownItem} from "../../systems/dropdown";
import BookingDetail from "./BookingDetail";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import Subscriber from "./Subscriber";
import Publisher from "./Publisher";
import CallParticipants from "./CallParticipants";

class Call extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenChat: false,
            isVideo: true,
            isMicrophone: true,
            streams: []
        };
        this.onCompleteCall = this.onCompleteCall.bind(this);
        this.onRedirectTo = this.onRedirectTo.bind(this);
        this.onVideoEnabled = this.onVideoEnabled.bind(true);
        this.onVideoDisabled = this.onVideoDisabled.bind(true);
        this.onMicrophoneEnabled = this.onMicrophoneEnabled.bind(true);
        this.onMicrophoneDisabled = this.onMicrophoneDisabled.bind(true);
        this._isMounted = false;
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.onCallSessionInitAction(null);
        this.props.onCallCallingPause();
    }

    onRedirectTo() {
        const {call: {consultationCallParams, commonParams}} = this.props;
        const {booking = null} = consultationCallParams;
        stopMediaDevices();
        this.props.onCallDestroy();
        if (!commonParams.callIncoming && booking != null) {
            return <Redirect to={"/doctor/bookings/" + booking.id + "/detail"}/>
        } else {
            return <Redirect to={"/"}/>
        }
    }

    componentWillMount() {
        this.props.onPreloaderPageShow();
        this._isMounted = true;
        const {call: {sessionOptions}} = this.props;

        const {apiKey = "", sessionId = "", token = ""} = sessionOptions;
        if (apiKey && sessionId && token) {
            this.sessionHelper = createSession({
                apiKey: apiKey,
                sessionId: sessionId,
                token: token,
                onStreamsUpdated: streams => {
                    if (this._isMounted) {
                        //this.props.onCallStreamsUpdate(streams);
                        this.setState({streams});
                    }
                },
                eventHandlers: this.sessionEvents,
                onConnect: () => this.onSessionConnectSuccess(),
                onError: (error) => this.onSessionConnectFail(error)
            });
            this.props.onCallSessionInitAction(this.sessionHelper);
        } else {
            this.sessionHelper = null;
        }
    }

    onCompleteCall = () => {
        const {sessionId = ""} = this.props.call.sessionOptions;
        this.props.onCompleteCall(sessionId);
    }

    onVideoEnabled = () => {
        this.setState({...this.state, isVideo: true})
    }

    onVideoDisabled = () => {
        this.setState({...this.state, isVideo: false})
    }

    onMicrophoneEnabled = () => {
        this.setState({...this.state, isMicrophone: true})
    }

    onMicrophoneDisabled = () => {
        this.setState({...this.state, isMicrophone: false})
    }

    onSessionConnectSuccess() {
        console.log("Connect success");
    }

    onSessionConnectFail(error) {
        console.log(error.message);
        this.props.onPreloaderPageHide();
        this.props.onNoticeShow('', <FormattedMessage id="call.failed.connect" values={{mgs: error.message}}/>);
        this.onRedirectTo();
    }

    sessionEvents = {
        sessionConnected: () => {
            console.log("session connected");
        },
        sessionDisconnected: () => {
            console.log("session disconnected");
        },
        streamDestroyed: (event) => {
            console.log("Stream stopped. Reason: " + event.reason);
            this.props.onNoticeShow('', <FormattedMessage id="call.competed.text"/>);
            this.onRedirectTo();
        }
    };

    render() {
        const {call: {consultationCallParams, calling, commonParams, isShow = false}, auth: {user}, modal} = this.props;
        const {booking = null} = consultationCallParams;
        const {patientId = null, callIncoming = true} = commonParams;

        if (this.sessionHelper == null) {
            this.props.onPreloaderPageHide();
            this.props.onNoticeShow('', <FormattedMessage id="call.missing.params.connect"/>);
            return <Redirect to={"/"}/>
        }

        const rowClassNames = classnames('row video-chat-row', {
            'chat-is-expanded': this.state.isOpenChat
        });
        const dropdownProfile = <ButtonIcon icon="points-circle" onClick={null}></ButtonIcon>;
        const streams = this.state.streams || [];

        return (
            <div style={{display: isShow ? 'block' : 'none'}}>
                <Header as="h1"><FormattedMessage id="core.call"/></Header>
                <div className='row content-row'>
                    <div className='col-md-8'>
                        <div className={rowClassNames}>
                            <div className="col-video col-md-6">
                                <div className='video-block video-block-patient'>
                                    <Publisher
                                        streams={streams}
                                        isMicrophone={this.state.isMicrophone}
                                        isVideo={this.state.isVideo}
                                        session={this.sessionHelper.session}
                                        onRedirectTo={this.onRedirectTo}
                                        onCompleteCall={this.onCompleteCall}/>

                                    {user.role == 'DOCTOR' && calling.play && <div className="call-preloader">
                                        <div className="call-preloader-text animate-scale-circle animate-ellipsis">
                                            <FormattedMessage id="calling"/>
                                        </div>
                                    </div>}

                                    <div className="video-block__controls">
                                        {this.state.isMicrophone &&
                                        <Button circle={true} icon="player-microphone" color="op-gray"
                                                onClick={this.onMicrophoneDisabled}/>}
                                        {!this.state.isMicrophone &&
                                        <Button circle={true} icon="player-microphone-off" color="op-gray"
                                                onClick={this.onMicrophoneEnabled}/>}
                                        <Button circle={true} icon="call-finish" color="red"
                                                onClick={this.onCompleteCall}/>
                                        {this.state.isVideo && <Button circle={true} icon="player-video" color="op-gray"
                                                                       onClick={this.onVideoDisabled}/>}
                                        {!this.state.isVideo &&
                                        <Button circle={true} icon="player-video-off" color="op-gray"
                                                onClick={this.onVideoEnabled}/>}
                                    </div>
                                </div>
                                <div className="video-person-name">{user.firstName + ' ' + user.lastName}</div>
                                {user.role == 'PATIENT' && booking && booking.id &&
                                <div className="video-menu">
                                    <Dropdown button={dropdownProfile} noArrow="true">
                                        <DropdownItem icon="description" to={'#'}
                                                      onClick={() => this.props.onPatientBookingDetail(booking.id || null, 'description')}>
                                            <FormattedMessage id="core.tab.description.label"/>
                                        </DropdownItem>
                                    </Dropdown>
                                </div>}
                                {user.role == 'DOCTOR' &&
                                <div className="video-menu">
                                    <Dropdown button={dropdownProfile}>
                                        {booking && booking.id && <EmptyTag>
                                            <DropdownItem icon="description" to={'#'}
                                                          onClick={() => this.props.onPatientBookingDetail(booking.id || null, 'description')}>
                                                <FormattedMessage id="core.tab.description.label"/>
                                            </DropdownItem>
                                            <DropdownItem icon="prescription" to={'#'}
                                                          onClick={() => this.props.onPatientBookingDetail(booking.id || null, 'prescription')}>
                                                <FormattedMessage id="core.tab.prescription.label"/>
                                            </DropdownItem>
                                            <DropdownItem icon="advice" to={'#'}
                                                          onClick={() => this.props.onPatientBookingDetail(booking.id || null, 'advices')}>
                                                <FormattedMessage
                                                    id="core.tab.medical-advice.label"/></DropdownItem>
                                            <DropdownItem icon="notes" to={'#'}
                                                          onClick={() => this.props.onPatientBookingDetail(booking.id || null, 'note')}>
                                                <FormattedMessage id="core.tab.note.label"/>
                                            </DropdownItem>
                                        </EmptyTag>}
                                        <DropdownItem icon="medical-record" to={'#'}
                                                      onClick={() => this.props.onPatientMedicalRecord({patient: {id: patientId}})}>
                                            <FormattedMessage id="booking.detail.chat.medical-record"/>
                                        </DropdownItem>
                                        <DropdownItem icon="postcard" to={'#'}
                                                      onClick={() => this.props.onBookAnAppointment({
                                                          patient: {id: patientId},
                                                          doctor: user
                                                      })}>
                                            <FormattedMessage id="booking.detail.chat.book-an-appointment"/>
                                        </DropdownItem>
                                    </Dropdown>
                                </div>}

                            </div>

                            {streams.map(stream => {
                                    let name;
                                    try {
                                        name = JSON.parse(stream.name);
                                    } catch (e) {
                                        name = null;
                                    }
                                    return (
                                        <div className="col-video col-md-6" key={stream.id}>
                                            <div className='video-block video-block-doctor'>
                                                <Subscriber
                                                    onRedirectTo={this.onRedirectTo}
                                                    onCompleteCall={this.onCompleteCall}
                                                    session={this.sessionHelper.session}
                                                    stream={stream}
                                                    streams={streams}/>
                                            </div>
                                            {name &&
                                            <div className="video-person-name">{name.firstName} {name.lastName}</div>}
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <CallParticipants onCompleteCall={this.onCompleteCall}
                                          callIncoming={callIncoming}
                                          streams={this.state.streams}/>
                    </div>
                </div>
                {
                    isModal(modal, 'bookAnAppointment') && <BookAnAppointment/>
                }
                {
                    isModal(modal, 'bookingDetail') && <BookingDetail/>
                }
            </div>
        )
    }
}


const mapStateToProps = store => {
    return {
        call: store.call,
        auth: store.auth,
        modal: store.modal,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallStreamsUpdate: (streams) => dispatch(callStreamsUpdateAction(streams)),
        onCallDestroy: () => dispatch(callDestroyAction()),
        onCallCallingInit: () => dispatch(callCallingInitAction()),
        onCallCallingPause: () => dispatch(callCallingPauseAction()),
        onBookAnAppointment: (data) => dispatch(modalShow('bookAnAppointment', data)),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onPatientMedicalRecord: (data) => dispatch(modalShow('patientMedicalRecord', data)),
        onPatientBookingDetail: (bookingId, tabName) => dispatch(modalShow('bookingDetail', {
            bookingId: bookingId,
            tabName: tabName
        })),
        onCallSessionInitAction: (sessionHelper) => dispatch(callSessionInitAction(sessionHelper)),
        onCompleteCall: (sessionId) => dispatch(completeCall(sessionId)),
        onPreloaderPageShow: () => dispatch(preloaderPageShowAction()),
        onPreloaderPageHide: () => dispatch(preloaderPageHideAction()),
        onStartRecord: (sessionId, callType) => dispatch(startRecord(sessionId, callType))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Call);