import React, {Component} from 'react';
import classnames from "classnames";

import {connect} from "react-redux";
import {fetchProfilePhoto} from "../../services/userService";
import {fetchDoctorPhoto} from "../../services/doctorService";

class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {base64: null}
    }

    componentDidMount() {
        const {userId = null} = this.props;
        this.onFetchProfilePhoto(userId);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const {auth: curAuth, userId: curUserId} = this.props;
        const {auth: prevAuth, userId: prevUserId} = prevProps;
        if (curUserId && prevUserId &&
            curUserId == prevUserId &&
            curAuth.user && prevAuth.user &&
            curAuth.user != prevAuth.user &&
            curUserId == curAuth.user.id) {
            this.onFetchProfilePhoto(curAuth.user.id);
        }
    }

    onFetchProfilePhoto(userId) {
        const {auth, doctor = false} = this.props;
        (async () => {
            if (userId) {
                const base64 = doctor && !auth.isAuthenticated ?
                    await fetchDoctorPhoto(userId) : await fetchProfilePhoto(userId);
                this.setState({...this.state, base64: base64});
            }
        })();
    }

    render() {
        const {size = 0, width = 0, doctor = false} = this.props;
        const avatarClassNames = classnames('avatar-item', {
            'avatar-size-80': (size === 80),
            'avatar-size-120': (size === 120),
            'avatar-size-220': (size === 220),
            'avatar-width-58': (width === 58),
            'avatar-item__photo-doctor': doctor === true
        });

        const spanClassNames = classnames('avatar-item__photo', {

        });
        return (
            <div className={avatarClassNames}>
                <span className={spanClassNames}>
                    {this.state.base64 && <img src={"data:;base64," + this.state.base64} className="avatar-item-img"/>}
                </span>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Avatar);