import React, {Component} from 'react';
import {connect} from "react-redux";
import {fetchBookingCalls, getUrlStreamingCall} from "../../../../services/bookingCallService";
import {Button} from "../../../../../components/elements/button";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import {isModal} from "../../../../../utils/app";
import VideoPlay from "../../../../systems/videoPlay";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../../actions/preloaderAction";
import {modalShow} from "../../../../services/system/modalService";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import * as filesize from "filesize";

class BookingDetailRecording extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.playVideo = this.playVideo.bind(this);
    }

    componentDidMount() {
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        const {form: {data}} = this.props;
        const {booking = {}} = data;
        this.props.onFetchCalls(booking, pageNumber);
    }

    playVideo(callId) {
        const {form: {data}} = this.props;
        const {booking = {}} = data;
        (async () => {
            this.props.onPageShowPreloader();
            const src = await getUrlStreamingCall(booking, callId);
            this.props.onModalShow("videoPlay", {src: src});
            this.props.onPageHidePreloader();
        })();
    }

    render() {
        const {list: {data: listData}, modal} = this.props;
        const {bookingCalls = []} = listData;

        return (
            <div className="row content-row">
                <div className="col-md-6">
                    <div className="white-block">
                        <div className="row form-elements form-bottom-bordered">
                            {bookingCalls.map(({id, hasVideo, hasAudio, createdAt, recordStatus, recordSize}) => {
                                return (
                                    <div className="col-12" key={id}>
                                        <div className="row simple-row align-items-center">
                                            <div className="col-auto padding-right-0">
                                                <Button
                                                    disabled={recordStatus != 'AVAILABLE'}
                                                    onClick={() => this.playVideo(id)}
                                                        icon="play-white" circle={true} circleMiddleSize={true}/>
                                            </div>
                                            <div className="col">
                                                <div>
                                                    {recordStatus == null && <FormattedMessage id="recording.pending"/>}
                                                    {recordStatus != null && recordStatus != 'AVAILABLE' && <FormattedMessage id="recording.not.available"/>}
                                                    {recordStatus == 'AVAILABLE' && <EmptyTag>
                                                        {hasAudio && !hasVideo && <FormattedMessage id="audio"/>}
                                                        {hasVideo && <FormattedMessage id="video"/>}
                                                    </EmptyTag>
                                                    }
                                                </div>
                                                <div className="small-text color-gray">
                                                    {moment(createdAt).format("lll")}
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                {recordStatus == 'AVAILABLE' &&
                                                <div className="small-text color-gray">
                                                    {filesize(recordSize, {separator: "."})}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {bookingCalls.length === 0 &&
                            <div className="col-12" ><FormattedMessage id="list.empty"/></div>}
                        </div>
                    </div>
                </div>
                {isModal(modal, 'videoPlay') && <VideoPlay/>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onFetchCalls: (booking, pageNumber) => dispatch(fetchBookingCalls(booking, pageNumber))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailRecording);
