import * as classnames from "classnames";
import {EmptyTag} from "../emptyTag";
import {Label} from "../label";
import {InputError} from "../error";
import React from "react";

export const Password = (prop) => {
    const {icon, error = null, placeholder = null, ...rest} = prop;
    const blockClass = classnames('text-block', {
        'block-error': (error !== null)
    });
    return (
        <EmptyTag>
            <div className={blockClass}>
                <input placeholder={placeholder} type="password" {...rest} autoComplete="new-password"/>
                <Label>{placeholder}</Label>
            </div>
            {error !== null && <InputError>{error}</InputError>}
        </EmptyTag>
    );
}