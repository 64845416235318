import React, {Component} from 'react';
import {connect} from "react-redux";
import ConsultationComingSoon from "./ConsultationComingSoon";
import Treatments from "./Treatments";
import UpcomingBookings from "./UpcomingBookings";
import Prescriptions from "./Prescriptions";
import NewTimeAppointment from "./NewTimeAppointment";
import NotPayedBookings from "./NotPayedBookings";
import FamilyDetachRequests from "./FamilyDetachRequests";

class PatientHome extends Component {
    render() {
        return (
            <div className="row content-row">
                <ConsultationComingSoon/>
                <NewTimeAppointment/>
                <NotPayedBookings />
                <FamilyDetachRequests/>

                <div className="col-md-4">
                    <Treatments/>
                </div>

                <div className="col-md-8">
                    <UpcomingBookings/>
                    <Prescriptions/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientHome);
