import React from 'react';

export const Paragraph = (props) => {
    const {bold = false} = props;
    return (
        <div className="text-block">
            <div className="input">
                {bold && <span className="bold">{props.children}</span>}
                {!bold && props.children}
            </div>
        </div>
    );
};

export const WhiteBlock = (props) => {
    return (
        <div className="white-block">{props.children}</div>
    );
};