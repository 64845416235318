import {arrayRemoveNullElements, prepareFilter} from "../../utils/app";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";
import {modalHide} from "./system/modalService";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {formInit} from "./system/formService";

export const fetchExtraConsultations = (pageNumber, listName = "extraConsultations") => {
    return (dispatch, state) => {
        const sort = prepareFilter(state().list.sorts[listName] || {});
        const filter = prepareFilter(state().list.filters[listName] || {});
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/extraConsultations/my?pageNumber=" + pageNumber + "&search=" + filter + "&sort=" + sort)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction(listName, content, {...rest}));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const approvedExtraConsultation = (id, callback = false) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("confirm"));
        return privateApi.post("/v1/extraConsultations/" + id + "/approved")
            .then(response => {
                if (callback) {
                    callback()
                } else {
                    dispatch(fetchExtraConsultations(1));
                }
            }).catch(error => {
                if (error.response) {
                    dispatch(noticeShowAction(
                        <FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const canceledExtraConsultation = (id, callback = false) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("confirm"));
        return privateApi.post("/v1/extraConsultations/" + id + "/canceled")
            .then(response => {
                if (callback) {
                    callback()
                } else {
                    dispatch(fetchExtraConsultations(1));
                }
            }).catch(error => {
                if (error.response) {
                    dispatch(noticeShowAction(
                        <FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const sendRequestExtraConsultation = (consultationId, users = []) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("addParticipantExtraConsultation"));
        const userIds = users.map(i => i.id);
        const data = {
            consultationId: consultationId,
            userIds: arrayRemoveNullElements(userIds)
        };
        return privateApi.post("/v1/extraConsultations/sendRequest", data)
            .then(response => {
                dispatch(getExtraConsultationsByConsultation(consultationId))
            }).catch(error => {
                dispatch(noticeShowAction(
                    <FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const getMyExtraConsultation = (consultationId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/consultations/" + consultationId + "/myExtraConsultation")
            .then(response => {
                dispatch(formInit("extraConsultation", response.data));
            }).catch(error => {
                dispatch(noticeShowAction(
                    <FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const getExtraConsultationsByConsultation = (consultationId, listName = "extraConsultations") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/consultations/" + consultationId + "/extraConsultations")
            .then(response => {
                dispatch(listFetchAction(listName, response.data, null));
            }).catch(error => {
                dispatch(noticeShowAction(
                    <FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};