import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";
import qs from "qs";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {modalHide} from "./system/modalService";

export const fetchAccountCalendars = (listName = "accountCalendars") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/calendars")
            .then(response => {
                const {data} = response;
                return dispatch(listFetchAction(listName, data, null));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const removeAccountCalendar = (id) => {
    return (dispatch) => {
        dispatch(modalHide('confirm'));
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/v1/calendars/" + id)
            .then(response => {
                return dispatch(fetchAccountCalendars("accountCalendars"));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const createCalendarAuthorizationUrl = (type, ownProps) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/calendars/" + type + "/createAuthorizationUrl")
            .then(response => {
                window.location = response.data;
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const calendarAuthorization = (code, type, ownProps) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/calendars/" + type + "/authorization", qs.stringify({code: code}))
            .then(response => {
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
                dispatch(fetchAccountCalendars("accountCalendars"));
                ownProps.history.push("/doctor/schedule/settings");
            });
    }
};