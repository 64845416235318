import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Header} from "../../../../components/elements/header";
import {Input} from "../../../../components/elements/input";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {Option, Select} from "../../../../components/elements/select";
import {formChangeField, formSimpleChange} from "../../../services/system/formService";
import {
    arrayRemoveNullElements,
    getFileExt,
    getSexInput,
    getSexsInput,
    isModal,
    stringToInt
} from "../../../../utils/app";
import {fetchCountries, fetchLanguages, fetchSystemSettings} from "../../../services/commonService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import {Label} from "../../../../components/elements/label";
import Birthday from "../../../systems/birthday";
import {Button, ButtonIcon, ButtonRemove} from "../../../../components/elements/button";
import {FormattedMessage} from "react-intl";
import {
    downloadSignature,
    fetchProfileGeneral,
    updateDoctorProfileGeneral
} from "../../../services/doctorProfileService";

import {Paragraph} from "../../../../components/elements/paragraph";
import DoctorLocation from "./DoctorLocation";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {noticeShowAction} from "../../../../actions/noticeAction";
import Dropdown, {DropdownWrapperItem} from "../../../systems/dropdown";
import {IconItem} from "../../../../components/elements/icon";
import {File} from "../../../../components/elements/file";
import {uploadFileChunk, uploadPhoto} from "../../../services/fileService";
import {AvatarEdit} from "../../../../components/collections/avatar";
import {dropdownNotVisibleAction, dropdownVisibleAction} from "../../../../actions/dropdownAction";
import ChooseSpecialization from "../../users/ChooseSpecialization";
import {Checkbox} from "../../../../components/elements/checkbox";
import * as filesize from "filesize";
import uuid from "react-uuid";

class DoctorProfileGeneral extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectSex = this.onSelectSex.bind(this);
        this.onChangeInteger = this.onChangeInteger.bind(this);
        this.onChangeBirthday = this.onChangeBirthday.bind(this);
        this.onUploadPhoto = this.onUploadPhoto.bind(this);
        this.onRemovePhoto = this.onRemovePhoto.bind(this);
        this.onChooseSpecializations = this.onChooseSpecializations.bind(this);
        this.onRemoveDoctorLocation = this.onRemoveDoctorLocation.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onRemoveSignature = this.onRemoveSignature.bind(this);
        this.onSelectCountry = this.onSelectCountry.bind(this);
        this.state = {isCanDownloadSignature: true}
    }

    componentDidMount() {
        const {profileUserId} = this.props;
        this.props.onInitForm("profile", {});
        this.props.onFetchDoctorProfile(profileUserId);
        this.props.onfetchSystemSettings();
        this.props.onFetchLanguages();
        this.props.onFetchCountries();
    }

    onRemoveSignature() {
        this.props.onModalHide("confirm");
        this.props.onFormSimpleChange("profile", 'doctor.signature', null);
    }

    onSelectCountry(country) {
        if (country) {
            this.props.onFormSimpleChangeField("profile", "countryIso", country.iso);
        } else {
            this.props.onFormSimpleChangeField("profile", "countryIso", null);
        }
    }

    onChangeFile(event) {
        const files = event.target.files;
        const onCallback = (response) => {
            this.props.onFormSimpleChange("profile", 'doctor.signature', response.data);
            this.setState({...this.state, isCanDownloadSignature : false})
        };
        this.props.onUploadFile(files, onCallback)
    }

    onChooseSpecializations(specializations) {
        this.props.onChangeDropdown("doctor.specializations", specializations);
    }

    onRemovePhoto = () => {
        this.props.onFormSimpleChange("profile", "photo", null);
    }

    onUploadPhoto(event) {
        this.props.onDropdownNotVisibleAction();
        this.props.onUploadPhoto("profile", event);
    }

    onChangeInteger(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChange("profile", target.name, stringToInt(value));
    }

    onChange(event) {
        this.props.onFormChangeField('profile', event);
    }

    onChangeBirthday(day, month, year) {
        const {form} = this.props;
        const profile = form.data.profile || {};
        if (day != null && month != null && year != null) {
            const birthday = moment().set({'date': day, 'month': month, 'year': year});
            if (moment([year, month, day]).isValid()) {
                this.props.onFormSimpleChange("profile", "birthday", birthday.format("YYYY-MM-DD"));

                const yearBirth = birthday.format("YYYY");
                const practiceSince = (profile.doctor && profile.doctor.practiceSince) || null;
                if (practiceSince && yearBirth && yearBirth > practiceSince) {
                    this.props.onFormSimpleChange("profile", "doctor.practiceSince", null);
                }

            } else {
                this.props.onFormSimpleChange("profile", "birthday", null);
                this.props.onFormSimpleChange("profile", "doctor.practiceSince", null);
            }
        } else {
            this.props.onFormSimpleChange("profile", "birthday", null);
            this.props.onFormSimpleChange("profile", "doctor.practiceSince", null);
        }
    }

    onSelectSex(id) {
        this.props.onChangeDropdown("sex", id);
    }

    onChangeDoctorLocation = (address = {}) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                const geolocation = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
                this.props.onModalShow("doctorLocation", {geolocation: geolocation, address: address});
            }, error => {
                this.props.onShowNotice(<FormattedMessage id="not.determine.current.location"/>);
            });
        }
    }

    onRemoveDoctorLocation(address) {
        const {form} = this.props;
        const {doctor: {addresses = []}} = form.data.profile || {};
        const index = addresses.findIndex(i => i.text == address.text);

        if (index !== -1) {
            delete addresses[index];
        }
        this.props.onFormSimpleChange("profile", "doctor.addresses", arrayRemoveNullElements(addresses));
    }

    render() {
        const {form, modal, profileUserId, auth: {user}, list: {data: listData}} = this.props;
        const errors = form.errors.profile;
        const {languages = [], countries = []} = listData;
        const disabled = form.isSend.profile || false;
        const profile = form.data.profile || {};

        const chooseSpecialisations = profile && profile.doctor && profile.doctor.specializations || [];
        const currentYear = moment().format("YYYY");
        let practiceSinceOptions = [];
        if (profile && profile.birthday && moment(profile.birthday).isValid()) {
            const yearStart = moment(profile.birthday).format("YYYY");
            for (let year = yearStart; year <= currentYear; year++) practiceSinceOptions.push(year);
        }

        let countryName = ""
        if (profile && profile['countryIso']) {
            const countryIndex = countries.findIndex(i => i.iso == profile['countryIso'])
            if (countryIndex > -1) {
                countryName = countries[countryIndex].name
            }
        }

        const practiceSince = (profile.doctor && profile.doctor.practiceSince) || null;
        const speakLanguages = profile.speakLanguages || [];
        const dropdownButton = <ButtonIcon icon="photo-menu" iconSize={40}/>
        const dropdownPhoto = <div className="avatar-dropdown">
            <Dropdown button={dropdownButton}>
                <DropdownWrapperItem onClick={() => this.props.onDropdownVisibleAction()}>
                    <File icon={"camera-plus"} name="photo" onChange={this.onUploadPhoto}>
                        <FormattedMessage id="upload"/>
                    </File>
                </DropdownWrapperItem>
                <DropdownWrapperItem>
                    <ButtonIcon onClick={this.onRemovePhoto}>
                        <IconItem name="trash-black">
                            <FormattedMessage id="core.delete.label"/>
                        </IconItem>
                    </ButtonIcon>
                </DropdownWrapperItem>
            </Dropdown>
        </div>;
        return (
            <EmptyTag>
                <div className="row content-row">
                    {user.role === 'ADMIN' &&
                        <GridCol count="1">
                            <div className="white-block">
                                <div className="row">
                                    <div className="col">
                                        <FormattedMessage id="core.emergency.call.label"/>
                                    </div>
                                    <div className="col-auto">
                                        <Checkbox
                                            name='doctor.isCanAcceptEmergencyCall'
                                            onChange={this.onChange}
                                            isChecked={(profile && profile.doctor && profile.doctor.isCanAcceptEmergencyCall) || false}
                                            toggle={true} textSize={'small'} textColor={'gray'}></Checkbox>
                                    </div>
                                </div>
                            </div>
                        </GridCol>}

                    <GridCol count="1">
                        <div className="white-block">
                            <div className="row form-elements">
                                <div className="col-12 align-center">
                                    <AvatarEdit doctor bytes={profile && profile.photo && profile.photo.bytes}>
                                        {dropdownPhoto}
                                    </AvatarEdit>
                                </div>

                                <GridCol count="2">
                                    <Input name="firstName"
                                           required={true}
                                           icon="user"
                                           value={profile.firstName || ''}
                                           error={(errors && errors.firstName) || null}
                                           placeholder={<FormattedMessage id="core.first.name.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Input name="lastName"
                                           required={true}
                                           icon="user"
                                           value={profile.lastName || ''}
                                           error={(errors && errors.lastName) || null}
                                           placeholder={<FormattedMessage id="core.last.name.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                                <GridCol count="2">
                                    <FormattedMessage id={getSexInput(profile['sex']) || 'undefined'}>
                                        {optionNameSelected =>
                                            <Select name="sex"
                                                    error={(errors && errors.sex) || null}
                                                    disabled={disabled}
                                                    label={<FormattedMessage id="core.gender.label"/>}
                                                    optionNameSelected={optionNameSelected}>
                                                {
                                                    getSexsInput().map(key =>
                                                        <Option
                                                            active={profile && profile['sex'] && profile['sex'] === key}
                                                            key={key}
                                                            onClick={() => this.onSelectSex(key)}>
                                                            {<FormattedMessage id={getSexInput(key)}/>}
                                                        </Option>
                                                    )
                                                }
                                            </Select>}
                                    </FormattedMessage>
                                </GridCol>
                                <GridCol count='2'>
                                    <Select name="countryIso"
                                            label={<FormattedMessage id="country"/>}
                                            optionNameSelected={countryName || 'undefined'}>
                                        {countries.map(i => {
                                            return (
                                                <Option
                                                    active={profile && profile['countryIso'] && profile['countryIso'] === i.id}
                                                    key={'country-' + i.id}
                                                    onClick={() => this.onSelectCountry(i)}>
                                                    {i.name}
                                                </Option>
                                            )
                                        })}
                                    </Select>
                                </GridCol>
                                <GridCol count="2">
                                    <Label required={true}>{<FormattedMessage id="core.birthday.label"/>}</Label>
                                    <Birthday error={(errors && errors.birthday) || null}
                                              value={profile.birthday && moment(profile.birthday).format("DD.MM.YYYY") || null}
                                              onChangeBirthday={this.onChangeBirthday}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Input name="phone"
                                           required={true}
                                           value={profile.phone || ''}
                                           error={(errors && errors.phone) || null}
                                           placeholder={<FormattedMessage id="mobile.phone"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Input name="officePhone"
                                           value={profile.officePhone || ''}
                                           error={(errors && errors.officePhone) || null}
                                           placeholder={<FormattedMessage id="office.phone"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Select
                                        required={false}
                                        label={<FormattedMessage id="practice.since"/>}
                                        name="doctor.practiceSince"
                                        error={(errors && errors['doctor.practiceSince']) || null}
                                        disabled={disabled}
                                        optionNameSelected={(practiceSince && practiceSinceOptions.find(i => i == practiceSince)) || ''}>
                                        {
                                            practiceSinceOptions.map(year =>
                                                <Option
                                                    active={practiceSince && practiceSince === year}
                                                    key={year}
                                                    onClick={() => this.props.onChangeDropdown("doctor.practiceSince", year)}>
                                                    {year}
                                                </Option>
                                            )
                                        }
                                    </Select>
                                </GridCol>
                                <GridCol count="2">
                                    <Input name="doctor.licenseNumber"
                                           required={true}
                                           value={(profile && profile.doctor && profile.doctor.licenseNumber) || ''}
                                           error={(errors && errors['doctor.licenseNumber']) || null}
                                           placeholder={<FormattedMessage id="core.license.number.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Select
                                        multiple={true}
                                        required={false}
                                        label={<FormattedMessage id="doctor.speak.languages"/>}
                                        name="speakLanguages"
                                        error={(errors && errors['speakLanguages']) || null}
                                        disabled={disabled}
                                        onRemoveOption={(v) => this.props.onChangeMultipleDropdown("speakLanguages", v)}
                                        optionsSelected={speakLanguages}>
                                        {
                                            languages.map(language =>
                                                <Option
                                                    active={speakLanguages.findIndex(i => i.id == language.id) !== -1}
                                                    multiple={true}
                                                    key={language.id}
                                                    onClick={() => this.props.onChangeMultipleDropdown("speakLanguages", language)}>
                                                    {language.name}
                                                </Option>
                                            )
                                        }
                                    </Select>
                                </GridCol>
                                <ChooseSpecialization
                                    required={true}
                                    error={errors && (errors["doctor.specializations"] || errors['doctor']) ?
                                        errors["doctor.specializations"] || errors['doctor'] : null}
                                    choosed={chooseSpecialisations || []}
                                    onChoose={this.onChooseSpecializations}/>
                            </div>
                        </div>
                    </GridCol>
                    <GridCol count="1">
                        <div className="white-block">
                            <Header as="h3"><FormattedMessage id="my.location"/></Header>

                            <div className="row form-elements">
                                {profile.doctor && profile.doctor.addresses && profile.doctor.addresses.map(address => {

                                    return (
                                        <div className="col-12" key={address.text}>
                                            <div className="row align-items-center">
                                                <div className="col">
                                                    <Paragraph>
                                                        <div className="color-blue">{address.name}</div>
                                                        <span className="color-gray"><FormattedMessage
                                                            id="address"/>:&nbsp;</span>
                                                        {address.text}
                                                    </Paragraph>
                                                </div>
                                                <div className="col-auto">
                                                    <ButtonIcon iconSize={24}
                                                                color="blue"
                                                                icon="edit-blue"
                                                                onClick={() => this.onChangeDoctorLocation(address)}/>

                                                    <ButtonRemove
                                                        onClick={() => this.onRemoveDoctorLocation(address)}/>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="col-12">
                                    <ButtonIcon
                                                color="blue"
                                                onClick={this.onChangeDoctorLocation}>
                                        <IconItem name="plus-bordered">
                                            <FormattedMessage id="add.location"/></IconItem>
                                    </ButtonIcon>
                                </div>
                            </div>
                        </div>
                    </GridCol>

                    <GridCol count="1">
                        <div className="white-block">
                            <Header as="h3"><FormattedMessage id="doctor.profile.header.upload.signature"/></Header>
                            {(!profile.doctor || !profile.doctor.signature) &&
                            <p className="small-text">
                                <FormattedMessage id="doctor.profile.header.upload.signature.description"/>
                            </p>}

                            <div className="row form-elements">
                                {(!profile.doctor || !profile.doctor.signature) &&
                                <GridCol count="1">
                                    <File name="file"
                                          onChange={(event) => this.onChangeFile(event)}><FormattedMessage
                                        id="core.btn.add.label"/></File>
                                </GridCol>}
                                {profile.doctor && profile.doctor.signature &&
                                <div className="col-md-6">
                                    <div className="file-preview-line">
                                        <div className="file-preview-name">
                                            <EmptyTag>
                                                {this.state.isCanDownloadSignature &&
                                                    <a href="#"
                                                       onClick={() => this.props.onDownloadSignature(uuid() + "." + getFileExt(profile.doctor.signature.name))}>
                                                        <FormattedMessage id="signature"/></a>}
                                                {!this.state.isCanDownloadSignature && <FormattedMessage id="signature"/>}
                                            </EmptyTag>
                                        </div>
                                        <div className="file-preview-info">
                                            {getFileExt(profile.doctor.signature.name)}, {filesize(profile.doctor.signature.size, {separator: "."})}
                                        </div>
                                        <button type="button"
                                                className="btn-delete"
                                                onClick={() => this.props.onConfirm({
                                                    text: <FormattedMessage id="confirm.default.question.text"/>,
                                                    onProceed: (() => this.onRemoveSignature())
                                                })}>×
                                        </button>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </GridCol>


                </div>
                <div className="form-submit margin-top-15">
                    <Button
                        disabled={disabled} preloader={disabled === true}
                        onClick={() => this.props.onUpdateDoctorProfileGeneral(
                            profileUserId,
                            profile,
                            () => this.setState({...this.state, isCanDownloadSignature : true}))}><FormattedMessage
                        id="core.btn.save.label"/></Button>
                </div>

                {isModal(modal, 'doctorLocation') && <DoctorLocation/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onFetchCountries: () => dispatch(fetchCountries()),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onUploadFile: (files, onCallback) => dispatch(uploadFileChunk(files, onCallback)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onDropdownNotVisibleAction: () => dispatch(dropdownNotVisibleAction()),
        onDropdownVisibleAction: () => dispatch(dropdownVisibleAction()),
        onUploadPhoto: (formName, event) => dispatch(uploadPhoto(formName, event)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onFetchDoctorProfile: (id) => dispatch(fetchProfileGeneral(id)),
        onUpdateDoctorProfileGeneral: (id, data, callback) => dispatch(updateDoctorProfileGeneral(id, data, callback)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('profile', fieldName, fieldValue)),
        onChangeMultipleDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('profile', fieldName, fieldValue, null, true)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onShowNotice: (textMsg) => dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, textMsg)),
        onFetchLanguages: () => dispatch(fetchLanguages()),
        onDownloadSignature: (fileName) => dispatch(downloadSignature(fileName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorProfileGeneral);
