import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {getCalendarLocale} from "../../../../utils/api";
import Calendar from "react-calendar/dist/entry.nostyle";
import moment from "moment";
import {listInitAction} from "../../../../actions/listAction";

class ScheduleViewOnYear extends Component {
    componentWillUnmount() {
        this.props.onInitList("doctorSchedules");
    }

    render() {

        const {list: {data: listData}, start} = this.props;
        const {appointmentDays = []} = listData;
        const year = start.format('YYYY')
        const monthItems = [...Array(12).keys()];
        const tileClassNameFullStates = ({activeStartDate, date, view}) =>
            view === 'month' && appointmentDays.findIndex(value => value === moment(date).format("YYYY-MM-DD")) !== -1 ? "color-orange" : "color-black";

        return (
            <div className="calendar-grid">
                <div className="calendar-grid-body">
                    {monthItems.map(month => {
                        return(
                            <div className="c-g-cell" key={'month-' + month}>
                                <Calendar
                                    activeStartDate={new Date(year, month, 1)}
                                    calendarType={"US"}
                                    locale={getCalendarLocale()}
                                    tileClassName={tileClassNameFullStates}
                                    view="month"
                                    name='calendar'
                                    minDetail='month'
                                    prev2Label={null}
                                    next2Label={null}
                                    onClickDay={(value) => this.props.onLoadByDate(moment(value))}
                                    showNeighboringMonth={false}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitList: (listName) => dispatch(listInitAction(listName)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleViewOnYear));
