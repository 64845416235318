import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ConfigureDoctorSchedule from "../../doctor/scheduleConfigure/ConfigureDoctorSchedule";
import {fetchAccount} from "../../../services/userService";

class DoctorSchedule extends Component {
    componentDidMount() {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};
        this.props.onFetchAccount(id, "profileAccountEmail");
    }

    render() {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};
        return (
            <ConfigureDoctorSchedule userId={id}/>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchAccount: (userId, formName) => dispatch(fetchAccount(userId, formName)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DoctorSchedule));