import React from 'react';
import classnames from "classnames";
import {Icon, IconItem} from "../icon";
import {Button} from "../button";

export const File = (prop) => {
    const {name, onChange, error = null, asButton = false, buttonIcon = false, icon = "plus"} = prop;
    const blockClass = classnames('file-control', {
        'block-error': (error !== null)
    });

    const inputfileClass = classnames('inputfile', {
        'btn-inputfile': (asButton)
    });

    return (

        <div className={blockClass}>
            <label>
                <input type="file" name={name} className={inputfileClass} onChange={onChange}/>
                {asButton && <Button icon={buttonIcon} square/>}
                {!asButton &&
                <div className="link link-icon">
                    <div className="icon-item">
                        <Icon name={icon}/>
                        <span className="icon-item__text">{prop.children}</span>
                    </div>
                </div>
                }
            </label>
        </div>
    );
};

export const FilePreviewItems = (prop) => {
    return (
        <div className="file-previews">{prop.children}</div>
    );
};

export const FilePreviewItem = (prop) => {
    const {name, ext, size, onRemove = null, onPlay = null} = prop;
    return (
        <div className="file-preview-line">
            <div className="file-preview-name">
                {name}
            </div>
            <div className="file-preview-info">{ext}, {size}</div>
            <div className="controls-top-right">
                {onPlay && <button type="button" className="btn-icon" onClick={onPlay}>
                    <IconItem name='play'/>
                </button>}
                {onRemove && <button type="button" className="btn-delete" onClick={onRemove}>×</button>}
            </div>
        </div>
    );
};
