import {
    LIST_FETCH,
    LIST_SORT,
    LIST_FILTER,
    LIST_FILTER_FIELD,
    LIST_INIT,
    LIST_INIT_DATA,
    LIST_IS_LOAD_DATA
} from "../constants/actionTypes";

export const listFetchAction = (listName, data, pagination) => ({
    type: LIST_FETCH,
    listName: listName,
    data: data,
    pagination: pagination,
});

export const listInitDataAction = (listName, data) => ({
    type: LIST_INIT_DATA,
    listName: listName,
    data: data
});

export const listInitAction = (listName) => ({
    type: LIST_INIT,
    listName: listName
});

export const listIsLoadingDataAction = (listName, isLoading) => ({
    type: LIST_IS_LOAD_DATA,
    listName: listName,
    isLoading: isLoading
});

export const listSortAction = (listName, sorts) => ({
    type: LIST_SORT,
    listName: listName,
    sorts: sorts,
});

export const listFilterAction = (listName, filters) => ({
    type: LIST_FILTER,
    listName: listName,
    filters: filters
});

export const listFilterFieldAction = (listName, fieldName, fieldValue) => ({
    type: LIST_FILTER_FIELD,
    listName: listName,
    fieldName: fieldName,
    fieldValue: fieldValue
});