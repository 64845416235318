import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import Dropdown, {DropdownItem} from "../../../../systems/dropdown";
import {Button, Buttons} from "../../../../../components/elements/button";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {modalHide, modalShow} from "../../../../services/system/modalService";
import {startChatConsultation} from "../../../../services/consultationService";
import {formInitAction} from "../../../../../actions/formAction";
import {checkMediaDevices, createConsultationCall} from "../../../../services/callService";
import {noticeShowAction} from "../../../../../actions/noticeAction";
import {Link} from "react-router-dom";

const DropDownButton = (isStarted) => <Button>
    {isStarted ? <FormattedMessage id="core.continue.consultation"/> :
        <FormattedMessage id="core.start.consultation"/>}
</Button>

class ButtonStartConsultation extends Component {
    constructor(props) {
        super(props);
        this.onStartConsultation = this.onStartConsultation.bind(this);
        this.onStartVideoConsultation = this.onStartVideoConsultation.bind(this);
    }

    onStartConsultation(booking) {
        const {history} = this.props;
        switch (booking.typeConsultation) {
            case "CHAT" :
                this.props.onStartChatConsultation(booking);
                break;
            default:
                checkMediaDevices(() => this.onStartVideoConsultation('DOCTOR_PATIENT', booking, history),
                    () => this.props.onModalHide("confirm") &&
                        this.props.onNoticeShow(<FormattedMessage id="core.call"/>, <FormattedMessage
                            id="core.media-device-no-available"/>));
        }
    }

    onStartVideoConsultation(type, booking) {
        const {history} = this.props;
        checkMediaDevices(() => this.props.onCreateConsultationCall(type, booking, history),
            () => this.props.onModalHide("confirm") &&
                this.props.onNoticeShow(<FormattedMessage id="core.call"/>, <FormattedMessage
                    id="core.media-device-no-available"/>));
    }

    render() {
        const {auth: {user}} = this.props;
        const {booking = {}, extraConsultations = []} = this.props;
        const isStartedConsultation = booking.consultation && booking.consultation.isStarted === true;
        const isBookingDoctor = booking && booking.doctor && user && booking.doctor.id == user.id;
        const {typeConsultation} = booking;
        const isMultipleCall = extraConsultations.filter(i => i.status == 'APPROVED').length > 0;
        if (isStartedConsultation && booking.typeConsultation == 'CHAT')
            return '';
        return (
            <EmptyTag>
                {isBookingDoctor && user.isEnabled && booking.status === 'APPROVED' && typeConsultation !== 'OFFLINE' &&
                    <EmptyTag>
                        {typeConsultation == 'VIDEO' && isMultipleCall &&
                            <Buttons>
                                <div className="select-block select-button">
                                    <Dropdown button={DropDownButton(isStartedConsultation)}>
                                        <DropdownItem to={'#'}
                                                      onClick={() => this.props.onConfirm({
                                                          text: isStartedConsultation ?
                                                              <FormattedMessage
                                                                  id="confirm.continue.consultation"/> :
                                                              <FormattedMessage
                                                                  id="core.confirm.start-consultation"/>,
                                                          onProceed: (() => this.onStartVideoConsultation('DOCTOR_PATIENT', booking))
                                                      })}>
                                            <FormattedMessage id="call.with.patient"/>
                                        </DropdownItem>

                                        <DropdownItem to={'#'}
                                                      onClick={() => this.props.onConfirm({
                                                          text: isStartedConsultation ?
                                                              <FormattedMessage
                                                                  id="confirm.continue.consultation"/> :
                                                              <FormattedMessage
                                                                  id="core.confirm.start-consultation"/>,
                                                          onProceed: (() => this.onStartVideoConsultation('DOCTOR_DOCTOR', booking))
                                                      })}>
                                            <FormattedMessage id="call.with.doctors"/>
                                        </DropdownItem>
                                        <DropdownItem to={'#'}
                                                      onClick={() => this.props.onConfirm({
                                                          text: isStartedConsultation ?
                                                              <FormattedMessage
                                                                  id="confirm.continue.consultation"/> :
                                                              <FormattedMessage
                                                                  id="core.confirm.start-consultation"/>,
                                                          onProceed: (() => this.onStartVideoConsultation('DOCTOR_PATIENT_DOCTOR', booking))
                                                      })}>
                                            <FormattedMessage id="call.with.all"/>
                                        </DropdownItem>
                                    </Dropdown>
                                </div>
                            </Buttons>}
                        {(typeConsultation == 'CHAT' || !isMultipleCall) && <Button
                            onClick={() => this.props.onConfirm({
                                text: isStartedConsultation ?
                                    <FormattedMessage id="confirm.continue.consultation"/> :
                                    <FormattedMessage id="core.confirm.start-consultation"/>,
                                onProceed: (() => this.onStartConsultation(booking))
                            })}>
                            {isStartedConsultation ? <FormattedMessage id="core.continue.consultation"/> :
                                <FormattedMessage id="core.start.consultation"/>}
                        </Button>}
                    </EmptyTag>
                }
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        tab: store.tab,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onCreateConsultationCall: (type, booking, history) => dispatch(createConsultationCall(type, booking, {history: history})),
        onStartChatConsultation: (booking) => dispatch(startChatConsultation(booking)),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ButtonStartConsultation);
