import React, {Component} from 'react';
import {connect} from "react-redux";

class ReportInfo extends Component {

    render() {
        const {title, info} = this.props;
        return (
            <div className="white-block padding-20">
                <label className="field-label">{title}</label>
                <div className="middle-text bold color-blue">{info}</div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportInfo);