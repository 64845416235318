import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../components/modules/modal/index";
import {modalHide} from "../../../services/system/modalService";
import {fetchPatientMedicalRecord} from "../../../services/patientService";
import MedicalRecordContent from "../../patient/medicalRecord/MedicalRecordContent";
import {FormattedMessage} from "react-intl";
import {Header} from "../../../../components/elements/header";

class PatientMedicalRecord extends Component {
    render() {
        const {onModalHide} = this.props;
        const {form: {data}} = this.props;
        const {patientMedicalRecord} = data;
        return (
            <Modal close={true} onClose={() => onModalHide('patientMedicalRecord')} width={1000}>
                <Header as="h2"><FormattedMessage id="page.medical.record.header"/></Header>
                <MedicalRecordContent patient={patientMedicalRecord.patient}/>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
        onFetchPatientMedicalRecord: (patient) => dispatch(fetchPatientMedicalRecord(patient)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientMedicalRecord);
