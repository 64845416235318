import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import * as filesize from "filesize";
import {FormattedMessage} from "react-intl";
import {GridCol} from "../../../../components/collections/grid";
import {Label} from "../../../../components/elements/label";
import {Paragraph, WhiteBlock} from "../../../../components/elements/paragraph";
import {File, FilePreviewItem, FilePreviewItems} from "../../../../components/elements/file";
import {getFileExt, getTemperatureUnitToUnitTemperature} from "../../../../utils/app";
import {noticeShowAction} from "../../../../actions/noticeAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../actions/preloaderAction";
import {
    downloadBookingFile,
    downloadHealthConditionVideo, fetchBooking, getBookingHealthConditionVideoLink,
    removeBookingFile,
    saveBookingFile
} from "../../../services/bookingService";
import {modalShow} from "../../../services/system/modalService";
import {formInitAction} from "../../../../actions/formAction";
import Tooltip from "../../../systems/tooltip";
import PaymentDetail from "./payment/PaymentDetail";
import {EmptyTag} from "../../../../components/elements/emptyTag";

class PatientBookingDetailDescription extends Component {
    constructor(props) {
        super(props);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    playHealthConditionVideo(booking) {
        (async () => {
            this.props.onPageShowPreloader();
            const src = await getBookingHealthConditionVideoLink(booking);
            this.props.onModalShow("videoPlay", {src: src});
            this.props.onPageHidePreloader();
        })();
    }

    onChangeFile(event) {
        const {form: {data}} = this.props;
        const {booking} = data;
        const files = event.target.files;
        this.props.onSaveBookingFile(booking, files);
    }

    render() {
        const {form, auth: {user}} = this.props;
        const {data: {booking = {}}} = form;
        const {
            status = "", statusDetail, symptoms = [], description, sharpPain, temperature, healthConditionVideo,
            timeOfReceipt, files, typeConsultation, doctor = {}, address, payment = null
        } = booking;
        const unitTemperature = user.unitTemperature;
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        return (
            <EmptyTag>
                <WhiteBlock>
                    <div className="row content-row">
                        <GridCol count="1">
                            <Label fixed="1"><FormattedMessage id="booking.detail.consultation.status.label"/></Label>
                            <div className="text-block">
                                <div className="input">
                                    <div className="nowrap">
                                        {status === 'REQUESTED' &&
                                            <FormattedMessage id="booking.status.requested.label"/>}
                                        {status === 'APPROVED' &&
                                            <FormattedMessage id="booking.status.approved.label"/>}
                                        {status === 'REJECTED' &&
                                            <FormattedMessage id="booking.status.rejected.label"/>}
                                        {status === 'COMPLETED' &&
                                            <FormattedMessage id="booking.status.completed.label"/>}
                                        {status === 'REJECTED' && statusDetail &&
                                            <Tooltip>Reason: {statusDetail}</Tooltip>}
                                        {status === 'REQUESTED' &&
                                            <Tooltip><FormattedMessage
                                                id="booking.status.requested.tooltip.text"/></Tooltip>}
                                    </div>
                                </div>
                            </div>
                        </GridCol>
                        <GridCol count="2">
                            <div className="row form-elements">
                                <GridCol count="1">
                                    <Label fixed={true}><FormattedMessage id="core.type.consultation.label"/></Label>
                                    <Paragraph>
                                        {typeConsultation === 'CHAT' &&
                                            <FormattedMessage id="core.type.consultation.chat.label"/>}
                                        {typeConsultation === 'VIDEO' &&
                                            <FormattedMessage id="core.type.consultation.video.label"/>}
                                        {typeConsultation === 'AUDIO' &&
                                            <FormattedMessage id="core.type.consultation.audio.label"/>}
                                        {typeConsultation === 'OFFLINE' &&
                                            <FormattedMessage id="core.type.consultation.offline.label"/>}
                                    </Paragraph>
                                </GridCol>

                                <GridCol count="1">
                                    <Label fixed={true}><FormattedMessage id="core.hcsp.or.doctor.label"/></Label>
                                    <Paragraph>
                                        <FormattedMessage id="core.doctor.info"
                                                          values={{
                                                              firstName: doctor.firstName,
                                                              lastName: doctor.lastName,
                                                              specialization: joinSpecializations
                                                          }}/></Paragraph>
                                </GridCol>
                                {address &&
                                    <GridCol count="1">
                                        <Label fixed={true}><FormattedMessage id="location"/></Label>
                                        <Paragraph>{address.text}</Paragraph>
                                    </GridCol>}

                                <GridCol count="1">
                                    <Label fixed={true}><FormattedMessage id="core.datetime.label"/></Label>
                                    <Paragraph>{moment(timeOfReceipt).format("lll")}</Paragraph>
                                </GridCol>

                                <GridCol count="1">
                                    <h3><FormattedMessage id="core.my.condition.label"/></h3>
                                </GridCol>
                                <GridCol count='1'>
                                    <Label fixed={true}><FormattedMessage id="core.video.description.label"/></Label>
                                    {booking.healthConditionVideo &&
                                        <FilePreviewItems>
                                            <FilePreviewItem
                                                onPlay={() => this.playHealthConditionVideo(booking)}
                                                name={<a href="#"
                                                         onClick={() => this.props.onDownloadHealthConditionVideo(booking)}>
                                                    {healthConditionVideo.name}</a>}
                                                size={filesize(booking.healthConditionVideo.size, {separator: "."})}
                                                ext={getFileExt(booking.healthConditionVideo.name)}/>
                                        </FilePreviewItems>
                                    }
                                </GridCol>
                                <GridCol count="1">
                                    <Label fixed={true}><FormattedMessage id="core.description.label"/></Label>
                                    <Paragraph>{description}</Paragraph>
                                </GridCol>
                                <GridCol count="1">
                                    <Label fixed={true}><FormattedMessage id="core.symptoms.label"/></Label>
                                    {symptoms &&
                                        <div className="tags-block">
                                            {symptoms.map(symptom => <span className="tag-item"
                                                                           key={symptom.id}>{symptom.commonName}</span>)}
                                        </div>
                                    }
                                </GridCol>
                                <GridCol count="1">
                                    <Label fixed={true}><FormattedMessage id="core.sharp.pain.label"/></Label>
                                    <Paragraph>{sharpPain}</Paragraph>
                                </GridCol>
                                <GridCol count="1">
                                    <Label fixed={true}>
                                        <FormattedMessage id="core.temperature.label"
                                                          values={{unit: getTemperatureUnitToUnitTemperature(unitTemperature)}}/>
                                    </Label>
                                    <Paragraph>{temperature}</Paragraph>
                                </GridCol>
                            </div>
                        </GridCol>

                        <GridCol count="2">
                            <div className="row form-elements">

                                <div className="col-12">
                                    <h3><FormattedMessage id="core.attachments.label"/></h3>
                                    <div className="row form-elements form-bottom-bordered">
                                        {files && files.map(file => {
                                            return (
                                                <GridCol count="1" key={file.id}>
                                                    <div className="text-ellipsis">
                                                        <a href="#"
                                                           onClick={() => this.props.onDownloadFile(booking, file)}>{file.name}</a>
                                                    </div>
                                                    {(booking.status === 'REQUESTED' || booking.status === 'APPROVED') &&
                                                        <button type="button"
                                                                className="btn-delete"
                                                                onClick={() => this.props.onConfirm({
                                                                    text: <FormattedMessage id="confirm.remove.file"
                                                                                            values={{fileName: file.name}}/>,
                                                                    onProceed: (() => this.props.onRemoveBookingFile(booking, file))
                                                                })}>×
                                                        </button>
                                                    }
                                                </GridCol>
                                            );
                                        })}
                                        {(!files || files.length === 0) &&
                                            <div className="col-12"><FormattedMessage
                                                id="booking.attachments.list.empty"/>
                                            </div>}
                                        {(booking.status === 'REQUESTED' || booking.status === 'APPROVED') &&
                                            <GridCol count="1">
                                                <div></div>
                                                <div className="controls-top-right">
                                                    <File name="file"
                                                          onChange={(event) => this.onChangeFile(event)}><FormattedMessage
                                                        id="core.btn.add.label"/></File>
                                                </div>
                                            </GridCol>
                                        }
                                    </div>
                                </div>
                            </div>
                        </GridCol>
                    </div>
                </WhiteBlock>
                {payment && <PaymentDetail payment={payment} booking={booking}/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onFetchBooking: (id) => dispatch(fetchBooking(id)),
        onDownloadFile: (booking, file) => dispatch(downloadBookingFile(booking, file)),
        onDownloadHealthConditionVideo: (booking) => dispatch(downloadHealthConditionVideo(booking)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onSaveBookingFile: (booking, file) => dispatch(saveBookingFile(booking, file)),
        onRemoveBookingFile: (booking, file) => dispatch(removeBookingFile(booking, file)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onNotice: (title, textMsg) => dispatch(noticeShowAction(title, textMsg))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBookingDetailDescription);
