import React from 'react';
import classnames from 'classnames';
import {Icon} from "../../elements/icon";
import {Header} from "../../elements/header";
import {Link} from "react-router-dom";

export const Widget = (props) => {
    const {title, linkTo, maxHeight = 0, minHeight = 0, marginMinus = 0, noScroll = false} = props;
    const classNames = classnames('overflow-auto', {
        'margin-minus-30': marginMinus === 30,
        'min-height-200': minHeight === 200,
        'min-height-300': minHeight === 300,
        'max-height-300': maxHeight === 300,
        'max-height-360' : maxHeight === 360,
        'max-height-400': maxHeight === 400,
        'max-height-480': maxHeight === 480,
        'no-scroll': noScroll === true
    });

    return (
        <div className="white-block">
            <Header as="h3">
                <Link to={linkTo} className="link-icon">
                    <div className="icon-item">
                        <span className="icon-item__text">{title}</span>
                        <Icon name="forward"/>
                    </div>
                </Link>
            </Header>
            <div className={classNames}>{props.children}</div>
        </div>
    );
};

export const WidgetNoResult = (props) => {
    const {icon = ""} = props;
    return (
        <div className="no-results-block">
            <p>{props.children}</p>
            {icon && <Icon name={icon} />}
        </div>
    );
}