import React, {Component} from 'react';
import {connect} from "react-redux";
import Pagination from "react-js-pagination";

import {FormattedMessage} from "react-intl";
import Rating from "@material-ui/lab/Rating";
import {
    approveDoctorReview,
    declineDoctorReview,
    fetchAllDoctorReviews
} from "../../../services/doctorReviewService";
import {modalShow} from "../../../services/system/modalService";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import Dropdown, {DropdownItem} from "../../../systems/dropdown";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Icon} from "../../../../components/elements/icon";
import {Header} from "../../../../components/elements/header";
import Search from "../../../systems/search";
import {getReviewStatus, getReviewStatuses} from "../../../../utils/app";
import {Option, Select} from "../../../../components/elements/select";
import {DatePicter} from "../../../../components/elements/datepicter";
import moment from "moment";

class DoctorReviews extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onChangeDatePeriod = this.onChangeDatePeriod.bind(this);
        this.onChangeStatus = this.onChangeStatus.bind(this);
    }

    onChangeStatus(status) {
        const value = status == 'ALL' ? null : status;
        this.props.onListFilterField("status", value);
        this.onPagination(1);
    }

    componentDidMount() {
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchAllDoctorReviews(pageNumber);
    }

    componentWillUnmount() {
        this.props.onInitList("doctorReviews");
    }

    onSearch(text) {
        this.props.onListFilterField("searchText", text);
        this.onPagination(1);
    }

    onChangeDatePeriod(objMoment, fieldName) {
        const fieldValue = moment(objMoment).isValid() ? objMoment.format("YYYY-MM-DD") : null
        this.props.onListFilterField(fieldName, fieldValue);
        this.onPagination(1);
    }

    render() {
        const {list: {data: listData, pagination: listPagination, filters: listFilters}} = this.props;
        const {doctorReviews = []} = listData;
        const {doctorReviews: doctorReviewsFilters = {}} = listFilters;
        const {number = 0, size = 0, totalElements = 0} = listPagination['doctorReviews'] || {};
        const dropDownButton = <div>
            <Icon name="menu-points"/>
        </div>;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="doctor.reviews"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                    </div>
                </div>
                <div className="row form-elements">
                    <div className="col-md-4">
                        <Search onSearch={this.onSearch} placeholder={'core.doctor.name.label'}/>
                    </div>

                    <div className="col-auto">
                        <div className="row small-form-row align-items-center filtr-form">
                            <div className="col-auto">
                                <label className="field-label margin-bottom-0"><FormattedMessage id="status"/></label>
                            </div>
                            <FormattedMessage id={getReviewStatus(doctorReviewsFilters.status) || "all.label"}>
                                {optionNameSelected =>
                                    <Select noLabel={true} height={40} width={180} bgColor={'white'}
                                            label={""}
                                            optionNameSelected={optionNameSelected}>
                                        {
                                            <Option
                                                active={"ALL" === doctorReviewsFilters.status || !doctorReviewsFilters.status}
                                                onClick={() => this.onChangeStatus("ALL")}>
                                                {<FormattedMessage id="all.label"/>}
                                            </Option>
                                        }

                                        {
                                            getReviewStatuses().map(key =>
                                                <Option
                                                    active={key === doctorReviewsFilters.status}
                                                    key={key}
                                                    onClick={() => this.onChangeStatus(key)}>
                                                    {<FormattedMessage id={getReviewStatus(key)}/>}
                                                </Option>
                                            )
                                        }
                                    </Select>
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                    <div className="col-auto">
                        <div className="row small-form-row align-items-center filtr-form">
                            <div className="col-auto">
                                <label className="field-label margin-bottom-0"><FormattedMessage
                                    id="from.label"/></label>
                            </div>
                            <div className="col-auto">
                                <DatePicter no_label={true}
                                            bg_white={true}
                                            height={40}
                                            width={180}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => this.onChangeDatePeriod(v, 'createdAtFrom')}
                                            dateFormat={'ll'}/>
                            </div>
                            <div className="col-auto">
                                <label className="field-label margin-bottom-0"><FormattedMessage id="to.label"/></label>
                            </div>
                            <div className="col-auto">
                                <DatePicter no_label={true}
                                            bg_white={true}
                                            height={40}
                                            width={180}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => this.onChangeDatePeriod(v, 'createdAtTo')}
                                            dateFormat={'ll'}/>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><FormattedMessage id="core.doctor.name.label"/></th>
                            <th><FormattedMessage id="rating"/></th>
                            <th><FormattedMessage id="review"/></th>
                            <th><FormattedMessage id="status"/></th>
                            <th><FormattedMessage id="createdAt"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {doctorReviews.map(({id, status, doctor, rating, text, createdAt}) => {
                            return (
                                <tr key={id}>
                                    <td className="td-controls">
                                        <Dropdown button={dropDownButton} noArrow="true">
                                            {(status === 'REQUESTED' || status === 'DECLINED') &&
                                            <DropdownItem to={"#"} icon="active"
                                                          onClick={() => this.props.onConfirm({
                                                              text: <FormattedMessage
                                                                  id="confirm.approved.doctor.review"/>,
                                                              onProceed: (() => this.props.onApproveDoctorReview(id))
                                                          })}><FormattedMessage id="status.approved"/></DropdownItem>}

                                            {(status === 'REQUESTED' || status === 'APPROVED') &&
                                            <DropdownItem to={"#"} icon="block"
                                                          onClick={() => this.props.onConfirm({
                                                              text: <FormattedMessage
                                                                  id="confirm.declined.doctor.review"/>,
                                                              onProceed: (() => this.props.onDeclineDoctorReview(id))
                                                          })}><FormattedMessage id="status.declined"/></DropdownItem>}
                                        </Dropdown>
                                    </td>
                                    <td>{doctor.firstName} {doctor.lastName}</td>
                                    <td>
                                        {rating && <EmptyTag>
                                            <Rating name="read-only" value={rating} readOnly/>
                                            <span>({rating})</span>
                                        </EmptyTag>}
                                    </td>
                                    <td>{text}</td>
                                    <td>
                                        {status === 'REQUESTED' && <FormattedMessage id="status.new"/>}
                                        {status === 'APPROVED' &&
                                        <span className="color-green"><FormattedMessage id="status.approved"/></span>}
                                        {status === 'DECLINED' &&
                                        <span className="color-red"><FormattedMessage id="status.declined"/></span>}
                                    </td>
                                    <td>
                                        {moment(createdAt).format("lll")}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}

                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onApproveDoctorReview: (reviewId) => dispatch(approveDoctorReview(reviewId)),
        onDeclineDoctorReview: (reviewId) => dispatch(declineDoctorReview(reviewId)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListSorts: (sorts) => dispatch(listSortAction("doctorReviews", sorts)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("doctorReviews", fieldName, fieldValue)),
        onFetchAllDoctorReviews: (pageNumber) => dispatch(fetchAllDoctorReviews(pageNumber))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorReviews);