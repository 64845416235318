import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../../components/collections/grid";
import {Tab, Tabs} from "../../../../../components/modules/tab";
import DetailAdvice from "./DetailAdvice";
import DetailPrescription from "./DetailPrescription";
import DetailDescription from "./DetailDescription";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {BackTo} from "../../../../../components/elements/backTo";
import {Header} from "../../../../../components/elements/header";
import {fetchBooking, isCanCreateReview} from "../../../../services/bookingService";
import {tabChangePanelAction, tabClearAction} from "../../../../../actions/tabAction";
import DetailHistory from "./DetailHistory";
import qs from "query-string";
import {FormattedMessage} from "react-intl";
import {Button} from "../../../../../components/elements/button";
import {modalShow} from "../../../../services/system/modalService";
import {isModal} from "../../../../../utils/app";
import NewDoctorReview from "../../catalogDoctors/NewDoctorReview";
import BookingDetailRecording from "../../../doctor/bookings/detail/BookingDetailRecording";
import {Redirect} from "react-router";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../../actions/preloaderAction";

class TreatmentDetail extends Component {
    componentDidMount() {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};

        const queryString = this.props.location.search;
        const {tabName = "description"} = qs.parse(queryString);
        this.props.onFetchBooking(id);
        this.props.onChangeTabPanel(tabName);
        this.props.onIsCanCreateReview(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match: {params: curParam}, form : {data: curFormData}, modal: curModal} = this.props;
        const {id: curId = ""} = curParam || {};
        const {booking: curBooking = {}} = curFormData;

        const {match: {params: prevParam}, form : {data: prevFormData}, modal: prevModal} = prevProps;
        const {id: prevId = ""} = prevParam || {};
        const {booking: prevBooking = {}} = prevFormData;

        const pCur = this.props.location.search;
        const {isNewDoctorReview: curIsNewDoctorReview = 'false', reload: curReload = "", tabName = "description"} = qs.parse(pCur);

        const pPrev = prevProps.location.search;
        const {reload: prevReload = ""} = qs.parse(pPrev);

        if (curId != prevId || curReload != prevReload) {
            this.props.onFetchBooking(curId, () => {

            });
            this.props.onChangeTabPanel(tabName);
            this.props.onIsCanCreateReview(curId);
        }

        if (curIsNewDoctorReview == 'true' && Object.keys(curBooking).length > 0 && curBooking.doctor && curBooking != prevBooking) {
            if (!isModal(curModal, 'newDoctorReview') && !isModal(prevModal, 'newDoctorReview')) {
                let qsParams = qs.parse(pCur);
                qsParams["isNewDoctorReview"] = false;
                this.props.onModalShow("newDoctorReview", {doctorId: curBooking.doctor.id, bookingId: curBooking.id})
                this.props.history.replace(this.props.history.location.pathname + '?' + qs.stringify(qsParams));
            }
        }
    }

    componentWillUnmount() {
        this.props.onTabClearAction("booking");
    }


    render() {
        const {tab: {data: tabData}, form: {data}, modal, auth: {user}} = this.props;
        if (user.role === 'PATIENT' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }

        const {booking: tabBooking = {}} = tabData;
        const {booking = {}, isCanCreateReview = {}} = data;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={"/patient/treatment"}/>
                    </div>

                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.treatments.detail.header"/></Header>
                    </div>

                    <div className="col-auto content-heading__controls">
                        {booking && booking.doctor &&
                        <Button
                            disabled={!isCanCreateReview.isCan}
                            onClick={() => this.props.onModalShow("newDoctorReview", {doctorId: booking.doctor.id, bookingId: booking.id})}><FormattedMessage
                            id="leave.review"/></Button>}
                    </div>
                </div>
                <div className="row content-heading">
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab onClick={() => this.props.onChangeTabPanel("description")}
                                 active={tabBooking.activeTabPanel === 'description'}><FormattedMessage id="core.tab.description.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("prescription")}
                                 active={tabBooking.activeTabPanel === 'prescription'}><FormattedMessage id="core.tab.prescription.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("advices")}
                                 active={tabBooking.activeTabPanel === 'advices'}><FormattedMessage id="core.tab.medical-advice.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("history")}
                                 active={tabBooking.activeTabPanel === 'history'}><FormattedMessage id="core.tab.history.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("recording")}
                                 active={tabBooking.activeTabPanel === 'recording'}><FormattedMessage id="recording"/></Tab>
                        </Tabs>
                    </GridCol>
                </div>
                {tabBooking.activeTabPanel === 'description' && <DetailDescription/>}
                {tabBooking.activeTabPanel === 'prescription' && <DetailPrescription/>}
                {tabBooking.activeTabPanel === 'advices' && <DetailAdvice/>}
                {tabBooking.activeTabPanel === 'history' && <DetailHistory/>}
                {tabBooking.activeTabPanel === 'recording' && <BookingDetailRecording/>}
                {isModal(modal, 'newDoctorReview') && <NewDoctorReview afterCreateFetch={false}/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth,
        tab: store.tab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onIsCanCreateReview: (bookingId) => dispatch(isCanCreateReview(bookingId)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onTabClearAction: (tabName) => dispatch(tabClearAction(tabName)),
        onFetchBooking: (id, callback = false) => dispatch(fetchBooking(id, callback)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("booking", tabPanelName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentDetail);
