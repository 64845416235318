import React, {Component} from 'react';
import {connect} from "react-redux";
import {formInitAction} from "../../../actions/formAction";
import {listInitAction} from "../../../actions/listAction";
import {getMyOpenRooms} from "../../services/chatService";
import ChatMessages from "./ChatMessages";
import ChatSendMessage from "./ChatSendMessage";
import {ButtonIcon} from "../../../components/elements/button";
import ChatHeader from "./ChatHeader";
import {withRouter} from "react-router-dom";

class ChatWidgetChosenRoom extends Component {
    constructor(props) {
        super(props);
        this.onBack = this.onBack.bind(this);
        this.onBackAndHideChats = this.onBackAndHideChats.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    onBack() {
        this.props.onInitForm("widgetChosenChatRoom", {});
    }

    onBackAndHideChats() {
        this.props.onInitForm("widgetChosenChatRoom", {});
        this.props.onHideChats();
    }

    componentWillUnmount() {
        this.props.onInitForm("widgetChosenChatRoom", {})
    }

    onResize() {
        const {form: {data: formData}, auth: {user}} = this.props;
        const {widgetChosenChatRoom = {}} = formData;
        const {consultation_chat = {}, consultation_video = {}} = widgetChosenChatRoom;

        const {booking_id: consultationChatBookingId = null} = consultation_chat || {};
        const {booking_id: consultationVideoBookingId = null} = consultation_video || {};
        const bookingId = consultationChatBookingId || consultationVideoBookingId;
        const linkBooking = user.role == 'DOCTOR' ? '/doctor/bookings/' + bookingId + '/detail?tab=chat&reload=' + Math.random() :
            '/bookings/' + bookingId + '/detail?tab=chat&reload=' + Math.random();

        this.onBackAndHideChats();
        this.props.history.push(linkBooking)
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {widgetChosenChatRoom = {}} = formData;
        const {type} = widgetChosenChatRoom;

        return (
            <div className="chat-widget-container chat-widget-chats-current chat-widget-opened">
                <div className="chat-widget">
                    <div className="chat-container">
                        <div className="chat-header">
                            <ChatHeader formNameChosenChatRoom={'widgetChosenChatRoom'}
                                        backward={<ButtonIcon icon={'arrow-left-white-bold'}
                                                              onClick={() => this.onBack()}/>}>
                                <div className="col-auto avatar-row__controls">
                                    {type != 'EMERGENCY_CALL' &&
                                        <ButtonIcon icon={'fullscreen-white-bold'} onClick={() => this.onResize()}/>}
                                    <ButtonIcon icon={'close-white-bold'} onClick={() => this.onBackAndHideChats()}/>
                                </div>
                            </ChatHeader>
                        </div>
                        <ChatMessages formNameChosenChatRoom={'widgetChosenChatRoom'}
                                      listNameChatRoomMessages={'widgetChatRoomMessages'}/>
                        <ChatSendMessage
                            formNameNewMessage={'widgetNewMessage'}
                            formNameChosenChatRoom={'widgetChosenChatRoom'}
                            listNameChatRoomMessages={'widgetChatRoomMessages'}/>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onGetMyOpenRooms: () => dispatch(getMyOpenRooms())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChatWidgetChosenRoom));