import React from 'react';
import classnames from "classnames";
import {Icon} from "../../elements/icon";
import {getPaymentStatusName} from "../../../containers/services/bookingPaymentService";

export const PaymentStatus = (props) => {
    const {statusCode} = props;
    const statusClassNames = classnames('icon-item small-text', {
        'color-red': (statusCode === 'DENIED')
    });
    return (

        <div className={statusClassNames}>
            <Icon name="money" size="16" />
            <span className="icon-item__text">{getPaymentStatusName(statusCode)}</span>
        </div>
    );
};

export const PaymentProvider = (props) => {
    const {payment} = props;
    if (!payment.paymentProviderCode) return ""
    return (
        <Icon width={74} height={37} name={'payment-' + payment.paymentProviderCode.toLowerCase()}/>
    );
};