import React from 'react';
import classnames from "classnames";
import {InputError} from "../error";
import {Label} from "../label";

export const Textarea = (prop) => {
    const {placeholderFixed = false, placeholder, error = null, ...rest} = prop;
    const blockClass = classnames('textarea-block', {
        'block-error': (error !== null)
    });
    return (
        <div className={blockClass}>
            <Label fixed={placeholderFixed}>{placeholder}</Label>
            <textarea {...rest}/>
            <div className="textarea-border"></div>
            {error !== null && <InputError>{error}</InputError>}
        </div>
    );
};