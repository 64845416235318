import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {
    approveBookingList,
    fetchScheduleByDate,
    rejectBookingList
} from "../../../services/bookingService";
import {listFilterFieldAction} from "../../../../actions/listAction";
import {modalShow} from "../../../services/system/modalService";
import ConsultationComingSoon from "./ConsultationComingSoon";
import WidgetPatientWaiting from "./WidgetPatientWaiting";
import WidgetSchedule from "./WidgetSchedule";
import WidgetDoctorsInvitations from "./WidgetDoctorsInvitations";

class DoctorHome extends Component {

    render() {
        const {list: {data}, auth: {user}} = this.props;
        const {bookings = []} = data;

        let groupByDateBookings = {};
        bookings.map(booking => {
            const date = moment(booking.timeOfReceipt).format("ll");
            if (!groupByDateBookings[date]) groupByDateBookings[date] = [];
            groupByDateBookings[date].push(booking);
        });

        return (
            <div className="row content-row">
                <div className="col-12">
                    {user.isEnabled && <ConsultationComingSoon/>}
                </div>

                <div className="col-md-6">
                    <WidgetSchedule />
                    <WidgetDoctorsInvitations/>
                </div>
                <div className="col-md-6">
                    <WidgetPatientWaiting/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchScheduleByDate: (doctor, dateOfReceipt) => dispatch(fetchScheduleByDate(doctor, dateOfReceipt)),
        onApproveBooking: (booking) => dispatch(approveBookingList(booking)),
        onRejectBooking: (booking) => dispatch(rejectBookingList(booking)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("bookings", fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorHome);
