import React from 'react';
import {connect} from "react-redux";

import {MenuItem} from "../../../components/collections/menu";
import 'react-notifications-component/dist/theme.css';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";

class PharmacyMainMenu extends React.Component {

    render() {
        return (
            <EmptyTag>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/pharmacies/todayIssue'} icon="calendar-today" activeClassName="active">
                        <FormattedMessage id="core.layout.sidebar.item.today.issue.label"/>
                    </MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/pharmacies/newPrescriptions'} icon="patients-new" activeClassName="active">
                        <FormattedMessage id="core.layout.sidebar.item.new.prescriptions.label"/>
                    </MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/pharmacies/patients'} icon="patients" activeClassName="active">
                        <FormattedMessage id="core.layout.sidebar.item.list.patients.label"/>
                    </MenuItem>
                </div>
            </EmptyTag>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyMainMenu);
