import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {Modal} from "../../../../components/modules/modal";
import {Header} from "../../../../components/elements/header";

import {modalHide} from "../../../services/system/modalService";
import {Button} from "../../../../components/elements/button";
import {Input} from "../../../../components/elements/input";

import {
    googleGeocode,
    googleMapAddMarker,
    googleReverseGeocode,
    loadGoogleMap
} from "../../../services/googleMapService";
import {noticeShowAction} from "../../../../actions/noticeAction";
import {formChangeFieldAction} from "../../../../actions/formAction";

class DoctorLocation extends Component {
    constructor(props) {
        super(props);
        const {form} = this.props;
        const {address = {}} = form.data.doctorLocation || {};
        this.onChange = this.onChange.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onChooseGeocodeResult = this.onChooseGeocodeResult.bind(this);
        this.onSave = this.onSave.bind(this);
        this.state = {map: null, geocodeResults: [], address: address, marker: null}
    }

    componentDidMount() {
        const {language: {locale}} = this.props;
        const callback = this.loadMapCallback;
        loadGoogleMap({
            language: locale,
            apiKey: window._env_.GOOGLE_API_KEY,
            callback
        });
    }

    loadMapCallback = () => {
        const {form: {data: formData}} = this.props;
        const {doctorLocation: {geolocation}} = formData;
        const {latitude = null, longitude = null} = this.state.address || {};
        let marker = this.state.marker;
        const map = new window.google.maps.Map(document.getElementById("google-map"), {
            center: {lat: geolocation.latitude, lng: geolocation.longitude},
            zoom: 8,
        });
        this.setState({...this.state, map: map});

        if (latitude && longitude) {
            marker = googleMapAddMarker({
                map: map,
                latitude: latitude,
                longitude: longitude,
                dragEnCallback: this.onDragEnCallback
            });
            this.setState({...this.state, marker: marker});
        }

        map.addListener("click", (mapsMouseEvent) => {
            const position = mapsMouseEvent.latLng.toJSON();
            if (marker != null) marker.setMap(null);
            marker = googleMapAddMarker({
                map: map,
                latitude: position.lat,
                longitude: position.lng,
                dragEnCallback: this.onDragEnCallback
            });
            this.onReverseGeocode(position.lat, position.lng);
            this.setState({...this.state, marker: marker});
        });
    };

    onGeocode = async (address) => {
        const response = await googleGeocode(address, window._env_.GOOGLE_API_KEY);
        const geocodeResults = [];
        const results = (response && response["results"]) || [];

        if (results.length == 0) {
            this.props.onShowNotice(<FormattedMessage id="address.not.found"/>);
        }

        results.map(result => {
            geocodeResults.push({
                placeId: result.place_id,
                latitude: result.geometry.location.lat,
                longitude: result.geometry.location.lng,
                address: result.formatted_address
            });
        })

        this.setState({...this.state, geocodeResults: geocodeResults});
    }

    onReverseGeocode = async (latitude, longitude) => {
        const response = await googleReverseGeocode({
            latitude: latitude,
            longitude: longitude,
            key: window._env_.GOOGLE_API_KEY
        });

        const results = (response && response["results"]) || [];
        const addressText = results.length > 0 ? results[0].formatted_address : "";
        const address = {...this.state.address, text: addressText, latitude: latitude, longitude: longitude};
        this.setState({...this.state, address: address});
    }

    onChooseGeocodeResult = (placeId) => {
        const {geocodeResults = [], map} = this.state;
        const index = geocodeResults.findIndex(i => i.placeId === placeId);

        if (index !== -1) {
            const geocodeResult = geocodeResults[index];
            let marker = this.state.marker;
            if (marker != null) marker.setMap(null);
            marker = googleMapAddMarker({
                map: map,
                latitude: geocodeResult.latitude,
                longitude: geocodeResult.longitude,
                dragEnCallback: this.onDragEnCallback
            });

            let {address = {}} = this.state;
            address = {
                ...address,
                text: geocodeResult.address,
                latitude: geocodeResult.latitude,
                longitude: geocodeResult.longitude
            };
            map.setCenter({lng: geocodeResult.longitude, lat: geocodeResult.latitude});
            this.setState({...this.state, marker: marker, address: address});
        }
    }

    onDragEnCallback = (marker) => {
        const position = marker.getPosition().toJSON();
        const address = {...this.state.address, latitude: position.lat, longitude: position.lng};
        this.setState({...this.state, marker: marker, address: address});
        this.onReverseGeocode(position.lat, position.lng);
    }

    onChangeAddress(event) {
        let {address = {}, marker, geocodeResults} = this.state;
        address = event.target.value ?
            {...address, text: event.target.value} :
            {...address, text: "", latitude: null, longitude: null};

        if (!event.target.value) {
            if (marker != null) marker.setMap(null);
            geocodeResults = [];
        }

        this.setState({...this.state, address: address, geocodeResults: geocodeResults});
    }

    onChange(event) {
        let {address = {}} = this.state;
        address = event.target.value ? {...address, name: event.target.value} : {...address, name: ""};
        this.setState({...this.state, address: address});
    }

    onSave = () => {
        const {form} = this.props;
        const {address = {}} = form.data.doctorLocation || {};
        const {doctor: {addresses = []}} = form.data.profile || {};

        const index = addresses.findIndex(i => i.text == address.text);
        if (index !== -1) {
            addresses[index] = this.state.address;
        } else {
            addresses.push({...this.state.address, id: null});
        }
        this.props.onFormSimpleChange("profile", "doctor.addresses", addresses);
        this.props.onModalHide();
    }

    render() {
        const {address, geocodeResults} = this.state;
        return (
            <Modal close={true} onClose={() => this.props.onModalHide()} width={800}>
                <Header as="h2"><FormattedMessage id="map"/></Header>

                <div className="row form-elements">
                    <div className="col-12">
                        <Input
                            value={(address && address.name) || ""}
                            onChange={this.onChange}
                            placeholder={<FormattedMessage id="address.name"/>}/>

                    </div>

                    <div className="col-12">
                        <div className="map-container">
                            <div className="map-body" id="google-map"></div>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="row unite-form-search-row">
                            <div className="col">
                                <Input
                                    value={(address && address.text) || ""}
                                    onChange={this.onChangeAddress}
                                    placeholder={<FormattedMessage id="address"/>}/>
                            </div>
                            <div className="col-auto">
                                <Button
                                    color="white-blue" disabled={!address || !address.text}
                                    icon="map" iconSize={24} onClick={() => this.onGeocode(address.text)}/>
                            </div>
                        </div>

                        {geocodeResults.length > 0 &&
                        <div className="map-search-results">
                            {geocodeResults.map(({placeId, address, latitude, longitude}) => {
                                return (
                                    <div key={placeId} className="map-search-results-item">
                                        <div className="map-search-results-item-text"
                                             onClick={() => this.onChooseGeocodeResult(placeId)}>
                                            {address}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}
                    </div>

                    <div className="col-12">
                        <div className="form-submit">
                            <Button color="white-red" onClick={() => this.props.onModalHide()}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button onClick={this.onSave}>
                                <FormattedMessage id="core.btn.save.label"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list,
        language: store.language
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onShowNotice: (textMsg) => dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, textMsg)),
        onModalHide: () => dispatch(modalHide("doctorLocation")),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorLocation);
