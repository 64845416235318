import React from 'react';
import {connect} from "react-redux";

import 'react-notifications-component/dist/theme.css';
import {FormattedMessage} from "react-intl";

import {Link} from "react-router-dom";
import {ButtonIcon} from "../../../components/elements/button";
import {isFillPatientProfile} from "../../services/patientService";
import {modalShow} from "../../services/system/modalService";
import TopMenu from "./TopMenu";
import Dropdown, {DropdownItem} from "../../systems/dropdown";
import QueueEmergencyCalls from "../../pages/call/QueueEmergencyCalls";
import LastNotifications from "../../pages/notifications/LastNotifications";
import {logoutUser} from "../../services/userService";
import Avatar from "../../systems/avatar";
import {Bell} from "../../../components/collections/bell";
import {Handset} from "../../../components/collections/handset";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.userLogout = this.userLogout.bind(this);
        this.onChooseMember = this.onChooseMember.bind(this);
    }

    onChooseMember(isQuick = false) {
        this.props.onModalShow("chooseMember", {isQuick: isQuick});
    }

    userLogout() {
        this.props.onLogout();
    }

    render() {
        const {form, auth, bell, handset, auth: {user}} = this.props;
        const {firstName, lastName, role, isFillProfile, isEnabled, } = auth.user;
        const {systemSettings = {}} = form.data || {};
        const {isEnableEmergencyCall = false} = systemSettings || {};
        const isCanAcceptEmergencyCall = user.doctor && user.doctor.isCanAcceptEmergencyCall

        const dropdownProfile = <div>
            <Avatar userId={auth.user.id}/>
            <span className="dropdown-toggle-text">{firstName}<br/>{lastName}</span>
        </div>;

        const dropdownBell = <Bell ring={bell.ring}/>;
        const dropdownHandset = <Handset ring={handset.ring}/>;
        return (
            <header className="header">
                <div className="main-container container-fluid">
                    <div className="row header-row align-items-center justify-content-end">
                        <div className="col header-row__btns">
                            <TopMenu/>
                        </div>
                        {role === 'DOCTOR' && isEnabled && isEnableEmergencyCall && isCanAcceptEmergencyCall &&
                            <div className="col-auto header-row__info">
                                <Dropdown button={dropdownHandset} noArrow={true} notification={true}>
                                    <QueueEmergencyCalls/>
                                </Dropdown>
                            </div>
                        }

                        <div className="col-auto header-row__info">
                            <ButtonIcon icon={'support'} onClick={() => this.props.onCreateTicketSupport()}/>
                        </div>

                        <div className="col-auto header-row__info">
                            <Dropdown button={dropdownBell} noArrow={true} notification={true}>
                                <LastNotifications/>
                            </Dropdown>
                        </div>

                        {role === 'DOCTOR' && isEnabled && isFillProfile &&
                            <div className="col-auto header-row__info">
                                <ButtonIcon icon={'share'} onClick={() => this.props.onModalShow('shareLink')}/>
                            </div>
                        }

                        {role === 'DOCTOR' && isEnabled && isFillProfile &&
                            <div className="col-auto header-row__info">
                                <Link to={'/doctor/schedule/configure'}>
                                    <ButtonIcon icon={'edit-shedule'}/>
                                </Link>
                            </div>
                        }

                        <div className="col-auto header-row__person">
                            <div className="col-auto header-row__status">
                                <Dropdown button={dropdownProfile}>
                                    {(role === 'PATIENT' || role === 'DOCTOR' || role === 'ASSISTANT' || role === 'PHARMACY') &&
                                        <DropdownItem icon="edit" to={"/user/profile"}>
                                            <FormattedMessage
                                                id="core.layout.sidebar.item.edit.profile.label"/></DropdownItem>}

                                    {role === 'PATIENT' && !user.familyMember && user.isEnabled && isFillProfile &&
                                        <DropdownItem icon="my-family" to={"/patient/family"}>
                                            <FormattedMessage id="my.family"/></DropdownItem>}

                                    <DropdownItem icon="settings-black" to={"/user/profile/settings"}>
                                        <FormattedMessage
                                            id="core.layout.sidebar.item.settings.label"/></DropdownItem>
                                    <DropdownItem icon="logout" to={false} onClick={() => this.userLogout()}>
                                        <FormattedMessage
                                            id="core.layout.sidebar.item.logout.label"/></DropdownItem>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        form: store.form,
        bell: store.bell,
        handset: store.handset
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(logoutUser()),
        onIsFillPatientProfile: () => dispatch(isFillPatientProfile()),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onCreateTicketSupport: () => dispatch(modalShow('createTicketSupport')),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
