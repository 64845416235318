import React, {Component} from 'react';
import {connect} from "react-redux";

import {Avatar} from "../../../../components/collections/avatar";
import PaymentDetail from "../../doctor/bookings/detail/payment/PaymentDetail";
import {GridCol} from "../../../../components/collections/grid";
import {
    approveBooking,
    fetchBookings, rejectBooking,
    requestOnReschedulingBooking
} from "../../../services/bookingService";
import {formChangeField, formInit, formSimpleChange} from "../../../services/system/formService";
import moment from "moment";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {Modal} from "../../../../components/modules/modal";
import {Button} from "../../../../components/elements/button";
import {refund} from "../../../services/bookingPaymentService";
import {getSex} from "../../../../utils/app";
import ConfirmReasonRejectedAdvanceContent from "../../doctor/bookings/ConfirmReasonRejectedAdvanceContent";
import Tooltip from "../../../systems/tooltip";
import {FormattedMessage} from "react-intl";

class AssistantPatientDetail extends Component {
    constructor(props) {
        super(props);
        this.onHideAssistantPatientDetail = this.onHideAssistantPatientDetail.bind(this)
    }

    componentDidMount() {
        const {form: {data: formData}} = this.props;
        const {assistantPatientDetail: {booking = {}}} = formData;
        this.props.onFormInit('booking', booking);
    }

    onHideAssistantPatientDetail() {
        const {list: {pagination}} = this.props;
        const {number} = pagination.bookings;
        this.props.onModalHide('assistantPatientDetail');
        this.props.onFetchBookings(number + 1);
    }

    componentWillUnmount() {
        this.props.onFormInit('assistantPatientDetail', {});
    }

    render() {
        const {form: {data}} = this.props;
        const {booking = {}} = data;
        const {patient = {}, timeOfReceipt, typeConsultation, payment = {}, status, statusDetail} = booking || {};
        const isStartedConsultation = booking.consultation && booking.consultation.isStarted === true;
        const {paymentStatus = ""} = payment || {};
        const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent booking={booking}/>;
        return (
            <Modal close={true} onClose={() => this.onHideAssistantPatientDetail()} width={600}>
                <div className="row form-elements">
                    <div className="col-12">
                        <div className="align-center">
                            <Avatar size="80"/>
                            <h3 className="margin-top-bottom-10">{patient.firstName} {patient.lastName}</h3>
                            <div className="small-text color-gray">
                                {patient.sex && getSex(patient.sex)}
                                {patient.sex && ", "}
                                {patient.birthday && <FormattedMessage id="core.birthday.value"
                                                               values={{value: moment().diff(moment(patient.birthday), 'years')}}/>}
                            </div>
                            <div className="small-text color-gray"><FormattedMessage id="core.phone.number.label" /> {patient.phone || '-'}</div>
                            <div className="small-text color-gray"><FormattedMessage id="core.insurance.number.label" /> {patient.nhsNumber || '-'}</div>

                        </div>
                    </div>
                    {booking.status === 'REQUESTED' && paymentStatus === 'PENDING' &&
                    <div className="col-12">
                        <div className="white-block">
                            <div><FormattedMessage id="doctor.booking.pending.payment" /></div>
                        </div>
                    </div>}
                    <div className="col-12">
                        <div className="white-block">
                            <table className="simple-table">
                                <tbody>
                                <tr>
                                    <td>
                                        <div className="small-text color-gray"><FormattedMessage id="core.type.consultation.label"/></div>
                                        <div>
                                            {typeConsultation === 'CHAT' && <FormattedMessage id="core.type.consultation.chat.label"/>}
                                            {typeConsultation === 'VIDEO' && <FormattedMessage id="core.type.consultation.video.label"/>}
                                            {typeConsultation === 'AUDIO' && <FormattedMessage id="core.type.consultation.audio.label"/>}
                                            {typeConsultation === 'OFFLINE' && <FormattedMessage id="core.type.consultation.offline.label"/>}
                                        </div>
                                    </td>
                                    <td className="align-right"></td>
                                </tr>
                                <tr className="middle-text tr-top-bottom-border">
                                    <td>
                                        <div className="small-text color-gray"><FormattedMessage id="core.datetime.label"/></div>
                                        <div>
                                            {moment(timeOfReceipt).format("lll")}
                                        </div>
                                    </td>
                                    <td className="align-right small-controls">
                                        {(booking.status === 'APPROVED' || booking.status === 'REQUESTED') && !isStartedConsultation &&
                                        <div className="small-controls margin-0">
                                            <Button color="white-blue"
                                                    icon="clock-blue"
                                                    onClick={() => this.props.onModalHide('assistantPatientDetail') && this.props.onRescheduling(booking)}>
                                                <FormattedMessage id="core.btn.reschedule.label"/>
                                            </Button>
                                        </div>}
                                    </td>
                                </tr>

                                <tr className="middle-text tr-top-bottom-border">
                                    <td>
                                        <div className="small-text color-gray"><FormattedMessage id="booking.detail.consultation.status.label"/></div>
                                        <div>
                                            <div className="text-block">
                                                <div className="input">
                                                    <div className="nowrap">
                                                        {status === 'REQUESTED' && <FormattedMessage id="booking.status.requested.label"/>}
                                                        {status === 'APPROVED' && <FormattedMessage id="booking.status.approved.label"/>}
                                                        {status === 'REJECTED' && <FormattedMessage id="booking.status.rejected.label"/>}
                                                        {status === 'COMPLETED' && <FormattedMessage id="booking.status.completed.label"/>}
                                                        {status === 'REJECTED' && statusDetail &&
                                                        <Tooltip align={'left'}>Reason: {statusDetail}</Tooltip>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="align-right"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {payment &&
                    <GridCol count="1">
                        <PaymentDetail payment={payment}/>
                    </GridCol>}
                    <div className="col-12">
                        <div className="form-submit btns-width-auto">
                            {booking.status === 'REQUESTED' &&
                            <Button
                                disabled={paymentStatus === 'PENDING'}
                                onClick={() => this.props.onConfirm({
                                    text: <FormattedMessage id="core.confirm.booking.approve"/>,
                                    onProceed: (() => this.props.onApproveBooking(booking))
                                })}><FormattedMessage id="core.btn.approve.label"/></Button>}

                            {(status === 'REQUESTED' || status === 'APPROVED') &&
                            <Button color="white-red" onClick={() => this.props.onConfirm({
                                text: <FormattedMessage id="core.confirm.booking.reject"/>,
                                advanceContent: rejectedAdvanceContent,
                                labelProceed: <FormattedMessage id="core.confirm.booking.reject.label-proceed"/>,
                                onProceed: (() => this.props.onRejectBooking(booking))
                            })}>
                                {status === 'REQUESTED' &&
                                <FormattedMessage id="core.btn.reject.label"/>}
                                {status === 'APPROVED' &&
                                <FormattedMessage id="core.btn.cancel.label"/>}
                            </Button>}

                          {/*  {status === 'REJECTED' && bookingCancelReasonCode != 'PATIENT_UNAVAILABLE' && (paymentStatus === 'COMPLETED' || paymentStatus === 'NOT_VERIFY') &&
                            <Button onClick={() => this.props.onConfirm({
                                text: <FormattedMessage id="core.confirm.booking.refund"/>,
                                onProceed: (() => this.props.onRefund(booking))
                            })}><FormattedMessage id="core.btn.refund.label"/></Button>}

                            {paymentStatus === 'REFUND' &&
                            <Button onClick={() => this.props.onModalShow('unverifiablePaymentRefund', {
                                booking: booking,
                                refundTransactionNumber: null,
                                refundCompletedAt: moment().format("DD.MM.YYYY hh:mm:ss") ,
                            })}><FormattedMessage id="core.booking.create-refund-transaction"/></Button>}*/}
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        preloader: store.preloader,
        tab: store.tab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onApproveBooking: (booking) => dispatch(approveBooking(booking)),
        onRejectBooking: (booking) => dispatch(rejectBooking(booking)),
        onRescheduling: (booking) => dispatch(modalShow('rescheduling', {booking: booking})),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onRequestOnReschedulingBooking: (rescheduling) => dispatch(requestOnReschedulingBooking(rescheduling)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber)),
        onFormInit: (formName, data) => dispatch(formInit(formName, data)),
        onRefund: (booking) => dispatch(refund(booking)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssistantPatientDetail);
