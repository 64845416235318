import React, {Component} from 'react';
import {connect} from "react-redux";
import {signViaApple} from "../../services/userService";
import qs from "query-string";
import {Redirect} from "react-router";
import {noticeShowAction} from "../../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import {MainLogo} from "../../../components/elements/logo";
import {Header} from "../../../components/elements/header";
import {GridCol} from "../../../components/collections/grid";
import {EmptyTag} from "../../../components/elements/emptyTag";

class SignViaApple extends Component {
    render() {
        const {auth} = this.props;
        const p = this.props.location.search;
        const {idToken = "", code = "", error = ""} = qs.parse(p);

        if (auth.isAuthenticated) {
            const signReferrer = localStorage.getItem("SignReferrer") || "/";
            return <Redirect to={signReferrer}/>;
        } else if (error != "" && error != null) {
            this.props.onNotification(error);
            return "";
        } if (idToken !== "" && code !== "") {
            this.props.onSignViaApple(idToken, code);
            return(
                <EmptyTag>
                    <MainLogo/>

                    <Header as="h2"><FormattedMessage id="core.btn.sign.via.apple.label"/></Header>

                    <GridCol count="1">
                        <a href={"/welcome"} className="back-link"><FormattedMessage id="core.btn.back.to.welcome.label"/></a>
                    </GridCol>
                </EmptyTag>
            );
        } else {
           return <Redirect to="/"/>;
        }
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSignViaApple: (idToken, code) =>
            dispatch(signViaApple({idToken: idToken, code : code, code2FA: "", isAgree: false}, ownProps)),
        onNotification: (textMsg) => dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, textMsg))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignViaApple);