import React, {Component} from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';

import PrivateLayoutRouter from './containers/layouts/private/PrivateLayoutRouter';
import PublicLayoutRouter from './containers/layouts/public/PublicLayoutRouter';

import PaymentReports from "./containers/pages/report";

import PaymentCancel from "./containers/pages/PaymentCancel";
import PaymentSuccess from "./containers/pages/PaymentSuccess";
import PaymentDecline from "./containers/pages/PaymentDecline";

import PrescriptionMedicationsPrint from "./containers/pages/pharmacy/PrescriptionMedicationsPrint";

import AcceptTerms from "./containers/pages/users/AcceptTerms";
import Welcome from "./containers/pages/home/Welcome";
import Sign from "./containers/pages/users/Sign";
import SignViaGoogle from "./containers/pages/users/SignViaGoogle";
import SignViaApple from "./containers/pages/users/SignViaApple";
import SignUp from "./containers/pages/users/Signup";
import SignUpDoctor from "./containers/pages/users/SignupDoctor";
import ForgotPassword from "./containers/pages/users/ForgotPassword";
import ReVerifyAccount from "./containers/pages/users/ReVerifyAccount";
import ResetPassword from "./containers/pages/users/ResetPassword";
import VerifyAccount from "./containers/pages/users/VerifyAccount";
import VerifyNewEmail from "./containers/pages/users/VerifyNewEmail";
import CompleteSignUpAsDoctor from "./containers/pages/users/CompleteSignUpAsDoctor";
import CompleteSignUpAsFamilyMember from "./containers/pages/patient/family/CompleteSignUpAsFamilyMember";

import NotFound from "./containers/pages/NotFound";
import AccessDenied from "./containers/pages/AccessDenied";
import Sign2FA from "./containers/pages/users/Sign2FA";
import Home from "./containers/pages/home";
import {TitleComponent} from "./containers/systems/title";
import Call from "./containers/pages/call";
import Notifications from "./containers/pages/notifications";

import EditProfile from "./containers/pages/users/EditProfile";
import SettingsProfile from "./containers/pages/users/SettingsProfile";

import AdminCalls from "./containers/pages/admin/calls";

import AdminDoctorSchedule from "./containers/pages/admin/users/DoctorSchedule";
import AdminProfile from "./containers/pages/admin/users/Profile";
import Settings from "./containers/pages/admin/settings";

import PatientHome from "./containers/pages/patient/home";
import MedicalRecord from "./containers/pages/patient/medicalRecord";
import Treatment from "./containers/pages/patient/treatment";
import TreatmentDetail from "./containers/pages/patient/treatment/detail";
import BookAnAppointment from "./containers/pages/patient/bookAnAppointment";
import QuickBooking from "./containers/pages/patient/quickBooking";
import PatientBookings from "./containers/pages/patient/bookings/PatientBookings";
import PatientBooking from "./containers/pages/patient/bookings/PatientBooking";
import CatalogDoctors from "./containers/pages/patient/catalogDoctors";
import EmbedCatalogDoctors from "./containers/pages/patient/catalogDoctors/embed";
import CatalogDoctorDetail from "./containers/pages/patient/catalogDoctors/CatalogDoctorDetail";
import MyFamily from "./containers/pages/patient/family";

import DoctorHome from "./containers/pages/doctor/home";
import DoctorSchedule from "./containers/pages/doctor/schedule";
import DoctorBookings from "./containers/pages/doctor/patients";
import DoctorBookingDetail from "./containers/pages/doctor/bookings/detail/BookingDetail";
import DoctorBookingDetailPrint from "./containers/pages/doctor/bookings/detail/BookingDetailPrint";
import DoctorAssistants from "./containers/pages/doctor/assistants";
import DoctorPatientConsulted from "./containers/pages/doctor/patientConsulted/PatientConsulted";
import DoctorPatientConsultedBookings from "./containers/pages/doctor/patientConsulted/PatientBookings";
import ConfigureDoctorSchedule from "./containers/pages/doctor/scheduleConfigure/ConfigureDoctorSchedule";
import AccountCalendar from "./containers/pages/doctor/scheduleConfigure/settings";
import DoctorExtraConsultations from "./containers/pages/doctor/extraConsultations";
import GoogleCalendarAuthMobileCallback from "./containers/pages/GoogleCalendarAuthMobileCallback";

import AssistantHome from "./containers/pages/assistant/home";
import AcceptAssistantInvite from "./containers/pages/assistant/AcceptAssistantInvite";
import SignUpAssistant from "./containers/pages/assistant/SignUpAssistant";
import AssistantDoctorSchedule from "./containers/pages/assistant/patients";

import ClientAdminHome from "./containers/pages/admin/billing/home";
import DoctorReviews from "./containers/pages/admin/doctorReviews";
import Users from "./containers/pages/admin/users";
import CurrencyRates from "./containers/pages/admin/currencyRates";
import BillingReport from "./containers/pages/admin/billing/report";
import BillingBookings from "./containers/pages/admin/bookings";
import BillingDoctorCommissions from "./containers/pages/admin/commissions";
import BillingReportRecipients from "./containers/pages/admin/recipients/";
import AcceptReportRecipientInvite from "./containers/pages/admin/recipients/AcceptReportRecipientInvite";
import ConsultationsRates from "./containers/pages/admin/consultationsRates";
import PaymentSettings from "./containers/pages/admin/paymentSettings";
import ConsultationRecordings from "./containers/pages/admin/consultationRecordings";

import PharmacyPatients from "./containers/pages/pharmacy/listPatients/PharmacyPatients";
import PharmacyNewPrescriptions from "./containers/pages/pharmacy/newPrescriptions/PharmacyNewPrescriptions";
import PharmacyPatientPrescriptions from "./containers/pages/pharmacy/listPatients/PharmacyPatientPrescriptions";
import PharmacyTodayIssuePatients from "./containers/pages/pharmacy/todayIssue/PharmacyTodayIssuePatients";
import PrintLayoutRouter from "./containers/layouts/print/PrintLayoutRouter";
import EmptyLayoutRouter from "./containers/layouts/empty/EmptyLayoutRouter";
import EmbedLayoutRouter from "./containers/layouts/embed/EmbedLayoutRouter";
import {connect} from "react-redux";

const withTitle = ({component: Component, title, ...rest}) => {
    return class Title extends React.Component {
        render() {
            return (
                <React.Fragment>
                    <TitleComponent title={title}/>
                    <Component {...this.props} {...rest}/>
                </React.Fragment>
            );
        }
    };
};

const EditProfileComponent = withTitle({component: EditProfile, title: 'Edit profile'});
const SettingsProfileComponent = withTitle({component: SettingsProfile, title: 'Settings'});
const AdminCallsComponent = withTitle({component: AdminCalls, title: 'Call sessions'});
const SettingsComponent = withTitle({component: Settings, title: 'Settings'});
const AdminDoctorScheduleComponent = withTitle({component: AdminDoctorSchedule, title: 'Doctor schedule'});
const AdminProfileDoctorComponent = withTitle({component: AdminProfile, title: 'Doctor profile', role: 'DOCTOR'});
const AdminProfilePatientComponent = withTitle({component: AdminProfile, title: 'Patient profile', role: 'PATIENT'});
const UsersComponent = withTitle({component: Users, title: 'Users'});
const PaymentCancelComponent = withTitle({component: PaymentCancel, title: 'Payment cancel'});
const PaymentSuccessComponent = withTitle({component: PaymentSuccess, title: 'Payment success'});
const PaymentDeclineComponent = withTitle({component: PaymentDecline, title: 'Payment decline'});
const PaymentReportsComponent = withTitle({component: PaymentReports, title: 'Payment reports'});
const CallComponent = withTitle({component: Call, title: 'Call'});
const NotificationsComponent = withTitle({component: Notifications, title: 'Notifications'});
const DoctorHomeComponent = withTitle({component: DoctorHome, title: 'Home'});
const DoctorExtraConsultationsComponent = withTitle({
    component: DoctorExtraConsultations,
    title: 'Consultations with doctors'
});
const ConfigureDoctorScheduleComponent = withTitle({component: ConfigureDoctorSchedule, title: 'Doctor schedule'});
const AccountCalendarComponent = withTitle({component: AccountCalendar, title: 'Settings'});
const GoogleCalendarAuthMobileCallbackComponent = withTitle({
    component: GoogleCalendarAuthMobileCallback,
    title: 'Settings'
});
const DoctorScheduleComponent = withTitle({component: DoctorSchedule, title: 'Doctor schedule'});
const DoctorBookingsComponent = withTitle({component: DoctorBookings, title: 'Patients'});
const DoctorBookingDetailComponent = withTitle({component: DoctorBookingDetail, title: 'Booking'});
const DoctorBookingDetailPrintComponent = withTitle({component: DoctorBookingDetailPrint, title: 'Booking print'});
const DoctorAssistantsComponent = withTitle({component: DoctorAssistants, title: 'Assistants'});
const DoctorPatientConsultedComponent = withTitle({component: DoctorPatientConsulted, title: 'Patient consulted'});
const DoctorPatientConsultedBookingsComponent = withTitle({
    component: DoctorPatientConsultedBookings,
    title: 'Patient consulted'
});
const AcceptAssistantInviteComponent = withTitle({component: AcceptAssistantInvite, title: 'Accept assistant invite'});
const SignUpAssistantComponent = withTitle({component: SignUpAssistant, title: 'Sign up assistant'});
const AssistantHomeComponent = withTitle({component: AssistantHome, title: 'Home'});
const AssistantDoctorScheduleComponent = withTitle({component: AssistantDoctorSchedule, title: 'Schedule'});
const PatientHomeComponent = withTitle({component: PatientHome, title: 'Home'});
const MyFamilyComponent = withTitle({component: MyFamily, title: 'My Family'});
const MedicalRecordComponent = withTitle({component: MedicalRecord, title: 'Medical record'});
const TreatmentComponent = withTitle({component: Treatment, title: 'Treatment'});
const TreatmentDetailComponent = withTitle({component: TreatmentDetail, title: 'Treatment'});
const BookAnAppointmentComponent = withTitle({component: BookAnAppointment, title: 'Book an appointment'});
const QuickBookingComponent = withTitle({component: QuickBooking, title: 'Quick book'});
const PatientBookingsComponent = withTitle({component: PatientBookings, title: 'Appointments'});
const PatientBookingComponent = withTitle({component: PatientBooking, title: 'Appointment'});
const WelcomeComponent = withTitle({component: Welcome, title: 'Welcome'});
const AcceptTermsComponent = withTitle({component: AcceptTerms, title: 'Accept terms'});
const SignComponent = withTitle({component: Sign, title: 'Sign in'});
const SignViaGoogleComponent = withTitle({component: SignViaGoogle, title: 'Sign in'});
const SignViaAppleComponent = withTitle({component: SignViaApple, title: 'Sign in'});
const SignUpComponent = withTitle({component: SignUp, title: 'Sign up'});
const SignUpDoctorComponent = withTitle({component: SignUpDoctor, title: 'Sign up as doctor'});
const ForgotPasswordComponent = withTitle({component: ForgotPassword, title: 'Forgot password?'});
const ReVerifyAccountComponent = withTitle({component: ReVerifyAccount, title: 'Re-verify account'});
const ResetPasswordComponent = withTitle({component: ResetPassword, title: 'Reset password'});
const VerifyAccountComponent = withTitle({component: VerifyAccount, title: 'Verify account'});
const VerifyNewEmailComponent = withTitle({component: VerifyNewEmail, title: 'Verify new email'});
const CompleteSignUpAsDoctorComponent = withTitle({
    component: CompleteSignUpAsDoctor,
    title: 'Complete sign up as doctor'
});
const CompleteSignUpAsFamilyMemberComponent = withTitle({
    component: CompleteSignUpAsFamilyMember,
    title: 'Complete sign up family member'
});

const Sign2FAComponent = withTitle({component: Sign2FA, title: 'Sign in'});
const HomeComponent = withTitle({component: Home, title: 'Home'});
const NotFoundComponent = withTitle({component: NotFound, title: 'Page not found'});
const AccessDeniedComponent = withTitle({component: AccessDenied, title: 'Access denied'});
const PharmacyPatientsComponent = withTitle({component: PharmacyPatients, title: 'List patients'});
const PharmacyNewPrescriptionsComponent = withTitle({component: PharmacyNewPrescriptions, title: 'New prescriptions'});
const PharmacyPatientPrescriptionsComponent = withTitle({
    component: PharmacyPatientPrescriptions,
    title: 'Patient prescriptions'
});
const PharmacyTodayIssuePatientsComponent = withTitle({component: PharmacyTodayIssuePatients, title: 'Today issue'});
const PrescriptionMedicationsPrintComponent = withTitle({
    component: PrescriptionMedicationsPrint,
    title: 'Prescription medications print'
});

const EmbedCatalogDoctorsComponent = withTitle({component: EmbedCatalogDoctors, title: 'Doctors catalogue'});
const CatalogDoctorsComponent = withTitle({component: CatalogDoctors, title: 'Doctors catalogue'});
const CatalogDoctorDetailComponent = withTitle({component: CatalogDoctorDetail, title: 'Doctor'});

const ClientAdminHomeComponent = withTitle({component: ClientAdminHome, title: 'Home'});
const DoctorReviewsComponent = withTitle({component: DoctorReviews, title: 'Doctor reviews'});
const DoctorReportRemittancesComponent = withTitle({
    component: BillingReport,
    title: 'Report remittances',
    reportType: 'REMITTANCE'
});
const BillingReportTotalRemittancesComponent = withTitle({
    component: BillingReport,
    title: 'Report total remittances',
    reportType: 'TOTAL_REMITTANCE'
});
const BillingReportTotalCommissionsComponent = withTitle({
    component: BillingReport,
    title: 'Report total commissions',
    reportType: 'TOTAL_COMMISSION'
});
const BillingReportConsultationsComponent = withTitle({
    component: BillingReport,
    title: 'All consultation',
    reportType: 'CONSULTATION'
});
const BillingReportBurnDownComponent = withTitle({
    component: BillingReport,
    title: 'Report total commissions',
    reportType: 'BURN_DOWN'
});
const BillingReportCortexPayoutComponent = withTitle({
    component: BillingReport,
    title: 'Report cortex payout',
    reportType: 'CORTEX_PAYOUT'
});
const BillingReportBankTotalPayoutComponent = withTitle({
    component: BillingReport,
    title: 'Report payout',
    reportType: 'BANK_TOTAL_PAYOUT'
});
const BillingBookingsComponent = withTitle({component: BillingBookings, title: 'Bookings & Payments'});
const BillingReportRecipientsComponent = withTitle({component: BillingReportRecipients, title: 'Recipient list'});
const AcceptReportRecipientInviteComponent = withTitle({
    component: AcceptReportRecipientInvite,
    title: 'Accept invite'
});
const ConsultationsRatesComponent = withTitle({component: ConsultationsRates, title: 'Consultations rates'});
const CurrencyRatesComponent = withTitle({component: CurrencyRates, title: 'Currency rates'});
const PaymentSettingsComponent = withTitle({component: PaymentSettings, title: 'Payment settings'});

const ConsultationRecordingsComponent = withTitle({
    component: ConsultationRecordings,
    title: 'Consultation recordings'
});

class Router extends Component {
    render() {
        const {auth: {user}} = this.props;
        return (
            <BrowserRouter>
                <Switch>
                    <PrintLayoutRouter exact path="/doctor/bookings/:id/print"
                                       component={DoctorBookingDetailPrintComponent}
                                       title="Booking print" hasAnyRoles={'DOCTOR'}/>
                    <EmbedLayoutRouter exact path="/catalogDoctors/embed" component={EmbedCatalogDoctorsComponent}
                                       title="Catalog of doctors" permitAll/>

                    <PublicLayoutRouter exact path="/users/acceptTerms" component={AcceptTermsComponent}
                                        title="Accept terms"
                                        permitAll/>
                    <PublicLayoutRouter exact path="/welcome" component={WelcomeComponent} title="Welcome" permitAll/>
                    <PublicLayoutRouter exact path="/paymentSuccess" component={PaymentSuccessComponent}
                                        title="Payment success"
                                        permitAll/>
                    <PublicLayoutRouter exact path="/paymentCancel" component={PaymentCancelComponent}
                                        title="Payment cancel"
                                        permitAll/>
                    <PublicLayoutRouter exact path="/paymentDecline" component={PaymentDeclineComponent}
                                        title="Payment decline"
                                        permitAll/>

                    <PublicLayoutRouter exact path="/users/sign-in" component={SignComponent} title="Sign in"
                                        permitAll/>
                    <PublicLayoutRouter exact path="/users/sign-up" component={SignUpComponent} title="Sign up"
                                        permitAll/>
                    <PublicLayoutRouter exact path="/users/sign-up-doctor" component={SignUpDoctorComponent}
                                        title="Sign up as doctor" permitAll/>
                    <PublicLayoutRouter exact path="/users/sign-in/2fa" component={Sign2FAComponent} title="Sign in"
                                        permitAll/>
                    <PublicLayoutRouter exact path="/users/sign-in/google/callback" component={SignViaGoogleComponent}
                                        title="Sign in via Google" permitAll/>
                    <PublicLayoutRouter exact path="/users/sign-in/apple/callback" component={SignViaAppleComponent}
                                        permitAll/>

                    <PublicLayoutRouter path="/users/forgotPassword" component={ForgotPasswordComponent}
                                        title="Forgot password?" permitAll/>

                    <PublicLayoutRouter path="/users/reVerifyAccount" component={ReVerifyAccountComponent}
                                        title="Re-verify account" permitAll/>

                    <PublicLayoutRouter exact path="/tokens/:token/verifyNewEmail" title="Verify new email"
                                        component={VerifyNewEmailComponent} permitAll/>
                    <PublicLayoutRouter exact path="/tokens/:token/verifyAccount" title="Verify account"
                                        component={VerifyAccountComponent} permitAll/>
                    <PublicLayoutRouter exact path="/tokens/:token/resetPassword" title="Reset password"
                                        component={ResetPasswordComponent} permitAll/>
                    <PublicLayoutRouter exact path="/tokens/:token/acceptAssistantInvite"
                                        title="Accept assistant invite"
                                        component={AcceptAssistantInviteComponent} permitAll/>
                    <PublicLayoutRouter exact path="/tokens/:token/signUpAssistant" title="Sign up assistant"
                                        component={SignUpAssistantComponent} permitAll/>
                    <PublicLayoutRouter exact path="/tokens/:token/completeSignUpAsDoctor"
                                        title="Complete sign up as doctor"
                                        component={CompleteSignUpAsDoctorComponent} permitAll/>
                    <PublicLayoutRouter exact path="/tokens/:token/completeSignUpAsFamilyMember"
                                        title="Complete sign up as doctor"
                                        component={CompleteSignUpAsFamilyMemberComponent}
                                        permitAll/>

                    <PublicLayoutRouter exact path="/tokens/:token/acceptReportRecipientsInvite" title=""
                                        component={AcceptReportRecipientInviteComponent} permitAll/>

                    <PrivateLayoutRouter exact path="/user/profile" component={EditProfileComponent} title="EditProfile"
                                         authenticate/>
                    <PrivateLayoutRouter exact path="/user/profile/settings" component={SettingsProfileComponent}
                                         title="Settings profile" authenticate/>

                    <PrivateLayoutRouter exact path="/admin/calls" component={AdminCallsComponent} title="Call sessions"
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/settings" component={SettingsComponent} title="Settings"
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/users/:id/profile/doctor/schedule"
                                         component={AdminDoctorScheduleComponent} title="Doctor schedule"
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/assistant/doctors/:id/schedule"
                                         component={AdminDoctorScheduleComponent} title="Doctor schedule"
                                         hasAnyRoles={'ASSISTANT'}/>
                    <PrivateLayoutRouter exact path="/admin/users/:id/profile/doctor"
                                         component={AdminProfileDoctorComponent}
                                         title="Doctor profile" hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/users/:id/profile/patient"
                                         component={AdminProfilePatientComponent}
                                         title="Patient profile" hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/paymentReports" component={PaymentReportsComponent}
                                         title="Payment reports" hasAnyRoles={'ADMIN'}/>

                    <PrivateLayoutRouter exact path="/pharmacies/todayIssue"
                                         component={PharmacyTodayIssuePatientsComponent}
                                         title="Today issue" hasAnyRoles={'PHARMACY'}/>
                    <PrivateLayoutRouter exact path="/pharmacies/patients" component={PharmacyPatientsComponent}
                                         title="List patients" hasAnyRoles={'PHARMACY'}/>
                    <PrivateLayoutRouter exact path="/pharmacies/newPrescriptions"
                                         component={PharmacyNewPrescriptionsComponent}
                                         title="New prescription" hasAnyRoles={'PHARMACY'}/>
                    <PrivateLayoutRouter exact path="/pharmacies/patients/:id/prescriptions"
                                         component={PharmacyPatientPrescriptionsComponent} title="Patient prescriptions"
                                         hasAnyRoles={'PHARMACY'}/>

                    <PrintLayoutRouter exact path="/pharmacies/prescriptions/:id/print"
                                       component={PrescriptionMedicationsPrintComponent}
                                       title="Prescription medications print"
                                       hasAnyRoles={'PHARMACY'}/>

                    <PrivateLayoutRouter path="/call" component={CallComponent} title="OpenTok" authenticate/>
                    <PrivateLayoutRouter path="/notifications" component={NotificationsComponent} title="Notifications"
                                         authenticate/>

                    <PrivateLayoutRouter exact path="/assistant/home" component={AssistantHomeComponent} title="Home"
                                         hasAnyRoles={'ASSISTANT'}/>
                    <PrivateLayoutRouter exact path="/assistant/schedule" component={AssistantDoctorScheduleComponent}
                                         title="Schedule" hasAnyRoles={'ASSISTANT'}/>

                    <PrivateLayoutRouter exact path="/doctor/home" component={DoctorHomeComponent} title="Home"
                                         hasAnyRoles={'DOCTOR'}/>

                    <PrivateLayoutRouter exact path="/doctor/extraConsultations"
                                         component={DoctorExtraConsultationsComponent}
                                         title="Consultations with doctors"
                                         hasAnyRoles={'DOCTOR'}/>

                    <PrivateLayoutRouter exact path="/doctor/schedule/settings" component={AccountCalendarComponent}
                                         title="Settings" hasAnyRoles={'DOCTOR'}/>

                    <EmptyLayoutRouter exact path="/google-calendar/authorization/mobile/callback"
                                       component={GoogleCalendarAuthMobileCallbackComponent}
                                       title="Configure doctor schedule" permitAll/>

                    <PrivateLayoutRouter exact path="/google-calendar/authorization/callback"
                                         component={AccountCalendarComponent}
                                         title="Configure doctor schedule" hasAnyRoles={'DOCTOR'}/>

                    <PrivateLayoutRouter exact path="/outlook-calendar/authorization/callback"
                                         component={AccountCalendarComponent}
                                         title="Configure doctor schedule" hasAnyRoles={'DOCTOR'}/>

                    <PrivateLayoutRouter exact path="/doctor/schedule/configure"
                                         component={ConfigureDoctorScheduleComponent}
                                         title="Configure doctor schedule" hasAnyRoles={'DOCTOR,ASSISTANT'}/>
                    <PrivateLayoutRouter exact path="/doctor/schedule" component={DoctorScheduleComponent}
                                         title="Doctor schedule" hasAnyRoles={'DOCTOR'}/>
                    <PrivateLayoutRouter exact path="/doctor/bookings/:id/detail"
                                         component={DoctorBookingDetailComponent}
                                         title="Booking" hasAnyRoles={'DOCTOR'}/>
                    <PrivateLayoutRouter exact path="/doctor/bookings" component={DoctorBookingsComponent}
                                         title="Bookings"
                                         hasAnyRoles={'DOCTOR'}/>
                    <PrivateLayoutRouter exact path="/doctor/assistants" component={DoctorAssistantsComponent}
                                         title="Assistants" hasAnyRoles={'DOCTOR'}/>
                    <PrivateLayoutRouter exact path="/doctor/reports/reportRemittance"
                                         component={DoctorReportRemittancesComponent} hasAnyRoles={'DOCTOR'}/>

                    <PrivateLayoutRouter exact path="/patientConsulted/:patientId/bookings"
                                         component={DoctorPatientConsultedBookingsComponent} title="PatientConsulted"
                                         hasAnyRoles={'DOCTOR'}/>
                    <PrivateLayoutRouter exact path="/patientConsulted/:patientId/bookings/:bookingId/detail"
                                         component={DoctorBookingDetailComponent} title="PatientConsulted"
                                         hasAnyRoles={'DOCTOR'}/>
                    <PrivateLayoutRouter exact path="/patientConsulted" component={DoctorPatientConsultedComponent}
                                         title="PatientConsulted" hasAnyRoles={'DOCTOR'}/>

                    <PrivateLayoutRouter exact path="/patient/home" component={PatientHomeComponent} title="Home"
                                         hasAnyRoles={'PATIENT'}/>

                    <PrivateLayoutRouter exact path="/patient/family" component={MyFamilyComponent}
                                         hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/patient/catalogDoctors" component={CatalogDoctorsComponent}
                                         hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/patient/catalogDoctors/:id/detail"
                                         component={CatalogDoctorDetailComponent} hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/patient/medicalRecord" component={MedicalRecordComponent}
                                         title="Medical record" hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/patient/treatment" component={TreatmentComponent}
                                         title="Treatment"
                                         hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/patient/treatment/:id/detail" component={TreatmentDetailComponent}
                                         title="Treatment" hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/bookAnAppointment" component={BookAnAppointmentComponent}
                                         title="Book an appointment" hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/quickBooking" component={QuickBookingComponent} title="Quick book"
                                         hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/bookings" component={PatientBookingsComponent}
                                         title="Appointments"
                                         hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/bookings/:id/detail" component={PatientBookingComponent}
                                         title="Appointment" hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/bookings/:id/paymentSuccess" component={PatientBookingComponent}
                                         title="Appointment" hasAnyRoles={'PATIENT'}/>
                    <PrivateLayoutRouter exact path="/bookings/:id/paymentDecline" component={PatientBookingComponent}
                                         title="Appointment" hasAnyRoles={'PATIENT'}/>

                    <PrivateLayoutRouter exact path="/admin/billing/home" component={ClientAdminHomeComponent}
                                         hasAnyRoles={'ADMIN_BILLING'}/>
                    <PrivateLayoutRouter exact path="/admin/users" component={UsersComponent} hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/doctorReviews" component={DoctorReviewsComponent}
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/commissions" component={BillingDoctorCommissions}
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/recipients" component={BillingReportRecipientsComponent}
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/consultationsRates" component={ConsultationsRatesComponent}
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/paymentSettings" component={PaymentSettingsComponent}
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/currencyRates" component={CurrencyRatesComponent}
                                         hasAnyRoles={'ADMIN'}/>
                    <PrivateLayoutRouter exact path="/admin/billing/reports/totalRemittances"
                                         component={BillingReportTotalRemittancesComponent}
                                         hasAnyRoles={'ADMIN_BILLING'}/>
                    <PrivateLayoutRouter exact path="/admin/billing/reports/totalCommissions"
                                         component={BillingReportTotalCommissionsComponent}
                                         hasAnyRoles={'ADMIN_BILLING'}/>
                    <PrivateLayoutRouter exact path="/admin/billing/reports/burnDown"
                                         component={BillingReportBurnDownComponent}
                                         hasAnyRoles={'ADMIN_BILLING'}/>
                    <PrivateLayoutRouter exact path="/admin/billing/reports/cortexPayout"
                                         component={BillingReportCortexPayoutComponent} hasAnyRoles={'ADMIN_BILLING'}/>
                    <PrivateLayoutRouter exact path="/admin/billing/reports/consultations"
                                         component={BillingReportConsultationsComponent} hasAnyRoles={'ADMIN_BILLING'}/>
                    <PrivateLayoutRouter exact path="/admin/billing/reports/bankTotalPayout"
                                         component={BillingReportBankTotalPayoutComponent}
                                         hasAnyRoles={'ADMIN_BILLING'}/>
                    <PrivateLayoutRouter exact path="/admin/consultationRecordings"
                                         component={ConsultationRecordingsComponent}
                                         hasAnyRoles={'ADMIN'}
                                         hasPrivilege={user.role === 'ADMIN' &&
                                             user.userAdminParams &&
                                             user.userAdminParams.isHasAccessConsultationRecording}/>
                    <PrivateLayoutRouter exact path="/admin/bookings" component={BillingBookingsComponent}
                                         hasAnyRoles={'ADMIN'}/>

                    <PrivateLayoutRouter path="/accessDenied" title="Access is denied" component={AccessDeniedComponent}
                                         permitAll/>
                    <PrivateLayoutRouter exact path="/" component={HomeComponent} authenticate/>
                    <PrivateLayoutRouter component={NotFoundComponent}/>
                </Switch>
            </BrowserRouter>
        )
    }

}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Router);