import React, {Component} from 'react';
import {connect} from "react-redux";

import {sign, signViaApple, signViaGoogle} from "../../services/userService";

import {MainLogo, TwoFactorAuth} from "../../../components/elements/logo";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Buttons, Button} from "../../../components/elements/button";
import {formChangeField, formInit} from "../../services/system/formService";
import {Header} from "../../../components/elements/header";
import {BackToSign} from "../../../components/elements/backTo";
import {Input} from "../../../components/elements/input";
import {Redirect} from "react-router";
import {FormattedMessage} from "react-intl";

class Sign2FA extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.onSign2FA = this.onSign2FA.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('twoFA', event);
    }

    onSign2FA() {
        const {form: {data: formData}} = this.props;
        const data = formData.twoFA || {};
        const credential = data;
        switch (data.authProvider) {
            case "APPLE":
                this.props.onSignViaApple(credential);
                break;
            case "GOOGLE":
                this.props.onSignViaGoogle(credential);
                break;
            case "DEFAULT":
                this.props.onSign(credential);
                break;
        }
    }

    render() {
        const {form: {data: formData, errors: formErrors, isSend}, auth} = this.props;
        const errors = formErrors.twoFA || {};
        const data = formData.twoFA || {};
        const disabled = isSend.twoFA || false;

        if (auth.isAuthenticated || Object.keys(data).length == 0) {
            const signReferrer = localStorage.getItem("SignReferrer") || "/";
            return <Redirect to={signReferrer}/>;
        }
        return (
            <>
                <MainLogo/>
                <Header as="h2"><FormattedMessage id="page.2fa.header"/></Header>
                <p><FormattedMessage id="page.2fa.description.text"/></p>
                <TwoFactorAuth/>
                <p><FormattedMessage id="page.2fa.description2.text"/></p>
                <GridRow width={300}>
                    <GridCol count="1">
                        <Input name="code2FA"
                               icon="password"
                               placeholder={<FormattedMessage id="core.code.label"/>}
                               value={data.code2FA || ""}
                               error={(errors && errors.code2FA) || null}
                               disabled={disabled}
                               onChange={this.onChange}/>
                    </GridCol>
                    <GridCol count="1">
                        <Buttons>
                            <Button disabled={disabled}
                                    preloader={disabled === true}
                                    onClick={this.onSign2FA}><FormattedMessage id="core.btn.done.label"/></Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
                <GridRow width={300}>
                    <GridCol count="1">
                        <BackToSign to={"/users/sign-in"}/>
                    </GridCol>
                </GridRow>
            </>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFormInit: (data) => dispatch(formInit("twoFA", data)),
        onSignViaGoogle: (credential) => dispatch(signViaGoogle(credential, ownProps, "twoFA")),
        onSignViaApple: (credential) => dispatch(signViaApple(credential, ownProps, "twoFA")),
        onSign: (credential) => dispatch(sign(credential, ownProps, "twoFA")),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sign2FA);