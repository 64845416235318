import React, {Component} from 'react';
import moment from 'moment';
import {connect} from "react-redux";
import {Modal} from "../../../../components/modules/modal";
import {GridCol, GridRow} from "../../../../components/collections/grid";
import {Button, Buttons} from "../../../../components/elements/button";
import {Label} from "../../../../components/elements/label";
import {modalHide} from "../../../services/system/modalService";
import {saveBooking} from "../../../services/bookingService";
import {Paragraph} from "../../../../components/elements/paragraph";
import {withRouter} from 'react-router-dom';
import {Icon} from "../../../../components/elements/icon";
import {fetchSystemSettings} from "../../../services/commonService";
import {FormattedMessage} from "react-intl";
import {fetchDoctorPrice} from "../../../services/doctorScheduleService";

class AppointmentSummary extends Component {
    componentDidMount() {
        const {form} = this.props;
        const {data: {createBooking = {}}} = form;
        const {doctor = {}, timeOfReceipt} = createBooking;
        this.props.onFetchDoctorPrice(doctor.id, timeOfReceipt);
        this.props.onfetchSystemSettings();
    }

    render() {
        const {form, list: {data: listData}, auth: {user}} = this.props;
        const {chooseFamilyMembers: familyMembers = []} = listData;
        const {data: {createBooking = {}, systemSettings = {}, doctorPrice = {}}} = form;
        const {doctor = {}, description, timeOfReceipt, patient, address = null} = createBooking;
        const {paymentCurrency} = systemSettings || {};
        const {doctor: {practiceSince}, speakLanguages = []} = doctor || {};
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        const currentYear = moment().format("yyyy")
        const experience = practiceSince ? currentYear - practiceSince : 0;
        const priceReception = doctorPrice.price || null;
        let patientName = "";
        if (patient && user.role == 'PATIENT' && user.isHasFamily && user.id != patient.id) {
            const familyMemberId = familyMembers.findIndex(i => i.child.id == patient.id);
            const memberTypeName = familyMembers[familyMemberId].memberType.name;
            patientName = memberTypeName + " - " + patient.firstName + " " + patient.lastName;
        } else if (patient) {
            patientName = patient.firstName + " " + patient.lastName;
        }

        return (
            <Modal close={true} onClose={() => this.props.onModalHide('appointmentSummary')} width={660}>
                <span className="avatar-item">
                    <span className="avatar-item__photo"/>
                </span>
                <h2><FormattedMessage id="core.doctor.name"
                                      values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></h2>
                <GridRow>
                    <GridCol count='1' border={'border-bottom'}>
                        <div className="color-gray">
                            {joinSpecializations},&nbsp;
                            {experience > 0 && <div><FormattedMessage id="experience.years.value"
                                                                      values={{experience: experience}}/></div>}
                            {!experience && '?'}

                            {speakLanguages.length > 0 &&
                            <div><FormattedMessage id="doctor.speak.languages.value"
                                                   values={{listSpeakLanguages: speakLanguages.map(i => i.name).join(", ")}}/></div>}
                        </div>
                    </GridCol>
                    <GridCol count='2'>
                        <Icon name="price"/>
                        <Label fixed={true}><FormattedMessage id="appointment.summary.price.label"/></Label>
                        <Paragraph>{paymentCurrency || ""} {priceReception && parseFloat(priceReception).toFixed(2) || '?'}</Paragraph>
                    </GridCol>
                    <GridCol count='2'>
                        <Icon name="clock"/>
                        <Label fixed={true}><FormattedMessage id="appointment.summary.time.label"/></Label>
                        <Paragraph>{moment(parseInt(timeOfReceipt)).format('lll')}</Paragraph>
                    </GridCol>
                    {address && <GridCol count='1' border={'border-bottom'}>
                        <Icon name="address"/>
                        <Label fixed={true}><FormattedMessage id="location"/></Label>
                        <Paragraph>{address.text}</Paragraph>
                    </GridCol>}
                    {patient &&
                    <div className="col-12">
                        <Icon name="patient-red"/>
                        <Label fixed={true}><FormattedMessage id="patient"/></Label>
                        <Paragraph>{patientName}</Paragraph>
                    </div>}
                    {description &&
                    <GridCol count='1'>
                        <Icon name="info"/>
                        <Label fixed={true}><FormattedMessage id="appointment.summary.description.label"/></Label>
                        <Paragraph>{description}</Paragraph>
                    </GridCol>}
                    <GridCol count='1'>
                        <Buttons>
                            <Button onClick={() => this.props.onSaveBooking(createBooking)}>
                                <FormattedMessage id="btn.confirm"/>
                            </Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal,
        form: store.form,
        list: store.list,
        infoAppointment: store.infoAppointment
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
        onSaveBooking: (createBooking) => dispatch(saveBooking(createBooking, ownProps)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onFetchDoctorPrice: (doctorId, timeOfReceipt) => dispatch(fetchDoctorPrice(doctorId, timeOfReceipt))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppointmentSummary));
