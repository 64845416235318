import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {fetchPharmacies, savePharmacy} from "../../../../services/consultationService";
import {formChangeField} from "../../../../services/system/formService";
import {Button, Buttons} from "../../../../../components/elements/button";
import {Modal} from "../../../../../components/modules/modal";
import {modalHide} from "../../../../services/system/modalService";
import {Header} from "../../../../../components/elements/header";
import {FormattedMessage} from "react-intl";

class ChoosePharmacy extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.props.onFetchPharmacies(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchPharmacies(pageNumber);
    }

    onChange(event) {
        this.props.onFormChangeField('choosePharmacy', event);
    }

    render() {
        const {form: {data: formData, errors: formErrors, isSend: formIsSend}, list: {data: listData}} = this.props;
        const errors = formErrors.choosePharmacy;
        const disabled = formIsSend.choosePharmacy || false;
        const {consultation = {}, choosePharmacy = {}} = formData;
        const {pharmacies = []} = listData;

        return (
            <Modal close={true} onClose={() => this.props.onModalHide()} width={800}>
                <Header as="h2"><FormattedMessage id="modal.choose.pharmacy.header"/></Header>
                {errors && errors["id"] && <div className="form-response color-red">{errors["id"]}</div>}
                <GridRow>
                    <GridCol count="1">
                        <div className="row tile-row">
                            {pharmacies.map(pharmacy => {
                                return (
                                    <div className="col-md-6" key={pharmacy.id}>
                                        <label className="white-block label-uni-input">
                                            <div className="row align-items-center">
                                                <div className="col-auto">
                                                    <input type="radio"
                                                           name="id"
                                                           defaultChecked={consultation && consultation.pharmacy && consultation.pharmacy.id === pharmacy.id}
                                                           value={pharmacy.id}
                                                           onChange={this.onChange}/>
                                                    <span></span>
                                                </div>
                                                <div className="col">
                                                    <div className="bold">{pharmacy.name}</div>
                                                    <div className="small-text color-gray">{pharmacy.address}</div>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                );
                            })}

                        </div>
                    </GridCol>
                    <GridCol count="1">
                        <Buttons>
                            <Button disabled={disabled}
                                    preloader={disabled === true}
                                    onClick={() => this.props.onSavePharmacy(consultation, choosePharmacy)}><FormattedMessage id="core.btn.save.label"/></Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchPharmacies: (pageNumber) => dispatch(fetchPharmacies(pageNumber)),
        onModalHide: () => dispatch(modalHide('choosePharmacy')),
        onSavePharmacy: (consultation, pharmacy) => dispatch(savePharmacy(consultation, pharmacy)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePharmacy);
