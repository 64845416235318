import React, {Component} from 'react';
import {connect} from "react-redux";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {Modal} from "../../../../components/modules/modal";
import {Header} from "../../../../components/elements/header";

import {FormattedMessage} from "react-intl";
import {GridRow} from "../../../../components/collections/grid";
import {formChangeField} from "../../../services/system/formService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import {Button, Buttons} from "../../../../components/elements/button";
import {Option, Select} from "../../../../components/elements/select";
import {DatePicter} from "../../../../components/elements/datepicter";
import moment from "moment";
import {applyAppointmentTemplate} from "../../../services/configureDoctorScheduleService";
import {Checkbox} from "../../../../components/elements/checkbox";


class ApplyAppointmentTemplate extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeAppointmentTemplate = this.onChangeAppointmentTemplate.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onChangeActiveDay = this.onChangeActiveDay.bind(this);
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData}} = this.props;
        const {form: {data: prevFormData}} = prevProps;
        const {applyAppointmentTemplate: curApplyAppointmentTemplate} = curFormData;
        const {applyAppointmentTemplate: prevApplyAppointmentTemplate} = prevFormData;

        const {appointmentTemplate: curAppointmentTemplate = null} = curApplyAppointmentTemplate;
        const {appointmentTemplate: prevAppointmentTemplate = null} = prevApplyAppointmentTemplate;

        if (curAppointmentTemplate != prevAppointmentTemplate && curAppointmentTemplate != null) {
            const {startTime} = curApplyAppointmentTemplate;
            this.onChangeTime(moment(startTime, "HH:mm"), "startTime");
        }
    }

    onChangeActiveDay(event, activeDay) {
        const {form: {data: formData}} = this.props;
        const {applyAppointmentTemplate = {}} = formData;
        const {activeDays = []} = applyAppointmentTemplate;

        if (event.target.checked) {
            activeDays.push(activeDay)
        } else {
            const index = activeDays.findIndex(i => i === activeDay);
            delete activeDays[index];
        }

        this.props.onFormSimpleChange('applyAppointmentTemplate', "activeDays", activeDays);
    }

    onChange(event) {
        this.props.onFormChangeField('applyAppointmentTemplate', event);
    }

    onChangeAppointmentTemplate(appointmentTemplate) {
        this.props.onFormSimpleChange('applyAppointmentTemplate', 'appointmentTemplate', appointmentTemplate);
    }

    onChangeDate(objMoment, fieldName) {
        const fieldValue =
            moment.isMoment(objMoment) && moment(objMoment).isValid() ? objMoment.format("YYYY-MM-DD") : null;
        this.props.onFormSimpleChange('applyAppointmentTemplate', fieldName, fieldValue);

        if (fieldName == 'startDate') {
            this.props.onFormSimpleChange('applyAppointmentTemplate', "endDate", fieldValue);
        }
    }

    onChangeTime(objMoment, fieldName) {
        const fieldValue =
            moment.isMoment(objMoment) && moment(objMoment).isValid() ? objMoment.format("HH:mm") : null;
        this.props.onFormSimpleChange('applyAppointmentTemplate', fieldName, fieldValue);

        if (fieldName == 'startTime') {
            const {form: {data: formData}} = this.props;
            const {applyAppointmentTemplate = {}} = formData;
            const {appointmentTemplate = {}} = applyAppointmentTemplate;

            if (appointmentTemplate) {
                const endTime = moment(fieldValue, "HH:mm")
                    .add(appointmentTemplate.duration, "m").format("HH:mm");
                this.props.onFormSimpleChange('applyAppointmentTemplate', "endTime", endTime);
            }
        }
    }

    render() {
        const {form: {data: formData, errors: formErrors}, list: {data: listData}, language} = this.props;
        const {appointmentTemplates = []} = listData;
        const {applyAppointmentTemplate = {}} = formData;
        const {
            startDate,
            startTime,
            appointmentTemplate,
            endDate = null,
            endTime = null,
            activeDays = []
        } = applyAppointmentTemplate;
        const errors = formErrors.applyAppointmentTemplate;
        let disabled = false;

        if (!startDate || !endDate || !startTime || !endTime ||
            moment(startDate).isAfter(moment(endDate)) ||
            moment(startTime, "HH:mm").isAfter(moment(endTime, "HH:mm"))) {
            disabled = true;
        }
        return (
            <Modal close={true} onClose={() => this.props.onModalHide()}>
                <Header as="h2">
                    <FormattedMessage id="add.appointment.to.calendar"/>
                </Header>
                <GridRow>
                    <div className="col-12">
                        <Select label={<FormattedMessage id="choose.consultation.template"/>}
                                error={(errors && errors['appointmentTemplate']) || null}
                                optionNameSelected={(appointmentTemplate && appointmentTemplate.name) || ''}>
                            {
                                appointmentTemplates.filter(i => i.isCanApply).map(i =>
                                    <Option
                                        active={appointmentTemplate && appointmentTemplate.id === i.id}
                                        key={i.id}
                                        onClick={() => this.onChangeAppointmentTemplate(i)}>
                                        {i.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>
                    <div className="col-md-6">
                        <FormattedMessage id="start.date">
                            {(placeholder) => (
                                <DatePicter no_label={true}
                                            error={(errors && errors['startDate']) || null}
                                            locale={language.locale}
                                            placeholder={placeholder}
                                            bg_white={true}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => {
                                                this.onChangeDate(v, 'startDate')
                                            }}
                                            isNotChangeInput={true}
                                            value={startDate && moment(startDate)}
                                            dateFormat={'ll'}/>
                            )}
                        </FormattedMessage>

                    </div>

                    <div className="col-md-6">
                        <FormattedMessage id="end.date">
                            {(placeholder) => (
                                <DatePicter no_label={true}
                                            error={(errors && errors['endDate']) || null}
                                            placeholder={placeholder}
                                            bg_white={true}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            locale={language.locale}
                                            onChange={(v) => {
                                                this.onChangeDate(v, 'endDate')
                                            }}
                                            isNotChangeInput={true}
                                            disabled={!applyAppointmentTemplate['startDate']}
                                            value={endDate && moment(endDate).format("ll")}
                                            dateFormat={'ll'}/>
                            )}
                        </FormattedMessage>
                    </div>

                    <div className="col-md-6">
                        <FormattedMessage id="begins.at">
                            {(placeholder) => (
                                <DatePicter no_label={true}
                                            error={(errors && errors['startTime']) || null}
                                            placeholder={placeholder}
                                            bg_white={true}
                                            closeOnSelect={true}
                                            locale={language.locale}
                                            disabled={appointmentTemplate == null}
                                            onChange={(v) => {
                                                this.onChangeTime(v, 'startTime')
                                            }}
                                            isNotChangeInput={true}
                                            value={startTime && moment(startTime, "HH:mm").format("LT")}
                                            dateFormat={false}/>
                            )}
                        </FormattedMessage>
                    </div>

                    <div className="col-md-6">
                        <FormattedMessage id="ends.at">
                            {(placeholder) => (
                                <DatePicter no_label={true}
                                            error={(errors && errors['endTime']) || null}
                                            placeholder={placeholder}
                                            bg_white={true}
                                            closeOnSelect={true}
                                            locale={language.locale}
                                            disabled={!startTime || appointmentTemplate == null}
                                            onChange={(v) => {
                                                this.onChangeTime(v, 'endTime')
                                            }}
                                            isNotChangeInput={true}
                                            value={endTime && moment(endTime, "HH:mm").format("LT")}
                                            dateFormat={false}/>
                            )}
                        </FormattedMessage>
                    </div>

                    <div className="col-12">
                        <label className="field-label"><FormattedMessage id="active.by.day"/></label>
                        <div className="square-labels-wrapper">
                            <Checkbox value='0'
                                      square={true}
                                      isChecked={activeDays.findIndex(i => i === 7) !== -1}
                                      onChange={(event) => this.onChangeActiveDay(event, 7)}>
                                <FormattedMessage id="s"/>
                            </Checkbox>
                            <Checkbox value='1'
                                      square={true}
                                      isChecked={activeDays.findIndex(i => i === 1) !== -1}
                                      onChange={(event) => this.onChangeActiveDay(event, 1)}>
                                <FormattedMessage id="m"/>
                            </Checkbox>
                            <Checkbox value='2'
                                      square={true}
                                      isChecked={activeDays.findIndex(i => i === 2) !== -1}
                                      onChange={(event) => this.onChangeActiveDay(event, 2)}>
                                <FormattedMessage id="t"/>
                            </Checkbox>
                            <Checkbox value='3'
                                      square={true}
                                      isChecked={activeDays.findIndex(i => i === 3) !== -1}
                                      onChange={(event) => this.onChangeActiveDay(event, 3)}>
                                <FormattedMessage id="w"/>
                            </Checkbox>
                            <Checkbox value='4'
                                      square={true}
                                      isChecked={activeDays.findIndex(i => i === 4) !== -1}
                                      onChange={(event) => this.onChangeActiveDay(event, 4)}>
                                <FormattedMessage id="t"/>
                            </Checkbox>
                            <Checkbox value='5'
                                      square={true}
                                      isChecked={activeDays.findIndex(i => i === 5) !== -1}
                                      onChange={(event) => this.onChangeActiveDay(event, 5)}>
                                <FormattedMessage id="f"/>
                            </Checkbox>
                            <Checkbox value='6'
                                      square={true}
                                      isChecked={activeDays.findIndex(i => i === 6) !== -1}
                                      onChange={(event) => this.onChangeActiveDay(event, 6)}>
                                <FormattedMessage id="s"/>
                            </Checkbox>
                        </div>
                    </div>

                    <div className="col-12">
                        <Buttons width="auto">
                            <Button disabled={disabled}
                                    color="white-red"
                                    onClick={() => this.props.onModalHide()}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button disabled={disabled || appointmentTemplate == null}
                                    onClick={() => this.props.onApplyAppointmentTemplate(applyAppointmentTemplate)}>
                                <FormattedMessage id="core.btn.add.label"/>
                            </Button>
                        </Buttons>
                    </div>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal,
        form: store.form,
        list: store.list,
        language: store.language
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onApplyAppointmentTemplate: (data) => dispatch(applyAppointmentTemplate(data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onModalHide: () => dispatch(modalHide('applyAppointmentTemplate')),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('applyAppointmentTemplate', fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplyAppointmentTemplate);
