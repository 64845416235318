import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Avatar} from "../../../../components/collections/avatar";

import {GridCol} from "../../../../components/collections/grid";
import Pagination from "react-js-pagination";
import {fetchDoctorPatients} from "../../../services/doctorService";
import {Link} from "react-router-dom";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Header} from "../../../../components/elements/header";
import {FormattedMessage} from "react-intl";
import {getSex} from "../../../../utils/app";
import Search from "../../../systems/search";
import {listFilterFieldAction} from "../../../../actions/listAction";
import {Redirect} from "react-router";

class PatientConsulted extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    componentDidMount() {
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        const {auth} = this.props;
        this.props.onFetchDoctorPatients(auth.user, pageNumber);
    }

    onSearch(text) {
        this.props.onListFilterField("searchText", text);
        this.onPagination(1);
    }

    render() {
        const {list: {data, pagination}, auth: {user}} = this.props;
        if (user.role === 'DOCTOR' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }
        const {doctorPatients = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['doctorPatients'] || {};

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="core.header.patient.consulted"/></Header>
                    </div>
                </div>
                <div className="row content-row">

                    <GridCol count="1">
                        <div className="row form-elements">
                            <div className="col-md-4">
                                <Search onSearch={this.onSearch}/>
                            </div>
                        </div>
                    </GridCol>
                    <GridCol count="1">
                        <div className="row link-blocks-row">
                            {doctorPatients.map(({id, firstName, lastName, sex, birthday}) => {
                                return (
                                    <GridCol count="2" key={id}>
                                        <div className="link-block-wrapper">
                                            <Link to={"/patientConsulted/" + id + "/bookings"} className="link-block"></Link>
                                            <div className="row avatar-row align-items-center">
                                                <div className="col-auto avatar-row__image">
                                                    <Avatar/>
                                                </div>
                                                <div className="col avatar-row__text">
                                                    <div>{firstName} &nbsp; {lastName}</div>
                                                    <div className="small-text color-gray">
                                                        {sex && getSex(sex)}
                                                        {sex && ", "}
                                                        {birthday && <FormattedMessage id="core.birthday.value"
                                                                                       values={{value: moment().diff(birthday, 'years')}}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </GridCol>
                                );
                            })}
                        </div>
                        <div className="col-12">
                            {size < totalElements && <Pagination
                                activeLinkClass={"active"}
                                activePage={number + 1}
                                itemsCountPerPage={size}
                                totalItemsCount={totalElements}
                                pageRangeDisplayed={5}
                                onChange={this.onPagination}/>}
                        </div>
                    </GridCol>
                </div>
            </EmptyTag>

        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("doctorPatients", fieldName, fieldValue)),
        onFetchDoctorPatients: (doctor, pageNumber) => dispatch(fetchDoctorPatients(doctor, pageNumber)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientConsulted);
