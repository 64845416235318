import React, {Component} from 'react';
import {connect} from "react-redux";

import {Link} from "react-router-dom";
import {GridCol} from "../../../../../components/collections/grid";
import {IconItem} from "../../../../../components/elements/icon";
import {Paragraph} from "../../../../../components/elements/paragraph";
import {formChangeField} from "../../../../services/system/formService";
import {fetchHistories, saveDescription} from "../../../../services/consultationService";
import Pagination from "react-js-pagination";
import moment from "moment/moment";
import {FormattedMessage} from "react-intl";

class DetailHistory extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        const {form: {data}} = this.props;
        const {booking = {}} = data;
        const {patient = {}} = booking;
        this.props.onFetchHistories(pageNumber, patient);
    }

    onChange(event) {
        this.props.onFormChangeField('consultation', event);
    }

    render() {
        const {list: {data, pagination}} = this.props;
        const {histories = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['histories'] || {};
        return (
            <div className="row tile-row">

                {histories.map(history => {
                    const {id, typeConsultation, symptoms = [], completedAt} = history;
                    return (
                        <GridCol count="3" key={id}>
                            <div className="link-block-wrapper">
                                <Link to={"/patient/treatment/" + id + "/detail"} className="link-block"></Link>
                                <div className="link-block-header bg-color-blue">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            {completedAt && moment(completedAt).format("lll")}
                                        </div>
                                        <div className="col-auto">
                                            <FormattedMessage id="core.id.label" /> {id}
                                        </div>
                                    </div>
                                </div>
                                <div className="row form-elements form-bottom-bordered">
                                    <GridCol count="1">
                                        {typeConsultation === 'OFFLINE' &&
                                        <IconItem name="hospital"><FormattedMessage id="booking.history.type.consultation.offline.label" /></IconItem>}
                                        {typeConsultation === 'CHAT' &&
                                        <IconItem name="chat-green"><FormattedMessage id="booking.history.type.consultation.chat.label" /></IconItem>}
                                        {typeConsultation === 'VIDEO' &&
                                        <IconItem name="webcam-green"><FormattedMessage id="booking.history.type.consultation.video.label" /></IconItem>}
                                        {typeConsultation === 'AUDIO' &&
                                        <IconItem name="audio-green"><FormattedMessage id="booking.history.type.consultation.audio.label" /></IconItem>}
                                    </GridCol>
                                    <GridCol count="1">
                                        <label className="field-label label-fixed"><FormattedMessage id="core.symptoms.label" /></label>
                                        {symptoms &&
                                        <Paragraph>
                                            {symptoms.map(symptom => symptom.commonName).join(", ")}
                                        </Paragraph>
                                        }
                                    </GridCol>
                                </div>
                            </div>
                        </GridCol>
                    );
                })}

                <div className="col-12">
                {size < totalElements && <Pagination
                    activeLinkClass={"active"}
                    activePage={number + 1}
                    itemsCountPerPage={size}
                    totalItemsCount={totalElements}
                    pageRangeDisplayed={5}
                    onChange={this.onPagination}/>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchHistories: (pageNumber, patient) => dispatch(fetchHistories(pageNumber, patient)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onSaveDescription: (consultation) => dispatch(saveDescription(consultation))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailHistory);
