import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../../components/collections/grid";
import {withRouter} from 'react-router-dom';
import {formSimpleChange} from "../../../../services/system/formService";
import classnames from "classnames";

import {Icon} from "../../../../../components/elements/icon";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import {Option, Select} from "../../../../../components/elements/select";
import {fetchCountries} from "../../../../services/commonService";
import {fetchPaymentProviders} from "../../../../services/paymentService";
import qs from "query-string";
import {pay} from "../../../../services/bookingPaymentService";
import {modalHide, modalShow} from "../../../../services/system/modalService";

const PaymentProvider = (props) => {
    const {paymentProvider, onChange, isChecked} = props;
    const {code} = paymentProvider
    return (
        <label className="label-simple-check">
            <div className="row align-items-center">
                <div className="col">
                    {<Icon name={'payment-' + code.toLowerCase()}/>}
                </div>
            </div>
            <input type="radio"
                   name="paymentMethod.code"
                   className={classnames({'checked': isChecked})}
                   value={code}
                   onChange={onChange}/>
            <span className="position-static"></span>
        </label>
    );
};

class ChoosePaymentProvider extends Component {
    constructor(props) {
        super(props);
        this.onSelectCountry = this.onSelectCountry.bind(this);
        this.onChoosePaymentProvider = this.onChoosePaymentProvider.bind(this);
    }

    componentDidMount() {

    }

    onChoosePaymentProvider(paymentProvider, event) {
        this.props.onFormSimpleChangeField("pay", "paymentProvider", paymentProvider);
    }

    onSelectCountry(country) {
        this.props.onFormSimpleChangeField("pay", "country", country);
        this.props.onFetchPaymentProviders(country.iso);
        this.props.onFormSimpleChangeField("pay", "paymentProvider", null);
    }

    render() {
        const {form: {data: formData}, list: {data: listData}} = this.props;
        const {paymentProviders = [], countries = []} = listData;
        const {pay = {}} = formData;
        const {paymentProvider = null, country = null} = pay;
        return (
            <EmptyTag>
                {countries.length > 1 &&
                <GridCol count='1'>
                    <Select name="country"
                            label={<FormattedMessage id="country"/>}
                            optionNameSelected={(country && country["name"]) || 'undefined'}>
                        {countries.map(i => {
                            return (
                                <Option
                                    active={country && country["id"] === i.id}
                                    key={'country-' + i.id}
                                    onClick={() => this.onSelectCountry(i)}>
                                    {i.name}
                                </Option>
                            )
                        })}
                    </Select>
                </GridCol>}
                {paymentProviders.length > 1 &&
                <GridCol count='1'>
                    <div className="row cols-padding-bottom-15 align-center">
                        {paymentProviders
                            .map((i, index) => {
                                const {code} = i
                                return (
                                    <div className="col-md-4" key={'payment-provider-' + '-' + index + '-' + i.id}>
                                        <PaymentProvider onChange={(event) => this.onChoosePaymentProvider(i, event)}
                                                         paymentProvider={i}
                                                         isChecked={paymentProvider && paymentProvider.code === code}/>

                                    </div>
                                );
                            })}
                    </div>
                </GridCol>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchPaymentProviders: (countryIso) => dispatch(fetchPaymentProviders(countryIso)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChoosePaymentProvider));