import moment from "moment";
import {ScheduleDetail} from "./ScheduleDetail";
import React from "react";
import classnames from "classnames";

export const ScheduleItem = (props) => {
    const {itemKey, item, detailScheduleKey, styleCell = null} = props;
    const {timeOfReceipt, endTimeOfReceipt, appointmentTemplate, color, booking} = item;
    const {payment, typeConsultation = ""} = booking || {};

    const isOffline = typeConsultation === 'OFFLINE';
    const isChat = typeConsultation === 'CHAT';
    const isVideo = typeConsultation === 'VIDEO';

    let colorCode = '';
    if (color) colorCode = color.code;
    if (appointmentTemplate) colorCode = appointmentTemplate.color.code;

    const classnameColor = classnames('color-template-item', 'color-template-' + colorCode);
    const {address = null} = appointmentTemplate || {};

    const classnameWrapper = classnames('c-g-event', {
        'active': detailScheduleKey == itemKey
    });

    return (
        <div className={classnameWrapper} style={styleCell}>
            <div className={classnameColor} onClick={props.onDetail}>
                <div className="row c-t-item-content-row">
                    <div className="col c-t-item-text">
                        <div>
                            {moment(timeOfReceipt).format("LT")}&nbsp;-&nbsp;
                            {moment(endTimeOfReceipt).format("LT")}
                        </div>
                        {payment && <div>
                            {payment.currency}&nbsp;
                            {payment.amount && parseFloat(payment.amount).toFixed(2)}
                        </div>}
                        {isOffline && address && <div>{address.text}</div>}
                    </div>
                    {typeConsultation &&
                    <div className="col-auto c-t-item-types">
                        {isVideo &&
                        <i className="icon icon--circle-event-online"></i>}
                        {isChat &&
                        <i className="icon icon--circle-event-chat"></i>}
                        {isOffline &&
                        <i className="icon icon--circle-event-offline"></i>}
                    </div>}
                </div>
            </div>
            {detailScheduleKey == itemKey &&
            <ScheduleDetail schedule={item}
                            onCloseDetail={props.onCloseDetail}
                            onRemoveSchedule={props.onRemoveSchedule}/>}
        </div>
    )
}