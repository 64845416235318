import {fetchBookings, fetchScheduleByDate} from "./bookingService";
import moment from "moment";
import {formInitAction} from "../../actions/formAction";

export const updateScheduleForAssistant = (doctorId) => {
    return (dispatch, state) => {
        const {data: formData = null} = state().form;
        const {filters: listFilter = null} = state().list;
        const {chooseDoctor = null, scheduleCalendar = null} = formData || {};
        const {bookings: bookingsFilter} = listFilter || {};
        const {dateOfReceipt = null} = bookingsFilter || {};
        if (chooseDoctor && parseInt(chooseDoctor["id"]) === parseInt(doctorId)) {
            dispatch(fetchBookings(1, 'bookings', false));

            if (scheduleCalendar) {
                if (dateOfReceipt)
                    dispatch(fetchScheduleByDate({id: parseInt(doctorId)}, moment(parseInt(dateOfReceipt)).format("YYYY-MM-DD")));
                dispatch(formInitAction("scheduleCalendar", {
                    ...scheduleCalendar,
                    isPreloader: false,
                    isReload: true
                }));
            }
        }
    }
};