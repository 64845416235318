import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {modalShow} from "../../../../services/system/modalService";
import {Button} from "../../../../../components/elements/button";
import {Avatar} from "../../../../../components/collections/avatar";
import moment from "moment";
import CountDownTimer from "../../../../systems/countDownTimer";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

class PayWidget extends Component {
    constructor(props) {
        super(props);
        this.onPaymentExpired = this.onPaymentExpired.bind(this);
        this.onPay = this.onPay.bind(this);
        this.state = {
            isPaymentExpired: false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData = {}}} = this.props;
        const {form: {data: prevFormData = {}}} = prevProps;

        const {booking: curBooking = {}} = curFormData;
        const {payment: curPayment = null} = curBooking;

        const {booking: prevBooking = {}} = prevFormData;
        const {payment: prevPayment = null} = prevBooking;

        if (curPayment != prevPayment) {
            const {paymentStatus, paymentExpireAt} = curPayment;
            if (curBooking.status === 'REQUESTED' && paymentStatus === 'PENDING' && moment(paymentExpireAt).isAfter(moment())) {
                /*const {booking, callbackAfterPay = null} = this.props;
                this.props.onModalShow("pay", {
                    booking: booking,
                    callbackAfterPay: callbackAfterPay
                });*/
               // this.onPay();
            }
        }
    }

    onPaymentExpired() {
        this.setState({isPaymentExpired: true});
    }

    onPay() {
        const {booking, callbackAfterPay = null, auth: {user}} = this.props;
        const {familyMember = null} = user || {};
        if (familyMember != null) {
            this.props.onModalShow("askParentPay", {
                booking: booking,
                callbackAfterPay: callbackAfterPay
            });
        } else {
            this.props.onModalShow("pay", {
                booking: booking,
                callbackAfterPay: callbackAfterPay
            });
        }
    }

    render() {
        const {booking, isShowButtonDetail = false, auth: {user}} = this.props;
        const {payment, timeOfReceipt, doctor = {}, status = "", patient = {}} = booking;
        const {familyMember = null} = patient;
        const {paymentExpireAt = null, paymentStatus = ""} = payment || {};
        const disabled = this.state.isPaymentExpired || moment(paymentExpireAt).isBefore(moment());
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        return (
            <EmptyTag>
                {paymentStatus === 'PENDING' && status === 'REQUESTED' &&
                <div className="white-block padding-0">
                    <div className="row form-elements form-padding-20-30 display-table">
                        <div className="col-md-4 div-bg bg-color-red">
                            <p className="small-text">
                                {disabled && <FormattedMessage id="widget.pay.payment.expired.true"/>}
                                {!disabled && <FormattedMessage id="widget.pay.payment.expired.false"/>}
                            </p>
                            {paymentExpireAt && <CountDownTimer icon="clock-white-red" startTime={paymentExpireAt}
                                                                onFinished={() => this.onPaymentExpired()}/>}
                        </div>
                        <div className="col">
                            {!user.familyMember && familyMember &&
                            <div className="margin-bottom-10">
                                <FormattedMessage id="widget.pay.payment.patient"
                                                  values={{firstName: patient.firstName,
                                                      lastName: patient.lastName,
                                                      memberType: familyMember.memberType.name}}/>
                            </div>}
                            <div className="small-text">{moment(timeOfReceipt).format("lll")}</div>
                            <div className="row avatar-row align-items-center">
                                <div className="col-auto avatar-row__image">
                                    <Avatar/>
                                </div>
                                <div className="col avatar-row__text">
                                    <div><FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></div>
                                    <div className="small-text color-gray">
                                        {joinSpecializations}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="person-col-controls small-controls">
                                <Button disabled={disabled}
                                        onClick={this.onPay}><FormattedMessage id="core.btn.pay.label"/></Button>
                                {isShowButtonDetail &&
                                <Link to={"/bookings/" + booking.id + "/detail"} className="btn-default">
                                    <Button color="white-blue"><FormattedMessage id="core.btn.detail.label"/></Button>
                                </Link>}
                            </div>
                        </div>
                    </div>
                </div>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalShow: (name, data) => dispatch(modalShow(name, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayWidget);
