import React, {Component} from 'react';
import {connect} from "react-redux";

import {IconItem} from "../../../../components/elements/icon";
import {formChangeFieldAction} from "../../../../actions/formAction";
import {ButtonRemove} from "../../../../components/elements/button";
import _ from "lodash";
import {formInit} from "../../../services/system/formService";
import {FormattedMessage} from "react-intl";

class MedicalRecordDataItem extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField(event.target.name, event.target.value);
    }

    onRemove(recordItem) {
        const {form: {data}} = this.props;
        const {medicalRecord = {}} = data;
        _.unset(medicalRecord, recordItem);
        this.props.onFormInit(medicalRecord);
    }

    render() {
        const {form: {data}, dataItemType, onAdd} = this.props;
        const {medicalRecord = {}} = data;
        const {recordItems = []} = medicalRecord || {};
        return (
            <div className="row form-elements form-padding-15">
                {recordItems != null && recordItems.map((recordItem, index) => {
                    if (recordItem.type != dataItemType) return "";
                    return (
                        <div className="col-md-4" key={index}>
                            <div className="text-block">
                                <input type="text" placeholder="Allergy" name={'recordItems[' + index + '].name'}
                                       value={recordItem.name} onChange={this.onChange}/>
                                <label className="field-label"></label>
                                <div className="controls-top-right">
                                    <ButtonRemove onClick={() => this.onRemove('recordItems[' + index + ']')} />
                                </div>
                            </div>
                        </div>
                    )
                })}

                <div className="col-12">
                    <button type="button" className="btn-icon" onClick={onAdd}>
                        <IconItem name="plus-bordered"><FormattedMessage id="core.btn.add.label"/></IconItem>
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormInit: (data) => dispatch(formInit("medicalRecord", data)),
        onFormChangeField: (fieldName, fieldValue) => dispatch(formChangeFieldAction('medicalRecord', fieldName, fieldValue)),
        onFormSimpleChangeField: (fieldName, fieldValue) => dispatch(formChangeFieldAction('medicalRecord', fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecordDataItem);