import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {IconItem} from "../../../components/elements/icon";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {ButtonIcon} from "../../../components/elements/button";
import {AutocompleteAsync} from "../../systems/autocomplete";
import {fetchSpecializations} from "../../services/bookingService";
import {listFilterFieldAction} from "../../../actions/listAction";
import _ from "lodash";
import {InputError} from "../../../components/elements/error";

class ChooseSpecialization extends Component {
    constructor(props) {
        super(props);
        this.onAdd = this.onAdd.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onSearchSpecialization = this.onSearchSpecialization.bind(this);
        this.onSelectedSpecialization = this.onSelectedSpecialization.bind(this);
        this.state = {
            isNew: false
        }
    }

    componentDidMount() {
        const {choosed = []} = this.props;
        this.setState({...this.state, isNew: choosed.length == 0});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {choosed: curChoosed = []} = this.props;
        const {choosed: prevChoosed = []} = prevProps;
        const isEquals = _.isEqual(curChoosed, prevChoosed);

        if (!isEquals) {
            this.setState({...this.state, isNew: curChoosed.length == 0});
        }
    }

    onDelete(specialization) {
        if (specialization == null) {
            this.setState({...this.state, isNew: false});
        } else {
            const {choosed = []} = this.props;
            _.remove(choosed, i => i.id === specialization.id);
            if (choosed.length == 0) {
                this.onAdd();
            }
            this.props.onChoose(choosed);
        }
    }

    onSelectedSpecialization(event, value, reason, oldSpecializationId = null) {
        const {choosed = []} = this.props;
        if (value != null) {
            if (oldSpecializationId != null) {
                _.remove(choosed, i => i.id === oldSpecializationId);
            }

            if (choosed.findIndex(i => i.id === value.id) === -1) {
                choosed.push(value)
            }
            this.props.onChoose(choosed);
            this.setState({...this.state, isNew: false});
        }
    }

    onSearchSpecialization(event, query, reason) {
        this.props.onListFilterField("specializations", "searchText", query);
        this.props.onFetchSpecializations(1);
    }

    onAdd() {
        this.props.onListFilterField("specializations", "searchText", "");
        this.props.onFetchSpecializations(1);
        this.setState({...this.state, isNew: true});
    }

    onOpen(event) {
        this.props.onListFilterField("specializations", "searchText", "");
        this.props.onFetchSpecializations(1);
    }

    render() {
        const {list: {data: listData}, choosed = [], error = null, required = false} = this.props;
        const {specializations = []} = listData;
        const disabled = false;
        return (
            <GridCol count="1">
                <GridRow>
                    {choosed.map(i => {

                        return (
                            <GridCol count="2" key={i.id}>
                                <AutocompleteAsync onInputChange={this.onSearchSpecialization}
                                                   required={required}
                                                   getOptionLabel={option => option.name || ''}
                                                   onChange={(event, value, reason) => this.onSelectedSpecialization(event, value, reason, i.id)}
                                                   onOpen={this.onOpen}
                                                   onDelete={() => this.onDelete(i)}
                                                   isCanDelete={true}
                                                   value={i || null}
                                                   placeholder={<FormattedMessage id="core.specialization.label"/>}
                                                   placeholderInside={true}
                                                   placeholderFixed={true}
                                                   disabled={disabled}
                                                   disableClearable={true}
                                                   error={null}
                                                   options={specializations}/>

                            </GridCol>
                        )
                    })}

                    {this.state.isNew &&
                    <GridCol count="2">
                        <AutocompleteAsync onInputChange={this.onSearchSpecialization}
                                           required={required}
                                           getOptionLabel={option => option.name || ''}
                                           onChange={(event, value, reason) => this.onSelectedSpecialization(event, value, reason)}
                                           onOpen={this.onOpen}
                                           onDelete={() => this.onDelete(null)}
                                           isCanDelete={choosed.length > 0}
                                           value={null}
                                           placeholder={<FormattedMessage id="core.specialization.label"/>}
                                           placeholderInside={true}
                                           placeholderFixed={true}
                                           disabled={disabled}
                                           disableClearable={true}
                                           error={null}
                                           options={specializations}/>

                    </GridCol>}

                    {error && <GridCol count="1">
                        <InputError>{error}</InputError>
                    </GridCol>}

                    <GridCol count="1" align={"left"}>
                        <ButtonIcon color={'blue'} onClick={this.onAdd}>
                            <IconItem name="plus-bordered"><FormattedMessage id="core.btn.add.label"/></IconItem>
                        </ButtonIcon>
                        <div className="field-tip color-blue">
                            <FormattedMessage id="description.chose.specialization"/></div>
                    </GridCol>
                </GridRow>
            </GridCol>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list,
        language: store.language
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onFetchSpecializations: (pageNumber, listName) => dispatch(fetchSpecializations(pageNumber, listName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChooseSpecialization);