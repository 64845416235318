import React, {Component} from 'react';
import {connect} from "react-redux";

import BookingDetailChat from "../../doctor/bookings/detail/BookingDetailChat";

class PatientBookingDetailChat extends Component {
    render() {
        return (
            <BookingDetailChat/>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBookingDetailChat);
