import {PAGIN_SET_NUMBER_PAGE, PAGIN_SET_PAGE_SIZE, PAGIN_SET_TOTAL_COUNT} from "../constants/actionTypes";

const initialState = {
    data: {}
};

const paginReducer = (state = initialState, action) => {
    let specificData = {};
    switch (action.type) {
        case PAGIN_SET_NUMBER_PAGE:
            specificData = {...state.data[action.paginName], numberPage: parseInt(action.numberPage) + 1};
            return {...state, data: {...state.data, [action.paginName]: specificData}};
        case PAGIN_SET_PAGE_SIZE:
            specificData = {...state.data[action.paginName], pageSize: parseInt(action.pageSize)};
            return {...state, data: {...state.data, [action.paginName]: specificData}};
        case PAGIN_SET_TOTAL_COUNT:
            specificData = {...state.data[action.paginName], totalCount: parseInt(action.totalCount)};
            return {...state, data: {...state.data, [action.paginName]: specificData}};
        default:
            return state;
    }
}

export default paginReducer;