import React, {Component} from 'react';
import {connect} from "react-redux";
import {BackTo} from "../../../../../components/elements/backTo";
import {
    approveBooking,
    completeBooking,
    fetchBooking,
    rejectBooking
} from "../../../../services/bookingService";
import {Header} from "../../../../../components/elements/header";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {tabChangePanelAction} from "../../../../../actions/tabAction";
import BookingDetailDescription from "./BookingDetailDescription";
import BookingDetailAdvice from "./BookingDetailAdvice";
import BookingDetailPrescription from "./BookingDetailPrescription";
import BookingDetailNote from "./BookingDetailNote";
import {modalHide, modalShow} from "../../../../services/system/modalService";
import {startChatConsultation} from "../../../../services/consultationService";
import {formInitAction} from "../../../../../actions/formAction";
import {noticeShowAction} from "../../../../../actions/noticeAction";
import {refund} from "../../../../services/bookingPaymentService";
import moment from "moment";
import {FormattedMessage} from "react-intl";

class BookingDetailPrint extends Component {
    componentDidMount() {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};
        this.props.onFetchBooking(id);
    }

    componentWillUnmount() {
        this.props.onInitForm("booking", {});
        this.props.onInitForm("consultation", {});
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {booking = {}} = formData;
        const {consultation = {}, timeOfReceipt, typeConsultation, doctor, patient} = booking;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={"/doctor/bookings"}/>
                    </div>

                    <div className="col content-heading__main">
                        <Header as="h1"><FormattedMessage id="booking.detail.print.header"/></Header>
                        <Header as="h2">
                            {doctor && <EmptyTag><FormattedMessage id="booking.detail.print.doctor" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/><br/></EmptyTag>}
                            {patient && <EmptyTag><FormattedMessage id="booking.detail.print.patient" values={{firstName: patient.firstName, lastName: patient.lastName}}/><br/></EmptyTag>}
                            {timeOfReceipt && <EmptyTag><FormattedMessage id="core.datetime.label"/>{moment(timeOfReceipt).format("lll")}<br/></EmptyTag>}
                            {typeConsultation && <EmptyTag><FormattedMessage id="core.type.consultation.label"/>
                                {typeConsultation === 'CHAT' && <FormattedMessage id="core.type.consultation.chat.label"/>}
                                {typeConsultation === 'VIDEO' && <FormattedMessage id="core.type.consultation.video.label"/>}
                                {typeConsultation === 'AUDIO' && <FormattedMessage id="core.type.consultation.audio.label"/>}
                                {typeConsultation === 'OFFLINE' && <FormattedMessage id="core.type.consultation.offline.label"/>}
                            </EmptyTag>}
                        </Header>
                    </div>
                </div>

                {booking && Object.keys(booking).length > 0 && <BookingDetailDescription print/>}
                {consultation && Object.keys(consultation).length > 0 && <BookingDetailPrescription print/>}
                {consultation && Object.keys(consultation).length > 0 && <BookingDetailAdvice print/>}
                {consultation && Object.keys(consultation).length > 0 && <BookingDetailNote print/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        tab: store.tab,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("booking", tabPanelName)),
        onFetchBooking: (id) => dispatch(fetchBooking(id)),
        onApproveBooking: (booking) => dispatch(approveBooking(booking)),
        onRejectBooking: (booking) => dispatch(rejectBooking(booking)),
        onStartChatConsultation: (booking) => dispatch(startChatConsultation(booking)),
        onCompleteBooking: (booking) => dispatch(completeBooking(booking)),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onRefund: (booking) => dispatch(refund(booking))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailPrint);
