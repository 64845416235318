import {TAB_CHANGE_PANEL, TAB_CLEAR, TAB_FETCH} from "../constants/actionTypes";

const initialState = {
    data: {}
};

const tabReducer = (state = initialState, action) => {
    let tabPanel = {};
    let data = {};
    switch (action.type) {
        case TAB_CLEAR:
            data = {...state.data, [action.tabName]: {}};
            return {...state, data: data}
        case TAB_FETCH:
            tabPanel = {...state.data[action.tabName], [action.tabPanelName]: action.data, activeTabPanel: action.tabPanelName};
            data = {...state.data, [action.tabName]: tabPanel};
            return {...state, data: data};
        case TAB_CHANGE_PANEL:
            tabPanel = {...state.data[action.tabName], activeTabPanel: action.tabPanelName};
            data = {...state.data, [action.tabName]: tabPanel};
            return {...state, data: data};
        default:
            return state;
    }
}

export default tabReducer;