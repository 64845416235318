import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {convertHeightToUnitSystem, convertWeightToUnitSystem, floatFormatting} from "../../utils/app";
import {privateApi, privateBlobApi} from '../../utils/api';
import {formErrors, formIsSend} from "./system/formService";
import {noticeHideAction, noticeShowAction} from "../../actions/noticeAction";
import {formInitAction} from "../../actions/formAction";
import React from "react";
import {Link} from "react-router-dom";
import {saveAs} from 'file-saver';
import {FormattedMessage} from "react-intl";
import {UNIT_SYSTEM} from "../../constants/system";

export const fetchPatientMedicalRecord = (patient) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        dispatch(preloaderPageShowAction());
        return privateApi.get("/patients/" + patient.id + "/medicalRecord")
            .then(response => {
                const {data} = response;

                if (data.weight) {
                    data.weight = user.unitSystem != UNIT_SYSTEM.METRIC ?
                        convertWeightToUnitSystem(data.weight, user.unitSystem) : floatFormatting(data.weight);
                }

                if (data.height) {
                    data.height = user.unitSystem != UNIT_SYSTEM.METRIC ?
                        convertHeightToUnitSystem(data.height, user.unitSystem) : floatFormatting(data.height)
                }

                dispatch(formInitAction("medicalRecord", data));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const saveMedicalRecord = (patient, medicalRecord) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("medicalRecord", true));

        let updateMedicalRecord = {...{}, ...medicalRecord};
        if (user.unitSystem != UNIT_SYSTEM.METRIC) {
            if (medicalRecord.weight) {
                updateMedicalRecord = {
                    ...updateMedicalRecord,
                    weight: convertWeightToUnitSystem(medicalRecord.weight, UNIT_SYSTEM.METRIC)
                };
            }

            if (medicalRecord.height) {
                updateMedicalRecord = {
                    ...updateMedicalRecord,
                    height: convertHeightToUnitSystem(medicalRecord.height, UNIT_SYSTEM.METRIC)
                };
            }
        }

        return privateApi.post("/patients/" + patient.id + "/medicalRecord", updateMedicalRecord)
            .then(response => {
                dispatch(formIsSend("medicalRecord", false));
                dispatch(fetchPatientMedicalRecord(patient));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("medicalRecord", error.response.data.details));
                }
                dispatch(formIsSend("medicalRecord", false));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const isFillPatientProfile = () => {
    return (dispatch, state) => {
        const auth = state().auth;
        const {role, isFillProfile} = auth.user;

        if (!isFillProfile && role === 'PATIENT') {
            const textMsg = <FormattedMessage id="not.fill.patient.profile.message.text"
                                              values={{
                                                  appName: window._env_.NAME,
                                                  link: <Link to={"/user/profile"}
                                                              onClick={() => dispatch(noticeHideAction())}>
                                                      <FormattedMessage
                                                          id="not.fill.patient.profile.your.profile.text"/></Link>
                                              }}/>
            return dispatch(noticeShowAction("", textMsg));
        }
    }
};

export const downloadPathologicalReportFile = (medicalRecord, pathologicalReport) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateBlobApi.get("/patients/" + medicalRecord.patient.id + "/medicalRecord/pathologicalReports/" + pathologicalReport.id + "/downloadFile", {responseType: "blob"})
            .then(response => {
                saveAs(response.data, pathologicalReport.file.name);
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};
