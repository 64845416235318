import {NOTICE_SHOW, NOTICE_HIDE} from "../constants/actionTypes";
import {NOTICE_AREA} from "../constants/system";

const initialState = {
    header: null,
    body: null

};

const noticeReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTICE_SHOW:
            switch (action.area) {
                case NOTICE_AREA.HEADER :
                    return {...state, header: {title: action.title, textMsg: action.textMsg, status: action.status}};
                case NOTICE_AREA.BODY :
                    return {...state, body: {title: action.title, textMsg: action.textMsg, status: action.status}};
                default:
                    return state;
            }
        case NOTICE_HIDE:
            switch (action.area) {
                case NOTICE_AREA.HEADER :
                    return {...state, header: null};
                case NOTICE_AREA.BODY :
                    return {...state, body: null};
                default:
                    return state;
            }
        default:
            return state;
    }
};

export default noticeReducer;