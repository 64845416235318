import React from 'react';

export const PushNotification = (props) => {
    const {title, body} = props;
    return (
        <div className="n-parent">
            <div className="notification-item n-child">
                <div className="notify-container">
                    <div className="row notify-row align-items-center">
                        <div className="col notify-row__text">
                            <h2 className="color-red">{title}</h2>
                            <div className="middle-text">{body}</div>
                            <div className="small-text"></div>
                        </div>
                        <div className="col-auto notify-row__controls">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
