import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../../components/elements/header";
import {Paragraph, WhiteBlock} from "../../../../../components/elements/paragraph";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {saveNote} from "../../../../services/consultationService";
import {formChangeField} from "../../../../services/system/formService";
import {Textarea} from "../../../../../components/elements/textarea";
import {Button, Buttons} from "../../../../../components/elements/button";
import {FormattedMessage} from "react-intl";

class BookingDetailNote extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('consultation', event);
    }

    render() {
        const {form: {data}, auth: {user}, print = false} = this.props;
        const {booking: {status, doctor = null}, consultation = {}} = data;
        const {note = ""} = consultation;
        const isBookingDoctor = doctor && user && doctor.id == user.id;
        return (
            <WhiteBlock>
                <GridRow>
                    <GridCol count="1">
                        <Header as="h3"><FormattedMessage id="booking.tab.panel.header.notes" /></Header>
                    </GridCol>
                    <GridCol count="1">
                        {(status === 'COMPLETED' || !user.isEnabled || !isBookingDoctor || print) &&
                        <Paragraph>{note || <FormattedMessage id="booking.tab.panel.note.empty" />}</Paragraph>}

                        {status === 'APPROVED' && user.isEnabled && isBookingDoctor && !print &&
                        <Textarea placeholder={<FormattedMessage id="booking.tab.panel.label.note" />}
                                  onChange={this.onChange}
                                  value={note || ''}
                                  name="note"/>}
                        {status === 'APPROVED' && user.isEnabled && isBookingDoctor &&
                        <Buttons>
                            <Button onClick={() => this.props.onSaveNote(consultation)}><FormattedMessage id="core.btn.save.label" /></Button>
                        </Buttons>}
                    </GridCol>
                </GridRow>
            </WhiteBlock>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onSaveNote: (consultation) => dispatch(saveNote(consultation))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailNote);
