import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../../components/modules/modal";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {Button, Buttons} from "../../../../../components/elements/button";
import {modalHide, modalShow} from "../../../../services/system/modalService";
import {Link, withRouter} from 'react-router-dom';
import {Header} from "../../../../../components/elements/header";
import {askPayment} from "../../../../services/bookingPaymentService";
import {FormattedMessage} from "react-intl";


class AskParentPay extends Component {
    constructor(props) {
        super(props);
        this.onYes = this.onYes.bind(this);
        this.onNo = this.onNo.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    onYes() {
        const {form: {data: formData}} = this.props;
        const {askParentPay = {}} = formData;
        if (askParentPay.booking)
            this.props.onAskPayment(askParentPay.booking)
    }

    onNo() {
        const {form: {data: formData}} = this.props;
        const {askParentPay = {}} = formData;
        this.props.onModalHide('askParentPay');
        this.props.onModalShow("pay", {
            booking: askParentPay.booking,
            callbackAfterPay: askParentPay.callbackAfterPay
        });
    }

    render() {
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('askParentPay')} width={800}>
                <Header as="h2"><FormattedMessage id="confirm.ask.parent.to.pay"/></Header>

                <div className="block-center max-width-500">
                    <GridRow>
                        <GridCol count="1">
                            <Buttons width="auto">
                                <Link to="#" onClick={() => this.onNo()}>
                                    <Button color='white-red'><FormattedMessage id="core.btn.no.label"/></Button>
                                </Link>
                                <Button onClick={() => this.onYes()}><FormattedMessage id="core.btn.yes.label"/></Button>
                            </Buttons>
                        </GridCol>
                    </GridRow>
                </div>
            </Modal>
        )
    }
}



const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalShow: (name, data) => dispatch(modalShow(name, data)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onAskPayment: (booking) => dispatch(askPayment(booking)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AskParentPay));