import React, {Component} from 'react';
import {connect} from "react-redux";
import {Paragraph, WhiteBlock} from "../../../../../components/elements/paragraph";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {Header} from "../../../../../components/elements/header";
import {FormattedMessage} from "react-intl";

class DetailAdvice extends Component {
    render() {
        const {form: {data}} = this.props;
        const {booking: {consultation = {}}} = data;
        const {description = ""} = consultation;
        return (
            <WhiteBlock>
                <GridRow>
                    <GridCol count="1">
                        <Header as="h3"><FormattedMessage id="core.medical.advice.label"/></Header>
                    </GridCol>
                    <GridCol count="1">
                        <Paragraph>{description || ""}</Paragraph>
                    </GridCol>
                </GridRow>
            </WhiteBlock>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailAdvice);
