import React, {Component} from 'react';
import {connect} from "react-redux";

import {verifyNewEmail} from "../../services/userService";
import {changeLanguage} from "../../services/commonService";
import qs from "query-string";
import {EmptyTag} from "../../../components/elements/emptyTag";

class VerifyNewEmail extends Component {
    componentDidMount() {
        const {match: {params}} = this.props;
        const p = this.props.location.search;
        const {language = ""} = qs.parse(p);
        const {token = ""} = params || {}
        this.props.onChangeLanguage(language);
        this.props.onVerifyNewEmail(token);
    }

    render() {
        return (
            <EmptyTag>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onVerifyNewEmail: (token) => dispatch(verifyNewEmail(token)),
        onChangeLanguage : (language) => dispatch(changeLanguage(language)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyNewEmail);