import React, {Component} from 'react';
import {connect} from "react-redux";
import Pagination from "react-js-pagination";

import {FormattedMessage} from "react-intl";
import {
    approvePriceRequest, cancelPriceRequest,
    fetchPriceRequests,
} from "../../../services/doctorService";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {Icon} from "../../../../components/elements/icon";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Header} from "../../../../components/elements/header";
import Dropdown, {DropdownItem} from "../../../systems/dropdown";
import {modalShow} from "../../../services/system/modalService";

class ConsultationsRates extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.props.onListSorts({id: "desc"});
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchPriceRequests(pageNumber);
    }

    componentWillUnmount() {
        this.props.onInitList("priceRequests");
    }

    render() {
        const {list: {data: listData, pagination: listPagination}} = this.props;
        const {priceRequests = []} = listData;
        const {number = 0, size = 0, totalElements = 0} = listPagination['priceRequests'] || {};
        const dropDownButton = <div>
            <Icon name="menu-points"/>
        </div>;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.admin.consultations.rates.header"/></Header>
                    </div>
                </div>

                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><FormattedMessage id="core.doctor.name.label"/></th>
                            <th><FormattedMessage id="allotted.time.ore.consultation"/></th>
                            <th><FormattedMessage id="core.type.consultation.chat.label"/></th>
                            <th><FormattedMessage id="core.type.consultation.online.label"/></th>
                            <th><FormattedMessage id="core.type.consultation.offline.label"/></th>
                            <th><FormattedMessage id="core.previous.rates.label"/></th>
                            <th><FormattedMessage id="core.new.rate.label"/></th>
                            <th><FormattedMessage id="core.status.label"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {priceRequests.map(priceRequest => {
                            const {id, status, price: newPrice, appointmentTemplate} = priceRequest;
                            const {
                                duration,
                                price: oldPrice,
                                doctor: {lastName, firstName},
                                appointmentTemplateTypes = []
                            } = appointmentTemplate;
                            const isChat = appointmentTemplateTypes.findIndex(i => i.typeConsultation == 'CHAT') !== -1;
                            const isVideo = appointmentTemplateTypes.findIndex(i => i.typeConsultation == 'VIDEO') !== -1;
                            const isOffline = appointmentTemplateTypes.findIndex(i => i.typeConsultation == 'OFFLINE') !== -1;
                            return (
                                <tr key={id}>
                                    <td className="td-controls">
                                        {status === 'REQUESTED' &&
                                        <Dropdown button={dropDownButton} noArrow="true">
                                            <DropdownItem to={"#"} icon="approve"
                                                          onClick={() => this.props.onConfirm({
                                                              text: <FormattedMessage
                                                                  id="confirm.price.consultation.approved.text"/>,
                                                              onProceed: (() => this.props.onApprovePriceRequest(id))
                                                          })}>
                                                <FormattedMessage
                                                    id="request.update.price.consultation.status.approved.label"/>
                                            </DropdownItem>
                                            <DropdownItem to={"#"} icon="delete"
                                                          onClick={() => this.props.onConfirm({
                                                              text: <FormattedMessage
                                                                  id="confirm.price.consultation.canceled.text"/>,
                                                              onProceed: (() => this.props.onCancelPriceRequest(id))
                                                          })}>
                                                <FormattedMessage
                                                    id="request.update.price.consultation.status.canceled.label"/>
                                            </DropdownItem>
                                        </Dropdown>}
                                    </td>
                                    <td>{firstName} {lastName}</td>
                                    <td>{duration}</td>
                                    <td>{isChat ? <FormattedMessage id="yes"/> : <FormattedMessage id="no"/>}</td>
                                    <td>{isVideo ? <FormattedMessage id="yes"/> : <FormattedMessage id="no"/>}</td>
                                    <td>{isOffline ? <FormattedMessage id="yes"/> : <FormattedMessage id="no"/>}</td>
                                    <td>{oldPrice}</td>
                                    <td>{newPrice}</td>
                                    <td>
                                        {status === 'REQUESTED' &&
                                        <div className="color-orange">
                                            <FormattedMessage
                                                id="request.update.price.consultation.status.requested.label"/>
                                        </div>}
                                        {status === 'APPROVED' &&
                                        <div className="color-green">
                                            <FormattedMessage
                                                id="request.update.price.consultation.status.approved.label"/>
                                        </div>}
                                        {status === 'CANCELED' &&
                                        <div className="color-red">
                                            <FormattedMessage
                                                id="request.update.price.consultation.status.canceled.label"/>
                                        </div>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchPriceRequests: (pageNumber) => dispatch(fetchPriceRequests(pageNumber)),
        onApprovePriceRequest: (id) => dispatch(approvePriceRequest(id)),
        onCancelPriceRequest: (id) => dispatch(cancelPriceRequest(id)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("priceRequests", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("priceRequests", sorts)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsRates);