import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../../../../components/elements/emptyTag";
import classnames from "classnames";
import moment from "moment";
import {getPaymentStatusName} from "../../../../../services/bookingPaymentService";
import {Header} from "../../../../../../components/elements/header";
import {FormattedMessage} from "react-intl";
import {PaymentProvider} from "../../../../../../components/collections/payment_status";

class PaymentDetail extends Component {

    render() {
        const {payment} = this.props;
        const {paymentStatus = null} = payment || {};
        const paymentStatusColor = classnames({
            'color-green': paymentStatus == 'COMPLETED' || paymentStatus == 'NOT_VERIFY',
            'color-blue': paymentStatus == 'REFUND',
            'color-orange': paymentStatus == 'PENDING',
            'color-red': paymentStatus == 'DENIED'
        });
        return (
            <EmptyTag>
                <div className="white-block">
                    <Header as="h3" align="left"><FormattedMessage id="booking.payment.detail.header"/></Header>
                    <table className="simple-table">
                        <tbody>
                        <tr>
                            <td className="color-gray small-text"><FormattedMessage
                                id="booking.payment.detail.payment.status.label"/></td>
                            <td className="align-right">
                                <div className={paymentStatusColor}>{getPaymentStatusName(paymentStatus) ||
                                <FormattedMessage id="core.none.label"/>}</div>
                            </td>
                        </tr>
                        {paymentStatus && paymentStatus != 'PENDING' && paymentStatus != 'DENIED' &&
                        <EmptyTag>
                            {payment.paymentProviderCode &&
                            <tr>
                                <td className="color-gray small-text"><FormattedMessage
                                    id="booking.payment.detail.pay.types.label"/></td>
                                <td className="align-right">
                                    <div>
                                        <PaymentProvider payment={payment}/>
                                    </div>
                                </td>
                            </tr>}{/*
                            {payment.paymentReferenceNumber &&
                            <tr>
                                <td className="color-gray small-text"><FormattedMessage
                                    id="booking.payment.detail.transaction.number.label"/></td>
                                <td className="align-right">
                                    <div>{payment.paymentReferenceNumber}</div>
                                </td>
                            </tr>}*/}
                            {payment.paymentProviderCode == 'HEALTH_INSURANCE' &&
                                <tr>
                                    <td className="color-gray small-text"><FormattedMessage
                                        id="health.insurance.number.label"/></td>
                                    <td className="align-right">
                                        <div>{payment.healthInsurancePayment.healthInsuranceNumber}</div>
                                    </td>
                                </tr>}
                            {payment.paymentPhoneNumber && payment.paymentProviderCode == 'HEALTH_INSURANCE' &&
                            <tr>
                                <td className="color-gray small-text"><FormattedMessage
                                    id="payment.mobile.number"/></td>
                                <td className="align-right">
                                    <div>{payment.paymentPhoneNumber}</div>
                                </td>
                            </tr>}
                            <tr>
                                <td className="color-gray small-text"><FormattedMessage
                                    id="booking.payment.detail.date.label"/></td>
                                <td className="align-right">
                                    <div>{payment.paymentCompletedAt && moment(payment.paymentCompletedAt).format("ll") ||
                                    <FormattedMessage id="core.none.label"/>}</div>
                                </td>
                            </tr>
                            <tr className="font-size-20 tr-top-bottom-border">
                                <td className="color-gray small-text"><FormattedMessage
                                    id="booking.payment.detail.total.label"/></td>
                                <td className="align-right">
                                    <div>
                                        {payment.currency} {payment.amount && parseFloat(payment.amount).toFixed(2)}
                                    </div>
                                </td>
                            </tr>
                        </EmptyTag>
                        }
                        </tbody>
                    </table>

                    {paymentStatus && paymentStatus == 'REFUND' &&
                    <EmptyTag>
                        <Header as="h3" align="left"><FormattedMessage id="booking.refund.detail.header"/></Header>
                        <table className="simple-table">
                            <tbody>
                            <tr>
                                <td className="color-gray small-text"><FormattedMessage
                                    id="booking.refund.detail.date.label"/></td>
                                <td className="align-right">
                                    <div>
                                        {(payment.refundCompletedAt &&
                                        moment(payment.refundCompletedAt).format("ll")) ||
                                        <FormattedMessage id="core.none.label"/>}
                                    </div>
                                </td>
                            </tr>
                            {/*<tr>
                                <td className="color-gray small-text"><FormattedMessage
                                    id="booking.refund.detail.refund.reference.number.label"/></td>
                                <td className="align-right">
                                    <div>{payment.refundTransactionNumber}</div>
                                </td>
                            </tr>*/}
                            </tbody>
                        </table>
                    </EmptyTag>}
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);
