import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../../components/modules/modal";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {Button} from "../../../../../components/elements/button";
import {modalHide, modalShow} from "../../../../services/system/modalService";
import {withRouter} from 'react-router-dom';
import {Header} from "../../../../../components/elements/header";
import {formChangeField, formSimpleChange} from "../../../../services/system/formService";
import {askPayment, pay} from "../../../../services/bookingPaymentService";
import {FormattedMessage} from "react-intl";
import {noticeShowAction} from "../../../../../actions/noticeAction";
import PaymentSettings from "./PaymentSettings";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import ChoosePaymentProvider from "./ChoosePaymentProvider";
import {listInitAction} from "../../../../../actions/listAction";
import {fetchCountries} from "../../../../services/commonService";
import {fetchPaymentProviders} from "../../../../services/paymentService";

class Pay extends Component {
    constructor(props) {
        super(props);
        this.onSelectCountry = this.onSelectCountry.bind(this);
        this.onPay = this.onPay.bind(this);
        this.onContinue = this.onContinue.bind(this);
        this.onBack = this.onBack.bind(this);
        this.state = {step: 1, isShowPayModal: false}
    }

    componentDidMount() {
        this.props.onFetchCountries();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list: {data: curListData = {}}, form: {data: curFormData}} = this.props;
        const {list: {data: prevListData = {}}, form: {data: prevFormData}} = prevProps;

        const {countries: curCountries = [], paymentProviders: curPaymentProviders = []} = curListData;
        const {countries: prevCountries = [], paymentProviders: prevPaymentProviders = []} = prevListData;

        const {pay: curPay = {}} = curFormData;
        const {pay: prevPay = {}} = prevFormData;

        const {isShowPayModal = false} = this.state

        if (curCountries != prevCountries && curCountries && curCountries.length > 0) {
            if (curCountries.length === 1) {
                this.onSelectCountry(curCountries[0]);
            } else if (!isShowPayModal) {
                this.setState({...this.state, isShowPayModal: true});
            }
        }

        if (curPaymentProviders != prevPaymentProviders && curPaymentProviders && curPaymentProviders.length >0) {
            if (curPaymentProviders.length === 1) {
                const paymentProvider = curPaymentProviders[0];
                this.props.onFormSimpleChangeField("pay", "paymentProvider", paymentProvider);
            } else if (!isShowPayModal) {
                this.setState({...this.state, isShowPayModal: true});
            }
        }

        if (curPay != prevPay && Object.keys(curPay).length !== 0 && curPaymentProviders.length > 0) {
            if (curPaymentProviders.length === 1) {
                const {paymentProvider = null} = curPay || {};
                const isMobileMoney = paymentProvider != null &&
                    paymentProvider.paymentMethod && paymentProvider.paymentMethod.code === 'MOBILE_MONEY'
                const isHealthInsurance = paymentProvider && paymentProvider.code === "HEALTH_INSURANCE";

                if (!isMobileMoney && !isHealthInsurance) {
                    this.onPay();
                }
            } else if (!isShowPayModal) {
                this.setState({...this.state, isShowPayModal: true});
            }
        }
    }

    componentWillUnmount() {
        this.props.onInitList("paymentProviders");
        this.props.onInitList("countries");
    }

    onSelectCountry(country) {
        this.props.onFormSimpleChangeField("pay", "country", country);
        this.props.onFetchPaymentProviders(country.iso);
        this.props.onFormSimpleChangeField("pay", "paymentProvider", null);
    }

    onContinue() {
        const {form: {data: formData}} = this.props;
        const {
            paymentProvider = null,
            booking = null,
            callbackAfterPay = null
        } = formData.pay || {};
        if (paymentProvider && paymentProvider.code === "HEALTH_INSURANCE") {
            this.props.onModalHide('pay');

            if (booking.patient.nhsNumber == null || booking.patient.nhsNumber === "") {
                this.props.onNoticeShow(<FormattedMessage
                    id="health.insurance.payment.not.fill.insurance.number"/>);
            } else {
                this.props.onModalShow("healthInsurancePayment", {
                    booking: booking,
                    callbackAfterPay: callbackAfterPay,
                });
            }
        } else {
            const currentStep = this.state.step;
            this.setState({...this.state, step: currentStep + 1})
        }
    }

    onBack() {
        const currentStep = this.state.step;
        this.setState({...this.state, step: currentStep - 1})
    }

    onPay() {
        const {form: {data: formData}} = this.props;
        const {
            paymentProvider = null,
            country = null,
            phone = null,
            booking = null,
            callbackAfterPay
        } = formData.pay || {};
        const data = {
            paymentProviderCode: paymentProvider.code,
            countryIso: country.iso,
            customerPhone: phone,
        }
        this.props.onPay(booking, data, callbackAfterPay);
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {step, isShowPayModal = false} = this.state
        const {pay = {}} = formData;
        const {paymentProvider = null} = pay;
        const isMobileMoney = paymentProvider != null &&
            paymentProvider.paymentMethod && paymentProvider.paymentMethod.code === 'MOBILE_MONEY'
        const isHealthInsurance = paymentProvider && paymentProvider.code === "HEALTH_INSURANCE";
        const isContinue = step == 1 && (isMobileMoney || isHealthInsurance)

        if (!isShowPayModal) return ""
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('pay')} width={800}>
                <Header as="h2"><FormattedMessage id="payment"/></Header>
                {step === 1 &&
                    <Header as="h3"><FormattedMessage id="modal.choose.payment.methods.description.text"/></Header>}
                {step === 2 && <Header as="h3"><FormattedMessage id="mobile.money.payment.details"/></Header>}

                <div className="block-center max-width-500">
                    <GridRow>
                        {step === 1 && <ChoosePaymentProvider/>}
                        {step === 2 && isMobileMoney && <PaymentSettings/>}
                        <GridCol count='1'>
                            <div className="form-submit btns-full-width">
                                {step === 2 &&
                                    <Button onClick={this.onBack}>
                                        <FormattedMessage id="step.back"/>
                                    </Button>}

                                {isContinue &&
                                    <Button onClick={this.onContinue}>
                                        <FormattedMessage id="core.btn.continue.label"/>
                                    </Button>}

                                {((step === 1 && paymentProvider != null && !isMobileMoney && !isHealthInsurance) || (step === 2)) &&
                                    <EmptyTag>
                                        <Button onClick={this.onPay}><FormattedMessage
                                            id="core.btn.pay.label"/></Button>
                                    </EmptyTag>}
                            </div>
                        </GridCol>
                    </GridRow>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        modal: store.modal,
        form: store.form,
        auth: store.auth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchCountries: () => dispatch(fetchCountries()),
        onNoticeShow: (text) => dispatch(noticeShowAction("", text)),
        onModalShow: (name, data) => dispatch(modalShow(name, data)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFetchPaymentProviders: (countryIso) => dispatch(fetchPaymentProviders(countryIso)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onPay: (booking, data, callback) => dispatch(pay(booking, data, callback)),
        onAskPayment: (booking) => dispatch(askPayment(booking)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pay));