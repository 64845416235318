import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../components/elements/input";
import {GridCol} from "../../../../components/collections/grid";
import {Button} from "../../../../components/elements/button";
import {fetchDoctorBillingProfile, saveDoctorBillingProfile} from "../../../services/doctorService";
import {formChangeField} from "../../../services/system/formService";


class DoctorBillingProfile extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {profileUserId} = this.props;
        this.props.onFetchDoctorBillingProfile(profileUserId);
    }

    onChange(event) {
        this.props.onFormChangeField('doctorBillingProfile', event);
    }

    render() {
        const {form: {data: formData, errors: formError, isSend}, profileUserId} = this.props;

        const {doctorBillingProfile = {}} = formData;
        const {user = {}} = doctorBillingProfile;
        const errors = formError.doctorBillingProfile;
        const disabled = isSend.doctorBillingProfile || false;
        return (
            <EmptyTag>
                <div className="row content-row">
                    <div className="col-12">
                        <div className="white-block">
                            {user && user.firstName && user.lastName &&
                            <h3>
                                <FormattedMessage id="doctor.beneficiary.name"
                                                  values={{firstName: user.firstName, lastName: user.lastName}}/>
                            </h3>}

                            <div className="row form-elements">
                                <GridCol count="2">
                                    <Input name="beneficiaryAddress"
                                           value={doctorBillingProfile.beneficiaryAddress || ''}
                                           error={(errors && errors.beneficiaryAddress) || null}
                                           placeholder={<FormattedMessage id="beneficiary.address.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="country"
                                           value={doctorBillingProfile.country || ''}
                                           error={(errors && errors.country) || null}
                                           placeholder={<FormattedMessage id="country.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="beneficiaryContactNumber"
                                           value={doctorBillingProfile.beneficiaryContactNumber || ''}
                                           error={(errors && errors.beneficiaryContactNumber) || null}
                                           placeholder={<FormattedMessage id="beneficiary.contact.number.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="beneficiaryBankName"
                                           value={doctorBillingProfile.beneficiaryBankName || ''}
                                           error={(errors && errors.beneficiaryBankName) || null}
                                           placeholder={<FormattedMessage id="beneficiary.bank.name.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="bankBranchCode"
                                           value={doctorBillingProfile.bankBranchCode || ''}
                                           error={(errors && errors.bankBranchCode) || null}
                                           placeholder={<FormattedMessage id="bank.branch.code.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="1">
                                    <Input name="beneficiaryBankAddress"
                                           value={doctorBillingProfile.beneficiaryBankAddress || ''}
                                           error={(errors && errors.beneficiaryBankAddress) || null}
                                           placeholder={<FormattedMessage id="beneficiary.bank.address.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="beneficiaryAccountNumber"
                                           value={doctorBillingProfile.beneficiaryAccountNumber || ''}
                                           error={(errors && errors.beneficiaryAccountNumber) || null}
                                           placeholder={<FormattedMessage id="beneficiary.account.number.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="matchingBeneficiaryAccountNumber"
                                           value={doctorBillingProfile.matchingBeneficiaryAccountNumber || ''}
                                           error={(errors && errors.matchingBeneficiaryAccountNumber) || null}
                                           placeholder={<FormattedMessage
                                               id="repeat.beneficiary.account.number.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="swiftCode"
                                           value={doctorBillingProfile.swiftCode || ''}
                                           error={(errors && errors.swiftCode) || null}
                                           placeholder={<FormattedMessage id="swift.code.label"/>}
                                           disabled={disabled}
                                           required={false}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="matchingSwiftCode"
                                           value={doctorBillingProfile.matchingSwiftCode || ''}
                                           error={(errors && errors.matchingSwiftCode) || null}
                                           placeholder={<FormattedMessage id="repeat.swift.code.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="abaNumber"
                                           value={doctorBillingProfile.abaNumber || ''}
                                           error={(errors && errors.abaNumber) || null}
                                           placeholder={<FormattedMessage id="aba.number.label"/>}
                                           tooltip={<FormattedMessage id="aba.number.tooltip"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="chipUid"
                                           value={doctorBillingProfile.chipUid || ''}
                                           error={(errors && errors.chipUid) || null}
                                           placeholder={<FormattedMessage id="chip.uid.label"/>}
                                           tooltip={<FormattedMessage id="chip.uid.tooltip"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>

                                <GridCol count="2">
                                    <Input name="ibanNumber"
                                           value={doctorBillingProfile.ibanNumber || ''}
                                           error={(errors && errors.ibanNumber) || null}
                                           placeholder={<FormattedMessage id="iban.number.label"/>}
                                           tooltip={<FormattedMessage id="iban.number.tooltip"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="form-submit margin-top-15">
                    <Button
                        disabled={disabled} preloader={disabled === true}
                        onClick={() => this.props.onSaveDoctorBillingProfile(profileUserId, doctorBillingProfile)}>
                        <FormattedMessage id="core.btn.save.label"/>
                    </Button>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onSaveDoctorBillingProfile: (profileUserId, data) => dispatch(saveDoctorBillingProfile(profileUserId, data)),
        onFetchDoctorBillingProfile: (profileUserId) => dispatch(fetchDoctorBillingProfile(profileUserId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorBillingProfile);