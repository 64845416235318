import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {GridCol} from "../../../../../components/collections/grid";
import {AutocompleteAsync} from "../../../../systems/autocomplete";
import {listFilterFieldAction, listInitAction} from "../../../../../actions/listAction";
import {fetchSpecializations} from "../../../../services/bookingService";
import {formChangeField, formSimpleChange} from "../../../../services/system/formService";
import {fetchDoctors} from "../../../../services/doctorService";
import {Label} from "../../../../../components/elements/label";
import {RadioBox, RadioBoxes} from "../../../../../components/elements/radiobox";
import {Option, Select} from "../../../../../components/elements/select";
import {fetchAllScheduleAvailableAddresses, scheduleQuickBooking} from "../../../../services/doctorScheduleService";

import moment from "moment";
import {Paragraph} from "../../../../../components/elements/paragraph";

class Step1 extends Component {
    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onSearchSpecialization = this.onSearchSpecialization.bind(this);
        this.onSelectedSpecialization = this.onSelectedSpecialization.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectAddress = this.onSelectAddress.bind(this);
    }

    onDelete() {
        this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "specialization", null);
        this.props.onFormSimpleChangeField("createBooking", "timeOfReceipt", null);
        this.props.onFormSimpleChangeField("createBooking", "doctor", null);
        this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "address", null);
        this.props.onFormSimpleChangeField("createBooking", "address", null);
        this.props.onInitList("allDoctorsProfileAddresses");
    }

    onSelectedSpecialization(event, value, reason) {
        this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "specialization", value);
        this.props.onFormSimpleChangeField("createBooking", "timeOfReceipt", null);
        this.props.onFormSimpleChangeField("createBooking", "doctor", null);
        this.props.onListFilterField("doctors", "specializations", (value && value.id) || null);
        this.props.onListFilterField("doctors", "searchText", null);
        this.props.onFormSimpleChangeField("createBooking", "doctor", null);

        const {form: {data: formData}} = this.props;
        const {createBooking = {}} = formData || {};
        const {typeConsultation = null} = createBooking;
        if (typeConsultation != null) {
            if (typeConsultation != 'OFFLINE' && value && value.id) {
                this.props.onScheduleQuickBooking(value.id, typeConsultation);
            } else if ( value && value.id) {
                this.props.onFetchAllScheduleAvailableAddresses(value.id);
            }
        }
        this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "address", null);
        this.props.onFormSimpleChangeField("createBooking", "address", null);
        this.props.onInitList("allDoctorsProfileAddresses");
    }

    onSearchSpecialization(event, query, reason) {
        this.props.onListFilterField("specializations", "searchText", query);
        this.props.onFetchSpecializations(1);
    }

    onOpen(event) {
        this.props.onListFilterField("specializations", "searchText", "");
        this.props.onFetchSpecializations(1);
    }

    onChange(event) {
        this.props.onFormChangeField('createBooking', event);
        if (event.target.name === 'typeConsultation') {
            const {form: {data: formData}} = this.props;
            const {advanceParamsCreateBooking = {}} = formData || {};
            const {specialization = null} = advanceParamsCreateBooking;
            if (event.target.value === 'OFFLINE') {
                if (specialization)
                    this.props.onFetchAllScheduleAvailableAddresses(specialization.id);
            } else {
                this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "address", null);
                this.props.onFormSimpleChangeField("createBooking", "address", null);
                if (specialization)
                    this.props.onScheduleQuickBooking(specialization.id, event.target.value);
            }
            this.props.onFormSimpleChangeField("createBooking", "timeOfReceipt", null);
            this.props.onFormSimpleChangeField("createBooking", "doctor", null);
        }
    }

    onSelectAddress(address) {
        const {form: {data: formData}} = this.props;
        const {advanceParamsCreateBooking = {}, createBooking = {}} = formData || {};
        const {specialization = null} = advanceParamsCreateBooking;
        const {typeConsultation = null} = createBooking;
        this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "address", address);
        this.props.onFormSimpleChangeField("createBooking", "address", address);
        if (address && specialization && typeConsultation)
            this.props.onScheduleQuickBooking(specialization.id, typeConsultation, address.id);
    }

    render() {
        const {form: {data: formData}, list: {data: listData}, globalDisabled = false} = this.props;
        const {allDoctorsProfileAddresses = [], specializations = []} = listData;
        const {createBooking = {}, advanceParamsCreateBooking = {}} = formData || {};
        const {specialization = null, address = null} = advanceParamsCreateBooking;
        return (
            <EmptyTag>
                <GridCol count='1'>
                    <AutocompleteAsync onInputChange={this.onSearchSpecialization}
                                       getOptionLabel={option => option.name || ''}
                                       onChange={(event, value, reason) => this.onSelectedSpecialization(event, value, reason)}
                                       onOpen={this.onOpen}
                                       disabled={globalDisabled}
                                       onDelete={() => this.onDelete()}
                                       isCanDelete={specialization}
                                       value={specialization || null}
                                       placeholder={<FormattedMessage id="core.specialization.label"/>}
                                       placeholderInside={true}
                                       placeholderFixed={true}
                                       disableClearable={true}
                                       error={null}
                                       options={specializations}/>
                </GridCol>
                <GridCol count='1'>
                    <Label fixed={true}><FormattedMessage id="core.type.consultation.label"/></Label>
                    <RadioBoxes>
                        <RadioBox name='typeConsultation'
                                  value='CHAT'
                                  checked={createBooking && createBooking.typeConsultation === 'CHAT'}
                                  onChange={this.onChange}>
                            <FormattedMessage id="core.type.consultation.chat.label"/>
                        </RadioBox>
                        <RadioBox name='typeConsultation'
                                  value='VIDEO'
                                  checked={createBooking && createBooking.typeConsultation === 'VIDEO'}
                                  onChange={this.onChange}>
                            <FormattedMessage id="core.type.consultation.video.label"/>
                        </RadioBox>
                        <RadioBox name='typeConsultation'
                                  value='OFFLINE'
                                  checked={createBooking && createBooking.typeConsultation === 'OFFLINE'}
                                  onChange={this.onChange}>
                            <FormattedMessage id="core.type.consultation.offline.label"/>
                        </RadioBox>
                    </RadioBoxes>
                </GridCol>

                {createBooking.typeConsultation === 'OFFLINE' &&
                <GridCol count='1'>
                    <Select label={<FormattedMessage id="location"/>}
                            optionNameSelected={(address && address.text) || ''}>
                        {
                            allDoctorsProfileAddresses.map(i =>
                                <Option
                                    active={address && i.id === address.id}
                                    key={i.id}
                                    onClick={() => this.onSelectAddress(i)}>
                                    {i.text}
                                </Option>
                            )
                        }
                    </Select>
                </GridCol>}

                {createBooking.doctor &&
                <GridCol count='1'>
                    <Label fixed={true}><FormattedMessage id="core.hcsp.or.doctor.label"/></Label>
                    <Paragraph>{createBooking.doctor.firstName}&nbsp;{createBooking.doctor.lastName}</Paragraph>
                </GridCol>}

                {createBooking.timeOfReceipt &&
                <GridCol count='1'>
                    <Label fixed={true}><FormattedMessage id="appointment.summary.time.label"/></Label>
                    <Paragraph>{moment(parseInt(createBooking.timeOfReceipt)).format('lll')}</Paragraph>
                </GridCol>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onScheduleQuickBooking: (specializationId, typeConsultation, addressId = null) =>
            dispatch(scheduleQuickBooking(specializationId, typeConsultation, addressId)),
        onFetchAllScheduleAvailableAddresses: (specializationId) => dispatch(fetchAllScheduleAvailableAddresses(specializationId)),
        onFetchDoctors: (pageNumber) => dispatch(fetchDoctors(pageNumber, "doctors")),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onFetchSpecializations: (pageNumber, listName) => dispatch(fetchSpecializations(pageNumber, listName)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
