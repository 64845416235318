import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {fetchPharmacyPatientPrescriptions} from "../../../services/pharmacyService";
import moment from "moment";

import {BackTo} from "../../../../components/elements/backTo";
import {modalShow} from "../../../services/system/modalService";
import {Button} from "../../../../components/elements/button";
import {isModal} from "../../../../utils/app";
import PrescriptionMedications from "./PrescriptionMedications";
import {FormattedMessage} from "react-intl";

class PharmacyPatientPrescriptions extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};
        this.props.onFetchPharmacyPatientPrescriptions({id: id}, 1);
    }

    onPagination(pageNumber) {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};
        this.props.onFetchPharmacyPatientPrescriptions({id: id}, pageNumber);
    }

    render() {
        const {list: {data, pagination}, modal} = this.props;
        const {pharmacyPatientPrescriptions = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['pharmacyPatientPrescriptions'] || {};

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={"/pharmacies/patients"}/>
                    </div>

                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="pharmacy.list.patients.header"/></Header>
                    </div>
                </div>

                {pharmacyPatientPrescriptions.map(booking => {
                    const {id, doctor, consultation = {}} = booking;
                    const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
                    const joinSpecializations = specializations.map(i => i.name).join(", ")
                    return (
                        <div className="white-block" key={id}>
                            <div className="row form-elements form-padding-small">
                                <div className="col-12">
                                    #{id}
                                </div>
                                <div className="col">
                                    <h3>
                                        <FormattedMessage id="page.pharmacy.today.issue.prescription.label"/> <span
                                        className="color-green"> <FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></span>&nbsp;-&nbsp;
                                        {joinSpecializations}
                                        <i className="icon icon--doctors-green"></i>
                                    </h3>
                                </div>
                                <div className="col-auto">
                                    {moment(consultation.createdAtPrescribe).format("ll")} &nbsp;&nbsp;&nbsp;
                                    {consultation.prescriptionStatus === 'REQUESTED' &&
                                    <span className="color-gray"><FormattedMessage id="medication.status.requested.label"/> <span
                                        className="inline-circle bg-color-gray"></span></span>}
                                    {consultation.prescriptionStatus === 'ACCEPTED' &&
                                    <span className="color-gray"><FormattedMessage id="medication.status.accepted.label"/>  <span
                                        className="inline-circle bg-color-orange"></span></span>}
                                    {consultation.prescriptionStatus === 'COMPLETED' &&
                                    <span className="color-gray"><FormattedMessage id="medication.status.completed.label"/> <span
                                        className="inline-circle bg-color-green"></span></span>}
                                </div>
                                <div className="col-12">
                                    <div className="color-gray">
                                        {consultation.medications && consultation.medications.map(medication => medication.prescribedMedication).join(", ")}
                                    </div>
                                    <div className="small-controls">
                                        <div className="row">
                                            <div className="col">
                                            </div>
                                            <div className="col-auto">
                                                <Button color="white-blue"
                                                        onClick={() => this.props.onPrescriptionMedications(booking)}><FormattedMessage id="core.btn.detail.label"/></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="col-12">
                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}
                </div>

                {isModal(modal, 'prescriptionMedications') && <PrescriptionMedications/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onPrescriptionMedications: (booking) => dispatch(modalShow("prescriptionMedications", booking)),
        onFetchPharmacyPatientPrescriptions: (patient, pageNumber) => dispatch(fetchPharmacyPatientPrescriptions(patient, pageNumber))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyPatientPrescriptions);
