import {privateApi, privateBlobApi} from '../../utils/api';
import React from "react";
import {formErrors, formIsSend} from "./system/formService";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {noticeShowAction} from "../../actions/noticeAction";
import {formFieldErrorsAction, formInitAction} from "../../actions/formAction";
import {saveAs} from 'file-saver';
import {FormattedMessage} from "react-intl";
import {updateProfileAction} from "../../actions/authAction";
import {listFetchAction} from "../../actions/listAction";
import {fetchAccount} from "./userService";

export const fetchProfileAboutMe = (id) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/doctors/" + id + "/profile/aboutMe")
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("profileAboutMe", data));
            }).catch(error => {
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const fetchProfileAddresses = (doctorId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v2/doctors/" + doctorId +"/profile/addresses")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("doctorProfileAddresses", data, {}));
            }).catch(error => {
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const updateDoctorProfileAboutMe = (id, aboutMe) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/doctors/" + id + "/profile/aboutMe", aboutMe)
            .then(response => {
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
             }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const downloadSignature = (fileName) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateBlobApi.get("/v1/doctors/current/signature/downloadFile", {responseType: "blob"})
            .then(response => {
                saveAs(response.data, fileName);
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchProfileGeneral = (id = null) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const {user} = state().auth;
        const userId = id || user.id;
        return privateApi.get("/v2/doctors/" + userId + "/profile/general")
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("profile", data));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const updateDoctorProfileGeneral = (id, updateProfile, callback) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/doctors/" + id + "/profile/general", updateProfile)
            .then(response => {
                if (user.id == id) {
                    dispatch(updateProfileAction({
                        ...user,
                        lastName: updateProfile.lastName,
                        firstName: updateProfile.firstName
                    }));
                }
                dispatch(formFieldErrorsAction("profile", []));
                dispatch(fetchProfileGeneral(id));
                dispatch(fetchAccount(id, "profileAccountEmail"));
                if (callback)
                    callback()
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("profile", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
                dispatch(formIsSend("profile", false));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};