import React from "react";

import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";

import {billingApi, publicBillingApi} from "../../utils/api";
import {formErrors, formIsSend} from "./system/formService";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";

import {modalHide} from "./system/modalService";
import {generateReport} from "./billingReportService";
import {prepareFilter} from "../../utils/app";
import {formInitAction} from "../../actions/formAction";
import {listFetchAction} from "../../actions/listAction";

export const fetchCurrentBillingPeriod = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.get("/v1/billingPeriods/current")
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("currentBillingPeriod", data));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchNextBillingPeriod = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.get("/v1/billingPeriods/next")
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("nextBillingPeriod", data));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchBillingPeriods = (year) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.get("/v1/billingPeriods/" + year)
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("billingPeriods", data, null));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchBillingPeriodAvailableYears = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.get("/v1/billingPeriods/availableYears")
            .then(response => {
                const {data} = response;
                data.sort((i, j) => j - i);
                dispatch(listFetchAction("billingPeriodAvailableYears", data, null));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const updateDoctorCommission = (specializationId, doctorCommissions, listName = "doctorCommissions") => {
    return (dispatch, state) => {
        const pagination = state().list.pagination[listName] || {};
        dispatch(preloaderPageShowAction());
        return billingApi.post("/v1/commissions/doctors", doctorCommissions)
            .then(response => {
                dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
                return dispatch(fetchDoctorCommissions(pagination.number + 1, specializationId));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctorCommissions = (pageNumber, specializationId, listName = "doctorCommissions") => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters[listName] || {});
        return billingApi.get("/v1/commissions/doctors?pageNumber=" + pageNumber + "&search=" + filter)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction(listName, content, {...rest}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }

};

export const updateSpecializationCommissions = (specializationCommissions) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.post("/v1/commissions/specializations", specializationCommissions)
            .then(response => {
                dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
                return dispatch(fetchCommissionSpecializations());
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};


export const fetchCommissionSpecializations = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.get("/v1/commissions/specializations")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("specializationCommissions", data, {}));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchReportRecipients = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.get("/v1/reportRecipients")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("reportRecipients", data, null));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const removeReportRecipient = (reportRecipientId) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return billingApi.delete("/v1/reportRecipients/" + reportRecipientId)
            .then(response => {
                dispatch(fetchReportRecipients());
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const createReportRecipients = (data) => {
    return (dispatch) => {
        dispatch(formIsSend("createReportRecipients", true));
        return billingApi.post("/v1/reportRecipients", data)
            .then(response => {
                dispatch(modalHide('createReportRecipients'));
                dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
                return dispatch(fetchReportRecipients());
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createReportRecipients", error.response.data.details));
                } else {
                    dispatch(modalHide('createReportRecipients'))
                }
            }).finally(() => {
                dispatch(formIsSend("createReportRecipients", false));
            });
    }
};

export const acceptReportRecipientInvite = (token) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("acceptReportRecipientsInvite", true));
        return publicBillingApi.post("/v1/reportRecipients/acceptReportRecipientsInvite", {token: token})
            .then(response => {
                return dispatch(noticeShowAction(<FormattedMessage
                        id="notice.accept.billing.report.recipient.invite.title"/>,
                    <FormattedMessage id="notice.accept.billing.report.recipient.invite.msg.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {token} = error.response.data.details || {};
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.accept.billing.report.recipient.invite.title"/>, token));
                }
            }).finally(() => {
                dispatch(formIsSend("acceptReportRecipientsInvite", false));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const updateRemittanceReferenceNumber = (billingProfileId, data) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("updateReferenceNumber", true));
        return billingApi.post("/v1/billingProfiles/" + billingProfileId + "/updateReferenceNumber", data)
            .then(response => {
                const {form: {data: formData}, list: {data: pagination}} = state();
                const {reportParams} = formData;
                const {number = 0} = pagination['reportItems'] || {};
                dispatch(generateReport(number + 1, reportParams));

                dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
                dispatch(formErrors("updateReferenceNumber", []));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("updateReferenceNumber", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
            }).finally(() => {
                dispatch(formIsSend("updateReferenceNumber", false));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const updateRemittanceReferenceNumberFromFile = (event, billingPeriodId, callback) => {
    return (dispatch) => {
        const target = event.target;
        const formData = new FormData();
        formData.append("file", target.files[0], target.files[0].name);
        formData.append("billingPeriodId", billingPeriodId);

        dispatch(preloaderPageShowAction());
        return billingApi.post("/v1/billingProfiles/updateReferenceNumberFromFile", formData)
            .then(response => {
                if (callback) callback();
                dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const completedRemittance = (billingProfileId) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return billingApi.post("/v1/billingProfiles/" + billingProfileId + "/completeRemittance")
            .then(response => {
                const {form: {data: formData}, list: {data: pagination}} = state();
                const {reportParams} = formData;
                const {number = 0} = pagination['reportItems'] || {};
                dispatch(generateReport(number + 1, reportParams));
                dispatch(noticeShowAction("", <FormattedMessage id="notification.sent"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const completedRemittanceAllDoctors = (billingPeriodId) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return billingApi.post("/v1/billingProfiles/completeRemittanceAllDoctors?billingPeriodId=" + billingPeriodId)
            .then(response => {
                const {form: {data: formData}, list: {data: pagination}} = state();
                const {reportParams} = formData;
                const {number = 0} = pagination['reportItems'] || {};
                dispatch(generateReport(number + 1, reportParams));
                dispatch(noticeShowAction("", <FormattedMessage id="notification.sent"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const completedRemittanceCortexAdmin= (billingPeriodId) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return billingApi.post("/v1/billingProfiles/completeRemittanceCortexAdmin?billingPeriodId=" + billingPeriodId)
            .then(response => {
                const {form: {data: formData}, list: {data: pagination}} = state();
                const {reportParams} = formData;
                const {number = 0} = pagination['reportItems'] || {};
                dispatch(generateReport(number + 1, reportParams));
                dispatch(noticeShowAction("", <FormattedMessage id="notification.sent"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};