import {privateApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {formErrors, formInit} from "./system/formService";
import {modalHide} from "./system/modalService";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {fetchDoctorSchedule, fetchDoctorScheduleContextOfDay} from "./doctorScheduleService";

export const fetchAppointmentTemplates = (doctorId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/appointmentTemplates?doctorId=" + doctorId)
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("appointmentTemplates", data, {}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchAppointmentTemplate = (id, formName = "appointmentTemplate", callback = null) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/appointmentTemplates/" + id)
            .then(response => {
                const {data} = response;
                dispatch(formInit(formName, data));
                if (callback) {
                    callback(data);
                }
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchAppointmentColors = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/appointmentTemplates/colors")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("appointmentTemplateColors", data, {}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const createAppointmentTemplate = (appointmentTemplate) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/appointmentTemplates", appointmentTemplate)
            .then(response => {
                dispatch(modalHide('newAppointmentTemplate'));
                dispatch(fetchAppointmentTemplates(appointmentTemplate.doctor.id));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("newAppointmentTemplate", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const applyAppointmentTemplate = (data) => {
    return (dispatch, state) => {
        const {appointmentTemplate: {id = null}} = data;
        const {formatStartCurrentPeriod, formatEndCurrentPeriod, doctor, view = ""} = state().form.data['tableSchedule'] || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/appointmentTemplates/" + id + "/apply", data)
            .then(response => {
                dispatch(modalHide('applyAppointmentTemplate'));
                dispatch(fetchDoctorSchedule(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod));
                if (view === "month") {
                    dispatch(fetchDoctorScheduleContextOfDay(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod))
                }
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 400) {
                        dispatch(formErrors("applyAppointmentTemplate", error.response.data.details));
                    } else {
                        dispatch(noticeShowAction(<FormattedMessage
                            id="notice.default.title"/>, error.response.data.message));
                    }
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const updateAppointmentTemplate = (appointmentTemplate) => {
    return (dispatch, state) => {
        const {formatStartCurrentPeriod, formatEndCurrentPeriod, doctor} = state().form.data['tableSchedule'] || {};
        const {id} = appointmentTemplate;
        dispatch(preloaderPageShowAction());
        return privateApi.put("/v1/appointmentTemplates/" + id, appointmentTemplate)
            .then(response => {
                dispatch(modalHide('newAppointmentTemplate'));
                dispatch(fetchAppointmentTemplates(doctor.id));
                dispatch(fetchDoctorSchedule(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("newAppointmentTemplate", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fullUpdateAppointmentTemplate = (appointmentTemplate) => {
    return (dispatch, state) => {
        const {formatStartCurrentPeriod, formatEndCurrentPeriod, doctor} = state().form.data['tableSchedule'] || {};
        const {id} = appointmentTemplate;
        dispatch(preloaderPageShowAction());
        return privateApi.put("/v1/appointmentTemplates/" + id + "/fullUpdate", appointmentTemplate)
            .then(response => {
                dispatch(modalHide('newAppointmentTemplate'));
                dispatch(fetchAppointmentTemplates(doctor.id));
                dispatch(fetchDoctorSchedule(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("newAppointmentTemplate", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const removeAppointmentTemplate = (id) => {
    return (dispatch, state) => {
        const {formatStartCurrentPeriod, formatEndCurrentPeriod, doctor} = state().form.data['tableSchedule'] || {};
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/v1/appointmentTemplates/" + id)
            .then(response => {
                dispatch(fetchAppointmentTemplates(doctor.id));
                dispatch(fetchDoctorSchedule(doctor.id, formatStartCurrentPeriod, formatEndCurrentPeriod));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};