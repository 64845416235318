import {modalHideAction, modalShowAction} from "../../../actions/modalAction";
import {formInit, formErrors} from "./formService";

export const modalShow = (name, data = {}) => {
    return dispatch => {
        dispatch(formInit(name, data));
        return dispatch(modalShowAction(name))
    }
};

export const modalHide = (name) => {
    return dispatch => {
        dispatch(modalHideAction(name));
        return dispatch(formErrors(name, null));
    }
};