import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Header} from "../../../../components/elements/header";

import {FormattedMessage} from "react-intl";
import Avatar from "../../../systems/avatar";
import {fetchSystemSettings} from "../../../services/commonService";
import {fetchDoctorReviews, fetchDoctorReviewsStatistic} from "../../../services/doctorReviewService";
import {listInitAction} from "../../../../actions/listAction";
import Rating from "@material-ui/lab/Rating";
import Pagination from "react-js-pagination";
import {floatFormatting, isModal} from "../../../../utils/app";
import {modalShow} from "../../../services/system/modalService";
import NewDoctorReview from "./NewDoctorReview";
import LinearProgress from "@material-ui/core/LinearProgress";


class DoctorReviews extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        const {doctorId} = this.props;
        this.onPagination(1);
        this.props.onFetchDoctorReviewsStatistic(doctorId);
    }

    onPagination(pageNumber = 1) {
        const {doctorId} = this.props;
        this.props.onFetchDoctorReviews(pageNumber, doctorId);
    }

    componentWillUnmount() {
        this.props.onInitList("doctorReviews");
    }

    render() {
        const {modal, list: {data: listData, pagination}, form: {data: formData}} = this.props;
        const {doctorReviews = []} = listData;
        const {doctorReviewsStatistic = {}} = formData;
        const {avgRatings, countRatings, countOneRating, countTwoRating,
            countThreeRating, countFourRating, countFiveRating, percentOneRating,
            percentTwoRating, percentThreeRating, percentFourRating, percentFiveRating} = doctorReviewsStatistic;
        const {number = 0, size = 0, totalElements = 0} = pagination['doctorReviews'] || {};

        return (
            <div className="col-12">
                <div className="white-block">

                    <div className="row default-row align-items-center">
                        <div className="col">
                            <Header as="h3"><FormattedMessage id="rating.and.reviews"/></Header>
                        </div>
                        <div className="col-auto">
                           {/* <Button
                                onClick={() => this.props.onModalShow("newDoctorReview", {doctorId: doctorId})}><FormattedMessage
                                id="leave.review"/></Button>*/}
                        </div>
                    </div>

                    <div className="row default-row align-items-center">
                        <div className="col-auto align-center">
                            <div className="biggest-text">{floatFormatting(avgRatings)}</div>
                            <div className="small-text color-gray">
                                <FormattedMessage id="count.ratings" values={{count: countRatings || 0}}/>
                            </div>
                        </div>
                        {countRatings > 0 &&
                        <div className="col-auto">
                            <table className="report-rating-table">
                                <tbody>
                                <tr>
                                    <td>
                                        <Rating name="read-only" value={5} readOnly max={5} />
                                    </td>
                                    <td className="rating-table-row-progressbar">
                                        <div className="progressbar-simple-wrapper">
                                            <LinearProgress variant="determinate" value={percentFiveRating || 0} />
                                        </div>
                                    </td>
                                    <td className="rating-table-row-value">{countFiveRating || 0}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <Rating name="read-only" value={4} readOnly max={4} />
                                    </td>
                                    <td className="rating-table-row-progressbar">
                                        <div className="progressbar-simple-wrapper">
                                            <LinearProgress variant="determinate" value={percentFourRating || 0} />
                                        </div>
                                    </td>
                                    <td className="rating-table-row-value">{countFourRating || 0}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <Rating name="read-only" value={3} readOnly max={3}  />
                                    </td>
                                    <td className="rating-table-row-progressbar">
                                        <div className="progressbar-simple-wrapper">
                                            <LinearProgress variant="determinate" value={percentThreeRating || 0} />
                                        </div>
                                    </td>
                                    <td className="rating-table-row-value">{countThreeRating || 0}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <Rating name="read-only" value={2} readOnly max={2}  />
                                    </td>
                                    <td className="rating-table-row-progressbar">
                                        <div className="progressbar-simple-wrapper">
                                            <LinearProgress variant="determinate" value={percentTwoRating || 0} />
                                        </div>
                                    </td>
                                    <td className="rating-table-row-value">{countTwoRating || 0}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <Rating name="read-only" value={1} readOnly max={1} />
                                    </td>
                                    <td className="rating-table-row-progressbar">
                                        <div className="progressbar-simple-wrapper">
                                            <LinearProgress variant="determinate" value={percentOneRating || 0} />
                                        </div>
                                    </td>
                                    <td className="rating-table-row-value">{countOneRating || 0}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>}
                    </div>


                    <h3><FormattedMessage id="count.reviews" values={{count: totalElements}}/></h3>
                    {doctorReviews.map(doctorReview => {
                        const author = doctorReview.author;
                        const doctor = doctorReview.doctor;
                        return (
                            <div className="white-block" key={doctorReview.id}>
                                <div className="row avatar-row align-items-center">
                                    <div className="col-auto avatar-row__image">
                                        <Avatar size="80" userId={doctor.id} doctor/>
                                    </div>
                                    <div className="col">
                                        <h4>{author.firstName} {author.lastName}</h4>
                                    </div>
                                    <div className="col-auto">
                                        <span className="small-text color-gray">
                                            {moment(doctorReview.createdAt).format("lll")}
                                        </span>
                                    </div>
                                    {doctorReview.rating &&
                                    <div className="col-12 padding-top-10">
                                        <div className="rating-block">
                                            <Rating name="read-only" value={doctorReview.rating} readOnly/>
                                        </div>
                                    </div>}
                                    <div className="col-12 padding-top-10">
                                        <div className="small-text">{doctorReview.text}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}


                    <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}
                    </div>
                </div>

                {isModal(modal, 'newDoctorReview') && <NewDoctorReview/>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        modal: store.modal,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onFetchDoctorReviews: (pageNumber, doctorId) => dispatch(fetchDoctorReviews(pageNumber, doctorId)),
        onFetchDoctorReviewsStatistic: (doctorId) => dispatch(fetchDoctorReviewsStatistic(doctorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorReviews);
