import moment from "moment";
import {ButtonIcon} from "../../../../components/elements/button";
import React from "react";
import classnames from "classnames";
import {Icon} from "../../../../components/elements/icon";

export const ScheduleDetail = (props) => {
    const {schedule, onCloseDetail, onRemoveSchedule} = props;
    const {timeOfReceipt, endTimeOfReceipt, appointmentTemplate, color, booking} = schedule;
    const {payment, typeConsultation = "", patient = {}} = booking || {};

    const isOffline = typeConsultation === 'OFFLINE';
    const isChat = typeConsultation === 'CHAT';
    const isVideo = typeConsultation === 'VIDEO';

    let colorCode = '';
    if (color) colorCode = color.code;
    if (appointmentTemplate) colorCode = appointmentTemplate.color.code;

    const classnameModalColor = classnames('c-g-event-modal', 'color-template-' + colorCode);
    const {address = null} = appointmentTemplate || {};
    return (
        <div className={classnameModalColor}>
            <div className="c-g-event-modal-content-wrapper">
                <div className="row c-t-item-content-row">
                    <div className="col c-t-item-text">
                        <div>
                            {moment(timeOfReceipt).format("ll")}&nbsp;
                            {moment(timeOfReceipt).format("HH:mm A")}&nbsp;-&nbsp;
                            {moment(endTimeOfReceipt).format("HH:mm A")}</div>
                        {payment && <div>
                            {payment.currency}&nbsp;
                            {payment.amount && parseFloat(payment.amount).toFixed(2)}
                        </div>}
                        {isOffline && address && <div>
                            <Icon name={"address-black"}/>&nbsp;{address.text}
                        </div>}

                        {booking && <div className="c-t-booked-line">
                            <Icon name={"circle-person"} />&nbsp;
                            {patient.firstName}&nbsp;{patient.lastName}
                        </div>}
                    </div>
                    <div className="col-auto c-t-item-types">
                        {isVideo &&
                        <i className="icon icon--circle-event-online"
                           title="Online"></i>}
                        {isChat &&
                        <i className="icon icon--circle-event-chat"
                           title="Chat"></i>}
                        {isOffline &&
                        <i className="icon icon--circle-event-offline"
                           title="Offline"></i>}
                    </div>
                </div>
                <div className="c-g-event-modal-controls">
                    <ButtonIcon icon={'close-gray'} onClick={onCloseDetail}/>
                    {!booking && <ButtonIcon icon={'trash-red'}
                                onClick={onRemoveSchedule}/>}
                </div>
            </div>
        </div>
    );
}