import React, {Component} from 'react';
import {connect} from "react-redux";

import ChatMessages from "../../../../systems/chat/ChatMessages";
import ChatSendMessage from "../../../../systems/chat/ChatSendMessage";
import Dropdown, {DropdownItem} from "../../../../systems/dropdown";
import {ButtonIcon} from "../../../../../components/elements/button";
import {modalShow} from "../../../../services/system/modalService";
import {isModal} from "../../../../../utils/app";
import BookAnAppointment from "./BookAnAppointment";
import {getBookingRooms} from "../../../../services/chatService";
import {FormattedMessage} from "react-intl";
import ChatRooms from "../../../../systems/chat/ChatRooms";
import {formInitAction} from "../../../../../actions/formAction";
import ChatHeader from "../../../../systems/chat/ChatHeader";
import {listInitAction} from "../../../../../actions/listAction";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import BookingDetailChatEmpty from "./BookingDetailChatEmpty";

class BookingDetailChat extends Component {
    componentDidMount() {
        const {form: {data: formData}} = this.props;
        const {booking = null} = formData;
        this.props.onInitForm('bookingChatRoomMessages', {});
        this.props.onInitList('bookingChatRoomMessages');
        this.props.onInitForm('bookingChosenChatRoom', {});
        this.props.onInitList('bookingChatRooms');
        if (booking && Object.keys(booking).length > 0)
            this.props.onGetBookingRooms(booking);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData = {}}} = this.props;
        const {form: {data: prevFormData = {}}} = prevProps;

        const {booking: curBooking = {}} = curFormData;
        const {booking: prevBooking = {}} = prevFormData;

        if (curBooking != prevBooking && Object.keys(curBooking).length > 0) {
            this.props.onInitForm('bookingChatRoomMessages', {});
            this.props.onInitList('bookingChatRoomMessages');
            this.props.onInitForm('bookingChosenChatRoom', {});
            this.props.onInitList('bookingChatRooms');
            this.props.onGetBookingRooms(curBooking);
        }
    }

    componentWillUnmount() {
        this.props.onInitForm('bookingChatRoomMessages', {});
        this.props.onInitList('bookingChatRoomMessages');

        this.props.onInitForm('bookingChosenChatRoom', {});
        this.props.onInitList('bookingChatRooms');
    }

    render() {
        const {modal, form: {data: formData}, list: {data: listData}, auth: {user:currentUser}} = this.props;
        const {booking = null} = formData;
        const {bookingChatRooms = []} = listData;
        const dropdownProfile = <ButtonIcon icon="points-circle" onClick={null}></ButtonIcon>;

        if (bookingChatRooms.length ==0) {
            return <EmptyTag>
                <BookingDetailChatEmpty onReload={() => this.props.onGetBookingRooms(booking)}/>
            </EmptyTag>
        }

        return (
            <div className="row content-row">
                <div className="col-8">
                    <div className="chat-container">
                        <div className="chat-header">
                            <ChatHeader formNameChosenChatRoom={'bookingChosenChatRoom'}>
                                {currentUser.role == 'DOCTOR' && booking &&
                                    <div className="col-auto">
                                        <Dropdown button={dropdownProfile}>
                                            <DropdownItem icon="medical-record" to={'#'}
                                                          onClick={() => this.props.onPatientMedicalRecord({patient: booking.patient})}>
                                                <FormattedMessage id="booking.detail.chat.medical-record"/></DropdownItem>
                                            {booking.doctor && booking.doctor.isEnabled &&
                                                <DropdownItem icon="postcard" to={'#'}
                                                              onClick={() => this.props.onBookAnAppointment({
                                                                  patient: booking.patient,
                                                                  doctor: booking.doctor
                                                              })}>
                                                    <FormattedMessage
                                                        id="booking.detail.chat.book-an-appointment"/></DropdownItem>}
                                        </Dropdown>
                                    </div>}
                            </ChatHeader>
                        </div>
                        <ChatMessages formNameChosenChatRoom={'bookingChosenChatRoom'}
                                      listNameChatRoomMessages={'bookingChatRoomMessages'}/>
                        <ChatSendMessage
                            formNameNewMessage={'bookingNewMessage'}
                            formNameChosenChatRoom={'bookingChosenChatRoom'}
                                         listNameChatRoomMessages={'bookingChatRoomMessages'}/>

                        {isModal(modal, 'bookAnAppointment') && <BookAnAppointment/>}
                    </div>
                </div>
                <div className="col-4">
                    <ChatRooms rooms={bookingChatRooms}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onBookAnAppointment: (data) => dispatch(modalShow('bookAnAppointment', data)),
        onPatientMedicalRecord: (data) => dispatch(modalShow('patientMedicalRecord', data)),
        onGetBookingRooms: (booking) => dispatch(getBookingRooms(booking))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailChat);
