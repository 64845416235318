import React from 'react';
import classnames from "classnames";
import {MainLogo} from "../../elements/logo";
import {Header} from "../../elements/header";
import {Button, ButtonClose, Buttons} from "../../elements/button";
import {NOTICE_STATUS} from "../../../constants/system";
import {Modal} from "../../modules/modal";
import {GridCol, GridRow} from "../grid";
import {FormattedMessage} from "react-intl";

export const NoticeBodyModal = (props) => {
    const {onClose, data} = props;
    const {title, textMsg} = data;
    return (
        <Modal close={true} onClose={onClose}>
            <Header as="h2">{title}</Header>
            <GridRow width={300}>
                <GridCol count="1">
                    {textMsg}
                </GridCol>
                <GridCol count="1">
                    <Buttons>
                        <Button onClick={onClose}><FormattedMessage id="core.btn.ok.label"/></Button>
                    </Buttons>
                </GridCol>
            </GridRow>
        </Modal>
    );
};

export const NoticeBodyPage = (props) => {
    const {title} = props;
    return (
        <>
            <MainLogo/>
            <Header as="h2">{title}</Header>
            {props.children}
        </>
    );
};

export const NoticeHeader = (props) => {
    const {type = null, hasClose = null, onClose = null} = props;
    const noticeClassNames = classnames('notice-top', {
        'notice-top--orange': (type == NOTICE_STATUS.WARNING),
        'bg-gradient-green': (type == NOTICE_STATUS.SUCCESS)
    });
    return (
        <div className={noticeClassNames}>
            {props.children}
            {hasClose && <ButtonClose onClick={onClose}/>}
        </div>
    );
};
