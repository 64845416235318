import React from 'react';
import classnames from "classnames";

export const Form = (props) => {
    return (
        <form method="post">
            {props.children}
        </form>
    );
};

export const FormTitle = (props) => {
    return (
        <h2>{props.children}</h2>
    );
};

export const FormWrapper = (props) => {
    return (
        <div className="pform-center-col">
            {props.children}
        </div>
    );
};

export const FormControlWrapper = (props) => {
    const {align = ''} = props;
    const wrapperClass = classnames('row', 'form-elements', {
        'align-items-center': (align === 'center'),
        'align-items-left': (align === 'left'),
        'align-items-right': (align === 'right')
    });
    return (
        <div className={wrapperClass}>
            {props.children}
        </div>
    );
};


export const ButtonWrapper = (props) => {
    const {widthAuto = false} = props;
    const wrapperClass = classnames('form-submit', {
        'btns-width-auto': (widthAuto == true)
    });
    return (
        <div className={wrapperClass}>{props.children}</div>
    );
};