import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {formChangeField} from "../../../../services/system/formService";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import MobileMoneySettings from "./paymentSettings/MobileMoneySettings";

class PaymentSettings extends Component {

    render() {
        const {form: {data: formData}} = this.props;
        const {pay = null} = formData;
        const {paymentProvider = null} = pay
        const {paymentMethod = null} = paymentProvider || {}
        return (
            <EmptyTag>
                {paymentMethod && paymentMethod.code === 'MOBILE_MONEY' && <MobileMoneySettings/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentSettings));