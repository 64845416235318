import React from 'react';
import classnames from 'classnames';
import {ButtonClose} from "../../elements/button";

export const Modal = (props) => {
    const {header = null, gradient = null, width = 0, close = null, align = "", onClose, noPadding = false, darkBg = false} = props;
    const modalClassNames = classnames('pform-wrapper pform-zoom-in pform-show', {
        'pform-gradient': (gradient === true),
        'pform-width-400': (width === 400),
        'pform-width-600': (width === 600),
        'pform-width-660': (width === 660),
        'pform-width-700': (width === 700),
        'pform-width-800': (width === 800),
        'pform-width-960': (width === 960),
        'pform-width-1000': (width === 1000),
        'pform-width-1100': (width === 1100),
        'pform-no-padding': noPadding === true,
        'pform-dark-bg': darkBg === true,
        'pform-align-center': (align === 'center')
    });
    return (
        <div className={modalClassNames}>
            <div className="pform-container">
                <div className="pform-outer">
                    <div className="pform zoom-anim-dialog">
                        {header &&
                        <div className="pform-header">
                            <div className="pform-header-content">
                                {header}
                            </div>
                        </div>}
                        {close && <ButtonClose onClick={onClose}/>}
                        <div className="pform-content">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};