import React from 'react';
import {connect} from "react-redux";

import 'react-notifications-component/dist/theme.css';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";

import {Button} from "../../../components/elements/button";
import {isFillPatientProfile} from "../../services/patientService";
import {modalShow} from "../../services/system/modalService";
import {withRouter} from "react-router-dom";

class TopMenu extends React.Component {
    constructor(props) {
        super(props);
        this.userLogout = this.userLogout.bind(this);
        this.onChooseMember = this.onChooseMember.bind(this);
        this.onCreateBooking = this.onCreateBooking.bind(this);
        this.onCreateQuickBooking = this.onCreateQuickBooking.bind(this);
        this.onCreateEmergencyCall = this.onCreateEmergencyCall.bind(this);
    }

    onChooseMember(isQuick = false) {
        this.props.onModalShow("chooseMember", {isQuick: isQuick});
    }

    userLogout() {
        this.props.onLogout();
    }

    onCreateBooking() {
        const {auth} = this.props;
        const {isFillProfile, isHasFamily} = auth.user;
        if (isFillProfile && !isHasFamily) {
            this.props.onRedirectToBookAnAppointment();
        } else if (!isFillProfile) {
            this.props.onIsFillPatientProfile()
        } else if (isHasFamily) {
            this.onChooseMember()
        }
    }

    onCreateQuickBooking() {
        const {auth} = this.props;
        const {isFillProfile, isHasFamily} = auth.user;
        if (isFillProfile && !isHasFamily) {
            this.props.onRedirectToQuickBookAnAppointment();
        } else if (!isFillProfile) {
            this.props.onIsFillPatientProfile()
        } else if (isHasFamily) {
            this.onChooseMember(true)
        }
    }

    onCreateEmergencyCall() {
        const {auth} = this.props;
        const {isFillProfile} = auth.user;
        if (!isFillProfile) {
            this.props.onIsFillPatientProfile()
        } else {
            this.props.onModalShow('emergencyCall')
        }
    }

    render() {
        const {form, auth} = this.props;
        const {role, id, doctor, isFillProfile, isEnabled} = auth.user;
        const {systemSettings = {}} = form.data || {};
        const {isEnableEmergencyCall = false} = systemSettings || {};
        const isSendEmergencyCall = form.isSend.emergencyCall || false;
        return (
            <EmptyTag>
                {role === 'PATIENT' && <EmptyTag>
                    {isEnableEmergencyCall &&
                        <Button color="orange"
                                onClick={() => this.onCreateEmergencyCall()}
                                disabled={!isFillProfile || !isEnabled}
                                preloader={isSendEmergencyCall === true}>
                            <FormattedMessage id="core.layout.sidebar.item.help.label"/></Button>}


                    <Button color="yellow" disabled={!isFillProfile || !isEnabled}
                            onClick={() => this.onCreateQuickBooking()}>
                        <FormattedMessage id="core.layout.sidebar.item.quick.booking.label"/>
                    </Button>

                    <Button disabled={!isFillProfile || !isEnabled} onClick={() => this.onCreateBooking()}>
                        <FormattedMessage id="core.layout.sidebar.item.create.booking.label"/>
                    </Button>

                </EmptyTag>}
                {role === 'DOCTOR' && !doctor.licenseNumber && <EmptyTag>
                    <FormattedMessage id="core.layout.sidebar.doctor.id.label"
                                      values={{id: id}}/></EmptyTag>}
                {role === 'DOCTOR' && doctor.licenseNumber && <EmptyTag>
                    <FormattedMessage id="core.layout.sidebar.doctor.license.label"
                                      values={{licenseNumber: doctor.licenseNumber}}/></EmptyTag>}
                {role === 'ASSISTANT' &&
                    <EmptyTag><FormattedMessage id="core.layout.sidebar.assistant.id.label"
                                                values={{id: id}}/></EmptyTag>}
            </EmptyTag>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onRedirectToBookAnAppointment: () => ownProps.history.push("/bookAnAppointment"),
        onRedirectToQuickBookAnAppointment: () => ownProps.history.push("/quickBooking"),
        onIsFillPatientProfile: () => dispatch(isFillPatientProfile()),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopMenu));
