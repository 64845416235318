import React, {Component} from 'react';
import {connect} from "react-redux";

import {verifyAccount} from "../../services/userService";
import {changeLanguage} from "../../services/commonService";
import qs from "query-string";

class VerifyAccount extends Component {
    componentDidMount() {
        const {match: {params}} = this.props;
        const p = this.props.location.search;
        const {language = ""} = qs.parse(p);
        const {token = ""} = params || {}
        this.props.onChangeLanguage(language);
        this.props.onVerifyAccount(token);
    }

    render() {
        return (
            <>
            </>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onVerifyAccount: (token) => dispatch(verifyAccount(token)),
        onChangeLanguage : (language) => dispatch(changeLanguage(language)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyAccount);