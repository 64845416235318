import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Header} from "../../../../components/elements/header";
import {IconItem} from "../../../../components/elements/icon";
import {GridCol} from "../../../../components/collections/grid";
import {isModal} from "../../../../utils/app";
import CreateReportRecipients from "./CreateReportRecipients";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {fetchReportRecipients, removeReportRecipient} from "../../../services/billingSettingsService";
import {listInitAction} from "../../../../actions/listAction";
import {ButtonRemove} from "../../../../components/elements/button";


class ReportRecipients extends Component {
    componentDidMount() {
        this.props.onInitList("reportRecipients");
        this.props.onFetchReportRecipients();
    }

    render() {
        const {modal, list: {data}} = this.props;
        const {reportRecipients = []} = data;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="report.recipients.list.header"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                        <a href="#" onClick={() => this.props.onFormCreateReportRecipients()}
                           className="btn-icon color-blue">
                            <IconItem name="plus-bordered"><FormattedMessage id="core.btn.add.label"/></IconItem>
                        </a>
                    </div>
                </div>
                <div className="row content-row">
                    <GridCol count="1">
                        {reportRecipients.length === 0 && <FormattedMessage id="report.recipients.list.empty"/>}
                        {reportRecipients.length > 0 &&
                        <div className="row link-blocks-row">

                            {reportRecipients.map(reportRecipient => {
                                return (
                                    <div className="col-md-6" key={reportRecipient.id}>
                                        <div className="link-block-wrapper">
                                            <div className="row avatar-row align-items-center">
                                                <div className="col avatar-row__text">
                                                    <div>
                                                        {reportRecipient.email}
                                                    </div>
                                                    {reportRecipient.isVerify &&
                                                    <div className="small-text color-green">Verified</div>}
                                                    {!reportRecipient.isVerify &&
                                                    <div className="small-text">Not verified</div>}
                                                </div>
                                                <div className="col-auto relative-controls">
                                                    <ButtonRemove onClick={() => this.props.onConfirm({
                                                        text:
                                                            <FormattedMessage id="report.recipients.list.remove.item.confirm.text"/>,
                                                        onProceed: (() => this.props.onRemoveReportRecipient(reportRecipient.id))
                                                    })}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>}
                    </GridCol>
                </div>
                {isModal(modal, 'createReportRecipients') && <CreateReportRecipients/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        tab: store.tab,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onRemoveReportRecipient: (reportRecipientId) => dispatch(removeReportRecipient(reportRecipientId)),
        onFetchReportRecipients : () => dispatch(fetchReportRecipients()),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFormCreateReportRecipients: () => dispatch(modalShow('createReportRecipients')),
        onModalHide: (name) => dispatch(modalHide(name))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportRecipients);