import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../components/collections/grid";
import {formChangeField, formInit} from "../../../services/system/formService";
import {Button, ButtonIcon, ButtonRemove, Buttons} from "../../../../components/elements/button";
import {Input} from "../../../../components/elements/input";
import {Modal} from "../../../../components/modules/modal";
import {modalHide} from "../../../services/system/modalService";
import {Header} from "../../../../components/elements/header";
import {formChangeFieldAction} from "../../../../actions/formAction";
import _ from "lodash";
import {arrayRemoveNullElements} from "../../../../utils/app";
import {createAssistantInvites} from "../../../services/doctorService";
import {FormattedMessage} from "react-intl";

class CreateAssistantInvites extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    onChange(event) {
        this.props.onFormSimpleChange(event.target.name, event.target.value);
    }

    onAdd() {
        const {form: {data}} = this.props;
        const {createAssistantInvites: {emails = [""]}} = data;
        emails.push("");
        this.props.onFormSimpleChange("emails", emails);
    }

    onRemove(item) {
        const {form: {data}} = this.props;
        const {createAssistantInvites} = data;
        _.unset(createAssistantInvites, item);
        this.props.onFormSimpleChange("emails", arrayRemoveNullElements(createAssistantInvites.emails));
    }

    render() {
        const {auth: {user}, form: {data: formData, errors: formErrors, isSend: formIsSend}} = this.props;
        const {createAssistantInvites} = formData;
        const {emails = [""]} = createAssistantInvites;
        const errors = formErrors['createAssistantInvites'];
        const disabled = formIsSend['createAssistantInvites'] || false;

        return (
            <Modal width={700} close={true} onClose={() => this.props.onModalHide()}>
                <Header as="h2"><FormattedMessage id="core.header.assistant.create"/></Header>
                <GridRow>
                    {emails.map((email, index) => {
                        const name = "emails[" + index + "]"
                        return (
                            <GridCol count="1" key={index}>
                                <div className="row align-items-center">
                                    <div className="col-auto"></div>
                                    <div className="col">
                                        <Input name={name}
                                               placeholder={<FormattedMessage id="core.create.assistant.invite.email.label"/>}
                                               error={(errors && errors["emails[" + index + "]"]) || (index === 0 && errors && errors["emails"]) || null}
                                               disabled={disabled}
                                               onChange={this.onChange}
                                               value={email}/>
                                    </div>
                                    <div className="col-auto relative-controls">
                                        {emails.length > 1 &&
                                            <ButtonRemove onClick={() => this.onRemove(name)} disabled={disabled}/>}

                                        {index + 1 === emails.length && emails.length < 5 &&
                                            <ButtonIcon icon="plus-bordered" onClick={this.onAdd} disabled={disabled}/>}
                                    </div>
                                </div>
                            </GridCol>
                        );
                    })}
                    <GridCol count="1">
                        <Buttons width="auto">
                            <Button disabled={disabled}
                                    color="white-red"
                                    preloader={false}
                                    onClick={() => this.props.onModalHide()}><FormattedMessage id="core.btn.cancel.label"/></Button>
                            <Button disabled={disabled}
                                    preloader={formIsSend['createAssistantInvites'] || false}
                                    onClick={() => this.props.onCreateAssistantInvites(user, createAssistantInvites)}><FormattedMessage id="assistant.send-invite"/></Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateAssistantInvites: (doctor, data) => dispatch(createAssistantInvites(doctor, data)),
        onFormInit: (data) => dispatch(formInit('createAssistantInvites', data)),
        onModalHide: () => dispatch(modalHide('createAssistantInvites')),
        onFormSimpleChange: (fieldName, fieldValue) => dispatch(formChangeFieldAction('createAssistantInvites', fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAssistantInvites);
