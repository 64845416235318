import React, {Component} from 'react';
import {connect} from "react-redux";
import {modalHide} from "../../services/system/modalService";
import {Modal} from "../../../components/modules/modal";
import {noticeShowAction} from "../../../actions/noticeAction";
import {FormattedMessage} from "react-intl";

class VideoPlay extends Component {
    componentDidMount() {
        const {form: {data}} = this.props;
        const {videoPlay = {}} = data;
        const video = document.querySelector('video');

        video.onplay = (event) => console.log(event.error)
        video.src = videoPlay.src;
        video.play()
            .catch(reason => {
                this.props.onNotification("", <FormattedMessage id="modal.video.play.error.msg"/>);
                this.props.onModalHide("videoPlay");
            });
    }

    render() {
        return (
            <Modal width={800} close={true} onClose={() => this.props.onModalHide("videoPlay")} noPadding={true}
                   darkBg={true}>
                <div className="video-block video-block-autoheight">
                   <video controls={true} preload='auto' autoPlay={true}>
                    </video>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onNotification: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onModalHide: (name) => dispatch(modalHide(name))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlay);
