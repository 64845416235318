import React from 'react';
import classnames from "classnames";

export const Handset = (props) => {
    const {ring = false, onClick} = props;
    const buttonClassNames = classnames('btn-icon', {});
    return (
        <button type="button" className={buttonClassNames} onClick={onClick}>
            {ring && <i className="icon icon--phone-calling-red"></i>}
            {!ring && <i className="icon icon--phone-calling-gray"></i>}
        </button>
    );
};