import React, {Component} from 'react';
import {connect} from "react-redux";

import {Modal} from "../../../components/modules/modal";
import {
    Form, FormControlWrapper,
    FormTitle,
    FormWrapper
} from "../../../components/collections/form";
import {modalHide} from "../../services/system/modalService";
import {GridCol} from "../../../components/collections/grid";
import {Button, Buttons} from "../../../components/elements/button";
import {emergencyCall} from "../../services/callService";
import {FormattedMessage} from "react-intl";

class EmergencyCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seconds: 5
        };
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const {seconds} = this.state;
            if (seconds > 0) {
                this.setState(({seconds}) => ({
                    seconds: seconds - 1
                }))
            }
            else if (seconds === 0) {
                clearInterval(this.myInterval);
                this.props.onModalHide('emergencyCall');
                this.props.onEmergencyCall();
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    render() {
        const {seconds} = this.state;
        const {onModalHide} = this.props;
        return (
            <Modal close={true} onClose={() => onModalHide('emergencyCall')}>
                <FormWrapper>
                    <Form>
                        <FormTitle><FormattedMessage id="modal.emergency.call.header"/></FormTitle>
                        <FormControlWrapper>
                            <GridCol count='1'>
                                <div className="call-counter">
                                    <div className="icon-item">
                                        <i className="icon icon--counter"/>
                                        <span className="icon-item__text">{seconds}</span>
                                    </div>
                                </div>
                            </GridCol>
                            <GridCol count='1'>
                                <Buttons>
                                    <Button color={"white-red"} onClick={() => onModalHide('emergencyCall')}>
                                        <FormattedMessage id="core.btn.cancel.label"/>
                                    </Button>
                                </Buttons>
                            </GridCol>
                        </FormControlWrapper>
                    </Form>
                </FormWrapper>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        preloader: store.preloader,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
        onEmergencyCall: () => dispatch(emergencyCall())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmergencyCall);
