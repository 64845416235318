import React, {Component} from 'react';
import {connect} from "react-redux";

import {sign} from "../../services/userService";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Buttons} from "../../../components/elements/button";
import {formChangeField, formInit} from "../../services/system/formService";
import {Link} from "react-router-dom";
import {MainLogo} from "../../../components/elements/logo";
import {Redirect} from "react-router";
import {FormattedMessage} from 'react-intl';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {ButtonSignup} from "../../../components/collections/button_signup";

class Welcome extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.onSignInit();
    }

    onChange(event) {
        this.props.onFormChangeField('sign', event);
    }

    render() {
        const {auth} = this.props;
        if (auth.isAuthenticated) {
            const signReferrer = localStorage.getItem("SignReferrer") || "/";
            return <Redirect to={signReferrer}/>;
        }

        return (
            <EmptyTag>
                <MainLogo/>
                <h2 className="big-text color-red"><FormattedMessage id="home.not.auth.welcome.label"/></h2>
                <p><FormattedMessage id="home.not.auth.welcome.description"/></p>

                <GridRow width={300}>
                    <GridCol count="1">
                        <Buttons>
                            <p className="small-text"><FormattedMessage id="core.already.have.account.label"/></p>
                            <Link to={'/users/sign-in'} className="btn-default">
                                <div className="btn__content">
                                    <FormattedMessage id="core.btn.sign-in.label"/>
                                </div>
                            </Link>
                        </Buttons>
                    </GridCol>
                    <GridCol count="1">
                        <ButtonSignup/>
                    </GridCol>
                </GridRow>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth,
        browser: store.browser
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onSignInit: () => dispatch(formInit("sign", {})),
        onSign: (credential) => dispatch(sign(credential, ownProps)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
