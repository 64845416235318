import React from 'react';
import {connect} from "react-redux";

import {MenuItem} from "../../../components/collections/menu";
import 'react-notifications-component/dist/theme.css';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import Submenu, {SubmenuItem} from "../../systems/submenu";

class DoctorMainMenu extends React.Component {

    render() {
        const {auth: {user}} = this.props;
        const {isFillProfile} = user;
        return (
            <EmptyTag>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/doctor/home'} icon="home"><FormattedMessage
                        id="core.layout.sidebar.item.home.label"/></MenuItem>
                </div>
                <div className="sidebar-menu__item sidebar-top-border">
                    <MenuItem to={'/doctor/schedule'} icon="calendar" activeClassName="active" disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.schedule.label"/>
                    </MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/patientConsulted'} icon="patients" activeClassName="active" disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.patients.consulted.label"/>
                    </MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/doctor/bookings'} icon="waiting" activeClassName="active" disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.patients.label"/>
                    </MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/doctor/extraConsultations'} icon="doctors" activeClassName="active" disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.extra.consultation.label"/>
                    </MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/doctor/assistants'} icon="assistant" activeClassName="active" disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.assistants.label"/>
                    </MenuItem>
                </div>

                <Submenu parentLabel={<FormattedMessage id="core.layout.sidebar.item.report.label"/>}
                         baseUrl={"/doctor/reports"}
                         parentUrl={"/doctor/reports/reportRemittance"}
                         parentIcon={'menu-item'}
                         disabled={!isFillProfile}>
                    <SubmenuItem>
                        <MenuItem to={'/doctor/reports/reportRemittance'} disabled={!isFillProfile}><FormattedMessage
                            id="core.layout.sidebar.item.report.remittance.label"/></MenuItem>
                    </SubmenuItem>
                </Submenu>
            </EmptyTag>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorMainMenu);
