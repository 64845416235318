import React, {Component} from 'react';
import {connect} from "react-redux";
import {IconItem} from "../../../components/elements/icon";
import {Header} from "../../../components/elements/header";
import {Modal} from "../../../components/modules/modal";
import {Button} from "../../../components/elements/button";
import {modalHide} from "../../services/system/modalService";
import {createLinkBookAnAppointment} from "../../services/doctorService";
import {noticeShowAction} from "../../../actions/noticeAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../actions/preloaderAction";
import {FormattedMessage} from "react-intl";

class ShareLink extends Component {
    constructor(props) {
        super(props);
        this.onCopyLink = this.onCopyLink.bind(this);
        this.state = {isCopied: false};
    }

    componentDidMount() {
    }

    onCopyLink() {
        (async () => {
            this.props.onPageShowPreloader();
            const {auth} = this.props;
            await createLinkBookAnAppointment(auth.user)
                .then(linkURL => {
                    const textArea = document.createElement("textarea");
                    textArea.value = linkURL;
                    textArea.style.top = "0";
                    textArea.style.left = "0";
                    textArea.style.position = "fixed";

                    document.body.appendChild(textArea);
                    textArea.focus();
                    textArea.select();

                    try {
                        if (document.execCommand('copy')) {
                            this.setState({...this.state, isCopied: true});
                        }
                    } catch (e) {
                        this.setState({...this.state, isCopied: false});
                        this.props.onNotice("", "Fail copy link");
                    } finally {
                        document.body.removeChild(textArea);

                    }
                })
                .catch(reason => {
                    const {response} = reason;
                    const {data} = response;
                    this.props.onModalHide('shareLink');
                    this.props.onNotice("", data.message);
                })
                .finally(() => this.props.onPageHidePreloader());
        })();
    }

    render() {
        const {isCopied} = this.state;
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('shareLink')} align={'center'}>
                <Header as="h2"><FormattedMessage id="modal.share.link.header"/></Header>
                <div className="row form-elements">
                    <div className="col-12">
                        <IconItem name={'copy'} circle/>
                    </div>
                    <div className="col-12">
                        <div className="color-gray"><FormattedMessage id="modal.share.link.description"/></div>
                        {isCopied && <p className="color-green"><FormattedMessage id="modal.share.link.is.copied.true"/></p>}
                    </div>
                    <div className="col-12">
                        <div className="form-submit btns-width-auto">
                            <Button onClick={() => this.onCopyLink()}><FormattedMessage id="modal.share.link.btn.label.copy"/></Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onModalHide: (name) => dispatch(modalHide(name)),
        onNotice: (title, textMsg) => dispatch(noticeShowAction(title, textMsg))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShareLink);
