import {BELL_NO_RING, BELL_RING} from "../constants/actionTypes";

const initialState = {
    ring: false
};

const bellReducer = (state = initialState, action) => {
    switch (action.type) {
        case BELL_NO_RING:
            return {...state, ring: false};
        case BELL_RING:
            return {...state, ring: true};
        default:
            return state;
    }
};

export default bellReducer;