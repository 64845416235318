import {
    PRELOADER_PAGE_IS_SHOW,
    PRELOADER_PAGE_IS_HIDE, PRELOADER_PAGE_INIT, PRELOADER_PAGE_TEXT
} from "../constants/actionTypes";

const initialState = {
    isShow: false,
    count: 0,
    text: null
};

const preloaderReducer = (state = initialState, action) => {
    const {count} = state;
    switch (action.type) {
        case PRELOADER_PAGE_IS_SHOW:
            return {...state, isShow: true, count: count + 1};
        case PRELOADER_PAGE_IS_HIDE:
            return {...state, isShow: (count - 1) > 0, count: count - 1};
        case PRELOADER_PAGE_INIT:
            return {...state, isShow: false, count: 0, text: null};
        case PRELOADER_PAGE_TEXT:
            return {...state, text: action.text};
        default:
            return state;
    }
};

export default preloaderReducer;