import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {fetchPharmacyNewPrescriptions} from "../../../services/pharmacyService";
import moment from "moment";
import {BackTo} from "../../../../components/elements/backTo";
import {modalShow} from "../../../services/system/modalService";
import {Button} from "../../../../components/elements/button";
import {isModal} from "../../../../utils/app";
import {GridCol} from "../../../../components/collections/grid";
import {Avatar} from "../../../../components/collections/avatar";
import NewPrescriptionMedications from "./PrescriptionMedications";
import {FormattedMessage} from "react-intl";

class PharmacyNewPrescriptions extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.props.onFetchPharmacyNewPrescriptions(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchPharmacyNewPrescriptions(pageNumber);
    }

    render() {
        const {list: {data, pagination}, modal} = this.props;
        const {pharmacyNewPrescriptions = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['pharmacyNewPrescriptions'] || {};

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={"/pharmacies/patients"}/>
                    </div>

                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.pharmacy.new.prescription.header"/></Header>
                    </div>
                </div>

                {pharmacyNewPrescriptions.length == 0 &&
                <div className="no-results-block">
                    <i className="icon icon--calendar-big"></i>
                    <div><FormattedMessage id="pharmacy.new.prescription.list.empty"/></div>
                </div>}

                <div className="row link-blocks-row">
                    {pharmacyNewPrescriptions.map(booking => {
                        const {id, patient, doctor, consultation = {}} = booking;
                        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
                        const joinSpecializations = specializations.map(i => i.name).join(", ")
                        return (
                            <GridCol count="2" key={id}>
                                <div className="link-block-wrapper">
                                    <div className="row avatar-row">

                                        <div className="col-auto avatar-row__image">
                                            <Avatar/>
                                        </div>
                                        <div className="col avatar-row__text">
                                            <div className="row">
                                                <div className="col">{patient.firstName} {patient.lastName}</div>
                                                <div className="col-auto">
                                                    #{id}&nbsp;
                                                    <span className="color-gray">
                                                        {moment(consultation.createdAtPrescribe).format("ll")}
                                                    </span>
                                                </div>
                                                <div className="col-12">
                                                    <div className="small-text">
                                                        <div>
                                                            <FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/> - <span
                                                            className="color-gray">{joinSpecializations}</span>
                                                        </div>
                                                        <div className="color-gray">
                                                            {consultation.medications &&
                                                            consultation.medications.map(medication => medication.prescribedMedication).join(", ")}
                                                        </div>
                                                    </div>
                                                    <div className="small-controls">
                                                        <div className="row">
                                                            <div className="col">
                                                            </div>
                                                            <div className="col-auto">
                                                                <Button color="white-blue"
                                                                        onClick={() => this.props.onNewPrescriptionMedications(booking)}><FormattedMessage id="core.btn.detail.label"/></Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </GridCol>
                        );
                    })}

                    <div className="col-12">
                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}
                    </div>
                </div>
                {isModal(modal, 'prescriptionMedications') && <NewPrescriptionMedications/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onNewPrescriptionMedications: (booking) => dispatch(modalShow("prescriptionMedications", booking)),
        onFetchPharmacyNewPrescriptions: (pageNumber) => dispatch(fetchPharmacyNewPrescriptions(pageNumber))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyNewPrescriptions);
