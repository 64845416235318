import React, {Component} from 'react';
import {connect} from "react-redux";
import CatalogDoctors from "../index";

class EmbedCatalogDoctors extends Component {
    render() {
        return (
            <CatalogDoctors/>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EmbedCatalogDoctors);
