import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";

export const fetchBookingCalls = (booking, pageNumber) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const params = {pageNumber: pageNumber}
        return privateApi.get("/v1/bookings/" + booking.id + "/calls", {params: params})
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                return dispatch(listFetchAction("bookingCalls", content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const getUrlStreamingCall = async (booking, callId) => {
    const response = await privateApi.post("/v1/bookings/" + booking.id + "/calls/" + callId + "/createStreamingLink");
    return window._env_.BACKEND_URL + "/v1/calls/streaming?hash=" + response.data;
};