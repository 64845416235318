import {
    formChangeFieldAction,
    formFieldErrorsAction,
    formInitAction,
    formIsSendAction
} from "../../../actions/formAction";

export const formSimpleChange = (formName, fieldName, fieldValue) => {
    return dispatch => dispatch(formChangeFieldAction(formName, fieldName, fieldValue, null));
};

export const formChangeField = (formName, event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    return dispatch => dispatch(formChangeFieldAction(formName, name, value));
};

export const formErrors = (formName, errors) => {
    return dispatch => dispatch(formFieldErrorsAction(formName, errors));
};

export const formIsSend = (formName, isSend) => {
    return dispatch => dispatch(formIsSendAction(formName, isSend));
};

export const formInit = (formName, data = {}) => {
    return dispatch => dispatch(formInitAction(formName, data));
};