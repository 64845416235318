import React, {Component} from 'react';
import {connect} from "react-redux";

import {withRouter} from 'react-router-dom';
import {IconItem} from "../../../../../components/elements/icon";
import moment from "moment";
import {Modal} from "../../../../../components/modules/modal";
import {Header} from "../../../../../components/elements/header";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {modalHide} from "../../../../services/system/modalService";
import {FormattedMessage} from "react-intl";

class PaymentSuccess extends Component {
    render() {
        const {form} = this.props;
        const {data: {paymentSuccess = {}}} = form;
        const {booking = null} = paymentSuccess;
        const {timeOfReceipt, doctor = null} = booking || {};
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('paymentSuccess')} align="center">
                <Header as="h2" color="green"><FormattedMessage id="modal.payment.success.header"/></Header>
                <GridRow>
                    {booking &&
                    <GridCol count='1'>
                        <p>
                            <FormattedMessage id="modal.payment.success.info.appointment"
                                              values={{doctorFirstName: doctor.firstName,
                                                  doctorLastName: doctor.lastName,
                                                  timeAppointment : moment(timeOfReceipt).format("ll")}}/>
                        </p>
                        <IconItem name="success"/>
                    </GridCol>}
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentSuccess));
