import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {EmptyTag} from "../../../components/elements/emptyTag";

import {listInitAction} from "../../../actions/listAction";
import {createEmergencyCall, checkMediaDevices, fetchFirstQueueEmergencyCalls} from "../../services/callService";
import {withRouter} from "react-router-dom";
import {Button} from "../../../components/elements/button";
import {noticeShowAction} from "../../../actions/noticeAction";
import {getSex} from "../../../utils/app";
import {FormattedMessage} from "react-intl";

class QueueEmergencyCalls extends Component {
    constructor(props) {
        super(props);
        this.onCall = this.onCall.bind(this);
    }

    componentDidMount() {
        this.props.onInitFirstQueueEmergencyCalls();
        this.props.onFetchFirstQueueEmergencyCalls();
    }

    onCall(patient) {
        checkMediaDevices(() => this.props.onCreateEmergencyCall(patient),
            () => this.props.onNoticeShow(
                <FormattedMessage id="notice.call.title" />,
                <FormattedMessage id="core.media.device.not.available" />));
    }

    render() {
        const {list: {data, pagination}} = this.props;
        const {firstQueueEmergencyCalls = []} = data;
        const {totalElements = 0} = pagination['firstQueueEmergencyCalls'] || {};
        return (
            <EmptyTag>
                <h2><FormattedMessage id="modal.queue.emergency.calls.header"/></h2>
                <div className="white-notifications-wrapper">

                    {firstQueueEmergencyCalls.map(applicantEmergencyCall => {
                        const {patient, createdAt, id} = applicantEmergencyCall;
                        return (
                            <div className="white-notification-item" key={id}>
                                <div className="row white-notification-row align-items-center">
                                    <div className="col">
                                        <a href="#">{patient.firstName} {patient.firstName}</a>
                                        <div className="small-text color-gray">
                                            {patient.sex && getSex(patient.sex)}
                                            {patient.sex && ", "}
                                            {patient.birthday && moment().diff(moment(patient.birthday), 'years') + ' years'}
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="small-text color-gray">
                                            {moment(createdAt).format("lll")}
                                        </div>
                                    </div>
                                    <div className="col-auto white-notification-controls">
                                        <Button icon="phone-white" circle={true} pulse={true}
                                                onClick={() => this.onCall(patient)}></Button>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    <div className="white-notification-item-last">
                        <FormattedMessage id="core.all.label"/>: {totalElements}
                    </div>
                </div>

            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCreateEmergencyCall: (patient) => dispatch(createEmergencyCall(patient, ownProps)),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onFetchFirstQueueEmergencyCalls: () => dispatch(fetchFirstQueueEmergencyCalls()),
        onInitFirstQueueEmergencyCalls: () => dispatch(listInitAction("firstQueueEmergencyCalls"))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QueueEmergencyCalls));
