import React from 'react';
import {Link} from "react-router-dom";

export const MainLogo = (props) => {
    return (
        <Link to={"/"}>
            <div className="main-logo-row">
                <Logo/>
            </div>
        </Link>
    );
}

export const Logo = () => {
    return (<img alt="" src="/logo/main-logo.png"/>);
}

export const TwoFactorAuth = () => <div className="two-step-auth-row"></div>;