import React, {Component} from 'react';
import {connect} from "react-redux";

import {cancelCall, checkMediaDevices, takeCall} from "../../services/callService";
import {Button, Buttons} from "../../../components/elements/button";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Header} from "../../../components/elements/header";
import {Modal} from "../../../components/modules/modal";
import {withRouter} from "react-router-dom";
import {noticeShowAction} from "../../../actions/noticeAction";
import {modalHide} from "../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../actions/preloaderAction";
import Avatar from "../../systems/avatar";

class IncomingCall extends Component {
    constructor(props) {
        super(props);
        this.onTake = this.onTake.bind(this);
        this.handlerTimeout = this.handlerTimeout.bind(this);
        this.state = {timerId: null}
    }

    handlerTimeout = () => {
        const {form} = this.props;
        const {sessionId = null} = form.data.incomingCall || {};
        this.props.onCancelCall(sessionId)
    }

    componentWillMount() {
        let timerId = setTimeout(this.handlerTimeout, 60000);
        this.setState({...this.state, timerId: timerId})
    }

    componentWillUnmount() {
        if (this.state.timerId) {
            clearTimeout(this.state.timerId);
        }
    }

    onTake(data) {
        this.props.onPageShowPreloader();
        checkMediaDevices(() => this.props.onPageHidePreloader() && this.props.onTakeCall(data),
            () => this.props.onPageHidePreloader() && this.props.onModalHide("incomingCall") &&
                this.props.onNoticeShow(<FormattedMessage id="notice.call.title"/>, <FormattedMessage
                    id="core.media.device.not.available"/>));
    }

    render() {
        const {form} = this.props;
        const {callsOutFirstName = "", callsOutLastName = "", callsOutId = null, sessionId = null} = form.data.incomingCall || {};
        return (
            <Modal width="400" align="center" gradient={true}>
                <Header as="h2"><FormattedMessage id="modal.patient.call.header"/></Header>
                <GridRow>
                    <GridCol count="1">
                        <div className="avatar-border-2">
                            <div className="avatar-border-1">
                                <Avatar userId={callsOutId}/>
                            </div>
                        </div>
                    </GridCol>
                    <GridCol count="1">
                        <FormattedMessage id="core.doctor.name"
                                          values={{firstName: callsOutFirstName, lastName: callsOutLastName}}/></GridCol>
                    <GridCol count="1">
                        <Buttons>
                            <Button onClick={() => this.onTake(form.data.incomingCall)}
                                    circle={true} pulse={true} icon="phone-white"></Button>
                            <Button onClick={() => this.props.onCancelCall(sessionId)}
                                    circle={true} icon="call-finish" color="red" />
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onTakeCall: (data) => dispatch(takeCall(data, ownProps)),
        onCancelCall: (sessionId) => dispatch(cancelCall(sessionId)),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IncomingCall));