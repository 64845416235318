import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {fetchCurrentBillingPeriod, fetchNextBillingPeriod} from "../../../services/billingSettingsService";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import InfoPeriod from "./InfoPeriod";

class InfoBillingPeriods extends Component {
    componentDidMount() {
        this.props.onFetchCurrentBillingPeriod();
        this.props.onFetchNextBillingPeriod();
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {currentBillingPeriod: current = null, nextBillingPeriod: next = null} = formData;

        return (
            <EmptyTag>
                <div className="col-md-7 padding-bottom-0">
                    <div className="row tile-row">
                        {current &&
                        <div className="col-md-6">
                            <InfoPeriod title={<FormattedMessage id="current.billing.cycle.label"/>}
                                        dateFrom={current.dateStart} dateTo={current.dateEnd}/>
                        </div>}
                        {next &&
                        <div className="col-md-6">
                            <InfoPeriod title={<FormattedMessage id="next.billing.cycle.label"/>}
                                        dateFrom={next.dateStart} dateTo={next.dateEnd}/>
                        </div>}
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchCurrentBillingPeriod: () => dispatch(fetchCurrentBillingPeriod()),
        onFetchNextBillingPeriod: () => dispatch(fetchNextBillingPeriod()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoBillingPeriods);