import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../../components/collections/grid";
import {
    removeAssistantInvite,
    fetchAssistantInvites
} from "../../../services/doctorService";
import {listFilterFieldAction, listInitAction} from "../../../../actions/listAction";
import Pagination from "react-js-pagination";
import moment from "moment";
import {modalShow} from "../../../services/system/modalService";
import {ButtonRemove} from "../../../../components/elements/button";
import {Avatar} from "../../../../components/collections/avatar";
import {FormattedMessage} from "react-intl";

class AssistantsByStatus extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        const {auth: {user}, status} = this.props;
        this.props.onListFilterField("status", status);
        this.props.onFetchAssistantInvites(user, 1);
    }

    onPagination(pageNumber) {
        const {auth: {user}} = this.props;
        this.props.onFetchAssistantInvites(user, pageNumber);
    }

    componentWillUnmount() {
        this.props.onInitList("assistantInvites");
    }

    render() {
        const {list: {data, pagination}, auth: {user}} = this.props;
        const {number = 0, size = 0, totalElements = 0} = pagination['assistantInvites'] || {};
        const {assistantInvites = []} = data;
        return (
            <GridCol count="1">
                {assistantInvites.length === 0 && <FormattedMessage id="assistant.list.empty"/>}
                {assistantInvites.length > 0 &&
                <div className="row link-blocks-row">

                    {assistantInvites.map(assistantInvite => {
                        return (
                            <div className="col-md-6" key={assistantInvite.id}>
                                <div className="link-block-wrapper">
                                    <div className="row avatar-row align-items-center">
                                        {assistantInvite.status === 'ACCEPTED' &&
                                        <div className="col-auto avatar-row__image">
                                            <Avatar/>
                                        </div>}

                                        <div className="col avatar-row__text">
                                            <div>
                                                {assistantInvite.status === 'ACCEPTED' && assistantInvite.assistant.firstName + ' ' + assistantInvite.assistant.lastName}
                                                {assistantInvite.status === 'REQUESTED' && assistantInvite.email}
                                            </div>
                                            <div className="small-text color-gray">
                                                {assistantInvite.status === 'ACCEPTED' && assistantInvite.email}
                                                {assistantInvite.status === 'REQUESTED' && moment(assistantInvite.createdAt).format("lll")}
                                            </div>
                                        </div>
                                        <div className="col-auto relative-controls">
                                            {user.isEnabled &&
                                            <ButtonRemove onClick={() => this.props.onConfirm({
                                                text: assistantInvite.status === 'REQUESTED' ?
                                                    <FormattedMessage id="assistant.cancel.invite"/> :
                                                    <FormattedMessage id="assistant.remove.assistant"/>,
                                                onProceed: (() => this.props.onRemoveAssistantInvite(user, assistantInvite))
                                            })}/>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                    <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}
                    </div>
                </div>}
            </GridCol>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRemoveAssistantInvite: (doctor, assistantInvite) => dispatch(removeAssistantInvite(doctor, assistantInvite)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFetchAssistantInvites: (doctor, pageNumber) => dispatch(fetchAssistantInvites(doctor, pageNumber)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("assistantInvites", fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssistantsByStatus);
