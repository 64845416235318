import React, {Component} from 'react';
import {connect} from "react-redux";
import {Widget, WidgetNoResult} from "../../../../components/collections/widget";
import ScheduleCalendar from "../schedule/ScheduleCalendar";
import moment from "moment/moment";
import {fetchBookings, fetchScheduleByDate} from "../../../services/bookingService";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {GridCol} from "../../../../components/collections/grid";
import {Link} from "react-router-dom";
import {Avatar} from "../../../../components/collections/avatar";
import {FormattedMessage} from "react-intl";

class WidgetSchedule extends Component {
    constructor(props) {
        super(props);
        this.onChooseCalendarDate = this.onChooseCalendarDate.bind(this);
    }

    componentDidMount() {
        this.props.onListFilterField("statuses", "APPROVED");
        this.props.onListSorts({timeOfReceipt: 'asc'});
        const tms = moment().hour(0).minute(0).second(0).unix();
        this.props.onListFilterField("startDateOfReceipt", tms + "000");
        this.props.onFetchBookings(1);
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    onChooseCalendarDate(date) {
        const {auth} = this.props;
        const tms = moment(date).unix();
        this.props.onListFilterField("startDateOfReceipt", null);
        this.props.onListFilterField("dateOfReceipt", tms + "000");
        this.props.onFetchBookings(1);
        this.props.onFetchScheduleByDate(auth.user, moment(date).format("YYYY-MM-DD"));
    }

    render() {
        const {list: {data, filters}, auth} = this.props;
        const tms = moment().hour(0).minute(0).second(0).unix();
        const {dateOfReceipt = tms + "000"} = filters['bookings'] || {};
        const {widgetScheduleItems = []} = data;
        return (
            <Widget title={<FormattedMessage id="widget.doctor.schedule.title"/>} linkTo={'/doctor/schedule'} noScroll={true} maxHeight={360}>
                <div className="row content-row">
                    <div className="col-md-6">
                        <div className="overflow-auto max-height-360">
                            {widgetScheduleItems.length === 0 &&
                                <WidgetNoResult icon="calendar-big">
                                    <FormattedMessage id="doctor.home.schedule.empty"/></WidgetNoResult>}
                            {widgetScheduleItems.length > 0 &&
                            <div className="row link-blocks-row blocks-hover-bg">
                                {widgetScheduleItems.filter((value, index) => index < 3).map(widgetScheduleItem => {
                                        const {patient: {firstName, lastName, birthday}, timeOfReceipt} = widgetScheduleItem;
                                        return (
                                            <GridCol count="1" key={widgetScheduleItem.id}>
                                                <div className="link-block-wrapper">
                                                    <Link
                                                        to={'/doctor/bookings/' + widgetScheduleItem.id + '/detail'}
                                                        className="link-block"></Link>
                                                    <div className="row date-row align-items-center">
                                                        <div className="col-auto date-row__icon">
                                                            <i className="icon icon--clock"></i>
                                                        </div>
                                                        <div className="col date-row__value color-gray">
                                                            {moment(timeOfReceipt).format("lll")}
                                                        </div>
                                                    </div>
                                                    <div className="row avatar-row align-items-center">
                                                        <div className="col-auto avatar-row__image">
                                                            <Avatar/>
                                                        </div>
                                                        <div className="col avatar-row__text">
                                                            <div>
                                                                {firstName} {lastName}
                                                                <span
                                                                    className="small-text color-gray">,
                                                                    {birthday && <FormattedMessage id="core.birthday.value"
                                                                                                   values={{value: moment().diff(birthday, 'years')}}/>}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </GridCol>
                                        );
                                    }
                                )}
                            </div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <ScheduleCalendar defaultChooseDate={new Date(parseInt(dateOfReceipt))}
                                          isShowRequested={false}
                                          doctor={auth.user}
                                          onChooseCalendarDate={this.onChooseCalendarDate}/>
                    </div>
                </div>
            </Widget>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchScheduleByDate: (doctor, dateOfReceipt) => dispatch(fetchScheduleByDate(doctor, dateOfReceipt)),
        onInitList: () => dispatch(listInitAction("widgetScheduleItems")),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber, "widgetScheduleItems")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("widgetScheduleItems", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("widgetScheduleItems", sorts)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetSchedule);
