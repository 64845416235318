import React, {Component} from 'react';
import {connect} from "react-redux";

import {forgotPassword} from "../../services/userService";
import {Redirect} from "react-router";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Input} from "../../../components/elements/input";
import {Buttons, Button} from "../../../components/elements/button";
import {formChangeField, formInit} from "../../services/system/formService";
import {Header} from "../../../components/elements/header";
import {BackToSign} from "../../../components/elements/backTo";
import {MainLogo} from "../../../components/elements/logo";
import {FormattedMessage} from "react-intl";

class ForgotPassword extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('forgotPassword', event);
    }

    render() {
        const {form, onForgotPassword, auth} = this.props;
        const errors = form.errors.forgotPassword;
        const disabled = form.isSend.forgotPassword || false;
        if (auth.isAuthenticated) return <Redirect to="/"/>;
        return (
            <>
                <MainLogo/>
                <Header as="h2"><FormattedMessage id="page.forgot.password.header"/></Header>
                <p><FormattedMessage id="page.forgot.password.description"/></p>
                <GridRow width={300}>
                    <GridCol count="1">
                        <Input name="email"
                               icon="user"
                               autocomplete="email"
                               error={(errors && errors.email) || null}
                               placeholder={<FormattedMessage id="core.field.email.label"/>}
                               disabled={disabled}
                               onChange={this.onChange}/>
                    </GridCol>
                    <GridCol count="1">
                        <Buttons>
                            <Button disabled={disabled}
                                    preloader={disabled === true}
                                    onClick={() => onForgotPassword(form.data.forgotPassword)}>
                                <FormattedMessage id="core.btn.send.label"/>
                            </Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
                <GridRow width={300}>
                    <GridCol count="1">
                        <BackToSign to={"/users/sign-in"} />
                    </GridCol>
                </GridRow>
            </>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    dispatch(formInit("forgotPassword", {}));
    return {
        onForgotPassword: (data) => dispatch(forgotPassword(data)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);