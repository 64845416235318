import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol} from "../../../components/collections/grid";
import {Option, Select} from "../../../components/elements/select";
import moment from "moment";
import {InputError} from "../../../components/elements/error";

class Birthday extends Component {
    constructor(props) {
        super(props);
        this.state = {chooseDay: null, chooseMonth: null, chooseYear: null};
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount() {
        const {value = null} = this.props;
        if (value) {
            const birthdayMoment = moment(value, "DD.MM.YYYY");
            this.setState({
                ...this.state,
                chooseDay: birthdayMoment.date(),
                chooseMonth: birthdayMoment.month(),
                chooseYear: birthdayMoment.year()
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {value: curValue = null} = this.props;
        const {value: prevValue = null} = prevProps;

        if (curValue != prevValue) {
            if (curValue) {
                const birthdayMoment = moment(curValue, "DD.MM.YYYY");
                this.setState({
                    ...this.state,
                    chooseDay: birthdayMoment.date(),
                    chooseMonth: birthdayMoment.month(),
                    chooseYear: birthdayMoment.year()
                });
            } else {
                this.setState({
                    ...this.state,
                    chooseDay: null,
                    chooseMonth: null,
                    chooseYear: null
                });
            }
        }
    }

    onClick(value, unit) {
        const {chooseDay, chooseMonth, chooseYear} = this.state;
        switch (unit) {
            case "DAY" :
                this.setState({...this.state, chooseDay: value});
                this.checkBirthday(value, chooseMonth, chooseYear);
                this.props.onChangeBirthday(value, chooseMonth, chooseYear);
                break;
            case "MONTH" :
                this.setState({...this.state, chooseMonth: value});
                this.checkBirthday(chooseDay, value, chooseYear);
                this.props.onChangeBirthday(chooseDay, value, chooseYear);
                break;
            case "YEAR" :
                this.setState({...this.state, chooseYear: value});
                this.checkBirthday(chooseDay, chooseMonth, value);
                this.props.onChangeBirthday(chooseDay, chooseMonth, value);
                break;
        }
    }
    checkBirthday(day, month, year) {
        if (day != null && month != null && year != null) {
            if (!moment([year, month, day]).isValid()) {
                this.setState({...this.state, chooseDay: null, chooseMonth: null, chooseYear: null});
            }
        }
    }

    render() {
        const {error} = this.props;
        const {chooseDay, chooseMonth, chooseYear} = this.state;

        return (
            <div className="row small-form-row">
                <GridCol count="3">
                    <Select label={'DD'}
                            noLabel={true}
                            height={40}
                            bgColor='white'
                            optionNameSelected={chooseDay || ""}>
                        {[...Array(31).keys()].map(day =>
                            <Option
                                active={chooseDay === day + 1}
                                key={day}
                                onClick={() => this.onClick(day + 1, "DAY")}>
                                {day + 1}
                            </Option>
                        )
                        }
                    </Select>
                </GridCol>
                <GridCol count="3">
                    <Select label={'MM'}
                            noLabel={true}
                            height={40}
                            bgColor='white'
                            optionNameSelected={(chooseMonth != null && moment().month(chooseMonth).format('MMM')) || ""}>
                        {[...Array(12).keys()].map(monthNumber =>
                            <Option
                                active={chooseMonth == monthNumber}
                                key={monthNumber}
                                onClick={() => this.onClick(monthNumber, "MONTH")}>
                                {moment().month(monthNumber).format('MMM')}
                            </Option>
                        )
                        }
                    </Select>
                </GridCol>
                <GridCol count="3">
                    <Select label={'YYYY'}
                            noLabel={true}
                            height={40}
                            bgColor='white'
                            optionNameSelected={(chooseYear != null && moment().year(chooseYear).format('YYYY')) || ""}
                            >
                        {[...Array(90).keys()].reverse().map(yearItem => {
                                const year = moment().year() - yearItem;
                                const yearValue = moment().year(year).format('YYYY')
                                return (
                                    <Option
                                        active={chooseYear == yearValue}
                                        key={yearItem}
                                        onClick={() => this.onClick(yearValue, "YEAR")}>
                                        {yearValue}
                                    </Option>
                                )
                            }
                        )
                        }
                    </Select>
                </GridCol>
                {error !== null && <InputError>{error}</InputError>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        language: store.language
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Birthday);