/* WS */
export const WS_INIT = 'WS_INIT';
export const WS_CLOSE = 'WS_CLOSE';

/* Dropdown */
export const DROPDOWN_VISIBLE = 'DROPDOWN_VISIBLE';
export const DROPDOWN_NOT_VISIBLE = 'DROPDOWN_NOT_VISIBLE';

/* Language */
export const CHOOSE_LANGUAGE = 'CHOOSE_LANGUAGE';

/* Auth */
export const AUTHENTICATE = 'AUTHENTICATE';
export const ENABLED_USER = 'ENABLED_USER';
export const DISABLED_USER = 'DISABLED_USER';
export const ENABLE_2FA = 'ENABLE_2FA';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

/* Browser */
export const BROWSER_NOTIFICATION_GRANTED = 'BROWSER_NOTIFICATION_GRANTED';
export const BROWSER_NOTIFICATION_DENY = 'BROWSER_NOTIFICATION_DENY';

/* Handset */
export const HANDSET_NO_RING = 'HANDSET_NO_RING';
export const HANDSET_RING = 'HANDSET_RING';

/* Bell */
export const BELL_RING = 'BELL_RING';
export const BELL_NO_RING = 'BELL_NO_RING';

/* Form */
export const FORM_INIT = 'FORM_INIT';
export const FORM_IS_SEND = 'FORM_IS_SEND';
export const FORM_CHANGE_FIELD = 'FORM_CHANGE_FIELD';
export const FORM_FIELD_ERRORS = 'FORM_FIELD_ERRORS';

/* Notice */
export const NOTICE_SHOW = 'NOTICE_SHOW';
export const NOTICE_HIDE = 'NOTICE_HIDE';

/* Modal */
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';
export const EMERGENCY_CALL = 'EMERGENCY_CALL';

/* Call */
export const EMERGENCY_CALL_INIT = 'EMERGENCY_CALL_INIT';
export const CONSULTATION_CALL_INIT = 'CONSULTATION_CALL_INIT';
export const CALL_CALLING_INIT = 'CALL_CALLING_INIT';
export const CALL_CALLING_START = 'CALL_CALLING_START';
export const CALL_CALLING_PAUSE = 'CALL_CALLING_PAUSE';
export const CALL_STREAMS_UPDATE = 'CALL_STREAMS_UPDATE';
export const CALL_SHOW = 'CALL_SHOW';
export const CALL_HIDE = 'CALL_HIDE';
export const CALL_SESSION_INIT = 'CALL_SESSION_INIT';
export const CALL_DESTROY = 'CALL_DESTROY';

/* List */
export const LIST_INIT = 'LIST_INIT';
export const LIST_FETCH = 'LIST_FETCH';
export const LIST_SORT = 'LIST_SORT';
export const LIST_FILTER = 'LIST_FILTER';
export const LIST_FILTER_FIELD = 'LIST_FILTER_FIELD';
export const LIST_INIT_DATA = 'LIST_INIT_DATA';
export const LIST_IS_LOAD_DATA = 'LIST_IS_LOAD_DATA';


/* Preloader */
export const PRELOADER_PAGE_IS_SHOW = 'PRELOADER_PAGE_IS_SHOW';
export const PRELOADER_PAGE_IS_HIDE = 'PRELOADER_PAGE_IS_HIDE';
export const PRELOADER_PAGE_INIT = 'PRELOADER_PAGE_INIT';
export const PRELOADER_PAGE_TEXT = 'PRELOADER_PAGE_TEXT';

/* Tab */
export const TAB_CLEAR = 'TAB_CLEAR';
export const TAB_FETCH = 'TAB_FETCH';
export const TAB_CHANGE_PANEL = 'TAB_CHANGE_PANEL';

/* Pagin */
export const PAGIN_SET_NUMBER_PAGE = 'PAGIN_SET_NUMBER_PAGE';
export const PAGIN_SET_PAGE_SIZE = 'PAGIN_SET_PAGE_SIZE';
export const PAGIN_SET_TOTAL_COUNT = 'PAGIN_SET_TOTAL_COUNT';
