import {billingApi, billingBlobApi} from "../../utils/api";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {saveAs} from 'file-saver';
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {listFetchAction} from "../../actions/listAction";
import {formInitAction} from "../../actions/formAction";
import {formErrors} from "./system/formService";


export const generateReport = (pageNumber, reportParams = {}) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.post("/v1/reports/generate?pageNumber=" + pageNumber, reportParams)
            .then(response => {
                const {data} = response;
                const {items, ...restForm} = data;
                const {content, ...rest} = items;
                const {dateFrom, dateTo, ...restStatistic} = restForm;
                const reportPeriodParams = {...reportParams.reportPeriodParams, dateFrom: dateFrom, dateTo: dateTo};
                dispatch(formInitAction("reportParams", {...reportParams, ...restStatistic, reportPeriodParams: reportPeriodParams}));
                dispatch(listFetchAction("reportItems", content, {...rest}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const exportReport = (data) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingBlobApi.post("/v1/reports/export", data, {responseType: "blob"})
            .then(response => {
                const [, filename] = response.headers['content-disposition'].split('filename=');
                saveAs(response.data, filename.replace(/['"]+/g, ''));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const sendReport = (data) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return billingApi.post( "/v1/reports/send", data)
            .then(response => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, <FormattedMessage id="report.send.success"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("sendReport", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};