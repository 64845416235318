import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Button, ButtonEdit} from "../../../../components/elements/button";
import {Password} from "../../../../components/elements/password";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import {applyPassword, changePassword, generatePassword} from "../../../services/userService";
import {formChangeField} from "../../../services/system/formService";
import {Paragraph} from "../../../../components/elements/paragraph";
import {Link} from "react-router-dom";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../actions/preloaderAction";

class ProfileAccountPassword extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onGeneratePassword = this.onGeneratePassword.bind(this);
        this.state = {
            isEdit: false
        }
    }

    componentDidMount() {
        this.props.onInitForm("profileAccountPassword");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: curForm} = this.props;
        const {form: prevForm} = prevProps;
        const curIsSend = curForm.isSend.profileAccountPassword;
        const prevIsSend = prevForm.isSend.profileAccountPassword;
        const curErrors = curForm.errors.profileAccountPassword || {};

        if (!curIsSend && prevIsSend && Object.keys(curErrors).length == 0) {
            this.onEdit();
        }
    }

    onChange(event) {
        this.props.onFormChangeField('profileAccountPassword', event);
    }

    onEdit() {
        this.props.onInitForm("profileAccountPassword");
        this.setState({...this.state, isEdit: !this.state.isEdit});
    }

    onGeneratePassword() {
        (async () => {
            this.props.onPageShowPreloader();
            const password = await generatePassword();
            this.props.onFormSimpleChange('profileAccountPassword', "password", password);
            this.props.onPageHidePreloader()
        })();
    }

    render() {
        const {form, auth: {user}, profileUserId} = this.props;
        const errors = form.errors.profileAccountPassword;
        const {matchingPassword, currentPassword, password} = form.data.profileAccountPassword || {};
        const {isEdit} = this.state;
        const data = {matchingPassword: matchingPassword, currentPassword: currentPassword, password: password};
        const isApply = user.id != profileUserId;
        return (
            <div className="row form-elements">
                {!isEdit && <EmptyTag>
                    <div className="col-auto">
                        <label className="field-label label-fixed">
                            <FormattedMessage id="password"/>
                        </label>
                        <Paragraph>*************</Paragraph>
                    </div>
                    <div className="col-auto">
                        <ButtonEdit onClick={() => this.onEdit()}/>
                    </div>
                </EmptyTag>}

                {isEdit && <EmptyTag>
                    {user.id == profileUserId && <EmptyTag>
                        <div className="col-md-6">
                            <Password name="password"
                                      icon="password"
                                      placeholder={<FormattedMessage id="new.password"/>}
                                      error={(errors && errors.password) || null}
                                      onChange={this.onChange}/>
                        </div>
                        <div className="col-md-6">
                            <Password name="matchingPassword"
                                      icon="password"
                                      placeholder={<FormattedMessage id="repeat.password"/>}
                                      error={(errors && errors.matchingPassword) || null}
                                      onChange={this.onChange}/>
                        </div>
                        <div className="col-md-6">
                            <Password name="currentPassword"
                                      icon="password"
                                      placeholder={<FormattedMessage id="current.password"/>}
                                      error={(errors && errors.currentPassword) || null}
                                      onChange={this.onChange}/>
                        </div>
                    </EmptyTag>}

                    {user.id !== profileUserId && <EmptyTag>
                        <div className="col-md-6">
                            {password && <Paragraph>{password}<br/></Paragraph>}
                            <div>
                                <Link to={"#"} onClick={() => this.onGeneratePassword()}><FormattedMessage
                                    id="generate.password"/></Link>
                            </div>
                        </div>
                    </EmptyTag>}

                    <div className="col-12">
                        <div className="form-submit">
                            <Button
                                color="white-red"
                                onClick={() => this.onEdit()}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button
                                onClick={() => isApply ?
                                    this.props.onApplyPassword(profileUserId, data) : this.props.onChangePassword(data)}>
                                <FormattedMessage id="core.btn.save.label"/>
                            </Button>
                        </div>
                    </div>
                </EmptyTag>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onChangePassword: (data) => dispatch(changePassword(data)),
        onApplyPassword: (userId, data) => dispatch(applyPassword(userId, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAccountPassword);