import React, {Component} from 'react';
import {connect} from "react-redux";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {approveBookingList, fetchBookings, rejectBookingList} from "../../../services/bookingService";
import {GridCol} from "../../../../components/collections/grid";
import {Link} from "react-router-dom";
import {Widget, WidgetNoResult} from "../../../../components/collections/widget";
import {Avatar} from "../../../../components/collections/avatar";
import moment from "moment/moment";
import {Button} from "../../../../components/elements/button";
import {modalShow} from "../../../services/system/modalService";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import ConfirmReasonRejectedAdvanceContent from "../bookings/ConfirmReasonRejectedAdvanceContent";
import {FormattedMessage} from "react-intl";

class WidgetPatientWaiting extends Component {

    componentDidMount() {
        this.props.onListSorts({timeOfReceipt: 'asc'});
        this.props.onListFilterField("status", "REQUESTED");
        this.props.onFetchBookings(1);
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    render() {
        const {list: {data}, auth: {user}} = this.props;
        const {patientWaitingItems = []} = data;

        return (
            <Widget title={<FormattedMessage id="widget.doctor.patient.waiting.title"/>} linkTo={'/doctor/bookings'}
                    maxHeight={480} minHeight={300}>
                {patientWaitingItems.length === 0 &&
                <WidgetNoResult><FormattedMessage id="widget.patient.patient.waiting.list.empty"/></WidgetNoResult>}
                {patientWaitingItems.length > 0 &&
                <div className="row link-blocks-row link-blocks-tile">
                    {patientWaitingItems.filter((value, index) => index < 4).map(patientWaitingItem => {
                            const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent
                                booking={patientWaitingItem}/>;
                            const {
                                patient: {firstName, lastName, birthday},
                                levelCriticalState,
                                timeOfReceipt,
                                payment = {}
                            } = patientWaitingItem;
                            const {paymentStatus = ""} = payment || {};
                            return (
                                <GridCol count="2" key={patientWaitingItem.id}>
                                    <div className="link-block-wrapper">
                                        <Link to={'/doctor/bookings/' + patientWaitingItem.id + '/detail'}
                                              className="link-block"></Link>
                                        <div className="row person-row align-items-center">
                                            <div className="person-col person-col-name">
                                                <div className="row avatar-row align-items-center">
                                                    <div className="col-auto avatar-row__image">
                                                        <Avatar/>
                                                    </div>
                                                    <div className="col avatar-row__text">
                                                        <div>
                                                            {firstName} {lastName}
                                                            <span className="small-text color-gray">,
                                                                {birthday && <FormattedMessage id="core.birthday.value"
                                                                                               values={{value: moment().diff(birthday, 'years')}}/>}
                                                                </span>
                                                        </div>
                                                        <div className="small-text">
                                                            Priority Level: <span
                                                            className="color-red">{levelCriticalState || 0}</span><span
                                                            className="color-gray">/5</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="person-col person-col-date">
                                                <div className="row date-row align-items-center">
                                                    <div className="col-auto date-row__icon">
                                                        <i className="icon icon--clock"></i>
                                                    </div>
                                                    <div className="col date-row__value color-gray">
                                                        {moment(timeOfReceipt).format("lll")}
                                                    </div>
                                                    <div className="col-auto date-row__status">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="person-col person-col-controls small-controls">
                                                {user.isEnabled && <EmptyTag>
                                                    <Button
                                                        disabled={paymentStatus === 'PENDING'}
                                                        onClick={() => this.props.onConfirm({
                                                            text: <FormattedMessage id="core.confirm.booking.approve"/>,
                                                            onProceed: (() => this.props.onApproveBooking(patientWaitingItem))
                                                        })}><FormattedMessage id="core.btn.approve.label"/></Button>
                                                    <Button color="white-red"
                                                            onClick={() => this.props.onConfirm({
                                                                text: patientWaitingItem.status === 'REQUESTED' ?
                                                                    <FormattedMessage id="core.confirm.booking.reject"/> :
                                                                    <FormattedMessage id="core.confirm.booking.cancel"/>,
                                                                advanceContent: rejectedAdvanceContent,
                                                                labelProceed: <FormattedMessage
                                                                    id="core.confirm.booking.reject.label-proceed"/>,
                                                                onProceed: (() => this.props.onRejectBooking(patientWaitingItem))
                                                            })}>
                                                        {patientWaitingItem.status === 'REQUESTED' &&
                                                        <FormattedMessage id="core.btn.reject.label"/>}
                                                        {patientWaitingItem.status === 'APPROVED' &&
                                                        <FormattedMessage id="core.btn.cancel.label"/>}
                                                    </Button>
                                                </EmptyTag>}
                                            </div>
                                        </div>


                                    </div>
                                </GridCol>
                            );
                        }
                    )}
                </div>}
            </Widget>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onInitList: () => dispatch(listInitAction("patientWaitingItems")),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber, "patientWaitingItems")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("patientWaitingItems", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("patientWaitingItems", sorts)),
        onApproveBooking: (booking) => dispatch(approveBookingList(booking, "patientWaitingItems")),
        onRejectBooking: (booking) => dispatch(rejectBookingList(booking, "patientWaitingItems"))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetPatientWaiting);
