import React, {Component} from 'react';
import {connect} from "react-redux";
import {OTPublisher} from 'opentok-react';
import {noticeShowAction} from "../../../actions/noticeAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../actions/preloaderAction";

import {FormattedMessage} from "react-intl";
import {callCallingInitAction, callCallingPauseAction} from "../../../actions/callAction";
import {formInitAction} from "../../../actions/formAction";

class Publisher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timerId: null
        };
        this.checkCountSubscriber = this.checkCountSubscriber.bind(this);
    }

    componentWillUnmount() {
        if (this.state.timerId) {
            clearTimeout(this.state.timerId);
        }
    }

    publisherEventHandlers = {
        streamCreated: event => {
            console.log('Publisher stream created!');
            this.props.onPreloaderPageHide();
            this.props.onCallCallingInit();
            let timerId = setTimeout(this.checkCountSubscriber, 60000);
            this.setState({...this.state, timerId: timerId});

            const {call: {sessionOptions}} = this.props;
            const {room} = sessionOptions;
            this.props.onInitForm("widgetChosenChatRoom", room);
        },
        streamDestroyed: event => {
            console.log('Publisher streamDestroyed!');
            const {call: {sessionOptions}} = this.props;
            const {room} = sessionOptions;
            const {widgetChosenChatRoom = null} = this.props.form.data;
            if (widgetChosenChatRoom && room && widgetChosenChatRoom.id == room.id) {
                this.props.onInitForm("chatWidget", {'isShowChats': false});
                this.props.onInitForm("widgetChosenChatRoom", null);
            }
            this.props.onCallCallingPause();
            this.props.onNoticeShow('', <FormattedMessage id="call.competed.text"/>);
            this.props.onRedirectTo();
        }
    };

    checkCountSubscriber = () => {
        const {streams = []} = this.props;
        if (streams.length == 0) {
            this.props.onCompleteCall();
        }
    }

    onPublisherError(error) {
        console.log(error);
        this.props.onPreloaderPageHide();
        this.props.onCompleteCall();
        this.props.onNoticeShow('', <FormattedMessage id="call.failed.connect" values={{mgs: error.message}}/>);
        this.props.onRedirectTo();
    }

    render() {
        const {auth: {user}, call: {commonParams}} = this.props;
        const name = {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            role: user.role,
            isIncomingCall: commonParams.callIncoming
        };
        return (
            <OTPublisher
                session={this.props.session}
                properties={{
                    name: JSON.stringify(name),
                    showControls: true,
                    publishAudio: this.props.isMicrophone,
                    publishVideo: this.props.isVideo,
                    resolution: "640x480",
                    audioBitrate: "6000",
                    insertMode: 'after',
                    width: '100%',
                    height: '100%'
                }}
                eventHandlers={this.publisherEventHandlers}
                onError={(error) => this.onPublisherError(error)}/>
        )
    }
}

const mapStateToProps = store => {
    return {
        call: store.call,
        modal: store.modal,
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onCallCallingInit: () => dispatch(callCallingInitAction()),
        onCallCallingPause: () => dispatch(callCallingPauseAction()),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onPreloaderPageShow: () => dispatch(preloaderPageShowAction()),
        onPreloaderPageHide: () => dispatch(preloaderPageHideAction())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Publisher);