import React, {Component} from 'react';
import {connect} from "react-redux";
import Pagination from "react-js-pagination";

import {FormattedMessage} from "react-intl";

import {listInitAction} from "../../../../actions/listAction";
import Dropdown, {DropdownItem} from "../../../systems/dropdown";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Icon, IconItem} from "../../../../components/elements/icon";
import {Header} from "../../../../components/elements/header";
import {fetchPaymentSettings, removePaymentSetting} from "../../../services/paymentService";
import {modalShow} from "../../../services/system/modalService";
import {isModal} from "../../../../utils/app";
import CreatePaymentSetting from "./CreatePaymentSetting";
import {formInitAction} from "../../../../actions/formAction";

class PaymentSettings extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.onPagination(1);
        this.props.onInitForm("createPaymentSetting");
    }

    onPagination(pageNumber) {
        this.props.onFetchPaymentSettings(pageNumber);
    }

    componentWillUnmount() {
        this.props.onInitList("paymentSettings");
    }

    render() {
        const {list: {data: listData, pagination: listPagination}, modal} = this.props;
        const {paymentSettings = []} = listData;
        const {number = 0, size = 0, totalElements = 0} = listPagination['paymentSettings'] || {};
        const dropDownButton = <div>
            <Icon name="menu-points"/>
        </div>;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="payment.setting.provider"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                        <a href="#" onClick={() => this.props.onModalShow("createPaymentSetting")}
                           className="btn-icon color-blue">
                            <IconItem name="plus-bordered"><FormattedMessage id="core.btn.add.label"/></IconItem>
                        </a>
                    </div>
                </div>
                <div className="row form-elements">
                    <div className="col-auto"></div>
                    <div className="col-auto"></div>
                </div>

                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><FormattedMessage id="payment.method"/></th>
                            <th><FormattedMessage id="payment.provider"/></th>
                            <th><FormattedMessage id="country"/></th>
                            <th><FormattedMessage id="currency"/></th>
                            <th><FormattedMessage id="payment.gateway"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {paymentSettings.map(paymentSetting => {
                            const {id, paymentProvider, countryIso, currency, paymentGateway} = paymentSetting;
                            return (
                                <tr key={id}>
                                    <td className="td-controls">
                                        <Dropdown button={dropDownButton} noArrow="true">
                                                <DropdownItem to={"#"} icon="edit"
                                                              onClick={() => this.props.onModalShow("createPaymentSetting", paymentSetting)}>
                                                    <FormattedMessage id="core.btn.edit.label"/></DropdownItem>

                                                <DropdownItem to={"#"} icon="delete"
                                                              onClick={() => this.props.onConfirm({
                                                                  text: <FormattedMessage
                                                                      id="confirm.remove.payment.setting"/>,
                                                                  onProceed: (() => this.props.onRemovePaymentSetting(id))
                                                              })}><FormattedMessage id="core.delete.label"/></DropdownItem>
                                        </Dropdown>
                                    </td>
                                    <td>{paymentProvider.paymentMethod && paymentProvider.paymentMethod.name}</td>
                                    <td>{paymentProvider.name}</td>
                                    <td>{countryIso || '*'}</td>
                                    <td>{(currency && currency.iso) || <FormattedMessage id="country.currency"/>}</td>
                                    <td>{(paymentGateway && paymentGateway.name) || <FormattedMessage id="core.none.label"/>}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}

                </div>
                {isModal(modal, 'createPaymentSetting') && <CreatePaymentSetting/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data = {}) => dispatch(formInitAction(formName, data)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFetchPaymentSettings: (pageNumber) => dispatch(fetchPaymentSettings(pageNumber)),
        onRemovePaymentSetting : (id) => dispatch(removePaymentSetting(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettings);