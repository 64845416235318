import React, {Component} from 'react';
import {connect} from "react-redux";
import {ButtonIcon} from "../../../components/elements/button";
import {sendMessage} from "../../services/chatService";
import {formChangeField} from "../../services/system/formService";
import {FormattedMessage} from "react-intl";
import {formInitAction} from "../../../actions/formAction";

class ChatSendMessage extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentWillUnmount() {
        const {formNameNewMessage} = this.props;
        this.props.onInitForm(formNameNewMessage, {});
    }

    onChange(event) {
        const {formNameNewMessage} = this.props;
        this.props.onFormChangeField(formNameNewMessage, event);
    }

    onKeyDown(event) {
        const {form: {data}, formNameChosenChatRoom, listNameChatRoomMessages, formNameNewMessage} = this.props;
        const chatRoomNewMessage = data[formNameNewMessage] || {};
        const {id: roomId = null} = data[formNameChosenChatRoom] || {};
        switch (event.key) {
            case "Enter" :
                if (chatRoomNewMessage.text != '')
                    this.props.onSendMessage(
                        listNameChatRoomMessages, formNameNewMessage, chatRoomNewMessage.text, roomId)
                break;
            default:

        }
    }

    render() {
        const {form: {data}, auth: {user}, formNameChosenChatRoom, listNameChatRoomMessages, formNameNewMessage} = this.props;
        const chatRoomNewMessage = data[formNameNewMessage] || {};
        const {id: roomId = null, is_open = false, participants = []} = data[formNameChosenChatRoom] || {};
        const {text = ""} = chatRoomNewMessage || {}
        const disabled = text === "" || text.length > 600;
        const isParticipant = participants.filter(p => p["user"]["id"] == user.id).length > 0;
        if (!user.isEnabled || !is_open || !isParticipant)
            return "";
        return (
            <div className="chat-footer">
                <div className="row chat-controls-row align-content-center">
                    <div className="col-auto chat-control-file"></div>
                    <div className="col chat-control-text show-placeholder">
                        <FormattedMessage id="booking.detail.chat.type-something">
                            {
                                placeholder => <input type="text" name="text"
                                                      value={text || ''}
                                                      error={null}
                                                      placeholder={placeholder}
                                                      disabled={false}
                                                      onKeyDown={this.onKeyDown}
                                                      onChange={this.onChange}/>
                            }

                        </FormattedMessage>
                        {text && text.length > 600 && <div className="color-red field-tip">
                            <FormattedMessage id="too.many.symbols" />
                            </div>}
                    </div>
                    <div className="col-auto chat-control-btn">
                        <ButtonIcon disabled={disabled} icon="send"
                                    onClick={() => this.props.onSendMessage(
                                        listNameChatRoomMessages, formNameNewMessage, chatRoomNewMessage.text, roomId)}/>
                    </div>
                </div>
            </div>
        );

    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onSendMessage: (listNameChatRoomMessages, formNameNewMessage, text, roomId) =>
            dispatch(sendMessage(listNameChatRoomMessages, formNameNewMessage, text, roomId)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatSendMessage);