import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {
    fetchPharmacyTodayBookings,
    fetchPharmacyTodayPatients
} from "../../../services/pharmacyService";
import {Avatar} from "../../../../components/collections/avatar";
import moment from "moment";
import {Button} from "../../../../components/elements/button";
import {modalShow} from "../../../services/system/modalService";
import {medicationDurationFormat, todayIssueMedication} from "../../../services/consultationService";
import {FormattedMessage} from "react-intl";
import {getMedicationForm} from "../../../../utils/app";

class PharmacyTodayIssuePatients extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.props.onFetchPharmacyTodayPatients(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchPharmacyTodayPatients(pageNumber);
    }

    render() {
        const {list: {data, pagination}} = this.props;
        const {todayIssuePatients = [], patientTodayIssues = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['todayIssuePatients'] || {};
         return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.pharmacy.today.issue.header"/></Header>
                    </div>
                </div>

                {todayIssuePatients.length === 0 &&
                <div className="no-results-block">
                    <i className="icon icon--calendar-big"></i>
                    <div><FormattedMessage id="page.pharmacy.today.issue.patients.list.empty"/></div>
                </div>}

                {todayIssuePatients.length > 0 &&
                <div className="row content-row">

                    <div className="col-md-5">
                        <div className="white-block">
                            <Header as="h2"><FormattedMessage id="pharmacy.list.patients.header"/></Header>
                            <div className="row link-blocks-row blocks-hover-bg blocks-no-space blocks-bg-white">
                                {todayIssuePatients.map((patient) => {
                                    const {firstName, lastName, id} = patient;
                                    return (
                                        <div className="col-12" key={id}>
                                            <div className="link-block-wrapper">
                                                <a href="#" className="link-block"
                                                   onClick={() => this.props.onFetchPharmacyTodayBookings(patient, 1)}></a>
                                                <div className="row avatar-row align-items-center">
                                                    <div className="col-auto avatar-row__image">
                                                        <Avatar/>
                                                    </div>
                                                    <div className="col avatar-row__text">
                                                        <div>{firstName} {lastName}</div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <span className="color-gray big-text">›</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}
                        </div>
                    </div>
                    {patientTodayIssues.length > 0 &&
                    <div className="col-md-7">
                        <div className="white-block">

                            {patientTodayIssues.map(booking => {
                                const {id: bookingId, doctor, consultation} = booking;
                                const {medications} = consultation;
                                return (
                                    <div className="row form-elements form-padding-15" key={bookingId}>
                                        <div className="col-12">#{bookingId}</div>
                                        <div className="col">
                                            <Header as="h3">
                                                <FormattedMessage id="page.pharmacy.today.issue.prescription.label"/> <span
                                                className="color-green"><FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></span>
                                                <i className="icon icon--doctors-green"></i>
                                            </Header>
                                        </div>

                                        <div
                                            className="col-auto">{moment(consultation.createdAtPrescribe).format("ll")}</div>
                                        {medications.map(medication => {
                                            const {
                                                prescribedMedication, repeatPrescriptions, duration, directionsForUse,
                                                countOfIssues, lastIssueAt, nextIssueAt
                                            } = medication;
                                            return (
                                                <div className="col-12">
                                                    <div
                                                        className="row form-elements form-padding-15 form-bg-light form-radius-8">
                                                        <div className="col-md-7">
                                                            <Header as="h4">{prescribedMedication}</Header>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <div className="align-right"></div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <div className="small-text">
                                                                <div>
                                                                    <span className="color-gray">
                                                                        {<FormattedMessage id={getMedicationForm(medication.form)}/>}, {directionsForUse}
                                                                    </span>&nbsp;
                                                                    {<FormattedMessage id={medicationDurationFormat(repeatPrescriptions, duration)}
                                                                                       values={{repeat: repeatPrescriptions}}/>}
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        className="color-gray"><FormattedMessage id="page.pharmacy.today.issue.latest.issue.label"/></span>&nbsp;
                                                                    {lastIssueAt ? moment(lastIssueAt).format("ll") : "-"}
                                                                </div>
                                                                <div>
                                                                    <span
                                                                        className="color-gray"><FormattedMessage id="page.pharmacy.today.issue.next.issue.label"/></span>&nbsp;
                                                                    {nextIssueAt ? moment(nextIssueAt).format("ll") : "-"}
                                                                </div>
                                                            </div>
                                                            <div className="small-controls">
                                                                <Button
                                                                    onClick={() => this.props.onConfirm({
                                                                        text: <FormattedMessage id="confirm.issuied.text" values={{medication: prescribedMedication}}/>,
                                                                        onProceed: (() => this.props.onTodayIssueMedication(booking, medication))
                                                                    })}><FormattedMessage id="core.btn.issuied.label"/></Button>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-5">
                                                            <div className="white-mark mark-right-bottom">
                                                                <FormattedMessage id="core.issued.label"/>
                                                                <div className="font-size-40">
                                                            <span
                                                                className="color-green">{countOfIssues || 0}</span><span
                                                                    className="color-gray">/{repeatPrescriptions}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>

                                );
                            })}

                        </div>

                    </div>}

                </div>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchPharmacyTodayPatients: (pageNumber) => dispatch(fetchPharmacyTodayPatients(pageNumber)),
        onFetchPharmacyTodayBookings: (patient, pageNumber) => dispatch(fetchPharmacyTodayBookings(patient, pageNumber)),
        onTodayIssueMedication: (booking, medication) => dispatch(todayIssueMedication(booking, medication)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyTodayIssuePatients);
