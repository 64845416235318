import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../components/elements/emptyTag";
import {Header} from "../../../components/elements/header";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Input} from "../../../components/elements/input";
import {Option, Select} from "../../../components/elements/select";
import Password from "../../systems/password";
import {Checkbox} from "../../../components/elements/checkbox";
import {Button, Buttons} from "../../../components/elements/button";
import {formChangeField} from "../../services/system/formService";
import {formChangeFieldAction} from "../../../actions/formAction";
import {Paragraph} from "../../../components/elements/paragraph";
import {Label} from "../../../components/elements/label";
import qs from "query-string";
import {InputError} from "../../../components/elements/error";
import {MainLogo} from "../../../components/elements/logo";
import moment from "moment-timezone";
import {signUpAssistant} from "../../services/doctorService";
import Birthday from "../../systems/birthday";
import {FormattedMessage} from "react-intl";
import {getSexInput, getSexsInput} from "../../../utils/app";
import AgreeText from "../users/AgreeText";

class SignUpAssistant extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectSex = this.onSelectSex.bind(this);
        this.onChangeBirthday = this.onChangeBirthday.bind(this);
    }

    componentDidMount() {
        this.props.onFormSimpleChange("signUpAssistant", "timezone", moment.tz.guess(true));
    }

    onChange(event) {
        this.props.onFormChangeField('signUpAssistant', event);
    }

    onSelectSex(id) {
        this.props.onChangeDropdown("sex", id);
    }

    onChangeBirthday(day, month, year) {
        if (day != null && month != null && year != null) {
            const birthday = moment().set({'date': day, 'month': month, 'year': year});
            if (moment([year, month, day]).isValid()) {
                this.props.onFormSimpleChange("signUpAssistant", "birthday", birthday.format("DD.MM.YYYY"));
            } else {
                this.props.onFormSimpleChange("signUpAssistant", "birthday", null);
            }
        } else {
            this.props.onFormSimpleChange("signUpAssistant", "birthday", null);
        }
    }

    render() {
        const {form: {data: formData, errors: formErrors, isSend: formIsSend}} = this.props;
        const errors = formErrors["signUpAssistant"];
        const assistant = formData["signUpAssistant"] || {};
        const disabled = formIsSend["signUpAssistant"] || false;

        const {match: {params}} = this.props;
        const {token = ""} = params || {}

        const search = this.props.location.search;
        const {email = ""} = qs.parse(search);
        return (
            <EmptyTag>
                <MainLogo/>

                <EmptyTag>
                    <Header as="h2"><FormattedMessage id="page.sign-up.assistant.description"/></Header>
                    <GridRow>
                        <GridCol count="2">
                            <Input name="firstName"
                                   icon="user"
                                   value={assistant.firstName || ""}
                                   error={(errors && errors.firstName) || null}
                                   placeholder={<FormattedMessage id="core.first.name.label"/>}
                                   disabled={disabled}
                                   onChange={this.onChange}/>
                        </GridCol>
                        <GridCol count="2">
                            <Input name="lastName"
                                   icon="user"
                                   value={assistant.lastName || ""}
                                   error={(errors && errors.lastName) || null}
                                   placeholder={<FormattedMessage id="core.last.name.label"/>}
                                   disabled={disabled}
                                   onChange={this.onChange}/>
                        </GridCol>
                        <GridCol count="2">
                            <Label fixed={true}><FormattedMessage id="core.field.email.label"/></Label>
                            <Paragraph>{email}</Paragraph>
                            {errors && errors.email && <InputError>{errors.email}</InputError>}
                        </GridCol>
                        <GridCol count="2">
                            <Input name="phone"
                                   icon="phone"
                                   error={(errors && errors.phone) || null}
                                   placeholder={<FormattedMessage id="core.phone.label"/>}
                                   disabled={disabled}
                                   onChange={this.onChange}/>
                        </GridCol>
                        <GridCol count="2">
                            <FormattedMessage id={getSexInput(assistant['sex']) || 'undefined'}>
                                {optionNameSelected =>
                                    <Select name="sex"
                                            icon="gender"
                                            error={(errors && errors.sex) || null}
                                            disabled={disabled}
                                            label={<FormattedMessage id="core.gender.label"/>}
                                            optionNameSelected={optionNameSelected}>
                                        {
                                            getSexsInput().map(key =>
                                                <Option
                                                    active={assistant['sex'] && assistant['sex'] === key}
                                                    key={key}
                                                    onClick={() => this.onSelectSex(key)}>

                                                    {<FormattedMessage id={getSexInput(key)}/>}
                                                </Option>
                                            )
                                        }
                                    </Select>}
                            </FormattedMessage>
                        </GridCol>
                        <GridCol count="2">
                            <Label><FormattedMessage id="core.birthday.label"/></Label>
                            <Birthday error={(errors && errors.birthday) || null} value={assistant.birthday || null}
                                      onChangeBirthday={this.onChangeBirthday}/>
                        </GridCol>
                        <GridCol count="2">
                            <Password name="password"
                                      icon="password"
                                      placeholder={<FormattedMessage id="core.field.password.label"/>}
                                      error={(errors && errors.password) || null}
                                      disabled={disabled}
                                      onChange={this.onChange}/>
                        </GridCol>
                        <GridCol count="2">
                            <Password name="matchingPassword"
                                      icon="password"
                                      placeholder={<FormattedMessage id="core.field.password.repeat.label"/>}
                                      error={(errors && errors.matchingPassword) || null}
                                      disabled={disabled}
                                      onChange={this.onChange}/>
                        </GridCol>
                        <GridCol count="1">
                            <div className="agreement-block">
                                <Checkbox name="isAgree"
                                          error={(errors && errors.isAgree) || null}
                                          disabled={disabled}
                                          onChange={this.onChange}>
                                    <AgreeText role={'assistant'}/>
                                </Checkbox>
                            </div>
                        </GridCol>
                    </GridRow>
                    <GridRow width={300}>
                        <GridCol count="1">
                            <Buttons>
                                <Button disabled={disabled}
                                        preloader={disabled === true}
                                        onClick={() => this.props.onSignUpAssistant(
                                            {...assistant, token: token, email: email})}><FormattedMessage
                                    id="core.btn.sign.up.label"/></Button>
                            </Buttons>
                        </GridCol>
                    </GridRow>
                </EmptyTag>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth,
        language:store.language
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onSignUpAssistant: (data) => dispatch(signUpAssistant(data)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('signUpAssistant', fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpAssistant);