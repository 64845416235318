import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";

export const fetchCurrencyRates = (dateOfRate, listName = "currencyRates") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/currencies/rates?dateOfRate=" + dateOfRate)
            .then(response => {
                const {data = []} = response;
                dispatch(listFetchAction(listName, data, {}));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const saveCurrencyRates = (dateOfRate, newRates = {}) => {
    return (dispatch) => {
        const data = {
            dateOfRate: dateOfRate,
            rates: newRates
        };

        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/currencies/rates", data)
            .then(response => {
                dispatch(fetchCurrencyRates(dateOfRate));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};