import {privateApi} from '../../utils/api';
import {listFetchAction} from "../../actions/listAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";

export const fetchPharmacyTodayPatients = (pageNumber) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/pharmacies/todayIssuePatients?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("todayIssuePatients", content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchPharmacyTodayBookings = ({id: patientId}, pageNumber) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/pharmacies/patients/" + patientId + "/todayIssues?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("patientTodayIssues", content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchPharmacyPatients = (pageNumber) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/pharmacies/patients?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("pharmacyPatients", content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchPharmacyPatientPrescriptions = ({id:patientId}, pageNumber) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/pharmacies/patients/" + patientId + "/prescriptions?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("pharmacyPatientPrescriptions", content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchPharmacyNewPrescriptions = (pageNumber) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/pharmacies/newPrescriptions?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("pharmacyNewPrescriptions", content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};