import {DROPDOWN_VISIBLE, DROPDOWN_NOT_VISIBLE} from "../constants/actionTypes";

const initialState = {
    isVisible: false
};

const dropdownReducer = (state = initialState, action) => {
    switch (action.type) {
        case DROPDOWN_VISIBLE:
            return {...state, isVisible: true};
        case DROPDOWN_NOT_VISIBLE:
            return {...state, isVisible: false};
        default:
            return state;
    }
};

export default dropdownReducer;