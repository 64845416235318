import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../components/modules/modal";
import {Button} from "../../../../components/elements/button";
import {modalHide} from "../../../services/system/modalService";
import {withRouter} from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../components/elements/input";
import {getRoleInput, getRolesInput} from "../../../../utils/app";
import {Option, Select} from "../../../../components/elements/select";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {formChangeField} from "../../../services/system/formService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import {listFilterAction, listFilterFieldAction} from "../../../../actions/listAction";
import _ from 'lodash'
import {GridCol} from "../../../../components/collections/grid";
import {DatePicter} from "../../../../components/elements/datepicter";
import moment from "moment";

class UsersFilter extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectRole = this.onSelectRole.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    componentDidMount() {
        const {list: {filters}, form: {data: formData}} = this.props;
        const {users: listUsersFilter = {}} = filters;
        const {originUsersFilter = {}} = formData;

        if (Object.keys(listUsersFilter).length == 0) {
            this.props.onInitForm("usersFilter", {});
        } else {
            Object.keys(originUsersFilter).map(f => {
                if (!listUsersFilter[f]) {
                    _.unset(originUsersFilter, f);
                }
            })
            this.props.onInitForm("usersFilter", originUsersFilter)
        }
    }

    onChange(event) {
        this.props.onFormChangeField('usersFilter', event);
    }

    onSelectRole(id) {
        this.props.onChangeDropdown("role", id);
    }

    onSave = () => {
        const {form, list: {filters}, onFilter} = this.props;
        const {users: listUsersFilter = {}} = filters;
        const {data: {usersFilter = {}}} = form;
        let chosenFilters = {};

        Object.keys(listUsersFilter).map(f => {
            if (!usersFilter[f]) {
                _.unset(listUsersFilter, f);
            }
        })

        this.props.onInitForm("originUsersFilter", usersFilter);

        Object.keys(usersFilter).map(keyFilter => {
            if (usersFilter[keyFilter] == null || usersFilter[keyFilter] == "") return;
            switch (keyFilter) {
                case "role" :
                    chosenFilters[keyFilter] = getRoleInput(usersFilter[keyFilter]);
                    listUsersFilter[keyFilter] = usersFilter[keyFilter];
                    break;
                case "createdAtFrom" :
                case "createdAtTo" :
                    chosenFilters[keyFilter] = moment(usersFilter[keyFilter], "YYYY-MM-DD").format("ll");
                    listUsersFilter[keyFilter] = usersFilter[keyFilter];
                    break;
                default :
                    chosenFilters[keyFilter] = usersFilter[keyFilter];
                    listUsersFilter[keyFilter] = usersFilter[keyFilter];
            }

        });

        this.props.onInitFilter(listUsersFilter);
        this.props.onInitForm("chosenFilters", chosenFilters);
        this.props.onModalHide('usersFilter');
        onFilter();
    }

    onClear = () => {
        const {onFilter} = this.props;
        this.props.onInitFilter({});
        this.props.onModalHide('userFilter');
        onFilter();
    }

    onChangeDate(objMoment, fieldName) {
        if (moment.isMoment(objMoment)) {
            const fieldValue = moment(objMoment, "YYYY-MM-DD").isValid() ? objMoment.format("YYYY-MM-DD") : null
            this.props.onFormSimpleChange('usersFilter', fieldName, fieldValue);
        } else if (objMoment == null || objMoment == "") {
            this.props.onFormSimpleChange('usersFilter', fieldName, null);

        }
    }

    render() {
        const {form} = this.props;
        const {data: {usersFilter = {}}} = form;

        return (
            <Modal close={true} onClose={() => this.props.onModalHide('usersFilter')}>
                <h2><FormattedMessage id="filter"/></h2>

                <div className="row form-elements">
                    <GridCol count="1">
                        <Input name="firstName"
                               value={usersFilter.firstName || ""}
                               placeholder={<FormattedMessage id="core.first.name.label"/>}
                               onChange={this.onChange}/>
                    </GridCol>
                    <GridCol count="1">
                        <Input name="lastName"
                               value={usersFilter.lastName || ""}
                               placeholder={<FormattedMessage id="core.last.name.label"/>}
                               onChange={this.onChange}/>
                    </GridCol>
                    <GridCol count="1">
                        <FormattedMessage id={getRoleInput(usersFilter.role) || 'any'}>
                            {optionNameSelected =>
                                <Select name="role"
                                        label={<FormattedMessage id="role"/>}
                                        optionNameSelected={optionNameSelected}>
                                    <EmptyTag>
                                        <Option
                                            active={!usersFilter.role}
                                            onClick={() => this.onSelectRole(null)}>
                                            <FormattedMessage id={'any'}/>
                                        </Option>
                                        {getRolesInput().map(key =>
                                            <Option
                                                active={usersFilter.role && usersFilter.role == key}
                                                key={key}
                                                onClick={() => this.onSelectRole(key)}>
                                                {<FormattedMessage id={getRoleInput(key)}/>}
                                            </Option>
                                        )}
                                    </EmptyTag>

                                </Select>}
                        </FormattedMessage>
                    </GridCol>

                    <GridCol count="1">
                        <Input name="email"
                               value={usersFilter.email || ""}
                               placeholder={<FormattedMessage id="core.field.email.label"/>}
                               onChange={this.onChange}/>
                    </GridCol>

                    <GridCol count="1">
                        <div className="row small-form-row align-items-center">
                            <div className="col-auto">
                                <label className="field-label"><FormattedMessage id="createdAt"/></label>
                            </div>
                            <div className="col">
                                <FormattedMessage id={'from.label'}>
                                    {optionNameSelected =>
                                        <DatePicter bg_white={true}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    onChange={(v) => this.onChangeDate(v, 'createdAtFrom')}
                                                    placeholder={optionNameSelected}
                                                    value={usersFilter.createdAtFrom && moment(usersFilter.createdAtFrom, 'YYYY-MM-DD')}
                                                    dateFormat={'ll'}/>
                                    }</FormattedMessage>
                            </div>
                            <div className="col-auto">
                                -
                            </div>
                            <div className="col">
                                <FormattedMessage id={'to.label'}>
                                    {optionNameSelected =>
                                        <DatePicter bg_white={true}
                                                    timeFormat={false}
                                                    closeOnSelect={true}
                                                    onChange={(v) => this.onChangeDate(v, 'createdAtTo')}
                                                    placeholder={optionNameSelected}
                                                    value={usersFilter.createdAtTo && moment(usersFilter.createdAtTo, 'YYYY-MM-DD')}
                                                    dateFormat={'ll'}/>}</FormattedMessage>
                            </div>
                        </div>
                    </GridCol>

                    <div className="col-12">
                        <div className="form-submit">
                            <Button color="white-red" onClick={this.onClear}>
                                <FormattedMessage id="clear.filter"/>
                            </Button>
                            <Button onClick={this.onSave}>
                                <FormattedMessage id="core.btn.save.label"/>
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onInitFilter: (filters) => dispatch(listFilterAction('users', filters)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('usersFilter', fieldName, fieldValue)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersFilter));
