import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {InputError} from "../../../components/elements/error";
import {Label} from "../../../components/elements/label";
import {ButtonRemove} from "../../../components/elements/button";
import classnames from "classnames";

export const AutocompleteAsync = (props) => {
    const {
        isCanDelete = false,
        required = false,
        loading = false,
        options = [],
        onInputChange = null,
        onChange = null,
        onDelete = null,
        placeholderInside = false,
        getOptionLabel = null,
        multiple = false,
        error = null,
        placeholder = null,
        placeholderFixed = false,
        ...rest
    } = props;
    const {value} = props;
    const boxClassNames = classnames({
        'MuiAutocomplete-inputFilled': value !== null && value !== ""
    });

    return (
        <EmptyTag>
            {!placeholderInside && <Label fixed={placeholderFixed} required={required}>{placeholder}</Label>}
            <Autocomplete
                {...rest}
                classes={{"inputFocused": "MuiAutocomplete-inputFocused"}}
                multiple={multiple}
                onInputChange={onInputChange}
                onChange={onChange}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={getOptionLabel}
                options={options}
                loading={loading}
                renderInput={params => (
                    <div className={boxClassNames}>
                        <TextField
                            {...params}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                        {placeholderInside && <Label required={required}>{placeholder}</Label>}
                        {isCanDelete &&
                            <div className="controls-top-right">
                                <ButtonRemove onClick={onDelete}/>
                            </div>}
                    </div>
                )}
            />
            {error !== null && <InputError>{error}</InputError>}
        </EmptyTag>
    );
};