import React, {Component} from 'react';
import {connect} from "react-redux";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {GridCol} from "../../../../components/collections/grid";
import {Link} from "react-router-dom";
import {Widget, WidgetNoResult} from "../../../../components/collections/widget";
import {Avatar} from "../../../../components/collections/avatar";
import moment from "moment/moment";
import {Button} from "../../../../components/elements/button";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import {
    approvedExtraConsultation,
    canceledExtraConsultation,
    fetchExtraConsultations
} from "../../../services/extraConsultationsService";

class WidgetDoctorsInvitations extends Component {

    componentDidMount() {
        this.props.onListSorts({id: 'desc'});
       /* this.props.onListFilterField("status", "REQUESTED");*/
        this.props.onFetchExtraConsultations(1);
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    render() {
        const {list: {data}, auth: {user: currentUser}} = this.props;
        const {extraConsultations = []} = data;
        if (extraConsultations.length == 0) return "";
        return (
            <Widget title={<FormattedMessage id="widget.doctors.invitations.title"/>} linkTo={'/doctor/bookings'}
                    maxHeight={480} minHeight={300}>
                {extraConsultations.length === 0 &&
                <WidgetNoResult><FormattedMessage id="list.empty"/></WidgetNoResult>}
                {extraConsultations.length > 0 &&
                <div className="row link-blocks-row link-blocks-tile">
                    {extraConsultations.filter((value, index) => index < 4).map(extraConsultation => {
                            const {id, consultation, status, user} = extraConsultation;
                            const booking = consultation.booking;
                            const patient = booking.patient;
                            const doctor = user.id == currentUser.id ? booking.doctor : user;
                            return (
                                <GridCol count="2" key={extraConsultation.id}>
                                    <div className="link-block-wrapper">
                                        <Link to={'/doctor/bookings/' + booking.id + '/detail'}
                                              className="link-block"></Link>
                                        <div className="row person-row align-items-center">
                                            <div className="person-col person-col-name">
                                                <div className="row avatar-row align-items-center">
                                                    <div className="col-auto avatar-row__image">
                                                        <Avatar/>
                                                    </div>
                                                    <div className="col avatar-row__text">
                                                        <div>
                                                            <FormattedMessage id="core.doctor.name" values={{
                                                                firstName: doctor.firstName,
                                                                lastName: doctor.lastName
                                                            }}/>
                                                        </div>
                                                        <div className="small-text color-gray">
                                                            <FormattedMessage id="core.patient.name" values={{
                                                                firstName: patient.firstName,
                                                                lastName: patient.lastName
                                                            }}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="person-col person-col-date">
                                                <div className="row date-row align-items-center">
                                                    <div className="col-auto date-row__icon">
                                                        <i className="icon icon--clock"></i>
                                                    </div>
                                                    <div className="col date-row__value color-gray">
                                                        {moment(booking.timeOfReceipt).format("lll")}
                                                    </div>
                                                    <div className="col-auto date-row__status">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="person-col person-col-controls small-controls">
                                                {status === 'REQUESTED' && user.id == currentUser.id &&
                                                <Button onClick={() => this.props.onConfirm({
                                                    text: <FormattedMessage id="confirm.accept"/>,
                                                    onProceed: (() => this.props.onApprovedExtraConsultation(id))
                                                })}><FormattedMessage id="core.btn.accept.label"/></Button>}

                                                <Button color="white-red" onClick={() => this.props.onConfirm({
                                                    text: <FormattedMessage id="confirm.cancel"/>,
                                                    onProceed: (() => this.props.onCanceledExtraConsultation(id))
                                                })}><FormattedMessage id="core.btn.cancel.label"/></Button>
                                            </div>
                                        </div>
                                    </div>
                                </GridCol>
                            );
                        }
                    )}
                </div>}
            </Widget>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitList: () => dispatch(listInitAction("extraConsultations")),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchExtraConsultations: (pageNumber) => dispatch(fetchExtraConsultations(pageNumber)),
        onApprovedExtraConsultation: (id) => dispatch(approvedExtraConsultation(id)),
        onCanceledExtraConsultation: (id) => dispatch(canceledExtraConsultation(id)),
        onListSorts: (sorts) => dispatch(listSortAction("extraConsultations", sorts)),
        onListFilterField: (fieldName, fieldValue) =>
            dispatch(listFilterFieldAction("extraConsultations", fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WidgetDoctorsInvitations);
