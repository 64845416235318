import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {formFieldErrorsAction, formInitAction} from "../../actions/formAction";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import {updateProfileAction} from "../../actions/authAction";
import {formErrors, formIsSend} from "./system/formService";
import React from "react";

export const fetchProfileGeneral = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/profiles/general")
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("profile", data));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const updateProfileGeneral = (updateProfile) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/profiles/general", updateProfile)
            .then(response => {
                dispatch(updateProfileAction({
                    ...user,
                    lastName: updateProfile.lastName,
                    firstName: updateProfile.firstName
                }));
                dispatch(formFieldErrorsAction("profile", []));
                dispatch(fetchProfileGeneral());
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("profile", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
                dispatch(formIsSend("profile", false));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};