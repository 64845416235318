import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Avatar} from "../../../../../components/collections/avatar";
import {Button} from "../../../../../components/elements/button";
import {Icon} from "../../../../../components/elements/icon";
import {
    approveBookingList,
    fetchBookings,
    fetchScheduleByDate,
    rejectBookingList
} from "../../../../services/bookingService";
import {GridCol} from "../../../../../components/collections/grid";
import {Header} from "../../../../../components/elements/header";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../../actions/listAction";
import {Link} from "react-router-dom";
import {modalShow} from "../../../../services/system/modalService";
import {createSortObject, isModal} from "../../../../../utils/app";
import Rescheduling from "../detail/Rescheduling";
import ScheduleCalendar from "../../schedule/ScheduleCalendar";
import {PaymentStatus} from "../../../../../components/collections/payment_status";
import AssistantPatientDetail from "../../../assistant/patients/AssistantPatientDetail";
import ConfirmReasonRejectedAdvanceContent from "../ConfirmReasonRejectedAdvanceContent";
import {formInitAction} from "../../../../../actions/formAction";
import {FormattedMessage} from "react-intl";
import {Redirect} from "react-router";

class DoctorBookingsByStatus extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onFilter = this.onFilter.bind(this);
        this.onChooseCalendarDate = this.onChooseCalendarDate.bind(this);
    }

    componentDidMount() {
        const {status = null, statuses = null, auth: {user}, doctor, sort = null} = this.props;
        if (status != null) {
            this.props.onListFilterField("status", status);
        } else if (statuses != null) {
            this.props.onListFilterField("statuses", statuses);
        }

        if (sort != null) {
            this.props.onListSorts(createSortObject(sort));
        }

        if (user.role === 'ASSISTANT') {
            this.props.onListFilterField("doctorId", doctor.id);
        }


        this.props.onInitForm("scheduleCalendar", {doctor: doctor, isReload: false});
        this.onPagination(1);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData = {}}, auth: {user}} = this.props;
        const {form: {data: prevFormData = {}}} = prevProps;

        const {chooseDoctor: curChooseDoctor = {}} = curFormData;
        const {chooseDoctor: prevChooseDoctor = {}} = prevFormData;
        const {id: curChooseDoctorId = null} = curChooseDoctor;
        const {id: prevChooseDoctorId = null} = prevChooseDoctor;

        if (curChooseDoctorId != prevChooseDoctorId) {
            if (user.role === 'ASSISTANT') {
                this.props.onListFilterField("doctorId", curChooseDoctorId);
                this.onPagination(1);
            }
        }
    }

    onPagination(pageNumber) {
        this.props.onFetchBookings(pageNumber);
    }

    onFilter(fieldName, fieldValue) {
        this.props.onListFilterField(fieldName, fieldValue);
        this.onPagination(1);
    }

    onChooseCalendarDate(date) {
        const {doctor} = this.props;
        const tms = moment(date).unix();
        this.onFilter("dateOfReceipt", tms + "000");
        this.props.onFetchScheduleByDate(doctor, moment(date).format("YYYY-MM-DD"));
    }

    componentWillUnmount() {
        this.props.onInitList("bookings");
        this.props.onInitList("doctorSchedule");
    }

    render() {
        const {list: {data, pagination, filters}, form: {data: formData}, modal, auth: {user}, doctor} = this.props;
        if (user.role === 'DOCTOR' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }
        const {bookings = [], doctorSchedule = []} = data;
        const {scheduleCalendar} = formData;
        const {number = 0, size = 0, totalElements = 0} = pagination['bookings'] || {};
        const tms = moment().hour(0).minute(0).second(0).unix();
        const {dateOfReceipt = tms + "000"} = filters['bookings'] || {};
        let groupByDateBookings = {};
        bookings.map(booking => {
            const date = moment(booking.timeOfReceipt).format("ll");
            if (!groupByDateBookings[date]) groupByDateBookings[date] = [];
            groupByDateBookings[date].push(booking);
        });

        return (
            <EmptyTag>
                <div className="col-md-8">
                    <div className="row link-blocks-row">
                        {bookings.length === 0 &&
                        <div className="col-12"><FormattedMessage id="doctor.bookings.empty"/></div>}

                        {Object.keys(groupByDateBookings).map((date) => {
                                return (
                                    <EmptyTag key={date}>
                                        <GridCol count="1">
                                            <Header as="h4">{date}</Header>
                                        </GridCol>
                                        {groupByDateBookings[date].map(booking => {
                                            const {rescheduling, timeOfReceipt, status, isBusyTimeOfReceipt, levelCriticalState, patient: {firstName, lastName, birthday}} = booking;
                                            const isStartedConsultation = booking.consultation && booking.consultation.isStarted === true;
                                            const {symptoms, payment = {}} = booking;
                                            const {paymentStatus = ""} = payment || {};
                                            const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent
                                                booking={booking}/>;
                                            return (
                                                <GridCol count="1" key={booking.id}>
                                                    <div className="link-block-wrapper">
                                                        {user.role === 'DOCTOR' &&
                                                        <Link to={'/doctor/bookings/' + booking.id + '/detail'}
                                                              className="link-block"></Link>}

                                                        {user.role === 'ASSISTANT' &&
                                                        <Link to={'#'}
                                                              onClick={() => this.props.onOpenAssistantPatientDetail({booking: booking})}
                                                              className="link-block"></Link>}

                                                        <div className="row person-row align-items-center">
                                                            <div className="person-col person-col-name">
                                                                <div className="row avatar-row align-items-center">
                                                                    <div className="col-auto avatar-row__image">
                                                                        <Avatar/>
                                                                    </div>
                                                                    <div className="col avatar-row__text">
                                                                        <div>{firstName} {lastName}</div>
                                                                        <div className="small-text">
                                                                            <FormattedMessage
                                                                                id="doctor.bookings.priority-level"/>: <span
                                                                            className="color-red">{levelCriticalState || 0}</span><span
                                                                            className="color-gray">/5</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="synopsis color-gray">
                                                                    {birthday && <FormattedMessage id="core.birthday.value"
                                                                                                   values={{value: moment().diff(birthday, 'years')}}/>}
                                                                    {user.role === 'DOCTOR' && <EmptyTag>
                                                                        {symptoms && symptoms.length > 0 && "/" + symptoms.map(symptom => symptom.name).join(", ")}
                                                                    </EmptyTag>}
                                                                </div>
                                                                {paymentStatus &&
                                                                <PaymentStatus statusCode={paymentStatus}/>}
                                                            </div>
                                                            <div className="person-col person-col-date">
                                                                {rescheduling != null &&
                                                                <div className="small-text color-blue">
                                                                    <FormattedMessage
                                                                        id="doctor.bookings.request-reschedule"/></div>}
                                                                <div className="row date-row align-items-center">
                                                                    <div className="col-auto date-row__icon">
                                                                        <Icon name="clock"/>
                                                                    </div>
                                                                    <div className="col date-row__value color-gray">
                                                                        {moment(timeOfReceipt).format("LT")}
                                                                    </div>
                                                                    {isBusyTimeOfReceipt &&
                                                                    <div className="col-auto date-row__status">
                                                                        <div className="icon-item badge badge-red">
                                                                            <Icon name="clock-red"/>
                                                                            <span
                                                                                className="icon-item__text"><FormattedMessage
                                                                                id="doctor.bookings.time-booked"/></span>
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                </div>
                                                                {rescheduling != null &&
                                                                <div className="small-text color-gray">
                                                                    <FormattedMessage
                                                                        id="doctor.bookings.exist-request-rescheduling"/>
                                                                </div>}
                                                                {rescheduling != null &&
                                                                <div className="row date-row align-items-center">
                                                                    <div className="col-auto date-row__icon">
                                                                        <Icon name="clock"/>
                                                                    </div>
                                                                    <div className="col date-row__value color-gray">
                                                                        {moment(rescheduling.timeOfReceipt).format("lll")}
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                            {(status === 'REQUESTED' || status === 'APPROVED') && user.isEnabled &&
                                                            <div
                                                                className="person-col person-col-controls small-controls">
                                                                {status === 'REQUESTED' &&
                                                                <Button
                                                                    disabled={paymentStatus === 'PENDING'}
                                                                    onClick={() => this.props.onConfirm({
                                                                        text: <FormattedMessage
                                                                            id="core.confirm.booking.approve"/>,
                                                                        onProceed: (() => this.props.onApproveBooking(booking))
                                                                    })}><FormattedMessage
                                                                    id="core.btn.approve.label"/></Button>}
                                                                <Button color="white-red"
                                                                        onClick={() => this.props.onConfirm({
                                                                            text: status === 'REQUESTED' ?
                                                                                <FormattedMessage id="core.confirm.booking.reject"/> :
                                                                                <FormattedMessage id="core.confirm.booking.cancel"/>,
                                                                            advanceContent: rejectedAdvanceContent,
                                                                            labelProceed: <FormattedMessage
                                                                                id="core.confirm.booking.reject.label-proceed"/>,
                                                                            onProceed: (() => this.props.onRejectBooking(booking))
                                                                        })}>
                                                                    {status === 'REQUESTED' &&
                                                                    <FormattedMessage id="core.btn.reject.label"/>}
                                                                    {status === 'APPROVED' &&
                                                                    <FormattedMessage id="core.btn.cancel.label"/>}
                                                                </Button>
                                                                {!isStartedConsultation &&
                                                                <Button color="white-blue"
                                                                        onClick={() => this.props.onRescheduling(booking)}><FormattedMessage
                                                                    id="core.confirm.booking.rescheduling"/></Button>}
                                                            </div>}
                                                            {(booking.status === 'REJECTED') &&
                                                            <div className="person-col">
                                                                <div className="small-controls align-right">
                                                                    {booking.canceledByUser && booking.canceledByUser.id === booking.patient.id &&
                                                                    <FormattedMessage
                                                                        id="core.confirm.booking.cancelled-by-patient"/>}
                                                                    {booking.canceledByUser && booking.canceledByUser.id === booking.doctor.id &&
                                                                    <FormattedMessage
                                                                        id="core.confirm.booking.cancelled-by-doctor"/>}
                                                                    {!booking.canceledByUser && <FormattedMessage
                                                                        id="core.confirm.booking.auto-cancelled"/>}
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </GridCol>
                                            );
                                        })}
                                    </EmptyTag>
                                );
                            }
                        )}
                    </div>
                    <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}

                    </div>
                </div>

                <div className="col-md-4">
                    <div className="white-block">

                        <ScheduleCalendar defaultChooseDate={new Date(parseInt(dateOfReceipt))}
                                          doctor={doctor}
                                          isReload={(scheduleCalendar && scheduleCalendar.isReload) || false}
                                          onChooseCalendarDate={this.onChooseCalendarDate}/>
                        {doctorSchedule && doctorSchedule.length > 0 &&
                        <EmptyTag>
                            <h3 className="align-center"><FormattedMessage id="core.time.label"/></h3>
                            <table className="time-table">
                                <tbody>
                                {doctorSchedule.map(i => {
                                    const {timeOfReceipt, booking = {}} = i;
                                    const {patient = {}} = booking || {};
                                    return (
                                        <tr key={timeOfReceipt}>
                                            <td className="time-td">{moment(timeOfReceipt).format('h:mm a')}</td>
                                            <td>
                                                {booking && booking.status === 'APPROVED' &&
                                                <div
                                                    className="color-cell light-bg-green">{patient.firstName} {patient.lastName}</div>}

                                                {booking && booking.status === 'REQUESTED' &&
                                                <div
                                                    className="color-cell light-bg-orange">{patient.firstName} {patient.lastName}</div>}
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </EmptyTag>
                        }
                    </div>
                </div>
                {isModal(modal, 'rescheduling') && <Rescheduling/>}
                {isModal(modal, 'assistantPatientDetail') && <AssistantPatientDetail/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRescheduling: (booking) => dispatch(modalShow('rescheduling', {booking: booking})),
        onOpenAssistantPatientDetail: (data) => dispatch(modalShow('assistantPatientDetail', data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchScheduleByDate: (doctor, dateOfReceipt) => dispatch(fetchScheduleByDate(doctor, dateOfReceipt)),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber)),
        onApproveBooking: (booking) => dispatch(approveBookingList(booking)),
        onRejectBooking: (booking) => dispatch(rejectBookingList(booking)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("bookings", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("bookings", sorts)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorBookingsByStatus);
