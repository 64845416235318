import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";

export const fetchSettings = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/settings")
            .then(response => {
                dispatch(listFetchAction("settings", response.data, null));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const updateSetting = (data) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/settings", data)
            .then(response => {
                dispatch(fetchSettings());
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};