import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import Pagination from "react-js-pagination";
import {FormattedMessage} from "react-intl";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {getPaymentStatusName, refund} from "../../../services/bookingPaymentService";
import classnames from "classnames";
import {fetchBookings} from "../../../services/bookingService";
import Dropdown, {DropdownItem} from "../../../systems/dropdown";
import {Icon} from "../../../../components/elements/icon";
import {isModal} from "../../../../utils/app";
import BookingDetail from "./BookingDetail";
import {modalShow} from "../../../services/system/modalService";
import UnverifiablePaymentRefund from "../../doctor/bookings/detail/payment/UnverifiablePaymentRefund";
import {AutocompleteAsync} from "../../../systems/autocomplete";
import {fetchDoctorAsync, fetchDoctors} from "../../../services/doctorService";
import {formSimpleChange} from "../../../services/system/formService";
import {formInitAction} from "../../../../actions/formAction";
import {listFilterFieldAction} from "../../../../actions/listAction";
import qs from "query-string";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../actions/preloaderAction";
import {PaymentProvider} from "../../../../components/collections/payment_status";

class Bookings extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onChangeDoctor = this.onChangeDoctor.bind(this);
        this.onSearchDoctor = this.onSearchDoctor.bind(this);
        this.state = {selectedDoctor: null};
    }

    componentDidMount() {
        const queryString = this.props.location.search;
        const {doctorId = null} = qs.parse(queryString);


        if (doctorId != null) {
            (async () => {
                this.props.onPageShowPreloader();
                await fetchDoctorAsync(doctorId)
                    .then(doctor => {
                        this.props.onListFilterField("bookings", "doctorId", doctorId);
                        this.onPagination(1)
                        this.setState({...this.state, selectedDoctor: doctor});

                    }).finally(() => {
                        this.props.onPageHidePreloader();
                    });
            })();
        } else {
            this.onPagination(1);
        }
    }

    onPagination(pageNumber) {
        this.props.onFetchBookings(pageNumber);
    }

    onChangeDoctor(event, value) {
        const doctorId = value ? value.id : null;
        this.props.onListFilterField("bookings", "doctorId", doctorId);
        this.setState({...this.state, selectedDoctor: value})
        this.onPagination(1);
        let qsParams = {};
        qsParams["doctorId"] = doctorId;
        this.props.history.replace(this.props.history.location.pathname + '?' + qs.stringify(qsParams));
    }

    onSearchDoctor(event, query, reason) {
        this.props.onListFilterField("doctors", "searchText", query);
        this.props.onFetchDoctors(1);
    }

    render() {
        const {list: {data, pagination}, modal} = this.props;
        const {doctors = [], bookings = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['bookings'] || {};
        const dropDownButton = <div>
            <Icon name="menu-points"/>
        </div>;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="bookings.and.payments"/></Header>
                    </div>
                </div>

                <div className="row form-elements align-items-center filtr-form">
                    
                    <div className="col-auto">
                        <label className="field-label margin-bottom-0">
                            <FormattedMessage id="core.hcsp.or.doctor.label"/>
                        </label>
                    </div>
                    
                    <div className="col-auto width-320">
                        <AutocompleteAsync onInputChange={this.onSearchDoctor}
                                            getOptionLabel={option => option.firstName + ' ' + option.lastName}
                                            onChange={this.onChangeDoctor}
                                            value={this.state.selectedDoctor || null}
                                            // placeholder={<FormattedMessage id="core.hcsp.or.doctor.label"/>}
                                            placeholderFixed={true}
                                            options={doctors}/>
                    </div>
                </div>

                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><FormattedMessage id="core.datetime.label"/></th>
                            <th><FormattedMessage id="doctor"/></th>
                            <th><FormattedMessage id="patient"/></th>
                            <th><FormattedMessage id="booking.detail.consultation.status.label"/></th>
                            <th><FormattedMessage id="booking.payment.detail.payment.status.label"/></th>
                            <th><FormattedMessage id="modal.create.refund.transaction.pay.type.label"/></th>
                            <th><FormattedMessage id="amount"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {bookings.map(booking => {
                            const {
                                doctor,
                                patient,
                                status,
                                payment,
                                timeOfReceipt,
                                bookingCancelReason = null
                            } = booking;
                            const {paymentStatus = ""} = payment || {};
                            const paymentStatusColor = classnames({
                                'color-green': paymentStatus == 'COMPLETED' || paymentStatus == 'NOT_VERIFY',
                                'color-blue': paymentStatus == 'REFUND',
                                'color-orange': paymentStatus == 'PENDING',
                                'color-red': paymentStatus == 'DENIED'
                            });
                            const bookingCancelReasonCode = bookingCancelReason != null ? bookingCancelReason.code : "";
                            return (
                                <tr key={booking.id}>
                                    <td className="td-controls">
                                        <Dropdown button={dropDownButton} noArrow="true">
                                            <DropdownItem to={"#"} icon="detail"
                                                          onClick={() => this.props.onFormBookingDetail(booking.id)}>
                                                <FormattedMessage id="core.detail.label"/>
                                            </DropdownItem>

                                            {status === 'REJECTED' &&
                                                bookingCancelReasonCode != 'PATIENT_UNAVAILABLE' &&
                                                (paymentStatus === 'COMPLETED' || paymentStatus === 'NOT_VERIFY') &&
                                                <DropdownItem to={"#"} icon="refund"
                                                              onClick={() => this.props.onConfirm({
                                                                  text: <FormattedMessage id="refund.payment"/>,
                                                                  onProceed: (() => this.props.onRefund(
                                                                      booking, () => this.onPagination(number + 1)))
                                                              })}>
                                                    <FormattedMessage id="core.btn.refund.label"/>
                                                </DropdownItem>}

                                       {/*     {paymentStatus === 'REFUND' &&
                                                <DropdownItem to={"#"} icon="transaction-number"
                                                              onClick={() => this.props.onModalShow('unverifiablePaymentRefund', {
                                                                  booking: booking,
                                                                  refundTransactionNumber: null,
                                                                  refundCompletedAt: moment().format("DD.MM.YYYY hh:mm:ss"),
                                                              })}>
                                                    <FormattedMessage id="core.booking.create-refund-transaction"/>
                                                </DropdownItem>}*/}
                                        </Dropdown>
                                    </td>
                                    <td>{moment(timeOfReceipt).format("lll")}</td>
                                    <td>{doctor.firstName} {doctor.lastName}</td>
                                    <td>{patient.firstName} {patient.lastName}</td>
                                    <td>
                                        {status === 'REQUESTED' &&
                                            <FormattedMessage id="booking.status.requested.label"/>}
                                        {status === 'APPROVED' &&
                                            <FormattedMessage id="booking.status.approved.label"/>}
                                        {status === 'REJECTED' &&
                                            <FormattedMessage id="booking.status.rejected.label"/>}
                                        {status === 'COMPLETED' &&
                                            <FormattedMessage id="booking.status.completed.label"/>}
                                    </td>
                                    <td>
                                        <span className={paymentStatusColor}>{getPaymentStatusName(paymentStatus) ||
                                            <FormattedMessage id="core.none.label"/>}</span>
                                    </td>
                                    <td><PaymentProvider payment={payment}/></td>
                                    <td>
                                        {payment.currency} {payment.amount && parseFloat(payment.amount).toFixed(2)}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}

                </div>
                {isModal(modal, 'bookingDetail') && <BookingDetail/>}
                {isModal(modal, 'unverifiablePaymentRefund') && <UnverifiablePaymentRefund/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onFetchDoctors: (pageNumber) => dispatch(fetchDoctors(pageNumber, "doctors")),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber)),
        onFormBookingDetail: (bookingId) => dispatch(modalShow("bookingDetail", {bookingId: bookingId})),
        onRefund: (booking, callback) => dispatch(refund(booking, callback)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bookings);
