import React, {Component} from 'react';
import {connect} from "react-redux";
import {BackTo} from "../../../../../components/elements/backTo";
import {Button} from "../../../../../components/elements/button";
import {
    approveBooking,
    completeBooking,
    fetchBooking,
    rejectBooking
} from "../../../../services/bookingService";
import {Header} from "../../../../../components/elements/header";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {GridCol} from "../../../../../components/collections/grid";
import {Tab, Tabs} from "../../../../../components/modules/tab";
import {tabChangePanelAction} from "../../../../../actions/tabAction";
import BookingDetailDescription from "./BookingDetailDescription";
import BookingDetailAdvice from "./BookingDetailAdvice";
import BookingDetailPrescription from "./BookingDetailPrescription";
import BookingDetailHistory from "./BookingDetailHistory";
import BookingDetailNote from "./BookingDetailNote";
import BookingDetailChat from "./BookingDetailChat";
import {modalHide, modalShow} from "../../../../services/system/modalService";
import {formInitAction} from "../../../../../actions/formAction";
import {createConsultationCall} from "../../../../services/callService";
import {noticeShowAction} from "../../../../../actions/noticeAction";
import qs from "query-string";
import {isModal} from "../../../../../utils/app";
import {refund} from "../../../../services/bookingPaymentService";
import ConfirmReasonRejectedAdvanceContent from "../ConfirmReasonRejectedAdvanceContent";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import UnverifiablePaymentRefund from "./payment/UnverifiablePaymentRefund";
import BookingDetailRecording from "./BookingDetailRecording";
import {Redirect} from "react-router";
import {
    approvedExtraConsultation,
    canceledExtraConsultation,
    getExtraConsultationsByConsultation, getMyExtraConsultation
} from "../../../../services/extraConsultationsService";
import AddParticipantExtraConsultation from "./AddParticipantExtraConsultation";
import ButtonStartConsultation from "./ButtonStartConsultation";
import {listInitAction} from "../../../../../actions/listAction";
import {callHideAction} from "../../../../../actions/callAction";

class BookingDetail extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {match: {params}, form: {data: formData}, auth: {user}} = this.props;
        const {booking = null} = formData;
        const {id = "", bookingId = ""} = params || {};
        this.props.onFetchBooking(id || bookingId);

        const p = this.props.location.search;
        const {tab = "description"} = qs.parse(p);
        this.props.onChangeTabPanel(tab);

        if (booking && booking.status !== 'REQUESTED' && booking.typeConsultation == 'VIDEO') {
            if (booking.doctor.id == user.id) {
                this.props.onFetchExtraConsultationsByConsultation(booking.consultation.id);
            } else {
                this.props.onFetchMyExtraConsultation(booking.consultation.id);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {match: {params: curParam}, form: {data: curFormData = {}}, auth: {user}} = this.props;
        const {id: curId = ""} = curParam || {};

        const {match: {params: prevParam}, form: {data: prevFormData = {}}} = prevProps;
        const {id: prevId = ""} = prevParam || {};

        const pCur = this.props.location.search;
        const {reload: curReload = "", tab: curTab = "description"} = qs.parse(pCur);

        const pPrev = prevProps.location.search;
        const {reload: prevReload = ""} = qs.parse(pPrev);

        if (curId != prevId || curReload != prevReload) {
            this.props.onCallHide();
            this.props.onFetchBooking(curId);
            if (curTab != '')
                this.props.onChangeTabPanel(curTab);
        }

        const {booking: curBooking = {}} = curFormData;
        const {payment: curPayment = null} = curBooking;

        const {booking: prevBooking = {}} = prevFormData;
        const {payment: prevPayment = null} = prevBooking;

        if (curBooking.id && curBooking != prevBooking) {
            if (curBooking.status !== 'REQUESTED' && curBooking.typeConsultation == 'VIDEO' && curBooking.consultation) {

                if (curBooking.doctor.id == user.id) {
                    this.props.onFetchExtraConsultationsByConsultation(curBooking.consultation.id);
                } else {
                    this.props.onFetchMyExtraConsultation(curBooking.consultation.id);
                }
            }
        }

        if (curPayment != prevPayment) {
            const {paymentStatus = ""} = curPayment;
            if (curBooking.status === 'REQUESTED' && paymentStatus === 'PENDING') {
                this.props.onNoticeShow("", <FormattedMessage id="doctor.booking.pending.payment"/>);
            }
        }
    }

    onChange(event) {
        this.props.onFormChangeField('consultation', event);
    }

    componentWillUnmount() {
        this.props.onInitList("extraConsultations");
        this.props.onInitForm("booking", {});
        this.props.onInitForm("consultation", {});
    }

    render() {
        const {form: {data: formData}, tab: {data: tabData}, modal, auth: {user}, list: {data: listData}} = this.props;
        if (user.role === 'DOCTOR' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }
        const {booking = {}, extraConsultation = null} = formData;
        const {booking: tabBooking = {}} = tabData;
        const isStartedConsultation = booking.consultation && booking.consultation.isStarted === true;
        const diagnosis = (booking.consultation && booking.consultation.diagnosis) || "";
        const isSetDiagnosis = diagnosis != "" && diagnosis != null;
        const isBookingDoctor = booking && booking.doctor && user && booking.doctor.id == user.id;

        const {consultation = {}, status, payment = {}, typeConsultation} = booking;
        const {paymentStatus = ""} = payment || {};
        const {medicalTests = [], medications = []} = consultation || {};
        const {extraConsultations = []} = listData;
        const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent booking={booking}/>;

        let confirmCompleteTextMsg = <FormattedMessage id="core.confirm.booking.complete"/>;
        if (booking.consultation) {
            if (medicalTests.length == 0 && medications.length == 0) {
                confirmCompleteTextMsg = <FormattedMessage id="core.booking.medical-tests.and.medications.empty"/>;
            } else if (medicalTests.length == 0) {
                confirmCompleteTextMsg = <FormattedMessage id="core.booking.medical-tests.empty"/>;
            } else if (medications.length == 0) {
                confirmCompleteTextMsg = <FormattedMessage id="core.booking.medications.empty"/>;
            }
        }

        const {match} = this.props;
        const {params} = match;
        const {patientId} = params;
        let backUrl = "/doctor/bookings";
        if (match.path.indexOf("patientConsulted") !== -1) {
            backUrl = "/patientConsulted/" + patientId + "/bookings";
        }

        const isCanComplete = booking.status === 'APPROVED' &&
            ((isStartedConsultation && typeConsultation !== 'OFFLINE') || typeConsultation === 'OFFLINE') &&
            isSetDiagnosis;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={backUrl}/>
                    </div>

                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="core.header.booking"/></Header>
                    </div>
                    {!isBookingDoctor && extraConsultation && user.isEnabled && <EmptyTag>
                        <div className="col-auto content-heading__controls">
                            <Button color="white-red" onClick={() => this.props.onConfirm({
                                text: <FormattedMessage id="confirm.cancel"/>,
                                onProceed: (() => this.props.onCanceledExtraConsultation(extraConsultation.id,
                                    () => this.props.onFetchMyExtraConsultation(consultation.id)))
                            })}><FormattedMessage id="core.btn.cancel.label"/></Button>

                            {extraConsultation.status && extraConsultation.status === 'REQUESTED' &&
                                <Button onClick={() => this.props.onConfirm({
                                    text: <FormattedMessage id="confirm.accept"/>,
                                    onProceed: (() => this.props.onApprovedExtraConsultation(extraConsultation.id,
                                        () => this.props.onFetchMyExtraConsultation(consultation.id)))
                                })}><FormattedMessage id="core.btn.accept.label"/></Button>}
                        </div>
                    </EmptyTag>}

                    {isBookingDoctor && user.isEnabled &&
                        <div className="col-auto content-heading__controls">
                            {booking.status === 'REQUESTED' &&
                                <Button
                                    disabled={paymentStatus === 'PENDING'}
                                    onClick={() => this.props.onConfirm({
                                        text: <FormattedMessage id="core.confirm.booking.approve"/>,
                                        onProceed: (() => this.props.onApproveBooking(booking))
                                    })}><FormattedMessage id="core.btn.approve.label"/></Button>}

                            {(status === 'REQUESTED' || status === 'APPROVED') &&
                                <Button color="white-red" onClick={() => this.props.onConfirm({
                                    text: status === 'REQUESTED' ?
                                        <FormattedMessage id="core.confirm.booking.reject"/> :
                                        <FormattedMessage id="core.confirm.booking.cancel"/>,
                                    advanceContent: rejectedAdvanceContent,
                                    labelProceed: <FormattedMessage id="core.confirm.booking.reject.label-proceed"/>,
                                    onProceed: (() => this.props.onRejectBooking(booking))
                                })}>

                                    {status === 'REQUESTED' &&
                                        <FormattedMessage id="core.btn.reject.label"/>}
                                    {status === 'APPROVED' &&
                                        <FormattedMessage id="core.btn.cancel.label"/>}

                                </Button>}

                            <ButtonStartConsultation booking={booking}
                                                     extraConsultations={extraConsultations}
                                                     history={this.props.history}/>

                            {booking.status !== 'COMPLETED' &&
                            <Button
                                disabled={!isCanComplete}
                                onClick={() => this.props.onConfirm({
                                    text: confirmCompleteTextMsg,
                                    onProceed: (() => this.props.onCompleteBooking(booking))
                                })}><FormattedMessage id="core.complete.consultation"/></Button>}

                            {booking.status !== 'REQUESTED' && booking.status !== 'COMPLETED' && typeConsultation == 'VIDEO' &&
                                <Button onClick={() => this.props.onModalShow("addParticipantExtraConsultation",
                                    {consultationId: booking.consultation.id})}>
                                    <FormattedMessage id="invite.doctor"/>
                                </Button>}

                            <Link title={<FormattedMessage id="core.print-page"/>} target="_blank"
                                  to={'/doctor/bookings/' + booking.id + '/print'}>
                                <Button icon='print' circle color='op-gray' circleMiddleSize={true}
                                        controlPrint={true}/>
                            </Link>

                        </div>}
                </div>
                <div className="row content-heading">
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab onClick={() => this.props.onChangeTabPanel("description")}
                                 active={tabBooking.activeTabPanel === 'description'}><FormattedMessage
                                id="core.tab.description.label"/></Tab>

                            {(booking.status === 'APPROVED' || booking.status === 'COMPLETED') &&
                                <Tab onClick={() => this.props.onChangeTabPanel("prescription")}
                                     active={tabBooking.activeTabPanel === 'prescription'}><FormattedMessage
                                    id="core.tab.prescription.label"/></Tab>}

                            {(booking.status === 'APPROVED' || booking.status === 'COMPLETED') &&
                                <Tab onClick={() => this.props.onChangeTabPanel("advices")}
                                     active={tabBooking.activeTabPanel === 'advices'}><FormattedMessage
                                    id="core.tab.medical-advice.label"/></Tab>}

                            {(booking.status !== 'REQUESTED') &&
                                <Tab onClick={() => this.props.onChangeTabPanel("chat")}
                                     active={tabBooking.activeTabPanel === 'chat'}><FormattedMessage
                                    id="core.tab.chat.label"/></Tab>}

                            <Tab onClick={() => this.props.onChangeTabPanel("history")}
                                 active={tabBooking.activeTabPanel === 'history'}><FormattedMessage
                                id="core.tab.history.label"/></Tab>

                            {(booking.status === 'APPROVED' || booking.status === 'COMPLETED') &&
                                <Tab onClick={() => this.props.onChangeTabPanel("note")}
                                     active={tabBooking.activeTabPanel === 'note'}><FormattedMessage
                                    id="core.tab.note.label"/></Tab>}

                            <Tab onClick={() => this.props.onChangeTabPanel("recording")}
                                 active={tabBooking.activeTabPanel === 'recording'}><FormattedMessage
                                id="recording"/></Tab>
                        </Tabs>
                    </GridCol>
                </div>

                {tabBooking.activeTabPanel === 'description' && <BookingDetailDescription/>}
                {tabBooking.activeTabPanel === 'prescription' && <BookingDetailPrescription/>}
                {tabBooking.activeTabPanel === 'advices' && <BookingDetailAdvice/>}
                {tabBooking.activeTabPanel === 'chat' && <BookingDetailChat/>}
                {tabBooking.activeTabPanel === 'history' && <BookingDetailHistory/>}
                {tabBooking.activeTabPanel === 'note' && <BookingDetailNote/>}
                {tabBooking.activeTabPanel === 'recording' && <BookingDetailRecording/>}
                {isModal(modal, 'unverifiablePaymentRefund') && user.isEnabled && isBookingDoctor &&
                    <UnverifiablePaymentRefund/>}

                {isModal(modal, 'addParticipantExtraConsultation') && user.isEnabled && isBookingDoctor &&
                    <AddParticipantExtraConsultation/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        tab: store.tab,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFetchMyExtraConsultation: (consultationId) => dispatch(getMyExtraConsultation(consultationId)),
        onFetchExtraConsultationsByConsultation: (consultationId) => dispatch(getExtraConsultationsByConsultation(consultationId)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("booking", tabPanelName)),
        onFetchBooking: (id) => dispatch(fetchBooking(id)),
        onApproveBooking: (booking) => dispatch(approveBooking(booking)),
        onRejectBooking: (booking) => dispatch(rejectBooking(booking)),
        onCreateConsultationCall: (type, booking) => dispatch(createConsultationCall(type, booking, ownProps)),
        onCompleteBooking: (booking) => dispatch(completeBooking(booking)),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onRefund: (booking) => dispatch(refund(booking)),
        onApprovedExtraConsultation: (id, callback) => dispatch(approvedExtraConsultation(id, callback)),
        onCanceledExtraConsultation: (id, callback) => dispatch(canceledExtraConsultation(id, callback)),
        onCallHide: () => dispatch(callHideAction())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail);
