import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {formErrors} from "../../../../../services/system/formService";
import {FormattedMessage} from "react-intl";
import {formChangeFieldAction} from "../../../../../../actions/formAction";
import {GridCol} from "../../../../../../components/collections/grid";
import {Input} from "../../../../../../components/elements/input";

class MobileMoneySettings extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {auth: {user}} = this.props;
        const {form: {data: formData}} = this.props;
        const {phone = user.phone} = formData.pay || {}
        this.props.onFormSimpleChange("pay", "phone", phone);
        this.props.onInitErrors("pay");
    }

    onChange(event) {
        this.props.onFormSimpleChange("pay", "phone", event.target.value);
    }

    render() {
        const {form: {data: formData, errors: formErrors}} = this.props;
        const {phone = null} = formData.pay || {}
        const errors = formErrors.pay;
        return (
            <GridCol count="1">
                <Input name="phone"
                       value={phone || ''}
                       error={(errors && errors['phone']) || null}
                       placeholder={<FormattedMessage id="mobile.phone"/>}
                       onChange={this.onChange}/>
            </GridCol>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitErrors: (formName) => dispatch(formErrors(formName, {})),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileMoneySettings));