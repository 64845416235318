import {WS_INIT, WS_CLOSE} from "../constants/actionTypes";

const initialState = {
    isConnected: false,
    config: {}
};

const wsReducer = (state = initialState, action) => {
    switch (action.type) {
        case WS_INIT:
            return {...state, isConnected: true, config: action.config};
        case WS_CLOSE:
            const {client = null, subscribe = null} = state.config;
            if (subscribe)
                subscribe.unsubscribe();
            if (client)
                client.disconnect();
            return {...state, isConnected: false, config: {}};
        default:
            return state;
    }
}

export default wsReducer;