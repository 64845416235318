import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {
    getBookingStatus,
    getBookingStatuses
} from "../../../../../utils/app";
import {generateReport} from "../../../../services/billingReportService";
import {FormattedMessage} from "react-intl";

import {formInitAction} from "../../../../../actions/formAction";
import {Option, Select} from "../../../../../components/elements/select";
import {formSimpleChange} from "../../../../services/system/formService";
import Search from "../../../../systems/search";

class ReportConsultationFilter extends Component {
    constructor(props) {
        super(props);
        this.onBookingStatus = this.onBookingStatus.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    onBookingStatus(bookingStatus) {
        const {form: {data: formData}} = this.props;
        const {reportParams = {}} = formData;
        const value = bookingStatus == 'ALL' ? null : bookingStatus;
        this.props.onFormSimpleChangeField("reportParams", "bookingStatus", value);
        this.props.onGenerateReport(1, {...reportParams, bookingStatus: value});
    }

    onSearch(searchText) {
        const {form: {data: formData}} = this.props;
        const {reportParams = {}} = formData;
        this.props.onFormSimpleChangeField("reportParams", "patientOrDoctorSearchText", searchText);
        this.props.onGenerateReport(1, {...reportParams, patientOrDoctorSearchText: searchText});
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {reportParams = {}} = formData;
        const {bookingStatus = "ALL"} = reportParams;
        return (
            <EmptyTag>
                <div className="col-auto">
                    <Search onSearch={this.onSearch} placeholder="search.by.patient.and.doctor" width={400}/>
                </div>

                <div className="col col-empty"/>

                <div className="col-auto padding-right-0">
                    <label className="field-label margin-bottom-0"><FormattedMessage id="status"/></label>
                </div>

                <div className="col-auto">
                    <FormattedMessage id={getBookingStatus(bookingStatus) || "all.label"}>
                        {optionNameSelected =>
                            <Select noLabel={true} height={40} width={180} bgColor={'white'}
                                    label={""}
                                    optionNameSelected={optionNameSelected}>
                                {
                                    <Option
                                        active={"ALL" === bookingStatus}
                                        onClick={() => this.onBookingStatus("ALL")}>
                                        {<FormattedMessage id="all.label"/>}
                                    </Option>
                                }

                                {
                                    getBookingStatuses().map(key =>
                                        <Option
                                            active={key === bookingStatus}
                                            key={key}
                                            onClick={() => this.onBookingStatus(key)}>
                                            {<FormattedMessage id={getBookingStatus(key)}/>}
                                        </Option>
                                    )
                                }
                            </Select>
                        }
                    </FormattedMessage>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onGenerateReport: (pageNumber, data) => dispatch(generateReport(pageNumber, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportConsultationFilter);