import {billingApi, privateApi, publicApi} from '../../utils/api';
import React from "react";
import {prepareFilter} from "../../utils/app";
import {listFetchAction} from "../../actions/listAction";
import {modalHide} from "./system/modalService";
import {formErrors, formInit, formIsSend} from "./system/formService";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {noticeShowAction} from "../../actions/noticeAction";
import {formInitAction} from "../../actions/formAction";
import {EmptyTag} from "../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import {getUserCurrentGeolocation} from "./userService";

export const fetchConsultationComingSoon = () => {
    return (dispatch, state) => {
        const filter = prepareFilter(state().list.filters['consultationComingSoon'] || {});
        const sort = prepareFilter(state().list.sorts['consultationComingSoon'] || {});
        return privateApi.get("/bookings?pageNumber=" + 1 + "&pageSize=1&search=" + filter + "&sort=" + sort)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("consultationComingSoon", content, {...rest}));
            }).catch(error => {
            });
    }
};

export const fetchAssistantInvites = (doctor, pageNumber = 1) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters['assistantInvites'] || {});
        const sort = prepareFilter(state().list.sorts['assistantInvites'] || {});
        return privateApi.get("/doctors/" + doctor.id + "/assistantInvites?pageNumber=" + pageNumber + "&pageSize=10&search=" + filter + "&sort=" + sort)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("assistantInvites", content, {...rest}));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const removeAssistantInvite = (doctor, assistantInvite) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/doctors/" + doctor.id + "/assistantInvites/" + assistantInvite.id + "/remove")
            .then(response => {
                dispatch(fetchAssistantInvites(doctor));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const createAssistantInvites = (doctor, data) => {
    return (dispatch) => {
        dispatch(formIsSend("createAssistantInvites", true));
        return privateApi.post("/doctors/" + doctor.id + "/createAssistantInvites", data)
            .then(response => {
                dispatch(modalHide('createAssistantInvites'));
                dispatch(fetchAssistantInvites(doctor, 1));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createAssistantInvites", error.response.data.details));
                } else {
                    dispatch(modalHide('createAssistantInvites'))
                }
            }).finally(() => {
                dispatch(formIsSend("createAssistantInvites", false));
            });
    }
};

export const acceptAssistantInvite = (token) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("acceptAssistantInvite", true));
        return privateApi.post("/users/acceptAssistantInvite", {token: token})
            .then(response => {
                const {doctor: {firstName, lastName}} = response.data;
                return dispatch(noticeShowAction(<FormattedMessage id="notice.accept.assistant.invite.title"/>,
                    <FormattedMessage id="notice.accept.assistant.invite.msg.success.text"
                                      values={{firstName: firstName, lastName: lastName}}/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {token} = error.response.data.details || {};
                    dispatch(noticeShowAction(<FormattedMessage id="notice.accept.assistant.invite.title"/>, token));
                }
            }).finally(() => {
                dispatch(formIsSend("acceptAssistantInvite", false));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const saveDoctorBillingProfile = (doctorId, data) => {
    return (dispatch, state) => {
        const {user} = state().auth;
        let url = '';
        if (user.role === 'DOCTOR') {
            url = "/v1/billingProfiles/currentMyBillingProfile";
        } else {
            url = "/v1/billingProfiles/currentDoctorBillingProfile";
        }
        dispatch(preloaderPageShowAction());
        data.doctorId = doctorId;
        return billingApi.post(url, data)
            .then(response => {
                dispatch(fetchDoctorBillingProfile(doctorId));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("doctorBillingProfile", error.response.data.details));
                } else {
                    return dispatch(noticeShowAction("", error.response.data.message));
                }
                dispatch(formIsSend("doctorBillingProfile", false));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctorBillingProfile = (doctorId) => {
    return (dispatch, state) => {
        const {user} = state().auth;
        let url = '';
        if (user.role === 'DOCTOR') {
            url = "/v1/billingProfiles/currentMyBillingProfile";
        } else {
            url = "/v1/billingProfiles/currentDoctorBillingProfile?doctorId=" + doctorId;
        }
        dispatch(preloaderPageShowAction());
        return billingApi.get(url)
            .then(response => {
                dispatch(formInitAction("doctorBillingProfile", response.data))
            }).catch(error => {
                dispatch(noticeShowAction("", error.response.data.message));
                dispatch(formIsSend("doctorBillingProfile", false));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const signUpAssistant = (data) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/users/signUpAssistant", data)
            .then(response => {
                const {doctor: {firstName, lastName}} = response.data;
                const textMsg = <EmptyTag><FormattedMessage id="notice.sign.up.assistant.success.msg" values={{
                    firstName: firstName,
                    lastName: lastName
                }}/></EmptyTag>;
                return dispatch(noticeShowAction(<FormattedMessage id="notice.sign.up.assistant.title"/>, textMsg));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {token} = error.response.data.details || {};
                    if (token) {
                        return dispatch(noticeShowAction(<FormattedMessage
                            id="notice.sign.up.assistant.title"/>, token));
                    }
                    dispatch(formErrors("signUpAssistant", error.response.data.details));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctors = (pageNumber, listName = "users") => {
    return (dispatch, state) => {
        const auth = state().auth;
        const filter = prepareFilter(state().list.filters[listName] || {});
        const sort = prepareFilter(state().list.sorts[listName] || {});
        if (sort == "doctor.nearest,asc") return dispatch(fetchNearestDoctors(pageNumber, listName));
        dispatch(preloaderPageShowAction());
        const url = auth.isAuthenticated ? "/doctors?pageNumber=" + pageNumber + "&search=" + filter + "&sort=" + sort :
            "/public/v1/doctors?pageNumber=" + pageNumber + "&search=" + filter + "&sort=" + sort;
        const promise = auth.isAuthenticated ? privateApi.get(url) : publicApi.get(url);
        return promise
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction(listName, content, {...rest}));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctorPhoto = async (userId) => {
    try {
        const response = await publicApi.get("/public/v1/doctors/" + userId + "/profile/photo");
        return response.data;
    } catch (e) {
        return null;
    }
};

export const fetchNearestDoctors = (pageNumber, listName = "users") => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters[listName] || {});
        getUserCurrentGeolocation((position) => {
            const geolocation = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }
            //localStorage.setItem("geolocation", JSON.stringify(geolocation));
            return privateApi.get("/v1/doctors/nearestDoctors", {
                params: {
                    pageNumber: pageNumber,
                    search: filter,
                    latitude: geolocation.latitude,
                    longitude: geolocation.longitude
                }
            })
                .then(response => {
                    const {data} = response;
                    const {content, ...rest} = data;
                    dispatch(listFetchAction(listName, content, {...rest}));
                }).finally(() => {
                    dispatch(preloaderPageHideAction());
                });
        }, (error) => {
            dispatch(preloaderPageHideAction());
            //localStorage.removeItem("geolocation");
            dispatch(
                noticeShowAction(
                    <FormattedMessage id="notice.default.title"/>,
                    <FormattedMessage id="not.determine.current.location"/>));
        })

    }
};

export const fetchDoctor = (doctorId, formName = "users") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/doctors/" + doctorId)
            .then(response => {
                const {data} = response;
                dispatch(formInitAction(formName, data));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctorAsync = async (id) => {
    const response = await privateApi.get("/doctors/" + id);
    return response.data;
};
export const createLinkBookAnAppointment = async (doctor) => {
    const response = await privateApi.post("/doctors/" + doctor.id + "/createLinkBookAnAppointment")
    return response.data;
};

export const createDoctor = (data, listName = "users") => {
    return (dispatch, state) => {
        const {number} = state().list.pagination[listName] || {};
        dispatch(formIsSend("createDoctor", true));
        return privateApi.post("/doctors", data)
            .then(response => {
                dispatch(modalHide("createDoctor"));
                dispatch(fetchDoctors(number + 1));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.save.doctor.success.msg"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createDoctor", error.response.data.details));
                }
                dispatch(formIsSend("createDoctor", false));
            }).finally(() => {
                dispatch(formIsSend("createDoctor", false));
            });
    }
};

export const fetchDoctorPatients = (doctor, pageNumber) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters['doctorPatients'] || {});
        return privateApi.get("/doctors/" + doctor.id + "/patients?pageNumber=" + pageNumber + "&search=" + filter)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("doctorPatients", content, {...rest}));
                return dispatch(preloaderPageHideAction());
            }).catch(error => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctorPatientBookings = (doctor, patient, pageNumber) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters['doctorPatientBookings'] || {});
        return privateApi.get("/doctors/" + doctor.id + "/patients/" + patient.id + "/bookings?pageNumber=" + pageNumber + "&search=" + filter)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("doctorPatientBookings", content, {...rest}));
                return dispatch(preloaderPageHideAction());
            }).catch(error => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const sendRequestUpdatePriceOfReception = (priceOfReception) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return billingApi.post("/v1/priceDoctorReceptions/newPriceMyReception", {priceOfReception: priceOfReception})
            .then(response => {
                dispatch(fetchRequestUpdatePriceOfReception());
                dispatch(formInit('sendRequestUpdatePriceOfReception', {}))
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors('sendRequestUpdatePriceOfReception', error.response.data.details));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchRequestUpdatePriceOfReception = () => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return billingApi.get("/v1/priceDoctorReceptions/newPriceMyReception")
            .then(response => {
                dispatch(formInitAction("requestUpdatePriceOfReception", response.data));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchPriceRequests = (pageNumber) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        /*const filter = prepareFilter(state().list.filters['priceRequests'] || {});
        const sort = prepareFilter(state().list.sorts['priceRequests'] || {});*/
        return privateApi.get("/v1/appointmentTemplates/priceRequests?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("priceRequests", content, {...rest}));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const approvePriceRequest = (priceRequestId) => {
    return (dispatch, state) => {
        dispatch(modalHide("confirm"));
        const pagination = state().list.pagination['priceRequests'] || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/appointmentTemplates/priceRequests/" + priceRequestId + "/approve")
            .then(response => {
                dispatch(fetchPriceRequests(pagination.number + 1));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const cancelPriceRequest = (priceRequestId) => {
    return (dispatch, state) => {
        dispatch(modalHide("confirm"));
        const pagination = state().list.pagination['priceRequests'] || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/appointmentTemplates/priceRequests/" + priceRequestId + "/cancel")
            .then(response => {
                dispatch(fetchPriceRequests(pagination.number + 1));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};