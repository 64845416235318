import React, {Component} from 'react';
import {connect} from "react-redux";
import {fetchHistories, saveDescription} from "../../../../services/consultationService";
import {formChangeField} from "../../../../services/system/formService";
import {GridCol} from "../../../../../components/collections/grid";
import {Paragraph} from "../../../../../components/elements/paragraph";
import {IconItem} from "../../../../../components/elements/icon";
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {Avatar} from "../../../../../components/collections/avatar";
import {FormattedMessage} from "react-intl";
import {Tab, Tabs} from "../../../../../components/modules/tab";
import {listFilterFieldAction} from "../../../../../actions/listAction";

class BookingDetailHistory extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onPagination = this.onPagination.bind(this);
        this.onHistoryMy = this.onHistoryMy.bind(this);
        this.onHistoryOther = this.onHistoryOther.bind(this);
    }

    componentDidMount() {
        const {form: {data}} = this.props;
        const {booking = {}} = data;
        const {patient = {}} = booking;
        this.props.onListFilterField("patientId", patient.id)
        this.onHistoryMy();
        this.onPagination(1);
    }

    onHistoryMy() {
        const {auth: {user}} = this.props;
        this.props.onListFilterField("doctorId", user.id);
        this.props.onListFilterField("doctorIdNotEquals", null);
        this.onPagination(1);
    }

    onHistoryOther() {
        const {auth: {user}} = this.props;
        this.props.onListFilterField("doctorId", null);
        this.props.onListFilterField("doctorIdNotEquals", user.id);
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchHistories(pageNumber);
    }

    onChange(event) {
        this.props.onFormChangeField('consultation', event);
    }

    render() {
        const {list: {data, pagination, filters}} = this.props;
        const {bookingHistories = []} = data;
        const {bookingHistories: filterBookingHistories = {}} = filters;
        const {number = 0, size = 0, totalElements = 0} = pagination['bookingHistories'] || {};

        return (
            <div className="row tile-row">
                <GridCol count="1">
                    <Tabs showAs="links">
                        <Tab onClick={() => this.onHistoryMy()}
                             active={filterBookingHistories["doctorId"]  > 0}><FormattedMessage id="my.consultations"/></Tab>

                        <Tab onClick={() => this.onHistoryOther()}
                             active={filterBookingHistories["doctorIdNotEquals"] > 0}><FormattedMessage id="other.consultations"/></Tab>
                    </Tabs>
                </GridCol>

                {bookingHistories.map(history => {
                    const {id, typeConsultation, symptoms = [], completedAt, doctor} = history;
                    const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
                    const joinSpecializations = specializations.map(i => i.name).join(", ")
                    return (
                        <GridCol count="3" key={id}>
                            <div className="link-block-wrapper">
                                <Link to={"/doctor/bookings/" + id + "/detail"}
                                      className="link-block"></Link>
                                <div className="link-block-header bg-color-blue">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            {moment(completedAt).format("lll")}
                                        </div>
                                        <div className="col-auto">
                                            <FormattedMessage id="core.id.label" /> {id}
                                        </div>
                                    </div>
                                </div>
                                <div className="row form-elements form-bottom-bordered">
                                    <GridCol count="1">
                                        <div className="row avatar-row align-items-center margin-bottom-15">
                                            <div className="col-auto avatar-row__image">
                                                <Avatar/>
                                            </div>
                                            <div className="col avatar-row__text">
                                                <div><FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></div>
                                                <div className="small-text color-gray">{joinSpecializations}</div>
                                            </div>
                                        </div>

                                        {typeConsultation === 'OFFLINE' &&
                                        <IconItem name="hospital"><FormattedMessage id="booking.history.type.consultation.offline.label" /></IconItem>}
                                        {typeConsultation === 'CHAT' &&
                                        <IconItem name="chat-green"><FormattedMessage id="booking.history.type.consultation.chat.label" /></IconItem>}
                                        {typeConsultation === 'VIDEO' &&
                                        <IconItem name="webcam-green"><FormattedMessage id="booking.history.type.consultation.video.label" /></IconItem>}
                                        {typeConsultation === 'AUDIO' &&
                                        <IconItem name="audio-green"><FormattedMessage id="booking.history.type.consultation.audio.label" /></IconItem>}
                                    </GridCol>
                                    <GridCol count="1">
                                        <label className="field-label label-fixed"><FormattedMessage id="core.symptoms.label" /></label>

                                        <Paragraph>
                                            {symptoms.map(symptom => symptom.commonName).join(", ")}
                                        </Paragraph>

                                    </GridCol>
                                </div>
                            </div>
                        </GridCol>
                    );
                })}

                <div className="col-12">
                {size < totalElements && <Pagination
                    activeLinkClass={"active"}
                    activePage={number + 1}
                    itemsCountPerPage={size}
                    totalItemsCount={totalElements}
                    pageRangeDisplayed={5}
                    onChange={this.onPagination}/>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("bookingHistories", fieldName, fieldValue)),
        onFetchHistories: (pageNumber) => dispatch(fetchHistories(pageNumber)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onSaveDescription: (consultation) => dispatch(saveDescription(consultation))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailHistory);
