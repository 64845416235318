import React from 'react';
import {connect} from "react-redux";

import 'react-notifications-component/dist/theme.css';
import {PreloaderPage} from "../../../components/elements/preloader";

class PrintLayout extends React.Component {

    render() {
        const {preloader} = this.props;
        return (
            <div className="wrapper wrapper-print">
                <main className="base">
                    <div className="content">
                        <div className="main-container container-fluid">
                            {this.props.children}
                        </div>
                    </div>
                </main>
                {preloader.isShow && <PreloaderPage/>}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        preloader: store.preloader
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintLayout);
