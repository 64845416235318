import {MODAL_SHOW, MODAL_HIDE} from "../constants/actionTypes";

export const modalShowAction = (name) => ({
    type: MODAL_SHOW,
    name: name
});

export const modalHideAction = (name) => ({
    type: MODAL_HIDE,
    name: name
});