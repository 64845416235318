import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {formInitAction} from "../../../actions/formAction";
import {listInitAction} from "../../../actions/listAction";
import {Avatar} from "../../../components/collections/avatar";

class ChatRooms extends Component {
    constructor(props) {
        super(props);
        this.onChoose = this.onChoose.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    onChoose(room) {
        this.props.onInitForm("bookingChosenChatRoom", room)
    }

    render() {
        const {rooms = []} = this.props;
        return (
            <div className="chat-rooms">

                <div className="chat-container">
                    <div className="chat-header">
                        <div className="row avatar-row align-items-center">
                            <div className="col avatar-row__text">
                                <h2><FormattedMessage id="rooms"/></h2>
                            </div>
                            <div className="col-auto avatar-row__controls">
                            </div>
                        </div>
                    </div>
                    <div className="chat-body">

                        <div className="chat-body-content">

                            <div className="row chats-list-row">
                                {rooms.map(room => {
                                    return (
                                        <div className="col-12" key={'chat-room-' + room.id}>
                                            <button type="button" className="btn-block"
                                                    onClick={() => this.onChoose(room)}></button>
                                            <div className="row avatar-row align-items-center">
                                                <div className="col-auto avatar-row__image">
                                                    <Avatar multiple={true}/>
                                                </div>
                                                <div className="col avatar-row__text">
                                                    <div>{room.participants.map(i => i.user.first_name + ' ' + i.user.last_name).join(", ")}</div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRooms);