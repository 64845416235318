import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../components/modules/modal";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {withRouter} from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import {getRoleInput, getRolesInput} from "../../../../utils/app";
import {formChangeField} from "../../../services/system/formService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";

import {Input} from "../../../../components/elements/input";
import Password from "../../../systems/password";
import {Button, Buttons} from "../../../../components/elements/button";
import {Option, Select} from "../../../../components/elements/select";
import {createAccount} from "../../../services/userService";

class CreateAccount extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectRole = this.onSelectRole.bind(this);
    }

    componentDidMount() {
        this.props.onInitForm("createAccount");
    }

    onChange(event) {
        this.props.onFormChangeField('createAccount', event);
    }

    onSelectRole(id) {
        this.props.onChangeDropdown("role", id);
    }

    render() {
        const {form} = this.props;
        const errors = form.errors.createAccount;
        const createAccount = form.data.createAccount || {};
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('createAccount')}>
                <h2><FormattedMessage id="add.user"/></h2>

                <div className="row form-elements">
                    <div className="col-12">
                        <FormattedMessage id={getRoleInput(createAccount['role']) || 'undefined'}>
                            {optionNameSelected =>
                                <Select name="role"
                                        error={(errors && errors.role) || null}
                                        label={<FormattedMessage id="role"/>}
                                        optionNameSelected={optionNameSelected}>
                                    {
                                        getRolesInput().map(key =>
                                            <Option
                                                active={createAccount && createAccount['role'] && createAccount['role'] === key}
                                                key={key}
                                                onClick={() => this.onSelectRole(key)}>
                                                {<FormattedMessage id={getRoleInput(key)}/>}
                                            </Option>
                                        )
                                    }
                                </Select>}
                        </FormattedMessage>
                    </div>

                    <div className="col-12">
                        <Input name="email"
                               icon="email"
                               value={createAccount.email || ''}
                               error={(errors && errors.email) || null}
                               placeholder={<FormattedMessage id="core.field.email.label"/>}
                               onChange={this.onChange}/>
                    </div>

                    <div className="col-12">
                        <Password name="password"
                                  icon="password"
                                  placeholder={<FormattedMessage id="core.field.password.label"/>}
                                  error={(errors && errors.password) || null}
                                  onChange={this.onChange}/>
                    </div>

                    <div className="col-12">
                        <Password name="matchingPassword"
                                  icon="password"
                                  placeholder={<FormattedMessage id="core.field.password.repeat.label"/>}
                                  error={(errors && errors.matchingPassword) || null}
                                  onChange={this.onChange}/>
                    </div>

                    <div className="col-12">
                        <Buttons>
                            <Button onClick={() => this.props.onCreateAccount(createAccount)}>
                                <FormattedMessage id="core.btn.add.label"/></Button>
                        </Buttons>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCreateAccount: (data) => dispatch(createAccount(data, ownProps)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onModalShow: (name) => dispatch(modalShow(name)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('createAccount', fieldName, fieldValue)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAccount));
