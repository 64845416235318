import React, {Component} from 'react';
import {connect} from "react-redux";
import {WhiteBlock} from "../../../../components/elements/paragraph";
import {Header} from "../../../../components/elements/header";
import {fetchDoctors} from "../../../services/bookingService";
import Pagination from "react-js-pagination";
import {Avatar} from "../../../../components/collections/avatar";
import {withRouter} from "react-router-dom";
import {formInit} from "../../../services/system/formService";
import {FormattedMessage} from "react-intl";

class AssistantHome extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onChooseDoctor = this.onChooseDoctor.bind(this);
    }

    componentDidMount() {
        this.props.onFetchDoctors(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchDoctors(pageNumber);
    }

    onChooseDoctor(doctor) {
        this.props.onFormInit("chooseDoctor", doctor)
        this.props.history.push("/assistant/schedule");
    }

    render() {
        const {list: {data, pagination}} = this.props;
        const {assistantDoctors = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['assistantDoctors'] || {};

        return (
            <div className="row content-row">
                <div className="col-12">

                    <WhiteBlock>
                        <Header as="h2"><FormattedMessage id="page.assistant.home.header" /></Header>
                        <div className="overflow-auto min-height-300 max-height-540">

                            <div className="row link-blocks-row blocks-hover-bg">

                                {assistantDoctors.map(assistantDoctor => {
                                    const {firstName, lastName, doctor = null} = assistantDoctor || {};
                                    const {cntBookingRequested} = doctor || {};
                                    const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
                                    const joinSpecializations = specializations.map(i => i.name).join(", ")
                                    return (
                                        <div className="col-md-6" key={assistantDoctor.id}>
                                            <div className="link-block-wrapper">
                                                <a href={'#'} onClick={() => this.onChooseDoctor(assistantDoctor)}
                                                      className="link-block"></a>
                                                <div className="row avatar-row align-items-center">
                                                    <div className="col-auto avatar-row__image">
                                                        <Avatar/>
                                                    </div>
                                                    <div className="col avatar-row__text">
                                                        <div><FormattedMessage id="core.doctor.name" values={{firstName: firstName, lastName: lastName}}/></div>
                                                        <div className="small-text color-gray">{joinSpecializations}</div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="big-text">{cntBookingRequested || 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                            </div>
                        </div>
                        <div className="col-12">
                            {size < totalElements && <Pagination
                                activeLinkClass={"active"}
                                activePage={number + 1}
                                itemsCountPerPage={size}
                                totalItemsCount={totalElements}
                                pageRangeDisplayed={5}
                                onChange={this.onPagination}/>}
                        </div>
                    </WhiteBlock>

                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormInit: (formName, data) => dispatch(formInit(formName, data)),
        onFetchDoctors: (pageNumber) => dispatch(fetchDoctors(pageNumber, "assistantDoctors")),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssistantHome));
