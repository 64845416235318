import _ from "lodash";
import {FormattedMessage} from "react-intl";
import React from "react";

import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/ru';
import 'moment/locale/pt';
import {create, all} from 'mathjs'
import {UNIT_TEMPERATURE} from "../constants/system";

export const isModal = (modals, modalName) => {
    return modals.shown.findIndex(name => name === modalName) !== -1;
};

export const prepareFilter = (filter = {}) => {
    return Object.keys(filter).map(key => key + ',' + encodeURIComponent(filter[key])).join(";");
};

export const prepareSort = (sort = {}) => {
    return Object.keys(sort).map(key => key + ',' + sort[key]).join(";");
};


export const createSortObject = (str = "") => {

    if (str.indexOf(";") === -1) {
        const j = str.split(",");
        const k = j[0];
        const v = j[1];
        return {[k] : v};
    }
    if (str.indexOf(",") === -1) {
        return {};
    }
    let obj = {};
    str.split(";").forEach(pSortField => {
        const pSortFieldSplit = pSortField.split(",");
        if (pSortFieldSplit.length > 0 && pSortFieldSplit[0] && pSortFieldSplit[1]) {
            const field = pSortFieldSplit[0];
            const sort = pSortFieldSplit[1];
            obj[field] = sort;
        }
    })

    return obj;
};

export const getMedicationForm = (code) => {
    const medicalForms = {
        LIQUID: "medical.form.liquid.label",
        TABLET: "medical.form.tablet.label",
        CAPSULES: "medical.form.capsules.label",
        TOPICAL_MEDICINES: "medical.form.topical.medicines.label",
        SUPPOSITORIES: "medical.form.suppositories.label",
        DROPS: "medical.form.drops.label",
        INHALERS: "medical.form.inhalers.label",
        INJECTIONS: "medical.form.injections.label",
        IMPLANTS_OT_PATCHES: "medical.form.implants.patches.label"
    };
    return medicalForms[code] || "";
}

export const getReportPeriodsInput = () => {
    return [
        "LAST_MONTH",
        "LAST_QUARTER",
        "LAST_YEAR",
        "BILLING_PERIOD",
        "CUSTOM"
    ];
}

export const getReportPeriodInput = (code) => {
    const reportPeriods = {
        "LAST_MONTH": "report.period.last.month.label",
        "LAST_QUARTER": "report.period.last.quarter.label",
        "LAST_YEAR": "report.period.last.year.label",
        "BILLING_PERIOD": "report.period.billing.period.label",
        "CUSTOM": "report.period.custom.label",
    };
    return reportPeriods[code] || "";
};

export const getMedicationForms = () => {
    return [
        "LIQUID",
        "TABLET",
        "CAPSULES",
        "TOPICAL_MEDICINES",
        "SUPPOSITORIES",
        "DROPS",
        "INHALERS",
        "INJECTIONS",
        "IMPLANTS_OT_PATCHES"
    ];
}

export const getBookingStatuses = () => {
    return ["REQUESTED", "APPROVED", "COMPLETED", "REJECTED"];
};

export const getReviewStatuses = () => {
    return ["REQUESTED", "APPROVED", "DECLINED"];
};

export const getBookingStatus = (code) => {
    const bookingStatuses = {
        "REQUESTED": "booking.status.requested.label",
        "APPROVED": "booking.status.approved.label",
        "COMPLETED": "booking.status.completed.label",
        "REJECTED": "booking.status.rejected.label"
    };
    return bookingStatuses[code] || "";
};

export const getReviewStatus = (code) => {
    const reviewStatuses = {
        "REQUESTED": "status.new",
        "APPROVED": "status.approved",
        "DECLINED": "status.declined",
    };
    return reviewStatuses[code] || "";
};

export const getDurations = () => {
    return ["DAY", "WEEK", "MONTH"];
};

export const getDuration = (code) => {
    const durations = {
        "DAY": "medication.duration.day.label",
        "WEEK": "medication.duration.week.label",
        "MONTH": "medication.duration.month.label"
    };
    return durations[code] || "";
};

export const getRole = (code) => {
    const roles = {
        "ADMIN_BILLING" : "role.admin.billing",
        "ADMIN": "role.admin",
        "PATIENT": "role.patient",
        "DOCTOR": "role.doctor",
        "ASSISTANT": "role.assistant",
        "PHARMACY": "role.pharmacy"
    };
    return roles[code] || "";
};

export const getRoleInput = (code) => {
    const roles = {
        "ADMIN_BILLING" : "role.admin.billing",
        "ADMIN": "role.admin",
        "PATIENT": "role.patient",
        "DOCTOR": "role.doctor",
        "ASSISTANT": "role.assistant",
        "PHARMACY": "role.pharmacy"
    };
    return roles[code];
}

export const getRolesInput = () => {
    return [ "ADMIN_BILLING", "ADMIN", "PATIENT", "DOCTOR", "ASSISTANT", "PHARMACY"];
}

export const getSex = (code) => {
    const sex = {
        "MALE": <FormattedMessage id="code.male.label"/>,
        "FEMALE": <FormattedMessage id="code.female.label"/>,
        "TRANSGENDER": <FormattedMessage id="core.transgender.text"/>
    };
    return sex[code];
}

export const getSexInput = (code) => {
    const sex = {
        "MALE": "code.male.label",
        "FEMALE": "code.female.label",
        "TRANSGENDER": "core.transgender.text"
    };
    return sex[code];
}

export const getSexsInput = () => {
    return ["MALE", "FEMALE"];
}

export const getFileExt = (fileName = "") => {
    return fileName.split('.').pop();
};

export const arrayRemoveNullElements = (array) => {
    if (_.isArray(array)) {
        return _.without(array, undefined)
    }
};

export const stringToFloat = (value = "", fractionDigits = 2) => {
    if (!value) return null;

    value = new String(value);
    value = value.replace(/[^\d.,]/ig, '');
    value = value.replace(",,", ",");
    value = value.replace(",", ".");
    value = value.replace("..", ".");
    return floatFormatting(value, fractionDigits);
};

export const stringToInt = (value) => {
    value = value.replace(/[^\d]/ig, '');
    return value;
};

export const isIE = () => {
    return (window.navigator.userAgent.indexOf('Trident') != -1 && window.navigator.userAgent.indexOf('rv:') != -1) ||
        window.navigator.userAgent.indexOf('; MSIE ') != -1;
}

export const isSafari = () => {
    return window.navigator.userAgent.indexOf('Safari') != -1 &&
        window.navigator.userAgent.indexOf('Chrome') === -1 &&
        window.navigator.userAgent.indexOf('Chromium') === -1
}

/*export const getBrowserLanguageLocale = () => {
    return navigator.languages && navigator.languages[0] || // Chrome / Firefox
        navigator.language ||   // All browsers
        navigator.userLanguage;
}*/


/*export const prepareSystemLanguageLocale = (locale) => {
    (async () => {
        await publicApi.get("/languages").then(response => {
            const languages = response.data || [];


            console.log(languages)
            const isNotExistLocale = languages.findIndex(i => i.locale === locale) === -1;
            if (isNotExistLocale) {
                locale = languages.filter(i => i.isDefault != null && i.isDefault)[0].locale || "";
            }

            localStorage.setItem("language", locale);
            moment.locale(locale);
            return locale;
        })
    })();
}*/

/*export const getDefaultSystemLanguageLocale = () => {
    if (localStorage.getItem("language") != null && localStorage.getItem("language") != "") {
        return localStorage.getItem("language");
    } else {
        return getBrowserLanguageLocale();
    }
}*/

export const floatFormatting = (v, fractionDigits = 2) => {
    return v ? parseFloat(v).toFixed(fractionDigits) : 0;
}

export const convertHeightToUnitSystem = (value, convertToUnitSystem) => {
    const config = {}
    const math = create(all, config)

    if (convertToUnitSystem == 'IMPERIAL') {
        return floatFormatting(math.unit(value, 'cm').to('in').toNumber());
    } else {
        return floatFormatting(math.unit(value, 'in').to('cm').toNumber());
    }
};

export const getHeightUnitToUnitSystem = (unitSystem) => {
    if (unitSystem == 'IMPERIAL') {
        return 'in';
    } else {
        return 'cm';
    }
};

export const convertWeightToUnitSystem = (value, convertToUnitSystem) => {
    const config = {}
    const math = create(all, config)

    if (convertToUnitSystem == 'IMPERIAL') {
        return floatFormatting(math.unit(value, 'kg').to('lb').toNumber());
    } else {
        return floatFormatting(math.unit(value, 'lb').to('kg').toNumber());
    }
};

export const getWeightUnitToUnitSystem = (unitSystem) => {
    if (unitSystem == 'IMPERIAL') {
        return 'lb';
    } else {
        return 'kg';
    }
};

export const convertTemperatureToUnitTemperature = (value, convertToUnitTemperature) => {
    const config = {}
    const math = create(all, config)

    if (convertToUnitTemperature == UNIT_TEMPERATURE.DEGREE_FAHRENHEIT) {
        return floatFormatting(math.unit(value, 'degC').to('degF').toNumber());
    } else {
        return floatFormatting(math.unit(value, 'degF').to('degC').toNumber());
    }
};

export const getTemperatureUnitToUnitTemperature = (unitTemperature) => {
    if (unitTemperature == UNIT_TEMPERATURE.DEGREE_FAHRENHEIT) {
        return 'ºF';
    } else {
        return 'ºC';
    }
};

export const niceBytes = (x, unit = null) => {
    const units = ['b', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;

    while(n >= 1024 && ++l){
        n = n/1024;
    }

    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}
