import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {Header} from "../../../components/elements/header";
import {GridCol} from "../../../components/collections/grid";
import {Option, Select} from "../../../components/elements/select";
import {Button} from "../../../components/elements/button";
import {fetchLanguages, updateUserSettings} from "../../services/commonService";
import {formChangeFieldAction, formInitAction} from "../../../actions/formAction";
import {FormattedMessage} from "react-intl";
import {RadioBox} from "../../../components/elements/radiobox";
import {formChangeField} from "../../services/system/formService";

class SettingsProfile extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    onSelectAppLanguage(language) {
        this.props.onFormSimpleChange("userSettings", "language", language);
    }

    componentDidMount() {
        const {auth: {user}} = this.props;
        const {language, unitSystem = "", unitTemperature = ""} = user;
        this.props.onFetchLanguages();
        this.props.onInitForm("userSettings", {
            language: language,
            unitSystem: unitSystem,
            unitTemperature: unitTemperature
        });
    }

    onChange(event) {
        this.props.onFormChangeField('userSettings', event);
    }

    render() {
        const {list: {data: listData}, form: {data: formData}} = this.props;
        const {languages = []} = listData;
        const {userSettings = {}} = formData;
        const selectedLanguage = userSettings.language || null;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="core.header.settings"/></Header>
                    </div>
                </div>

                <div className={"row content-row"}>
                    <div className={"col-12"}>
                        <div className="white-block">
                            <Header as="h3"><FormattedMessage id="core.header.language"/></Header>
                            <hr/>
                            <div className="row form-elements">
                                <GridCol count="3">
                                    <Select name="language"
                                            label={<FormattedMessage id="settings.profile.label.app.language"/>}
                                            optionNameSelected={selectedLanguage && selectedLanguage.name || ""}>
                                        {
                                            languages.map(language =>
                                                <Option
                                                    active={selectedLanguage && parseInt(selectedLanguage.id) === parseInt(language.id)}
                                                    key={language.id}
                                                    onClick={() => this.onSelectAppLanguage(language)}>
                                                    {language.name}
                                                </Option>
                                            )
                                        }
                                    </Select>
                                </GridCol>
                            </div>
                        </div>

                        <div className="white-block">
                            <Header as="h3"><FormattedMessage id="core.header.parameters"/></Header>
                            <hr/>
                            <div className="row form-elements">
                                <GridCol count="3">
                                    <Header as="h4"><FormattedMessage id="measurement.system"/></Header>
                                    <div className="form-label-group">
                                        <RadioBox name="unitSystem"
                                                  value="METRIC"
                                                  asButton
                                                  color={'blue'}
                                                  onChange={this.onChange}
                                                  checked={userSettings.unitSystem === 'METRIC'}>
                                            <FormattedMessage id="measurement.system.metric"/></RadioBox>
                                        <RadioBox name="unitSystem"
                                                  value="IMPERIAL"
                                                  asButton
                                                  color={'blue'}
                                                  onChange={this.onChange}
                                                  checked={userSettings.unitSystem === 'IMPERIAL'}>
                                            <FormattedMessage id="measurement.system.imperial"/></RadioBox>
                                    </div>
                                </GridCol>
                                <GridCol count="3">
                                    <Header as="h4"><FormattedMessage id="temperature"/></Header>
                                    <div className="form-label-group">
                                        <RadioBox name="unitTemperature"
                                                  value="DEGREE_CELSIUS"
                                                  asButton
                                                  color={'blue'}
                                                  onChange={this.onChange}
                                                  checked={userSettings.unitTemperature === 'DEGREE_CELSIUS'}>ºC</RadioBox>
                                        <RadioBox name="unitTemperature"
                                                  value="DEGREE_FAHRENHEIT"
                                                  asButton
                                                  color={'blue'}
                                                  onChange={this.onChange}
                                                  checked={userSettings.unitTemperature === 'DEGREE_FAHRENHEIT'}>ºF</RadioBox>
                                    </div>
                                </GridCol>
                            </div>
                        </div>
                        <div className="form-submit margin-top-15">
                            <Button
                                disabled={!userSettings.language}
                                onClick={() => this.props.onUpdateUserSettings(userSettings)}><FormattedMessage
                                id="core.btn.save.label"/></Button>
                        </div>
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        list: store.list,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onFetchLanguages: () => dispatch(fetchLanguages()),
        onUpdateUserSettings: (userSettings) => dispatch(updateUserSettings(userSettings))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsProfile);
