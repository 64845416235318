import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {Icon} from "../../elements/icon";
import classnames from "classnames";
import {EmptyTag} from "../../elements/emptyTag";

export const MenuItem = (props) => {
    const {icon = null, disabled = false, ...rest} = props;
    const linkClassNames = classnames('link-icon', {
        'disabled': disabled
    });
    const {to = null} = props;
    return (
        <EmptyTag>
            {to && to == '#' &&
                <a href="#" {...rest} className={linkClassNames}>
                    <div className="icon-item">
                        {icon && <Icon name={icon}/>}
                        <span className="icon-item__text">{props.children}</span>
                    </div>
                </a>}
            {!to || to != '#' &&
                <NavLink {...rest} className={linkClassNames}>
                    <div className="icon-item">
                        {icon && <Icon name={icon}/>}
                        <span className="icon-item__text">{props.children}</span>
                    </div>
                </NavLink>}
        </EmptyTag>


    );
};

export const LinkItem = (props) => {
    const {icon = null, isActive = false, ...rest} = props;
    const linkClassNames = classnames('link-icon', {
        'active': isActive
    });
    return (
        <Link {...rest} className={linkClassNames}>
            <div className="icon-item">
                {icon && <Icon name={icon}/>}
                <span className="icon-item__text">{props.children}</span>
            </div>
        </Link>
    );
};