import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {formChangeField} from "../../../services/system/formService";
import {isModal} from "../../../../utils/app";
import {formChangeFieldAction} from "../../../../actions/formAction";
import {Button} from "../../../../components/elements/button";
import {FormattedMessage} from "react-intl";
import {
    fetchProfileAboutMe,
    updateDoctorProfileAboutMe
} from "../../../services/doctorProfileService";

import DoctorLocation from "./DoctorLocation";

import {Textarea} from "../../../../components/elements/textarea";

class DoctorProfileAboutMe extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {profileUserId} = this.props;
        this.props.onFetchDoctorProfileAboutMe(profileUserId);
    }

    onChange(event) {
        this.props.onFormChangeField('profileAboutMe', event);
    }

    render() {
        const {form, modal, profileUserId} = this.props;
        const profileAboutMe = form.data.profileAboutMe || {};

        return (
            <EmptyTag>
                <div className="row content-row">
                    <GridCol count="1">
                        <div className="white-block">
                            <div className="row form-elements">
                                <GridCol count="1">
                                    <Textarea placeholder={<FormattedMessage id="about.me"/>}
                                              onChange={this.onChange}
                                              value={(profileAboutMe && profileAboutMe.description) || ''}
                                              name="description"/>
                                </GridCol>


                            </div>
                        </div>
                    </GridCol>
                </div>
                <div className="form-submit margin-top-15">
                    <Button
                        onClick={() => this.props.onUpdateDoctorProfileAboutMe(profileUserId, profileAboutMe)}><FormattedMessage
                        id="core.btn.save.label"/></Button>
                </div>

                {isModal(modal, 'doctorLocation') && <DoctorLocation/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchDoctorProfileAboutMe: (id) => dispatch(fetchProfileAboutMe(id)),
        onUpdateDoctorProfileAboutMe: (id, data) => dispatch(updateDoctorProfileAboutMe(id, data)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorProfileAboutMe);
