import React, {Component} from 'react';
import {connect} from "react-redux";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {fetchBookings} from "../../../services/bookingService";
import PayWidget from "../bookings/payment/PayWidget";
import {isModal} from "../../../../utils/app";
import Pay from "../bookings/payment/Pay";

class NotPayedBookings extends Component {

    componentDidMount() {
        this.props.onListSorts({timeOfReceipt: 'asc'});
        this.props.onListFilterField("paymentStatus", "PENDING");
        this.props.onListFilterField("status", "REQUESTED");
        this.props.onListFilterField("isPaymentExpired", "false");
        this.props.onListFilterField("isFamilyMember", "true");
        this.props.onFetchBookings(1);
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    render() {
        const {list: {data}, modal} = this.props;
        const {notPayedBookings = []} = data;
        const {0: firstNotPayedBookings = {}} = notPayedBookings;
        if (notPayedBookings.length == 0) return "";
        return (
            <div className="col-12">
                <div className="white-block padding-0">
                    <PayWidget booking={firstNotPayedBookings} isShowButtonDetail={true} callbackAfterPay={() => this.props.onFetchBookings(1)}/>
                    {isModal(modal, 'pay') && <Pay/>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        list: store.list,

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitList: () => dispatch(listInitAction("notPayedBookings")),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber, "notPayedBookings")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("notPayedBookings", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("notPayedBookings", sorts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotPayedBookings);
