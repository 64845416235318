import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {fetchFamilyMembers} from "../../../services/familyService";
import {Option, Select} from "../../../../components/elements/select";
import {formInitAction} from "../../../../actions/formAction";
import {listInitAction} from "../../../../actions/listAction";
import {withRouter} from "react-router-dom";

class ChooseMemberSelect extends Component {
    constructor(props) {
        super(props);
        this.onChoose = this.onChoose.bind(this);
    }

    onChoose(child) {
        this.props.onInitForm("chooseMemberSelect", {"member": child});
    }

    componentDidMount() {
        this.props.onFetchFamilyMembers();
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    render() {
        const {list: {data: listData}, auth: {user}, form: {data: formData}} = this.props;
        const {familyMembers = []} = listData;
        const {chooseMemberSelect = {}} = formData;
        const {member = null} = chooseMemberSelect;
        let optionNameSelected = '';
        if (member) {
            if (member.id == user.id) {
                optionNameSelected = user.firstName + ' ' + user.lastName ;
            } else {
                const chooseFamilyMember = familyMembers.filter(i => i.child.id == member.id)[0];
                if (chooseFamilyMember != null) {
                    const {child, memberType} = chooseFamilyMember;
                    const {firstName, lastName} = child;
                    optionNameSelected = memberType.name + ' - ' + firstName + ' ' + lastName ;
                }
            }
        }

        return (
            <Select name=""
                    bgColor={'white'}
                    label={<FormattedMessage id="patient"/>}
                    optionNameSelected={optionNameSelected}>
                {familyMembers.map(familyMember => {
                    const {id, child, memberType} = familyMember;
                    const {firstName, lastName} = child;
                    return (
                        <Option
                            active={member && member.id == child.id}
                            key={id}
                            onClick={() => this.onChoose(child)}>
                            {memberType.name} &nbsp;-&nbsp;{firstName} {lastName}
                        </Option>)

                })}

                <Option
                    active={member && member.id == user.id}
                    onClick={() => this.onChoose(user)}>
                    {user.firstName} {user.lastName}
                </Option>
            </Select>

        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onInitList: () => dispatch(listInitAction("familyMembers")),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onFetchFamilyMembers: () => dispatch(fetchFamilyMembers())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseMemberSelect));
