import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Icon, IconItem} from "../../../../components/elements/icon";
import Search from "../../../systems/search";
import Pagination from "react-js-pagination";
import moment from "moment";
import {listFilterAction, listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import Dropdown, {DropdownItem} from "../../../systems/dropdown";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import {disableUser, enableUser, fetchUsers} from "../../../services/userService";
import {createSortObject, getRole, isModal} from "../../../../utils/app";
import _ from "lodash";
import {Option, Select} from "../../../../components/elements/select";
import {Button, ButtonRemove} from "../../../../components/elements/button";
import UsersFilter from "./UsersFilter";
import {formInitAction} from "../../../../actions/formAction";
import CreateAccount from "./CreateAccount";
import {withRouter} from 'react-router-dom';

class Users extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onClear = this.onClear.bind(this);
        this.onSortBy = this.onSortBy.bind(this);
        this.onRemoveFilter = this.onRemoveFilter.bind(this);
    }

    componentDidMount() {
        this.props.onListSorts({"id": "desc"})
        this.onPagination(1);
    }

    onPagination(pageNumber = 1) {
        this.props.onFetchUsers(pageNumber);
    }

    componentWillUnmount() {
        this.props.onInitList("users");
    }

    onSortBy = (sortBy = "") => {
        this.props.onListSorts(createSortObject(sortBy))
        this.onPagination(1);
    }

    onSearch(text) {
        this.props.onListFilterField("searchText", text || null);
        this.onPagination(1);
    }

    onClear = () => {
        this.props.onInitFilter({});
        this.props.onInitForm("chosenFilters", {});
        this.onPagination(1);
    }

    onRemoveFilter = (field) => {
        const {form: {data: formData}} = this.props;
        const {chosenFilters = {}} = formData;
        _.unset(chosenFilters, field);
        this.props.onListFilterField(field, null);
        this.props.onInitForm("chosenFilters", chosenFilters);
        this.onPagination(1);
    }

    render() {
        const {
            list: {data: listData, pagination: pagination, filters, sorts},
            form: {data: formData},
            modal
        } = this.props;
        const {users: listUsersFilter = {}} = filters;
        const {users: listUsersSort = {}} = sorts;
        const {users = []} = listData;
        const {number = 0, size = 0, totalElements = 0} = pagination['users'] || {};
        const {chosenFilters = {}} = formData;
        const sortByOptions = {
            "createdAt,desc": "date.registration.desc",
            "createdAt,asc": "date.registration.asc",
            "firstName,asc;lastName,asc": "full.name.asc",
            "firstName,desc;lastName,desc": "full.name.desc"
        };
        const currentSortBy = _.map(listUsersSort, (key, val) => val + "," + key).join(";");
        const currentSortByOption = sortByOptions[currentSortBy] || null;
        const dropDownButton = <div>
            <Icon name="menu-points"/>
        </div>;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"> <FormattedMessage id="manage.users"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                        <a href="#" onClick={() => this.props.onModalShow("createAccount")}
                           className="btn-icon color-blue">
                            <IconItem name="plus-bordered"><FormattedMessage id="core.btn.add.label"/></IconItem>
                        </a>
                    </div>
                </div>
                <div className="row form-elements filtr-form align-items-center justify-content-end">
                    <div className="col-auto">
                        <Search onSearch={this.onSearch} placeholder="search.by.name"
                                value={listUsersFilter.searchText || null}/>
                    </div>

                    <div className="col col-empty"></div>

                    <div className="col-auto padding-right-0">
                        <label className="field-label"><FormattedMessage id="sort.by"/></label>
                    </div>

                    <div className="col-auto">
                        <FormattedMessage id={currentSortByOption || 'undefined'}>
                            {optionNameSelected =>
                                <Select noLabel
                                        bgColor="white"
                                        width={360}
                                        height={40}
                                        optionNameSelected={optionNameSelected}>
                                    {
                                        Object.keys(sortByOptions).map(key => {
                                                return (
                                                    <Option
                                                        active={key == currentSortBy}
                                                        key={key}
                                                        onClick={() => this.onSortBy(key)}>
                                                        <FormattedMessage id={sortByOptions[key]}/>
                                                    </Option>
                                                )
                                            }
                                        )
                                    }
                                </Select>}
                        </FormattedMessage>
                    </div>

                    <div className="col-auto">
                        <Button square icon="filtr" onClick={() => this.props.onModalShow("usersFilter")}/>
                    </div>

                    {Object.keys(listUsersFilter).length > 0 &&
                    <div className="col-12">
                        <div className="selected-filters">
                            <Button border="red-bordered" sizeMiddle onClick={() => this.onClear()}>
                                <FormattedMessage id="clear.filter"/>
                            </Button>

                            {Object.keys(chosenFilters).map(field => {
                                const value = chosenFilters[field];
                                if (!value) return;
                                return (
                                    <label className="form-label button-label editable-label button-label-gray"
                                           key={"chosen-filter-" + field}>
                                    <span>
                                        <i>
                                        {field === 'role' &&
                                        <EmptyTag>
                                            <FormattedMessage id="role"/>: <FormattedMessage id={value}/>
                                        </EmptyTag>}
                                            {field === 'firstName' &&
                                            <EmptyTag>
                                                <FormattedMessage id="core.first.name.label"/>: <FormattedMessage
                                                id={value}/>
                                            </EmptyTag>}
                                            {field === 'lastName' &&
                                            <EmptyTag>
                                                <FormattedMessage id="core.last.name.label"/>: {value}
                                            </EmptyTag>}

                                            {field === 'email' &&
                                            <EmptyTag>
                                                <FormattedMessage id="core.field.email.label"/>: <FormattedMessage
                                                id={value}/>
                                            </EmptyTag>}

                                            {field === 'createdAtFrom' &&
                                            <EmptyTag>
                                                <FormattedMessage id="createdAt"/>: <FormattedMessage
                                                id="from"/>&nbsp;{value}
                                            </EmptyTag>}

                                            {field === 'createdAtTo' &&
                                            <EmptyTag>
                                                <FormattedMessage id="createdAt"/>: <FormattedMessage
                                                id="to"/>&nbsp;{value}
                                            </EmptyTag>}

                                        </i>
                                    </span>
                                        <ButtonRemove onClick={() => this.onRemoveFilter(field)}/>
                                    </label>
                                )
                            })}
                        </div>
                    </div>}
                </div>

                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th><FormattedMessage id="id"/></th>
                            <th><FormattedMessage id="core.active.label"/></th>
                            <th><FormattedMessage id="role"/></th>
                            <th><FormattedMessage id="full.name"/></th>
                            <th><FormattedMessage id="core.field.email.label"/></th>
                            <th><FormattedMessage id="core.phone.label"/></th>
                            <th><FormattedMessage id="core.date.created.label"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map(user => {
                            let editUrl = "#";
                            if (user.role === 'DOCTOR') {
                                editUrl = "/admin/users/" + user.id + "/profile/doctor";
                            } else if (user.role === 'PATIENT') {
                                editUrl = "/admin/users/" + user.id + "/profile/patient";
                            }

                            return (
                                <tr key={user.id}>
                                    <td className="td-controls">
                                        <Dropdown button={dropDownButton} noArrow="true">
                                            {!user.isEnabled &&
                                            <DropdownItem to={"#"} icon="active"
                                                          onClick={() => this.props.onConfirm({
                                                              text: <FormattedMessage
                                                                  id="confirm.admin.active.doctor.text"/>,
                                                              onProceed: (() => this.props.onEnableUser(user.id))
                                                          })}><FormattedMessage id="core.active.label"/></DropdownItem>}

                                            {user.isEnabled &&
                                            <DropdownItem to={"#"} icon="block"
                                                          onClick={() => this.props.onConfirm({
                                                              text: <FormattedMessage
                                                                  id="confirm.admin.block.doctor.text"/>,
                                                              onProceed: (() => this.props.onDisableUser(user.id))
                                                          })}><FormattedMessage id="core.block.label"/></DropdownItem>}

                                            {(user.role === 'DOCTOR' || user.role === 'PATIENT') &&
                                            <DropdownItem to={editUrl}
                                                          icon="edit">
                                                <FormattedMessage id="core.edit.label"/></DropdownItem>}

                                            {user.role === 'DOCTOR' && user.isFillProfile &&
                                            <DropdownItem to={"/admin/users/" + user.id + "/profile/doctor/schedule"}
                                                          icon="calendar-2">
                                                <FormattedMessage id="core.schedule.label"/></DropdownItem>}
                                        </Dropdown>
                                    </td>
                                    <td>{user.id}</td>
                                    <td>
                                        {user.isEnabled &&
                                        <span className="badge badge-no-radius badge-blue"><FormattedMessage
                                            id="core.yes.label"/></span>}
                                        {!user.isEnabled &&
                                        <span className="badge badge-no-radius badge-red"><FormattedMessage
                                            id="core.no.label"/></span>}
                                    </td>
                                    <td><FormattedMessage id={getRole(user.role)}/></td>
                                    <td>{user.firstName} {user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td><span className="nowrap">{user.phone}</span></td>
                                    <td>{user.createdAt && moment(user.createdAt).format("ll")}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {
                        size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>
                    }

                </div>
                {
                    isModal(modal, 'usersFilter') && <UsersFilter onFilter={() => this.onPagination()}/>
                }
                {
                    isModal(modal, 'createAccount') && <CreateAccount/>
                }
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitFilter: (filters) => dispatch(listFilterAction('users', filters)),
        onModalShow: (name) => dispatch(modalShow(name)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchUsers: (pageNumber) => dispatch(fetchUsers(pageNumber)),
        onEnableUser: (userId) => dispatch(enableUser(userId, 'users')),
        onDisableUser: (userId) => dispatch(disableUser(userId, 'users')),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListSorts: (sorts) => dispatch(listSortAction("users", sorts)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("users", fieldName, fieldValue)),
        onFormConfigureDoctorSchedule: () => dispatch(modalShow('configureDoctorSchedule')),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));