import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {WhiteBlock} from "../../../../../components/elements/paragraph";
import {Header} from "../../../../../components/elements/header";
import {Button} from "../../../../../components/elements/button";
import {formInit} from "../../../../services/system/formService";
import {
    medicationDurationFormat,
    removeMedicalTestFileResult,
    saveMedicalTestFileResult
} from "../../../../services/consultationService";
import {File} from "../../../../../components/elements/file";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {modalShow} from "../../../../services/system/modalService";
import {getMedicationForm, isModal} from "../../../../../utils/app";
import PharmacyInfo from "./PharmacyInfo";
import {downloadMedicalTestResult, downloadPrescription} from "../../../../services/bookingService";
import {FormattedMessage} from "react-intl";

class DetailPrescription extends Component {
    constructor(props) {
        super(props);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    onChangeFile(event, medicalTest) {
        const {form: {data}} = this.props;
        const {booking} = data;
        const files = event.target.files;
        this.props.onSaveMedicalTestFileResult(booking, medicalTest, files);
    }

    render() {
        const {form: {data}, modal} = this.props;
        const {booking = {}} = data;
        const {consultation = {}} = booking;
        const {medications = [], medicalTests = [], pharmacy = {}, isPrescribe} = consultation;
        return (
            <div className="row content-row">
                <GridCol count="2">
                    <WhiteBlock>
                        <GridRow>
                            <div className="col">
                                <Header as="h3"><FormattedMessage id="page.column.prescriptions.header"/></Header>
                            </div>
                            {isPrescribe &&
                                <div className="col-auto">
                                    <div className="color-green"><FormattedMessage
                                        id="booking.prescriptions.prescribe.is.true"/></div>
                                </div>}
                            {medications && medications.length > 0 &&
                                <EmptyTag>
                                    <GridCol count="1">
                                        <div
                                            className="row form-elements form-bottom-bordered form-bg-light form-radius-8">
                                            {medications.map(({
                                                                  id,
                                                                  status,
                                                                  prescribedMedication,
                                                                  form,
                                                                  duration,
                                                                  directionsForUse,
                                                                  repeatPrescriptions
                                                              }) => {
                                                return (
                                                    <GridCol count="1" key={id}>
                                                        <div className="row">
                                                            <div className="col">
                                                                <Header as="h4">{prescribedMedication}</Header>
                                                            </div>
                                                            <div className="col-auto">
                                                                {status && status === 'REQUESTED' &&
                                                                    <div className="color-gray small-text">
                                                                        <FormattedMessage
                                                                            id="medication.status.requested.label"/>
                                                                    </div>}
                                                                {status && status === 'ACCEPTED' &&
                                                                    <div className="color-orange small-text">
                                                                        <FormattedMessage
                                                                            id="medication.status.accepted.label"/>
                                                                    </div>}
                                                                {status && status === 'COMPLETED' &&
                                                                    <div className="color-green small-text">
                                                                        <FormattedMessage
                                                                            id="medication.status.completed.label"/>
                                                                    </div>}
                                                            </div>
                                                            <GridCol count="1">
                                                                <div className="small-text">
                                                                    <div className="color-gray">
                                                                        {<FormattedMessage
                                                                            id={getMedicationForm(form)}/>}, {directionsForUse}
                                                                    </div>
                                                                    <div className="color-blue">
                                                                        {<FormattedMessage
                                                                            id={medicationDurationFormat(repeatPrescriptions, duration)}
                                                                            values={{repeat: repeatPrescriptions}}/>}
                                                                    </div>
                                                                </div>
                                                            </GridCol>
                                                        </div>
                                                    </GridCol>
                                                );
                                            })}

                                        </div>
                                    </GridCol>

                                    <GridCol count="1">
                                        <div
                                            className="row form-elements form-bottom-bordered form-bg-light form-radius-8">
                                            <div className="col-12">
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <h4><FormattedMessage id="download.prescription"/></h4>
                                                    </div>
                                                    <div className="col-auto">
                                                        <Button
                                                            onClick={() => this.props.onDownloadPrescription(booking)}
                                                            square={true}
                                                            squareColor={'blue'}
                                                            icon={'download-white'}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </GridCol>
                                </EmptyTag>
                            }

                            {medications.length === 0 && <GridCol count="1"><FormattedMessage
                                id="booking.medications.list.empty.text"/></GridCol>}

                            {medications && medications.length > 0 && pharmacy && <EmptyTag>
                                <div className="col">
                                    <Header as="h3"><FormattedMessage id="page.column.pharmacies.header"/></Header>
                                </div>

                                {!pharmacy &&
                                    <GridCol count="1"><FormattedMessage id="pharmacies.not.choose.text"/></GridCol>}

                                {pharmacy &&
                                    <GridCol count="1">
                                        <div className="row form-elements form-bottom-bordered form-bg-light">
                                            <GridCol count="1">
                                                <div className="row">
                                                    <div className="col">
                                                        <Header as={"h4"}>{pharmacy.name}</Header>
                                                        <div className="color-gray">{pharmacy.address}</div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className="small-controls">
                                                            <Button color="white-blue"
                                                                    onClick={() => this.props.onPharmacyInfo(pharmacy)}><FormattedMessage
                                                                id="core.btn.detail.label"/></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </GridCol>
                                        </div>
                                    </GridCol>}
                            </EmptyTag>}
                        </GridRow>
                    </WhiteBlock>
                </GridCol>
                <GridCol count="2">
                    <WhiteBlock>
                        <GridRow>
                            <GridCol count="1">
                                <Header as="h3"><FormattedMessage id="page.column.medical.test.header"/></Header>
                            </GridCol>
                            {medicalTests && medicalTests.length > 0 &&
                                <GridCol count="1">
                                    <div className="row form-elements form-bottom-bordered form-bg-light">
                                        {medicalTests.map((medicalTest, index) => {
                                            return (
                                                <EmptyTag key={medicalTest.id}>
                                                    {medicalTest.files.length === 0 &&
                                                        <GridCol count="1">
                                                            <div>{medicalTest.name}</div>
                                                            <div className="controls-top-right">
                                                                <File name="file"
                                                                      onChange={(event) => this.onChangeFile(event, medicalTest)}/>
                                                            </div>
                                                        </GridCol>}
                                                    {medicalTest.files.map(file => {
                                                        return (
                                                            <GridCol count="1" key={file.id}>
                                                                <div><a href="#"
                                                                        onClick={() => this.props.onDownloadFile(consultation, medicalTest, file)}>{medicalTest.name}</a>
                                                                </div>
                                                                <div className="controls-top-right">
                                                                    <i className="icon icon--success"></i>
                                                                    <button type="button"
                                                                            className="btn-delete"
                                                                            onClick={() => this.props.onConfirm({
                                                                                text: <FormattedMessage
                                                                                    id="confirm.remove.medical.test.result.text"/>,
                                                                                onProceed: (() => this.props.onRemoveMedicalTestFileResult(booking, medicalTest, file))
                                                                            })}>×
                                                                    </button>
                                                                </div>
                                                            </GridCol>
                                                        )
                                                    })}

                                                </EmptyTag>
                                            );
                                        })}
                                    </div>
                                </GridCol>
                            }
                            {medicalTests && medicalTests.length == 0 &&
                                <GridCol count="1"><FormattedMessage
                                    id="booking.medical.tests.list.empty.text"/></GridCol>}
                        </GridRow>
                    </WhiteBlock>
                </GridCol>
                {isModal(modal, 'pharmacyInfo') && <PharmacyInfo/>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDownloadPrescription: (booking) => dispatch(downloadPrescription(booking)),
        onPharmacyInfo: (data) => dispatch(modalShow('pharmacyInfo', data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onDownloadFile: (consultation, medicalTest, fileResult) =>
            dispatch(downloadMedicalTestResult(consultation, medicalTest, fileResult)),
        onFormInit: (data) => dispatch(formInit("medicalTestResults", data)),
        onSaveMedicalTestFileResult: (booking, medicalTest, fileResult) =>
            dispatch(saveMedicalTestFileResult(booking, medicalTest, fileResult)),
        onRemoveMedicalTestFileResult: (booking, medicalTest, fileResult) =>
            dispatch(removeMedicalTestFileResult(booking, medicalTest, fileResult)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailPrescription);
