import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {Input} from "../../../../../../components/elements/input";
import {modalHide} from "../../../../../services/system/modalService";
import {formChangeField, formInit, formSimpleChange} from "../../../../../services/system/formService";
import {Modal} from "../../../../../../components/modules/modal";
import {Header} from "../../../../../../components/elements/header";
import {GridCol, GridRow} from "../../../../../../components/collections/grid";
import {Button, Buttons} from "../../../../../../components/elements/button";
import moment from "moment";
import {EmptyTag} from "../../../../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import {unverifiablePaymentRefund} from "../../../../../services/bookingPaymentService";
import {PaymentProvider} from "../../../../../../components/collections/payment_status";

class UnverifiablePaymentRefund extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeDateTime = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('unverifiablePaymentRefund', event);
    }

    onChangeDateTime(event) {
        const value = moment(event.target.value, "DD.MM.YYYY hh:mm:ss").isValid() ? event.target.value : null;
        this.props.onFormSimpleChangeField('unverifiablePaymentRefund', event.target.name, value);
    }

    render() {
        const {form: {data: formData, errors: formErrors, isSend: formIsSend}} = this.props;
        const {unverifiablePaymentRefund} = formData;
        const errors = formErrors.unverifiablePaymentRefund || [];
        const disabled = formIsSend.unverifiablePaymentRefund.isSend || false;
        const {booking} = unverifiablePaymentRefund;
        const {payment} = booking;
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('unverifiablePaymentRefund')} width={600}>
                <Header as="h2"><FormattedMessage id="modal.create.refund.transaction.header.label"/></Header>

                <GridRow>
                    <GridCol count='1'>
                        <Input placeholder={<FormattedMessage id="modal.create.refund.transaction.tx.number.label"/>}
                               name="refundTransactionNumber"
                               value={(unverifiablePaymentRefund && unverifiablePaymentRefund.refundTransactionNumber) || ''}
                               error={(errors && errors["refundTransactionNumber"]) || null}
                               disabled={disabled}
                               onChange={this.onChange}/>
                    </GridCol>
                    <GridCol count='1'>
                        <Input placeholder={<FormattedMessage id="modal.create.refund.transaction.tx.date.label"/>}
                               name="refundCompletedAt"
                               mask="99.99.9999 99:99:99"
                               value={(unverifiablePaymentRefund && unverifiablePaymentRefund.refundCompletedAt) || ''}
                               error={(errors && errors["refundCompletedAt"]) || null}
                               disabled={disabled}
                               onChange={this.onChange}/>
                    </GridCol>
                    <GridCol count='1'>
                        <div className="col-12">
                            <div className="row">
                                <div className="col big-text"><FormattedMessage
                                    id="modal.create.refund.transaction.total.label"/></div>
                                <div className="col big-text align-right">
                                    {(payment && payment.currency) || ''}&nbsp;
                                    {(payment && payment.amount && parseFloat(payment.amount).toFixed(2)) || ''}
                                </div>
                            </div>
                        </div>
                    </GridCol>
                    <GridCol count='1'>
                        <div className="white-block">
                            <Header as="h3" align="left"><FormattedMessage
                                id="modal.create.refund.transaction.payment.detail"/></Header>
                            <table className="simple-table">
                                <tbody>
                                {payment &&
                                <EmptyTag>
                                    <tr>
                                        <td className="color-gray small-text"><FormattedMessage
                                            id="modal.create.refund.transaction.pay.type.label"/></td>
                                        <td className="align-right">
                                            <PaymentProvider payment={payment}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="color-gray small-text"><FormattedMessage
                                            id="modal.create.refund.transaction.tx.number.label"/></td>
                                        <td className="align-right">{payment.paymentTransactionNumber}</td>
                                    </tr>
                                </EmptyTag>
                                }
                                </tbody>
                            </table>
                        </div>
                    </GridCol>
                    <GridCol count='1'>
                        <Buttons>
                            <Button onClick={() => this.props.onUnverifiablePaymentRefund(unverifiablePaymentRefund)}><FormattedMessage
                                id="core.btn.save.label"/></Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        modal: store.modal,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormInit: (formName) => dispatch(formInit(formName, {})),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onUnverifiablePaymentRefund: (data) => dispatch(unverifiablePaymentRefund(data))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnverifiablePaymentRefund));
