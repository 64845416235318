import React, {Component} from 'react';
import {connect} from "react-redux";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import {Button} from "../../../../components/elements/button";
import MedicalRecordDataItem from "./MedicalRecordDataItem";
import {formChangeFieldAction} from "../../../../actions/formAction";
import {formChangeField} from "../../../services/system/formService";
import {fetchPatientMedicalRecord, saveMedicalRecord} from "../../../services/patientService";
import PathologicalReports from "./PathologicalReports";
import {
    getHeightUnitToUnitSystem,
    getWeightUnitToUnitSystem,
    stringToFloat
} from "../../../../utils/app";
import {Input} from "../../../../components/elements/input";
import {FormattedMessage} from "react-intl";

class MedicalRecordContent extends Component {
    constructor(props) {
        super(props);
        this.onAdd = this.onAdd.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeFloat = this.onChangeFloat.bind(this);
    }

    componentDidMount() {
        const {patient} = this.props;
        this.props.onFetchPatientMedicalRecord(patient);
        this.props.onChangeTabPanel("allergies");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData}} = this.props;
        const {form: {data: prevFormData}} = prevProps;

        const {chooseMemberSelect: curChooseMemberSelect = {}} = curFormData;
        const {chooseMemberSelect: prevChooseMemberSelect = {}} = prevFormData;
        const {member : curMember= null} = curChooseMemberSelect;
        const {member : prevMember= null} = prevChooseMemberSelect;
        if (curMember && prevMember && curMember.id != prevMember.id) {
            this.props.onFetchPatientMedicalRecord(curMember);
            this.props.onChangeTabPanel("allergies");
        }
    }

    onChange(event) {
        this.props.onFormChangeField('medicalRecord', event);
    }

    onChangeFloat(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChangeField(target.name, stringToFloat(value));
    }

    onAdd(dataItemType) {
        const {form: {data}} = this.props;
        const {medicalRecord = {}} = data;
        let {recordItems = []} = medicalRecord || {};
        if (recordItems == null) recordItems = [];
        recordItems.push({type: dataItemType, name: ""});
        this.props.onFormSimpleChangeField("recordItems", recordItems);
    }

    render() {
        const {form: {data, isSend: formIsSend, errors: formErrors}, tab: {data: tabData}, auth: {user}, patient} = this.props;
        const {medicalRecord: tabMedicalRecord = {}} = tabData;
        const {medicalRecord = {}} = data;
        const disabled = formIsSend.medicalRecord || false;
        const errors = formErrors.medicalRecord;
        const unitSystem = user.unitSystem;
        return (
            <div className="row content-row">
                <div className="col-12">
                    <div className="white-block">

                        <div className="row form-elements form-padding-0 align-items-center">
                            <div className="col-auto">
                                <h3><FormattedMessage id="medical.record.clinical.vitals.label"/></h3>
                            </div>
                            <div className="col-md-4">
                                <Input name="weight"
                                       placeholder={
                                           <FormattedMessage id="medical.record.weight.label"
                                                             values={{unit: getWeightUnitToUnitSystem(unitSystem)}}/>}
                                       error={(errors && errors.weight) || null}
                                       value={medicalRecord.weight || ''}
                                       onBlur={this.onChangeFloat}
                                       onChange={this.onChange}/>
                            </div>
                            <div className="col-md-4">
                                <Input name="height"
                                       placeholder={
                                           <FormattedMessage id="medical.record.height.label"
                                                             values={{unit: getHeightUnitToUnitSystem(unitSystem)}}/>}
                                       error={(errors && errors.height) || null}
                                       value={medicalRecord.height || ''}
                                       onBlur={this.onChangeFloat}
                                       onChange={this.onChange}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="white-block">
                        <Tabs showAs="links" marginBottom="15" fontSize="16">
                            <Tab onClick={() => this.props.onChangeTabPanel("allergies")}
                                 active={tabMedicalRecord.activeTabPanel === 'allergies'}><FormattedMessage
                                id="medical.record.tab.allergies.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("conditions")}
                                 active={tabMedicalRecord.activeTabPanel === 'conditions'}><FormattedMessage
                                id="medical.record.tab.conditions.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("immunizations")}
                                 active={tabMedicalRecord.activeTabPanel === 'immunizations'}><FormattedMessage
                                id="medical.record.tab.immunizations.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("medications")}
                                 active={tabMedicalRecord.activeTabPanel === 'medications'}><FormattedMessage
                                id="medical.record.tab.medications.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("procedures")}
                                 active={tabMedicalRecord.activeTabPanel === 'procedures'}><FormattedMessage
                                id="medical.record.tab.procedures.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("habits")}
                                 active={tabMedicalRecord.activeTabPanel === 'habits'}><FormattedMessage
                                id="medical.record.tab.habits.label"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("pathologicalReports")}
                                 active={tabMedicalRecord.activeTabPanel === 'pathologicalReports'}><FormattedMessage
                                id="medical.record.tab.pathological.reports.label"/></Tab>
                        </Tabs>

                        <div className="tab-blocks">
                            <div className="tab-block">
                                {tabMedicalRecord.activeTabPanel === 'allergies' &&
                                <MedicalRecordDataItem dataItemType="ALLERGY"
                                                       onAdd={() => this.onAdd("ALLERGY")}/>}
                                {tabMedicalRecord.activeTabPanel === 'conditions' &&
                                <MedicalRecordDataItem dataItemType="CONDITION"
                                                       onAdd={() => this.onAdd("CONDITION")}/>}
                                {tabMedicalRecord.activeTabPanel === 'immunizations' &&
                                <MedicalRecordDataItem dataItemType="IMMUNIZATION"
                                                       onAdd={() => this.onAdd("IMMUNIZATION")}/>}
                                {tabMedicalRecord.activeTabPanel === 'medications' &&
                                <MedicalRecordDataItem dataItemType="MEDICATION"
                                                       onAdd={() => this.onAdd("MEDICATION")}/>}
                                {tabMedicalRecord.activeTabPanel === 'procedures' &&
                                <MedicalRecordDataItem dataItemType="PROCEDURE"
                                                       onAdd={() => this.onAdd("PROCEDURE")}/>}
                                {tabMedicalRecord.activeTabPanel === 'habits' &&
                                <MedicalRecordDataItem dataItemType="HABIT"
                                                       onAdd={() => this.onAdd("HABIT")}/>}
                                {tabMedicalRecord.activeTabPanel === 'pathologicalReports' &&
                                <PathologicalReports/>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-submit">
                        <Button
                            disabled={disabled} preloader={disabled === true}
                            onClick={() => this.props.onSaveMedicalRecord(patient, medicalRecord)}>
                            <FormattedMessage id="core.btn.save.label"/>
                        </Button>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth,
        tab: store.tab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchPatientMedicalRecord: (patient) => dispatch(fetchPatientMedicalRecord(patient)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("medicalRecord", tabPanelName)),
        onFormSimpleChangeField: (fieldName, fieldValue) => dispatch(formChangeFieldAction('medicalRecord', fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onSaveMedicalRecord: (patient, medicalRecord) => dispatch(saveMedicalRecord(patient, medicalRecord)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MedicalRecordContent);
