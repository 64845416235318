import React, {Component} from 'react';
import {connect} from "react-redux";
import ProfileAccountEmail from "./ProfileAccountEmail";
import ProfileAccountPassword from "./ProfileAccountPassword";
import {FormattedMessage} from "react-intl";
import {Checkbox} from "../../../../components/elements/checkbox";
import {disableUserSingle, enableUserSingle, fetchAccount} from "../../../services/userService";
import {formChangeFieldAction} from "../../../../actions/formAction";

class ProfileAccount extends Component {
    constructor(props) {
        super(props);
        this.onChangeEnabled = this.onChangeEnabled.bind(this);
    }

    componentDidMount() {
        const {profileUserId} = this.props;
        this.props.onFetchAccount(profileUserId, "profileAccountEmail");
    }

    onChangeEnabled(event) {
        const {profileUserId} = this.props;
        const target = event.target
        target.checked ?
            this.props.onEnableUser(profileUserId,
                () => this.props.onFormSimpleChange("profileAccountEmail", "isEnabled", true))
            : this.props.onDisableUser(profileUserId,
                () => this.props.onFormSimpleChange("profileAccountEmail", "isEnabled", false));
    }

    render() {
        const {form, profileUserId, auth: {user}} = this.props;
        const {profileAccountEmail} = form.data;
        const {isEnabled, isFillProfile} = profileAccountEmail || {};

        return (
            <div className="row content-row">
                {user.role == 'ADMIN' && user.id != profileUserId && isFillProfile &&
                <div className="col-12">
                    <div className="white-block">
                        <div className="row">
                            <div className="col">
                                <FormattedMessage id="core.active.label"/>
                            </div>
                            <div className="col-auto">
                                <Checkbox
                                    name='isEnabled'
                                    onChange={this.onChangeEnabled}
                                    isChecked={isEnabled || false}
                                    toggle={true} textSize={'small'} textColor={'gray'}></Checkbox>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="col-12">
                    <div className="white-block">
                        <ProfileAccountEmail profileUserId={profileUserId}/>
                    </div>
                </div>

                <div className="col-12">
                    <div className="white-block">
                        <ProfileAccountPassword profileUserId={profileUserId}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onEnableUser: (userId, callback) => dispatch(enableUserSingle(userId, callback)),
        onDisableUser: (userId, callback) => dispatch(disableUserSingle(userId, callback)),
        onFetchAccount: (userId, formName) => dispatch(fetchAccount(userId, formName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAccount);