import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {MainLogo} from "../../../../components/elements/logo";
import {Header} from "../../../../components/elements/header";
import {GridCol, GridRow} from "../../../../components/collections/grid";
import Password from "../../../systems/password";
import {Button, Buttons} from "../../../../components/elements/button";
import {BackToSign} from "../../../../components/elements/backTo";
import {formChangeField, formInit} from "../../../services/system/formService";
import {completeSignUpAsFamilyMember} from "../../../services/familyService";

class CompleteSignUpAsFamilyMember extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('completeSignUpAsFamilyMember', event);
    }

    render() {
        const {form, onCompleteSignUpAsFamilyMember, match: {params}} = this.props;
        const errors = form.errors.completeSignUpAsFamilyMember;
        const disabled = form.isSend.completeSignUpAsFamilyMember || false;
        const {token = ""} = params;
        return (
            <EmptyTag>
                <MainLogo/>
                <Header as="h2"><FormattedMessage id="complete.sign.up.family.member"/></Header>
                <p><FormattedMessage id="complete.sign.up.family.member.description"/></p>
                <GridRow width={300}>
                    <GridCol count="1">
                        <Password name="password"
                                  icon="password"
                                  placeholder={<FormattedMessage id="core.field.password.label"/>}
                                  error={(errors && errors.password) || null}
                                  disabled={disabled}
                                  onChange={this.onChange}/>
                    </GridCol>
                    <GridCol count="1">
                        <Password name="matchingPassword"
                                  icon="password"
                                  placeholder={<FormattedMessage id="core.field.password.repeat.label"/>}
                                  error={(errors && errors.matchingPassword) || null}
                                  disabled={disabled}
                                  onChange={this.onChange}/>
                    </GridCol>
                    <GridCol count="1">
                        <Buttons>
                            <Button disabled={disabled}
                                    preloader={disabled === true}
                                    onClick={() => onCompleteSignUpAsFamilyMember(token, form.data.completeSignUpAsFamilyMember)}>
                                <FormattedMessage id="core.btn.complete.sign.up.label"/></Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
                <GridRow width={300}>
                    <GridCol count="1">
                        <BackToSign to={"/users/sign-in"}/>
                    </GridCol>
                </GridRow>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    dispatch(formInit("completeSignUpAsFamilyMember", {}));
    return {
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onCompleteSignUpAsFamilyMember: (token, data) => dispatch(completeSignUpAsFamilyMember(token, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteSignUpAsFamilyMember);