import React, {Component} from 'react';
import {connect} from "react-redux";
import {checkMediaDevices, createEmergencyCall} from "../../services/callService";
import {Button} from "../../../components/elements/button";
import {withRouter} from 'react-router-dom';

import {toast} from 'react-toastify';
import {noticeShowAction} from "../../../actions/noticeAction";
import {FormattedMessage} from "react-intl";

class DoctorCall extends Component {
    constructor(props) {
        super(props);
        this.onCall = this.onCall.bind(this);
    }

    onCall(toastId, patient) {
        toast.dismiss(toastId);
        checkMediaDevices(() => this.props.onCreateEmergencyCall(patient),
            () => this.props.onNoticeShow(
                <FormattedMessage id="notice.call.title" />,
                <FormattedMessage id="core.media.device.not.available" />));
    }

    render() {
        const {data, toastId} = this.props;
        const {lastName = "", firstName = "", patientId} = data;
        return (
            <div className="n-parent">
                <div className="notification-item n-child">
                    <div className="notify-container">
                        <div className="row notify-row align-items-center">
                            <div className="col notify-row__text">
                                <h2 className="color-red"><FormattedMessage id="modal.doctor.call.header"/></h2>
                                <div className="middle-text">{firstName} {lastName}</div>
                                <div className="small-text"></div>
                            </div>
                            <div className="col-auto notify-row__controls">
                                <Button icon="phone-white" circle={true} pulse={true}
                                        onClick={() => this.onCall(toastId, {id: patientId})}></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCreateEmergencyCall: (patient) => dispatch(createEmergencyCall(patient, ownProps)),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DoctorCall));
