import {
    LIST_FETCH,
    LIST_SORT,
    LIST_FILTER,
    LIST_FILTER_FIELD,
    LIST_INIT,
    LIST_INIT_DATA, LIST_IS_LOAD_DATA
} from "../constants/actionTypes";
import _ from "lodash";

const initialState = {
    data: {},
    pagination: {},
    sorts: {},
    filters: {},
    isLoading: {}
};

const listReducer = (state = initialState, action) => {
    switch (action.type) {
        case LIST_IS_LOAD_DATA:
            return {...state, isLoading: {...state.isLoading, [action.listName]: action.isLoading}};
        case LIST_INIT :
            const dataInit = {...state.data, [action.listName]: []};
            const paginationInit = {...state.pagination, [action.listName]: {}};
            const filtersInit = {...state.filters, [action.listName]: {}};
            const isLoadingInit = {...state.isLoading, [action.formName]: false};
            return {...state, data: dataInit, pagination: paginationInit, filters: filtersInit, isLoading: isLoadingInit};
        case LIST_INIT_DATA:
            return {...state, data: {...state.data, [action.listName]: action.data}};
        case LIST_FETCH:
            const data = {...state.data, [action.listName]: action.data};
            const pagination = {...state.pagination, [action.listName]: action.pagination};
            return {...state, data: data, pagination: pagination};
        case LIST_SORT:
            return {...state, sorts: {...state.sorts, [action.listName]: action.sorts}};
        case LIST_FILTER:
            return {...state, filters: {...state.filters, [action.listName]: action.filters}};
        case LIST_FILTER_FIELD:
            let singleFilter = {...state.filters[action.listName]};
            _.set(singleFilter, action.fieldName, action.fieldValue);
            if (action.fieldValue == null) _.unset(singleFilter, action.fieldName);
            return {...state, filters: {...state.filters, [action.listName]: singleFilter}};
        default:
            return state;
    }
};

export default listReducer;