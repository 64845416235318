import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../../components/modules/modal";
import {modalHide, modalShow} from "../../../../services/system/modalService";
import {withRouter} from 'react-router-dom';
import {Header} from "../../../../../components/elements/header";

import {FormattedMessage} from "react-intl";
import {Logo} from "../../../../../components/elements/logo";
import moment from "moment";

class PaymentReceipt extends Component {
    render() {
        const {form: {data: formData}} = this.props;
        const {paymentReceipt = {}} = formData;
        const {booking} = paymentReceipt || {};
        const {doctor = {}, timeOfReceipt, payment = {}, completedAt} = booking;
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('paymentReceipt')} width={600}>
                <Header as="h2"><FormattedMessage id="modal.payment.receipt.header"/></Header>

                <div className="receipt-block">
                    <table className="receipt-table" width="100%" cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                        <tr>
                            <td>&nbsp;</td>
                            <td className="receipt-container">
                                <div className="receipt-content">
                                    <table className="receipt-main" cellSpacing="0" cellPadding="0" align="center">
                                        <tbody>
                                        <tr>
                                            <td className="receipt-header">
                                                <table className="logo-table" cellSpacing="0" cellPadding="0"
                                                       border="0">
                                                    <tbody>
                                                    <tr>
                                                        <td className="align-center">
                                                            <Logo/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <div className="blue-table-wrapper">
                                                    <table className="blue-table" cellSpacing="0" cellPadding="0"
                                                           border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td className="m-pad-left td-receipt-number td-bg-blue nowrap bold">
                                                                <FormattedMessage
                                                                    id="modal.payment.receipt.field.number.receipt.label"
                                                                    values={{receiptNumber: booking.id}}/>
                                                            </td>
                                                            <td className="td-arrow">
                                                                <img alt={""} src="/img/receipt/arrow-blue-right.png"/>
                                                            </td>
                                                            <td className="align-right">
                                                                <FormattedMessage id="code.field.date.label"/>
                                                                : &nbsp;
                                                                <span
                                                                    className="bold">{(completedAt && moment(completedAt).format("ll")) || '?'}</span>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="receipt-body">
                                                <table className="list-table" cellSpacing="0" cellPadding="0"
                                                       border="0">
                                                    <tbody>
                                                    <tr className="tr-list-head">
                                                        <td className="td-bg-gray"><FormattedMessage
                                                            id="code.field.number.label"/></td>
                                                        <td className="td-bg-gray"><FormattedMessage
                                                            id="code.field.service.label"/></td>
                                                        <td className="td-bg-gray"><FormattedMessage
                                                            id="code.field.cost.label"/></td>
                                                    </tr>
                                                    <tr className="tr-list-row">
                                                        <td className="td-number"> 1</td>
                                                        <td>
                                                            <FormattedMessage id="payment.receipt.booking.service.name"
                                                                              values={{
                                                                                  specialization: joinSpecializations || "",
                                                                                  firstName: (doctor && doctor.firstName) || "",
                                                                                  lastName: (doctor && doctor.lastName) || "",
                                                                                  timeOfReceipt: moment(timeOfReceipt).format("lll")
                                                                              }}/>
                                                        </td>
                                                        <td className="td-price">
                                                            {payment.currency}
                                                            {payment.amount && parseFloat(payment.amount).toFixed(2)}
                                                        </td>
                                                    </tr>
                                                    <tr className="tr-list-bottom">
                                                        <td colSpan="3">&nbsp;  </td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <div className="blue-table-wrapper">
                                                    <table className="blue-table" cellSpacing="0" cellPadding="0"
                                                           border="0">
                                                        <tbody>
                                                        <tr>
                                                            <td>&nbsp;

                                                            </td>
                                                            <td className="td-arrow">
                                                                <img alt="" src="/img/receipt/arrow-blue-left.png"/>
                                                            </td>
                                                            <td className="td-receipt-total-text td-bg-blue bold">
                                                                <FormattedMessage
                                                                    id="code.field.total.label"/>:
                                                            </td>
                                                            <td className="td-receipt-total-price td-bg-blue bold">
                                                                {payment.currency} {payment.amount && parseFloat(payment.amount).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div className="separator sep-border-bottom">
                                                </div>
                                                <div className="separator">
                                                </div>

                                                <table className="info-table" cellSpacing="0" cellPadding="0"
                                                       border="0">
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <FormattedMessage
                                                                id="code.field.type.of.payment.label"/>:
                                                        </td>
                                                        <td className="align-right bold">
                                                            {payment.paymentMethod.name}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <FormattedMessage
                                                                id="code.field.date.label"/>:
                                                        </td>
                                                        <td className="align-right bold">
                                                            <div className="nowrap">
                                                                {(payment.paymentCompletedAt &&
                                                                moment(payment.paymentCompletedAt).format("ll")) || <FormattedMessage
                                                                    id="core.none.label"/>}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <FormattedMessage
                                                                id="code.field.payment.transaction.number.label"/>:
                                                        </td>
                                                        <td className="align-right bold">
                                                            {payment.paymentTransactionNumber}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        modal: store.modal,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalShow: (name, data) => dispatch(modalShow(name, data)),
        onModalHide: (name) => dispatch(modalHide(name)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentReceipt));
