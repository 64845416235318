import React, {Component} from 'react';
import {connect} from "react-redux";
import {listFilterFieldAction, listSortAction} from "../../../../actions/listAction";
import {Button} from "../../../../components/elements/button";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import {detachMember, detachRejectMember, fetchFamilyMembers} from "../../../services/familyService";

class FamilyDetachRequests extends Component {
    constructor(props) {
        super(props);
        this.onDetach = this.onDetach.bind(this);
        this.onDetachReject = this.onDetachReject.bind(this);
    }

    componentDidMount() {
        this.props.onListFilterField("isDetachRequest", true);
        this.props.onFetchFamilyMembers();
    }

    onDetach(memberId) {
        this.props.onDetachMember(memberId, () => this.props.onFetchFamilyMembers());
    }

    onDetachReject(memberId) {
        this.props.onDetachRejectMember(memberId, () => this.props.onFetchFamilyMembers());
    }

    render() {
        const {list: {data}} = this.props;
        const {familyDetachRequests = []} = data;
        const {0: firstFamilyDetachRequests = {}} = familyDetachRequests;
        const {child, memberType, id} = firstFamilyDetachRequests || {};
        if (familyDetachRequests.length == 0) return "";
        return (
            <div className="col-12">
                <div className="white-block padding-0">
                    <div className="row form-elements form-padding-20-30 display-table">
                        <div className="col">
                            <div className="color-red margin-bottom-10">
                                <FormattedMessage id="detach.profile.request"/>
                            </div>
                            <div>
                                <FormattedMessage id="detach.profile.request.description"
                                                  values={{
                                                      memberTypeName: memberType.name,
                                                      firstName: child.firstName,
                                                      lastName: child.lastName
                                                  }}/>
                            </div>
                        </div>

                        <div className="col-auto">
                            <div className="person-col-controls small-controls">
                                <Button
                                    onClick={() => this.props.onConfirm({
                                        text: <FormattedMessage id="confirm.detach"/>,
                                        onProceed: (() => this.onDetach(id))
                                    })}><FormattedMessage id="detach"/></Button>
                                <Button color="white-red"
                                        onClick={() => this.props.onConfirm({
                                            text: <FormattedMessage id="confirm.detach.reject"/>,
                                            onProceed: (() => this.onDetachReject(id))
                                        })}><FormattedMessage id="core.btn.reject.label"/></Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDetachMember: (id, callback) => dispatch(detachMember(id, callback)),
        onDetachRejectMember: (id, callback) => dispatch(detachRejectMember(id, callback)),
        onFetchFamilyMembers: () => dispatch(fetchFamilyMembers("familyDetachRequests")),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("familyDetachRequests", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("familyDetachRequests", sorts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FamilyDetachRequests);