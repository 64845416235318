import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {GridCol} from "../../../../../components/collections/grid";
import {AutocompleteAsync} from "../../../../systems/autocomplete";
import {listFilterFieldAction} from "../../../../../actions/listAction";
import {fetchSpecializations} from "../../../../services/bookingService";
import {formSimpleChange} from "../../../../services/system/formService";
import {fetchDoctors} from "../../../../services/doctorService";

class Step1 extends Component {
    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onSearchSpecialization = this.onSearchSpecialization.bind(this);
        this.onSelectedSpecialization = this.onSelectedSpecialization.bind(this);
        this.onChangeDoctor = this.onChangeDoctor.bind(this);
        this.onSearchDoctor = this.onSearchDoctor.bind(this);
    }

    onDelete() {
        this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "specialization", null);
    }

    onSelectedSpecialization(event, value, reason) {
        this.props.onFormSimpleChangeField("advanceParamsCreateBooking", "specialization", value);
        this.props.onListFilterField("doctors", "specializations", (value && value.id) || null);
        this.props.onListFilterField("doctors", "searchText", null);
        this.props.onFormSimpleChangeField("createBooking", "doctor", null);
        this.props.onFetchDoctors(1);
    }

    onSearchSpecialization(event, query, reason) {
        this.props.onListFilterField("specializations", "searchText", query);
        this.props.onFetchSpecializations(1);
    }

    onOpen(event) {
        this.props.onListFilterField("specializations", "searchText", "");
        this.props.onFetchSpecializations(1);
    }

    onChangeDoctor(event, value) {
        this.props.onFormSimpleChangeField("createBooking", "doctor", value);
    }

    onSearchDoctor(event, query, reason) {
        this.props.onListFilterField("doctors", "searchText", query);
        this.props.onFetchDoctors(1);
    }

    render() {
        const {form: {data: formData, errors: formErrors}, list: {data: listData}, globalDisabled = false} = this.props;
        const errors = formErrors.createBooking;
        const {doctors = [], specializations = []} = listData;
        const {createBooking = {}, advanceParamsCreateBooking = {}} = formData || {};
        const {specialization = null} = advanceParamsCreateBooking;
        return (
            <EmptyTag>
                <GridCol count='1'>
                    <AutocompleteAsync onInputChange={this.onSearchSpecialization}
                                       getOptionLabel={option => option.name || ''}
                                       onChange={(event, value, reason) => this.onSelectedSpecialization(event, value, reason)}
                                       onOpen={this.onOpen}
                                       disabled={globalDisabled}
                                       onDelete={() => this.onDelete()}
                                       isCanDelete={specialization}
                                       value={specialization || null}
                                       placeholder={<FormattedMessage id="core.specialization.label"/>}
                                       placeholderInside={true}
                                       placeholderFixed={true}
                                       disableClearable={true}
                                       error={null}
                                       options={specializations}/>
                </GridCol>
                <GridCol count='1'>
                    <AutocompleteAsync onInputChange={this.onSearchDoctor}
                                       getOptionLabel={option => option.firstName + ' ' + option.lastName}
                                       onChange={this.onChangeDoctor}
                                       disabled={globalDisabled}
                                       value={createBooking.doctor || null}
                                       placeholder={<FormattedMessage id="core.hcsp.or.doctor.label"/>}
                                       placeholderFixed={true}
                                       error={(errors && errors.doctor) || null}
                                       options={doctors}/>
                </GridCol>

            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchDoctors: (pageNumber) => dispatch(fetchDoctors(pageNumber, "doctors")),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onFetchSpecializations: (pageNumber, listName) => dispatch(fetchSpecializations(pageNumber, listName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step1);
