import React from "react";
import axios from 'axios';
import store from '../store';
import {noticeShowAction} from "../actions/noticeAction";

import {FormattedMessage} from "react-intl";

import {preloaderPageInitAction} from "../actions/preloaderAction";
import {getRequestsToRefresh, refreshToken} from "../containers/services/userService";

export const getCalendarLocale = () => {
    return store.getState().language.locale || "";
}

export const privateApi = axios.create({
    baseURL: window._env_.BACKEND_URL
});

export const billingApi = axios.create({
    baseURL: window._env_.BILLING_URL
});

export const publicBillingApi = axios.create({
    baseURL: window._env_.BILLING_URL
});

export const billingBlobApi = axios.create({
    baseURL: window._env_.BILLING_URL
});

export const privateBlobApi = axios.create({
    baseURL: window._env_.BACKEND_URL
});

export const publicApi = axios.create({
    baseURL: window._env_.BACKEND_URL
});

export const callApi = axios.create({
    baseURL: window._env_.VCF_URL,
});

export const vcfApi = axios.create({
    baseURL: window._env_.VCF_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
});

export const vcfAttachApi = axios.create({
    baseURL: window._env_.VCF_URL,
});

export const signAxios = axios.create({
    baseURL: window._env_.BACKEND_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
});

signAxios.interceptors.request.use(config => onPublicRequestFulfilled(config), error => Promise.reject(error));
publicApi.interceptors.request.use(config => onPublicRequestFulfilled(config), error => Promise.reject(error));
publicBillingApi.interceptors.request.use(config => onPublicRequestFulfilled(config), error => Promise.reject(error));
vcfAttachApi.interceptors.request.use(config => onRequestFulfilled(config), error => Promise.reject(error));
vcfApi.interceptors.request.use(config => onRequestFulfilled(config), error => Promise.reject(error));
callApi.interceptors.request.use(config => onRequestFulfilled(config), error => Promise.reject(error));
privateApi.interceptors.request.use(config => onRequestFulfilled(config), error => Promise.reject(error));
privateBlobApi.interceptors.request.use(config => onRequestFulfilled(config), error => Promise.reject(error));
billingApi.interceptors.request.use(config => onRequestFulfilled(config), error => Promise.reject(error));
billingBlobApi.interceptors.request.use(config => onRequestFulfilled(config), error => Promise.reject(error));

vcfAttachApi.interceptors.response.use(response => response, error => onResponseRejected(error));
vcfApi.interceptors.response.use(response => response, error => onResponseRejected(error));
callApi.interceptors.response.use(response => response, error => onResponseRejected(error));
privateApi.interceptors.response.use(response => response, error => onResponseRejected(error));
billingApi.interceptors.response.use(response => response, error => onResponseRejected(error));

privateBlobApi.interceptors.response.use(response => response, error => {
    const {response} = error;
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.onload = () => {
            const _data = JSON.parse(fileReader.result);
            const _response = {...response, data: _data};
            const _error = {...error, response: _response};
            resolve(onResponseRejected(_error));
        };
        fileReader.readAsText(response.data);
    });
});

billingBlobApi.interceptors.response.use(response => response, error => {
    const {response} = error;
    const fileReader = new FileReader();

    return new Promise((resolve, reject) => {
        fileReader.onload = () => {
            const _data = JSON.parse(fileReader.result);
            const _response = {...response, data: _data};
            const _error = {...error, response: _response};
            resolve(onResponseRejected(_error));
        };
        fileReader.readAsText(response.data);
    });
});

publicApi.interceptors.response.use(response => response, error => {
    const status = error.response.status;
    const {message} = error.response.data;
    switch (status) {
        case 500 :
            if (message !== 'authentication.invalid.refresh.token') {
                store.dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, message));
            } else {
                store.dispatch(preloaderPageInitAction());
            }
            break;
        default:
    }
    return Promise.reject(error);
});

publicBillingApi.interceptors.response.use(response => response, error => {
    const status = error.response.status;
    const {message} = error.response.data;
    switch (status) {
        case 500 :
            if (message !== 'authentication.invalid.refresh.token') {
                store.dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, message));
            } else {
                store.dispatch(preloaderPageInitAction());
            }
            break;
        default:
    }
    return Promise.reject(error);
});

const onRequestFulfilled = (config) => {
    const accessToken = localStorage.getItem('accessToken') || null;
    config.headers['Authorization'] = `Bearer ${accessToken}`;
    config.headers["Accept-Language"] = store.getState().language.locale || "";
    return config;
};

const onPublicRequestFulfilled = (config) => {
    config.headers["Accept-Language"] = store.getState().language.locale || "";
    return config;
};

const onResponseRejected = (error) => {
    const {config, response} = error;
    const status = response.status;
    let {message, errorCode} = response.data;

    switch (status) {
        case 401 :
            if (errorCode === "authentication.entry.point") {
                refreshToken();
                return new Promise((resolve, reject) => {
                    getRequestsToRefresh().push((token) => {
                        if (token) {
                            try {
                                config.headers.Authorization = 'Bearer ' + token
                                resolve(axios(config))
                            } catch (e) {
                                console.log("error: " + e);
                            }
                        }
                    })
                });
            } else {
                store.dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, message));
            }
            break;
        case 403 :
            store.dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, message));
            break;
        case 500 :
            store.dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, message));
            break;
        default:
    }

    return Promise.reject(error);
};
