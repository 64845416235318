import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {saveMedication} from "../../../../services/consultationService";
import {formChangeField, formSimpleChange} from "../../../../services/system/formService";
import {Input} from "../../../../../components/elements/input";
import {Option, Select} from "../../../../../components/elements/select";
import {Button, Buttons} from "../../../../../components/elements/button";
import {Modal} from "../../../../../components/modules/modal";
import {modalHide} from "../../../../services/system/modalService";
import {
    getDuration,
    getDurations,
    getMedicationForm,
    getMedicationForms,
    stringToInt
} from "../../../../../utils/app";
import {formChangeFieldAction} from "../../../../../actions/formAction";
import {injectIntl, FormattedMessage} from "react-intl";


class NewMedication extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectDuration = this.onSelectDuration.bind(this);
        this.onSelectMedicationForm = this.onSelectMedicationForm.bind(this);
        this.onChangeInteger = this.onChangeInteger.bind(this);
    }

    onChange(event) {
        this.props.onFormChangeField('medication', event);
    }

    onSelectDuration(id) {
        this.props.onChangeDropdown("medication", "duration", id);
    }

    onSelectMedicationForm(id) {
        this.props.onChangeDropdown("medication", "form", id);
    }

    onChangeInteger(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChange("medication", target.name, stringToInt(value));
    }

    render() {
        const {form: {data, errors: formErrors, isSend: formIsSend}} = this.props;
        const errors = formErrors.medication;
        const disabled = formIsSend.medication || false;
        const {booking: {consultation = {}}, medication = {}} = data;

        return (
            <Modal close={true} onClose={() => this.props.onModalHide()}>
                <GridRow>
                    <GridCol count="1">
                        <Input name="prescribedMedication"
                               placeholder={<FormattedMessage id="medication.name"/>}
                               error={(errors && errors.prescribedMedication) || null}
                               disabled={disabled}
                               onChange={this.onChange}
                               value={medication.prescribedMedication || ""}/>
                    </GridCol>
                    <GridCol count="1">
                        <FormattedMessage id={getMedicationForm(medication.form) || 'undefined'}>
                            {optionNameSelected =>
                                <Select label={<FormattedMessage id="medication.form"/>}
                                        disabled={disabled}
                                        error={(errors && errors['form']) || null}
                                        optionNameSelected={optionNameSelected}>
                                    {
                                        getMedicationForms().map(key =>
                                            <Option
                                                active={medication.form === key}
                                                key={key}
                                                onClick={() => this.onSelectMedicationForm(key)}>
                                                {<FormattedMessage id={getMedicationForm(key)}/>}
                                            </Option>
                                        )
                                    }
                                </Select>}
                        </FormattedMessage>
                    </GridCol>
                    <GridCol count="1">
                        <Input name="directionsForUse"
                               placeholder={<FormattedMessage id="medication.directions.use"/>}
                               error={(errors && errors.directionsForUse) || null}
                               disabled={disabled}
                               onChange={this.onChange}
                               value={medication.directionsForUse || ""}/>
                    </GridCol>
                    <GridCol count="2">
                        <FormattedMessage id={getDuration(medication.duration) || 'undefined'}>
                            {optionNameSelected =>
                                <Select label={<FormattedMessage id="medication.duration"/>}
                                        disabled={disabled}
                                        error={(errors && errors['duration']) || null}
                                        optionNameSelected={optionNameSelected}>
                                    {
                                        getDurations().map(key =>
                                            <Option
                                                active={medication.duration === key}
                                                key={key}
                                                onClick={() => this.onSelectDuration(key)}>
                                                {<FormattedMessage id={getDuration(key)}/>}
                                            </Option>
                                        )
                                    }
                                </Select>}
                        </FormattedMessage>
                    </GridCol>
                    <GridCol count="2">
                        <Input name="repeatPrescriptions"
                               placeholder={<FormattedMessage id="medication.repeat"/>}
                               error={(errors && errors.repeatPrescriptions) || null}
                               disabled={disabled}
                               onBlur={this.onChangeInteger}
                               onChange={this.onChangeInteger}
                               value={medication.repeatPrescriptions || ""}/>
                    </GridCol>
                    <GridCol count="1">
                        <Buttons>
                            <Button disabled={disabled}
                                    preloader={disabled === true}
                                    onClick={() => this.props.onSaveMedication(consultation, medication)}><FormattedMessage
                                id="core.btn.save.label"/></Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: () => dispatch(modalHide('medication')),
        onSaveMedication: (consultation, medication) => dispatch(saveMedication(consultation, medication)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
    };
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(NewMedication));
