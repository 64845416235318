import {FORM_CHANGE_FIELD, FORM_FIELD_ERRORS, FORM_INIT, FORM_IS_SEND} from "../constants/actionTypes";

export const formInitAction = (formName, data) => ({
    type: FORM_INIT,
    formName: formName,
    data: data
});

export const formIsSendAction = (formName, isSend) => ({
    type: FORM_IS_SEND,
    formName: formName,
    isSend: isSend
});

export const formChangeFieldAction = (formName, fieldName, fieldValue, indexArrayValue = null, isMultiple = false) => ({
    type: FORM_CHANGE_FIELD,
    formName: formName,
    fieldName: fieldName,
    fieldValue: fieldValue,
    indexArrayValue: indexArrayValue,
    isMultiple: isMultiple
});

export const formFieldErrorsAction = (formName, errors) => ({
    type: FORM_FIELD_ERRORS,
    formName: formName,
    errors: errors
});