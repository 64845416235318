import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {formInitAction} from "../../../actions/formAction";
import {listInitAction} from "../../../actions/listAction";
import {Button} from "../../../components/elements/button";
import {getMyOpenRooms} from "../../services/chatService";
import {toast} from "react-toastify";
import Avatar from "../avatar";

class ChatNotificationNewMessage extends Component {
    constructor(props) {
        super(props);
        this.onReply = this.onReply.bind(this);
        this.getRoomById = this.getRoomById.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    getRoomById(roomId) {
        const {list: {data: listData}} = this.props;
        const {myOpenChatRooms = []} = listData;
        const indexRoom = myOpenChatRooms.findIndex(i => i.id == roomId);
        return indexRoom !== -1 ? myOpenChatRooms[indexRoom] : null;
    }

    onReply() {
        const {roomId, toastId} = this.props;
        const room = this.getRoomById(roomId)
        if (room) {
            this.props.onInitForm("widgetChosenChatRoom", room);
        } else {
            this.props.onGetMyOpenRooms((response) => {
                const room = this.getRoomById(roomId)
                this.props.onInitForm("widgetChosenChatRoom", room);
            });
        }
        toast.dismiss(toastId);
    }

    render() {
        const {user, text} = this.props;
        return (
            <div className="n-parent">
                <div className="notification-item n-child">
                    <div className="notify-container">
                        <div className="row notify-row align-items-center">
                            <div className="col-auto notify-row__image">
                                    <span className="avatar-item">
                                        <Avatar userId={user.id}/>
                                    </span>
                            </div>
                            <div className="col notify-row__text">
                                <div className="middle-text text-ellipsis">{user.first_name} {user.last_name}</div>
                                <div className="small-text text-ellipsis">{text}</div>
                            </div>
                            <div className="col-auto notify-row__controls">
                                <Button size={'small'} onClick={() => this.onReply()}>
                                    <FormattedMessage id="to.answer"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onGetMyOpenRooms: (callback) => dispatch(getMyOpenRooms("myOpenChatRooms", callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatNotificationNewMessage);