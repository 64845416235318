import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../../components/elements/header";
import {WhiteBlock} from "../../../../../components/elements/paragraph";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {ButtonRemove, ButtonEdit, Button, Buttons} from "../../../../../components/elements/button";
import {Icon, IconItem} from "../../../../../components/elements/icon";
import {modalShow} from "../../../../services/system/modalService";
import {getMedicationForm, isModal} from "../../../../../utils/app";
import NewMedication from "./NewMedication";
import NewMedicalTest from "./NewMedicalTest";
import ChoosePharmacy from "./ChoosePharmacy";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {
    medicationDurationFormat,
    prescribeMedication,
    removeMedicalTest,
    removeMedication
} from "../../../../services/consultationService";
import {FormattedMessage} from "react-intl";

class BookingDetailPrescription extends Component {
    constructor(props) {
        super(props);
        this.onChangeMedication = this.onChangeMedication.bind(this);
        this.onSelectDuration = this.onSelectDuration.bind(this);
        this.onSelectMedicationForm = this.onSelectMedicationForm.bind(this);
    }

    onChangeMedication(event) {
        this.props.onFormChangeField('medication', event);
    }

    onSelectDuration(id) {
        this.props.onChangeDropdown("medication", "duration", id);
    }

    onSelectMedicationForm(id) {
        this.props.onChangeDropdown("medication", "form", id);
    }

    render() {
        const {form: {data}, modal, auth: {user}} = this.props;
        const {booking: {consultation = {}, status, doctor = null}} = data;
        const {medications = [], medicalTests = [], pharmacy = {}, isPrescribe} = consultation;
        const isBookingDoctor = doctor && user && doctor.id == user.id;
        const isCanModifyPrescription = !isPrescribe && status == 'APPROVED' && user.isEnabled && isBookingDoctor;
        return (
            <div className="row content-row">
                <GridCol count="2">
                    <WhiteBlock>
                        <GridRow>
                            <div className="col">
                                <Header as="h3"><FormattedMessage id="page.column.prescriptions.header"/></Header>
                            </div>
                            <div className="col-auto">
                                {isPrescribe && <div className="color-green"><FormattedMessage
                                    id="booking.prescriptions.prescribe.is.true"/></div>}
                            </div>
                            {medications && medications.length > 0 &&
                            <GridCol count="1">
                                <div className="row form-elements form-bottom-bordered form-bg-light">
                                    {medications.map(medication => {
                                        const {prescribedMedication, repeatPrescriptions, duration, directionsForUse} = medication;
                                        return (
                                            <GridCol count="1" key={medication.id}>
                                                <Header as="h4">{prescribedMedication}</Header>
                                                <div
                                                    className="color-gray">{<FormattedMessage id={getMedicationForm(medication.form)}/>}, {directionsForUse}</div>
                                                <div className="color-blue">
                                                    {<FormattedMessage id={medicationDurationFormat(repeatPrescriptions, duration)}
                                                                        values={{repeat: repeatPrescriptions}}/>}

                                                </div>

                                                {isCanModifyPrescription &&
                                                <div className="controls-top-right">
                                                    <ButtonEdit
                                                        onClick={() => this.props.onEditMedication(medication)}/>
                                                    <ButtonRemove onClick={() => this.props.onConfirm({
                                                        text: <FormattedMessage id="booking.confirm.remove.medication"
                                                                                values={{medicationName: prescribedMedication}}/>,
                                                        onProceed: (() => this.props.onRemoveMedication(consultation, medication))
                                                    })}/>
                                                </div>}
                                            </GridCol>
                                        );
                                    })}

                                </div>
                            </GridCol>
                            }

                            {medications.length === 0 &&
                            <GridCol count="1"><FormattedMessage id="booking.medications.list.empty.text"/></GridCol>}

                            {isCanModifyPrescription &&
                            <GridCol count="1">
                                <a href="#" onClick={() => this.props.onNewMedication()} className="link-icon">
                                    <IconItem name="plus-bordered"><FormattedMessage
                                        id="core.btn.add.label"/></IconItem>
                                </a>
                            </GridCol>}

                            <GridCol count="1">
                                <div className="row form-elements form-bottom-bordered form-bg-light">
                                    <GridCol count="1">
                                        <Header as="h3"><FormattedMessage id="page.column.pharmacies.header"/></Header>

                                        {pharmacy &&
                                        <EmptyTag>
                                            <Header as={"h4"}>{pharmacy.name}</Header>
                                            <div className="color-gray">{pharmacy.address}</div>
                                            {isCanModifyPrescription &&
                                            <div className="controls-top-right">
                                                <a href="#" onClick={() => this.props.onChoosePharmacy({})}
                                                   className="link-icon control-top-right">
                                                    <Icon name="edit-green"/>
                                                </a>
                                            </div>}
                                        </EmptyTag>}

                                        {!pharmacy && isCanModifyPrescription &&
                                        <div className="controls-top-right">
                                            <a href="#" onClick={() => this.props.onChoosePharmacy({})}
                                               className="link-icon color-gray">
                                                <IconItem name="placemark-red"><FormattedMessage
                                                    id="pharmacies.not.choose.text"/></IconItem>
                                            </a>
                                        </div>}
                                    </GridCol>

                                </div>

                            </GridCol>

                            <GridCol count="1">
                                {isCanModifyPrescription && <Buttons>
                                    <Button
                                        disabled={!pharmacy || medications.length == 0}
                                        onClick={() => this.props.onConfirm({
                                            text: <FormattedMessage id="booking.confirm.send.prescription"
                                                                    values={{pharmacyName: pharmacy.name}}/>,
                                            onProceed: (() => this.props.onPrescribeMedication(consultation))
                                        })}><FormattedMessage id="core.send-to-pharmacy"/></Button></Buttons>}
                            </GridCol>
                        </GridRow>
                    </WhiteBlock>
                </GridCol>
                <GridCol count="2">
                    <WhiteBlock>
                        <GridRow>
                            <GridCol count="1">
                                <Header as="h3"><FormattedMessage id="page.column.medical.test.header"/></Header>
                            </GridCol>
                            {medicalTests && medicalTests.length > 0 &&
                            <GridCol count="1">
                                <div className="row form-elements form-bottom-bordered form-bg-light">
                                    {medicalTests.map(medicalTest => {
                                        return (
                                            <GridCol count="1" key={medicalTest.id}>
                                                <div>{medicalTest.name}</div>
                                                {status == 'APPROVED' && user.isEnabled && isBookingDoctor &&
                                                <div className="controls-top-right">
                                                    <ButtonEdit
                                                        onClick={() => this.props.onEditMedicalTest(medicalTest)}/>
                                                    <ButtonRemove onClick={() => this.props.onConfirm({
                                                        text: <FormattedMessage id="confirm.remove.medical.test.text"
                                                                                values={{medicalTestName: medicalTest.name}}/>,
                                                        onProceed: (() => this.props.onRemoveMedicalTest(consultation, medicalTest))
                                                    })}/>
                                                </div>}
                                            </GridCol>
                                        );
                                    })}
                                </div>
                            </GridCol>
                            }

                            {medicalTests && medicalTests.length == 0 &&
                            <GridCol count="1"><FormattedMessage id="booking.medical.tests.list.empty.text"/></GridCol>}

                            {status == 'APPROVED' && user.isEnabled && isBookingDoctor &&
                            <GridCol count="1">
                                <a href="#" onClick={() => this.props.onNewMedicalTest()} className="link-icon">
                                    <IconItem name="plus-bordered"><FormattedMessage
                                        id="core.btn.add.label"/></IconItem>
                                </a>
                            </GridCol>}
                        </GridRow>
                    </WhiteBlock>
                </GridCol>
                {isModal(modal, 'medication') && user.isEnabled && isBookingDoctor && <NewMedication/>}
                {isModal(modal, 'medicalTest') && user.isEnabled && isBookingDoctor && <NewMedicalTest/>}
                {isModal(modal, 'choosePharmacy') && user.isEnabled && isBookingDoctor && <ChoosePharmacy/>}
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onPrescribeMedication: (consultation) => dispatch(prescribeMedication(consultation)),
        onRemoveMedication: (consultation, medication) => dispatch(removeMedication(consultation, medication)),
        onRemoveMedicalTest: (consultation, medicalTest) => dispatch(removeMedicalTest(consultation, medicalTest)),
        onNewMedication: () => dispatch(modalShow("medication", {})),
        onEditMedication: (data) => dispatch(modalShow("medication", data)),
        onNewMedicalTest: () => dispatch(modalShow("medicalTest", {})),
        onEditMedicalTest: (data) => dispatch(modalShow("medicalTest", data)),
        onChoosePharmacy: (data) => dispatch(modalShow("choosePharmacy", data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailPrescription);
