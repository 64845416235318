import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../../components/modules/modal";
import {Header} from "../../../../../components/elements/header";
import {modalHide} from "../../../../services/system/modalService";

import {compose, withProps} from "recompose";
import {withScriptjs, withGoogleMap, GoogleMap, Marker} from "react-google-maps"

const PharmacyMap = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=" + window._env_.GOOGLE_API_KEY + "&v=3.exp&libraries=geometry,drawing,places&language=en",
        loadingElement: <div style={{height: `100%`}}/>,
        containerElement: <div style={{height: `400px`}}/>,
        mapElement: <div style={{height: `100%`}}/>,
    }),
    withScriptjs,
    withGoogleMap
)(props => {
        return (
            <GoogleMap
                defaultZoom={12}
                defaultCenter={{lat: props.lat, lng: props.lng}}>
                <Marker position={{lat: props.lat, lng: props.lng}}/>
            </GoogleMap>
        )
    }
);

class PharmacyInfo extends Component {
    render() {
        const {onModalHide, form: {data}} = this.props;
        const {pharmacyInfo = {}} = data;

        return (
            <Modal close={true} onClose={() => onModalHide('pharmacyInfo')} width={1000}>
                <Header as="h2">{pharmacyInfo.name}</Header>

                <div className="row form-elements">
                    {(pharmacyInfo.lat && pharmacyInfo.lng) &&
                    <div className="col-12">
                        <PharmacyMap lat={parseFloat(pharmacyInfo.lat)} lng={parseFloat(pharmacyInfo.lng)}/>
                    </div>}
                    <div className="col-md-4">
                        <div className="icon-item">
                            <i className="icon icon--phone-2"></i>
                            <span className="icon-item__text">{pharmacyInfo.phone}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="icon-item">
                            <i className="icon icon--address"></i>
                            <span className="icon-item__text">{pharmacyInfo.address}</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="icon-item">
                            <i className="icon icon--clock"></i>
                            <span
                                className="icon-item__text">{pharmacyInfo.startWorkingHour} - {pharmacyInfo.endWorkingHour}</span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PharmacyInfo);
