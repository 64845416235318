import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from 'moment';
import {ScheduleItem} from "./ScheduleItem";
import {formInitAction} from "../../../../actions/formAction";
import {FormattedMessage} from "react-intl";
import {listInitAction} from "../../../../actions/listAction";
import {isModal} from "../../../../utils/app";

class ScheduleViewOnMonth extends Component {
    componentWillUnmount() {
        this.props.onInitForm("doctorSchedulesContextOfDay", {});
        this.props.onInitList("doctorSchedules");
    }

    render() {
        const {
            list: {data: listData},
            detailScheduleKey = null,
            start,
            end,
            form: {data: formData},
            modal
        } = this.props;
        const {doctorSchedules = []} = listData;
        const {doctorSchedulesContextOfDay = {}} = formData;

        const formatDateStart = start.format('YYYY-MM-DD');
        const formatDateEnd = end.format('YYYY-MM-DD');

        let emptyCells = {};
        let momentItemWeekday = moment(formatDateStart);
        let momentEndWeekday = moment(formatDateEnd);
        while (momentItemWeekday.isSameOrBefore(momentEndWeekday)) {
            const key = moment(momentItemWeekday, "YYYY-MM-DD").format("YYYY-MM-DD");
            emptyCells[key] = null;
            momentItemWeekday = momentItemWeekday.add(1, "day");
        }

        let cells = {};
        doctorSchedules.map(doctorSchedule => {
            const {timeOfReceipt} = doctorSchedule;
            const key = moment(timeOfReceipt).format("YYYY-MM-DD");
            if (!cells[key]) cells[key] = [];
            cells[key].push(doctorSchedule);
        })

        const lastNumberWeekDay = momentEndWeekday.format("d");
        let whiteCells = [];
        for (let i = lastNumberWeekDay; i < 7; i++) {
            whiteCells.push(i);
        }

        return (
            <div className="calendar-grid">
                <div className="calendar-grid-headline">
                    {moment.weekdaysShort(true).map(weekday => {
                        return (
                            <div className="c-g-cell" key={weekday}>
                                <div className="c-g-cell-text">
                                    <div className="bold color-gray">{weekday}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="calendar-grid-body">
                    {Object.keys(moment.weekdays()).map(numberWeekday => {
                        if (numberWeekday < start.format('d')) {
                            return (
                                <div className="c-g-cell" key={numberWeekday}>
                                    <div className="c-g-cell-text"></div>
                                </div>
                            )
                        }
                    })}

                    {Object.keys(emptyCells).map(key => {
                        const date = moment(key, "YYYY-MM-DD").format("YYYY-MM-DD");
                        const time = moment(key, "YYYY-MM-DD HH:mm").format("HH:mm");

                        const pageSchedules = doctorSchedulesContextOfDay[date];
                        const {content: scheduleItems = [], totalElements = 0} = pageSchedules || {};

                        return (
                            <div className="c-g-cell" key={key}>
                                <div className="c-g-cell-text">{moment(key, "YYYY-MM-DD").format("D")}</div>
                                {scheduleItems.length == 0 &&
                                <div className="c-g-click-area"
                                     onClick={() => this.props.onApplyAppointmentTemplate(date, time)}></div>}
                                {scheduleItems.map(scheduleItem => {
                                    const {timeOfReceipt, id} = scheduleItem;
                                    const itemKey = moment(timeOfReceipt).format("YYYY-MM-DD HH:mm");
                                    return (
                                        <ScheduleItem
                                            key={'schedule-item-' + id}
                                            item={scheduleItem}
                                            detailScheduleKey={isModal(modal, 'showAllAppointment') ? null : detailScheduleKey}
                                            itemKey={itemKey}
                                            onCloseDetail={() => this.props.onCloseDetail()}
                                            onRemoveSchedule={() => this.props.onRemoveSchedule(id)}
                                            onDetail={() => this.props.onDetail(itemKey)}/>
                                    )
                                })}
                                {totalElements > 4 &&
                                <div className="c-g-event c-g-event-extra-items">
                                    <button type="button"
                                            className="btn c-g-event-extra-btn"
                                            onClick={() => this.props.onShowAll(moment(key))}>
                                        <FormattedMessage id="show.all.appointment" values={{"count": totalElements}}/>
                                    </button>
                                </div>}
                            </div>
                        )
                    })}

                    {Object.keys(moment.weekdays()).map(numberWeekday => {
                        if (numberWeekday > end.format('d')) {
                            return (
                                <div className="c-g-cell" key={numberWeekday}>
                                    <div className="c-g-cell-text"></div>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleViewOnMonth));
