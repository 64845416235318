import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../components/elements/emptyTag";
import {modalShow} from "../../services/system/modalService";
import {isModal} from "../../../utils/app";
import OpenAcceptTerm from "./OpenAcceptTerm";
import axios from 'axios';
import reactStringReplace from "react-string-replace";

import {preloaderPageHideAction, preloaderPageShowAction} from "../../../actions/preloaderAction";

class AgreeText extends Component {
    constructor() {
        super();
        this.loadAcceptTermsParams = this.loadAcceptTermsParams.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.state = {
            text : ""
        }
    }

    loadAcceptTermsParams = () => {
        const {language: {locale}, role} = this.props;
        (async () => {
            this.props.onPageShowPreloader();
            const url = "/ui/static/acceptTerms/" + locale + ".json";
            await axios.get(url).then(response => {
                const {text = {}, documents = []} = response.data;
                const documentsByRole = documents[role] || null;
                let textByRole = text[role] || "";
                if (documentsByRole) {
                    documentsByRole.map(({code, name, url}) => {
                        const documentLink = <a href={"#"} key={code} onClick={() => this.onOpen(url)}>{name}</a>;
                        textByRole = reactStringReplace(textByRole, '%' + code + '%', (match, i) =>
                            documentLink
                        )
                    });
                }
                this.setState({...this.state, text: textByRole});
            });

            this.props.onPageHidePreloader();
        })();
    }

    componentDidMount() {
        this.loadAcceptTermsParams();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {language: curLanguage} = this.props;
        const {language: prevLanguage} = prevProps;
        if (curLanguage.locale && prevLanguage.locale && curLanguage.locale != prevLanguage.locale) {
            this.loadAcceptTermsParams();
        }
    }

    onOpen(url) {
        (async () => {
            const response = await axios.get(url);
            this.props.onOpenAcceptTerm(response.data);
        })();
    }

    render() {
        const {modal} = this.props;
        return (
            <EmptyTag>
                {this.state.text}
                {isModal(modal, 'openAcceptTerm') && <OpenAcceptTerm/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        language: store.language,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onOpenAcceptTerm: (text) => dispatch(modalShow("openAcceptTerm", {text: text})),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreeText);
