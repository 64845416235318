import React, {Component} from 'react';
import {connect} from "react-redux";

import {withRouter} from 'react-router-dom';
import {Modal} from "../../../components/modules/modal";
import {modalHide} from "../../services/system/modalService";

class OpenAcceptTerm extends Component {
    render() {
        const {form: {data: formData}} = this.props;
        const {openAcceptTerm} = formData;
        const {text = ""} = openAcceptTerm || {};
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('openAcceptTerm')} width={1100}>
                <div dangerouslySetInnerHTML={{__html: text}}></div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        infoAppointment: store.infoAppointment
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpenAcceptTerm));
