import React, {Component} from 'react';
import {connect} from "react-redux";
import {OTSubscriber} from 'opentok-react';
import {noticeShowAction} from "../../../actions/noticeAction";
import {startRecord} from "../../services/callService";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../actions/preloaderAction";
import {FormattedMessage} from "react-intl";
import {callCallingPauseAction} from "../../../actions/callAction";

class Subscriber extends Component {
    subscriberEventHandlers = {
        error: (event) => {
            console.log(event);
        },
        disconnected: () => {
            console.log('Subscriber disconnected!');
        },
        connected: () => {
            console.log('Subscriber connected!');
            const {callIncoming} = this.props.call.commonParams;
            this.props.onCallCallingPause();
            if (!callIncoming && this.props.streams.length == 1) {
                const {sessionId} = this.props.call.sessionOptions;
                this.props.onStartRecord(sessionId);
            }
        },
        destroyed: () => {
            console.log('subscriber destroyed!');
            const {streams = [], call: {commonParams}} = this.props;
            const isOnlyIncoming = streams.every(stream => {
                    try {
                        const name = JSON.parse(stream.name);
                        return name.isIncomingCall;
                    } catch (e) {
                        return false
                    }
                }
            );

            const isNeedCompleteCall = (isOnlyIncoming && commonParams.callIncoming) ||
                (streams.length == 0 && !commonParams.callIncoming);

            if (isNeedCompleteCall) {
                this.props.onNoticeShow('', <FormattedMessage id="call.competed.text"/>);
                this.props.onRedirectTo();
            }
        }
    };

    onSubscriberError(error) {
        console.log(error.message)
        this.props.onCompleteCall();
        this.props.onNoticeShow('', <FormattedMessage id="call.failed.connect" values={{mgs: error.message}}/>);
    }

    render() {
        return (
            <OTSubscriber
                stream={this.props.stream}
                session={this.props.session}
                properties={{
                    showControls: true,
                    subscribeToAudio: true,
                    subscribeToVideo: true,
                    resolution: "640x480",
                    insertMode: 'after',
                    width: '100%',
                    height: '100%'
                }}
                eventHandlers={this.subscriberEventHandlers}
                onError={(error) => this.onSubscriberError(error)}
            />
        );
    }
}

const mapStateToProps = store => {
    return {
        call: store.call
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCallCallingPause: () => dispatch(callCallingPauseAction()),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onPreloaderPageShow: () => dispatch(preloaderPageShowAction()),
        onPreloaderPageHide: () => dispatch(preloaderPageHideAction()),
        onStartRecord: (sessionId) => dispatch(startRecord(sessionId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriber);