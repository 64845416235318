import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../../components/modules/modal/index";
import {modalHide, modalShow} from "../../../../services/system/modalService";
import {Header} from "../../../../../components/elements/header";
import {Button} from "../../../../../components/elements/button";
import {Textarea} from "../../../../../components/elements/textarea";
import {formChangeField, formSimpleChange} from "../../../../services/system/formService";
import {requestOnReschedulingBooking} from "../../../../services/bookingService";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import ChooseTimeOfReceipt from "../../../patient/bookAnAppointment/wizard/ChooseTimeOfReceipt";

class Rescheduling extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {form: {data}} = this.props;
        const {rescheduling: {booking}} = data;
        const selectedTimeOfReceipt = booking.rescheduling == null ? booking.timeOfReceipt : booking.rescheduling.timeOfReceipt;
        this.props.onFormSimpleChangeField('rescheduling', 'timeOfReceipt', selectedTimeOfReceipt);
    }

    onChange(event) {
        this.props.onFormChangeField('rescheduling', event);
    }

    render() {
        const {form: {data}} = this.props;
        const {rescheduling} = data;
        const {booking, comment, timeOfReceipt} = rescheduling;
        const isDisabled = parseInt(booking.timeOfReceipt) === parseInt(rescheduling.timeOfReceipt) ||
            (booking.rescheduling != null && parseInt(booking.rescheduling.timeOfReceipt) === parseInt(rescheduling.timeOfReceipt));
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('rescheduling')} width={1000}>
                <Header as="h2"><FormattedMessage id="rescheduling.header"/></Header>
                <div className="row form-elements">
                    <div className="col-12">
                        {timeOfReceipt && <ChooseTimeOfReceipt doctor={booking.doctor}
                                                               isRescheduling={true}
                                                               typeConsultation={booking.typeConsultation}
                                                               address={booking.address}
                                                               timeOfReceipt={timeOfReceipt}
                                                               onChangeTime={this.onChange}/>}
                    </div>
                    <div className="col-12">
                        <Textarea placeholder=<FormattedMessage id="rescheduling.comment"/>
                        value={comment || ''}
                        name="comment"
                        onChange={this.onChange}/>
                    </div>
                    <div className="col-12">
                        <div className="form-submit">
                            <Button
                                disabled={isDisabled}
                                onClick={() => this.props.onConfirm({
                                    text: <FormattedMessage id="rescheduling.confirm.save"
                                                            values={{time: moment(parseInt(rescheduling.timeOfReceipt)).format("lll")}}/>,
                                    onProceed: (() => this.props.onRequestOnReschedulingBooking(rescheduling))
                                })}><FormattedMessage id="core.btn.save.label"/></Button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        preloader: store.preloader,
        tab: store.tab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onRequestOnReschedulingBooking: (rescheduling) => dispatch(requestOnReschedulingBooking(rescheduling)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rescheduling);
