import React, {Component} from 'react';
import {connect} from "react-redux";

import {Modal} from "../../../components/modules/modal";
import {modalHide} from "../../services/system/modalService";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Button, Buttons} from "../../../components/elements/button";
import {Textarea} from "../../../components/elements/textarea";
import * as filesize from "filesize";
import {formChangeField} from "../../services/system/formService";

class ConfirmUploadFile extends Component {
    constructor(props) {
        super(props);
        this.onChangeDescription = this.onChangeDescription.bind(this);
    }

    componentDidMount() {
    }

    onChangeDescription(event) {
        this.props.onFormChangeField('confirmFileUpload', event);
    }

    render() {
        const {onModalHide, form: {data}} = this.props;
        const {confirmFileUpload: {files, onSend, description = ''}} = data;
        return (
            <Modal close={true} onClose={() => onModalHide('confirmFileUpload')}>
                <div className="row content-row">
                    <GridRow>
                        <GridCol count='1'>
                            {files[0].name} ({filesize(files[0].size)})
                        </GridCol>
                        <GridCol count='1'>
                                <Textarea placeholder="Description"
                                          name="description"
                                          value={description}
                                          disabled={false}
                                          onChange={this.onChangeDescription}/>
                        </GridCol>
                        <GridCol count='1'>
                            <Buttons>
                                <Button color={"white-red"} onClick={() => onModalHide('confirmFileUpload')}>
                                    Cancel
                                </Button>
                                <Button onClick={onSend}>Send</Button>
                            </Buttons>
                        </GridCol>
                    </GridRow>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUploadFile);
