import {formErrors, formInit, formIsSend} from "./system/formService";
import {privateApi, callApi} from '../../utils/api';
import {noticeShowAction} from "../../actions/noticeAction";
import {
    callCallingPauseAction,
    callDestroyAction,
    consultationCallInitAction,
    emergencyCallInitAction
} from "../../actions/callAction";
import {modalHide} from "./system/modalService";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {listFetchAction} from "../../actions/listAction";
import {handsetNoRingAction, handsetRingAction} from "../../actions/handsetAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {prepareFilter} from "../../utils/app";

export const getUrlStreamingCall = async (callId) => {
    const response = await privateApi.post("/v1/calls/" + callId + "/createStreamingLink");
    return window._env_.BACKEND_URL + "/v1/calls/streaming?hash=" + response.data;
};

export const fetchCalls = (pageNumber, listName = "calls") => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters[listName] || {});
        const sort = prepareFilter(state().list.sorts[listName] || {});
        return privateApi.get("/v1/calls?pageNumber=" + pageNumber + "&search=" + filter + "&sort=" + sort)
            .then(response => {
                const {data} = response;
                const {content = [], ...rest} = data;
                dispatch(listFetchAction(listName, content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchSizeQueueEmergencyCalls = () => {
    return (dispatch) => {
        return privateApi.get("/doctors/queueEmergencyCalls/size")
            .then(response => {
                const {data} = response;
                if (data > 0) {
                    dispatch(handsetRingAction());
                } else {
                    dispatch(handsetNoRingAction());
                }
            }).catch(error => {
            });
    }
};

export const fetchFirstQueueEmergencyCalls = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/doctors/queueEmergencyCalls?pageNumber=1&pageSize=1")
            .then(response => {
                const {data} = response;
                const {content = [], ...rest} = data;
                if (content.length == 0) {
                    dispatch(handsetNoRingAction());
                }
                dispatch(listFetchAction("firstQueueEmergencyCalls", content, {...rest}));

            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const emergencyCall = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/doctors/emergencyCall")
            .then(response => {
                return dispatch(noticeShowAction(<FormattedMessage id="notice.emergency.call.title"/>,
                    <FormattedMessage id="notice.emergency.call.msg.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    const {token = ""} = error.response.data.details || {};
                    if (token) {
                        return dispatch(noticeShowAction("emergencyCall", token));
                    }
                    dispatch(formErrors("emergencyCall", error.response.data.details));
                }
                dispatch(formIsSend("emergencyCall", false));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const createEmergencyCall = (patient, ownProps) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return callApi.post("/v1/calls/emergencyCall", {patientId: patient.id})
            .then(response => {
                const sessionOptions = {...response.data};
                const commonParams = {callIncoming: false, patientId: patient.id};
                dispatch(emergencyCallInitAction(sessionOptions, commonParams));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const createConsultationCall = (type, booking, ownProps) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return callApi.post("/v1/calls/consultationCall", {bookingId: booking.id, type: type})
            .then(response => {
                const sessionOptions = {...response.data};
                const commonParams = {callIncoming: false, patientId: booking.patient.id};
                const consultationCallParams = {booking: booking};
                dispatch(consultationCallInitAction(sessionOptions, commonParams, consultationCallParams));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const takeCall = (data, ownProps) => {
    const {sessionId, callType, bookingId = null, patientId} = data;
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return callApi.post("/v1/calls/" + sessionId + "/take")
            .then(response => {
                if (callType == 'EMERGENCY_CALL') {
                    const sessionOptions = {...response.data};
                    const commonParams = {callType: "EMERGENCY_CALL", callIncoming: true, patientId: patientId};
                    dispatch(emergencyCallInitAction(sessionOptions, commonParams));
                } else {
                    const sessionOptions = {...response.data};
                    const commonParams = {callType: "CONSULTATION_CALL", callIncoming: true, patientId: patientId};
                    const consultationCallParams = {booking: {id: bookingId}};
                    dispatch(consultationCallInitAction(sessionOptions, commonParams, consultationCallParams));
                }
                dispatch(formInit("chatRoom", {roomId: response.data.roomId}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(modalHide("incomingCall"));
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const startRecord = (sessionId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return callApi.post("/v1/calls/" + sessionId + "/archiveStart")
            .then(response => {
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const completeCall = (sessionId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return callApi.post("/v1/calls/" + sessionId + "/complete")
            .then(response => {
            })
            .catch(error => {
                if (error.response)
                    dispatch(noticeShowAction(
                        <FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(callCallingPauseAction());
                dispatch(callDestroyAction());
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const cancelCall = (sessionId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/calls/" + sessionId + "/sendCancelCall")
            .then(response => {
            }).catch(error => {
                return dispatch(noticeShowAction("", error.response.data.message));
            }).finally(() => {
                dispatch(modalHide("incomingCall"));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const sendReCall = (sessionId, participantId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/calls/" + sessionId + "/participants/" + participantId + "/sendReCall")
            .then(response => {
            }).catch(error => {
                return dispatch(noticeShowAction("", error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchCallParticipants = (sessionId, listName = 'callParticipants') => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/calls/" + sessionId + "/participants")
            .then(response => {
                dispatch(listFetchAction(listName, response.data, null));
            })
            .catch(error => {
                if (error.response)
                    dispatch(noticeShowAction(
                        <FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};


let localStream = null;
export const checkMediaDevices = (onEventDeviceAvailable, onEventDeviceNoAvailable) => {
    if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
        navigator.mediaDevices.getUserMedia({audio: true, video: true})
            .then(mediaStream => {
                localStream = mediaStream;
                onEventDeviceAvailable();
            })
            .catch(onEventDeviceNoAvailable);
    }
};

export const stopMediaDevices = () => {
    localStream.getTracks().forEach(function (track) {
        track.stop();
    });
}