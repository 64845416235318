import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Modal} from "../../../../components/modules/modal";
import {formChangeField, formSimpleChange} from "../../../services/system/formService";
import {createMember, fetchFamilyMemberTypes} from "../../../services/familyService";
import {Option, Select} from "../../../../components/elements/select";
import {formChangeFieldAction} from "../../../../actions/formAction";
import {getSexInput, getSexsInput, stringToInt} from "../../../../utils/app";
import {Input} from "../../../../components/elements/input";
import {Label} from "../../../../components/elements/label";
import Birthday from "../../../systems/birthday";
import moment from "moment";
import {Checkbox} from "../../../../components/elements/checkbox";
import {Button} from "../../../../components/elements/button";
import {modalHide} from "../../../services/system/modalService";
import Tooltip from "../../../systems/tooltip";

class CreateMember extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeBirthday = this.onChangeBirthday.bind(this);
        this.onChangeInteger = this.onChangeInteger.bind(this);
        this.state = {isOriginalCanSign: null};
    }

    componentDidMount() {
        const {form: {data: formData}} = this.props;
        const {createMember = {}} = formData;
        this.setState({
            isOriginalCanSign: createMember.child && createMember.child.isCanSign
        });
        this.props.onFetchFamilyMemberTypes();
    }

    onChange(event) {
        this.props.onFormChangeField('createMember', event);
    }

    onChangeInteger(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChange("createMember", target.name, stringToInt(value));
    }

    onChangeBirthday(day, month, year) {
        if (day != null && month != null && year != null) {
            const birthday = moment().set({'date': day, 'month': month, 'year': year});
            if (moment([year, month, day]).isValid()) {
                this.props.onFormSimpleChange("createMember", "child.birthday", birthday.format("DD.MM.YYYY"));
            } else {
                this.props.onFormSimpleChange("createMember", "child.birthday", null);
            }
        } else {
            this.props.onFormSimpleChange("createMember", "child.birthday", null);
        }
    }

    render() {
        const {form: {data: formData, errors: formErrors, isSend: formIsSend}, list: {data: listData}} = this.props;
        const {isOriginalCanSign} = this.state;
        const errors = formErrors.createMember;
        const disabled = formIsSend.createMember || false;
        const {createMember = {}} = formData;
        const {familyMemberTypes = []} = listData;
        const sex = createMember['child'] && createMember['child']['sex'];
        const birthday = (createMember.child && createMember.child.birthday) || null;
        let birthdayValue = null;
        if (birthday != null) {
            let momentValue = moment(birthday, "DD.MM.YYYY")
            if (momentValue.isValid()) {
                birthdayValue = momentValue.format("DD.MM.YYYY");
            } else {
                momentValue = moment(birthday, "YYYY-MM-DD")
                birthdayValue = momentValue.format("DD.MM.YYYY");
            }
        }

        const isChangeOriginalCanSign = createMember && createMember.child && createMember.child.isCanSign && !isOriginalCanSign;
        const isDisabledEmail = createMember && createMember.id && !isChangeOriginalCanSign;

        return (
            <Modal close={true} onClose={() => this.props.onModalHide()} width={800}>
                <div className="row form-elements">
                    <div className="col-md-6">
                        <Select label={<FormattedMessage id="type.relationship"/>}
                                disabled={disabled}
                                error={(errors && (errors['memberType'] || errors['memberType.id'])) || null}
                                optionNameSelected={(createMember['memberType'] && createMember['memberType']['name']) || ''}>
                            {
                                familyMemberTypes.map(familyMemberType =>
                                    <Option
                                        active={createMember['memberType'] && createMember['memberType']['id'] === familyMemberType.id}
                                        key={familyMemberType.id}
                                        onClick={() => this.props.onChangeDropdown("memberType", familyMemberType)}>
                                        {familyMemberType.name}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>

                    <div className="col-md-6">
                        <FormattedMessage id={getSexInput(sex) || 'undefined'}>
                            {optionNameSelected =>
                                <Select name="child.sex"
                                        icon="gender"
                                        error={(errors && (errors['child.sex'] || errors.child)) || null}
                                        disabled={disabled}
                                        label={<FormattedMessage id="core.gender.label"/>}
                                        optionNameSelected={optionNameSelected}>
                                    {
                                        getSexsInput().map(key =>
                                            <Option
                                                active={createMember['child'] && createMember['child']['sex'] && createMember['child']['sex'] === key}
                                                key={key}
                                                onClick={() => this.props.onChangeDropdown("child.sex", key)}>
                                                {<FormattedMessage id={getSexInput(key)}/>}
                                            </Option>
                                        )
                                    }
                                </Select>}
                        </FormattedMessage>
                    </div>

                    <div className="col-md-6">
                        <Input placeholder={<FormattedMessage id="core.first.name.label"/>}
                               name="child.firstName"
                               error={(errors && (errors['child.firstName'] || errors.child)) || null}
                               value={(createMember.child && createMember.child.firstName) || ''}
                               disabled={disabled}
                               onChange={this.onChange}/>
                    </div>

                    <div className="col-md-6">
                        <Input placeholder={<FormattedMessage id="core.last.name.label"/>}
                               name="child.lastName"
                               error={(errors && (errors['child.lastName'] || errors.child)) || null}
                               value={(createMember.child && createMember.child.lastName) || ''}
                               disabled={disabled}
                               onChange={this.onChange}/>
                    </div>

                    <div className="col-md-6">
                        <Label>{<FormattedMessage id="core.birthday.label"/>}</Label>
                        <Birthday error={(errors && (errors['child.birthday'] || errors.child)) || null}
                                  value={birthdayValue}
                                  onChangeBirthday={this.onChangeBirthday}/>
                    </div>

                    <div className="col-md-6">
                        <Input name="child.phone"
                               icon="phone"
                               value={(createMember.child && createMember.child.phone) || ''}
                               error={(errors && (errors['child.phone'] || errors.child)) || null}
                               placeholder={<FormattedMessage id="core.phone.label"/>}
                               disabled={disabled}
                               onChange={this.onChange}/>
                    </div>

                    <div className="col-12">
                        <Input name="child.nhsNumber"
                               icon="nhs"
                               value={(createMember.child && createMember.child.nhsNumber) || ''}
                               error={(errors && errors['child.nhsNumber']) || null}
                               placeholder={<FormattedMessage id="core.insurance.number.label"/>}
                               disabled={disabled}
                               onChange={this.onChangeInteger}/>
                    </div>

                    <div className="col-12">
                        <Checkbox name="child.isCanSign"
                                  toggle={true}
                                  isChecked={(createMember.child && createMember.child.isCanSign) || false}
                                  onChange={this.onChange}>
                            <FormattedMessage id="account.user"/>
                        </Checkbox>
                        <Tooltip icon={'info-blue'} margin='left-10'>
                            <FormattedMessage id="create.member.account.user.tooltip"/>
                        </Tooltip>
                    </div>

                    {(createMember.child && createMember.child.isCanSign) &&
                    <div className="col-12">
                        <Input placeholder={<FormattedMessage id="core.field.email.label"/>}
                               name="child.email"
                               error={(errors && (errors['child.email'] || errors.child)) || null}
                               value={(createMember.child && createMember.child.email) || ''}
                               disabled={isDisabledEmail}
                               onChange={this.onChange}/>
                    </div>}

                    <div className="col-12">
                        <div className="form-submit">
                            <Button disabled={disabled}
                                    color="white-red"
                                    preloader={false}
                                    onClick={() => this.props.onModalHide()}><FormattedMessage
                                id="core.btn.cancel.label"/></Button>

                            <Button
                                disabled={disabled}
                                onClick={() => this.props.onCreateMember(createMember)}>
                                <FormattedMessage id="core.btn.save.label"/>
                            </Button>
                        </div>
                    </div>

                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeDropdown: (fieldName, fieldValue) =>
            dispatch(formChangeFieldAction('createMember', fieldName, fieldValue)),
        onFetchFamilyMemberTypes: () => dispatch(fetchFamilyMemberTypes()),
        onModalHide: () => dispatch(modalHide('createMember')),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChange: (formName, fieldName, fieldValue) =>
            dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onCreateMember: (data) => dispatch(createMember(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateMember);
