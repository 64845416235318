import {
    CALL_SHOW,
    CALL_HIDE,
    EMERGENCY_CALL_INIT,
    CONSULTATION_CALL_INIT,
    CALL_SESSION_INIT,
    CALL_CALLING_INIT,
    CALL_CALLING_START,
    CALL_CALLING_PAUSE,
    CALL_STREAMS_UPDATE, CALL_DESTROY
} from "../constants/actionTypes";

export const emergencyCallInitAction = (sessionOptions, commonParams = {}) => ({
    type: EMERGENCY_CALL_INIT,
    sessionOptions: sessionOptions,
    commonParams: commonParams
});

export const consultationCallInitAction = (sessionOptions, commonParams = {}, consultationCallParams = {}) => ({
    type: CONSULTATION_CALL_INIT,
    sessionOptions: sessionOptions,
    commonParams: commonParams,
    consultationCallParams: consultationCallParams
});

export const callDestroyAction = () => ({
    type: CALL_DESTROY
});

export const callShowAction = () => ({
    type: CALL_SHOW
});

export const callHideAction = () => ({
    type: CALL_HIDE
});

export const callCallingInitAction = () => ({
    type: CALL_CALLING_INIT
});

export const callCallingStartAction = () => ({
    type: CALL_CALLING_START
});

export const callCallingPauseAction = () => ({
    type: CALL_CALLING_PAUSE
});

export const callStreamsUpdateAction = (streams) => ({
    type: CALL_STREAMS_UPDATE,
    streams: streams
});

export const callSessionInitAction = (sessionHelper) => ({
    type: CALL_SESSION_INIT,
    sessionHelper: sessionHelper
});