import React, {Component} from 'react';
import {connect} from "react-redux";

import {sign, signViaApple, signViaGoogle} from "../../services/userService";

import {GridCol} from "../../../components/collections/grid";
import {Button, Buttons} from "../../../components/elements/button";
import {Link} from "react-router-dom";
import {MainLogo} from "../../../components/elements/logo";
import {Redirect} from "react-router";

import {FormattedMessage} from 'react-intl';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {Header} from "../../../components/elements/header";
import {modalShow} from "../../services/system/modalService";
import {isModal} from "../../../utils/app";
import OpenAcceptTerm from "./OpenAcceptTerm";
import AgreeText from "./AgreeText";

import {preloaderPageHideAction, preloaderPageShowAction} from "../../../actions/preloaderAction";
import qs from "query-string";

class AcceptTerms extends Component {
    constructor() {
        super();
        this.onAccept = this.onAccept.bind(this);
    }

    onAccept() {
        const {form: {data: formData}} = this.props;
        const locationSearch = this.props.location.search;
        const {role = "patient"} = qs.parse(locationSearch);
        const data = formData.acceptTerms || {};
        const credential = data;
        switch (data.authProvider) {
            case "APPLE":
                this.props.onSignViaApple({...credential, isAgree: true});
                break;
            case "GOOGLE":
                this.props.onSignViaGoogle({...credential, isAgree: true});
                break;
            default:
                if (role =="patient") {
                    this.props.onRedirectSignUp();
                } else if (role == "doctor") {
                    this.props.onRedirectSignUpDoctor();
                }
        }
    }

    render() {
        const {auth, modal} = this.props;
        if (auth.isAuthenticated) {
            const signReferrer = localStorage.getItem("SignReferrer") || "/";
            return <Redirect to={signReferrer}/>;
        }
        const locationSearch = this.props.location.search;
        const {role = "patient"} = qs.parse(locationSearch);
        return (
            <EmptyTag>
                <MainLogo/>
                <p><i className="icon icon-size-112-65 icon--accept"></i></p>

                <Header as="h2"><FormattedMessage id="page.accept.terms.header"/></Header>
                <p><AgreeText role={role}/></p>
                <GridCol count="1">
                    <Buttons width="auto">
                        <Link to={'/welcome'} className="btn-default btn-white-red">
                            <div className="btn__content">
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </div>
                        </Link>

                        <Button onClick={this.onAccept}>
                            <FormattedMessage id="core.btn.agree.label"/>
                        </Button>
                    </Buttons>
                </GridCol>
                <GridCol count="1">
                    <Link to={"/welcome"} className="back-link"><FormattedMessage id="core.btn.back.to.welcome.label"/></Link>
                </GridCol>
                {isModal(modal, 'openAcceptTerm') && <OpenAcceptTerm/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth,
        browser: store.browser,
        language: store.language,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction()),
        onSignViaApple: (credential) => dispatch(signViaApple(credential)),
        onSignViaGoogle: (credential) => dispatch(signViaGoogle(credential)),
        onSign: (credential) => dispatch(sign(credential, ownProps)),
        onRedirectSignUp: () => ownProps.history.push('/users/sign-up'),
        onRedirectSignUpDoctor: () => ownProps.history.push('/users/sign-up-doctor'),
        onOpenAcceptTerm: (text) => dispatch(modalShow("openAcceptTerm", {text: text})),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTerms);
