import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";

import {floatFormatting} from "../../../../../utils/app";
import ReportInfo from "../ReportInfo";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import InfoPeriod from "../InfoPeriod";

class ReportBurnDownInfo extends Component {
    render() {
        const {form: {data: formData}, dateFrom, dateTo} = this.props;
        const {reportParams, systemSettings} = formData || {};
        const {paymentCurrency} = systemSettings || {};
        const {
            totalRemittanceAmount = null, totalCommissionAmount = null, totalCortexPayout = null,
            totalConsultationNumber = null, totalPrescriptionNumber = null, totalCloudStorageUsed = null
        } = reportParams || {};

        return (
            <EmptyTag>
                <div className="col-md-7 padding-bottom-0">
                    <div className="row tile-row">
                        <div className="col-md-6">
                            <InfoPeriod title={<FormattedMessage id="report.period.value.label"/>}
                                        dateFrom={dateFrom} dateTo={dateTo}/>
                        </div>
                        <div className="col-md-6">
                            <ReportInfo title={<FormattedMessage id="report.totals.remittance.amount"
                                                                 values={{currency: paymentCurrency}}/>}
                                        info={floatFormatting(totalRemittanceAmount)}/>
                        </div>
                        <div className="col-md-6">
                            <ReportInfo title={<FormattedMessage id="report.totals.commission.amount"
                                                                 values={{currency: paymentCurrency}}/>}
                                        info={floatFormatting(totalCommissionAmount)}/>
                        </div>
                        <div className="col-md-6">
                            <ReportInfo title={<FormattedMessage id="report.totals.cortex.payout"
                                                                 values={{currency: "USD"}}/>}
                                        info={floatFormatting(totalCortexPayout)}/>
                        </div>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="white-block padding-20">
                        <div className="row default-row row-padding-15">
                            <div className="col-12">
                                <div className="icon-item icon-item-bg icon-item-blue-bg">
                                    <i className="icon icon--comments-blue"></i>
                                    <span className="icon-item__text">
                                        <label className="field-label">
                                            <FormattedMessage id="report.totals.consultation.number"/>
                                        </label>
                                        <strong>{totalConsultationNumber}</strong>
                                    </span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="icon-item icon-item-bg icon-item-red-bg">
                                    <i className="icon icon--bottle-red"></i>
                                    <span className="icon-item__text">
                                        <label className="field-label">
                                            <FormattedMessage id="report.totals.prescription.number"/>
                                        </label>
                                        <strong>{totalPrescriptionNumber}</strong>
                                    </span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="icon-item icon-item-bg icon-item-orange-bg">
                                    <i className="icon icon--cloud-orange"></i>
                                    <span className="icon-item__text">
                                        <label className="field-label"><FormattedMessage
                                            id="report.totals.cloud.storage.used"/></label>
                                        <strong>{floatFormatting(totalCloudStorageUsed / (1024 * 1024 * 1024))}</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportBurnDownInfo);