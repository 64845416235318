import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Button} from "../../../../components/elements/button";
import {Icon} from "../../../../components/elements/icon";
import {
    approveBookingList,
    fetchBookings,
    rejectBookingList
} from "../../../services/bookingService";
import {GridCol} from "../../../../components/collections/grid";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";
import {listFilterFieldAction} from "../../../../actions/listAction";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {Link} from "react-router-dom";
import {modalShow} from "../../../services/system/modalService";
import {PaymentStatus} from "../../../../components/collections/payment_status";
import {FormattedMessage} from "react-intl";
import ConfirmReasonRejectedAdvanceContent from "../../doctor/bookings/ConfirmReasonRejectedAdvanceContent";
import Avatar from "../../../systems/avatar";
import ChooseMemberSelect from "../family/ChooseMemberSelect";
import {formInitAction} from "../../../../actions/formAction";
import {Redirect} from "react-router";

class PatientBookings extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onFilter = this.onFilter.bind(this);
    }

    componentDidMount() {
        const {auth: {user}} = this.props;
        this.props.onInitForm("chooseMemberSelect", {"member": user});
        this.props.onListFilterField("patientId", user.id);
        this.props.onListFilterField("statuses", "REQUESTED-APPROVED");
        this.props.onFetchBookings(1);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData}} = this.props;
        const {form: {data: prevFormData}} = prevProps;

        const {chooseMemberSelect: curChooseMemberSelect = {}} = curFormData;
        const {chooseMemberSelect: prevChooseMemberSelect = {}} = prevFormData;
        const {member : curMember= null} = curChooseMemberSelect;
        const {member : prevMember= null} = prevChooseMemberSelect;
        if (curMember && prevMember && curMember.id != prevMember.id) {
            this.props.onListFilterField("statuses", "REQUESTED-APPROVED");
            this.props.onListFilterField("patientId", curMember.id);
            this.props.onFetchBookings(1);
        }
    }

    onPagination(pageNumber) {
        this.props.onFetchBookings(pageNumber);
    }

    onFilter(fieldName, fieldValue) {
        this.props.onListFilterField(fieldName, fieldValue);
        this.props.onFetchBookings(1);
    }

    render() {
        const {list: {data, pagination, filters}, auth: {user}} = this.props;
        if (user.role === 'PATIENT' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }

        const {bookings = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['bookings'] || {};
        const {statuses: filterStatus = ""} = filters['bookings'] || {};

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    {!user.familyMember && <EmptyTag>
                        <div className="col-md-6">
                            <ChooseMemberSelect/>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </EmptyTag>}

                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.patient.bookings.header"/></Header>
                    </div>
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab active={filterStatus == 'REQUESTED-APPROVED'}
                                 onClick={() => this.onFilter("statuses", "REQUESTED-APPROVED")}><FormattedMessage
                                id="patient.bookings.tab.active.header"/></Tab>
                            <Tab active={filterStatus == 'COMPLETED'}
                                 onClick={() => this.onFilter("statuses", "COMPLETED")}><FormattedMessage
                                id="patient.bookings.tab.history.header"/></Tab>
                            <Tab active={filterStatus == 'REJECTED'}
                                 onClick={() => this.onFilter("statuses", "REJECTED")}><FormattedMessage
                                id="patient.bookings.tab.canceled.header"/></Tab>
                        </Tabs>
                    </GridCol>
                </div>

                <div className="row link-blocks-row">
                    {bookings.map(booking => {
                            const {doctor, payment} = booking;
                            const {paymentStatus} = payment || {};
                            const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent booking={booking}/>;
                        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
                        const joinSpecializations = specializations.map(i => i.name).join(", ")
                            return (
                                <GridCol count="2" key={booking.id}>
                                    <div className="link-block-wrapper">
                                        <Link to={'/bookings/' + booking.id + '/detail'} className="link-block"></Link>
                                        <div className="row avatar-row align-items-center">
                                            <div className="col-12">
                                                <div className="row date-row align-items-center">
                                                    <div className="col-auto date-row__icon">
                                                        <Icon name="clock"/>
                                                    </div>
                                                    <div className="col date-row__value color-gray">
                                                        {moment(booking.timeOfReceipt).format("lll")}
                                                    </div>
                                                    <div className="col-auto">
                                                        {booking.status === 'REQUESTED' &&
                                                        <div className="small-text color-orange"><FormattedMessage
                                                            id="booking.status.requested.label"/></div>}
                                                        {booking.status === 'APPROVED' &&
                                                        <div className="small-text color-green"><FormattedMessage
                                                            id="booking.status.approved.label"/></div>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto avatar-row__image">
                                                <Avatar userId={doctor.id}/>
                                            </div>
                                            <div className="col avatar-row__text">
                                                <div>
                                                    <FormattedMessage id="core.doctor.name" values={{
                                                        firstName: doctor.firstName,
                                                        lastName: doctor.lastName
                                                    }}/>
                                                </div>
                                                <div
                                                    className="small-text color-gray">{joinSpecializations}</div>
                                            </div>
                                            {(booking.status === 'REQUESTED' || booking.status === 'APPROVED' || booking.status === 'REJECTED') &&
                                            <div className="col-12">
                                                <div className="small-controls align-right">
                                                    {paymentStatus && <div className="float-left">
                                                        <PaymentStatus statusCode={paymentStatus}/>
                                                    </div>}

                                                    {(booking.status === 'REQUESTED' || booking.status === 'APPROVED') &&
                                                    <Button color="white-red" onClick={() => this.props.onConfirm({
                                                        text: <FormattedMessage id="core.confirm.booking.cancel"/>,
                                                        advanceContent: rejectedAdvanceContent,
                                                        onProceed: (() => this.props.onRejectBooking(booking))
                                                    })}><FormattedMessage id="core.btn.cancel.label"/></Button>}

                                                    {(booking.status === 'REJECTED') && <EmptyTag>
                                                        {booking.canceledByUser && booking.canceledByUser.id === booking.patient.id &&
                                                        <FormattedMessage id="booking.patient.canceled.text"/>}
                                                        {booking.canceledByUser && booking.canceledByUser.id === booking.doctor.id &&
                                                        <FormattedMessage id="booking.doctor.canceled.text"/>}
                                                        {!booking.canceledByUser &&
                                                        <FormattedMessage id="booking.auto.canceled.text"/>}
                                                    </EmptyTag>}
                                                </div>
                                            </div>}

                                        </div>
                                    </div>
                                </GridCol>
                            );
                        }
                    )}

                    {bookings.length === 0 &&
                    <div className="col-12"><FormattedMessage id="patient.bookings.list.empty"/></div>}

                    <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber)),
        onApproveBooking: (booking) => dispatch(approveBookingList(booking)),
        onRejectBooking: (booking) => dispatch(rejectBookingList(booking)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("bookings", fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientBookings);
