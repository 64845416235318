import React, {Component} from 'react';
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {floatFormatting, getReportPeriodInput, getReportPeriodsInput} from "../../../../../utils/app";
import {Option, Select} from "../../../../../components/elements/select";

import {EmptyTag} from "../../../../../components/elements/emptyTag";

import ReportInfo from "../ReportInfo";
import {formInitAction} from "../../../../../actions/formAction";
import {listInitAction} from "../../../../../actions/listAction";
import {generateReport} from "../../../../services/billingReportService";
import moment from "moment";
import {DatePicter} from "../../../../../components/elements/datepicter";
import {fetchBillingPeriodAvailableYears, fetchBillingPeriods} from "../../../../services/billingSettingsService";

class ClientAdminHome extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onChangePeriod = this.onChangePeriod.bind(this);
        this.onChangeBillingPeriodYear = this.onChangeBillingPeriodYear.bind(this);
        this.onChangeBillingPeriod = this.onChangeBillingPeriod.bind(this);
        this.onChangeDateCustomPeriod = this.onChangeDateCustomPeriod.bind(this);
        this.state = {
            isDisabledExportOrSend: false,
            reportParams: {
                reportPeriod: 'BILLING_PERIOD', reportType: "BURN_DOWN",
                reportPeriodParams: null
            }
        }
    }

    componentWillUnmount() {
        this.props.onInitForm("reportParams");
        this.props.onInitList("reportItems");
    }

    componentDidMount() {
        this.props.onInitList("reportItems");
        this.onChangePeriod(this.state.reportParams.reportPeriod, this.state.reportParams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list: {data: curListData = {}}, form: {data: curFormData}} = this.props;
        const {list: {data: prevListData = {}}, form: {data: prevFormData}} = prevProps;

        const {
            billingPeriodAvailableYears: curBillingPeriodAvailableYears = [],
            billingPeriods: curBillingPeriods = []
        } = curListData;
        const {
            billingPeriodAvailableYears: prevBillingPeriodAvailableYears = [],
            billingPeriods: prevBillingPeriods = []
        } = prevListData;

        const {reportParams: curReportParams = null} = curFormData;
        const {reportParams: prevReportParams = null} = prevFormData;

        if (curReportParams && prevReportParams && curReportParams.reportPeriod == 'BILLING_PERIOD') {
            if (curBillingPeriodAvailableYears != prevBillingPeriodAvailableYears && curBillingPeriodAvailableYears.length > 0) {
                this.onChangeBillingPeriodYear(curBillingPeriodAvailableYears[0]);
            }

            if (curBillingPeriods != prevBillingPeriods && curBillingPeriods.length > 0) {
                this.onChangeBillingPeriod(curBillingPeriods[0].id);
            }
        }
    }

    onPagination(pageNumber) {
        const {form: {data: formData}} = this.props;
        const {reportParams} = formData;
        const {reportPeriod, reportPeriodParams} = reportParams;
        const {dateFrom = null, dateTo = null} = reportPeriodParams || {};

        if (reportPeriod == 'CUSTOM' && (!dateFrom || !dateTo)) {
            return;
        }
        this.props.onGenerateReport(pageNumber, reportParams);
    }

    onChangePeriod(reportPeriod, reportParams = {}) {
        const {form: {data: formData}} = this.props;
        if (Object.keys(reportParams).length == 0 || reportParams == null) {
            reportParams = {...formData.reportParams};
        }

        reportParams = {...reportParams, reportPeriod: reportPeriod, reportPeriodParams: null};

        if (reportPeriod !== 'CUSTOM' && reportPeriod !== 'BILLING_PERIOD') {
            this.props.onGenerateReport(1, reportParams);
        } else if (reportPeriod == 'BILLING_PERIOD') {
            this.props.onFetchBillingPeriodAvailableYears();
        }
        this.props.onInitForm("reportParams", reportParams);
    }

    onChangeDateCustomPeriod(objMoment, fieldName) {
        const {form: {data: formData}} = this.props;
        const fieldValue = moment(objMoment).isValid() ? objMoment.format("YYYY-MM-DD") : null
        let {reportPeriodParams = {}} = formData.reportParams;
        reportPeriodParams = {...reportPeriodParams, [fieldName]: fieldValue};
        let reportParams = {...formData.reportParams, reportPeriodParams: reportPeriodParams};
        const {dateFrom, dateTo} = reportPeriodParams;
        let isDisabledExportOrSend = false;

        if (dateFrom && dateTo) {
            if (moment(dateFrom, "YYYY-MM-DD").isSameOrBefore(moment(dateTo, "YYYY-MM-DD"))) {
                this.props.onGenerateReport(1, reportParams);
            } else {
                this.props.onNotice("", <FormattedMessage id="report.period.incorrect.date"/>);
                isDisabledExportOrSend = true;
            }
        } else if (!dateFrom && !dateTo) {
            reportPeriodParams = {...reportPeriodParams, dateFrom: null, dateTo: null};
            reportParams = {...formData.reportParams, reportPeriodParams: reportPeriodParams};
            this.props.onInitList("reportItems");
        }

        this.setState({...this.state, reportParams: reportParams, isDisabledExportOrSend: isDisabledExportOrSend});
        this.props.onInitForm("reportParams", reportParams);
    }

    onChangeBillingPeriodYear(year) {
        const {form: {data: formData}} = this.props;
        let {reportPeriodParams = {}} = formData.reportParams;
        reportPeriodParams = {...reportPeriodParams, billingPeriodYear: year};
        let reportParams = {...formData.reportParams, reportPeriodParams: reportPeriodParams};
        this.props.onInitForm("reportParams", reportParams);
        this.props.onFetchBillingPeriods(year);
    }

    onChangeBillingPeriod(billingPeriodId) {
        const {form: {data: formData}} = this.props;
        let {reportPeriodParams = {}} = formData.reportParams;
        reportPeriodParams = {...reportPeriodParams, billingPeriodId: billingPeriodId, dateFrom: null, dateTo: null};
        let reportParams = {...formData.reportParams, reportPeriodParams: reportPeriodParams};
        this.props.onInitForm("reportParams", reportParams);
        this.props.onGenerateReport(1, reportParams);
    }

    render() {
        const {form: {data: formData}, list: {data: listData}} = this.props;
        let { billingPeriods = [], billingPeriodAvailableYears = []} = listData;
        const {reportParams, systemSettings} = formData || {};
        const {paymentCurrency} = systemSettings || {};
        const {
            reportPeriod, reportPeriodParams,
            totalRemittanceAmount = null, totalCommissionAmount = null, totalCortexPayout = null,
            totalConsultationNumber = null, totalPrescriptionNumber = null, totalCloudStorageUsed = null
        } = reportParams || {};

        let {billingPeriodId = null, billingPeriodYear = null} = reportPeriodParams || {};

        if (billingPeriodYear == null && billingPeriodAvailableYears.length > 0) {
            billingPeriodYear = billingPeriodAvailableYears[0];
        }

        if (billingPeriodId == null && billingPeriods.length > 0) {
            billingPeriodId = billingPeriods[0].id;
        }

        const selectedBillingPeriod = billingPeriodId && billingPeriods.length > 0 ? billingPeriods.filter(i => i.id == billingPeriodId)[0] : null;
        const selectedOptionBillingPeriod = selectedBillingPeriod ?
            moment(selectedBillingPeriod.dateStart, "YYYY-MM-DD").format('MMM DD') + " - " +
            moment(selectedBillingPeriod.dateEnd, "YYYY-MM-DD").format('MMM DD') : 'undefined';
        return (
            <EmptyTag>
                <div className="row form-elements align-items-center filtr-form justify-content-end">

                    <div className="col-auto padding-right-0">
                        <label className="field-label margin-bottom-0"><FormattedMessage id="report.period"/></label>
                    </div>

                    <div className="col-auto">
                        <FormattedMessage id={getReportPeriodInput(reportPeriod) || 'undefined'}>
                            {optionNameSelected =>
                                <Select noLabel={true} height={40} width={180} bgColor={'white'}
                                        label={""}
                                        optionNameSelected={optionNameSelected}>
                                    {
                                        getReportPeriodsInput().map(key =>
                                            <Option
                                                active={reportPeriod === key}
                                                key={key}
                                                onClick={() => this.onChangePeriod(key)}>
                                                {<FormattedMessage id={getReportPeriodInput(key)}/>}
                                            </Option>
                                        )
                                    }
                                </Select>}
                        </FormattedMessage>
                    </div>
                    {reportPeriod === 'CUSTOM' && <div className="col-auto">
                        <div className="row small-form-row align-items-center filtr-form">
                            <div className="col-auto">
                                <label className="field-label margin-bottom-0"><FormattedMessage
                                    id="from.label"/></label>
                            </div>
                            <div className="col-auto">
                                <DatePicter no_label={true}
                                            bg_white={true}
                                            height={40}
                                            width={180}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => this.onChangeDateCustomPeriod(v, 'dateFrom')}
                                            dateFormat={'ll'}/>
                            </div>
                            <div className="col-auto">
                                <label className="field-label margin-bottom-0"><FormattedMessage id="to.label"/></label>
                            </div>
                            <div className="col-auto">
                                <DatePicter no_label={true}
                                            bg_white={true}
                                            height={40}
                                            width={180}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => this.onChangeDateCustomPeriod(v, 'dateTo')}
                                            dateFormat={'ll'}/>
                            </div>
                        </div>
                    </div>}
                    {reportPeriod === 'BILLING_PERIOD' && <div className="col-auto">
                        <div className="row small-form-row align-items-center filtr-form">
                            <div className="col-auto">

                                <Select noLabel={true} height={40} width={180} bgColor={'white'}
                                        label={""}
                                        optionNameSelected={billingPeriodYear || 'undefined'}>
                                    {
                                        billingPeriodAvailableYears.map(year =>
                                            <Option
                                                active={billingPeriodYear == year}
                                                key={year}
                                                onClick={() => this.onChangeBillingPeriodYear(year)}>
                                                {year}
                                            </Option>
                                        )
                                    }
                                </Select>
                            </div>
                            <div className="col-auto">

                                <Select noLabel={true} height={40} width={220} bgColor={'white'} icon="calendar-gray-2"
                                        label={""}
                                        optionNameSelected={selectedOptionBillingPeriod || 'undefined'}>
                                    {
                                        billingPeriods.map(({id, dateStart, dateEnd}) =>
                                            <Option
                                                active={billingPeriodId == id}
                                                key={id}
                                                onClick={() => this.onChangeBillingPeriod(id)}>
                                                {moment(dateStart, "YYYY-MM-DD").format('MMM DD')} -
                                                {moment(dateEnd, "YYYY-MM-DD").format('MMM DD')}
                                            </Option>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="row tile-row margin-bottom-15">
                    <div className="col-md-7 padding-bottom-0">
                        <div className="row tile-row">

                            <div className="col-md-6">
                                <ReportInfo title={<FormattedMessage id="report.totals.remittance.amount"
                                                                     values={{currency: paymentCurrency}}/>}
                                            info={floatFormatting(totalRemittanceAmount)}/>
                            </div>
                            <div className="col-md-6">
                                <ReportInfo title={<FormattedMessage id="report.totals.commission.amount"
                                                                     values={{currency: paymentCurrency}}/>}
                                            info={floatFormatting(totalCommissionAmount)}/>
                            </div>
                            <div className="col-md-6">
                                <ReportInfo title={<FormattedMessage id="report.totals.cortex.payout"
                                                                     values={{currency: "USD"}}/>}
                                            info={floatFormatting(totalCortexPayout)}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="white-block padding-20">
                            <div className="row default-row row-padding-15">
                                <div className="col-12">
                                    <div className="icon-item icon-item-bg icon-item-blue-bg">
                                        <i className="icon icon--comments-blue"></i>
                                        <span className="icon-item__text">
                                        <label className="field-label">
                                            <FormattedMessage id="report.totals.consultation.number"/>
                                        </label>
                                        <strong>{totalConsultationNumber}</strong>
                                    </span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="icon-item icon-item-bg icon-item-red-bg">
                                        <i className="icon icon--bottle-red"></i>
                                        <span className="icon-item__text">
                                        <label className="field-label">
                                            <FormattedMessage id="report.totals.prescription.number"/>
                                        </label>
                                        <strong>{totalPrescriptionNumber}</strong>
                                    </span>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="icon-item icon-item-bg icon-item-orange-bg">
                                        <i className="icon icon--cloud-orange"></i>
                                        <span className="icon-item__text">
                                        <label className="field-label"><FormattedMessage
                                            id="report.totals.cloud.storage.used"/></label>
                                        <strong>{floatFormatting(totalCloudStorageUsed / (1024 * 1024 * 1024))}</strong>
                                    </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchBillingPeriodAvailableYears: () => dispatch(fetchBillingPeriodAvailableYears()),
        onFetchBillingPeriods: (year) => dispatch(fetchBillingPeriods(year)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onGenerateReport: (pageNumber, data) => dispatch(generateReport(pageNumber, data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientAdminHome));
