import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {floatFormatting} from "../../../../../utils/app";
import {FormattedMessage} from "react-intl";
import {Input} from "../../../../../components/elements/input";
import {formSimpleChange} from "../../../../services/system/formService";
import {formInitAction} from "../../../../../actions/formAction";
import {completedRemittance, updateRemittanceReferenceNumber} from "../../../../services/billingSettingsService";
import {Icon} from "../../../../../components/elements/icon";
import Dropdown, {DropdownItem} from "../../../../systems/dropdown";

class ReportBankTotalPayoutItems extends Component {
    constructor(props) {
        super(props);
        this.onChangeReferenceNumber = this.onChangeReferenceNumber.bind(this);
        this.onEditReferenceNumber = this.onEditReferenceNumber.bind(this);
        this.onUpdateReferenceNumber = this.onUpdateReferenceNumber.bind(this);
        this.state = {
            isEdit: false
        }
    }

    onChangeReferenceNumber(event) {
        this.props.onFormSimpleChangeField("updateReferenceNumber", "referenceNumber", event.target.value);
    }

    onEditReferenceNumber(billingProfileId, currentReferenceNumber) {
        const updateReferenceNumber = {
            referenceNumber: currentReferenceNumber,
            billingProfileId: billingProfileId
        }
        this.props.onInitForm("updateReferenceNumber", updateReferenceNumber);
        this.setState({...this.state, isEdit: true});
    }

    onUpdateReferenceNumber() {
        const {form: {data: formData}} = this.props;
        const {updateReferenceNumber} = formData;
        const {billingProfileId, referenceNumber} = updateReferenceNumber;
        this.props.onUpdateRemittanceReferenceNumber(billingProfileId, {referenceNumber : referenceNumber});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: curForm} = this.props;
        const {updateReferenceNumber: currErrors = []} = curForm.errors;
        const {updateReferenceNumber: curIsSend = false} = curForm.isSend;

        const {form: prevForm} = prevProps;
        const {updateReferenceNumber: prevIsSend = false} = prevForm.isSend;

        if (currErrors.length == 0 && prevIsSend && !curIsSend) {
            this.setState({...this.state, isEdit: false});
        }
    }

    render() {
        const {items} = this.props;
        const {form: {data: formData, errors: formErrors}} = this.props;
        const {updateReferenceNumber = {}, reportParams} = formData;
        const {cortexBeneficiaryBankName, cortexSwiftCode, cortexBankBranchCode, cortexBeneficiaryAccountNumber,
            isCortexCompleted, cortexBeneficiaryName, cortexPaymentCurrency, cortexAmount,
            cortexReferenceNumber, cortexBillingProfileId} = reportParams;
        const {updateReferenceNumber: errors} = formErrors;
        const dropDownButton = <div>
            <Icon name="menu-points"/>
        </div>;

        return (
            <EmptyTag>
                <table className="admin-table">
                    <thead>
                    <tr>
                        <th></th>
                        <th><FormattedMessage id="beneficiary.bank.name.label"/></th>
                        <th><FormattedMessage id="swift.code.table.label"/></th>
                        <th><FormattedMessage id="bank.branch.code.label"/></th>
                        <th><FormattedMessage id="beneficiary.account.number.label"/></th>
                        <th><FormattedMessage id="beneficiary.name.label"/></th>
                        <th><FormattedMessage id="payment.currency"/></th>
                        <th><FormattedMessage id="amount"/></th>
                        <th><FormattedMessage id="reference.number"/></th>
                        <th><FormattedMessage id="remittance.cleared"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(({billingProfileId, beneficiaryBankName, swiftCode, bankBranchCode,
                                    beneficiaryAccountNumber, beneficiaryName, paymentCurrency, amount,
                                    referenceNumber, doctorId, isCompleted}) => {
                        return (
                            <tr key={doctorId}>
                                <td>
                                    {billingProfileId && <Dropdown button={dropDownButton} noArrow="true">
                                        {(!this.state.isEdit || updateReferenceNumber.billingProfileId != billingProfileId) &&
                                        <DropdownItem to={"#"} icon="edit-black"
                                                      onClick={() => this.onEditReferenceNumber(billingProfileId, referenceNumber)}>
                                            <FormattedMessage id="core.edit.label"/></DropdownItem>}

                                        {this.state.isEdit && updateReferenceNumber.billingProfileId == billingProfileId &&
                                        <DropdownItem to={"#"} icon="save-black"
                                                      onClick={() => this.onUpdateReferenceNumber()}>
                                            <FormattedMessage id="core.btn.save.label"/></DropdownItem>}

                                        {referenceNumber && <DropdownItem to={"#"} icon="arrow-2-right"
                                                      onClick={() => this.props.onCompletedRemittance(billingProfileId)}>
                                            <FormattedMessage id="remittance.cleared"/></DropdownItem>}
                                    </Dropdown>
                                    }
                                </td>
                                <td>{beneficiaryBankName}</td>
                                <td>{swiftCode}</td>
                                <td>{bankBranchCode}</td>
                                <td>{beneficiaryAccountNumber}</td>
                                <td>{beneficiaryName}</td>
                                <td>{paymentCurrency}</td>
                                <td>{floatFormatting(amount)}</td>
                                <td>
                                    {billingProfileId && <EmptyTag>
                                        {(!this.state.isEdit || updateReferenceNumber.billingProfileId != billingProfileId) && referenceNumber}
                                        {this.state.isEdit && updateReferenceNumber.billingProfileId == billingProfileId &&
                                        <Input simple name="referenceNumber"
                                               hasError={errors && errors["referenceNumber"]}
                                               value={updateReferenceNumber.referenceNumber || ''}
                                               onChange={this.onChangeReferenceNumber}/>}
                                    </EmptyTag>}
                                </td>
                                <td>{referenceNumber && isCompleted && <Icon name="approve"/>}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th>
                            {cortexBillingProfileId && <Dropdown button={dropDownButton} noArrow="true">
                                {(!this.state.isEdit || updateReferenceNumber.billingProfileId != cortexBillingProfileId) &&
                                <DropdownItem to={"#"} icon="edit-black"
                                              onClick={() => this.onEditReferenceNumber(cortexBillingProfileId, cortexReferenceNumber)}>
                                    <FormattedMessage id="core.edit.label"/></DropdownItem>}

                                {this.state.isEdit && updateReferenceNumber.billingProfileId == cortexBillingProfileId &&
                                <DropdownItem to={"#"} icon="save-black"
                                              onClick={() => this.onUpdateReferenceNumber()}>
                                    <FormattedMessage id="core.btn.save.label"/></DropdownItem>}

                                {cortexReferenceNumber &&
                                <DropdownItem to={"#"} icon="arrow-2-right"
                                              onClick={() => this.props.onCompletedRemittance(cortexBillingProfileId)}>
                                    <FormattedMessage id="remittance.cleared"/></DropdownItem>}
                            </Dropdown>
                            }
                        </th>
                        <th>{cortexBeneficiaryBankName}</th>
                        <th>{cortexSwiftCode}</th>
                        <th>{cortexBankBranchCode}</th>
                        <th>{cortexBeneficiaryAccountNumber}</th>
                        <th>{cortexBeneficiaryName}</th>
                        <th>{cortexPaymentCurrency}</th>
                        <th>{floatFormatting(cortexAmount)}</th>
                        <th>
                            {cortexBillingProfileId && <EmptyTag>
                                {(!this.state.isEdit || updateReferenceNumber.billingProfileId != cortexBillingProfileId) && cortexReferenceNumber}
                                {this.state.isEdit && updateReferenceNumber.billingProfileId == cortexBillingProfileId &&
                                <Input simple name="referenceNumber"
                                       hasError={errors && errors["referenceNumber"]}
                                       value={updateReferenceNumber.referenceNumber || ''}
                                       onChange={this.onChangeReferenceNumber}/>}
                            </EmptyTag>}
                        </th>
                        <td>{cortexReferenceNumber && isCortexCompleted && <Icon name="approve"/>}</td>
                    </tr>
                    </tfoot>
                </table>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCompletedRemittance: (billingProfileId) => dispatch(completedRemittance(billingProfileId)),
        onUpdateRemittanceReferenceNumber: (billingProfileId, data) => dispatch(updateRemittanceReferenceNumber(billingProfileId, data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportBankTotalPayoutItems);