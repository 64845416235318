import React, {Component} from 'react';
import {connect} from "react-redux";


import {withRouter} from "react-router-dom";
import {Modal} from "../../../../components/modules/modal";
import {Header} from "../../../../components/elements/header";
import {GridCol, GridRow} from "../../../../components/collections/grid";
import {Button, Buttons} from "../../../../components/elements/button";
import {modalHide} from "../../../services/system/modalService";
import {formSimpleChange} from "../../../services/system/formService";
import {FormattedMessage} from "react-intl";
import {Avatar} from "../../../../components/collections/avatar";
import {joinRoom} from "../../../services/chatService";

class StartChatConsultation extends Component {

    render() {
        const {form} = this.props;
        const {doctorFirstName = "", doctorLastName = "", doctorId, roomId} = form.data.startChatConsultation || {};

        return (
            <Modal width="400" align="center" gradient={true}>
                <Header as="h2"><FormattedMessage id="modal.start.chat.consultation.header"/></Header>
                <GridRow>
                    <GridCol count="1">
                        <div className="avatar-border-2">
                            <div className="avatar-border-1">
                                <Avatar userId={doctorId} doctor/>
                            </div>
                        </div>
                    </GridCol>
                    <GridCol count="1">
                        <FormattedMessage id="core.doctor.name"
                                          values={{firstName: doctorFirstName, lastName: doctorLastName}}/>
                    </GridCol>
                    <GridCol count="1">
                        <Buttons>
                            <Button onClick={() => this.props.onJoinRoom(roomId)}>
                                <FormattedMessage id="core.btn.go.to.chat.label"/>
                            </Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
        onJoinRoom: (roomId) => dispatch(joinRoom(roomId)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StartChatConsultation));