import {privateApi} from '../../utils/api';
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {modalHide} from "./system/modalService";
import {formErrors, formIsSend} from "./system/formService";
import {prepareFilter} from "../../utils/app";
import {listFetchAction} from "../../actions/listAction";
import {fetchBooking} from "./bookingService";
import {fetchPharmacyTodayBookings, fetchPharmacyTodayPatients} from "./pharmacyService";
import {formInitAction} from "../../actions/formAction";
import {tabChangePanelAction} from "../../actions/tabAction";
import {uploadFileChunk} from "./fileService";
import {createRoomConsultationChat} from "./chatService";

export const startChatConsultation = (booking) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        return dispatch(createRoomConsultationChat(booking, () => {
            dispatch(tabChangePanelAction("booking", "chat"));
            dispatch(fetchBooking(booking.id));
        }));
    }
};

export const fetchPharmacies = (pageNumber) => {
    return (dispatch, state) => {
        const filter = prepareFilter(state().list.filters['pharmacies'] || {});
        return privateApi.get("/pharmacies?pageNumber=" + pageNumber + "&search=" + filter)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("pharmacies", content, {...rest}));
            }).catch(error => {
            });
    }
};

export const fetchHistories = (pageNumber) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        const filter = prepareFilter(state().list.filters['bookingHistories'] || {});
        return privateApi.get("/bookings/histories?pageNumber=" + pageNumber + "&search=" + filter)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("bookingHistories", content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const saveDiagnosis = (consultation) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/diagnosis', {diagnosis: consultation.diagnosis})
            .then(response => {
                dispatch(fetchBooking(consultation.booking.id));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("consultation", error.response.data.details));
                }
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const saveDescription = (consultation) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/description', {description: consultation.description})
            .then(response => {
                dispatch(fetchBooking(consultation.booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const saveNote = (consultation) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/note', {note: consultation.note})
            .then(response => {
                dispatch(fetchBooking(consultation.booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const saveMedication = (consultation, medication) => {
    return (dispatch) => {
        dispatch(formIsSend("medication", true));
        return privateApi.post("/consultations/" + consultation.id + '/medications', medication)
            .then(response => {
                dispatch(modalHide('medication'));
                dispatch(formIsSend("medication", false));
                dispatch(fetchBooking(consultation.booking.id));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("medication", error.response.data.details));
                }
                dispatch(formIsSend("medication", false));
            });
    }
};

export const prescribeMedication = (consultation) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/medications/prescribe')
            .then(response => {
                dispatch(fetchBooking(consultation.booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const todayIssueMedication = (booking, medication) => {
    const {consultation} = booking;
    return (dispatch, state) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/medications/' + medication.id + "/issue")
            .then(response => {
                let pagination = state().list.pagination['todayIssuePatients'] || {};
                dispatch(fetchPharmacyTodayPatients(pagination.number + 1));
                pagination = state().list.pagination['patientTodayIssues'] || {};
                return dispatch(fetchPharmacyTodayBookings(booking.patient, pagination.number + 1));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const issueMedication = (booking, medication) => {
    const {consultation} = booking;
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/medications/' + medication.id + "/issue")
            .then(response => {
                dispatch(preloaderPageShowAction());
                return privateApi.get("/bookings/" + booking.id)
                    .then(response => {
                        const {data} = response;
                        return dispatch(formInitAction("prescriptionMedications", data));
                    }).finally(() => {
                        return dispatch(preloaderPageHideAction());
                    });
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};
/*
export const requestMedication = (booking, medication) => {
    const {consultation} = booking;
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/medications/' + medication.id + "/request")
            .then(response => {
                dispatch(preloaderPageShowAction());
                return privateApi.get("/bookings/" + booking.id)
                    .then(response => {
                        const {data} = response;
                        dispatch(formInitAction("prescriptionMedications", data));
                    }).finally(() => {
                        return dispatch(preloaderPageHideAction());
                    });
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};*/

export const acceptMedication = (booking, medication) => {
    const {consultation} = booking;
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/medications/' + medication.id + "/accept")
            .then(response => {
                dispatch(preloaderPageShowAction());
                return privateApi.get("/bookings/" + booking.id)
                    .then(response => {
                        const {data} = response;
                        return dispatch(formInitAction("prescriptionMedications", data));
                    }).finally(() => {
                        return dispatch(preloaderPageHideAction());
                    });
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const completeMedication = (booking, medication) => {
    const {consultation} = booking;
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.post("/consultations/" + consultation.id + '/medications/' + medication.id + "/complete")
            .then(response => {
                dispatch(preloaderPageShowAction());
                return privateApi.get("/bookings/" + booking.id)
                    .then(response => {
                        const {data} = response;
                        dispatch(formInitAction("prescriptionMedications", data));
                    }).finally(() => {
                        return dispatch(preloaderPageHideAction());
                    });
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const removeMedication = (consultation, medication) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/consultations/" + consultation.id + '/medications/' + medication.id)
            .then(response => {
                dispatch(fetchBooking(consultation.booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const saveMedicalTest = (consultation, medicalTest) => {
    return (dispatch) => {
        dispatch(formIsSend("medicalTest", true));
        return privateApi.post("/consultations/" + consultation.id + '/medicalTests', medicalTest)
            .then(response => {
                dispatch(modalHide('medicalTest'));
                dispatch(formIsSend("medicalTest", false));
                dispatch(fetchBooking(consultation.booking.id));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("medicalTest", error.response.data.details));
                }
                dispatch(formIsSend("medicalTest", false));
            });
    }
};

export const saveMedicalTestFileResult = (booking, medicalTest, fileResult) => {
    return (dispatch) => {
        dispatch(
            uploadFileChunk(fileResult,
                () => dispatch(fetchBooking(booking.id)),
                "/v1/consultations/" + booking.consultation.id + '/medicalTests/' + medicalTest.id + '/fileResults')
        );
    }
};

export const removeMedicalTestFileResult = (booking, medicalTest, fileResult) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/consultations/" + booking.consultation.id + '/medicalTests/' + medicalTest.id + '/fileResults/' + fileResult.id)
            .then(response => {
                dispatch(fetchBooking(booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const removeMedicalTest = (consultation, medicalTest) => {
    return (dispatch) => {
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/consultations/" + consultation.id + '/medicalTests/' + medicalTest.id)
            .then(response => {
                dispatch(fetchBooking(consultation.booking.id));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const savePharmacy = (consultation, pharmacy) => {
    return (dispatch) => {
        dispatch(formIsSend("choosePharmacy", true));
        return privateApi.post("/consultations/" + consultation.id + '/pharmacy', pharmacy)
            .then(response => {
                dispatch(modalHide('choosePharmacy'));
                dispatch(formIsSend("choosePharmacy", false));
                dispatch(fetchBooking(consultation.booking.id));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("choosePharmacy", error.response.data.details));
                }
                dispatch(formIsSend("choosePharmacy", false));
            });
    }
};

export const medicationDurationFormat = (repeat, duration) => {
    const singularDurations = {
        DAY: "medication.duration.day.repeat.label",
        WEEK: "medication.duration.week.repeat.label",
        MONTH: "medication.duration.month.repeat.label"
    };
    const pluralDurations = {
        DAY: "medication.duration.days.repeat.label",
        WEEK: "medication.duration.weeks.repeat.label",
        MONTH: "medication.duration.months.repeat.label"
    };
    return parseInt(repeat) === 1 ? singularDurations[duration] : pluralDurations[duration];
}