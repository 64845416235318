import React from 'react';
import {InputError} from "../error";
import classnames from "classnames";
import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../emptyTag";

export const Checkboxes = (prop) => {
    return (
        <div className="form-label-lines">{prop.children}</div>
    );
};

export const Checkbox = (prop) => {
    const {
        name, error = null, isChecked, onChange, disabled, buttonLabel,
        toggleBeforeText = "", toggleAfterText = "", square = false,
        inputChecked, labelFixed, toggle = false, textColor = "", textSize = ""
    } = prop;
    const checkboxClassNames = classnames('form-label', {
        'toggle-label': (toggle == true),
        'button-label': (buttonLabel === 'buttonLabel'),
        'label-fixed': (labelFixed === 'labelFixed'),
        'square-label-text' : square === true
    });
    const inputClassNames = classnames({
        'checked': (inputChecked === true)
    });

    const textClassNames = classnames({
        'color-gray': (textColor === 'gray'),
        'small-text': textSize === 'small'
    });
    return (
        <EmptyTag>
            <label className={checkboxClassNames}>
                <input type="checkbox" className={inputClassNames} name={name} checked={isChecked} disabled={disabled}
                       onChange={onChange}/>
                {toggleBeforeText && <span>
                <i className="small-text color-gray"><FormattedMessage id={toggleBeforeText}/></i></span>}
                <span className={textClassNames}><i>{prop.children}</i></span>
                {error !== null && <InputError>{error}</InputError>}
            </label>
            {toggleAfterText && <span className="form-label-extra-text small-text color-gray">
                <FormattedMessage id={toggleAfterText}/></span>}
        </EmptyTag>
    );
};
