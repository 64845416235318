import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {Header} from "../../../../components/elements/header";
import {Textarea} from "../../../../components/elements/textarea";
import {formChangeField} from "../../../services/system/formService";
import {FormattedMessage} from "react-intl";
import {RadioBox, RadioBoxes} from "../../../../components/elements/radiobox";
import {fetchBookingCancelReasons} from "../../../services/bookingService";

class Confirm extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {booking} = this.props;
        this.props.onFetchBookingCancelReasons(booking);
    }

    onChange(event) {
        this.props.onFormChangeField('confirm', event);
    }

    render() {
        const {form, list, booking} = this.props;
        const {confirm = {}} = form.data;
        const {bookingCancelReasons = []} = list.data;
        const errors = form.errors.confirm;
        return (
            <EmptyTag>
                <GridCol count="1">
                    <Header as="h2" color="red">
                        {booking.status == 'REQUESTED' && <FormattedMessage id="doctor.header.confirm-reason-reject"/>}
                        {booking.status == 'APPROVED' && <FormattedMessage id="doctor.header.confirm-reason-cancel"/>}
                    </Header>
                </GridCol>
                <GridCol count="1">
                    <RadioBoxes error={(errors && errors['reason.id']) || null}>
                        {bookingCancelReasons.map(bookingCancelReason => {
                            const isChecked = confirm && confirm.reason && confirm.reason.id == bookingCancelReason.id;
                            return (
                                <div key={bookingCancelReason.id}>
                                    <RadioBox name='reason.id'
                                              value={bookingCancelReason.id}
                                              disabled={null}
                                              defaultChecked={isChecked}
                                              onChange={this.onChange}>{bookingCancelReason.name}</RadioBox>
                                    {bookingCancelReason.isHasReasonDetail && isChecked && <Textarea name="reasonDetail"
                                                                                                     value={confirm.reasonDetail || ''}
                                                                                                     placeholder={
                                                                                                         <FormattedMessage
                                                                                                             id="notice.doctor.confirm-reason-reject.reason.label"/>}
                                                                                                     onChange={this.onChange}
                                                                                                     error={(errors && errors.reasonDetail) || null}></Textarea>}
                                </div>
                            );
                        })}
                    </RadioBoxes>

                </GridCol>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchBookingCancelReasons: (booking) => dispatch(fetchBookingCancelReasons(booking)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);