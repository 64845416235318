import {privateApi, privateBlobApi} from "../../utils/api";
import {saveAs} from 'file-saver';
import {formChangeFieldAction} from "../../actions/formAction";
import {preloaderPageShowAction, preloaderPageHideAction, preloaderPageTextAction} from "../../actions/preloaderAction";
import {arrayRemoveNullElements} from "../../utils/app";
import {modalHide} from "./system/modalService";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import React from "react";

export const uploadFile = (formName, event) => {
    return (dispatch) => {
        const target = event.target;
        const formData = new FormData();
        formData.append("file", target.files[0], target.files[0].name);
        dispatch(preloaderPageShowAction());
        return privateApi.post("/files/upload", formData)
            .then(response => {
                return dispatch(formChangeFieldAction(formName, target.name, response.data));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const uploadFileChunk = (files, onCallback, url = "/v1/files/upload") => {
    return async (dispatch) => {
        dispatch(modalHide("uploadFileDescription"));
        const chunkSize = 1048576 * 5;
        const file = files[0];
        const totalChunks = Math.ceil(file.size / chunkSize);

        let startChunk = 0;
        let endChunk = chunkSize;
        let fileId = null;
        dispatch(preloaderPageShowAction());
        dispatch(preloaderPageTextAction(<FormattedMessage id="loading.progressbar" values={{percent: 0}}/>));
        for (let chunkNumber = 1; chunkNumber <= totalChunks; chunkNumber++) {
            let chunk = file.slice(startChunk, endChunk);
            const formData = new FormData();
            formData.append("file", chunk, file.name);

            const percent = Math.min((chunkNumber / totalChunks) * 100, 100);
            dispatch(preloaderPageTextAction(
                <FormattedMessage id="loading.progressbar" values={{percent: Math.floor(percent)}}/>));

            await privateApi.post(url, formData, {
                params: {
                    fileInfoId: fileId,
                    chunkNumber: chunkNumber,
                    totalChunks: totalChunks
                }
            }).then(response => {
                startChunk = endChunk;
                endChunk = Math.min(endChunk + chunkSize, file.size);
                fileId = response.data.id;

                if (chunkNumber === totalChunks) {
                    if (onCallback) onCallback(response);
                    dispatch(preloaderPageTextAction(null));
                    return dispatch(preloaderPageHideAction());
                }
            }).catch(error => {
                if (error.response.data)
                    dispatch(noticeShowAction("", error.response.data.message));
                dispatch(preloaderPageTextAction(null));
                dispatch(preloaderPageHideAction());
                chunkNumber = totalChunks + 1;
            })
        }
    }
};

export const uploadBlob = (blob, fileName, onCallback) => {
    return (dispatch) => {
        dispatch(modalHide("uploadFileDescription"));
        const formData = new FormData();
        formData.append("file", blob, fileName);
        dispatch(preloaderPageShowAction());
        return privateApi.post("/files/upload", formData, {headers: {"content-type": blob.type}})
            .then(response => {
                onCallback(response);
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

/*export const getFile = async (file) => {
    const response = await privateBlobApi.get("/files/" + file.id, {responseType: "blob"});
    return response.data;
};*/

export const uploadPhoto = (formName, event) => {
    return (dispatch) => {
        const target = event.target;
        const formData = new FormData();
        formData.append("file", target.files[0], target.files[0].name);
        dispatch(preloaderPageShowAction());
        return privateApi.post("/files/photo/upload", formData)
            .then(response => {
                return dispatch(formChangeFieldAction(formName, target.name, response.data));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const downloadFile = (uploadFile) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateBlobApi.get("/files/" + uploadFile.id + "/download", {responseType: "blob"})
            .then(response => {
                saveAs(response.data, uploadFile.name);
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const removeFile = (formName, fieldName = "") => {
    return (dispatch, state) => {
        dispatch(formChangeFieldAction(formName, fieldName, null));
        const p = fieldName.indexOf("[");
        if (p !== -1) {
            const arrayName = fieldName.substr(0, p);
            let files = state().form.data[formName][arrayName];
            dispatch(formChangeFieldAction(formName, "files", arrayRemoveNullElements(files)));
        }

    }
}