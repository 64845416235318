import {
    EMERGENCY_CALL_INIT, CONSULTATION_CALL_INIT, CALL_HIDE, CALL_SHOW, CALL_SESSION_INIT,
    CALL_CALLING_INIT, CALL_CALLING_PAUSE, CALL_STREAMS_UPDATE, CALL_DESTROY
} from "../constants/actionTypes";

const initialState = {
    sessionHelper: null,
    sessionOptions: {
        apiKey: "",
        sessionId: "",
        token: "",
        room: null
    },
    commonParams: {
        callType: null,
        callIncoming: false,
        patientId: null
    },
    consultationCallParams: {
        booking: null
    },
    calling: {
        objectAudio: null,
        play: false
    },
    streams: [],
    isCall: false,
    isShow: false,
};

const callReducer = (state = initialState, action) => {
    let {calling: {objectAudio = null}} = state;
    const {app_key: apiKey = "", session_id: sessionId = "", token = "", room = null} = action.sessionOptions || {};

    switch (action.type) {
        case CONSULTATION_CALL_INIT:
            return {
                ...state,
                sessionHelper: null,
                sessionOptions: {apiKey: apiKey, sessionId: sessionId, token: token, room : room},
                commonParams: {
                    callType: 'CONSULTATION_CALL',
                    callIncoming: action.commonParams.callIncoming,
                    patientId: action.commonParams.patientId
                },
                consultationCallParams: {...action.consultationCallParams},
                calling: {objectAudio: null, play: false},
                streams: [],
                isShow: true,
                isCall: true
            };
        case EMERGENCY_CALL_INIT:
            return {
                ...state,
                sessionHelper: null,
                sessionOptions: {apiKey: apiKey, sessionId: sessionId, token: token, room : room},
                commonParams: {
                    callType: 'EMERGENCY_CALL',
                    callIncoming: action.commonParams.callIncoming,
                    patientId: action.commonParams.patientId
                },
                consultationCallParams: {},
                calling: {objectAudio: null, play: false},
                streams: [],
                isShow: true,
                isCall: true
            };
        case CALL_CALLING_INIT:
            if (!state.commonParams.callIncoming) {
                const url = "/ui/static/sounds/call.mp3";
                objectAudio = new Audio(url);
                objectAudio.loop = true;
                objectAudio.play();
                return {...state, calling: {objectAudio: objectAudio, play: true}};
            }
            return {...state};
        case CALL_DESTROY:
            if (!state.commonParams.callIncoming && objectAudio) {
                objectAudio.pause();
            }

            if (state.sessionHelper != null) {
                state.sessionHelper.disconnect();
            }

            return {
                ...state,
                sessionHelper: null,
                calling: {objectAudio: null, play: false},
                streams: [],
                isShow: false,
                isCall: false
            };
        case CALL_CALLING_PAUSE:
            if (!state.commonParams.callIncoming && objectAudio) {
                objectAudio.pause();
                return {...state, calling: {objectAudio: objectAudio, play: false}};
            }
            return {...state};
        case CALL_SHOW:
            return {...state, isShow: true};
        case CALL_HIDE:
            return {...state, isShow: false};
        case CALL_SESSION_INIT:
            return {...state, sessionHelper: action.sessionHelper};
        case CALL_STREAMS_UPDATE:
            return {...state, streams: action.streams};
        default:
            return state;
    }
};

export default callReducer;