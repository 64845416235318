import React, {Component} from 'react';
import {connect} from "react-redux";

import {acceptAssistantInvite} from "../../services/doctorService";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {Header} from "../../../components/elements/header";
import {MainLogo} from "../../../components/elements/logo";
import {FormattedMessage} from "react-intl";

class AcceptAssistantInvite extends Component {
    componentDidMount() {
        const {match: {params}} = this.props;
        const {token = ""} = params || {}
        this.props.onAcceptAssistantInvite(token);
    }

    render() {
        const {form: {isSend: formIsSend}} = this.props;
        const isFormSignUp = !formIsSend["acceptAssistantInvite"];
        return (
            <EmptyTag>
                <MainLogo/>
                {!isFormSignUp && <Header as="h2"><FormattedMessage id="accept.assistant.invite.in.progress.text"/></Header>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAcceptAssistantInvite: (token) => dispatch(acceptAssistantInvite(token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptAssistantInvite);