import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import moment from "moment";
import {PaymentProvider} from "../../../../../components/collections/payment_status";

class ReportConsultationItems extends Component {
    render() {
        const {items, paymentCurrency} = this.props;
        return (
            <EmptyTag>
                <table className="admin-table">
                    <thead>
                    <tr>
                        <th><FormattedMessage id="report.patient.name"/></th>
                        <th><FormattedMessage id="report.patient.email"/></th>
                        <th><FormattedMessage id="report.patient.phone"/></th>
                        <th><FormattedMessage id="report.doctor.consulted"/></th>
                        <th><FormattedMessage id="amount" values={{currency : paymentCurrency}}/></th>
                        <th><FormattedMessage id="status"/></th>
                        <th><FormattedMessage id="datetime.consultation"/></th>
                        <th><FormattedMessage id="payment.transaction.number"/></th>
                        <th><FormattedMessage id="payment.method"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(({id, patient, doctor, payment, status, timeOfReceipt}) => {

                        return (
                            <EmptyTag key={id}>
                                <tr>
                                    <td>{patient.firstName} {patient.lastName}</td>
                                    <td>{patient.email}</td>
                                    <td>{patient.phone}</td>
                                    <td>{doctor.firstName} {doctor.lastName}</td>
                                    <td>{payment.amount}</td>
                                    <td>
                                        {status === 'REQUESTED' &&
                                        <div className="color-orange"><FormattedMessage
                                            id="booking.status.requested.label"/></div>}
                                        {status === 'APPROVED' &&
                                        <div className="color-green"><FormattedMessage
                                            id="booking.status.approved.label"/></div>}
                                        {status === 'REJECTED' &&
                                        <div className="color-red"><FormattedMessage
                                            id="booking.status.rejected.label"/></div>}
                                        {status === 'COMPLETED' &&
                                        <div className=""><FormattedMessage
                                            id="booking.status.completed.label"/></div>}
                                    </td>
                                    <td>{moment(timeOfReceipt).format("lll")}</td>
                                    <td>{payment.paymentTransactionNumber}</td>
                                    <td><PaymentProvider payment={payment}/></td>
                                </tr>
                            </EmptyTag>
                        );
                    })}
                    </tbody>
                </table>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportConsultationItems);