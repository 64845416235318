import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import moment from 'moment';
import {Label} from "../../../../../components/elements/label";
import {Paragraph} from "../../../../../components/elements/paragraph";
import classnames from "classnames";
import {FormattedMessage} from "react-intl";
import {getPaymentStatusName} from "../../../../services/bookingPaymentService";
import {modalShow} from "../../../../services/system/modalService";
import {PaymentProvider} from "../../../../../components/collections/payment_status";

class PaymentDetail extends Component {
    render() {
        const {payment, booking} = this.props;
        const {paymentStatus = null} = payment || {};

        const paymentStatusColor = classnames({
            'color-green': paymentStatus == 'COMPLETED' || paymentStatus == 'NOT_VERIFY',
            'color-blue': paymentStatus == 'REFUND',
            'color-orange': paymentStatus == 'PENDING',
            'color-red': paymentStatus == 'DENIED'
        });

        return (
            <EmptyTag>
                <div className="white-block">
                    <h3><FormattedMessage id="booking.payment.detail.header"/></h3>
                    <div className="row form-elements form-bottom-bordered">
                        <div className="col-md-3">
                            <Label fixed={true}><FormattedMessage id="booking.payment.detail.header"/></Label>
                            <Paragraph>
                                <div className={paymentStatusColor}>{getPaymentStatusName(paymentStatus) ||
                                <FormattedMessage id="core.none.label"/>}</div>
                            </Paragraph>
                        </div>
                        {paymentStatus && paymentStatus != 'PENDING' && paymentStatus != 'DENIED' && <EmptyTag>
                            <div className="col-md-3">
                                <Label fixed={true}><FormattedMessage
                                    id="booking.payment.detail.date.label"/></Label>
                                <Paragraph>
                                    {payment.paymentCompletedAt && moment(payment.paymentCompletedAt).format("ll") ||
                                    <FormattedMessage id="core.none.label"/>}
                                </Paragraph>
                            </div>
                            {payment.paymentProviderCode &&
                            <div className="col-md-3">
                                <Label fixed={true}><FormattedMessage
                                    id="booking.payment.detail.pay.types.label"/></Label>
                                <Paragraph>
                                    <PaymentProvider payment={payment}/>
                                </Paragraph>
                            </div>}
                            {/*{payment.paymentReferenceNumber && <div className="col-md-3">
                                <Label fixed={true}><FormattedMessage
                                    id="booking.payment.detail.transaction.number.label"/></Label>
                                <Paragraph>{payment.paymentReferenceNumber}</Paragraph>
                            </div>}*/}

                            {payment.paymentProviderCode == 'HEALTH_INSURANCE' && <div className="col-md-3">
                                <Label fixed={true}><FormattedMessage
                                    id="health.insurance.number.label"/></Label>
                                <Paragraph>{payment.healthInsurancePayment.healthInsuranceNumber}</Paragraph>
                            </div>}

                            {payment.paymentPhoneNumber && payment.paymentProviderCode == 'HEALTH_INSURANCE' &&
                            <div className="col-md-3">
                                <Label fixed={true}><FormattedMessage
                                    id="payment.mobile.number"/></Label>
                                <Paragraph>{payment.paymentPhoneNumber}</Paragraph>
                            </div>}
                            <div className="col-12">
                                <div className="align-right big-text">
                                    <FormattedMessage id="booking.payment.detail.total.label"/>:
                                    {payment.currency} {payment.amount && parseFloat(payment.amount).toFixed(2)}
                                </div>
                            </div>
                        </EmptyTag>}
                    </div>
                    {paymentStatus && paymentStatus == 'REFUND' &&
                    <EmptyTag>
                        <hr className="hr-gray"/>
                        <h3><FormattedMessage id="booking.refund.detail.header"/></h3>
                        <div className="row form-elements">

                           {/* <div className="col-md-6">
                                <label className="field-label label-fixed"><FormattedMessage
                                    id="booking.refund.detail.refund.reference.number.label"/></label>
                                <Paragraph>{payment.refundTransactionNumber}</Paragraph>
                            </div>*/}
                            <div className="col-md-6">
                                <label className="field-label label-fixed"><FormattedMessage
                                    id="booking.refund.detail.date.label"/></label>
                                <Paragraph>{payment.refundCompletedAt && moment(payment.refundCompletedAt).format("ll")}</Paragraph>
                            </div>
                        </div>
                    </EmptyTag>}
                    {booking.status === 'COMPLETED' &&
                    <EmptyTag>
                        <hr className="hr-gray"/>
                        <button type="button" className="btn-text btn-link"
                                onClick={() => this.props.onModalShow("paymentReceipt", {booking: booking})}>
                            <FormattedMessage id="core.btn.receipt.for.consultation.label"/>
                        </button>
                    </EmptyTag>}
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetail);
