import React, {Component} from 'react';
import {connect} from "react-redux";


import {FormattedMessage} from "react-intl";
import EmergencyCalls from "./EmergencyCalls";
import ConsultationCalls from "./ConsultationCalls";
import {tabChangePanelAction} from "../../../../actions/tabAction";
import {listFilterFieldAction, listInitAction} from "../../../../actions/listAction";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Header} from "../../../../components/elements/header";
import {GridCol} from "../../../../components/collections/grid";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {isModal} from "../../../../utils/app";
import VideoPlay from "../../../systems/videoPlay";

class Calls extends Component {

    componentWillUnmount() {
        this.props.onInitList("calls");
    }

    render() {
        const {tab: {data: tabData}, modal} = this.props;
        const {calls: tabCalls = {}} = tabData;
        const {activeTabPanel: callsActiveTabPanel = "consultationCalls"} = tabCalls || {};

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="consultation.recordings"/></Header>
                    </div>
                </div>

                <div className="row content-heading">
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab
                                active={callsActiveTabPanel === 'consultationCalls'}
                                onClick={() => this.props.onChangeTabPanel("consultationCalls")}>
                                <FormattedMessage id="booking.calls"/>
                            </Tab>
                            <Tab active={callsActiveTabPanel === 'emergencyCalls'}
                                 onClick={() => this.props.onChangeTabPanel("emergencyCalls")}>
                                <FormattedMessage id="sessions.emergency.calls"/>
                            </Tab>
                        </Tabs>
                    </GridCol>
                </div>

                {callsActiveTabPanel === 'consultationCalls' && <ConsultationCalls/>}
                {callsActiveTabPanel === 'emergencyCalls' && <EmergencyCalls/>}
                {isModal(modal, 'videoPlay') && <VideoPlay/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("calls", tabPanelName)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("calls", fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Calls);