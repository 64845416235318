import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {MainLogo} from "../../../../components/elements/logo";
import {Header} from "../../../../components/elements/header";
import {acceptReportRecipientInvite} from "../../../services/billingSettingsService";

class AcceptReportRecipientInvite extends Component {
    componentDidMount() {
        const {match: {params}} = this.props;
        const {token = ""} = params || {}
        this.props.onAcceptReportRecipientInvite(token);
    }

    render() {
        const {form: {isSend: formIsSend}} = this.props;
        const isFormSignUp = !formIsSend["acceptReportRecipientsInvite"];
        return (
            <EmptyTag>
                <MainLogo/>
                {!isFormSignUp && <Header as="h2"><FormattedMessage id="accept.billing.report.recipient.invite.in.progress.text"/></Header>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onAcceptReportRecipientInvite: (token) => dispatch(acceptReportRecipientInvite(token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptReportRecipientInvite);