import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../components/collections/grid";
import Calendar from "react-calendar/dist/entry.nostyle";
import moment from "moment";
import {
    asyncFetchDoctorScheduleAvailableAppointmentDays,
    fetchDoctorFreeBusyAppointments,
} from "../../../services/bookingService";
import {formChangeField} from "../../../services/system/formService";
import {RadioBox} from "../../../../components/elements/radiobox";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../../../actions/preloaderAction";
import {Header} from "../../../../components/elements/header";
import {FormattedMessage} from "react-intl";
import {getCalendarLocale} from "../../../../utils/api";

class ScheduleDoctor extends Component {
    constructor(props) {
        super(props);
        this.selectDay = this.selectDay.bind(this);
        this.incrementMonth = this.incrementMonth.bind(this);
        this.decrementMonth = this.decrementMonth.bind(this);
        this.fetchSchedule = this.fetchSchedule.bind(this);
        this.state = {
            activeMonth: new Date().getMonth(),
            activeYear: new Date().getFullYear(),
            chosenDate: new Date(),
            chosenFormattedDate: "",
            appointmentTimesByChosenDate: {},
            appointmentDays: [],
            freeBusyAppointmentsGroupByDate: []

        }
    }

    fetchSchedule(doctor, month, year) {
        const {isReschedule = false, typeConsultation, address} = this.props;
        const dateStart = moment().year(year).month(month - 1).date(1);
        const dateEnd = moment().year(year).month(month - 1)
            .date(1).add(1, "month").add(-1, "day");
        const dateStartFormat = dateStart.format("YYYY-MM-DD");
        const dateEndFormat = dateEnd.format("YYYY-MM-DD");
        return isReschedule ?
            asyncFetchDoctorScheduleAvailableAppointmentDays(doctor, typeConsultation, address, dateStartFormat, dateEndFormat) :
            fetchDoctorFreeBusyAppointments(doctor, month, year);
    }

    componentDidMount() {
        const {doctor, timeOfReceipt = null} = this.props;
        let {activeMonth, activeYear, chosenDate} = this.state;
        if (timeOfReceipt != null) {
            chosenDate = new Date(timeOfReceipt);
            activeMonth = chosenDate.getMonth();
            activeYear = chosenDate.getFullYear();
        }
        (async () => {
            this.props.onPageShowPreloader();
            let freeBusyAppointments = await this.fetchSchedule(doctor, activeMonth + 1, activeYear);
            this.prepareFreeBusyAppointments(freeBusyAppointments);
            this.selectDay(chosenDate)
            this.props.onPageHidePreloader();
        })();
    }

    incrementMonth() {
        const {doctor} = this.props;
        const {activeMonth, activeYear} = this.state;
        const newMonth = activeMonth === 11 ? 0 : activeMonth + 1;
        const newYear = activeMonth === 11 ? activeYear + 1 : activeYear;

        (async () => {
            this.props.onPageShowPreloader();
            const freeBusyAppointments = await this.fetchSchedule(doctor, newMonth + 1, newYear);
            this.setState({activeMonth: newMonth, activeYear: newYear});
            this.prepareFreeBusyAppointments(freeBusyAppointments);
            this.props.onPageHidePreloader();
        })();
    }

    decrementMonth() {
        const {doctor} = this.props;
        const {activeMonth, activeYear} = this.state;
        const newMonth = activeMonth === 0 ? 11 : activeMonth - 1;
        const newYear = activeMonth === 0 ? activeYear - 1 : activeYear;

        const dateStart = moment().set({'year': newYear, 'month': newMonth, 'date': 1});
        const dateEnd = moment().set({'year': newYear, 'month': newMonth, 'date': dateStart.daysInMonth()});

        if (moment().isAfter(dateEnd)) return false;

        (async () => {
            this.props.onPageShowPreloader();
            const freeBusyAppointments = await this.fetchSchedule(doctor, newMonth + 1, newYear);
            this.setState({activeMonth: newMonth, activeYear: newYear});
            this.prepareFreeBusyAppointments(freeBusyAppointments);
            this.props.onPageHidePreloader();
        })();
    }

    prepareFreeBusyAppointments(freeBusyAppointments = []) {
        let freeBusyAppointmentsGroupByDate = [];
        let appointmentDays = [];

        freeBusyAppointments.forEach(({time, state}) => {
            let dateAppointment = moment(time).format('L');
            let timeAppointment = moment(time).format('LT');
            appointmentDays.push(dateAppointment);
            if (!freeBusyAppointmentsGroupByDate[dateAppointment]) freeBusyAppointmentsGroupByDate[dateAppointment] = {};
            freeBusyAppointmentsGroupByDate[dateAppointment][timeAppointment] = {state: state, timestamp: time};
        });

        this.setState({
            freeBusyAppointmentsGroupByDate: freeBusyAppointmentsGroupByDate,
            appointmentTimesByChosenDate: {},
            appointmentDays: appointmentDays
        });
    }

    selectDay(value) {
        const {freeBusyAppointmentsGroupByDate} = this.state;
        const chosenFormattedDate = moment(value).format('L');
        const appointmentTimesByDate = freeBusyAppointmentsGroupByDate[chosenFormattedDate] || {};

        this.setState({
            chosenDate: value,
            chosenFormattedDate: chosenFormattedDate,
            appointmentTimesByChosenDate: appointmentTimesByDate
        });
    }

    render() {
        const {appointmentDays, chosenDate, appointmentTimesByChosenDate} = this.state;
        const {timeOfReceipt} = this.props;
        const NextLabelComponent = () => <div onClick={this.incrementMonth}>&rsaquo;</div>;
        const PrevLabelComponent = () => <div onClick={this.decrementMonth}>&lsaquo;</div>;
        return (
            <GridRow>
                <GridCol count='1'>
                    <Calendar
                        activeStartDate={new Date()}
                        calendarType={"US"}
                        locale={getCalendarLocale()}
                        view="month"
                        name='calendar'
                        minDate={new Date()}
                        minDetail='month'
                        prev2Label={null}
                        next2Label={null}
                        nextLabel={<NextLabelComponent/>}
                        prevLabel={<PrevLabelComponent/>}
                        tileDisabled={({date}) => appointmentDays.findIndex(v => v === moment(date).format('L')) === -1}
                        onClickDay={(value) => this.selectDay(value)}
                        showNeighboringMonth={false}
                        value={chosenDate}
                    />
                </GridCol>
                {Object.keys(appointmentTimesByChosenDate).length > 0 &&
                <GridCol count='1'>
                    <Header as="h3" align="center"><FormattedMessage id="core.time.label" /></Header>
                    {
                        Object.keys(appointmentTimesByChosenDate).map((key) => {
                            const tms = parseInt(appointmentTimesByChosenDate[key].timestamp);
                            const isChecked = timeOfReceipt != null && parseInt(timeOfReceipt) === parseInt(appointmentTimesByChosenDate[key].timestamp);
                            const isDisabled = !isChecked && (appointmentTimesByChosenDate[key].state === "BUSY" || (tms / 1000) < moment().unix());
                            return (<RadioBox asButton={true}
                                              key={key}
                                              name='timeOfReceipt'
                                              onChange={this.props.onChangeTime}
                                              value={tms}
                                              checked={isChecked}
                                              disabled={isDisabled}>{key}</RadioBox>)
                        })
                    }

                </GridCol>
                }
            </GridRow>
        )
    }
}

const mapStateToProps = store => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onPageShowPreloader: () => dispatch(preloaderPageShowAction()),
        onPageHidePreloader: () => dispatch(preloaderPageHideAction())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDoctor);
