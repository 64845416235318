import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import EditDoctorProfile from "../../doctor/profile";
import {noticeShowAction} from "../../../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import {Redirect} from "react-router";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import EditPatientProfile from "../../patient/profile";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {isIncorrectRole: false, role: this.props.role};
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curFormData = {}}} = this.props;
        const {profileAccountEmail: currProfileAccountEmail = {}} = curFormData;
        const {role: currRole = ""} = currProfileAccountEmail;

        const {form: {data: prevFormData = {}}} = prevProps;
        const {profileAccountEmail: prevProfileAccountEmail = {}} = prevFormData;
        const {role: prevRole = ""} = prevProfileAccountEmail;

        if (currRole && currRole !== prevRole && currRole !== this.state.role) {
            this.props.onNoticeShow(<FormattedMessage id="page.access.denied.header"/>);
            this.setState({...this.state, isIncorrectRole: true})
        }
    }

    render() {
        const {match: {params}} = this.props;
        const {isIncorrectRole = false, role} = this.state;
        const {id = ""} = params || {};
        return (
            <EmptyTag>
                {!isIncorrectRole && <EmptyTag>
                    {role === 'DOCTOR' && <EditDoctorProfile userId={id}/>}
                    {role === 'PATIENT' && <EditPatientProfile userId={id}/>}
                </EmptyTag>}
                {isIncorrectRole && <Redirect to={"/admin/users"}/>}
            </EmptyTag>

        )
    }
}

const mapStateToProps = store => {
    return {form: store.form}
};

const mapDispatchToProps = (dispatch) => {
    return {
        onNoticeShow: (text) => dispatch(noticeShowAction("", text)),
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));