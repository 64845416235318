import React from 'react';
import {connect} from "react-redux";

import {MenuItem} from "../../../components/collections/menu";
import 'react-notifications-component/dist/theme.css';
import {FormattedMessage} from "react-intl";
import {MainLogo} from "../../../components/elements/logo";
import AdminBillingMainMenu from "./AdminBillingMainMenu";
import DoctorMainMenu from "./DoctorMainMenu";
import AdminMainMenu from "./AdminMainMenu";
import PatientMainMenu from "./PatientMainMenu";
import AssistantMainMenu from "./AssistantMainMenu";
import PharmacyMainMenu from "./PharmacyMainMenu";
import {Sidebar} from "../../../components/modules/sidebar";
import {logoutUser} from "../../services/userService";

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.userLogout = this.userLogout.bind(this);
    }

    userLogout() {
        this.props.onLogout();
    }
    render() {
        const {auth} = this.props;
        const {role} = auth.user;
        return (
            <Sidebar>
                <MainLogo/>
                <div className="sidebar-menu">
                    {role === 'ADMIN_BILLING' && <AdminBillingMainMenu/>}
                    {role === 'DOCTOR' && <DoctorMainMenu/>}
                    {role === 'ADMIN' && <AdminMainMenu/>}
                    {role === 'PATIENT' && <PatientMainMenu/>}
                    {role === 'ASSISTANT' && <AssistantMainMenu/> }
                    {role === 'PHARMACY' && <PharmacyMainMenu/>}
                </div>
                <div className="sidebar-bottom sidebar-top-border">
                    <div className="logout-block">
                        <MenuItem to={'/'} onClick={() => this.userLogout()} icon="logout">
                            <FormattedMessage id="core.layout.sidebar.item.logout.label"/>
                        </MenuItem>
                    </div>
                </div>
            </Sidebar>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogout: () => dispatch(logoutUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
