import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {EmptyTag} from "../../../../components/elements/emptyTag";

class CountDownTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timerId: null,
            countDown: {
                hours: 0,
                minutes: 0,
                seconds: 0
            }
        }
    }

    prepareTime(number) {
        return number < 10 ? '0' + number : number;
    }

    componentDidMount() {
        const {startTime} = this.props;
        const timerId = setInterval(() => {
            const hours = moment(startTime).diff(moment(), 'hours');
            const minutes = moment(startTime).diff(moment(), 'minutes') % 60;
            const seconds = moment(startTime).diff(moment(), 'seconds') % 60;
            this.setState({
                timerId: timerId,
                countDown: {
                    hours: this.prepareTime(hours),
                    minutes: this.prepareTime(minutes),
                    seconds: this.prepareTime(seconds)
                }
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.state.timerId);
    }

    render() {
        return (
            <EmptyTag>
                {(this.state.countDown.hours > 0 || this.state.countDown.minutes > 0 || this.state.countDown.seconds > 0) &&
                <div className="icon-item timer-item">
                    <i className="icon icon--clock-white"></i>
                    <span className="icon-item__text">
                            {this.state.countDown.hours}:{this.state.countDown.minutes}:{this.state.countDown.seconds}
                        </span>
                </div>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CountDownTimer);
