import React, {Component} from 'react';
import {connect} from "react-redux";
import {EmptyTag} from "../../../components/elements/emptyTag";

class PaymentReports extends Component {

    render() {
        return (
            <EmptyTag>

            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReports);
