import React, {Component} from 'react';
import {Icon} from "../../../components/elements/icon";
import {FormattedMessage} from "react-intl";
import classnames from 'classnames';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: ""
        };
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {value = ""} = this.props;
        this.setState({...this.state, searchText: value});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {value: curValue} = this.props;
        const {value: prevValue} = prevProps;
        if (curValue != prevValue) {
            this.setState({...this.state, searchText: curValue});
        }
    }

    onChange(event) {
        this.setState({searchText: event.target.value});
    }

    onKeyDown(event) {
        const {onSearch} = this.props;
        switch (event.key) {
            case "Enter" :
                onSearch(event.target.value);
                break;
            case "Escape" :
                this.setState({searchText: ""});
                onSearch("");
            default:

        }
    }

    render() {
        const {placeholder = "core.input.search.placeholder", width = 0} = this.props;
        const className = classnames("text-block field-no-label field-height-40 field-bg-white", {
            'width-400': width == 400
        });
        return (
            <div className={className}>
                <Icon name="search"/>
                <FormattedMessage id={placeholder}>{
                    msg => <input type="text" placeholder={msg}
                                  value={this.state.searchText || ''} onChange={this.onChange}
                                  onKeyDown={this.onKeyDown}/>
                }

                </FormattedMessage>
            </div>
        )
    }
}

export default Search;