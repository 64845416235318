import React from 'react';
import {Button} from "../../elements/button";
import {FormattedMessage} from "react-intl";

export const ViaGoogle = (props) => {
    const {name} = props;
    return (
        <FormattedMessage id="core.btn.sign.via.google.title">
            {
                title => <a href={window._env_.BACKEND_URL + '/users/sign/oauth2/google'} title={title}>
                    <Button color="white" icon="google">{name}</Button>
                </a>
            }
        </FormattedMessage>
    );
};