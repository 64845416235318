import React, {Component} from 'react';
import {connect} from "react-redux";

import moment from "moment";

import {FormattedMessage} from "react-intl";
import {fetchBooking} from "../../../services/bookingService";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {Modal} from "../../../../components/modules/modal";
import Tooltip from "../../../systems/tooltip";
import {getPaymentStatusName, refund} from "../../../services/bookingPaymentService";
import classnames from "classnames";
import {formInitAction} from "../../../../actions/formAction";
import {Button} from "../../../../components/elements/button";
import {Header} from "../../../../components/elements/header";
import {PaymentProvider} from "../../../../components/collections/payment_status";

class BookingDetail extends Component {

    componentDidMount() {
        const {form: {data: formData}} = this.props;
        const {bookingDetail: {bookingId}} = formData;
        this.props.onFetchBooking(bookingId);
    }

    componentWillUnmount() {
        this.props.onInitForm('bookingDetail', {});
        this.props.onInitForm('booking', {});
    }

    render() {
        const {form: {data}} = this.props;
        const {booking = {}} = data;
        const {
            doctor = {}, patient = {}, timeOfReceipt, typeConsultation,
            payment = {}, status, statusDetail, bookingCancelReason = null
        } = booking || {};
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        const {paymentStatus = ""} = payment || {};
        const paymentStatusColor = classnames({
            'color-green': paymentStatus === 'COMPLETED' || paymentStatus === 'NOT_VERIFY',
            'color-blue': paymentStatus === 'REFUND',
            'color-orange': paymentStatus === 'PENDING',
            'color-red': paymentStatus === 'DENIED'
        });
        const bookingCancelReasonCode = bookingCancelReason != null ? bookingCancelReason.code : "";

        return (
            <Modal close={true} onClose={() => this.props.onModalHide('bookingDetail')} width={1000}>
                <h2><FormattedMessage id="booking.payment.info"/></h2>

                <div className="row form-elements">

                    <div className="col-md-6">
                        <div className="white-block">
                            <div className="row form-elements form-bottom-bordered">
                                <div className="col-12">
                                    <label className="field-label label-fixed">
                                        <FormattedMessage id="patient"/>
                                    </label>
                                    <div className="text-block">
                                        {patient.firstName} {patient.lastName}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label className="field-label label-fixed">
                                        <FormattedMessage id="core.phone.number.label"/>
                                    </label>
                                    <div className="text-block">
                                        {patient.phone}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="white-block">
                            <div className="row form-elements form-bottom-bordered">
                                <div className="col-12">
                                    <label className="field-label label-fixed">
                                        <FormattedMessage id="core.type.consultation.label"/>
                                    </label>
                                    <div className="text-block">
                                        {typeConsultation === 'CHAT' &&
                                        <FormattedMessage id="core.type.consultation.chat.label"/>}
                                        {typeConsultation === 'VIDEO' &&
                                        <FormattedMessage id="core.type.consultation.video.label"/>}
                                        {typeConsultation === 'AUDIO' &&
                                        <FormattedMessage id="core.type.consultation.audio.label"/>}
                                        {typeConsultation === 'OFFLINE' &&
                                        <FormattedMessage id="core.type.consultation.offline.label"/>}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label className="field-label label-fixed">
                                        <FormattedMessage id="core.datetime.label"/>
                                    </label>
                                    <div className="text-block">
                                        {moment(timeOfReceipt).format("lll")}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label className="field-label label-fixed">
                                        <FormattedMessage id="booking.detail.consultation.status.label"/>
                                    </label>
                                    <div className="text-block">
                                        {status === 'REQUESTED' &&
                                        <FormattedMessage id="booking.status.requested.label"/>}
                                        {status === 'APPROVED' &&
                                        <FormattedMessage id="booking.status.approved.label"/>}
                                        {status === 'REJECTED' &&
                                        <FormattedMessage id="booking.status.rejected.label"/>}
                                        {status === 'COMPLETED' &&
                                        <FormattedMessage id="booking.status.completed.label"/>}
                                        {status === 'REJECTED' && statusDetail &&
                                        <Tooltip><FormattedMessage id="booking.detail.reason"/>{statusDetail}
                                        </Tooltip>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="white-block">
                            <div className="row form-elements form-bottom-bordered">
                                <div className="col-12">
                                    <label className="field-label label-fixed">
                                        <FormattedMessage id="doctor"/>
                                    </label>
                                    <div className="text-block">
                                        {doctor.firstName} {doctor.lastName}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label className="field-label label-fixed">
                                        <FormattedMessage id="core.specialization.label"/>
                                    </label>
                                    <div className="text-block">
                                        {joinSpecializations}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="white-block">
                            <h3 className="align-left">
                                <FormattedMessage id="modal.create.refund.transaction.payment.detail"/>
                            </h3>
                            <table className="simple-table">
                                <tbody>
                                <tr>
                                    <td className="color-gray small-text">
                                        <FormattedMessage id="booking.payment.detail.payment.status.label"/>
                                    </td>
                                    <td className="align-right color-green">
                                        <span className={paymentStatusColor}>{getPaymentStatusName(paymentStatus) ||
                                        <FormattedMessage id="core.none.label"/>}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="color-gray small-text">
                                        <FormattedMessage id="date.payment"/>
                                    </td>
                                    <td className="align-right">
                                        {payment.paymentCompletedAt && moment(payment.paymentCompletedAt).format("ll") ||
                                        <FormattedMessage id="core.none.label"/>}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="color-gray small-text">
                                        <FormattedMessage id="modal.create.refund.transaction.pay.type.label"/>
                                    </td>
                                    <td className="align-right">
                                        <PaymentProvider payment={payment}/>
                                    </td>
                                </tr>
             {/*                   {payment.paymentReferenceNumber &&
                                <tr>
                                    <td className="color-gray small-text">
                                        <FormattedMessage id="booking.payment.detail.transaction.number.label"/>
                                    </td>
                                    <td className="align-right">
                                        {payment.paymentReferenceNumber}
                                    </td>
                                </tr>}*/}
                                {payment.paymentProviderCode == 'HEALTH_INSURANCE' &&
                                    <tr>
                                        <td className="color-gray small-text"><FormattedMessage
                                            id="health.insurance.number.label"/></td>
                                        <td className="align-right">
                                            <div>{payment.healthInsurancePayment.healthInsuranceNumber}</div>
                                        </td>
                                    </tr>}
                                <tr>
                                    <td className="color-gray small-text">
                                        <FormattedMessage id="payment.mobile.number"/>
                                    </td>
                                    <td className="align-right">
                                        {payment.paymentPhoneNumber}
                                    </td>
                                </tr>
                                <tr className="font-size-20 tr-top-bottom-border">
                                    <td>
                                        <FormattedMessage id="booking.payment.detail.total.label"/>
                                    </td>
                                    <td className="align-right">
                                        {payment.currency} {payment.amount && parseFloat(payment.amount).toFixed(2)}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {paymentStatus && paymentStatus == 'REFUND' &&
                    <div className="col-12">
                        <div className="white-block">
                            <Header as="h3" align="left"><FormattedMessage id="booking.refund.detail.header"/></Header>
                            <table className="simple-table">
                                <tbody>
                                <tr>
                                    <td className="color-gray small-text"><FormattedMessage
                                        id="booking.refund.detail.date.label"/></td>
                                    <td className="align-right">
                                        <div>
                                            {(payment.refundCompletedAt &&
                                            moment(payment.refundCompletedAt).format("ll")) ||
                                            <FormattedMessage id="core.none.label"/>}
                                        </div>
                                    </td>
                                </tr>
                                {/*<tr>
                                    <td className="color-gray small-text"><FormattedMessage
                                        id="booking.refund.detail.refund.reference.number.label"/></td>
                                    <td className="align-right">
                                        <div>{payment.refundTransactionNumber}</div>
                                    </td>
                                </tr>*/}
                                </tbody>
                            </table>
                        </div>
                    </div>}

                    <div className="col-12">
                        <div className="form-submit">
                            {status === 'REJECTED' &&
                            bookingCancelReasonCode != 'PATIENT_UNAVAILABLE' &&
                            (paymentStatus === 'COMPLETED' || paymentStatus === 'NOT_VERIFY') &&
                            <Button onClick={() => this.props.onConfirm({
                                text: <FormattedMessage id="refund.payment"/>,
                                onProceed: (() => this.props.onRefund(booking))
                            })}>
                                <FormattedMessage id="refund.payment"/>
                            </Button>}

                         {/*   {paymentStatus === 'REFUND' &&
                            <Button onClick={() => this.props.onModalShow('unverifiablePaymentRefund', {
                                booking: booking,
                                refundTransactionNumber: null,
                                refundCompletedAt: moment().format("DD.MM.YYYY hh:mm:ss"),
                            })}><FormattedMessage id="core.booking.create-refund-transaction"/></Button>}*/}
                        </div>
                    </div>


                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        preloader: store.preloader,
        tab: store.tab
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRefund: (booking) => dispatch(refund(booking)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFetchBooking: (bookingId) => dispatch(fetchBooking(bookingId)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail);
