import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {floatFormatting} from "../../../../../utils/app";
import {FormattedMessage} from "react-intl";
import moment from "moment";

class ReportCortexPayoutItems extends Component {
    render() {
        const {items} = this.props;
        return (
            <EmptyTag>
                <table className="admin-table">
                    <thead>
                    <tr>
                        <th><FormattedMessage id="report.billing.cycle"/></th>
                        <th><FormattedMessage id="report.cost.type" /></th>
                        <th><FormattedMessage id="report.cost.base.value"/></th>
                        <th><FormattedMessage id="report.base.amount.multiple"/></th>
                        <th className="color-blue td-bg-gray"><FormattedMessage id="report.cortex.payout"/></th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(({settingInvoice, totalConsultationNumber, totalPrescriptionNumber, totalCloudStorageUsed, totalCortexPayout}) => {
                        const {
                            billingPeriod, chargeConsultation, chargePrescription,
                            chargeCloudStorage, minimumMaintenance
                        } = settingInvoice;
                        const dateFrom = billingPeriod.dateStart;
                        const dateTo = billingPeriod.dateEnd;
                        const key = moment(dateFrom).format('ll') + "-" + moment(dateTo).format('ll');
                        const chargeOneByte = chargeCloudStorage / (1024 * 1024 * 1024);
                        return (
                            <EmptyTag key={key}>
                                <tr>
                                    <td className="align-center" rowSpan="4">
                                        {dateFrom && moment(dateFrom).format('ll')}&nbsp;-&nbsp;
                                        {dateTo && moment(dateTo).format('ll')}
                                    </td>
                                    <td><FormattedMessage id="report.cost.type.consultations"/></td>
                                    <td>{totalConsultationNumber}</td>
                                    <td>{floatFormatting(chargeConsultation)}</td>
                                    <td className="color-blue td-bg-gray">{floatFormatting(totalConsultationNumber * chargeConsultation)}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="report.cost.type.prescriptions"/></td>
                                    <td>{totalPrescriptionNumber}</td>
                                    <td>{floatFormatting(chargePrescription)}</td>
                                    <td className="color-blue td-bg-gray">{floatFormatting(totalPrescriptionNumber * chargePrescription)}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="report.cost.type.cloud.storage"/></td>
                                    <td>{floatFormatting(totalCloudStorageUsed / (1024 * 1024 * 1024))}</td>
                                    <td>{floatFormatting(chargeCloudStorage)}</td>
                                    <td className="color-blue td-bg-gray">{floatFormatting(totalCloudStorageUsed * chargeOneByte)}</td>
                                </tr>
                                <tr>
                                    <td><FormattedMessage id="report.cost.type.minimum.maintenance"/></td>
                                    <td>{floatFormatting(minimumMaintenance)}</td>
                                    <td>{floatFormatting(minimumMaintenance)}</td>
                                    <td className="color-blue td-bg-gray">{floatFormatting(minimumMaintenance)}</td>
                                </tr>
                            </EmptyTag>
                        );
                    })}
                    </tbody>
                </table>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCortexPayoutItems);