import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {EmptyTag} from "../../../components/elements/emptyTag";

class CountUpTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timerId: null,
            countUp: {
                hours: 0,
                minutes: 0,
                seconds: 0
            }
        }
    }

    prepareTime(number) {
        return number < 10 ? '0' + number : number;
    }

    componentDidMount() {
        const start = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
        const timerId = setInterval(() => {
            const timer = start.add(1, 'second')
            this.setState({
                timerId: timerId,
                countUp: {
                    hours: timer.get('hours'),
                    minutes: timer.get('minutes'),
                    seconds: timer.get('second')
                }
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.state.timerId);
    }

    render() {
        const {countUp: {hours, minutes, seconds}} = this.state;
        return (
            <EmptyTag>
                {this.prepareTime(hours)}:{this.prepareTime(minutes)}:{this.prepareTime(seconds)}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CountUpTimer);
