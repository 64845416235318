import React from 'react';
import classnames from 'classnames';

export const IconText = (props) => <span className="icon-item__text">{props.children}</span>;

export const Icon = (props) => {
    const {name, size = 0, width = 0, height = 0} = props;
    const iconClassNames = classnames('icon', {
        'icon-size-100-per': size === 100,
        'icon-size-40': size === 40,
        'icon-size-24': size === 24,
        'icon-size-10': size === 10,
        'icon-size-50': size === 50,
        'icon-size-16': size === 16,
        'icon-size-74-37': width === 74 && height === 37,
        'icon--payment-mpesa-3': name === 'payment-settings-mpesa' || name === 'payment-mpesa',
        'icon--payment-mon-cash-en': name === 'payment-settings-mon_cash' || name === 'payment-mon_cash',
        'icon--payment-dpo': name === 'payment-settings-pay_gate',
        'icon--billing-profile-black': name === 'billing-profile-black',
        'icon--payments-verified': name === 'payment-pay_gate',
        "icon--payment-health-insurance" : name === 'payment-health_insurance',
        'icon--payment-card-2': name === 'payment-card-2',
        'icon--payment-paypal-2': name === 'payment-paypal-2',
        'icon--payment-visa-2': name === 'payment-visa-2',
        'icon--approve': name === 'approve',
        'icon--payment-xpay': name === 'payment-xpay',
        'icon--payment-pawapay': name === 'payment-pawapay',
        'icon--payment-mpesa-2': name === 'payment-mpesa-2',
        'icon--payment-visa-maestro' : name === 'payment-visa' || name === 'payment-mastercard',
        'icon--payment-mpesa' : name === 'payment-vodafone_mpesa',


        'icon--payment-mtn' : name === 'payment-mtn_momo',
        'icon--payment-airtel' : name === 'payment-airtel_money',
        'icon--payment-orange-money' : name === 'payment-orange_money',

        'icon--print': name === 'print',
        'icon--postcard': name === 'postcard',
        'icon--postcard-white': name === 'postcard-white',
        'icon--user': (name === 'user'),
        'icon--edit': (name === 'edit'),
        'icon--email': (name === 'email'),
        'icon--phone': (name === 'phone'),
        'icon--password': (name === 'password'),
        'icon--users': (name === 'users'),
        'icon--nhs': (name === 'nhs'),
        'icon--date': (name === 'date'),
        'icon--gender': (name === 'gender'),
        'icon--nhs-logo': (name === 'nhs-logo'),
        'icon--home': (name === 'home'),
        'icon--chat': (name === 'chat'),
        'icon--calendar': (name === 'calendar'),
        'icon--settings': (name === 'settings'),
        'icon--logout': (name === 'logout'),
        'icon--player-microphone': (name === 'player-microphone'),
        'icon--call-finish': (name === 'call-finish'),
        'icon--player-video': (name === 'player-video'),
        'icon--phone-white': (name === 'phone-white'),
        'icon--plus': (name === 'plus'),
        'icon--back': (name === 'back'),
        'icon--clock': (name === 'clock'),
        'icon--clock-red': (name === 'clock-red'),
        'icon--placemark-red': (name === 'placemark-red'),
        'icon--webcam-green': (name === 'webcam-green'),
        'icon--cancel-white': (name === 'cancel-white'),
        'icon--completed-white': (name === 'completed-white'),
        'icon--approved-white': (name === 'approved-white'),
        'icon--requested-white': (name === 'requested-white'),
        'icon--rejected-white': (name === 'rejected-white'),
        'icon--patients': (name === 'patients'),
        'icon--patients-new': (name === 'patients-new'),
        'icon--calendar-today': (name === 'calendar-today'),
        'icon--waiting': (name === 'waiting'),
        'icon--edit-green': (name === 'edit-green'),
        'icon--chat-white': (name === 'chat-white'),
        'icon--expand': (name === 'expand'),
        'icon--success': (name === 'success'),
        'icon--send': (name === 'send'),
        'icon--notification': (name === 'notification'),
        'icon--attach': (name === 'attach'),
        'icon--support': (name === 'support'),
        'icon--treatment': (name === 'treatment'),
        'icon--chat-green': (name === 'chat-green'),
        'icon--audio-green': (name === 'audio-green'),
        'icon--record': (name === 'record'),
        'icon--plus-bordered': (name === 'plus-bordered'),
        'icon--points-circle': (name === 'points-green' || name === 'points-circle'),
        'icon--medical-record': (name === 'medical-record'),
        'icon--medical-record-white': (name === 'medical-record-white'),
        'icon--forward': (name === 'forward'),
        'icon--calendar-big': (name === 'calendar-big'),
        'icon--clock-blue': (name === 'clock-blue'),
        'icon--assistant': (name === 'assistant'),
        'icon--price': (name === 'price'),
        'icon--info': (name === 'info'),
        'icon--clock-white': name === 'clock-white',
        'icon--clock-white-red': name === 'clock-white-red',
        'icon--money': name === 'money',
        'icon--doctors': name === 'doctors',
        'icon--provider': name === 'provider',
        'icon--menu-points': name === 'menu-points',
        'icon--calendar-2': name === 'calendar-2',
        'icon--active': name === 'active',
        'icon--block': name === 'block',
        'icon--delete': name === 'delete',
        'icon--search': name === 'search',
        'icon--hospital': name === 'hospital',
        'icon--error': name === 'error',
        'icon--camera': name === 'camera',
        'icon--rec': name === 'rec',
        'icon--play': name === 'play',
        'icon--stop': name === 'stop',
        'icon--repeat-white': name === 'repeat-white',
        'icon--send-white': name === 'send-white',
        'icon--share': name === 'share',
        'icon--copy': name === 'copy',
        'icon--description': name === 'description',
        'icon--prescription': name === 'prescription',
        'icon--advice': name === 'advice',
        'icon--notes': name === 'notes',
        'icon--settings-black': name === 'settings-black',
        "icon--commissions": name === 'commissions',
        'icon--edit-black': name === 'edit-black',
        'icon--lang-en': name === 'lang-en-GB' || name === 'lang-en',
        'icon--lang-fr': name === 'lang-fr-FR',
        'icon--lang-ht': name === 'lang-fr-HT',
        'icon--lang-es': name === 'lang-es-DO',
        'icon--lang-ru': name === 'lang-ru-RU' || name === 'lang-ru',
        'icon--lang-pt': name === 'lang-pt-MO' || name === 'lang-pt-PT',
        "icon--player-microphone-off": name === 'player-microphone-off',
        "icon--player-video-off": name === 'player-video-off',
        "icon--reports": name === 'reports',
        "icon--file-black-pdf": name === 'file-black-pdf',
        "icon--file-black-csv": name === 'file-black-csv',
        "icon--file-black-share": name === 'file-black-share',
        "icon--report": name === 'report',
        "icon--edit-shedule": name === "edit-shedule",
        "icon--payment-options": name === "payment-options",
        "icon--all-consultations": name === "all-consultations",
        "icon--payout": name === "payout",
        "icon--calendar-gray-2": name === "calendar-gray-2",
        "icon--save-blue": name === 'save-blue',
        "icon--file-black-upload": name === 'file-black-upload',
        "icon--payment-medicard": name === 'payment-medi_card' || name === 'payment-settings-medi_card',
        "icon--dashboard": name === 'dashboard',
        "icon--approvals": name === 'approvals',
        "icon--specialization": name === 'specialization',
        "icon--email-receipeints": name === 'email-receipeints',
        "icon--menu-item": name === "menu-item",
        "icon--save-black": name === "save-black",
        "icon--arrow-2-right": name === "arrow-2-right",
        "icon--address-blue": name === 'address-blue',
        "icon--geolocation": name === "geolocation",
        "icon--map": name === "map",
        "icon--trash-black": name === "trash-black",
        "icon--photo-menu": name === "photo-menu",
        "icon--camera-plus": name === "camera-plus",
        "icon--filtr": name === 'filtr',
        "icon--play-white": name === "play-white",
        "icon--recordings": name === "recordings",
        "icon--play-gray": name === "play-gray",
        "icon--detail": name === "detail",
        "icon--refund": name === "refund",
        "icon--transaction-number": name == "transaction-number",
        "icon--bookings-payments": name === "bookings-payments",
        "icon--calendar-add": name === "calendar-add",
        "icon--trash-red": name === "trash-red",
        "icon--calendar-middle": name === "calendar-middle",
        "icon--arrow-left-black": name === "arrow-left-black",
        "icon--arrow-right-black": name === "arrow-right-black",
        "icon--circle-person": name === "circle-person",
        "icon--close-gray": name === "close-gray",
        "icon--address-black": name === "address-black",
        "icon--edit-blue": name === "edit-blue",
        "icon--my-family": name === "my-family",
        "icon--detach": name === "detach",
        "icon--patient-red": name === "patient-red",
        "icon--address": name === "address",
        "icon--info-blue": name === "info-blue",
        "icon--user-circle-blue" : name === "user-circle-blue",
        "icon--manage-users" : name === "manage-users",
        "icon--calendar-google" : name === 'calendar-google',
        "icon--calendar-outlook" : name === 'calendar-outlook',
        "icon--calendar-exclam-black" : name === "calendar-exclam-black",
        "icon--call-outgoing" : name === 'call-outgoing',
        "icon--call-incoming" : name === 'call-incoming',
        "icon--chat-white-filled": name === 'chat-white-filled',
        "icon--close-gray-bold" : name === 'close-gray-bold',
        "icon--arrow-left-gray-bold" : name === 'arrow-left-gray-bold',
        "icon--fullscreen-gray-bold" : name === 'fullscreen-gray-bold',
        "icon--close-white-bold" : name === 'close-white-bold',
        "icon--fullscreen-white-bold" : name === 'fullscreen-white-bold',
        "icon--arrow-left-white-bold" : name === 'arrow-left-white-bold',
        "icon--clipart-chat" : name === 'clipart-chat',
        "icon--download-white" : name === 'download-white',
        "icon--currency-rates" : name === 'currency-rates'

    });
    return (
        <i className={iconClassNames}/>
    );
};

export const IconItem = (props) => {
    const {name, circle = false, size = false} = props;
    const iconClassNames = classnames('icon-item', {
        'icon-circle': circle
    });
    return (
        <div className={iconClassNames}>
            <Icon name={name} size={size}/>
            {props.children && <IconText>{props.children}</IconText>}
        </div>
    );
};