import {Loader} from "@googlemaps/js-api-loader";
import axios from "axios";

export const loadGoogleMap = ({language, apiKey, callback}) => {
    const loader = new Loader({
        apiKey: apiKey,
        version: "weekly",
        language: language
    });
    loader.load().then(() => callback());
};


export const googleGeocode = async (address, key) => {
    const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
            address: address,
            key: key
        }
    });
    return response.data;
};

export const googleReverseGeocode = async ({latitude, longitude, key}) => {
    const response = await axios.get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
            latlng: latitude + "," + longitude,
            key: key
        }
    });
    return response.data;
};

export const googleMapAddMarker = ({map, latitude, longitude, dragEnCallback = null, draggable = true}) => {
    const marker = new window.google.maps.Marker({
        map: map,
        position: {
            lat: latitude,
            lng: longitude
        },
        draggable: draggable,
    });
    if (dragEnCallback)
        window.google.maps.event.addListener(marker, 'dragend', () => dragEnCallback(marker));
    return marker;
};