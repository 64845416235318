import {combineReducers} from 'redux';

import preloader from './preloaderReducer';
import list from './listReducer';
import modal from './modalReducer';
import form from './formReducer';
import auth from './authReduce';
import pagin from "./paginReducer";
import notice from "./noticeReducer";
import call from "./callReducer";
import tab from "./tabReducer";
import bell from "./bellReducer";
import handset from "./handsetReducer";
import browser from "./browserReducer";
import webSocket from "./wsReducer";
import language from "./languageReducer";
import dropdown from "./dropdownReducer";


const rootReducer = combineReducers({
    preloader,
    list,
    auth,
    modal,
    form,
    pagin,
    notice,
    call,
    tab,
    bell,
    handset,
    browser,
    webSocket,
    language,
    dropdown
});

export default rootReducer;
