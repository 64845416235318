import React, {Component} from 'react';
import {LinkItem} from "../../../components/collections/menu";
import classnames from 'classnames';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {withRouter} from "react-router-dom";

export const SubmenuItem = (props) => {
    return (
        <div className="sidebar-submenu__item">
            {props.children}
        </div>
    );
};

class Submenu extends Component {
    render() {
        const {parentLabel, parentIcon, parentUrl, baseUrl} = this.props;
        const {match: {path = ""}} = this.props;
        const isOpen = path.indexOf(baseUrl) !== -1;

        const parentClassNames = classnames('sidebar-menu__item sidebar-menu__item-parent sidebar-menu__item-arrow', {
            'sidebar-menu__item-opened': isOpen,
        });
        return (
            <EmptyTag>
                <div className={parentClassNames}>
                    <LinkItem to={parentUrl} icon={parentIcon} isActive={isOpen}>{parentLabel}</LinkItem>
                </div>

                <div className="sidebar-submenu">
                    {this.props.children}
                </div>
            </EmptyTag>
        )
    }
}

export default withRouter(Submenu);
