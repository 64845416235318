import React, {Component} from 'react';
import {connect} from "react-redux";
import {GridCol, GridRow} from "../../../../components/collections/grid";
import {ButtonWrapper} from "../../../../components/collections/form";
import {isModal} from "../../../../utils/app";
import {modalShow} from "../../../services/system/modalService";
import {Button} from "../../../../components/elements/button";

import {fetchSpecializations} from "../../../services/bookingService";
import {BackTo} from "../../../../components/elements/backTo";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {isFillPatientProfile} from "../../../services/patientService";
import {FormattedMessage} from "react-intl";
import Step1 from "./wizard/Step1";
import {formInitAction} from "../../../../actions/formAction";
import qs from "query-string";
import {fetchDoctorAsync} from "../../../services/doctorService";
import {formSimpleChange} from "../../../services/system/formService";
import Step3 from "../bookAnAppointment/wizard/Step3";
import AppointmentSummary from "../bookAnAppointment/AppointmentSummary";
import VideoRecord from "../bookAnAppointment/VideoRecord";
import VideoPlay from "../../../systems/videoPlay";

class BookAnAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {currentStep: 1, isFillProfile: true};
        this.onNextStep = this.onNextStep.bind(this);
        this.onPrevStep = this.onPrevStep.bind(this);
    }

    componentDidMount() {
        const {auth} = this.props;
        const {role, isFillProfile} = auth.user;
        this.props.onInitForm("advanceParamsCreateBooking", {});

        if (role === 'PATIENT') {
            if (!isFillProfile) {
                this.props.onIsFillPatientProfile();
                this.setState({...this.state, isFillProfile : false});
            }

            const queryString = this.props.location.search;
            const {doctorId = null} = qs.parse(queryString);

            if (doctorId != null) {
                (async() => {
                    await fetchDoctorAsync(doctorId)
                        .then(doctor => {
                            this.props.onFormSimpleChangeField("createBooking", "doctor", doctor);
                        });
                })();
            }
        }
    }

    onNextStep() {
        const {currentStep} = this.state;
        this.setState({...this.state, currentStep: currentStep + 1});
    }

    onPrevStep() {
        const {currentStep} = this.state;
        this.setState({...this.state, currentStep: currentStep - 1});
    }

    componentWillUnmount() {
        this.props.onInitForm("createBooking", {});
    }

    render() {
        const {modal, form: {data: formData}, auth} = this.props;
        const {role, isHasFamily} = auth.user;
        const {currentStep, isFillProfile} = this.state;
        const {doctor = null, timeOfReceipt = null, patient = null} = formData.createBooking || {};
        const isNextDisabled = (currentStep == 1 && doctor == null) || (currentStep == 2 && timeOfReceipt == null);

        if (role == 'PATIENT' && isHasFamily != null && isHasFamily && patient == null && !isModal(modal, 'chooseMember')) {
            this.props.onModalShow("chooseMember", {isQuick: true});
        }

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={"/"}/>
                    </div>
                    <div className="col content-heading__main">
                        <Header as="h2">
                            <FormattedMessage id={"header.create.appointment.step" + currentStep}/>
                        </Header>
                    </div>
                </div>
                <div className="white-block">
                    <div className="row content-row">
                        <GridCol count='1'>
                            <GridRow>
                                {currentStep === 1 && <Step1 globalDisabled={!isFillProfile}/>}
                                {currentStep === 2 && <Step3/>}
                            </GridRow>
                        </GridCol>

                        <GridCol count='1'>
                            <ButtonWrapper>
                                {currentStep < 2 && <Button onClick={() => this.onNextStep()} disabled={isNextDisabled}>
                                    <FormattedMessage id="step.next"/>
                                </Button>}

                                {currentStep == 2 && <Button onClick={() => this.props.onModalShow('appointmentSummary')}>
                                    <FormattedMessage id="core.btn.send.label"/>
                                </Button>}
                            </ButtonWrapper>
                        </GridCol>
                    </div>
                </div>
                {isModal(modal, 'appointmentSummary') && <AppointmentSummary/>}
                {isModal(modal, 'videoRecord') &&
                <VideoRecord
                    onCallbackSend={(response) => this.props.onFormSimpleChangeField('createBooking', "healthConditionVideo", response.data)}/>}
                {isModal(modal, 'videoPlay') && <VideoPlay/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onFetchSpecializations: () => dispatch(fetchSpecializations(1)),
        onIsFillPatientProfile: () => dispatch(isFillPatientProfile()),
        onModalShow: (name, data) => dispatch(modalShow(name, data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookAnAppointment);
