import {callApi} from '../../utils/api';
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {listFetchAction, listIsLoadingDataAction} from "../../actions/listAction";
import {formInit, formSimpleChange} from "./system/formService";
import _ from "lodash";
import {formInitAction} from "../../actions/formAction";
import {modalHide} from "./system/modalService";

export const createRoomConsultationChat = (booking, callback = false) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const data = {bookingId: booking.id, consultationId: booking.consultation.id};
        return callApi.post("/v1/chat/rooms/consultationChat", data)
            .then(response => {
                dispatch(formInit("bookingChosenChatRoom", response.data));
                if (callback)
                    callback();
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const joinRoom = (roomId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return callApi.post("/v1/chat/rooms/" + roomId + "/join")
            .then(response => {
                const {data} = response;
                dispatch(formInitAction('widgetChosenChatRoom', data))
                return dispatch(preloaderPageHideAction());
            }).finally(() => {
                dispatch(modalHide("startChatConsultation"));
                dispatch(formSimpleChange("startChatConsultation", "isStarted", true))
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const getBookingRooms = (booking) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return callApi.get("/v1/chat/rooms?search=type,CONSULTATION;bookingId," + booking.id)
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("bookingChatRooms", data, {}));
                const room = data.length > 0 ? data[0] : {};
                dispatch(formInitAction("bookingChosenChatRoom", room))
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const getMyOpenRooms = (listName="myOpenChatRooms", callback = false) => {
    return (dispatch) => {
        dispatch(listIsLoadingDataAction(listName, true));
        return callApi.get("/v1/chat/rooms?search=isOpen,True")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction(listName, data, {}));
                if (callback)
                    callback(response.data);
            })
            .finally(() => dispatch(listIsLoadingDataAction(listName, false)));
    }
};

export const handlerCloseChat = (bookingId) => {
    return (dispatch, state) => {
        const {
            widgetChosenChatRoom = {},
            bookingChosenChatRoom = {}
        } = state().form.data;
        const {
            consultation_video: widgetConsultationVideo = null,
            consultation_chat: widgetConsultationChat = null
        } = widgetChosenChatRoom;
        const {booking_id: widgetConsultationVideoBookingId = null} = widgetConsultationVideo || {};
        const {booking_id: widgetConsultationChatBookingId = null} = widgetConsultationChat || {};

        if ((widgetConsultationVideoBookingId && widgetConsultationVideoBookingId == bookingId) ||
            (widgetConsultationChatBookingId && widgetConsultationChatBookingId == bookingId)) {
            dispatch(formSimpleChange("widgetChosenChatRoom", "is_open", false));
        }

        const {
            consultation_video: bookingConsultationVideo = null,
            consultation_chat: bookingConsultationChat = null
        } = bookingChosenChatRoom;
        const {booking_id: bookingConsultationVideoBookingId = null} = bookingConsultationVideo || {};
        const {booking_id: bookingConsultationChatBookingId = null} = bookingConsultationChat || {};

        if ((bookingConsultationVideoBookingId && bookingConsultationVideoBookingId == bookingId) ||
            (bookingConsultationChatBookingId && bookingConsultationChatBookingId == bookingId)) {
            dispatch(formSimpleChange("bookingChosenChatRoom", "is_open", false));
        }
    }
};

export const sendMessage = (listNameChatRoomMessages, formNameNewMessage, text, roomId) => {
    if (text == "") return false;
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return callApi.post("/v1/chat/rooms/" + roomId + "/messages", {text: text})
            .then(response => {
                dispatch(formSimpleChange(formNameNewMessage, "text", ""));
                dispatch(showNewMessage('bookingChatRoomMessages', response.data, roomId));
                dispatch(showNewMessage('widgetChatRoomMessages', response.data, roomId));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const showNewMessage = (listNameChatRoomMessages, message, roomId) => {
    return (dispatch, state) => {
        const chatRoomMessages = state().form.data[listNameChatRoomMessages] || {};
        const messages = state().list.data[listNameChatRoomMessages] || [];
        const {content = [], totalElements = 0} = chatRoomMessages;

        if (!chatRoomMessages.roomId || chatRoomMessages.roomId != roomId)
            return;

        messages.unshift(message);
        messages.sort((a, b) => a.id - b.id);
        dispatch(formInitAction(listNameChatRoomMessages, {content: content, totalElements: totalElements + 1, roomId: roomId}));
        dispatch(listFetchAction(listNameChatRoomMessages, messages, {}));
    }
};

export const getMessages = (listName, roomId, afterMessageId = null) => {
    return (dispatch, state) => {
        dispatch(listIsLoadingDataAction(listName, true));
        let params = {};
        if (afterMessageId)
            params["afterMessageId"] = afterMessageId;
        let messages = state().list.data[listName] || [];
        return callApi.get("/v1/chat/rooms/" + roomId + "/messages", {params: params})
            .then(response => {
                const {data} = response;
                dispatch(formInitAction(listName, {...data, afterMessageId: afterMessageId, roomId: roomId}));
                messages = _.concat(data.content, messages);
                messages.sort((a, b) => a.id - b.id);
                dispatch(listFetchAction(listName, messages, {}));
            }).catch(error => {
            }).finally(() => {
                dispatch(listIsLoadingDataAction(listName, false));
            });
    }
};