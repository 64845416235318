import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Header} from "../../../../components/elements/header";
import {Avatar} from "../../../../components/collections/avatar";
import {listInitAction} from "../../../../actions/listAction";
import {
    activateMember,
    deactivateMember,
    fetchMember,
    fetchFamilyMembers,
    detachMember
} from "../../../services/familyService";
import {ButtonIcon} from "../../../../components/elements/button";
import {Icon, IconItem} from "../../../../components/elements/icon";
import Dropdown, {DropdownItem} from "../../../systems/dropdown";
import {modalShow} from "../../../services/system/modalService";
import moment from "moment";
import Tooltip from "../../../systems/tooltip";
import {Redirect} from "react-router";

class MyFamily extends Component {
    constructor(props) {
        super(props);
        this.onEdit = this.onEdit.bind(this);
    }

    componentDidMount() {
        this.props.onFetchFamilyMembers();
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    onEdit(id) {
        this.props.onFetchMember(id, (data) => {
            if (data && data['child'] && data['child']['birthday'])
                data["child"]["birthday"] = moment(data.child.birthday).format("DD-MM-YYYY");
            this.props.onModalShow("createMember", data)
        }, 'createMember');
    }

    render() {
        const {list: {data: listData}, auth: {user}} = this.props;
        if (user.role === 'PATIENT' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }

        const {familyMembers = []} = listData;
        const dropDownButton = <div>
            <Icon name="menu-points"/>
        </div>;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="my.family"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                        <ButtonIcon color={'blue'} onClick={() => this.props.onModalShow("createMember")}>
                            <IconItem name={'plus-bordered'}>
                                <FormattedMessage id="add.family.member"/>
                            </IconItem>
                        </ButtonIcon>
                    </div>
                </div>

                <div className="row content-row">
                    <div className="col-12">
                        <div className="row link-blocks-row">
                            {familyMembers.map(familyMember => {
                                const {id, child, memberType} = familyMember;
                                const {firstName, lastName, isEnabled, isCanSign} = child;
                                return (
                                    <div className="col-md-6" key={id}>
                                        <div className="link-block-wrapper">
                                            <div className="row avatar-row align-items-center">
                                                <div className="col-auto avatar-row__image">
                                                    <Avatar/>
                                                </div>
                                                <div className="col avatar-row__text">
                                                    <div>
                                                        {firstName} {lastName}
                                                        {!isEnabled && <span className="small-text color-red">
                                                            &nbsp;(<FormattedMessage id="deactivated"/>)
                                                        </span>}
                                                    </div>
                                                    <div className="small-text color-gray">{memberType.name}</div>
                                                </div>
                                                {isCanSign &&
                                                <div className="col-auto">
                                                    <Tooltip icon={'user-circle-blue'}>
                                                        <FormattedMessage id="account.user"/>
                                                    </Tooltip>
                                                </div>}
                                                <div className="col-auto relative-controls">
                                                    <Dropdown button={dropDownButton} noArrow="true">
                                                        <DropdownItem to={"#"} icon="edit"
                                                                      onClick={() => this.onEdit(id)}>
                                                            <FormattedMessage id="core.btn.edit.label"/>
                                                        </DropdownItem>
                                                        {isCanSign &&
                                                        <DropdownItem to={"#"}
                                                                      onClick={() => this.props.onConfirm({
                                                                          text: <FormattedMessage
                                                                              id="confirm.detach"/>,
                                                                          onProceed: (() => this.props.onDetachMember(id))
                                                                      })}
                                                                      icon="detach">
                                                            <FormattedMessage id="detach"/>
                                                        </DropdownItem>}
                                                        {!isEnabled && <DropdownItem to={"#"} icon="active"
                                                                                     onClick={() => this.props.onConfirm({
                                                                                         text: <FormattedMessage
                                                                                             id="confirm.activate"/>,
                                                                                         onProceed: (() => this.props.onActivateMember(id))
                                                                                     })}>
                                                            <FormattedMessage id="activate"/>
                                                        </DropdownItem>}
                                                        {isEnabled &&
                                                        <DropdownItem to={"#"} icon="block"
                                                                      onClick={() => this.props.onConfirm({
                                                                          text: <FormattedMessage
                                                                              id="confirm.deactivate"/>,
                                                                          onProceed: (() => this.props.onDeactivateMember(id))
                                                                      })}>
                                                            <FormattedMessage id="deactivate"/>
                                                        </DropdownItem>}
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>
                    </div>
                </div>

            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onInitList: () => dispatch(listInitAction("familyMembers")),
        onFetchFamilyMembers: () => dispatch(fetchFamilyMembers()),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onFetchMember: (id, callback, formName) => dispatch(fetchMember(id, callback, formName)),
        onActivateMember: (id) => dispatch(activateMember(id)),
        onDeactivateMember: (id) => dispatch(deactivateMember(id)),
        onDetachMember: (id) => dispatch(detachMember(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyFamily);
