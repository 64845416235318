import React, {Component} from "react";
import {NoticeHeader} from "../../../components/collections/notice";
import {NOTICE_STATUS} from "../../../constants/system";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {callShowAction} from "../../../actions/callAction";
import {formInitAction} from "../../../actions/formAction";

class BackToCall extends Component {
    constructor(props) {
        super(props);
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        const {call: {sessionOptions}} = this.props;
        const {room} = sessionOptions;
        this.props.onInitForm("widgetChosenChatRoom", room);
        this.props.onCallShow();
    }

    render() {
        const {call: {isShow, isCall}} = this.props;
        if (!isCall || isShow) {
            return "";
        }
        return (
            <NoticeHeader type={NOTICE_STATUS.SUCCESS}>
                <a href={"#"} onClick={() => this.onShow()}>
                    <FormattedMessage id="back.to.call"/>&nbsp;
                    <i className="icon icon--arrow-right-white"></i>
                </a>
            </NoticeHeader>
        )
    }
}

const mapStateToProps = store => {
    return {
        call: store.call
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onCallShow: () => dispatch(callShowAction())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BackToCall);