import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from 'moment';
import classnames from "classnames";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {ScheduleItem} from "./ScheduleItem";
import {listInitAction} from "../../../../actions/listAction";

class ScheduleViewOnWeek extends Component {
    componentWillUnmount() {
        this.props.onInitList("doctorSchedules");
    }

    render() {
        const {list: {data: listData}, detailScheduleKey = null, start, end} = this.props;
        const {doctorSchedules = []} = listData;

        const formatDateStart = start.format('YYYY-MM-DD');
        const formatDateEnd = end.format('YYYY-MM-DD');

        let weekdays = [];
        let momentItemWeekday = moment(formatDateStart);
        let momentEndWeekday = moment(formatDateEnd);
        while (momentItemWeekday.isSameOrBefore(momentEndWeekday)) {
            weekdays.push(momentItemWeekday.format('YYYY-MM-DD'));
            momentItemWeekday = momentItemWeekday.add(1, "day");
        }

        let hours = [];
        for (let hour = 0; hour < 24; hour++)
            hours.push(hour);

        let emptyCells = {};
        weekdays.map(weekday => {
            for (let hour = 0; hour < 24; hour++) {
                const key = moment(weekday, "YYYY-MM-DD").hour(hour).format("YYYY-MM-DD HH:mm");
                emptyCells[key] = null;
            }
        });

        let cells = [];
        doctorSchedules.map(doctorSchedule => {
            const {timeOfReceipt} = doctorSchedule;
            const key = moment(timeOfReceipt).format("YYYY-MM-DD HH:mm");
            cells[key] = doctorSchedule;
        })

        return (
            <div className="calendar-grid">
                <div className="calendar-grid-headline">
                    {weekdays.map(weekday => {
                        let momentWeekday = moment(weekday, "YYYY-MM-DD");
                        const classNames = classnames({
                            'inline-circle bg-color-blue':
                                (momentWeekday.format("D") === moment().format("D"))
                        });
                        return (
                            <div className="c-g-cell" key={momentWeekday.format("ddd")}>
                                <div className="c-g-cell-text">
                                    <div className="bold color-gray">{momentWeekday.format("ddd")}</div>
                                    <span className={classNames}>{momentWeekday.format("D")}</span>
                                </div>
                            </div>
                        )
                    })}

                </div>

                <div className="calendar-grid-time">
                    {hours.map(hour => {
                        const momentHour = moment().hour(hour);
                        return (
                            <div className="c-g-cell" key={"hour-" + hour}>
                                <time className="c-g-cell-text">{momentHour.format("h A")}</time>
                            </div>
                        )
                    })}

                </div>

                <div className="calendar-grid-body">
                    {Object.keys(emptyCells).map(key => {
                        const date = moment(key, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD");
                        const time = moment(key, "YYYY-MM-DD HH:mm").format("HH:mm");
                        return (
                            <div className="c-g-cell" key={key}>
                                {!cells[key] &&
                                <div className="c-g-click-area"
                                     onClick={() => this.props.onApplyAppointmentTemplate(date, time)}></div>}
                            </div>
                        )
                    })}

                    {Object.keys(cells).map(key => {
                        const cell = cells[key];

                        if (cell == null) {
                            return <div className="c-g-cell"></div>
                        }
                        const {timeOfReceipt, endTimeOfReceipt, id} = cell;
                        const duration = moment(endTimeOfReceipt).diff(moment(timeOfReceipt), "minutes");
                        const hour = moment(key, "YYYY-MM-DD HH:mm").format("k");
                        const minute = moment(key, "YYYY-MM-DD HH:mm").format("m");
                        const column = moment(key, "YYYY-MM-DD HH:mm").format("e");
                        const row = hour > 23 ? 1 + Math.round(minute / 2) : hour * 30 + 1 + Math.round(minute / 2);
                        const span = Math.round(duration / 2);
                        const styleCell = {
                            '--column': parseInt(column) + 1,
                            '--row': row + ' / span ' + span
                        };
                        return (
                            <EmptyTag key={key}>
                                {cell &&
                                    <ScheduleItem item={cell}
                                                  detailScheduleKey={detailScheduleKey}
                                                  itemKey={key}
                                                  onCloseDetail={() => this.props.onCloseDetail()}
                                                  onRemoveSchedule={() => this.props.onRemoveSchedule(id)}
                                                  onDetail={() => this.props.onDetail(key)}
                                                  styleCell={styleCell}/>

                                }
                            </EmptyTag>
                        )
                    })}

                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitList: (listName) => dispatch(listInitAction(listName)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleViewOnWeek));
