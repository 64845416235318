import React, {Component} from 'react';
import {connect} from "react-redux";
import {Avatar} from "../../../components/collections/avatar";

class ChatHeader extends Component {
    render() {
        const {form: {data: formData}, formNameChosenChatRoom} = this.props;
        const chatRoom = formData[formNameChosenChatRoom] || {};
        const roomName = Object.keys(chatRoom).length > 0 ?
            chatRoom.participants.map(i => i.user.first_name + ' ' + i.user.last_name).join(", ") : null;
        return (
            <div className="row avatar-row align-items-center">
                {this.props.backward &&
                    <div className="col-auto avatar-row__backward">
                        {this.props.backward}
                    </div>}
                <div className="col-auto avatar-row__image">
                    <Avatar multiple={true}/>
                </div>
                <div className="col avatar-row__text">
                    {roomName && <div>{roomName}</div>}
                </div>
                {this.props.children}
            </div>

        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatHeader);
