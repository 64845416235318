import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {Modal} from "../../../../components/modules/modal";
import {fetchFamilyMembers} from "../../../services/familyService";
import {formInitAction} from "../../../../actions/formAction";
import {ButtonIcon} from "../../../../components/elements/button";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {listFilterFieldAction, listInitAction} from "../../../../actions/listAction";
import {Header} from "../../../../components/elements/header";
import {Avatar} from "../../../../components/collections/avatar";
import {IconItem} from "../../../../components/elements/icon";
import {withRouter} from "react-router-dom";

class ChooseMember extends Component {
    constructor(props) {
        super(props);
        this.onChoose = this.onChoose.bind(this);
    }

    onChoose(child) {
        const {form : {data: formData}} = this.props;
        const {chooseMember = {}} = formData;
        this.props.onInitForm("createBooking", {"patient" : child});
        this.props.onModalHide();
        if (chooseMember.isQuick) {
            this.props.onRedirectToQuickBookAnAppointment();
        } else {
            this.props.onRedirectToBookAnAppointment();
        }
    }

    componentDidMount() {
        this.props.onListFilterField("isEnabledChild", "true");
        this.props.onFetchFamilyMembers();
    }

    render() {
        const {list: {data: listData}, auth: {user}} = this.props;
        const {chooseFamilyMembers:familyMembers = []} = listData;

        return (
            <Modal close={true} onClose={() => this.props.onModalHide()} width={800}>
                <Header as="h2"><FormattedMessage id="select.patient"/></Header>
                <div className="overflow-auto min-height-200 max-height-300">
                    <div className="row link-blocks-row blocks-hover-bg blocks-light-bg">
                        <div className="col-12" >
                            <div className="link-block-wrapper">
                                <a href="#" onClick={() => this.onChoose(user)} className="link-block"></a>
                                <div className="row avatar-row align-items-center">
                                    <div className="col-auto avatar-row__image">
                                        <Avatar/>
                                    </div>
                                    <div className="col avatar-row__text">
                                        <div>{user.firstName} {user.lastName}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {familyMembers.map(familyMember => {
                            const {id, child, memberType} = familyMember;
                            const {firstName, lastName} = child;
                            return (
                                <div className="col-12" key={id}>
                                    <div className="link-block-wrapper">
                                        <a href="#" onClick={() => this.onChoose(child)} className="link-block"></a>
                                        <div className="row avatar-row align-items-center">
                                            <div className="col-auto avatar-row__image">
                                                <Avatar/>
                                            </div>
                                            <div className="col avatar-row__text">
                                                <div>{firstName} {lastName}</div>
                                                <div className="small-text color-gray">{memberType.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="margin-top-15">
                    <ButtonIcon color={'blue'} onClick={() => this.props.onModalHide("chooseMember")&& this.props.onModalShow("createMember")}>
                        <IconItem name={'plus-bordered'}>
                            <FormattedMessage id="add.family.member"/>
                        </IconItem>
                    </ButtonIcon>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onRedirectToBookAnAppointment: () => ownProps.history.push("/bookAnAppointment"),
        onRedirectToQuickBookAnAppointment: () => ownProps.history.push("/quickBooking"),
        onInitList: () => dispatch(listInitAction("chooseFamilyMembers")),
        onFetchFamilyMembers: () => dispatch(fetchFamilyMembers("chooseFamilyMembers")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("chooseFamilyMembers", fieldName, fieldValue)),
        onModalHide: () => dispatch(modalHide('chooseMember')),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseMember));
