import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {EmptyTag} from "../../../components/elements/emptyTag";

import {fetchLastNotifications, removeNotification} from "../../services/notificationService";
import {ButtonRemove} from "../../../components/elements/button";
import {Link} from "react-router-dom";
import {modalShow} from "../../services/system/modalService";
import {listInitAction} from "../../../actions/listAction";
import {FormattedMessage} from "react-intl";

class LastNotifications extends Component {
    componentDidMount() {
        this.props.onInitLastNotifications();
        this.props.onFetchLastNotifications();
    }

    render() {
        const {list: {data}, auth: {user}} = this.props;
        const {lastNotifications: notifications = []} = data;

        const today = moment().format("ll");
        let groupByDateNotifications = {};
        notifications.map(notification => {
            const date = moment(notification.createdAt).format("ll");
            const key = date === today ? "today" : date;
            if (!groupByDateNotifications[key]) groupByDateNotifications[key] = [];
            groupByDateNotifications[key].push(notification);
        });

        return (
            <EmptyTag>
                <h2><FormattedMessage id="page.notification.header"/></h2>
                <div className="white-notifications-wrapper">
                    {Object.keys(groupByDateNotifications).map((date) => {
                        return (
                            <EmptyTag key={date}>
                                <div className="white-notification-date">
                                    <span className="white-notification-date-text">{date == 'today' ?
                                        <FormattedMessage id="core.today.label"/> : date}</span>
                                </div>
                                {groupByDateNotifications[date].map(notification => {
                                    const data = JSON.parse(notification.data);
                                    let linkTo = null;
                                    switch (notification.type) {
                                        case "APPROVED_APPOINTMENT" :
                                            if (user.role == 'PATIENT')
                                                linkTo = "/bookings/" + data['bookId'] + "/detail?reload=" + Math.random();
                                                break;
                                        case "ASK_PAYMENT":
                                        case 'DOCTOR_REJECT_APPOINTMENT' :
                                        case 'DOCTOR_CREATE_APPOINTMENT' :
                                        case 'REQUEST_RESCHEDULING' :
                                            linkTo = "/bookings/" + data['bookId'] + "/detail?reload=" + Math.random();
                                            break;
                                        case 'PRESCRIPTION_READY_TO_ISSUE' :
                                            linkTo = "/patient/treatment/" + data['bookId'] + "/detail?tabName=prescription&reload=" + Math.random();
                                            break;
                                        case 'DOCTOR_COMPLETED_CONSULTATION' :
                                            linkTo = "/patient/treatment/" + data['bookId'] + "/detail?reload=" + Math.random();
                                            break;
                                        case "ASSISTANT_INVITE_ACCEPT":
                                            linkTo = "/doctor/assistants?status=accepted";
                                            break;
                                        case 'PATIENT_REJECT_APPOINTMENT' :
                                        case 'ACCEPT_RESCHEDULING' :
                                        case 'BOOKING_NOT_PAID':
                                        case 'EXTRA_CONSULTATION_REQUEST':
                                        case 'EXTRA_CONSULTATION_APPROVE':
                                        case 'EXTRA_CONSULTATION_CANCEL':
                                        case 'APPOINTMENT_REQUEST' :
                                            switch (user.role) {
                                                case 'ASSISTANT' :
                                                    linkTo = '/assistant/schedule?doctorId=' + data['doctorId'] + '&bookingId=' + data['bookId'] + "&reload=" + Math.random();
                                                    break;
                                                case 'DOCTOR' :
                                                    linkTo = "/doctor/bookings/" + data['bookId'] + "/detail?reload=" + Math.random();
                                                    break;
                                            }
                                            break;
                                        case "APPROVED_DOCTOR_REQUEST_UPDATE_PRICE" :
                                        case "CANCELED_DOCTOR_REQUEST_UPDATE_PRICE" :
                                            linkTo = "/doctor/schedule/configure?reload=" + Math.random();
                                            break;
                                        case "REJECTED_NOT_PAYED_APPOINTMENT" :
                                            linkTo = user.role === 'DOCTOR' ?
                                                "/doctor/bookings/" + data['bookId'] + "/detail?reload=" + Math.random() : "/bookings/" + data['bookId'] + "/detail?reload=" + Math.random();
                                            break;
                                    }

                                    return (
                                        <div className="white-notification-item" key={notification.id}>
                                            <div className="row white-notification-row align-items-center">
                                                <div className="col">
                                                    {linkTo && <Link to={linkTo}>{notification.text}</Link>}
                                                    {!linkTo && notification.text}
                                                </div>
                                                <div className="col-auto">
                                                    <div className="small-text color-gray">
                                                        {moment(notification.createdAt).format("h:mm a")}
                                                    </div>
                                                </div>
                                                <div className="col-auto white-notification-controls">
                                                    <ButtonRemove onClick={() => this.props.onConfirm({
                                                        text: <FormattedMessage id="confirm.notification.remove.text"
                                                                                values={{text: notification.text}}/>,
                                                        onProceed: (() => this.props.onRemoveNotification(notification))
                                                    })}/>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                            </EmptyTag>
                        );
                    })}

                    {Object.keys(groupByDateNotifications).length == 0 &&
                    <div className="white-notification-item-last">
                        <FormattedMessage id="notifications.list.empty"/>
                    </div>}

                    <div className="white-notification-item-last">
                        <Link to={"/notifications"} className="link-icon color-blue">
                            <div className="icon-item">
                                <span className="icon-item__text"><FormattedMessage
                                    id="core.btn.view.all.label"/></span>
                                <i className="icon icon--forward"></i>
                            </div>
                        </Link>
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchLastNotifications: () => dispatch(fetchLastNotifications()),
        onRemoveNotification: (notification) => dispatch(removeNotification(notification)),
        onInitLastNotifications: () => dispatch(listInitAction("lastNotifications"))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LastNotifications);
