import React, {Component} from 'react';
import {connect} from "react-redux";
import {Modal} from "../../../../../components/modules/modal";
import {modalHide} from "../../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import {Header} from "../../../../../components/elements/header";
import {Button, Buttons} from "../../../../../components/elements/button";
import {RadioBoxSimple} from "../../../../../components/elements/radiobox";
import {IconItem} from "../../../../../components/elements/icon";
import {formChangeFieldAction} from "../../../../../actions/formAction";
import {withRouter} from "react-router-dom";
import {
    createCalendarAuthorizationUrl
} from "../../../../services/AccountCalendarsService";

class SelectProvider extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onContinue = this.onContinue.bind(this);
    }

    onChange(event) {
        this.props.onFormSimpleChange(event.target.name, event.target.value);
    }

    onContinue(type = "") {
        this.props.onCreateCalendarAuthorizationUrl(type.toLowerCase());
    }

    render() {
        const {form: {data: formData}} = this.props;
        const {selectProvider = {}} = formData;
        const {type = null} = selectProvider;
        return (
            <Modal close={true} onClose={() => this.props.onModalHide()}>
                <Header as="h2">
                    <FormattedMessage id="select.provider"/>
                </Header>
                <div className="row form-elements">
                    <div className="col-12">
                        <div className="row cols-padding-bottom-15">
                            <div className="col-12">
                                <RadioBoxSimple
                                    name="type"
                                    value='GOOGLE'
                                    checked={type == 'GOOGLE'}
                                    onChange={this.onChange}>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <IconItem name={'calendar-google'} size={50}>
                                                <FormattedMessage id="google"/>
                                            </IconItem>
                                        </div>
                                    </div>
                                </RadioBoxSimple>
                            </div>
                            <div className="col-12">
                                <RadioBoxSimple
                                    name="type"
                                    value='OUTLOOK'
                                    checked={type == 'OUTLOOK'}
                                    onChange={this.onChange}>
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <IconItem name={'calendar-outlook'} size={50}>
                                                <FormattedMessage id="outlook"/>
                                            </IconItem>
                                        </div>
                                    </div>
                                </RadioBoxSimple>
                            </div>
                        </div>
                    </div>

                    <div className="col-12">
                        <Buttons width="auto">
                            <Button
                                color="white-red"
                                onClick={() => this.props.onModalHide()}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button disabled={type == null}
                                    onClick={() => this.onContinue(type)}>
                                <FormattedMessage id="core.btn.continue.label"/>
                            </Button>
                        </Buttons>
                    </div>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal,
        form: store.form,
        list: store.list,
        language: store.language
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCreateCalendarAuthorizationUrl: (type) => dispatch(createCalendarAuthorizationUrl(type, ownProps)),
        onModalHide: () => dispatch(modalHide('selectProvider')),
        onFormSimpleChange: (fieldName, fieldValue) => dispatch(formChangeFieldAction('selectProvider', fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectProvider));
