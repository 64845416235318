import React, {Component} from 'react';
import {connect} from "react-redux";
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {IconItem} from "../../../../components/elements/icon";
import {listFilterAction, listFilterFieldAction, listInitAction} from "../../../../actions/listAction";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";
import {formInitAction} from "../../../../actions/formAction";
import {withRouter} from 'react-router-dom';
import {fetchCurrencyRates, saveCurrencyRates} from "../../../services/currencyService";
import moment from "moment";
import {DatePicter} from "../../../../components/elements/datepicter";
import {Input} from "../../../../components/elements/input";
import {formSimpleChange} from "../../../services/system/formService";
import {Button} from "../../../../components/elements/button";
import {stringToFloat} from "../../../../utils/app";

class CurrencyRates extends Component {
    constructor(props) {
        super(props);
        this.onChangeDateOfRate = this.onChangeDateOfRate.bind(this);
        this.onChangeRate = this.onChangeRate.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onChangeFloat = this.onChangeFloat.bind(this);
        this.state = {choseDateOfRateMomentObj: null, isEdit: false}
    }

    componentDidMount() {
        this.onChangeDateOfRate(moment());
    }

    componentWillUnmount() {
        this.props.onInitList("currencyRates");
        this.props.onInitForm("updateCurrencyRates", {});
    }

    onChangeDateOfRate(momentObj) {
        this.setState({...this.state, choseDateOfRateMomentObj: momentObj});
        this.props.onFetchCurrencyRates(momentObj.format("YYYY-MM-DD"));
    }

    onEdit() {
        const {list: {data: listData}} = this.props;
        const {currencyRates = []} = listData;
        this.setState({...this.state, isEdit: true});
        currencyRates.map(
            ({currency, rate}) => this.props.onFormSimpleChangeField("updateCurrencyRates", currency.iso, rate));
    }

    onChangeFloat(currencyIso, event) {
        const target = event.target;
        let value = stringToFloat(target.value, 4);
        this.props.onFormSimpleChangeField("updateCurrencyRates", currencyIso, value);
    }

    onSave() {
        const {form: {data: formData}} = this.props;
        const {updateCurrencyRates = {}} = formData;
        this.props.onSaveCurrencyRates(this.state.choseDateOfRateMomentObj.format("YYYY-MM-DD"), updateCurrencyRates);
        this.setState({...this.state, isEdit: false});
    }

    onChangeRate(currencyIso, event) {
        this.props.onFormSimpleChangeField("updateCurrencyRates", currencyIso, event.target.value);
    }

    render() {
        const {list: {data: listData}, form: {data: formData}, language} = this.props;
        const {currencyRates = []} = listData;
        const {updateCurrencyRates = {}} = formData;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"> <FormattedMessage id="manage.users"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                    </div>
                </div>
                <div className="row form-elements align-items-center filtr-form">
                    <div className="col-auto">
                        <DatePicter no_label={true}
                                    bg_white={true}
                                    locale={language.locale}
                                    height={40}
                                    width={180}
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    value={this.state.choseDateOfRateMomentObj && this.state.choseDateOfRateMomentObj.format('ll')}
                                    onChange={(v) => this.onChangeDateOfRate(v)}
                                    dateFormat={'ll'}/>
                    </div>
                    <div className="col col-empty">
                    </div>
                    <div className="col-auto">
                        {this.state.isEdit &&
                            <Button onClick={() => this.onSave()}>
                                <FormattedMessage id="core.btn.save.label"/>
                            </Button>}

                        {!this.state.isEdit &&
                            <a href="#" onClick={() => this.onEdit()}
                               className="btn-icon color-blue">
                                <IconItem name="plus-bordered"><FormattedMessage id="core.btn.edit.label"/></IconItem>
                            </a>}
                    </div>
                </div>

                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th><FormattedMessage id="currency.name"/></th>
                            <th><FormattedMessage id="for.usd.1"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {currencyRates.map(currencyRate => {
                            return (
                                <tr key={currencyRate.currency.iso}>
                                    <td>{currencyRate.currency.name}</td>
                                    <td>
                                        {!this.state.isEdit && currencyRate.rate}
                                        {this.state.isEdit && <Input simple
                                                                     onBlur={(event) => this.onChangeFloat(currencyRate.currency.iso, event)}
                                                                     value={updateCurrencyRates[currencyRate.currency.iso] || ''}
                                                                     onChange={(event) => this.onChangeRate(currencyRate.currency.iso, event)}/>}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal,
        language: store.language
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitFilter: (filters) => dispatch(listFilterAction('currencyRates', filters)),
        onModalShow: (name) => dispatch(modalShow(name)),
        onFetchCurrencyRates: (dateOfRate) => dispatch(fetchCurrencyRates(dateOfRate)),
        onSaveCurrencyRates: (dateOfRate, newRates) => dispatch(saveCurrencyRates(dateOfRate, newRates)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("currencyRates", fieldName, fieldValue)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CurrencyRates));