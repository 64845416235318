import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Input} from "../../../../components/elements/input";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {formChangeField} from "../../../services/system/formService";
import { stringToInt} from "../../../../utils/app";
import {fetchSystemSettings} from "../../../services/commonService";
import {formChangeFieldAction} from "../../../../actions/formAction";
import {Button, ButtonIcon} from "../../../../components/elements/button";
import {FormattedMessage} from "react-intl";

import {modalShow} from "../../../services/system/modalService";
import {noticeShowAction} from "../../../../actions/noticeAction";
import Dropdown, {DropdownWrapperItem} from "../../../systems/dropdown";
import {IconItem} from "../../../../components/elements/icon";
import {File} from "../../../../components/elements/file";
import {uploadPhoto} from "../../../services/fileService";
import {AvatarEdit} from "../../../../components/collections/avatar";
import {dropdownNotVisibleAction, dropdownVisibleAction} from "../../../../actions/dropdownAction";
import {fetchProfileGeneral, updateProfileGeneral} from "../../../services/profileService";

class ProfileGeneral extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeInteger = this.onChangeInteger.bind(this);
        this.onUploadPhoto = this.onUploadPhoto.bind(this);
        this.onRemovePhoto = this.onRemovePhoto.bind(this);
    }

    componentDidMount() {
        this.props.onFetchProfileGeneral();
        this.props.onfetchSystemSettings();
    }

    onRemovePhoto = () => {
        this.props.onFormSimpleChange("profile", "photo", null);
    }

    onUploadPhoto(event) {
        this.props.onDropdownNotVisibleAction();
        this.props.onUploadPhoto("profile", event);
    }

    onChangeInteger(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChange("profile", target.name, stringToInt(value));
    }

    onChange(event) {
        this.props.onFormChangeField('profile', event);
    }

    render() {
        const {form} = this.props;
        const errors = form.errors.profile;
        const disabled = form.isSend.profile || false;
        const profile = form.data.profile || {};

        const currentYear = moment().format("YYYY");
        let practiceSinceOptions = [];
        if (profile && profile.birthday && moment(profile.birthday).isValid()) {
            const yearStart = moment(profile.birthday).format("YYYY");
            for (let year = yearStart; year <= currentYear; year++) practiceSinceOptions.push(year);
        }

        const dropdownButton = <ButtonIcon icon="photo-menu" iconSize={40}/>
        const dropdownPhoto = <div className="avatar-dropdown">
            <Dropdown button={dropdownButton}>
                <DropdownWrapperItem onClick={() => this.props.onDropdownVisibleAction()}>
                    <File icon={"camera-plus"} name="photo" onChange={this.onUploadPhoto}>
                        <FormattedMessage id="upload"/>
                    </File>
                </DropdownWrapperItem>
                <DropdownWrapperItem>
                    <ButtonIcon onClick={this.onRemovePhoto}>
                        <IconItem name="trash-black">
                            <FormattedMessage id="core.delete.label"/>
                        </IconItem>
                    </ButtonIcon>
                </DropdownWrapperItem>
            </Dropdown>
        </div>;
        return (
            <EmptyTag>
                <div className="row content-row">
                    <GridCol count="1">
                        <div className="white-block">
                            <div className="row form-elements">
                                <div className="col-12 align-center">
                                    <AvatarEdit doctor bytes={profile && profile.photo && profile.photo.bytes}>
                                        {dropdownPhoto}
                                    </AvatarEdit>
                                </div>

                                <GridCol count="2">
                                    <Input name="firstName"
                                           icon="user"
                                           value={profile.firstName || ''}
                                           error={(errors && errors.firstName) || null}
                                           placeholder={<FormattedMessage id="core.first.name.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                                <GridCol count="2">
                                    <Input name="lastName"
                                           icon="user"
                                           value={profile.lastName || ''}
                                           error={(errors && errors.lastName) || null}
                                           placeholder={<FormattedMessage id="core.last.name.label"/>}
                                           disabled={disabled}
                                           onChange={this.onChange}/>
                                </GridCol>
                            </div>
                        </div>
                    </GridCol>

                </div>
                <div className="form-submit margin-top-15">
                    <Button
                        disabled={disabled} preloader={disabled === true}
                        onClick={() => this.props.onUpdateProfileGeneral(profile)}><FormattedMessage
                        id="core.btn.save.label"/></Button>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onDropdownNotVisibleAction: () => dispatch(dropdownNotVisibleAction()),
        onDropdownVisibleAction: () => dispatch(dropdownVisibleAction()),
        onUploadPhoto: (formName, event) => dispatch(uploadPhoto(formName, event)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onFetchProfileGeneral: () => dispatch(fetchProfileGeneral()),
        onUpdateProfileGeneral: (data) => dispatch(updateProfileGeneral(data)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('profile', fieldName, fieldValue)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onShowNotice: (textMsg) => dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, textMsg)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileGeneral);
