import React from 'react';
import classnames from "classnames";
import {InputError} from "../error";
import {Label} from "../label";
import {EmptyTag} from "../emptyTag";
import DateTime from "react-datetime";

export const DatePicter = (prop) => {
    const {isNotChangeInput = false, placeholderFixed = false, placeholder = null, disabled = false, error = null, width = 0, height = 0, no_label = false, bg_white = false,  ...rest} = prop;
    const blockClass = classnames('text-block datepicker-block', {
        'block-error': (error !== null),
        'field-no-label' : no_label === true,
        'field-height-40' : height === 40,
        'field-bg-white' : bg_white === true,
        'width-180' : width === 180
    });
    const inputProps = {disabled : disabled}

    const renderInput = (props, openCalendar, closeCalendar ) => {
        return (
            <EmptyTag>
                {isNotChangeInput && <input {...props} placeholder={placeholder} autoComplete="off" name="datepicter" onChange={() => {}}/>}
                {!isNotChangeInput && <input {...props} placeholder={placeholder} autoComplete="off" name="datepicter"/>}
                <Label fixed={placeholderFixed}>{placeholder}</Label>
            </EmptyTag>
        );
    };
    return (
        <EmptyTag>
            <div className={blockClass}>
                <DateTime {...rest} inputProps={inputProps} renderInput={renderInput}/>
            </div>
            {error !== null && <InputError>{error}</InputError>}
        </EmptyTag>
    );
};