import {privateApi, publicApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";
import {formInitAction} from "../../actions/formAction";
import {noticeShowAction} from "../../actions/noticeAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {updateProfileAction} from "../../actions/authAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {chooseLanguageAction} from "../../actions/languageAction";
import moment from "moment";
import {modalHide} from "./system/modalService";
import {formErrors} from "./system/formService";

export const fetchSystemSettings = () => {
    return (dispatch) => {
        return privateApi.get("/systemSettings")
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("systemSettings", data));
            }).finally(() => {
            });
    }
};

export const fetchLanguages = () => {
    return (dispatch) => {
        return publicApi.get("/languages")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("languages", data, null));
            }).finally(() => {
            });
    }
};

export const fetchCountries = () => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return publicApi.get("/countries")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("countries", data, null));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchMobileNetworkOperators = (countryId) => {
    return (dispatch) => {
        return publicApi.get("/countries/" + countryId + "/mobileNetworkOperators")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("mobileNetworkOperators", data, null));
            }).finally(() => {
            });
    }
};

export const createTicketSupport = (data) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/tickets", data)
            .then(response => {
                dispatch(modalHide("createTicketSupport"));
                return dispatch(noticeShowAction("", <FormattedMessage id="message.success.send"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createTicketSupport", error.response.data.details));
                }
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const updateUserSettings = (userSettings) => {
    return (dispatch, state) => {
        const user = state().auth.user;
        dispatch(preloaderPageShowAction());
        return privateApi.put("/users/settings", userSettings)
            .then(response => {
                dispatch(updateProfileAction({...user, ...userSettings}));
                dispatch(changeLanguage(userSettings.language.locale || ""));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const updatePaymentMerchantNumbers = (data) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.put("/paymentMethods/merchantNumber", data)
            .then(response => {
                /*dispatch(fetchBookingPaymentMethods());*/
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.default.msg.save.success.text"/>));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const changeLanguage = (languageLocale) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        publicApi.get("/languages").then(response => {
            const languages = response.data || [];
            const isNotExistLocale = languages.findIndex(i => i.locale === languageLocale) === -1;
            if (isNotExistLocale) {
                languageLocale = languages.filter(i => i.isDefault != null && i.isDefault)[0].locale || "";
            }

            localStorage.setItem("language", languageLocale);
            moment.locale(languageLocale);
            dispatch(chooseLanguageAction(languageLocale));

        }).finally(() => {
            dispatch(preloaderPageHideAction());
        });

    }
}

export const defineSystemLanguageUser = (user) => {
    return (dispatch) => {
        if (user && user.language) {
            const locale = user.language.locale;
            dispatch(changeLanguage(locale));
        } else {
            dispatch(defineSystemLanguage());
        }
    }
}

export const defineSystemLanguage = () => {
    return (dispatch) => {
        let locale = "";
        if (localStorage.getItem("language") != null && localStorage.getItem("language") != "") {
            locale = localStorage.getItem("language");
        } else {
            locale = navigator.languages && navigator.languages[0] || navigator.language || navigator.userLanguage;
        }

        dispatch(changeLanguage(locale));
    }
}
