import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Header} from "../../../components/elements/header";
import {EmptyTag} from "../../../components/elements/emptyTag";
import Pagination from "react-js-pagination";

import {modalShow} from "../../services/system/modalService";
import {fetchNotifications, removeNotification} from "../../services/notificationService";
import {ButtonRemove} from "../../../components/elements/button";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";

class Notifications extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        this.props.onFetchNotifications(pageNumber);
    }

    render() {
        const {list: {data, pagination}, auth: {user}} = this.props;
        const {notifications = []} = data;
        const {number = 0, size = 0, totalElements = 0} = pagination['notifications'] || {};

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="page.notification.header"/></Header>
                    </div>
                </div>

                <div className="white-notifications-wrapper">
                    {notifications.map(notification => {
                            const data = JSON.parse(notification.data);
                            let linkTo = null;
                            switch (notification.type) {
                                case "APPROVED_APPOINTMENT" :
                                    if (user.role == 'PATIENT')
                                        linkTo = "/bookings/" + data['bookId'] + "/detail?reload=" + Math.random();
                                        break;
                                case "ASK_PAYMENT":
                                case 'DOCTOR_REJECT_APPOINTMENT' :
                                case 'DOCTOR_CREATE_APPOINTMENT' :
                                case 'REQUEST_RESCHEDULING' :
                                    linkTo = "/bookings/" + data['bookId'] + "/detail?reload=" + Math.random();
                                    break;
                                case 'PRESCRIPTION_READY_TO_ISSUE' :
                                    linkTo = "/patient/treatment/" + data['bookId'] + "/detail?tabName=prescription&reload=" + Math.random();
                                    break;
                                case 'DOCTOR_COMPLETED_CONSULTATION' :
                                    linkTo = "/patient/treatment/" + data['bookId'] + "/detail?reload=" + Math.random();
                                    break;
                                case "ASSISTANT_INVITE_ACCEPT":
                                    linkTo = "/doctor/assistants?status=accepted";
                                    break;
                                case 'PATIENT_REJECT_APPOINTMENT' :
                                case 'ACCEPT_RESCHEDULING' :
                                case 'BOOKING_NOT_PAID':
                                case 'EXTRA_CONSULTATION_REQUEST':
                                case 'EXTRA_CONSULTATION_APPROVE':
                                case 'EXTRA_CONSULTATION_CANCEL':
                                case 'APPOINTMENT_REQUEST' :
                                    switch (user.role) {
                                        case 'ASSISTANT' :
                                            linkTo = '/assistant/schedule?doctorId=' + data['doctorId'] + '&bookingId=' + data['bookId'] + "&reload=" + Math.random();
                                            break;
                                        case 'DOCTOR' :
                                            linkTo = "/doctor/bookings/" + data['bookId'] + "/detail?reload=" + Math.random();
                                            break;
                                    }
                                    break;
                                case "REJECTED_NOT_PAYED_APPOINTMENT" :
                                    linkTo = user.role === 'DOCTOR' ?
                                        "/doctor/bookings/" + data['bookId'] + "/detail?reload=" + Math.random() : "/bookings/" + data['bookId'] + "/detail?reload=" + Math.random();
                                    break;
                                case "APPROVED_DOCTOR_REQUEST_UPDATE_PRICE" :
                                case "CANCELED_DOCTOR_REQUEST_UPDATE_PRICE" :
                                    linkTo = "/doctor/schedule/configure?reload=" + Math.random();
                                    break;
                            }

                            return (
                                <div className="white-notification-item" key={notification.id}>
                                    <div className="row white-notification-row align-items-center">
                                        <div className="col">
                                            {linkTo && <Link to={linkTo}>{notification.text}</Link>}
                                            {!linkTo && notification.text}
                                        </div>
                                        <div className="col-auto">
                                            <div className="small-text color-gray">
                                                {moment(notification.createdAt).format("lll")}
                                            </div>
                                        </div>
                                        <div className="col-auto white-notification-controls">
                                            <ButtonRemove onClick={() => this.props.onConfirm({
                                                text: <FormattedMessage id="confirm.notification.remove.text"
                                                                        values={{text: notification.text}}/>,
                                                onProceed: (() => this.props.onRemoveNotification(notification))
                                            })}/>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                    <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}
                    </div>
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchNotifications: (pageNumber) => dispatch(fetchNotifications(pageNumber)),
        onRemoveNotification: (notification) => dispatch(removeNotification(notification))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
