import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {listInitAction} from "../../../actions/listAction";
import {getMyOpenRooms} from "../../services/chatService";
import moment from "moment";
import {Avatar} from "../../../components/collections/avatar";
import {ButtonIcon} from "../../../components/elements/button";
import {formInitAction} from "../../../actions/formAction";
import {EmptyTag} from "../../../components/elements/emptyTag";
import {ChatMessage} from "./ChatMessage";

class ChatWidgetRooms extends Component {
    constructor(props) {
        super(props);
        this.onChoose = this.onChoose.bind(this);
    }

    componentDidMount() {
        this.props.onGetMyOpenRooms();
    }

    componentWillUnmount() {
        this.props.onInitList("myOpenChatRooms")
    }

    onChoose(room) {
        this.props.onInitForm("widgetChosenChatRoom", room)
    }

    render() {
        const {list: {data: listData, isLoading: isListLoading}} = this.props;
        const {myOpenChatRooms: rooms = []} = listData;
        const isLoadedChatRooms = !isListLoading["myOpenChatRooms"]

        return (
            <div className="chat-widget-container chat-widget-chats-list chat-widget-opened">
                <div className="chat-widget">
                    <div className="chat-container">
                        <div className="chat-header">
                            <div className="row avatar-row align-items-center">
                                <div className="col avatar-row__text">
                                    <div className="bold">
                                        <FormattedMessage id="active.chats"/>
                                    </div>
                                </div>
                                <div className="col-auto avatar-row__controls">
                                    <ButtonIcon icon={'close-white-bold'} onClick={() => this.props.onHideChats()}/>
                                </div>
                            </div>
                        </div>
                        <div className="chat-body">
                            {!isLoadedChatRooms &&
                                <div className="chat-body-preloader">
                                    <span className="preloader-icon animateSpin"></span>
                                </div>}

                            {isLoadedChatRooms &&
                                <div className="chat-body-content">
                                    {rooms.length == 0 && <div className="chat-body-empty">
                                        <div className="chat-body-empty__content">
                                            <FormattedMessage id="empty.list.rooms"/>
                                        </div>
                                    </div>}
                                    {rooms.length > 0 && <div className="row chats-list-row">

                                        {rooms.map(room => {
                                            const {last_message} = room;
                                            const roomName = room.participants
                                                .map(i => i.user.first_name + ' ' + i.user.last_name).join(", ");
                                            return (
                                                <div className="col-12" key={'all-open-room-' + room.id}>
                                                    <button type="button" className="btn-block"
                                                            onClick={() => this.onChoose(room)}></button>
                                                    <div className="row avatar-row">
                                                        <div className="col-12 avatar-row__top_date">
                                                            <div className="small-text color-gray">
                                                                {moment.utc(last_message.created_at)
                                                                    .local()
                                                                    .format("h:mm a")}
                                                            </div>
                                                        </div>
                                                        <div className="col-auto avatar-row__image">
                                                            <Avatar multiple={true}/>
                                                        </div>
                                                        <div className="col avatar-row__text">
                                                            <div className="text-ellipsis-container">
                                                                <div className="text-ellipsis">{roomName}</div>
                                                                <div className="small-text color-gray text-ellipsis">
                                                                    {last_message.user && <EmptyTag>
                                                                        {last_message.user.first_name} {last_message.user.last_name}:&nbsp;
                                                                    </EmptyTag>}
                                                                    <ChatMessage text={last_message.text}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>}
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth,
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onGetMyOpenRooms: () => dispatch(getMyOpenRooms())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatWidgetRooms);