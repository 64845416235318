import React, {Component} from 'react';
import {connect} from "react-redux";

import _ from "lodash";
import {File, FilePreviewItem, FilePreviewItems} from "../../../components/elements/file";
import {removeFile, uploadFileChunk} from "../../services/fileService";
import * as filesize from "filesize";
import {getFileExt} from "../../../utils/app";
import {formSimpleChange} from "../../services/system/formService";

class UploadFile extends Component {
    constructor() {
        super();
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    onChangeFile(event) {
        const files = event.target.files;
        const fieldName = event.target.name;
        const formName = this.props.formName;
        const onCallback = (response) => {
            this.props.onFormSimpleChangeField(formName, fieldName, response.data);
        };
        this.props.onUploadFile(files, onCallback);
    }

    render() {
        const {label, name, formName, error, isMultiple = false, form, onRemoveFile, isShowDownloadFiles = true} = this.props;
        const {data} = form;
        const fieldValue = _.get(data[formName], name) || [];
        const downloadFiles = _.isArray(fieldValue) ? fieldValue : [fieldValue];
        return (
            <>
                {downloadFiles && isShowDownloadFiles &&
                <FilePreviewItems>
                    {downloadFiles.map((downloadFile, index) =>
                        <FilePreviewItem key={index}
                                         onRemove={() => onRemoveFile(formName, isMultiple ? name + '[' + index + ']' : name)}
                                         name={downloadFile.name}
                                         size={filesize(downloadFile.size, {separator: "."})}
                                         ext={getFileExt(downloadFile.name)} />)}
                </FilePreviewItems>
                }

                {(isMultiple || !isMultiple && downloadFiles.length == 0) &&
                <File name={isMultiple ? 'files[' + downloadFiles.length + ']' : name}
                      error={error}
                      onChange={this.onChangeFile}>{label}</File>}

            </>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUploadFile: (files, onCallback) => dispatch(uploadFileChunk(files, onCallback)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onRemoveFile: (formName, fieldName) => dispatch(removeFile(formName, fieldName))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFile);