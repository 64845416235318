import React from 'react';
import classnames from "classnames";
import {EmptyTag} from "../../elements/emptyTag";

export const TabPanes = (props) => <div className="tab-blocks">{props.children}</div>;

export const TabPane = (props) => <div className="tab-block">{props.children}</div>;

export const Tabs = (props) => {
    const {showAs = 'buttons', marginBottom = "", fontSize = ""} = props;
    const tabClassNames = classnames('tab-controls', {
        'tab-buttons': showAs === 'buttons',
        'tab-links': showAs === 'links',
        'margin-bottom-15': marginBottom === '15',
        'font-size-16': fontSize === '16'
    });
    return (
        <div className={tabClassNames}>{props.children}</div>
    );
}

export const Tab = (props) => {
    const {to = "#", children, error = false, disabled = false, active = false, ...rests} = props;
    const tabClassNames = classnames('tab-control', {
        'active': active === true,
        'block-error': error === true
    });
    return (
        <EmptyTag>
            {disabled && <span className={tabClassNames}>{children}</span>}
            {!disabled && <a href={to} {...rests} className={tabClassNames}>{children}</a>}
        </EmptyTag>
    );
};