import React, {Component} from 'react';
import {connect} from "react-redux";
import ScheduleDoctor from "../../../patient/bookAnAppointment/ScheduleDoctor";

import {Modal} from "../../../../../components/modules/modal";
import {Paragraph} from "../../../../../components/elements/paragraph";
import {saveBooking} from "../../../../services/bookingService";
import {Header} from "../../../../../components/elements/header";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {Label} from "../../../../../components/elements/label";
import {RadioBox, RadioBoxes} from "../../../../../components/elements/radiobox";
import {Textarea} from "../../../../../components/elements/textarea";
import {ButtonWrapper} from "../../../../../components/collections/form";
import {Button} from "../../../../../components/elements/button";
import {InputError} from "../../../../../components/elements/error";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {formChangeField, formInit, formSimpleChange} from "../../../../services/system/formService";
import {modalHide} from "../../../../services/system/modalService";
import {FormattedMessage} from "react-intl";

class BookAnAppointment extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const {form: {data: formData}} = this.props;
        const {bookAnAppointment = {}} = formData || {};
        this.props.onBookAnAppointmentInit(bookAnAppointment);
    }

    onChange(event) {
        this.props.onFormChangeField('createBooking', event);
    }

    render() {
        const {form: {data: formData, errors: formErrors, isSend: formIsSend}} = this.props;
        const {createBooking = {}} = formData || {};
        const errors = formErrors.createBooking;
        const disabled = formIsSend.createBooking || false;
        const disabledSend = !createBooking || !createBooking.doctor || !createBooking.timeOfReceipt;

        return (
            <Modal close={true} onClose={() => this.props.onModalHide('bookAnAppointment')} width={1000}>
                <Header as="h2"><FormattedMessage id="doctor.create-appointment.header"/></Header>
                <div className="row content-row">
                    <GridCol count='2'>
                        <GridRow>
                            <GridCol count='1'>
                                <Label fixed={true}><FormattedMessage id="core.type.consultation.label"/></Label>
                                <RadioBoxes error={(errors && errors.typeConsultation) || null}>
                                    <RadioBox name='typeConsultation'
                                              value='CHAT'
                                              disabled={disabled}
                                              checked={createBooking && createBooking.typeConsultation === 'CHAT'}
                                              onChange={this.onChange}><FormattedMessage id="core.type.consultation.chat.label"/></RadioBox>
                                    <RadioBox name='typeConsultation'
                                              value='VIDEO'
                                              disabled={disabled}
                                              checked={createBooking && createBooking.typeConsultation === 'VIDEO'}
                                              onChange={this.onChange}><FormattedMessage id="core.type.consultation.video.label"/></RadioBox>
                                    <RadioBox name='typeConsultation'
                                              value='OFFLINE'
                                              disabled={disabled}
                                              checked={createBooking && createBooking.typeConsultation === 'OFFLINE'}
                                              onChange={this.onChange}><FormattedMessage id="core.type.consultation.offline.label"/></RadioBox>
                                </RadioBoxes>
                            </GridCol>
                            <GridCol count='1'>
                                <Label fixed={true}><FormattedMessage id="doctor.create-appointment.patient"/></Label>
                                <Paragraph>{createBooking.patient && createBooking.patient.firstName}&nbsp;{createBooking.patient && createBooking.patient.lastName}</Paragraph>
                            </GridCol>
                            <GridCol count='1'>
                                <Label fixed={true}><FormattedMessage id="doctor.create-appointment.doctor"/></Label>
                                <Paragraph>{createBooking.doctor && createBooking.doctor.firstName}&nbsp;{createBooking.doctor && createBooking.doctor.lastName}</Paragraph>
                            </GridCol>
                            <GridCol count='1'>
                                <Textarea placeholder={<FormattedMessage id="doctor.create-appointment.description"/>}
                                          name="description"
                                          error={(errors && errors.description) || null}
                                          value={createBooking.description || ''}
                                          disabled={disabled}
                                          onChange={this.onChange}/>
                            </GridCol>
                        </GridRow>
                    </GridCol>

                    <GridCol count='2'>
                        {
                            createBooking.doctor &&
                            <EmptyTag>
                                <Header as="h3"><FormattedMessage id="page.create.booking.time.receipt.label"/></Header>
                                <ScheduleDoctor doctor={createBooking.doctor}
                                                timeOfReceipt={createBooking.timeOfReceipt || null}
                                                onChangeTime={this.onChange}/>
                                {errors && errors.timeOfReceipt && <InputError>{errors.timeOfReceipt}</InputError>}
                            </EmptyTag>
                        }
                    </GridCol>

                    <GridCol count='1'>
                        <ButtonWrapper widthAuto={true}>
                            <Button disabled={disabled} onClick={() => this.props.onModalHide('bookAnAppointment')}
                                    color='white-red'><FormattedMessage id="core.btn.cancel.label"/></Button>

                            <Button disabled={disabled || disabledSend} preloader={disabled === true}
                                    onClick={() => this.props.onSaveBooking(createBooking)}><FormattedMessage id="core.btn.send.label"/></Button>
                        </ButtonWrapper>
                    </GridCol>
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
        onSaveBooking: (createBooking) => dispatch(saveBooking(createBooking, ownProps)),
        onBookAnAppointmentInit: (data) => dispatch(formInit('createBooking', data)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookAnAppointment);