import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {formErrors, formIsSend} from "./system/formService";
import {privateApi} from "../../utils/api";
import {modalHide, modalShow} from "./system/modalService";
import {noticeShowAction} from "../../actions/noticeAction";
import {fetchBooking} from "./bookingService";
import moment from 'moment';
import {FormattedMessage} from "react-intl";
import React from "react";

export const askPayment = (booking) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/bookings/" + booking.id + "/askPayment")
            .then(response => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, <FormattedMessage id="request.send"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            })
            .finally(() => {
                dispatch(modalHide('askParentPay'));
                dispatch(preloaderPageHideAction());
            });
    }
};

export const pay = (booking, data, callback) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/bookings/" + booking.id + "/pay", data)
            .then(response => {
                const {paymentUrl = null} = response.data
                dispatch(modalHide('pay'));
                if (paymentUrl) {
                    window.location.replace(paymentUrl);
                } else {
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, <FormattedMessage id="send.request.pay"/>));
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("pay", error.response.data.details));
                } else {
                    dispatch(modalHide('pay'));
                    dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
                }
            })
            .finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const healthInsurancePayment= ({booking, callbackAfterPay, paymentCompletedAt, ...rest}) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("healthInsurancePayment", true));
        const bookingId = booking.id;
        paymentCompletedAt = moment(paymentCompletedAt, "DD.MM.YYYY hh:mm:ss").isValid() ?
            moment(paymentCompletedAt, "DD.MM.YYYY hh:mm:ss").unix() + "000" : null;

        const showPaymentSuccess = (data) => {
            dispatch(modalShow("paymentSuccess", {booking: data}))
        };

        return privateApi.post("/v1/bookings/" + bookingId + "/healthInsurancePayment", {
            ...rest,
            paymentCompletedAt: paymentCompletedAt
        })
            .then(response => {
                if (callbackAfterPay == null) {
                    dispatch(fetchBooking(booking.id, showPaymentSuccess));
                } else {
                    callbackAfterPay();
                }
                return dispatch(modalHide("healthInsurancePayment"));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("healthInsurancePayment", error.response.data.details));
                } else {
                    dispatch(modalHide("healthInsurancePayment"));
                }
                dispatch(formIsSend("healthInsurancePayment", false));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const unverifiablePaymentRefund = ({booking, refundCompletedAt, ...rest}) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(formIsSend("unverifiablePaymentRefund", true));
        const bookingId = booking.id;
        refundCompletedAt = moment(refundCompletedAt, "DD.MM.YYYY hh:mm:ss").isValid() ?
            moment(refundCompletedAt, "DD.MM.YYYY hh:mm:ss").unix() + "000" : null;

        return privateApi.post("/v1/bookings/" + bookingId + "/unverifiablePaymentRefund", {
            ...rest,
            refundCompletedAt: refundCompletedAt
        })
            .then(response => {
                dispatch(fetchBooking(booking.id));
                dispatch(modalHide("unverifiablePaymentRefund"));
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.create.refund.msg.success.text"/>));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("unverifiablePaymentRefund", error.response.data.details));
                } else {
                    dispatch(modalHide("unverifiablePaymentRefund"));
                }
                dispatch(formIsSend("unverifiablePaymentRefund", false));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const refund = (booking, callback = null) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        dispatch(modalHide("confirm"));

        return privateApi.post("/v1/bookings/" + booking.id + "/refundPayment")
            .then(response => {
                dispatch(fetchBooking(booking.id));
                if (callback) {
                    callback();
                }
                return dispatch(noticeShowAction("", <FormattedMessage id="notice.refund.msg.success.text"/>));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const getPaymentStatusName = (code) => {
    const paymentStatuses = {
        "PENDING": <FormattedMessage id="core.payment.status.pending.label"/>,
        "COMPLETED": <FormattedMessage id="core.payment.status.completed.label"/>,
        "REFUND": <FormattedMessage id="core.payment.status.refund.label"/>,
        "DENIED": <FormattedMessage id="core.payment.status.denied.label"/>,
        "NOT_VERIFY": <FormattedMessage id="core.payment.status.not.verified.label"/>
    };
    return paymentStatuses[code];
};