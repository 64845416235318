import React, {Component} from 'react';
import {connect} from "react-redux";

import {sign} from "../../services/userService";

import {GridCol, GridRow} from "../../../components/collections/grid";
import {Input} from "../../../components/elements/input";
import {Buttons, Button} from "../../../components/elements/button";
import Password from "../../systems/password";
import {formChangeField, formInit} from "../../services/system/formService";
import {TabPane, TabPanes} from "../../../components/modules/tab";
import {ViaGoogle} from "../../../components/modules/viaGoogle";
import {MainLogo} from "../../../components/elements/logo";
import {Redirect} from "react-router";

import {FormattedMessage} from 'react-intl';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {ViaApple} from "../../../components/modules/viaApple";
import {AUTH_PROVIDER} from "../../../constants/system";
import {ButtonSignup} from "../../../components/collections/button_signup";

class Sign extends Component {
    constructor() {
        super();
        this.onChange = this.onChange.bind(this);
        this.onSignUp = this.onSignUp.bind(this);
    }

    componentDidMount() {
        this.props.onFormInit();
    }

    onChange(event) {
        this.props.onFormChangeField(event);
    }

    onSignUp() {
        this.props.onFormInit("acceptTerms", {authProvider : AUTH_PROVIDER.DEFAULT});
        this.props.onRedirectAcceptTerm();
    }

    render() {
        const {form, onSign, auth} = this.props;
        const errors = form.errors.sign;
        const disabled = form.isSend.sign || false;
        if (auth.isAuthenticated) {
            const signReferrer = localStorage.getItem("SignReferrer") || "/";
            return <Redirect to={signReferrer}/>;
        }
        return (
            <EmptyTag>
                <MainLogo/>
                <TabPanes>
                    <TabPane>
                        <GridRow width={300}>
                            <GridCol count="1">
                                <ViaApple name={<FormattedMessage id="core.btn.sign.via.apple.label"/>}/>
                            </GridCol>
                            <GridCol count="1">
                                <ViaGoogle name={<FormattedMessage id="core.btn.sign.via.google.label"/>}/>
                            </GridCol>
                            <GridCol count="1">
                                <span className="color-gray"><FormattedMessage id="core.or.label"/></span>
                            </GridCol>
                        </GridRow>
                        <GridRow width={300}>
                            <GridCol count="1">
                                <Input name="email"
                                       icon="user"
                                       error={(errors && errors.email) || null}
                                       placeholder={<FormattedMessage id="core.field.email.label"/>}
                                       disabled={disabled}
                                       onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="1">
                                <Password name="password"
                                          isWithTip={false}
                                          icon="password"
                                          placeholder={<FormattedMessage id="core.field.password.label"/>}
                                          error={(errors && errors.password) || null}
                                          disabled={disabled}
                                          onChange={this.onChange}/>
                                <div className="password-link">
                                    <a href={'/users/forgotPassword'}><FormattedMessage
                                        id="core.btn.forgot.password.label"/></a>
                                </div>
                                <div className="password-link">
                                    <a href={'/users/reVerifyAccount'}><FormattedMessage
                                        id="re.verify.account.link.label"/></a>
                                </div>
                            </GridCol>
                            <GridCol count="1">
                                <Buttons>
                                    <Button disabled={disabled}
                                            preloader={form.isSend.sign === true}
                                            onClick={() => onSign(form.data.sign)}>
                                        <FormattedMessage id="core.btn.sign-in.label"/>
                                    </Button>
                                </Buttons>
                            </GridCol>
                            <GridCol count="1">
                                <ButtonSignup/>
                            </GridCol>
                        </GridRow>
                    </TabPane>
                </TabPanes>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth,
        browser: store.browser
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFormInit: (formName='sign', data={}) => dispatch(formInit(formName, data)),
        onSign: (credential) => dispatch(sign(credential, ownProps, 'sign')),
        onFormChangeField: (event) => dispatch(formChangeField('sign', event)),
        onRedirectAcceptTerm: () => ownProps.history.push('/users/acceptTerms')
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sign);
