import React from 'react';
import {Button} from "../../elements/button";
import {FormattedMessage} from "react-intl";

export const ViaApple = (props) => {
    const {name} = props;
    return (
        <FormattedMessage id="core.btn.sign.via.google.title">
            {
                title => <a href={window._env_.BACKEND_URL + '/users/sign/apple'} title={title}>
                    <Button color="white-black" border="black-border" icon="apple">{name}</Button>
                </a>
            }
        </FormattedMessage>
    );
};