import React from 'react';
import classnames from "classnames";

export const PreloaderPage = (props) => {
    const {fullScreen = true, text} = props;
    const bClassNames = classnames('preloader-block', {
        'preloader-screen': fullScreen === true
    });
    return (
        <div className={bClassNames}>
            <table>
                <tbody>
                <tr>
                    <td>
                        <div className="preloader-heading"></div>
                        <div className="preloader-text">{text || ''}</div>
                        <span className="preloader-icon animateSpin"></span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};