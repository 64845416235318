import {FormattedMessage} from "react-intl";
import React from "react";
import {EmptyTag} from "../../../components/elements/emptyTag";

export const ChatMessage = (props) => {
    const {text = ""} = props;
    return (
        <EmptyTag>
            {text === '{{openchat}}' && <FormattedMessage
                id="chat.message.doctor.started.consultation.label"/>}
            {text === '{{closechat}}' && <FormattedMessage
                id="chat.message.doctor.complete.consultation.label"/>}
            {(text != '{{openchat}}' && text != '{{closechat}}') && text}
        </EmptyTag>
    )
}