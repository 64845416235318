import React, {Component} from 'react';
import {connect} from "react-redux";
import {modalHide, modalShow} from "../../../services/system/modalService";

import {Modal} from "../../../../components/modules/modal";
import {Button} from "../../../../components/elements/button";
import CountUpTimer from "../../../systems/countUpTimer";
import {uploadBlob} from "../../../services/fileService";
import moment from 'moment';
import {noticeShowAction} from "../../../../actions/noticeAction";
import {isIE, isSafari} from "../../../../utils/app";
import {FormattedMessage} from "react-intl";

class VideoRecord extends Component {

    constructor(props) {
        super(props);

        this._isMounted = false;
        this.state = {
            recorder: null,
            blob: null,
            stream: null,
            chunks: [],
            mimeType: ""
        };
        this.onRec = this.onRec.bind(this);
        this.onResume = this.onResume.bind(this);
        this.onStop = this.onStop.bind(this);
        this.onSend = this.onSend.bind(this);
        this.getUserMedia = this.getUserMedia.bind(this);
    }

    videoStream(stream) {
        const video = document.querySelector('video');
        video.srcObject = stream;
        video.onloadedmetadata = function (e) {
            video.play();
        };
        this.setState({...this.state, stream: stream});
    }

    componentDidMount() {
        this._isMounted = true;
        if (isIE() || isSafari()) {
            this.props.onNotification("", <FormattedMessage id="modal.video.record.incorrect.browser" />);
            this.props.onModalHide("videoRecord");
        } else {
            this.getUserMedia(stream => {
                const mimeType = MediaRecorder.isTypeSupported('video/webm;codecs="vp8,opus"') ?
                    'video/webm;codecs="vp8,opus"' : "video/webm";
                const config = {
                    mimeType: mimeType,
                    audioBitsPerSecond: 128000,
                    videoBitsPerSecond: 512000
                };
                const recorder = new MediaRecorder(stream, config);
                recorder.addEventListener('dataavailable', event => {
                    if (typeof event.data === 'undefined') return;
                    if (event.data.size === 0) return;
                    if (!this._isMounted) return;
                    const chunks = this.state.chunks;
                    chunks.push(event.data);
                    this.setState({...this.state, chunks: chunks})

                });

                recorder.addEventListener('stop', () => {
                    if (!this._isMounted) return;
                    const chunks = this.state.chunks;
                    const blob = new Blob(chunks, {
                        type: "video/webm"
                    });
                    this.setState({...this.state, chunks: [], blob: blob})
                });

                this.setState({...this.state, recorder: recorder, blob: null, mimeType: mimeType});
                this.videoStream(stream);
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        const {stream} = this.state;
        if (stream) {
            stream.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    }

    onRec() {
        const {recorder} = this.state;
        recorder.start();
        this.setState({...this.state, blob: null});
        setTimeout(event => {
            this.onStop();
        }, 60000);
    }

    onResume() {
        const {stream, recorder} = this.state;
        recorder.start();
        this.videoStream(stream);
        setTimeout(event => {
            this.onStop();
        }, 60000);
    }

    onStop() {
        if (!this._isMounted) return;
        const {recorder} = this.state;
        recorder.stop();
    }

    onSend() {
        const {blob} = this.state;
        const {onCallbackSend} = this.props;
        const onCallback = (response) => {
            onCallbackSend(response)
        };
        const fileName = "Video-" + moment().unix() + ".webm";
        this.props.onModalHide("videoRecord");
        this.props.onUploadBlob(blob, fileName, onCallback);
    }

    getUserMedia(callback) {
        if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
        }

        if (navigator.mediaDevices.getUserMedia === undefined) {
            navigator.mediaDevices.getUserMedia = function (constraints) {
                const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }

                return new Promise(function (resolve, reject) {
                    getUserMedia.call(navigator, constraints, resolve, reject);
                });
            }
        }

        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({audio: true, video: true})
                .then(callback)
                .catch(error => {
                    this.props.onNotification("", <FormattedMessage id="core.media.device.not.available" />);
                    this.props.onModalHide("videoRecord");
                });
        }

    }

    render() {
        if (!this._isMounted) return "";
        const {recorder, blob} = this.state;
        const {state: recorderState = ""} = recorder || {};
        const modalHeader = recorderState === 'recording' && <div className={'align-center'}><CountUpTimer/></div>;
        return (
            <Modal width={800} close={true} onClose={() => this.props.onModalHide("videoRecord")} noPadding={true}
                   darkBg={true} header={modalHeader}>
                <div className="video-block video-block-autoheight">
                    {(recorderState === '' || recorderState === 'inactive' || recorderState === 'recording') && !this.state.blob &&
                    <video></video>}
                    {recorderState === 'inactive' && this.state.blob &&
                    <video controls={true} preload='auto'>
                        <source src={URL.createObjectURL(this.state.blob)} type="video/webm"/>
                    </video>
                    }

                    <div className="video-block__controls">
                        {recorderState === 'inactive' && !blob &&
                        <Button circle={true} icon="rec" color="red" circleTransparent={true}
                                onClick={() => this.onRec()}/>}
                        {recorderState === 'recording' &&
                        <Button circle={true} icon="stop" color="red" circleTransparent={true}
                                onClick={() => this.onStop()}/>}
                        {recorderState === 'inactive' && blob &&
                        <Button circle={true} icon="repeat-white" color="red"
                                onClick={() => this.onResume()}/>}
                        {recorderState === 'inactive' && blob &&
                        <Button circle={true} icon="send-white" color="blue" onClick={() => this.onSend()}/>}
                    </div>

                    {recorderState === 'inactive' &&
                    <div className="video-block-note-wrapper">
                        <div className="video-block-note-text">
                            <FormattedMessage id="modal.video.record.description.text" />
                        </div>
                    </div>}
                </div>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onNotification: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onModalShow: (name) => dispatch(modalShow(name)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onUploadBlob: (blob, fileName, onCallback) => dispatch(uploadBlob(blob, fileName, onCallback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoRecord);
