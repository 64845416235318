import React, {Component} from 'react';
import {connect} from "react-redux";

import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {GridCol} from "../../../../../components/collections/grid";
import {Label} from "../../../../../components/elements/label";
import {Header} from "../../../../../components/elements/header";
import {formChangeField, formSimpleChange} from "../../../../services/system/formService";
import {fetchProfileAddresses} from "../../../../services/doctorProfileService";
import UploadFile from "../../../../systems/uploadFile";
import {ButtonIcon} from "../../../../../components/elements/button";
import {FilePreviewItem, FilePreviewItems} from "../../../../../components/elements/file";
import * as filesize from "filesize";
import {getFileExt, getTemperatureUnitToUnitTemperature, stringToFloat} from "../../../../../utils/app";
import {InputError} from "../../../../../components/elements/error";
import {Textarea} from "../../../../../components/elements/textarea";
import {AutocompleteAsync} from "../../../../systems/autocomplete";
import Slider from "@material-ui/core/Slider";
import {Input} from "../../../../../components/elements/input";
import {fillDescriptionFromVideo, fillSymptomsFromText, searchSymptoms} from "../../../../services/bookingService";
import {modalShow} from "../../../../services/system/modalService";

class Step3 extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeSymptoms = this.onChangeSymptoms.bind(this);
        this.onChangeFloat = this.onChangeFloat.bind(this);
        this.onChangeSharpPain = this.onChangeSharpPain.bind(this);
        this.state = {symptoms: []};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {form: {data: curData}} = this.props;
        const {createBooking: curCreateBooking = {}} = curData;
        const {healthConditionVideo: curHealthConditionVideo = {}, symptoms: curSymptoms = []} = curCreateBooking;
        const {id: curHealthConditionVideoId = null} = curHealthConditionVideo;

        const {form: {data: prevData}} = prevProps;
        const {createBooking: prevCreateBooking = {}} = prevData;
        const {healthConditionVideo: prevHealthConditionVideo = {}, symptoms: prevSymptoms = []} = prevCreateBooking;
        const {id: prevHealthConditionVideoId = null} = prevHealthConditionVideo;

        if (curHealthConditionVideoId !== prevHealthConditionVideoId && curHealthConditionVideoId != null) {
            this.props.onFillDescriptionFromVideo({id: curHealthConditionVideoId});
        }

        if (curSymptoms.map(i => i.id).join(",") !== prevSymptoms.map(i => i.id).join(",")) {
            this.setState({...this.state, "symptoms": curSymptoms});
        }
    }

    onChange(event) {
        this.props.onFormChangeField('createBooking', event);
    }

    onChangeSymptoms(event, value) {
        if (value == null) {
            this.props.onFormSimpleChangeField("createBooking", "symptoms", null);
        }
        this.props.onFormSimpleChangeField("createBooking", "symptoms", value);
    }

    onChangeSharpPain(event, value) {
        this.props.onFormSimpleChangeField('createBooking', "sharpPain", parseInt(value));
    }

    onChangeFloat(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChangeField('createBooking', target.name, stringToFloat(value));
    }

    render() {
        const {form: {data: formData, errors: formErrors}, list: {data: listData}, auth: {user}} = this.props;
        const {createBooking = {}, systemSettings = {}} = formData || {};
        const {searchSymptoms = []} = listData;
        const errors = formErrors.createBooking;
        const {isEnableInfermedica = null} = systemSettings || {};
        let sharpPainMarks = [];
        for (let i = 0; i <= 10; i++) {
            sharpPainMarks.push({value: i, label: i});
        }
        const unitTemperature = user.unitTemperature;
        return (
            <EmptyTag>
                <GridCol count='1'>
                    <Header as="h3"><FormattedMessage id="core.my.condition.label"/></Header>
                </GridCol>
                <GridCol count='1'>
                    <Label fixed={true}><FormattedMessage id="core.video.description.label"/></Label>
                    {!createBooking.healthConditionVideo &&
                    <EmptyTag>
                        <UploadFile label={<FormattedMessage id="core.btn.add.label"/>} name="healthConditionVideo" error={null}
                                    formName="createBooking"
                                    isCanRemove={true} isMultiple={false} isShowDownloadFiles={false}/>

                        <ButtonIcon icon="camera"
                                    onClick={() => this.props.onModalShow("videoRecord")}>
                            <span className="icon-item__text"><FormattedMessage id="core.btn.video.recording.label"/></span>
                        </ButtonIcon>
                    </EmptyTag>
                    }

                    {createBooking.healthConditionVideo &&
                    <FilePreviewItems>
                        <FilePreviewItem
                            onRemove={() => this.props.onFormSimpleChangeField('createBooking', "healthConditionVideo", null)}
                            name={createBooking.healthConditionVideo.name}
                            size={filesize(createBooking.healthConditionVideo.size, {separator: "."})}
                            ext={getFileExt(createBooking.healthConditionVideo.name)}/>
                    </FilePreviewItems>
                    }

                    {errors && errors.healthConditionVideo && <InputError>{errors.healthConditionVideo}</InputError>}
                </GridCol>
                <GridCol count='1'>
                    <Textarea placeholder={<FormattedMessage id="core.description.label"/>}
                              name="description"
                              error={(errors && errors.description) || null}
                              value={createBooking.description || ''}
                              onChange={this.onChange}/>
                </GridCol>
                {isEnableInfermedica &&
                <GridCol count="1">
                    <AutocompleteAsync onInputChange={this.props.onSearchSymptoms}
                                       value={this.state.symptoms || []}
                                       getOptionLabel={option => option.commonName}
                                       onChange={this.onChangeSymptoms}
                                       multiple={true}
                                       placeholder={<FormattedMessage id="core.choose.symptoms.label"/>}
                                       placeholderFixed={true}
                                       error={(errors && errors.symptoms) || null}
                                       options={searchSymptoms}/>
                    {createBooking.description && <a href="#"
                                                     onClick={() => this.props.onFillSymptomsFromText(createBooking.description)}>
                        <FormattedMessage id="fill.from.description"/></a>}
                </GridCol>}
                <GridCol count='1'>
                    <Label fixed={true}><FormattedMessage id="core.sharp.pain.label"/></Label>
                    <Slider
                        name="sharpPain"
                        onChange={this.onChangeSharpPain}
                        value={createBooking.sharpPain || 0}
                        aria-labelledby="discrete-slider-custom"
                        step={1}
                        valueLabelDisplay="auto"
                        marks={sharpPainMarks}
                        min={0}
                        max={10}
                    />
                    {errors && errors.sharpPain && <InputError>{errors.sharpPain}</InputError>}
                </GridCol>
                <GridCol count='1'>
                    <Input placeholder={
                        <FormattedMessage id="core.temperature.label"
                                          values={{unit: getTemperatureUnitToUnitTemperature(unitTemperature)}}/>}
                           name="temperature"
                           error={(errors && errors.temperature) || null}
                           value={createBooking.temperature || ''}
                           onBlur={this.onChangeFloat}
                           onChange={this.onChange}/>
                </GridCol>
                <GridCol count='1'>
                    <Header as="h3">{<FormattedMessage id="core.attachments.label"/>}</Header>
                </GridCol>
                <GridCol count='1'>
                    <UploadFile label={<FormattedMessage id="core.btn.add.label"/>} name="files" error={null} formName="createBooking"
                                isCanRemove={true} isMultiple={true}/>
                </GridCol>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onFillSymptomsFromText: (text) => dispatch(fillSymptomsFromText(text)),
        onFillDescriptionFromVideo: (file) => dispatch(fillDescriptionFromVideo(file)),
        onSearchSymptoms: (event, query, reason) => dispatch(searchSymptoms(query)),
        onFetchDoctorProfileAddresses: (doctorId) => dispatch(fetchProfileAddresses(doctorId)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onModalShow: (name, data) => dispatch(modalShow(name, data)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
