import React from 'react';
import classnames from 'classnames';
import {Icon} from "../icon";

export const Buttons = (props) => {
    const {width = ''} = props;
    const btnsClassNames = classnames('form-submit', {
        'btns-width-auto': width === 'auto'
    });

    return (
        <div className={btnsClassNames}>{props.children}</div>
    );
};

export const Button = (props) => {
    const {
        onClick, disabled, preloader = false, circleTransparent = false, circleMiddleSize = false, title = "", border = "",
        controlPrint = false, color = '', icon = '', iconSize = 0, circle = '', pulse = '', fontColor = '', square = false,
        sizeMiddle = false, size = '', squareColor = ''
    } = props;
    const buttonClassNames = classnames('btn-default', {
        'btn-circle-transparent': circleTransparent === true,
        'btn-preloader': preloader === true,
        'btn-white': color === 'white',
        'btn-black-border': border === 'black-border',
        'btn-red-bordered': border === 'red-bordered',
        'btn-gray-bordered' : border === 'gray-bordered',
        'btn-google': icon === 'google',
        'btn-apple': icon === 'apple',
        'btn-white-red': color === 'white-red',
        'btn-white-blue': color === 'white-blue',
        'btn-white-black': color === 'white-black',
        'btn-red': color === 'red',
        'btn-orange': color === 'orange',
        'btn-yellow': color === 'yellow',
        'font-color-black': fontColor === 'black',
        'btn-blue': color === 'blue',
        'btn-op-gray': color === 'op-gray',
        'btn-circle': circle === true,
        'btn-pulse': pulse === true,
        'btn-square': square === true,
        'btn-square-blue' : squareColor === 'blue',
        'control-print': controlPrint === true,
        'btn-circle-middle-size': circleMiddleSize === true,
        'btn-size-middle' : sizeMiddle === true || size === 'middle',
        'btn-size-small' : size === 'small'
    });

    return (
        <button type="button"
                title={title}
                className={buttonClassNames}
                disabled={disabled}
                onClick={onClick}>
            <div className="btn__content">
                {icon && <Icon name={icon} size={iconSize}/>}
                {props.children}
            </div>
        </button>
    );
};

export const ButtonRemove = (props) => {
    const {onClick} = props;
    return (
        <button type="button" className="btn-delete" onClick={onClick}>×</button>
    );
};

export const ButtonEdit = (props) => {
    const {onClick} = props;
    return (
        <button type="button" className="btn-edit" onClick={onClick}></button>
    );
};

export const ButtonClose = (props) => {
    const {onClick} = props;
    return (
        <button title="Close" type="button" className="pform-close" onClick={onClick}>×</button>
    );
};

export const ButtonMessage = (props) => {
    const {onClick} = props;
    return (
        <button type="button" className="btn-icon btn-chat-trigger" onClick={onClick}>
            <Icon name="chat-white"/>
            <Icon name="expand"/>
        </button>
    );
};

export const ButtonIcon = (props) => {
    const {color = '', onClick, icon = null, disabled = false, iconSize = false} = props;
    const buttonClassNames = classnames('btn-icon', {
        'color-black': color === 'black',
        'color-blue': color === 'blue',
    });
    return (
        <button type="button" className={buttonClassNames} onClick={onClick} disabled={disabled}>
            {icon && <Icon name={icon} size={iconSize}/>}
            {props.children}
        </button>
    );
};