import {privateApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";
import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {modalHide} from "./system/modalService";

export const fetchPaymentProviders = (countryIso) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/payments/providers?countryIso=" + countryIso)
            .then(response => {
                return dispatch(listFetchAction("paymentProviders", response.data));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchPaymentMethods = () => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/payments/methods")
            .then(response => {
                return dispatch(listFetchAction("paymentMethods", response.data));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchNotConfigureProviders = (paymentMethodId) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/payments/methods/" + paymentMethodId + "/notConfigureProviders")
            .then(response => {
                return dispatch(listFetchAction("paymentProviders", response.data));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchCurrencies = () => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/currencies")
            .then(response => {
                return dispatch(listFetchAction("currencies", response.data));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchPaymentGateways = () => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/payments/gateways")
            .then(response => {
                return dispatch(listFetchAction("paymentGateways", response.data));
            }).catch(error => {
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchPaymentSettings = (pageNumber, listName="paymentSettings") => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/payments/settings?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content = [], ...rest} = data;
                dispatch(listFetchAction(listName, content, {...rest}));
            }).finally(() => {
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const createPaymentSetting = (data) => {
    return (dispatch, state) => {
        const pagination = state().list.pagination["paymentSettings"] || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/payments/settings", data)
            .then(response => {
                  dispatch(fetchPaymentSettings(pagination.number + 1));
            }).finally(() => {
                dispatch(modalHide('createPaymentSetting'));
                return dispatch(preloaderPageHideAction());
            });
    }
};

export const removePaymentSetting = (id) => {
    return (dispatch, state) => {
        const pagination = state().list.pagination["paymentSettings"] || {};
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/v1/payments/settings/" + id)
            .then(response => {
                dispatch(fetchPaymentSettings(pagination.number + 1));
            }).finally(() => {
                dispatch(modalHide('confirm'));
                return dispatch(preloaderPageHideAction());
            });
    }
};
