import React from 'react';
import {Provider} from 'react-redux';

import store from './store';
import Router from './Router';
import {authorizeUser} from "./containers/services/userService";
import 'react-toastify/dist/ReactToastify.css';

import IntlProviderWrapper from "./IntlProviderWrapper";
import {getAnalytics} from "firebase/analytics";
import {initializeApp} from "firebase/app";

const accessToken = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');
const user = (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))) || {};
store.dispatch(authorizeUser(accessToken, refreshToken, user));

const firebaseConfig = {
    apiKey: window._env_.FIREBASE_API_KEY,
    authDomain: window._env_.FIREBASE_AUTH_DOMAIN,
    databaseURL: window._env_.FIREBASE_DATABASE_URL,
    projectId: window._env_.FIREBASE_PROJECT_ID,
    storageBucket: window._env_.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: window._env_.FIREBASE_MESSAGING_SENDER_ID,
    appId: window._env_.FIREBASE_APP_ID,
    measurementId: window._env_.FIREBASE_MEASUREMENT_ID
};

const firebase = initializeApp(firebaseConfig);
getAnalytics(firebase);

const App = () => (
    <Provider store={store}>
        <IntlProviderWrapper>
            <Router/>
        </IntlProviderWrapper>
    </Provider>
);

export default App;