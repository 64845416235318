import React from 'react';
import {Link} from "react-router-dom";
import {Icon} from "../icon";
import {FormattedMessage} from "react-intl";

export const BackToSign = (props) => {
    const {...rest} = props;
    return (
        <Link {...rest} className="back-link"><FormattedMessage id="core.btn.back.to.sign.label"/></Link>
    );
};

export const BackToHome = (props) => {
    const {...rest} = props;
    return (
        <Link {...rest} className="back-link"><FormattedMessage id="core.btn.back.to.home.label"/></Link>
    );
};

export const BackTo = (props) => {
    const {...rest} = props;
    return (
        <Link {...rest} className="link-icon">
            <div className="icon-item">
                <Icon name="back"/>
            </div>
        </Link>
    );
};
