import React, {Component} from 'react';
import {connect} from "react-redux";

import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {GridCol} from "../../../../components/collections/grid";
import {Tab, Tabs} from "../../../../components/modules/tab";
import {tabChangePanelAction, tabClearAction} from "../../../../actions/tabAction";
import {modalHide, modalShow} from "../../../services/system/modalService";

import {noticeShowAction} from "../../../../actions/noticeAction";
import {IconItem} from "../../../../components/elements/icon";
import {isModal} from "../../../../utils/app";
import CreateAssistantInvites from "./CreateAssistantInvites";
import AssistantsByStatus from "./AssistantsByStatus";
import qs from "query-string";
import {listInitAction} from "../../../../actions/listAction";
import {FormattedMessage} from "react-intl";
import {Redirect} from "react-router";

class Assistants extends Component {
    componentDidMount() {
        const queryString = this.props.location.search;
        const {status = "requested"} = qs.parse(queryString);
        this.props.onInitList("assistantInvites");
        this.props.onChangeTabPanel(status);
    }

    componentWillUnmount() {
        this.props.onTabClearAction("assistants");
    }

    render() {
        const {modal, tab: {data: tabData}, auth: {user}} = this.props;
        if (user.role === 'DOCTOR' && !user.isFillProfile) {
            return <Redirect to={"/"}/>
        }
        const {assistants: tabAssistants = {}} = tabData;

        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2"><FormattedMessage id="core.header.assistants"/></Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                        {user.isEnabled &&
                        <a href="#" onClick={() => this.props.onFormCreateAssistantInvites()}
                           className="btn-icon color-blue">
                            <IconItem name="plus-bordered"><FormattedMessage id="assistant.invite"/></IconItem>
                        </a>}
                    </div>
                </div>
                <div className="row content-heading">
                    <GridCol count="1">
                        <Tabs showAs="links">
                            <Tab onClick={() => this.props.onChangeTabPanel("requested")}
                                 active={tabAssistants.activeTabPanel === 'requested'}><FormattedMessage id="assistant.invitation"/></Tab>
                            <Tab onClick={() => this.props.onChangeTabPanel("accepted")}
                                 active={tabAssistants.activeTabPanel === 'accepted'}><FormattedMessage id="assistant.connected-assistants"/></Tab>
                        </Tabs>
                    </GridCol>
                </div>
                <div className="row content-row">
                    {tabAssistants.activeTabPanel === 'requested' && <AssistantsByStatus status="REQUESTED"/>}
                    {tabAssistants.activeTabPanel === 'accepted' && <AssistantsByStatus status="ACCEPTED"/>}
                </div>
                {isModal(modal, 'createAssistantInvites') && user.isEnabled && <CreateAssistantInvites/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        tab: store.tab,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onTabClearAction: (tabName) => dispatch(tabClearAction(tabName)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFormCreateAssistantInvites: () => dispatch(modalShow('createAssistantInvites')),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onChangeTabPanel: (tabPanelName) => dispatch(tabChangePanelAction("assistants", tabPanelName)),
        onNoticeShow: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onModalHide: (name) => dispatch(modalHide(name))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Assistants);