import React, {Component} from 'react';
import {connect} from "react-redux";
import Pagination from "react-js-pagination";
import moment from "moment";
import {FormattedMessage} from "react-intl";
import {fetchCalls} from "../../../services/callService";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import DatepicterRange from "../../../systems/datepicterRange";


class ConsultationCalls extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
    }

    componentDidMount() {
        this.props.onInitList("consultationCalls");
        this.onPagination(1);
    }

    onPagination(pageNumber) {
        this.props.onListFilterField("type", "CONSULTATION_CALL");
        this.props.onListSorts({'consultationCall.booking.id': 'desc'});
        this.props.onFetchCalls(pageNumber);
    }

    componentWillUnmount() {
        this.props.onInitList("consultationCalls");
    }

    render() {
        const {list: {data: listData, pagination: listPagination}} = this.props;
        const {consultationCalls:calls = []} = listData;
        const {number = 0, size = 0, totalElements = 0} = listPagination['consultationCalls'] || {};

        let groupVideoConsultationCalls = {};

        calls.map(({id, consultationCall}) => {
            const booking = consultationCall && consultationCall.booking;
            if (booking) {
                if (!groupVideoConsultationCalls[booking.id]) groupVideoConsultationCalls[booking.id] = [];
                groupVideoConsultationCalls[booking.id].push(id);
            }
        });

        return (
            <EmptyTag>
                <div className="row form-elements">
                    <div className="col-auto">
                        <div className="row small-form-row align-items-center filtr-form">
                            <DatepicterRange fieldNameFrom={'createdAtFrom'}
                                             fieldNameTo={'createdAtTo'}
                                             labelFrom={'created.from.label'}
                                             labelTo={'created.to.label'}
                                             onListFilterField={this.props.onListFilterField}
                                             onPagination={this.onPagination}/>

                            <DatepicterRange fieldNameFrom={'timeOfReceiptFrom'}
                                             fieldNameTo={'timeOfReceiptTo'}
                                             labelFrom={'consultation.date.from.label'}
                                             labelTo={'consultation.date.to.label'}
                                             onListFilterField={this.props.onListFilterField}
                                             onPagination={this.onPagination}/>
                        </div>
                    </div>
                </div>

                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th><FormattedMessage id="createdAt"/></th>
                            <th><FormattedMessage id="sessionId"/></th>
                            <th><FormattedMessage id="doctor"/></th>
                            <th><FormattedMessage id="patient"/></th>
                            <th><FormattedMessage id="consultation.date"/></th>
                            <th><FormattedMessage id="consultation.id"/></th>
                        </tr>
                        </thead>
                        <tbody>
                        {calls.map(call => {
                            const {createdAt, sessionId, id, consultationCall = null} = call;
                            const booking = consultationCall ? consultationCall.booking : null;
                            const rowSpan = booking && groupVideoConsultationCalls[booking.id] ?
                                groupVideoConsultationCalls[booking.id].length : 1;
                            const isShow = booking && groupVideoConsultationCalls[booking.id];
                            if (booking)
                                delete groupVideoConsultationCalls[booking.id];
                            return (
                                <tr key={id}>
                                    <td>{moment(createdAt).format("lll")}</td>
                                    <td>{sessionId}</td>
                                    {isShow && <EmptyTag>
                                        <td rowSpan={rowSpan}>{booking && booking.doctor.firstName} {booking && booking.doctor.lastName}</td>
                                        <td rowSpan={rowSpan}>{booking && booking.patient.firstName} {booking && booking.patient.lastName}</td>
                                        <td rowSpan={rowSpan}>
                                           {booking && moment(booking.timeOfReceipt).format("lll")}
                                        </td>
                                        <td rowSpan={rowSpan}>{booking && booking.id}</td>
                                    </EmptyTag>}
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {size < totalElements && <Pagination
                        activeLinkClass={"active"}
                        activePage={number + 1}
                        itemsCountPerPage={size}
                        totalItemsCount={totalElements}
                        pageRangeDisplayed={5}
                        onChange={this.onPagination}/>}

                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onListSorts: (sorts) => dispatch(listSortAction("consultationCalls", sorts)),
        onFetchCalls: (pageNumber) => dispatch(fetchCalls(pageNumber, "consultationCalls")),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("consultationCalls", fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationCalls);