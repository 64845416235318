import React, {Component} from 'react';
import {connect} from "react-redux";

import {withRouter} from 'react-router-dom';
import {IconItem} from "../../../../../components/elements/icon";
import {Modal} from "../../../../../components/modules/modal";
import {Header} from "../../../../../components/elements/header";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {modalHide} from "../../../../services/system/modalService";
import {FormattedMessage} from "react-intl";

class PaymentDecline extends Component {
    render() {
        return (
            <Modal close={true} onClose={() => this.props.onModalHide('paymentDecline')} align="center">
                <Header as="h2" color="red"><FormattedMessage id="modal.payment.decline.header"/></Header>
                <GridRow>
                    <GridCol count='1'>
                        <p><FormattedMessage id="modal.payment.decline.description.text"/></p>
                        <IconItem name="refused"/>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onModalHide: (name) => dispatch(modalHide(name)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentDecline));
