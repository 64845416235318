import React, {Component} from 'react';
import {connect} from "react-redux";
import {FormattedMessage} from "react-intl";
import {
    fetchDoctorCommissions, updateDoctorCommission,
} from "../../../services/billingSettingsService";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {Input} from "../../../../components/elements/input";
import {listFilterFieldAction, listInitAction} from "../../../../actions/listAction";
import {Header} from "../../../../components/elements/header";
import {stringToFloat} from "../../../../utils/app";
import InfoBillingPeriods from "../billing/InfoBillingPeriods";
import {Button, ButtonIcon} from "../../../../components/elements/button";
import {formInitAction} from "../../../../actions/formAction";
import Pagination from "react-js-pagination";
import {AutocompleteAsync} from "../../../systems/autocomplete";
import {fetchSpecializations} from "../../../services/bookingService";

class DoctorCommissions extends Component {
    constructor(props) {
        super(props);
        this.onChangeCommissionDoctor = this.onChangeCommissionDoctor.bind(this);
        this.onBlurCommissionDoctor = this.onBlurCommissionDoctor.bind(this);
        this.onUpdateCommission = this.onUpdateCommission.bind(this);
        this.onEditCommission = this.onEditCommission.bind(this);
        this.onPagination = this.onPagination.bind(this);
        this.onBlurSpecialization = this.onBlurSpecialization.bind(this);
        this.onSearchSpecialization = this.onSearchSpecialization.bind(this);
        this.onOpen = this.onOpen.bind(this);
        this.onSelectedSpecialization = this.onSelectedSpecialization.bind(this);
        this.state = {
            isEdit: false,
            filterSpecializations: {}
        }
    }

    onSearchSpecialization(event, query, reason) {
        this.props.onListFilterField("specializations", "searchText", query);
        this.props.onFetchSpecializations(1);
    }

    onOpen(event) {
        this.props.onListFilterField("specializations", "searchText", "");
        this.props.onFetchSpecializations(1);
    }

    onSelectedSpecialization(event, value, reason) {
        this.setState({...this.state, filterSpecializations: value})
        this.props.onListFilterField("doctorCommissions", "specializations", value.id);
        this.onPagination(1);
    }

    onBlurSpecialization(event) {
        if (!event.target.value) {
            this.props.onListFilterField("doctorCommissions", "specializations", null);
            this.setState({...this.state, filterSpecializations: {
                    id: "ANY",
                    name: "ANY"
                }})
        }
    }

    onBlurCommissionDoctor(commissionDoctor) {
        const {form: {data: formData}} = this.props;
        const {updateDoctorCommissions = []} = formData;
        if (commissionDoctor.currentCommission)
            commissionDoctor.currentCommission = stringToFloat(commissionDoctor.currentCommission);

        if (commissionDoctor.nextCommission)
            commissionDoctor.nextCommission = stringToFloat(commissionDoctor.nextCommission);
        this.props.onInitForm("updateDoctorCommissions", updateDoctorCommissions);
    }

    onChangeCommissionDoctor(doctor, typeValue, event) {
        const {form: {data: formData}} = this.props;
        const {updateDoctorCommissions = []} = formData;
        updateDoctorCommissions.filter(i => i.doctor.id == doctor.id)
            .forEach(i => {
                if (typeValue == "current") {
                    i.currentCommission = event.target.value;
                } else {
                    i.nextCommission = event.target.value;
                }
            });
        this.props.onInitForm("updateDoctorCommissions", updateDoctorCommissions);
    }

    onPagination(pageNumber) {
        const {match: {params}} = this.props;
        const {id: specializationId = ""} = params || {};
        this.props.onFetchDoctorCommissions(pageNumber, specializationId);
    }

    componentDidMount() {
        this.onPagination(1);
    }

    componentWillUnmount() {
        this.props.onInitList("doctorCommissions");
    }

    onUpdateCommission(doctorCommissions) {
        const {match: {params}} = this.props;
        const {id: specializationId = ""} = params || {}
        this.props.onUpdateDoctorCommissions(specializationId, doctorCommissions);
        this.onEditCommission();
    }

    onEditCommission() {
        const {list: {data: listData}} = this.props;
        const {doctorCommissions = []} = listData;
        const isEdit = this.state.isEdit;
        const updateDoctorCommissions = [];
        doctorCommissions.map(i => updateDoctorCommissions.push({...i}));
        this.props.onInitForm("updateDoctorCommissions", updateDoctorCommissions);
        this.setState({...this.state, isEdit: !isEdit});
    }

    render() {
        const {list: {data: listData, pagination}, form: {data: formData}} = this.props;
        const {number = 0, size = 0, totalElements = 0} = pagination['doctorCommissions'] || {};
        const {doctorCommissions = [], specializations = []} = listData;
        const {filterSpecializations = {}} = this.state;
        const {updateDoctorCommissions = []} = formData;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">

                    </div>
                    <div className="col content-heading__main">
                        <Header as="h2">
                            <FormattedMessage id="page.admin.billing.commissions.header"/>
                        </Header>
                    </div>
                </div>

                <div className="row tile-row margin-bottom-15">
                    <InfoBillingPeriods/>
                </div>

                <div className="row form-elements align-items-center filtr-form justify-content-end">

                    <div className="col-auto">
                        <label className="field-label margin-bottom-0">
                            <FormattedMessage id="core.specialization.label"/>
                        </label>
                    </div>

                    <div className="col-auto width-320">
                        <FormattedMessage id={'any'}>
                            {optionNameSelected => {

                                const value = filterSpecializations.specializations && filterSpecializations.specializations.id == 'ANY' ?
                                    {
                                        id: "ANY",
                                        name: optionNameSelected[0]
                                    } : filterSpecializations.specializations;

                                return (
                                    <AutocompleteAsync onInputChange={this.onSearchSpecialization}
                                                       getOptionLabel={option => option.name || ''}
                                                       onChange={this.onSelectedSpecialization}
                                                       onOpen={this.onOpen}
                                                       value={value || null}
                                                       onBlur={this.onBlurSpecialization}
                                                       placeholderFixed={true}
                                                       options={specializations}/>
                                );
                            }
                            }</FormattedMessage>
                    </div>

                    <div className="col col-empty"></div>
                    <div className="col-auto">
                        {this.state.isEdit &&
                        <Button onClick={() => this.onUpdateCommission(updateDoctorCommissions)}>
                            <FormattedMessage id="core.btn.save.label"/>
                        </Button>}

                        {!this.state.isEdit &&
                        <ButtonIcon color={'black'} icon={'edit-black'} onClick={this.onEditCommission}>
                            <span className="icon-item__text"><FormattedMessage id="core.btn.edit.label"/></span>
                        </ButtonIcon>}
                    </div>
                </div>


                <div className="table-wrapper table-wrapper-shadow">
                    <table className="admin-table">
                        <thead>
                        <tr>
                            <th><FormattedMessage id="page.admin.billing.commissions.table.doctors.label"/></th>
                            <th><FormattedMessage id="page.admin.billing.commissions.table.commission.size.label"/></th>
                            <th><FormattedMessage id="page.admin.billing.commissions.table.next.commission.size.label"/>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {doctorCommissions.map(commissionDoctor => {
                            const doctor = commissionDoctor.doctor;
                            const updateCommissionDoctor = (updateDoctorCommissions.length > 0 &&
                                updateDoctorCommissions.filter(i => i.doctor.id == doctor.id)[0]) || null;
                            const isCanEditCurrentCommission = !commissionDoctor.currentCommission;
                            const joinSpecializations = doctor.doctor.specializations ?
                                "(" + doctor.doctor.specializations.map(i => i.name).join(", ") + ")" : "";
                            return (
                                <tr key={doctor.id}>
                                    <td>{doctor.firstName} {doctor.lastName} <i>{joinSpecializations}</i></td>
                                    <td className="td-input">
                                        {this.state.isEdit && isCanEditCurrentCommission &&
                                        <Input
                                            simple
                                            onChange={(event) => this.onChangeCommissionDoctor(
                                                doctor, "current", event)}
                                            onBlur={() => this.onBlurCommissionDoctor(updateCommissionDoctor)}
                                            value={updateCommissionDoctor && updateCommissionDoctor.currentCommission}
                                        />}

                                        {this.state.isEdit && !isCanEditCurrentCommission && commissionDoctor.currentCommission}
                                        {!this.state.isEdit && commissionDoctor.currentCommission}
                                    </td>
                                    <td className="td-input">
                                        {this.state.isEdit &&
                                        <Input
                                            simple
                                            onChange={(event) => this.onChangeCommissionDoctor(
                                                doctor, "next", event)}
                                            onBlur={() => this.onBlurCommissionDoctor(updateCommissionDoctor)}
                                            value={updateCommissionDoctor && updateCommissionDoctor.nextCommission}
                                        />}

                                        {!this.state.isEdit && commissionDoctor.nextCommission}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>


                {size < totalElements && <Pagination
                    activeLinkClass={"active"}
                    activePage={number + 1}
                    itemsCountPerPage={size}
                    totalItemsCount={totalElements}
                    pageRangeDisplayed={5}
                    onChange={this.onPagination}/>}


            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        tab: store.tab,
        list: store.list,
        form: store.form,
        modal: store.modal
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchSpecializations: (pageNumber) => dispatch(fetchSpecializations(pageNumber)),
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFetchDoctorCommissions: (pageNumber, specializationId) => dispatch(fetchDoctorCommissions(pageNumber, specializationId)),
        onUpdateDoctorCommissions: (specializationId, doctorCommissions) => dispatch(updateDoctorCommission(specializationId, doctorCommissions))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorCommissions);