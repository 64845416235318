import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {listFilterFieldAction, listSortAction} from "../../../../actions/listAction";
import {Avatar} from "../../../../components/collections/avatar";
import {fetchConsultationComingSoon} from "../../../services/doctorService";
import {Link} from "react-router-dom";
import {Button} from "../../../../components/elements/button";
import CountDownTimer from "../../../systems/countDownTimer";
import {modalShow} from "../../../services/system/modalService";
import {FormattedMessage} from "react-intl";

class ConsultationComingSoon extends Component {
    constructor(props) {
        super(props);
        this.onStartConsultation = this.onStartConsultation.bind(this);
    }

    componentDidMount() {
        const tms = moment().hour(0).minute(0).second(0).unix();
        this.props.onListFilterField("dateOfReceipt", tms + "000");
        this.props.onListFilterField("status", "APPROVED");
        this.props.onListFilterField("isStarted", "false");
        this.props.onListFilterField("isRescheduling", "false");
        this.props.onListFilterField("consultationTypes", "CHAT-VIDEO");
        this.props.onListSorts({timeOfReceipt: 'asc'});
        this.props.onFetchConsultationComingSoon();
    }

    onStartConsultation(booking) {
        switch (booking.typeConsultation) {
            case "CHAT" :
                this.props.onStartChatConsultation(booking);
                break;
            default:
                this.props.onStartVideoAudioConsultation(booking);
        }
    }

    render() {
        const {list: {data}, auth: {user}} = this.props;
        const {consultationComingSoon = []} = data;
        const {0: firstConsultationComingSoon = {}} = consultationComingSoon;
        const {id, doctor = {}, timeOfReceipt, patient} = firstConsultationComingSoon || {};
        const {familyMember = null} = patient || {};
        if (consultationComingSoon.length === 0) return "";
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        return (
            <div className="col-12">
                <div className="white-block padding-0">
                    <div className="row form-elements form-padding-20-30 display-table">
                        <div className="col-md-4 div-bg bg-color-blue">
                            <h4><FormattedMessage id="widget.consultation.coming.soon.header"/></h4>
                            {timeOfReceipt && <CountDownTimer startTime={timeOfReceipt} />}
                        </div>
                        <div className="col">
                            {!user.familyMember && familyMember &&
                            <div className="margin-bottom-10">
                                <FormattedMessage id="widget.pay.payment.patient"
                                                  values={{firstName: patient.firstName,
                                                      lastName: patient.lastName,
                                                      memberType: familyMember.memberType.name}}/>
                            </div>}
                            <div className="small-text">{moment(timeOfReceipt).format("h:mm a")}</div>
                            <div className="row avatar-row align-items-center">
                                <div className="col-auto avatar-row__image">
                                    <Avatar width="58"/>
                                </div>
                                <div className="col avatar-row__text">
                                    <div><FormattedMessage id="core.doctor.name" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></div>
                                    <div className="small-text color-gray">{joinSpecializations}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="form-submit controls-padding-20">
                                <Link to={"/bookings/" + id + "/detail"}>
                                    <Button color="white-blue"><FormattedMessage id="core.btn.detail.label"/></Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchConsultationComingSoon: () => dispatch(fetchConsultationComingSoon()),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("consultationComingSoon", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("consultationComingSoon", sorts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationComingSoon);
