import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {Header} from "../../../../../components/elements/header";
import {
    getReportPeriodInput,
    getReportPeriodsInput,
    isModal
} from "../../../../../utils/app";
import {modalHide, modalShow} from "../../../../services/system/modalService";
import {listInitAction} from "../../../../../actions/listAction";
import {exportReport, generateReport} from "../../../../services/billingReportService";
import {FormattedMessage} from "react-intl";
import Pagination from "react-js-pagination";
import {Button} from "../../../../../components/elements/button";
import moment from "moment";

import {formInitAction} from "../../../../../actions/formAction";
import SendReport from "./SendReport";
import TotalRemittanceItems from "./ReportTotalRemittanceItems";
import {Option, Select} from "../../../../../components/elements/select";
import {DatePicter} from "../../../../../components/elements/datepicter";
import {formSimpleChange} from "../../../../services/system/formService";
import TotalCommissionItems from "./ReportTotalCommissionItems";
import ReportTotalRemittanceInfo from "./ReportTotalRemittanceInfo";
import ReportTotalCommissionInfo from "./ReportTotalCommissionInfo";
import RemittanceItems from "./RemittanceItems";
import ReportRemittanceInfo from "./ReportRemittanceInfo";
import ReportBurnDownInfo from "./ReportBurnDownInfo";
import ReportBurnDownItems from "./ReportBurnDownItems";
import ReportCortexPayoutItems from "./ReportCortexPayoutItems";
import ReportCortexPayoutInfo from "./ReportCortexPayoutInfo";
import {noticeShowAction} from "../../../../../actions/noticeAction";
import ReportConsultationItems from "./ReportConsultationItems";
import ReportConsultationFilter from "./ReportConsultationFilters";
import ReportBankTotalPayoutItems from "./ReportBankTotalPayoutItems";
import {
    completedRemittanceAllDoctors, completedRemittanceCortexAdmin,
    fetchBillingPeriodAvailableYears,
    fetchBillingPeriods,
    updateRemittanceReferenceNumberFromFile
} from "../../../../services/billingSettingsService";
import {File} from "../../../../../components/elements/file";

class Report extends Component {
    constructor(props) {
        super(props);
        this.onPagination = this.onPagination.bind(this);
        this.onSendReport = this.onSendReport.bind(this);
        this.onChangePeriod = this.onChangePeriod.bind(this);
        this.onChangeBillingPeriodYear = this.onChangeBillingPeriodYear.bind(this);
        this.onChangeBillingPeriod = this.onChangeBillingPeriod.bind(this);
        this.onChangeDateCustomPeriod = this.onChangeDateCustomPeriod.bind(this);
        this.onChangeFileImport = this.onChangeFileImport.bind(this);
        this.state = {
            isDisabledExportOrSend: false,
            reportParams: {
                reportPeriod: 'BILLING_PERIOD', reportType: this.props.reportType,
                reportPeriodParams: null
            }
        }
    }

    componentWillUnmount() {
        this.props.onInitForm("reportParams");
        this.props.onInitList("reportItems");
    }

    componentDidMount() {
        this.props.onInitList("reportItems");
        this.onChangePeriod(this.state.reportParams.reportPeriod, this.state.reportParams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list: {data: curListData = {}}, form: {data: curFormData}} = this.props;
        const {list: {data: prevListData = {}}, form: {data: prevFormData}} = prevProps;

        const {
            billingPeriodAvailableYears: curBillingPeriodAvailableYears = [],
            billingPeriods: curBillingPeriods = []
        } = curListData;
        const {
            billingPeriodAvailableYears: prevBillingPeriodAvailableYears = [],
            billingPeriods: prevBillingPeriods = []
        } = prevListData;

        const {reportParams: curReportParams = null} = curFormData;
        const {reportParams: prevReportParams = null} = prevFormData;

        if (curReportParams && prevReportParams && curReportParams.reportPeriod == 'BILLING_PERIOD') {
            if (curBillingPeriodAvailableYears != prevBillingPeriodAvailableYears && curBillingPeriodAvailableYears.length > 0) {
                this.onChangeBillingPeriodYear(curBillingPeriodAvailableYears[0]);
            }

            if (curBillingPeriods != prevBillingPeriods && curBillingPeriods.length > 0) {
                this.onChangeBillingPeriod(curBillingPeriods[0].id);
            }
        }
    }

    onSendReport() {
        const {form: {data: formData}} = this.props;
        const {reportParams} = formData || {};
        this.props.onModalShow("sendReport", reportParams);
    }

    onPagination(pageNumber) {
        const {form: {data: formData}} = this.props;
        const {reportParams} = formData;
        const {reportPeriod, reportPeriodParams} = reportParams;
        const {dateFrom = null, dateTo = null} = reportPeriodParams || {};

        if (reportPeriod == 'CUSTOM' && (!dateFrom || !dateTo)) {
            return;
        }
        this.props.onGenerateReport(pageNumber, reportParams);
    }

    onChangePeriod(reportPeriod, reportParams = {}) {
        const {form: {data: formData}} = this.props;
        if (Object.keys(reportParams).length == 0 || reportParams == null) {
            reportParams = {...formData.reportParams};
        }

        reportParams = {...reportParams, reportPeriod: reportPeriod, reportPeriodParams: null};

        if (reportPeriod !== 'CUSTOM' && reportPeriod !== 'BILLING_PERIOD') {
            this.props.onGenerateReport(1, reportParams);
        } else if (reportPeriod === 'BILLING_PERIOD') {
            this.props.onFetchBillingPeriodAvailableYears();
        }
        this.props.onInitForm("reportParams", reportParams);
    }

    onChangeDateCustomPeriod(objMoment, fieldName) {
        const {form: {data: formData}} = this.props;
        const fieldValue = moment(objMoment).isValid() ? objMoment.format("YYYY-MM-DD") : null
        let {reportPeriodParams = {}} = formData.reportParams;
        reportPeriodParams = {...reportPeriodParams, [fieldName]: fieldValue};
        let reportParams = {...formData.reportParams, reportPeriodParams: reportPeriodParams};
        const {dateFrom, dateTo} = reportPeriodParams;
        let isDisabledExportOrSend = false;

        if (dateFrom && dateTo) {
            if (moment(dateFrom, "YYYY-MM-DD").isSameOrBefore(moment(dateTo, "YYYY-MM-DD"))) {
                this.props.onGenerateReport(1, reportParams);
            } else {
                this.props.onNotice("", <FormattedMessage id="report.period.incorrect.date"/>);
                isDisabledExportOrSend = true;
            }
        } else if (!dateFrom && !dateTo) {
            reportPeriodParams = {...reportPeriodParams, dateFrom: null, dateTo: null};
            reportParams = {...formData.reportParams, reportPeriodParams: reportPeriodParams};
            this.props.onInitList("reportItems");
        }

        this.setState({...this.state, reportParams: reportParams, isDisabledExportOrSend: isDisabledExportOrSend});
        this.props.onInitForm("reportParams", reportParams);
    }

    onChangeBillingPeriodYear(year) {
        const {form: {data: formData}} = this.props;
        let {reportPeriodParams = {}} = formData.reportParams;
        reportPeriodParams = {...reportPeriodParams, billingPeriodYear: year};
        let reportParams = {...formData.reportParams, reportPeriodParams: reportPeriodParams};
        this.props.onInitForm("reportParams", reportParams);
        this.props.onFetchBillingPeriods(year);
    }

    onChangeBillingPeriod(billingPeriodId) {
        const {form: {data: formData}} = this.props;
        let {reportPeriodParams = {}} = formData.reportParams;
        reportPeriodParams = {...reportPeriodParams, billingPeriodId: billingPeriodId, dateFrom: null, dateTo: null};
        let reportParams = {...formData.reportParams, reportPeriodParams: reportPeriodParams};
        this.props.onInitForm("reportParams", reportParams);
        this.props.onGenerateReport(1, reportParams);
    }

    onChangeFileImport(event) {
        const {form: {data: formData}} = this.props;
        let {reportPeriodParams = {}} = formData.reportParams;
        this.props.onUpdateRemittanceReferenceNumberFromFile(
            event, reportPeriodParams.billingPeriodId, () => this.onPagination(1));
    }

    render() {
        const {form: {data: formData}, list: {data: listData, pagination}, modal} = this.props;
        let {reportItems = [], billingPeriods = [], billingPeriodAvailableYears = []} = listData;
        const {number = 0, size = 0, totalElements = 0} = pagination['reportItems'] || {};
        const {reportParams = {}, systemSettings} = formData || {};
        const {paymentCurrency} = systemSettings || {};
        const {reportPeriod, reportPeriodParams, reportType} = reportParams;

        let {dateFrom = null, dateTo = null, billingPeriodId = null, billingPeriodYear = null} = reportPeriodParams || {};

        const {isDisabledExportOrSend} = this.state;

        if (reportParams && reportParams.reportType !== reportType)
            reportItems = [];

        if (billingPeriodYear == null && billingPeriodAvailableYears.length > 0) {
            billingPeriodYear = billingPeriodAvailableYears[0];
        }

        if (billingPeriodId == null && billingPeriods.length > 0) {
            billingPeriodId = billingPeriods[0].id;
        }

        const selectedBillingPeriod = billingPeriodId && billingPeriods.length > 0 ? billingPeriods.filter(i => i.id == billingPeriodId)[0] : null;
        const selectedOptionBillingPeriod = selectedBillingPeriod ?
            moment(selectedBillingPeriod.dateStart, "YYYY-MM-DD").format('MMM DD') + " - " +
            moment(selectedBillingPeriod.dateEnd, "YYYY-MM-DD").format('MMM DD') : 'undefined';
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col content-heading__main">
                        <Header as="h2">
                            {reportType === 'TOTAL_REMITTANCE' &&
                            <FormattedMessage id="report.total.remittance.header"/>}
                            {reportType === 'TOTAL_COMMISSION' &&
                            <FormattedMessage id="report.total.remittance.header"/>}
                            {reportType === 'REMITTANCE' && <FormattedMessage id="report.remittance.header"/>}
                            {reportType === 'BURN_DOWN' && <FormattedMessage id="report.burn.down.header"/>}
                            {reportType === 'CORTEX_PAYOUT' && <FormattedMessage id="report.cortex.payout.header"/>}
                            {reportType === 'CONSULTATION' && <FormattedMessage id="report.all.consultation.header"/>}
                            {reportType === 'BANK_TOTAL_PAYOUT' &&
                            <FormattedMessage id="report.bank.total.payout.header"/>}
                        </Header>
                    </div>
                    {reportItems.length > 0 &&
                    <div className="col-auto content-heading__controls">
                        {reportType === 'BANK_TOTAL_PAYOUT' && <EmptyTag>
                            <Button disabled={isDisabledExportOrSend}
                                    onClick={() => this.props.onCompletedRemittanceAllDoctors(billingPeriodId)}>
                                <FormattedMessage id="remittance.cleared"/>
                            </Button>

                            <Button disabled={isDisabledExportOrSend}
                                    onClick={() => this.props.onCompletedRemittanceCortexAdmin(billingPeriodId)}>
                                <FormattedMessage id="cortex.payout.cleared"/>
                            </Button>
                        </EmptyTag>}

                        <FormattedMessage id="download.report.pdf">
                            {title =>
                                <Button icon='file-black-pdf' title={title}
                                        square
                                        disabled={isDisabledExportOrSend}
                                        onClick={() => this.props.onExportReport({
                                            ...reportParams, reportFormat: 'PDF'
                                        })}/>
                            }
                        </FormattedMessage>
                        <FormattedMessage id="download.report.csv">
                            {title =>
                                <Button icon='file-black-csv' title={title}
                                        square
                                        disabled={isDisabledExportOrSend}
                                        onClick={() => this.props.onExportReport({
                                            ...reportParams, reportFormat: 'CSV'
                                        })}/>
                            }
                        </FormattedMessage>
                        {reportType === 'BANK_TOTAL_PAYOUT' &&
                        <File name="file" asButton buttonIcon={'file-black-upload'}
                              onChange={this.onChangeFileImport}/>}
                        <FormattedMessage id="send.report.on.email">
                            {title =>
                                <Button icon='file-black-share' title={title}
                                        square
                                        disabled={isDisabledExportOrSend}
                                        onClick={() => this.onSendReport()}/>
                            }
                        </FormattedMessage>
                    </div>}
                </div>
                <div className="row tile-row margin-bottom-15">
                    {reportType === 'TOTAL_REMITTANCE' &&
                    <ReportTotalRemittanceInfo dateFrom={dateFrom} dateTo={dateTo}/>}
                    {reportType === 'TOTAL_COMMISSION' &&
                    <ReportTotalCommissionInfo dateFrom={dateFrom} dateTo={dateTo}/>}
                    {reportType === 'REMITTANCE' && <ReportRemittanceInfo dateFrom={dateFrom} dateTo={dateTo}/>}
                    {reportType === 'BURN_DOWN' && <ReportBurnDownInfo dateFrom={dateFrom} dateTo={dateTo}/>}
                    {reportType === 'CORTEX_PAYOUT' &&
                    <ReportCortexPayoutInfo dateFrom={dateFrom} dateTo={dateTo} items={reportItems}/>}
                </div>
                <div className="row form-elements align-items-center filtr-form justify-content-end">
                    {reportType === 'CONSULTATION' && <ReportConsultationFilter/>}

                    <div className="col-auto padding-right-0">
                        <label className="field-label margin-bottom-0"><FormattedMessage id="report.period"/></label>
                    </div>

                    <div className="col-auto">
                        <FormattedMessage id={getReportPeriodInput(reportPeriod) || 'undefined'}>
                            {optionNameSelected => {
                                const isDisabled = reportType === 'BANK_TOTAL_PAYOUT';
                                return (<Select noLabel={true} height={40} width={180} bgColor={'white'}
                                                label={""}
                                                disabled={isDisabled}
                                                optionNameSelected={optionNameSelected}>
                                    {
                                        !isDisabled && getReportPeriodsInput().map(key =>
                                            <Option
                                                active={reportPeriod === key}
                                                key={key}
                                                onClick={() => this.onChangePeriod(key)}>
                                                {<FormattedMessage id={getReportPeriodInput(key)}/>}
                                            </Option>
                                        )
                                    }
                                </Select>)
                            }
                            }
                        </FormattedMessage>
                    </div>

                    {reportPeriod === 'CUSTOM' && <div className="col-auto">
                        <div className="row small-form-row align-items-center filtr-form">
                            <div className="col-auto">
                                <label className="field-label margin-bottom-0"><FormattedMessage
                                    id="from.label"/></label>
                            </div>
                            <div className="col-auto">
                                <DatePicter no_label={true}
                                            bg_white={true}
                                            height={40}
                                            width={180}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => this.onChangeDateCustomPeriod(v, 'dateFrom')}
                                            dateFormat={'ll'}/>
                            </div>
                            <div className="col-auto">
                                <label className="field-label margin-bottom-0"><FormattedMessage id="to.label"/></label>
                            </div>
                            <div className="col-auto">
                                <DatePicter no_label={true}
                                            bg_white={true}
                                            height={40}
                                            width={180}
                                            timeFormat={false}
                                            closeOnSelect={true}
                                            onChange={(v) => this.onChangeDateCustomPeriod(v, 'dateTo')}
                                            dateFormat={'ll'}/>
                            </div>
                        </div>
                    </div>}

                    {reportPeriod === 'BILLING_PERIOD' && <div className="col-auto">
                        <div className="row small-form-row align-items-center filtr-form">
                            <div className="col-auto">

                                <Select noLabel={true} height={40} width={180} bgColor={'white'}
                                        label={""}
                                        optionNameSelected={billingPeriodYear || 'undefined'}>
                                    {
                                        billingPeriodAvailableYears.map(year =>
                                            <Option
                                                active={billingPeriodYear == year}
                                                key={year}
                                                onClick={() => this.onChangeBillingPeriodYear(year)}>
                                                {year}
                                            </Option>
                                        )
                                    }
                                </Select>
                            </div>
                            <div className="col-auto">

                                <Select noLabel={true} height={40} width={220} bgColor={'white'} icon="calendar-gray-2"
                                        label={""}
                                        optionNameSelected={selectedOptionBillingPeriod || 'undefined'}>
                                    {
                                        billingPeriods.map(({id, dateStart, dateEnd}) =>
                                            <Option
                                                active={billingPeriodId == id}
                                                key={id}
                                                onClick={() => this.onChangeBillingPeriod(id)}>
                                                {moment(dateStart, "YYYY-MM-DD").format('MMM DD')} -
                                                {moment(dateEnd, "YYYY-MM-DD").format('MMM DD')}
                                            </Option>
                                        )
                                    }
                                </Select>
                            </div>
                        </div>
                    </div>}
                </div>

                {reportItems.length > 0 &&
                <div className="table-wrapper table-wrapper-shadow">

                    {reportType === 'TOTAL_REMITTANCE' &&
                    <TotalRemittanceItems items={reportItems} paymentCurrency={paymentCurrency}/>}
                    {reportType === 'TOTAL_COMMISSION' &&
                    <TotalCommissionItems items={reportItems} paymentCurrency={paymentCurrency}/>}
                    {reportType === 'REMITTANCE' && <RemittanceItems items={reportItems}/>}
                    {reportType === 'BURN_DOWN' &&
                    <ReportBurnDownItems items={reportItems} paymentCurrency={paymentCurrency}/>}
                    {reportType === 'CORTEX_PAYOUT' && <ReportCortexPayoutItems items={reportItems}/>}
                    {reportType === 'CONSULTATION' &&
                    <ReportConsultationItems items={reportItems} paymentCurrency={paymentCurrency}/>}
                    {reportType === 'BANK_TOTAL_PAYOUT' && <ReportBankTotalPayoutItems items={reportItems}/>}

                    <div className="col-12">
                        {size < totalElements && <Pagination
                            activeLinkClass={"active"}
                            activePage={number + 1}
                            itemsCountPerPage={size}
                            totalItemsCount={totalElements}
                            pageRangeDisplayed={5}
                            onChange={this.onPagination}/>}
                    </div>
                </div>}

                {isModal(modal, 'sendReport') && <SendReport/>}
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        tab: store.tab,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCompletedRemittanceAllDoctors: (billingPeriodId) => dispatch(completedRemittanceAllDoctors(billingPeriodId)),
        onCompletedRemittanceCortexAdmin: (billingPeriodId) => dispatch(completedRemittanceCortexAdmin(billingPeriodId)),
        onFetchBillingPeriodAvailableYears: () => dispatch(fetchBillingPeriodAvailableYears()),
        onFetchBillingPeriods: (year) => dispatch(fetchBillingPeriods(year)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue)),
        onModalShow: (name, data = {}) => dispatch(modalShow(name, data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onExportReport: (data) => dispatch(exportReport(data)),
        onGenerateReport: (pageNumber, data) => dispatch(generateReport(pageNumber, data)),
        onNotice: (title, textMsg) => dispatch(noticeShowAction(title, textMsg)),
        onUpdateRemittanceReferenceNumberFromFile:
            (event, billingPeriodId, callback) => dispatch(updateRemittanceReferenceNumberFromFile(event, billingPeriodId, callback))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);