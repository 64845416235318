import React from 'react';
import {connect} from "react-redux";

import {MenuItem} from "../../../components/collections/menu";
import 'react-notifications-component/dist/theme.css';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";

class PatientMainMenu extends React.Component {

    render() {
        const {auth: {user}} = this.props;
        const {isFillProfile} = user;
        return (
            <EmptyTag>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/patient/home'} icon="home">
                        <FormattedMessage id="core.layout.sidebar.item.home.label"/></MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/patient/medicalRecord'} icon="record" activeClassName="active"
                              disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.medical.record.label"/>
                    </MenuItem>
                </div>
      {/*          <div className="sidebar-menu__item">
                    <MenuItem to={'/chats'} icon="chat" activeClassName="active" disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.chats.label"/></MenuItem>
                </div>*/}
                <div className="sidebar-menu__item">
                    <MenuItem to={'/bookings'} icon="calendar" activeClassName="active"
                              disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.appointments.label"/></MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/patient/treatment'} icon="treatment" activeClassName="active"
                              disabled={!isFillProfile}>
                        <FormattedMessage id="core.layout.sidebar.item.treatment.label"/>
                    </MenuItem>
                </div>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/patient/catalogDoctors'} icon="doctors" activeClassName="active">
                        <FormattedMessage id="doctors.catalogue"/>
                    </MenuItem>
                </div>
            </EmptyTag>
        );
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientMainMenu);
