import React, {Component} from 'react';
import {connect} from "react-redux";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {Modal} from "../../../../components/modules/modal";
import {Header} from "../../../../components/elements/header";

import {FormattedMessage} from "react-intl";
import {Input} from "../../../../components/elements/input";
import {GridRow} from "../../../../components/collections/grid";
import {formChangeField} from "../../../services/system/formService";
import {formChangeFieldAction, formInitAction} from "../../../../actions/formAction";
import {Checkbox, Checkboxes} from "../../../../components/elements/checkbox";
import {Button, Buttons} from "../../../../components/elements/button";
import {RadioBox} from "../../../../components/elements/radiobox";
import {
    createAppointmentTemplate,
    fetchAppointmentColors, fullUpdateAppointmentTemplate,
    updateAppointmentTemplate
} from "../../../services/configureDoctorScheduleService";
import {arrayRemoveNullElements, stringToFloat, stringToInt} from "../../../../utils/app";
import {InputError} from "../../../../components/elements/error";
import {fetchProfileAddresses} from "../../../services/doctorProfileService";
import {Option, Select} from "../../../../components/elements/select";
import {Paragraph} from "../../../../components/elements/paragraph";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {noticeShowAction} from "../../../../actions/noticeAction";

class NewAppointmentTemplate extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onChangeInteger = this.onChangeInteger.bind(this);
        this.onChangeAppointmentColor = this.onChangeAppointmentColor.bind(this);
        this.onChangeTypeConsultation = this.onChangeTypeConsultation.bind(this);
        this.onSaveAppointmentTemplate = this.onSaveAppointmentTemplate.bind(this);
        this.onBlurPrice = this.onBlurPrice.bind(this);
    }

    onSelectAddress(address) {
        this.props.onChangeDropdown("address", address);
    }

    onChangeInteger(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChange("newAppointmentTemplate", target.name, stringToInt(value));
    }

    componentDidMount() {
        const {form: {data: formData}} = this.props;
        const {newAppointmentTemplate = {}} = formData;
        const {isCanFullUpdate, id} = newAppointmentTemplate;
        if (!isCanFullUpdate && id) {
            const textMsg = <FormattedMessage id="cat.not.full.update.appointment.template"/>
            this.props.onNoticeShow(textMsg);
        }
        this.props.onFetchAppointmentColors();
        this.props.onFetchDoctorProfileAddresses(newAppointmentTemplate.doctor.id);
    }

    onChange(event) {
        this.props.onFormChangeField('newAppointmentTemplate', event);
    }

    onChangeTypeConsultation(event, type) {
        const {form: {data: formData}} = this.props;
        const {newAppointmentTemplate = {}} = formData;
        const {appointmentTemplateTypes = []} = newAppointmentTemplate;
        const target = event.target;

        if (target.type === 'checkbox' && target.checked) {
            appointmentTemplateTypes.push({typeConsultation: type});
            this.props.onFormSimpleChange(
                'newAppointmentTemplate', 'appointmentTemplateTypes', appointmentTemplateTypes);
        } else {
            const index = appointmentTemplateTypes.findIndex(i => i.typeConsultation === type)
            delete appointmentTemplateTypes[index];
            this.props.onFormSimpleChange(
                'newAppointmentTemplate', 'appointmentTemplateTypes', arrayRemoveNullElements(appointmentTemplateTypes));
        }
    }

    onChangeAppointmentColor(color) {
        this.props.onFormSimpleChange('newAppointmentTemplate', 'color', color);
    }

    onBlurPrice(fieldName, value) {
        this.props.onFormSimpleChange('newAppointmentTemplate', fieldName, stringToFloat(value));
    }

    onSaveAppointmentTemplate(appointmentTemplate) {
        const {id = null} = appointmentTemplate;
        if (id == null) {
            this.props.onCreateAppointmentTemplate(appointmentTemplate);
        } else {
            const {form: {data: formData}} = this.props;
            const {newAppointmentTemplate = {}} = formData;
            const {isCanFullUpdate} = newAppointmentTemplate;
            if (isCanFullUpdate) {
                this.props.onFullUpdateAppointmentTemplate(appointmentTemplate);
            } else {
                this.props.onUpdateAppointmentTemplate(appointmentTemplate);
            }
        }
    }

    render() {
        const {form: {data: formData, errors: formErrors}, list: {data: listData}} = this.props;
        const {appointmentTemplateColors = [], doctorProfileAddresses = []} = listData;
        const {newAppointmentTemplate = {}, systemSettings = {}} = formData;
        const {
            id = null,
            name,
            duration,
            color = {id: null},
            appointmentTemplateTypes = [],
            address,
            price,
            newPrice,
            priceRequest,
            isCanFullUpdate
        } = newAppointmentTemplate;
        const {paymentCurrency} = systemSettings || {};
        const errors = formErrors.newAppointmentTemplate;
        const isEdit = id != null;
        let selectedAddress = '';
        if (address) {
            selectedAddress = address.name ? (address.name + " - " + address.text) : address.text;
        }

        return (
            <Modal close={true} onClose={() => this.props.onModalHide()}>
                <Header as="h2">
                    {isEdit && <FormattedMessage id="edit.appointment.type"/>}
                    {!isEdit && <FormattedMessage id="add.appointment.type"/>}
                </Header>
                <GridRow>
                    <div className="col-12">
                        <div className="text-block">
                            <Input name="name"
                                   value={name || ''}
                                   error={(errors && errors.name) || null}
                                   placeholder={<FormattedMessage id="name.appointment.type"/>}
                                   onChange={this.onChange}/>
                        </div>
                    </div>
                    <div className="col-12">
                        <Input name="duration"
                               value={duration || ''}
                               disabled={!isCanFullUpdate && isEdit}
                               error={(errors && errors.duration) || null}
                               placeholder={<FormattedMessage id="alloted.time.per.consultation"/>}
                               fixedTooltip={<FormattedMessage id="min"/>}
                               onChange={this.onChangeInteger}/>
                    </div>
                    <div className="col-12">
                        <label className="field-label"><FormattedMessage id="appointment.color"/></label>
                        <div className="square-labels-wrapper">
                            {appointmentTemplateColors.map(({id, code}) => {
                                return (
                                    <RadioBox key={'colors-' + id} isSquareColor={true} colorSquare={code} name="color"
                                              checked={color.id == id}
                                              onChange={() => this.onChangeAppointmentColor({id: id})}></RadioBox>
                                )
                            })}
                        </div>
                        {errors != null && errors['color'] && <InputError>{errors['color']}</InputError>}
                    </div>
                    <div className="col-12">
                        <label className="field-label"><FormattedMessage id="type.event"/></label>
                        <Checkboxes>
                            <div>
                                <Checkbox value='CHAT'
                                          disabled={!isCanFullUpdate && isEdit}
                                          isChecked={appointmentTemplateTypes.findIndex(i => i.typeConsultation === 'CHAT') !== -1}
                                          onChange={(event) => this.onChangeTypeConsultation(event, 'CHAT')}>
                                    <FormattedMessage id="chat.consultation"/>
                                </Checkbox>
                            </div>
                            <div>
                                <Checkbox value='VIDEO'
                                          disabled={!isCanFullUpdate && isEdit}
                                          isChecked={appointmentTemplateTypes.findIndex(i => i.typeConsultation === 'VIDEO') !== -1}
                                          onChange={(event) => this.onChangeTypeConsultation(event, 'VIDEO')}>
                                    <FormattedMessage id="video.consultation"/>
                                </Checkbox>
                            </div>
                            <div>
                                <Checkbox value='OFFLINE'
                                          disabled={!isCanFullUpdate && isEdit}
                                          isChecked={appointmentTemplateTypes.findIndex(i => i.typeConsultation === 'OFFLINE') !== -1}
                                          onChange={(event) => this.onChangeTypeConsultation(event, 'OFFLINE')}>
                                    <FormattedMessage id="offline.consultation"/>
                                </Checkbox>
                            </div>
                        </Checkboxes>
                        {errors != null && errors['appointmentTemplateTypes'] &&
                        <InputError>{errors['appointmentTemplateTypes']}</InputError>}
                    </div>

                    {appointmentTemplateTypes.findIndex(i => i.typeConsultation === 'OFFLINE') !== -1 &&
                    <div className="col-12">
                        <Select label={<FormattedMessage id="location"/>}
                                disabled={!isCanFullUpdate && isEdit}
                                error={(errors && errors['addresses']) || null}
                                optionNameSelected={selectedAddress}>
                            {
                                doctorProfileAddresses.map(i =>
                                    <Option
                                        active={address && i.id === address.id}
                                        key={i.id}
                                        onClick={() => this.onSelectAddress(i)}>
                                        {i.name && i.name + " - " + i.text}
                                        {!i.name && i.text}
                                    </Option>
                                )
                            }
                        </Select>
                    </div>}

                    {isEdit && <EmptyTag>
                        {price &&
                        <div className="col-12">
                            <label className="field-label"><FormattedMessage id="current.consultation.rate"/></label>
                            <Paragraph bold={true}>{paymentCurrency} {stringToFloat(price)}</Paragraph>
                        </div>}
                        <div className="col-12">
                            <Input name="newPrice"
                                   value={newPrice || ''}
                                   error={(errors && errors.newPrice) || null}
                                   placeholder={<FormattedMessage id="new.cost.for.consultation"/>}
                                   fixedTooltip={paymentCurrency}
                                   onBlur={() => this.onBlurPrice("newPrice", newPrice || '')}
                                   onChange={this.onChange}/>
                            <div className="field-tip">
                                {priceRequest && <EmptyTag>
                                    <FormattedMessage id="requested.consultation.rate"
                                                      values={{
                                                          price:
                                                              <strong>{parseFloat(priceRequest.price).toFixed(2)}</strong>,
                                                          currency: <strong>{paymentCurrency}</strong>
                                                      }}/>
                                    {priceRequest.status === 'REQUESTED' &&
                                    <div className="color-orange">
                                        <FormattedMessage
                                            id="request.update.price.consultation.status.requested.label"/>
                                    </div>}
                                    {priceRequest.status === 'APPROVED' &&
                                    <div className="color-green">
                                        <FormattedMessage id="request.update.price.consultation.status.approved.label"/>
                                    </div>}
                                    {priceRequest.status === 'CANCELED' &&
                                    <div className="color-red">
                                        <FormattedMessage id="request.update.price.consultation.status.canceled.label"/>
                                    </div>}
                                </EmptyTag>}
                            </div>
                        </div>
                    </EmptyTag>}

                    {!isEdit &&
                    <div className="col-12">
                        <Input name="price"
                               value={price || ''}
                               error={(errors && errors.price) || null}
                               placeholder={<FormattedMessage id="cost.for.consultation"/>}
                               fixedTooltip={paymentCurrency}
                               onBlur={() => this.onBlurPrice("price", price || '')}
                               onChange={this.onChange}/>
                    </div>}

                    <div className="col-12">
                        <Buttons width="auto">
                            <Button
                                color="white-red"
                                onClick={() => this.props.onModalHide()}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button
                                onClick={() => this.onSaveAppointmentTemplate(newAppointmentTemplate)}>
                                <FormattedMessage id="core.btn.save.label"/>
                            </Button>
                        </Buttons>
                    </div>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onNoticeShow: (textMsg) => dispatch(noticeShowAction("", textMsg)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onModalHide: () => dispatch(modalHide('newAppointmentTemplate')),
        onFetchAppointmentColors: () => dispatch(fetchAppointmentColors()),
        onCreateAppointmentTemplate: (data) => dispatch(createAppointmentTemplate(data)),
        onUpdateAppointmentTemplate: (data) => dispatch(updateAppointmentTemplate(data)),
        onFullUpdateAppointmentTemplate: (data) => dispatch(fullUpdateAppointmentTemplate(data)),
        onFetchDoctorProfileAddresses: (doctorId) => dispatch(fetchProfileAddresses(doctorId)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('newAppointmentTemplate', fieldName, fieldValue)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointmentTemplate);
