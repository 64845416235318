import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {Avatar} from "../../../../components/collections/avatar";
import {fetchConsultationComingSoon} from "../../../services/doctorService";
import {Link, withRouter} from "react-router-dom";
import {Button} from "../../../../components/elements/button";
import CountDownTimer from "./CountDownTimer";
import {startChatConsultation} from "../../../services/consultationService";
import {modalShow} from "../../../services/system/modalService";
import {getSex} from "../../../../utils/app";
import {FormattedMessage} from "react-intl";
import ButtonStartConsultation from "../bookings/detail/ButtonStartConsultation";

import {getExtraConsultationsByConsultation} from "../../../services/extraConsultationsService";

class ConsultationComingSoon extends Component {
    componentDidMount() {
        const tms = moment().hour(0).minute(0).second(0).unix();
        this.props.onListFilterField("dateOfReceipt", tms + "000");
        this.props.onListFilterField("status", "APPROVED");
        this.props.onListFilterField("isRescheduling", "false");
        this.props.onListFilterField("isStarted", "false");
        this.props.onListFilterField("consultationTypes", "CHAT-VIDEO");
        this.props.onListSorts({timeOfReceipt: 'asc'});
        this.props.onFetchConsultationComingSoon();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {list: {data: curListData = {}}, auth: {user}} = this.props;
        const {list: {data: prevListData = {}}} = prevProps;
        const {consultationComingSoon: curConsultationComingSoon = []} = curListData;
        const {consultationComingSoon: prevConsultationComingSoon = []} = prevListData;
        if (curConsultationComingSoon != prevConsultationComingSoon && curConsultationComingSoon.length > 0) {
            const {0: firstCurConsultationComingSoon = {}} = curConsultationComingSoon;
            if (Object.keys(firstCurConsultationComingSoon).length > 0 &&
                firstCurConsultationComingSoon.status !== 'REQUESTED' &&
                firstCurConsultationComingSoon.typeConsultation == 'VIDEO') {
                if (firstCurConsultationComingSoon.doctor.id == user.id) {
                    this.props.onFetchExtraConsultationsByConsultation(firstCurConsultationComingSoon.consultation.id);
                }
            }
        }
    }

    componentWillUnmount() {
        this.props.onInitList("extraConsultations");
    }

    render() {
        const {list: {data}} = this.props;
        const {consultationComingSoon = [], extraConsultations = []} = data;
        const {0: firstConsultationComingSoon = {}} = consultationComingSoon;
        const {id, patient = {}, symptoms = [], timeOfReceipt} = firstConsultationComingSoon || {};
        if (consultationComingSoon.length == 0) return "";
        return (

            <div className="white-block">
                <div className="white-block-header bg-color-blue">
                    <h4><FormattedMessage id="doctor.home.consultation-coming-soon"/></h4>
                    {timeOfReceipt && <CountDownTimer startTime={timeOfReceipt}/>}
                </div>
                <div className="row form-elements form-padding-small">
                    <div className="col-12">
                        <div className="small-text">{moment(timeOfReceipt).format("h:mm a")}</div>
                    </div>
                    <div className="col-12">
                        <div className="row avatar-row align-items-center">
                            <div className="col-auto avatar-row__image">
                                <Avatar width="58"/>
                            </div>
                            <div className="col avatar-row__text">
                                <div>{patient.firstName} {patient.lastName}</div>
                                <div className="small-text color-gray">
                                    <div>{symptoms && symptoms.map(symptom => symptom.name).join(", ")}</div>
                                    <div>
                                        {patient.sex && getSex(patient.sex)}
                                        {patient.sex && ", "}
                                        {patient.birthday && <FormattedMessage id="core.birthday.value"
                                                                               values={{value: moment().diff(patient.birthday, 'years')}}/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-submit controls-padding-20">
                            <ButtonStartConsultation booking={firstConsultationComingSoon}
                                                     extraConsultations={extraConsultations}
                                                     history={this.props.history}/>
                            <Link to={"/doctor/bookings/" + id + "/detail"}>
                                <Button color="white-blue"><FormattedMessage id="core.btn.detail.label"/></Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onInitList: (listName) => dispatch(listInitAction(listName)),
        onFetchExtraConsultationsByConsultation:
            (consultationId) => dispatch(getExtraConsultationsByConsultation(consultationId)),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onStartChatConsultation: (booking) => dispatch(startChatConsultation(booking)),
        onFetchConsultationComingSoon: () => dispatch(fetchConsultationComingSoon()),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("consultationComingSoon", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("consultationComingSoon", sorts))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConsultationComingSoon));
