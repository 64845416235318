import React from 'react';
import classnames from "classnames";

export const Avatar = (props) => {
    const {size = 0, width = 0, doctor = false, pharmacy = false, userId = null, file = null, multiple=false} = props;
    const avatarClassNames = classnames('avatar-item', {
        'avatar-size-80': (size === 80),
        'avatar-width-58': (width === 58),
        'avatar-item__photo-multiple' : multiple == true

    });
    const spanClassNames = classnames('avatar-item__photo', {
        'avatar-item__photo-doctor': doctor,
        'avatar-item__photo-pharmacy' : pharmacy
    });

    return (
        <div className={avatarClassNames}>
            <span className={spanClassNames}>
                {doctor && file && file.bytes && <img alt="" src={"data:;base64," + file.bytes} className="avatar-item-img"/>}
                {userId && (!file || !file.bytes) && <img alt="" src={window._env_.BACKEND_URL + "/v1/users/" + userId + "/profile/photo"} className="avatar-item-img"/>}
            </span>
            {props.children}
        </div>
    );
};

export const AvatarEdit = (props) => {
    const {size = 0, width = 0, doctor = false, bytes = null} = props;
    const avatarClassNames = classnames('avatar-item', {
        'avatar-size-80': (size === 80),
        'avatar-width-58': (width === 58),

    });
    const spanClassNames = classnames('avatar-item__photo', {
        'avatar-item__photo-doctor': doctor
    });

    return (
        <div className={avatarClassNames}>
            <span className={spanClassNames}>
                {doctor && bytes && <img alt="" src={"data:;base64," + bytes} className="avatar-item-img"/>}
            </span>
            {props.children}
        </div>
    );
};