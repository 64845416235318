import React, {Component} from 'react';
import Dropdown, {DropdownItem} from "../dropdown";
import {connect} from "react-redux";
import {IconItem} from "../../../components/elements/icon";
import {changeLanguage, fetchLanguages} from "../../services/commonService";
import {withRouter} from "react-router-dom";

class ChooseLanguage extends Component {
    constructor(props) {
        super(props);
        this.onChangeAppLanguage = this.onChangeAppLanguage.bind(this);
    }

    componentDidMount() {
        this.props.onFetchLanguages();
    }

    onChangeAppLanguage(language) {
        this.props.onChangeLanguage(language);
    }

    render() {
        const {list: {data: listData}, language: {locale}} = this.props;
        const {languages = []} = listData;
        const dropdownButton = <IconItem name={'lang-' + locale}/>
        const {pathname, search} = this.props.location;
        const to = pathname + search;
        return (
            <div className="public-block-lang">
                <Dropdown button={dropdownButton} arrow={true}>
                    {languages.map(language => {
                        return (
                            <DropdownItem
                                key={language.id}
                                icon={'lang-' + language.locale} to={to}
                                onClick={() => this.onChangeAppLanguage(language.locale)}>
                                {language.name}
                            </DropdownItem>
                        )
                    })}
                </Dropdown>
            </div>
        );
    }
};

const mapStateToProps = store => {
    return {
        language: store.language,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeLanguage : (language) => dispatch(changeLanguage(language)),
        onFetchLanguages: () => dispatch(fetchLanguages()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChooseLanguage));