import React from 'react';
import {connect} from "react-redux";

import {MenuItem} from "../../../components/collections/menu";
import 'react-notifications-component/dist/theme.css';
import {EmptyTag} from "../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
import Submenu, {SubmenuItem} from "../../systems/submenu";

class AdminBillingMainMenu extends React.Component {
    render() {

        console.log()

        return (
            <EmptyTag>
                <div className="sidebar-menu__item">
                    <MenuItem to={'/admin/billing/home'} icon="dashboard"><FormattedMessage
                        id="core.layout.sidebar.item.home.label"/></MenuItem>
                </div>

                <Submenu parentLabel={<FormattedMessage id="core.layout.sidebar.item.report.label"/>}
                         baseUrl={"/admin/billing/reports"}
                         parentUrl={"/admin/billing/reports/totalRemittances"}
                         parentIcon={'menu-item'}>
                    <SubmenuItem>
                        <MenuItem to={'/admin/billing/reports/totalRemittances'}><FormattedMessage
                            id="core.layout.sidebar.item.report.total.remittance.label"/></MenuItem>
                    </SubmenuItem>

                    <SubmenuItem>
                        <MenuItem to={'/admin/billing/reports/totalCommissions'}><FormattedMessage
                            id="core.layout.sidebar.item.report.total.commission.label"/></MenuItem>
                    </SubmenuItem>

                    <SubmenuItem>
                        <MenuItem to={'/admin/billing/reports/burnDown'}><FormattedMessage
                            id="core.layout.sidebar.item.report.burn.down.label"/></MenuItem>
                    </SubmenuItem>

                    <SubmenuItem>
                        <MenuItem to={'/admin/billing/reports/cortexPayout'}><FormattedMessage
                            id="core.layout.sidebar.item.report.cortex.payout.label"/></MenuItem>
                    </SubmenuItem>

                    <SubmenuItem>
                        <MenuItem to={'/admin/billing/reports/consultations'}><FormattedMessage
                            id="core.layout.sidebar.item.all.consultations.label"/></MenuItem>
                    </SubmenuItem>

                    <SubmenuItem>
                        <MenuItem to={'/admin/billing/reports/bankTotalPayout'}><FormattedMessage
                            id="core.layout.sidebar.item.bank.total.payout.label"/></MenuItem>
                    </SubmenuItem>
                </Submenu>

            </EmptyTag>
        );
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminBillingMainMenu);
