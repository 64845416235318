import {TAB_CHANGE_PANEL, TAB_CLEAR, TAB_FETCH} from "../constants/actionTypes";

export const tabFetchAction = (tabName, tabPanelName, data) => ({
    type: TAB_FETCH,
    tabName: tabName,
    tabPanelName: tabPanelName,
    data: data
});

export const tabClearAction = (tabName, tabPanelName, data) => ({
    type: TAB_CLEAR,
    tabName: tabName
});

export const tabChangePanelAction = (tabName, tabPanelName) => ({
    type: TAB_CHANGE_PANEL,
    tabName: tabName,
    tabPanelName: tabPanelName
});