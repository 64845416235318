import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {Header} from "../../../../components/elements/header";
import {EmptyTag} from "../../../../components/elements/emptyTag";

import {FormattedMessage} from "react-intl";
import Avatar from "../../../systems/avatar";
import {fetchDoctor,} from "../../../services/doctorService";
import {fetchSystemSettings} from "../../../services/commonService";
import Location from "../../../systems/location";
import {BackTo} from "../../../../components/elements/backTo";
import {Button} from "../../../../components/elements/button";
import {Link} from "react-router-dom";
import {formInitAction} from "../../../../actions/formAction";
import DoctorReviews from "./DoctorReviews";
import {Paragraph} from "../../../../components/collections/paragraph";


class CatalogDoctorDetail extends Component {

    componentDidMount() {
        const {match: {params}} = this.props;
        const {id = ""} = params || {};
        this.props.onFetchDoctor(id);
    }

    componentWillUnmount() {
        this.props.onInitForm("catalogDoctorDetail", {});
    }

    render() {
        const {form: {data: formData}, auth: {user}} = this.props;
        const {catalogDoctorDetail = {}, systemSettings = {}} = formData;

        const {id, firstName, lastName, doctor, email, phone, officePhone, speakLanguages = []} = catalogDoctorDetail;
        const {specializations = [], minPrice, maxPrice, practiceSince, address} = doctor || {};
        const currentYear = moment().format("yyyy")
        const experience = practiceSince ? currentYear - practiceSince : 0;

        if (address) {
            if (!address.latitude) address.latitude = null;
            if (!address.longitude) address.longitude = null;
        }

        const {isFillProfile, isEnabled} = user;
        return (
            <EmptyTag>
                <div className="row content-heading align-items-center">
                    <div className="col-auto content-heading__back">
                        <BackTo to={"/patient/catalogDoctors"}/>
                    </div>
                    <div className="col content-heading__main">
                        <Header as="h2">
                            {!id && <FormattedMessage id="doctors.catalogue"/>}
                            {id && <FormattedMessage id="core.doctor.name"
                                                     values={{firstName: firstName, lastName: lastName}}/>}
                        </Header>
                    </div>
                    <div className="col-auto content-heading__controls">
                        {id && (!isFillProfile || !isEnabled) &&
                            <Button disabled={!isFillProfile || !isEnabled}>
                                <FormattedMessage id="book.an.appointment"/>
                            </Button>}

                        {id && isFillProfile && isEnabled &&
                        <Link to={'/bookAnAppointment?doctorId=' + id}>
                            <Button>
                                <FormattedMessage id="book.an.appointment"/>
                            </Button>
                        </Link>}
                    </div>
                </div>

                <div className="row content-row">
                    <div className="col-12">
                        <div className="white-block">
                            <div className="row default-row">
                                <div className="col-md-7">
                                    <div className="row avatar-row avatar-row-detail">
                                        <div className="col-auto avatar-row__image">
                                            {id && <Avatar size={220} userId={id} doctor/>}
                                        </div>
                                        <div className="col avatar-row__text bigger-line-height">
                                            <div className="small-text">
                                                {specializations.map(i => i.name).join(", ")}
                                            </div>
                                            <h2 className="margin-top-bottom-10">
                                                <FormattedMessage id="core.doctor.name"
                                                                  values={{firstName: firstName, lastName: lastName}}/>
                                            </h2>
                                            <div>

                                                <FormattedMessage id="price.interval" values={{
                                                    currency:systemSettings.paymentCurrency,
                                                    from: (minPrice && parseFloat(minPrice).toFixed(2)) || '0',
                                                    to: (maxPrice && parseFloat(maxPrice).toFixed(2)) || '0'
                                                }}/>
                                            </div>
                                            <div>
                                                {experience > 0 && <FormattedMessage id="experience.years.value"
                                                                                     values={{experience: experience}}/>}
                                            </div>
                                            <div>
                                                <span className="color-gray">
                                                    <FormattedMessage id="mobile.phone"/>:</span>&nbsp;
                                                <span className="nowrap">{phone}</span>
                                            </div>
                                            <div>
                                                <span className="color-gray"><FormattedMessage
                                                    id="office.phone"/>:</span>&nbsp;
                                                <span className="nowrap">{officePhone}</span></div>
                                            <div>
                                                <span className="color-gray">
                                                    <FormattedMessage id="core.field.email.label"/>:</span>&nbsp;
                                                <a href={'mailto:' + email} className="no-underline color-black"
                                                   target="_blank">{email}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-5">
                                    {address && address.text && <EmptyTag>
                                        <h3><FormattedMessage id="location"/></h3>
                                        <div className="margin-bottom-15">{address && address.text}</div>
                                        {address.latitude && address.longitude &&
                                        <div className="map-container map-height-200">
                                            <Location latitude={address.latitude} longitude={address.longitude}/>
                                        </div>}
                                    </EmptyTag>
                                    }
                                </div>

                                {doctor && doctor.description &&
                                <div className="col-12">
                                    <h2><FormattedMessage id="information.about.doctor"/></h2>
                                    <div><Paragraph>{doctor && doctor.description}</Paragraph></div>
                                </div>
                                }

                                {speakLanguages.length > 0 &&
                                    <div className="col-12">
                                        <h2><FormattedMessage id="communication"/></h2>
                                        <div><Paragraph>{speakLanguages.map(i => i.name).join(", ")}</Paragraph></div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {id && <DoctorReviews doctorId={id}/>}
                </div>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onInitForm: (formName, data) => dispatch(formInitAction(formName, data)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings()),
        onFetchDoctor: (doctorId) => dispatch(fetchDoctor(doctorId, "catalogDoctorDetail")),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogDoctorDetail);
