import React, {Component} from 'react';
import {connect} from "react-redux";
import {googleMapAddMarker, loadGoogleMap} from "../../services/googleMapService";

class Location extends Component {
    constructor(props) {
        super(props);
        this.state = {map: null, marker: null}
    }

    componentDidMount() {
        const {language: {locale}} = this.props;
        const callback = this.loadMapCallback;
        loadGoogleMap({
            language: locale,
            apiKey: window._env_.GOOGLE_API_KEY,
            callback
        });
    }

    loadMapCallback = () => {
        const {latitude, longitude} = this.props;

        let marker = this.state.marker;
        const map = new window.google.maps.Map(document.getElementById("google-map"), {
            center: {lat: latitude, lng: longitude},
            zoom: 8,
        });
        this.setState({...this.state, map: map});

        if (latitude && longitude) {
            marker = googleMapAddMarker({
                map: map,
                latitude: latitude,
                longitude: longitude,
                draggable: false
            });
            this.setState({...this.state, marker: marker});
        }

    };

    render() {
        return (
            <div className="map-body" id="google-map"></div>
        )
    }
}

const mapStateToProps = store => {
    return {
        language: store.language
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
