import React, {Component} from 'react';
import {connect} from "react-redux";

import {Redirect} from "react-router";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Input} from "../../../components/elements/input";
import {Buttons, Button} from "../../../components/elements/button";
import Password from "../../systems/password";
import {formChangeField, formInit, formSimpleChange} from "../../services/system/formService";
import {TabPane, TabPanes} from "../../../components/modules/tab";
import {googleUserInfo, signUpDoctor} from "../../services/userService";
import qs from "query-string";
import {formChangeFieldAction} from "../../../actions/formAction";
import {getSexInput, getSexsInput, stringToFloat, stringToInt} from "../../../utils/app";
import {MainLogo} from "../../../components/elements/logo";

import moment from "moment-timezone";
import {FormattedMessage} from "react-intl";
import {EmptyTag} from "../../../components/elements/emptyTag";
import ChooseSpecialization from "./ChooseSpecialization";
import {Label} from "../../../components/elements/label";
import Birthday from "../../systems/birthday";
import {listFilterFieldAction} from "../../../actions/listAction";
import {Option, Select} from "../../../components/elements/select";
import {fetchCountries} from "../../services/commonService";

class SignUpDoctor extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectSex = this.onSelectSex.bind(this);
        this.onChangeInteger = this.onChangeInteger.bind(this);
        this.onChangeFloat = this.onChangeFloat.bind(this);
        this.onChooseSpecializations = this.onChooseSpecializations.bind(this);
        this.onChangeBirthday = this.onChangeBirthday.bind(this);
        this.onSelectCountry = this.onSelectCountry.bind(this);
    }

    componentDidMount() {
        this.props.onSignUpInit();
        const p = this.props.location.search;
        const {accessToken = ""} = qs.parse(p);
        if (accessToken !== "") {
            this.props.onGoogleUserInfo(accessToken);
        }
        this.props.onFetchCountries();
        this.props.onFormSimpleChange("signUp", "timezone", moment.tz.guess(true));
        this.props.onFormSimpleChange("signUp", "isAgree", true);
    }

    onSelectCountry(country) {
        if (country) {
            this.props.onFormSimpleChangeField("signUp", "countryIso", country.iso);
        } else {
            this.props.onFormSimpleChangeField("signUp", "countryIso", null);
        }
    }

    onChangeInteger(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChange("signUp", target.name, stringToInt(value));
    }

    onChangeFloat(event) {
        const target = event.target;
        let value = target.value;
        this.props.onFormSimpleChangeField('signUp', target.name, stringToFloat(value));
    }

    onChange(event) {
        this.props.onFormChangeField('signUp', event);
    }

    onSelectSex(id) {
        this.props.onChangeDropdown("sex", id);
    }

    onChooseSpecializations(specializations) {
        this.props.onChangeDropdown("doctor.specializations", specializations);
    }

    onChangeBirthday(day, month, year) {
        if (day != null && month != null && year != null) {
            const birthday = moment().set({'date': day, 'month': month, 'year': year});
            if (moment([year, month, day]).isValid()) {
                this.props.onFormSimpleChange("signUp", "birthday", birthday.format("YYYY-MM-DD"));

            } else {
                this.props.onFormSimpleChange("signUp", "birthday", null);
            }
        } else {
            this.props.onFormSimpleChange("signUp", "birthday", null);
        }
    }

    render() {
        const {form, auth, list: {data: listData}} = this.props;
        const errors = form.errors.signUp;
        const disabled = form.isSend.signUp || false;
        const newUser = form.data.signUp || {};
        const {countries = []} = listData;

        if (auth.isAuthenticated) return <Redirect to="/"/>;
        const chooseSpecialisations = newUser && newUser.doctor && newUser.doctor.specializations || [];

        let countryName = ""
        if (newUser && newUser['countryIso']) {
            const countryIndex = countries.findIndex(i => i.iso == newUser['countryIso'])
            if (countryIndex > -1) {
                countryName = countries[countryIndex].name
            }
        }

        return (
            <EmptyTag>
                <MainLogo/>
                <TabPanes>
                    <TabPane>
                        <GridRow>
                            <GridCol count="2">
                                <Input name="firstName"
                                       icon="user"
                                       value={newUser.firstName || ''}
                                       error={(errors && errors.firstName) || null}
                                       placeholder={<FormattedMessage id="core.first.name.label"/>}
                                       disabled={disabled}
                                       onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="2">
                                <Input name="lastName"
                                       icon="user"
                                       value={newUser.lastName || ''}
                                       error={(errors && errors.lastName) || null}
                                       placeholder={<FormattedMessage id="core.last.name.label"/>}
                                       disabled={disabled}
                                       onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="2">
                                <Input name="email"
                                       icon="email"
                                       value={newUser.email || ''}
                                       error={(errors && errors.email) || null}
                                       placeholder={<FormattedMessage id="core.field.email.label"/>}
                                       autocomplete="email"
                                       disabled={disabled}
                                       onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="2">
                                <Input name="doctor.licenseNumber"
                                       icon="phone"
                                       value={(newUser.doctor && newUser.doctor.licenseNumber) || ''}
                                       error={errors && (errors["doctor.licenseNumber"] || errors['doctor']) ?
                                           errors["doctor.licenseNumber"] || errors['doctor'] : null}
                                       placeholder={<FormattedMessage id="core.license.number.label"/>}
                                       disabled={disabled}
                                       onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="2">
                                <Label>{<FormattedMessage id="core.birthday.label"/>}</Label>
                                <Birthday error={(errors && errors.birthday) || null}
                                          value={(newUser.birthday && moment(newUser.birthday).format("DD.MM.YYYY")) || null}
                                          onChangeBirthday={this.onChangeBirthday}/>
                            </GridCol>
                            <GridCol count="2">
                                <Input name="phone"
                                       icon="phone"
                                       error={(errors && errors.phone) || null}
                                       placeholder={<FormattedMessage id="core.phone.label"/>}
                                       disabled={disabled}
                                       onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="2">
                                <FormattedMessage id={getSexInput(newUser['sex']) || 'undefined'}>
                                    {optionNameSelected =>
                                        <Select name="sex"
                                                error={(errors && errors.sex) || null}
                                                disabled={disabled}
                                                label={<FormattedMessage id="core.gender.label"/>}
                                                optionNameSelected={optionNameSelected}>
                                            {
                                                getSexsInput().map(key =>
                                                    <Option
                                                        active={newUser && newUser['sex'] && newUser['sex'] === key}
                                                        key={key}
                                                        onClick={() => this.onSelectSex(key)}>
                                                        {<FormattedMessage id={getSexInput(key)}/>}
                                                    </Option>
                                                )
                                            }
                                        </Select>}
                                </FormattedMessage>
                            </GridCol>
                            <GridCol count='2'>
                                <Select name="countryIso"
                                        label={<FormattedMessage id="country"/>}
                                        optionNameSelected={countryName || 'undefined'}>
                                    {countries.map(i => {
                                        return (
                                            <Option
                                                active={newUser && newUser['countryIso'] && newUser['countryIso'] === i.id}
                                                key={'country-' + i.id}
                                                onClick={() => this.onSelectCountry(i)}>
                                                {i.name}
                                            </Option>
                                        )
                                    })}
                                </Select>
                            </GridCol>
                            <ChooseSpecialization
                                error={errors && (errors["doctor.specializations"] || errors['doctor']) ?
                                    errors["doctor.specializations"] || errors['doctor'] : null}
                                choosed={chooseSpecialisations || []}
                                onChoose={this.onChooseSpecializations}/>

                            <GridCol count="2">
                                <input type="hidden" value=""/>
                                <Password name="password"
                                          icon="password"
                                          placeholder={<FormattedMessage id="core.field.password.label"/>}
                                          error={(errors && errors.password) || null}
                                          disabled={disabled}
                                          onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="2">
                                <Password name="matchingPassword"
                                          icon="password"
                                          placeholder={<FormattedMessage id="core.field.password.repeat.label"/>}
                                          error={(errors && errors.matchingPassword) || null}
                                          disabled={disabled}
                                          onChange={this.onChange}/>
                            </GridCol>
                        </GridRow>
                        <GridRow width={300}>
                            <GridCol count="1">
                                <Buttons>
                                    <Button disabled={disabled}
                                            preloader={disabled === true}
                                            onClick={() => this.props.onSignUp(form.data.signUp)}>
                                        <FormattedMessage id="sing.up.as.doctor"/></Button>
                                </Buttons>
                            </GridCol>
                            <GridCol count="1">
                                <Buttons>
                                    <p className="small-text"><FormattedMessage id="core.already.have.account.label"/>
                                    </p>
                                    <a href={'/users/sign-in'} className="btn-default btn-blue-bordered">
                                        <div className="btn__content">
                                            <FormattedMessage id="core.btn.sign-in.label"/>
                                        </div>
                                    </a>
                                </Buttons>
                            </GridCol>
                        </GridRow>
                    </TabPane>
                </TabPanes>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth,
        list: store.list,
        language: store.language
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchCountries: () => dispatch(fetchCountries()),
        onListFilterField: (listName, fieldName, fieldValue) => dispatch(listFilterFieldAction(listName, fieldName, fieldValue)),
        onSignUpInit: () => dispatch(formInit("signUp", {})),
        onSignUp: (data) => dispatch(signUpDoctor(data)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onGoogleUserInfo: (googleAccessToken) => dispatch(googleUserInfo(googleAccessToken)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('signUp', fieldName, fieldValue)),
        onFormSimpleChangeField: (formName, fieldName, fieldValue) => dispatch(formSimpleChange(formName, fieldName, fieldValue))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpDoctor);