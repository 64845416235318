import {CHOOSE_LANGUAGE} from "../constants/actionTypes";

const initialState = {
    locale: ""
};

const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHOOSE_LANGUAGE:
            return {...state, locale: action.locale};
        default:
            return state;
    }
};

export default languageReducer;