import React, {Component} from 'react';
import {connect} from "react-redux";

import {EmptyTag} from "../../../../../components/elements/emptyTag";
import {floatFormatting} from "../../../../../utils/app";
import {FormattedMessage} from "react-intl";

class ReportTotalCommissionItems extends Component {

    render() {
        const {items, paymentCurrency} = this.props;
        return (
            <EmptyTag>
                <table className="admin-table">
                    <thead>
                    <tr>
                        <th><FormattedMessage id="report.doctor.name"/></th>
                        <th><FormattedMessage id="report.total.consultation.amount" values={{currency: paymentCurrency}}/></th>
                        <th><FormattedMessage id="report.total.commission.amount" values={{currency: paymentCurrency}}/>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(({doctor, totalConsultationAmount, totalCommissionAmount}) => {
                        return (
                            <tr key={doctor.id}>
                                <td> {doctor.firstName} {doctor.lastName}</td>
                                <td>{floatFormatting(totalConsultationAmount)}</td>
                                <td>{floatFormatting(totalCommissionAmount)}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </EmptyTag>
        )
    }
}

const mapStateToProps = store => {
    return {}
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTotalCommissionItems);