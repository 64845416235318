import {HANDSET_NO_RING, HANDSET_RING} from "../constants/actionTypes";

const initialState = {
    ring: false
};

const handsetReducer = (state = initialState, action) => {
    switch (action.type) {
        case HANDSET_NO_RING:
            return {...state, ring: false};
        case HANDSET_RING:
            return {...state, ring: true};
        default:
            return state;
    }
};

export default handsetReducer;