import React from 'react';
import classnames from "classnames";

export const GridRow = (props) => {
    const {width = 0, bordered = 'none'} = props;
    const gridRowClassNames = classnames('row', 'form-elements', {
        'form-center-300': (width === 300),
        'form-bottom-bordered' : (bordered === 'bottom')
    });
    return (
        <div className={gridRowClassNames}>
            {props.children}
        </div>
    );
};

export const GridCol = (props) => {
    const {count = '', align = '', border = ''} = props;
    const gridColClassNames = classnames({
        'col-12': (count == '1'),
        'col-md-6': (count == '2'),
        'col-md-4': (count == '3'),
        'col-md-2': (count == '4'),
        'align-center': (align == 'center'),
        'align-left': (align == 'left'),
        'align-right': (align == 'right'),
        "col-bottom-bordered" : border == 'border-bottom'
    });
    return (
        <div className={gridColClassNames}>{props.children}</div>
    );
};
