import React, {Component} from 'react';
import {connect} from "react-redux";

import {Button} from "../../../../../components/elements/button";
import {FormattedMessage} from "react-intl";
import {Icon} from "../../../../../components/elements/icon";

class BookingDetailChatEmpty extends Component {
    render() {
        return (
            <div className="col-12">
                <div className="no-results-block">
                    <Icon name={'clipart-chat'}/>
                    <div className="color-gray"><FormattedMessage id="booking.detail.chat.empty.chat.list.description"/></div>
                    <Button onClick={this.props.onReload}>
                        <FormattedMessage id="reload"/>
                    </Button>
                </div>

            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        list: store.list,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailChatEmpty);
