import React, {Component} from 'react';
import {connect} from "react-redux";

import {formChangeFieldAction} from "../../../../actions/formAction";
import _ from "lodash";
import {formChangeField} from "../../../services/system/formService";
import {arrayRemoveNullElements, getFileExt} from "../../../../utils/app";
import * as filesize from "filesize";
import {File} from "../../../../components/elements/file";
import {modalHide, modalShow} from "../../../services/system/modalService";
import {uploadFileChunk} from "../../../services/fileService";
import {downloadPathologicalReportFile} from "../../../services/patientService";
import {EmptyTag} from "../../../../components/elements/emptyTag";
import {FormattedMessage} from "react-intl";
class PathologicalReports extends Component {
    constructor(props) {
        super(props);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onRemovePathologicalReport = this.onRemovePathologicalReport.bind(this);
    }

    onRemovePathologicalReport(pathologicalReport) {
        this.props.onModalHide("confirm");
        const {form: {data}} = this.props;
        const {medicalRecord} = data;
        const pathologicalReports = medicalRecord.pathologicalReports || [];

        const index = pathologicalReports.findIndex(value => value.file.id === pathologicalReport.file.id);
        if (index > -1) {
            _.unset(medicalRecord, "pathologicalReports[" + index + "]");
            this.props.onFormSimpleChangeField("pathologicalReports", arrayRemoveNullElements(pathologicalReports));
        }
    }

    onChangeFile(event) {
        const target = event.target;
        const fieldName = target.name;
        const files = event.target.files;
        const onCallback = (response) => {
            const {form: {data}} = this.props;
            const {uploadFileDescription} = data;
            const description = uploadFileDescription.description || "";
            this.props.onFormSimpleChangeField(fieldName + '.file', response.data);
            this.props.onFormSimpleChangeField(fieldName + '.name', description);
        };

        this.props.onShowModal("uploadFileDescription", {
            files: target.files,
            onSend: () => this.props.onUploadFile(files, onCallback)
        });
    }

    render() {
        const {form: {data}} = this.props;
        const {medicalRecord = {}} = data;
        const pathologicalReports = medicalRecord.pathologicalReports || [];
        return (
            <div className="row form-elements form-bottom-bordered file-previews">
                {pathologicalReports.map((pathologicalReport, index) => {
                    const {name, file} = pathologicalReport;
                    return (
                        <div className="col-md-4" key={index}>
                            <div>
                                {pathologicalReport.id && <a href="#"
                                                             onClick={() => this.props.onDownloadFile(medicalRecord, pathologicalReport)}>{name || file.name} </a>}
                                {!pathologicalReport.id && <EmptyTag>{name || file.name}</EmptyTag>}

                            </div>
                            <div className="small-text color-gray">
                                {getFileExt(file.name)}, {filesize(file.size, {separator: "."})}
                            </div>
                            <div className="controls-top-right">
                                <button type="button"
                                        className="btn-delete"
                                        onClick={() => this.props.onConfirm({
                                            text: <FormattedMessage id="confirm.remove.pathological.report.text"/>,
                                            onProceed: (() => this.onRemovePathologicalReport(pathologicalReport))
                                        })}>×
                                </button>
                            </div>
                        </div>
                    )
                })}

                <div className="col-12">
                    <File name={'pathologicalReports[' + pathologicalReports.length + ']'}
                          onChange={this.onChangeFile}><FormattedMessage id="core.btn.add.label"/>
                    </File>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        modal: store.modal,
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onShowModal: (name, data) => dispatch(modalShow(name, data)),
        onModalHide: (name) => dispatch(modalHide(name)),
        onFormSimpleChangeField: (fieldName, fieldValue) => dispatch(formChangeFieldAction('medicalRecord', fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onUploadFile: (files, onCallback) => dispatch(uploadFileChunk(files, onCallback)),
        onDownloadFile: (medicalRecord, pathologicalReport) => dispatch(downloadPathologicalReportFile(medicalRecord, pathologicalReport)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PathologicalReports);