import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {listFetchAction} from "../../actions/listAction";
import {modalHide} from "./system/modalService";
import {formChangeFieldAction, formInitAction} from "../../actions/formAction";
import {formErrors} from "./system/formService";
import qs from "qs";

export const fetchDoctorPrice = (doctorId, timeOfReceipt, formName = "doctorPrice") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const config = {
            params: {timeOfReceipt: timeOfReceipt}
        };
        return privateApi.get('/v1/doctors/' + doctorId + '/price', config)
            .then(response => {
                const {data} = response;
                dispatch(formInitAction(formName, {price: data}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctorSchedule = (doctorId, dateStart, dateEnd, listName = "doctorSchedules", callback = null) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const config = {
            params: {dateStart: dateStart, dateEnd: dateEnd}
        };
        return privateApi.get("/v1/doctors/" + doctorId + "/schedules", config)
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction(listName, data, {}));
                if (callback)
                    callback(data);
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctorScheduleAvailableAddresses = (doctorId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/doctors/" + doctorId + "/schedules/availableAddresses")
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("doctorProfileAddresses", data, {}));
            }).catch(error => {
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const fetchAllScheduleAvailableAddresses = (specializationId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/doctors/schedules/availableAddresses?specializationId=" + specializationId)
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction("allDoctorsProfileAddresses", data, {}));
            }).catch(error => {
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const fetchDoctorScheduleContextOfDay = (doctorId, dateStart, dateEnd, formName = "doctorSchedulesContextOfDay") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const config = {
            params: {dateStart: dateStart, dateEnd: dateEnd}
        };
        return privateApi.get("/v1/doctors/" + doctorId + "/schedules/contextOfDay", config)
            .then(response => {
                const {data} = response;
                return dispatch(formInitAction(formName, data));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const scheduleQuickBooking = (specializationId, typeConsultation, addressId) => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/doctors/scheduleQuickBooking",
            qs.stringify({
                specializationId: specializationId,
                typeConsultation: typeConsultation,
                addressId: addressId
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then(response => {
                const {doctor, timeOfReceipt} = response.data;
                dispatch(formChangeFieldAction("createBooking", "doctor", doctor));
                dispatch(formChangeFieldAction("createBooking", "timeOfReceipt", timeOfReceipt));
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("createBooking", error.response.data.details));
                }
                dispatch(formChangeFieldAction("createBooking", "doctor", null));
                dispatch(formChangeFieldAction("createBooking", "timeOfReceipt", null));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const fetchDoctorScheduleAppointmentDays = (doctorId, dateStart, dateEnd, listName = "appointmentDays") => {
    return (dispatch) => {
        dispatch(preloaderPageShowAction());
        const config = {
            params: {dateStart: dateStart, dateEnd: dateEnd}
        };
        return privateApi.get("/v1/doctors/" + doctorId + "/schedules/appointmentDays", config)
            .then(response => {
                const {data} = response;
                dispatch(listFetchAction(listName, data, {}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};

export const removeDoctorSchedule = (scheduleId, callback = null) => {
    return (dispatch, state) => {
        const {doctor} = state().form.data['tableSchedule'] || {};
        dispatch(modalHide("confirm"));
        dispatch(preloaderPageShowAction());
        return privateApi.delete("/v1/doctors/" + doctor.id + "/schedules/" + scheduleId)
            .then(response => {
                if (callback)
                    callback();
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => {
                dispatch(preloaderPageHideAction());
            });
    }
};