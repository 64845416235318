import React, {Component} from 'react';
import {connect} from "react-redux";

import _ from "lodash";
import {FormattedMessage} from "react-intl";
import {modalHide} from "../../../../services/system/modalService";
import {formChangeFieldAction} from "../../../../../actions/formAction";
import {formChangeField} from "../../../../services/system/formService";
import {Button, ButtonIcon, ButtonRemove, Buttons} from "../../../../../components/elements/button";
import {GridCol, GridRow} from "../../../../../components/collections/grid";
import {Input} from "../../../../../components/elements/input";
import {Header} from "../../../../../components/elements/header";
import {Modal} from "../../../../../components/modules/modal";
import {arrayRemoveNullElements} from "../../../../../utils/app";
import {RadioBox, RadioBoxes} from "../../../../../components/elements/radiobox";
import {sendReport} from "../../../../services/billingReportService";

class SendReport extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
    }

    componentDidMount() {
        this.props.onFormSimpleChange("reportFormat", "PDF");
    }

    onChange(event) {
        this.props.onFormSimpleChange(event.target.name, event.target.value);
    }

    onAdd() {
        const {form: {data}} = this.props;
        const {sendReport: {emails = [""]}} = data;
        emails.push("");
        this.props.onFormSimpleChange("emails", emails);
    }

    onRemove(item) {
        const {form: {data}} = this.props;
        const {sendReport} = data;
        _.unset(sendReport, item);
        this.props.onFormSimpleChange("emails", arrayRemoveNullElements(sendReport.emails));
    }

    render() {
        const {form: {data: formData, errors: formErrors, isSend: formIsSend}} = this.props;
        const {sendReport} = formData;
        const {emails = [""], reportFormat = 'PDF'} = sendReport;
        const errors = formErrors['sendReport'];
        const disabled = formIsSend['sendReport'] || false;

        return (
            <Modal width={700} close={true} onClose={() => this.props.onModalHide()}>
                <Header as="h2"><FormattedMessage id="modal.send.report.title"/></Header>
                <GridRow>
                    <GridCol count='1'>
                        <RadioBoxes error={(errors && errors.reportFormat) || null}>
                            <RadioBox name='reportFormat'
                                      value='PDF'
                                      disabled={disabled}
                                      checked={reportFormat === 'PDF'}
                                      onChange={this.onChange}><FormattedMessage id="pdf"/></RadioBox>
                            <RadioBox name='reportFormat'
                                      value='CSV'
                                      disabled={disabled}
                                      checked={reportFormat === 'CSV'}
                                      onChange={this.onChange}><FormattedMessage id="csv"/></RadioBox>
                        </RadioBoxes>
                    </GridCol>

                    {emails.map((email, index) => {
                        const name = "emails[" + index + "]"
                        return (
                            <GridCol count="1" key={index}>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <Input name={name}
                                               placeholder={<FormattedMessage id="email"/>}
                                               error={(errors && errors["emails[" + index + "]"]) || (index === 0 && errors && errors["emails"]) || null}
                                               disabled={disabled}
                                               onChange={this.onChange}
                                               value={email}/>
                                    </div>
                                    <div className="col-auto relative-controls">
                                        {emails.length > 1 &&
                                            <ButtonRemove onClick={() => this.onRemove(name)} disabled={disabled}/>}

                                        {index + 1 === emails.length && emails.length < 5 &&
                                            <ButtonIcon icon="plus-bordered" onClick={this.onAdd} disabled={disabled}/>}
                                    </div>
                                </div>
                            </GridCol>
                        );
                    })}
                    <GridCol count="1">
                        <Buttons width="auto">
                            <Button disabled={disabled}
                                    color="white-red"
                                    preloader={false}
                                    onClick={() => this.props.onModalHide()}>
                                <FormattedMessage id="core.btn.cancel.label"/>
                            </Button>
                            <Button disabled={disabled}
                                    preloader={formIsSend['sendReport'] || false}
                                    onClick={() => this.props.onSend(sendReport)}>
                                <FormattedMessage id="core.btn.send.label"/>
                            </Button>
                        </Buttons>
                    </GridCol>
                </GridRow>
            </Modal>
        )
    }
}

const mapStateToProps = store => {
    return {
        auth: store.auth,
        modal: store.modal,
        form: store.form,
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSend: (data) => dispatch(sendReport(data)),
        onModalHide: () => dispatch(modalHide('sendReport')),
        onFormSimpleChange: (fieldName, fieldValue) => dispatch(formChangeFieldAction('sendReport', fieldName, fieldValue)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SendReport);