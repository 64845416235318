import React from 'react';
import {FormattedMessage} from "react-intl";
import Dropdown, {DropdownItem} from "../../../containers/systems/dropdown";
import {Buttons} from "../../elements/button";

const dropDownButton = <a href={'#'} className="btn-default">
    <div className="btn__content">
        <FormattedMessage id="core.btn.sign.up.label"/>
    </div>
</a>

export const ButtonSignup = () => {
    return (
        <Buttons>
            <p className="small-text"><FormattedMessage id="core.not.member.yet.label"/></p>
            <div className="select-block select-button select-button-blue-bordered">
                <Dropdown button={dropDownButton}>

                    <DropdownItem to={'/users/acceptTerms?role=patient'}
                                  onClick={null}>
                        <FormattedMessage id="sing.up.as.patient"/>
                    </DropdownItem>

                    <DropdownItem to={'/users/acceptTerms?role=doctor'}
                                  onClick={null}>
                        <FormattedMessage id="sing.up.as.doctor"/>
                    </DropdownItem>
                </Dropdown>
            </div>
        </Buttons>
    );
};