import React, {Component} from 'react';
import {connect} from "react-redux";
import {listFilterFieldAction, listInitAction, listSortAction} from "../../../../actions/listAction";
import {fetchBookings, rejectBookingList} from "../../../services/bookingService";
import {GridCol} from "../../../../components/collections/grid";
import {Link} from "react-router-dom";
import {Widget, WidgetNoResult} from "../../../../components/collections/widget";
import {Avatar} from "../../../../components/collections/avatar";
import moment from "moment/moment";
import {modalShow} from "../../../services/system/modalService";
import {Button} from "../../../../components/elements/button";
import {FormattedMessage} from "react-intl";
import ConfirmReasonRejectedAdvanceContent from "../../doctor/bookings/ConfirmReasonRejectedAdvanceContent";

class UpcomingBookings extends Component {

    componentDidMount() {
        const tms = moment().hour(0).minute(0).second(0).unix();
        this.props.onListSorts({timeOfReceipt: 'asc'});
        this.props.onListFilterField("statuses", "REQUESTED-APPROVED");
        this.props.onListFilterField("isStarted", "false");
        this.props.onListFilterField("startDateOfReceipt", tms + "000");
        this.props.onFetchBookings(1);
    }

    componentWillUnmount() {
        this.props.onInitList();
    }

    render() {
        const {list: {data}} = this.props;
        const {upcomingBookings = []} = data;
        return (
            <Widget title={<FormattedMessage id="widget.patient.upcoming.booking.title"/>} linkTo={'/bookings'}
                    maxHeight={300} minHeight={200}>
                {upcomingBookings.length === 0 &&
                <WidgetNoResult><FormattedMessage id="widget.upcoming.booking.list.empty"/></WidgetNoResult>}
                {upcomingBookings.length > 0 &&
                <div className="row link-blocks-row blocks-hover-bg">
                    {upcomingBookings.filter((value, index) => index < 2).map(upcomingBooking => {
                            const {doctor = {}, timeOfReceipt, status} = upcomingBooking;
                            const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent booking={upcomingBooking}/>;
                            const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
                            const joinSpecializations = specializations.map(i => i.name).join(", ")
                            return (
                                <GridCol count="1" key={upcomingBooking.id}>

                                    <div className="link-block-wrapper">
                                        <Link to={'/bookings/' + upcomingBooking.id + '/detail'}
                                              className="link-block"></Link>
                                        <div className="row avatar-row align-items-center">
                                            <div className="col-auto avatar-row__image">
                                                <Avatar/>
                                            </div>
                                            <div className="col avatar-row__text">
                                                <div><FormattedMessage id="core.doctor.name" values={{
                                                    firstName: doctor.firstName,
                                                    lastName: doctor.lastName
                                                }}/></div>
                                                <div
                                                    className="small-text color-gray">{joinSpecializations}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="small-text color-gray"><i
                                                    className="icon icon--clock"></i> <FormattedMessage
                                                    id="core.time.label"/>
                                                </div>
                                                <div>{moment(timeOfReceipt).format("h:mm a")}</div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="small-text color-gray"><i
                                                    className="icon icon--date-2"></i> <FormattedMessage
                                                    id="core.date.label"/>
                                                </div>
                                                <div>{moment(timeOfReceipt).format("ll")}</div>
                                            </div>
                                            <div className="col-auto">
                                                {status === 'REQUESTED' &&
                                                <div className="small-text color-orange"><FormattedMessage
                                                    id="booking.status.requested.label"/></div>}
                                                {status === 'APPROVED' &&
                                                <div className="small-text color-green"><FormattedMessage
                                                    id="booking.status.approved.label"/></div>}
                                            </div>
                                            <div className="col-auto">
                                                <div className="small-controls">
                                                    <Button color="white-red" onClick={() => this.props.onConfirm({
                                                        advanceContent: rejectedAdvanceContent,
                                                        text: <FormattedMessage id="core.confirm.booking.cancel"/>,
                                                        onProceed: (() => this.props.onRejectBooking(upcomingBooking))
                                                    })}>
                                                        <FormattedMessage id="core.btn.cancel.label"/>
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </GridCol>
                            );
                        }
                    )}
                </div>}
            </Widget>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onRejectBooking: (booking) => dispatch(rejectBookingList(booking, "upcomingBookings")),
        onInitList: () => dispatch(listInitAction("upcomingBookings")),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber, "upcomingBookings")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("upcomingBookings", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("upcomingBookings", sorts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingBookings);
