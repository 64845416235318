import React from 'react';
import Helmet from 'react-helmet';

const TitleComponent = ({title}) => {
    return (
        <Helmet>
            <title>{title} - Telemed</title>
        </Helmet>
    );
};

export { TitleComponent };