import React, {Component} from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {listFilterFieldAction, listSortAction} from "../../../../actions/listAction";
import {Avatar} from "../../../../components/collections/avatar";
import {Button} from "../../../../components/elements/button";
import {modalShow} from "../../../services/system/modalService";
import {acceptReschedulingList, fetchBookings, rejectBookingList} from "../../../services/bookingService";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import ConfirmReasonRejectedAdvanceContent from "../../doctor/bookings/ConfirmReasonRejectedAdvanceContent";

class NewTimeAppointment extends Component {
    componentDidMount() {
        this.props.onListFilterField("statuses", "REQUESTED-APPROVED");
        this.props.onListFilterField("isRescheduling", "true");
        this.props.onListSorts({timeOfReceipt: 'asc'});
        this.props.onFetchBookings(1);
    }

    render() {
        const {list: {data}} = this.props;
        const {newTimeAppointments = []} = data;
        const {0: firstNewTimeAppointment = {}} = newTimeAppointments;
        const {id, doctor = {}, rescheduling, timeOfReceipt: oldTimeOfReceipt} = firstNewTimeAppointment || {};
        const {timeOfReceipt = null} = rescheduling || {};
        if (newTimeAppointments.length == 0) return "";
        const rejectedAdvanceContent = <ConfirmReasonRejectedAdvanceContent booking={firstNewTimeAppointment}/>;
        const specializations = (doctor.doctor && doctor.doctor.specializations) || [];
        const joinSpecializations = specializations.map(i => i.name).join(", ")
        return (
            <div className="col-12">
                <div className="white-block padding-0">
                    <div className="row form-elements form-padding-20-30 display-table">

                        <div className="col">
                            <div className="row avatar-row align-items-center">
                                <div className="col-auto avatar-row__image">
                                    <Avatar width="58"/>
                                </div>
                                <div className="col avatar-row__text">
                                    <div><FormattedMessage id="suggests.new.time.appointment" values={{firstName: doctor.firstName, lastName: doctor.lastName}}/></div>
                                    <div
                                        className="small-text color-gray">{joinSpecializations}</div>
                                </div>
                            </div>
                            <div className="small-text margin-top-10">
                                <FormattedMessage id="scheduled.date.time"/>
                                <span className="color-gray">{moment(oldTimeOfReceipt).format("lll")}</span>&nbsp;
                                <FormattedMessage id="new.proposed.date.time"/>
                                <span className="color-gray">{moment(timeOfReceipt).format("lll")}</span>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="person-col-controls small-controls">
                                <Button onClick={() => this.props.onConfirm({
                                    text: <FormattedMessage id="widget.patient.reschedule.confirm.accept.text"/>,
                                    onProceed: (() => this.props.onAcceptRescheduling(firstNewTimeAppointment))
                                })}><FormattedMessage id="core.btn.accept.label"/></Button>
                                <Button color="white-red"
                                        onClick={() => this.props.onConfirm({
                                            advanceContent: rejectedAdvanceContent,
                                            text: <FormattedMessage id="core.confirm.booking.cancel"/>,
                                            onProceed: (() => this.props.onRejectBooking(firstNewTimeAppointment))
                                        })}>
                                    <FormattedMessage id="core.btn.cancel.label"/>
                                </Button>
                                <Link to={"/bookings/" + id + "/detail"} className="btn-default">
                                    <Button color="white-blue"><FormattedMessage id="core.btn.detail.label"/></Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        list: store.list
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRejectBooking: (booking) => dispatch(rejectBookingList(booking, "newTimeAppointments")),
        onAcceptRescheduling: (booking) => dispatch(acceptReschedulingList(booking, "newTimeAppointments")),
        onConfirm: (data) => dispatch(modalShow('confirm', data)),
        onFetchBookings: (pageNumber) => dispatch(fetchBookings(pageNumber, "newTimeAppointments")),
        onListFilterField: (fieldName, fieldValue) => dispatch(listFilterFieldAction("newTimeAppointments", fieldName, fieldValue)),
        onListSorts: (sorts) => dispatch(listSortAction("newTimeAppointments", sorts))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTimeAppointment);
