import {preloaderPageHideAction, preloaderPageShowAction} from "../../actions/preloaderAction";
import {privateApi} from "../../utils/api";
import {listFetchAction} from "../../actions/listAction";
import {noticeShowAction} from "../../actions/noticeAction";
import {FormattedMessage} from "react-intl";
import React from "react";
import {modalHide} from "./system/modalService";
import {formInitAction} from "../../actions/formAction";
import {prepareFilter} from "../../utils/app";
import {formErrors} from "./system/formService";
import {isCanCreateReview} from "./bookingService";


export const fetchAllDoctorReviews = (pageNumber) => {
    return (dispatch, state) => {
        const filter = prepareFilter(state().list.filters["doctorReviews"] || {});
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/reviews?pageNumber=" + pageNumber + "&search=" + filter)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("doctorReviews", content, {...rest}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const fetchDoctorReviews = (pageNumber, doctorId) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/doctors/" + doctorId + "/reviews?pageNumber=" + pageNumber)
            .then(response => {
                const {data} = response;
                const {content, ...rest} = data;
                dispatch(listFetchAction("doctorReviews", content, {...rest}));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const fetchDoctorReviewsStatistic = (doctorId) => {
    return (dispatch, state) => {
        dispatch(preloaderPageShowAction());
        return privateApi.get("/v1/doctors/" + doctorId + "/reviews/statistic")
            .then(response => {
                const {data} = response;
                dispatch(formInitAction("doctorReviewsStatistic", data));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const newDoctorReview = (data, afterCreateFetch) => {
    const {bookingId, doctorId, ...newReview} = data;
    return (dispatch, state) => {
        const {number} = state().list.pagination["doctorReviews"] || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/bookings/" + bookingId + "/reviews", newReview)
            .then(response => {
                dispatch(modalHide("newDoctorReview"));
                dispatch(isCanCreateReview(bookingId));
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>,
                    <FormattedMessage id="success.submitting.feedback"/>));
                if (afterCreateFetch) {
                    dispatch(fetchDoctorReviews(number + 1, doctorId));
                    dispatch(fetchDoctorReviewsStatistic(doctorId));
                }
            }).catch(error => {
                if (error.response.status === 400) {
                    dispatch(formErrors("newDoctorReview", error.response.data.details));
                } else {
                    dispatch(noticeShowAction(<FormattedMessage
                        id="notice.default.title"/>, error.response.data.message));
                }
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const approveDoctorReview = (reviewId) => {
    return (dispatch, state) => {
        const {number} = state().list.pagination["doctorReviews"] || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/reviews/" + reviewId + "/approve")
            .then(response => {
                dispatch(modalHide("confirm"));
                dispatch(fetchAllDoctorReviews(number + 1));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};

export const declineDoctorReview = (reviewId) => {
    return (dispatch, state) => {
        const {number} = state().list.pagination["doctorReviews"] || {};
        dispatch(preloaderPageShowAction());
        return privateApi.post("/v1/reviews/" + reviewId + "/decline")
            .then(response => {
                dispatch(modalHide("confirm"));
                dispatch(fetchAllDoctorReviews(number + 1));
            }).catch(error => {
                dispatch(noticeShowAction(<FormattedMessage id="notice.default.title"/>, error.response.data.message));
            }).finally(() => dispatch(preloaderPageHideAction()));
    }
};