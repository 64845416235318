import React, {Component} from 'react';
import {connect} from "react-redux";

import {Redirect} from "react-router";
import {GridCol, GridRow} from "../../../components/collections/grid";
import {Input} from "../../../components/elements/input";
import {Buttons, Button} from "../../../components/elements/button";
import Password from "../../systems/password";
import {formChangeField, formInit} from "../../services/system/formService";
import {TabPane, TabPanes} from "../../../components/modules/tab";
import {ViaGoogle} from "../../../components/modules/viaGoogle";
import {googleUserInfo, signUp} from "../../services/userService";
import {formChangeFieldAction} from "../../../actions/formAction";
import {MainLogo} from "../../../components/elements/logo";
import moment from "moment-timezone";
import {fetchSystemSettings} from "../../services/commonService";
import {FormattedMessage} from "react-intl";
import {ViaApple} from "../../../components/modules/viaApple";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.props.onSignUpInit();
        this.props.onFormSimpleChange("signUp", "timezone", moment.tz.guess(true));
        this.props.onFormSimpleChange("signUp", "isAgree", true);
    }

    onChange(event) {
        this.props.onFormChangeField('signUp', event);
    }

    render() {
        const {form, auth} = this.props;
        const errors = form.errors.signUp;
        const disabled = form.isSend.signUp || false;
        const newUser = form.data.signUp || {};
        if (auth.isAuthenticated) return <Redirect to="/"/>;

        return (
            <>
                <MainLogo/>
                <TabPanes>
                    <TabPane>
                        <GridRow width={300}>
                            <GridCol count="1">
                                <ViaApple name={<FormattedMessage id="core.btn.sign.up.via.apple.label"/>}/>
                            </GridCol>
                            <GridCol count="1">
                                <ViaGoogle name={<FormattedMessage id="core.btn.sign.up.via.google.label"/>}/>
                            </GridCol>
                            <GridCol count="1">
                                <span className="color-gray"><FormattedMessage id="core.or.label"/></span>
                            </GridCol>
                        </GridRow>
                        <GridRow width={300}>
                            <GridCol count="1">
                                <Input name="email"
                                       icon="email"
                                       value={newUser.email || ''}
                                       error={(errors && errors.email) || null}
                                       placeholder={<FormattedMessage id="core.field.email.label"/>}
                                       disabled={disabled}
                                       autocomplete="email"
                                       onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="1">
                                <input type="hidden" value=""/>
                                <Password name="password"
                                          icon="password"
                                          placeholder={<FormattedMessage id="core.field.password.label"/>}
                                          error={(errors && errors.password) || null}
                                          disabled={disabled}
                                          onChange={this.onChange}/>
                            </GridCol>
                            <GridCol count="1">
                                <Password name="matchingPassword"
                                          icon="password"
                                          placeholder={<FormattedMessage id="core.field.password.repeat.label"/>}
                                          error={(errors && errors.matchingPassword) || null}
                                          disabled={disabled}
                                          onChange={this.onChange}/>
                            </GridCol>
                        </GridRow>
                        <GridRow width={300}>
                            <GridCol count="1">
                                <Buttons>
                                    <Button disabled={disabled}
                                            preloader={disabled === true}
                                            onClick={() => this.props.onSignUp(form.data.signUp)}><FormattedMessage
                                        id="sing.up.as.patient"/></Button>
                                </Buttons>
                            </GridCol>
                            <GridCol count="1">
                                <Buttons>
                                    <p className="small-text"><FormattedMessage id="core.already.have.account.label"/></p>
                                    <a href={'/users/sign-in'} className="btn-default btn-blue-bordered">
                                        <div className="btn__content">
                                            <FormattedMessage id="core.btn.sign-in.label"/>
                                        </div>
                                    </a>
                                </Buttons>
                            </GridCol>
                        </GridRow>
                    </TabPane>
                </TabPanes>
            </>
        )
    }
}

const mapStateToProps = store => {
    return {
        form: store.form,
        auth: store.auth
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSignUpInit: () => dispatch(formInit("signUp", {})),
        onSignUp: (data) => dispatch(signUp(data)),
        onFormSimpleChange: (formName, fieldName, fieldValue) => dispatch(formChangeFieldAction(formName, fieldName, fieldValue)),
        onGoogleUserInfo: (googleAccessToken) => dispatch(googleUserInfo(googleAccessToken)),
        onFormChangeField: (formName, event) => dispatch(formChangeField(formName, event)),
        onChangeDropdown: (fieldName, fieldValue) => dispatch(formChangeFieldAction('signUp', fieldName, fieldValue)),
        onfetchSystemSettings: () => dispatch(fetchSystemSettings())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);